<div
	class="groups-container"
	[routerLinkActive]="''"
	#routerLinkContainer="routerLinkActive"
	[class.active]="routerLinkContainer.isActive && (!isGroupExpanded || !isPanelOpened)"
>
	@if (isPanelOpened) {
		<div class="groups-header" (click)="toggleGroups()">
			<pp-icon class="groups-header-logo" [name]="groupIcon" [size]="18"></pp-icon>

			<span [class.new-item-label]="isNew">{{ title }}</span>

			<pp-icon
				class="groups-header-chevron"
				[name]="
					isGroupExpanded ? 'navigation-chevron-down-18' : 'navigation-chevron-right-18'
				"
			></pp-icon>
		</div>
	} @else {
		<div class="groups-header collapsed" (click)="togglePanel()">
			<pp-icon
				class="groups-header-logo"
				[class.new-mark]="isNew"
				[name]="groupIcon"
				[size]="18"
			></pp-icon>

			<span>{{ title }}</span>
		</div>
	}

	@if (items.length) {
		<div [class.hidden]="!(isPanelOpened && isGroupExpanded)">
			@for (group of items; track group.name) {
				@if (group.items?.length) {
					<shared-navigation-group
						[routerLinkActive]="''"
						#routerLink2="routerLinkActive"
						[groupName]="group.name"
						[hasSelectedLink]="routerLink2.isActive"
					>
						@for (item of group.items; track item.name) {
							<a
								class="navigation-group-expandable-item"
								[routerLinkActive]="'selected'"
								[routerLink]="item.link"
								(click)="trackAnalytics(item.name)"
								>{{ item.name }}</a
							>
						}
					</shared-navigation-group>
				} @else {
					@if (!group.isLocked) {
						@if (!group.externalLink) {
							<a
								class="groups-item-link"
								[routerLink]="group.link"
								(click)="trackAnalytics(group.name)"
								[routerLinkActive]="''"
								[queryParams]="group.queryParams || null"
								#routerLink="routerLinkActive"
								[class.selected]="
									group.isSelected !== undefined
										? group.isSelected
										: routerLink.isActive
								"
							>
								<ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
							</a>
						} @else {
							<a
								class="groups-item-link"
								[href]="group.externalLink"
								target="_blank"
								(click)="trackExternalLink(group.name)"
							>
								<ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
							</a>
						}

						<ng-template #externalLink>
							<a
								class="groups-item-link"
								[href]="group.externalLink"
								target="_blank"
								(click)="trackExternalLink(group.name)"
							>
								<ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
							</a>
						</ng-template>
					} @else {
						<div class="groups-item-link access-denied">
							<span>{{ group.name }}</span>

							<pp-icon
								(click)="openAccessInfoPopover($event)"
								class="access-icon"
								name="action-lock-fill-18"
							></pp-icon>
						</div>
					}

					<ng-template #linkTemplate>
						<span [class.new-item-label]="group.isNew">{{ group.name }} </span>
						@if (group.hint) {
							<pp-icon
								class="hint-icon"
								name="notification-info-outline-18"
								[hint]="group.hint"
								[hintOffset]="12"
								[hintPosition]="'BOTTOM'"
							></pp-icon>
						}

						@if (!!group.badge) {
							<shared-abbreviation-icon
								class="badge"
								[label]="group.badge"
							></shared-abbreviation-icon>
						}
					</ng-template>
				}
			}
		</div>
	} @else {
		@if (isPanelOpened) {
			<pp-spinner class="groups-spinner" type="uui" size="medium"></pp-spinner>
		}
	}
</div>
