import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';

@Injectable()
export class DocumentScrollService {
	constructor(@Inject(DOCUMENT) private readonly document: Document) {}

	hideDocumentScroll(): void {
		const root = this.document.documentElement;
		root.style.overflow = 'hidden';
		root.style.height = '100vh';
	}

	showDocumentScroll(): void {
		const root = this.document.documentElement;
		root.style.overflow = '';
		root.style.height = '';
	}
}
