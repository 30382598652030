import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Self,
	SimpleChanges,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {BreakpointsType, ContainerStrategy, LayoutService} from '@shared/layout';

import {SearchResultModel} from '@shared/models';
import {AnalyticsService} from '@shared/analytics';

const BreakpointsDefault: BreakpointsType = ['(min-width: 635px)'];

@Component({
	selector: 'shared-preview',
	templateUrl: './preview.component.html',
	styleUrls: ['./preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [LayoutService, ContainerStrategy],
	standalone: true,
})
export class PreviewComponent implements OnInit, OnChanges, OnDestroy {
	private readonly subscriptions = new Subscription();

	private intervalHandle?: number;
	private resizeHandle?: number;
	private strategy!: ContainerStrategy;

	isPortraitMode = false;
	previewSource: SafeUrl | null = null;
	isBig = false;

	@Input({required: true}) document!: Partial<SearchResultModel>;
	@Input() previewPath?: string;
	@Input() query?: string;
	@Input() videoPreview = false;
	@Input() inline = false;
	@Input() calculateOnResize = true;
	@Output() closePreview = new EventEmitter();

	constructor(
		@Self() private readonly layout: LayoutService,
		private sanitizer: DomSanitizer,
		private analytics: AnalyticsService,
		private element: ElementRef,
		private detector: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.strategy = this.layout.useStrategy('container');
		this.strategy.element = this.element.nativeElement;
		this.strategy.breakpoints = BreakpointsDefault;

		this.subscriptions.add(
			this.strategy.observer$
				.pipe(
					tap(({matches}) => {
						this.isBig = matches && !!this.previewPath?.match('embed.aspx');
						this.detector.detectChanges();
					}),
				)
				.subscribe(),
		);

		this.analytics.trackPreviewAction('open');
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['previewPath'] || (!this.previewPath && changes['document'])) {
			this.previewSource = null;

			setTimeout(() => {
				let link: string | undefined;

				if (!this.videoPreview) {
					link = this.previewPath || this.document.fullPreview;
				} else {
					link = this.document.videoUrl;
				}

				if (link !== undefined) {
					this.previewSource = this.sanitizer.bypassSecurityTrustResourceUrl(link);
				}
				this.isPortraitMode = !this.videoPreview && this.document.fileType !== 'PowerPoint';

				this.detector.markForCheck();

				setTimeout(() => {
					this.resize();
				});
			});
		}

		this.resize();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.strategy?.destroy();

		window.clearInterval(this.intervalHandle);

		this.analytics.trackPreviewAction('close');
	}

	@HostListener('window:message', ['$event'])
	initDocumentPreview(event: MessageEvent): void {
		// presales communicate with word, powerpoint, visio and excel iframes on search via messages
		if (event.data === 'PresalesDocumentKeyUpEscape') {
			return this.closePreview.emit();
		}

		if (event.data === 'PresalesWordLoaded') {
			const frame: HTMLIFrameElement = this.element.nativeElement.querySelector('iframe');

			if (frame === null) {
				return this.analytics.trackException('Word preview iframe is null');
			}

			if (!frame.contentDocument) {
				return this.analytics.trackException('Word preview iframe CORS issue');
			}

			const childIframe = this.getChildIframe(frame);

			if (childIframe) {
				childIframe.contentWindow?.postMessage({type: 'WordQuery', query: this.query}, '*');
			}

			return;
		}

		// on-prem OOS will be decommissioned soon with all customizations
		// if (event.data?.type === 'PresalesWordQueryError') {
		// 	return this.analytics.trackException(event.data.message);
		// }
	}

	@HostListener('window:resize')
	resize(): void {
		if (this.calculateOnResize) {
			window.clearTimeout(this.resizeHandle);

			this.resizeHandle = window.setTimeout(this.resizeCallback.bind(this), 100);
		}
	}

	private getChildIframe(iframe: HTMLIFrameElement): HTMLIFrameElement | undefined {
		const childIframe: HTMLIFrameElement | undefined =
			iframe.contentDocument?.querySelector('#WebApplicationFrame') ?? undefined;

		if (!childIframe) {
			this.analytics.trackException('Preview child iframe does not exist');

			return undefined;
		}

		return childIframe;
	}

	private resizeCallback(): void {
		const sideMargin = this.inline ? 0 : 30;
		const container = this.element.nativeElement.querySelector(
			'.preview-container',
		) as HTMLElement;
		const frame = this.element.nativeElement.querySelector('.preview-wrapper') as HTMLElement;
		const {offsetHeight, offsetWidth} = container;

		if (frame && !this.isPortraitMode) {
			const ratio = 16 / 9;
			const bottomMenu = this.videoPreview ? 0 : 23;
			const margin = this.inline ? 0 : 100;

			this.resizePreview(
				offsetWidth - sideMargin,
				offsetHeight - margin,
				ratio,
				bottomMenu,
				frame,
			);
		}
	}

	private resizePreview(
		width: number,
		height: number,
		ratio: number,
		offset: number,
		frame: HTMLElement,
	): void {
		if (width / (height - offset) > ratio) {
			frame.style.height = `${height}px`;
			frame.style.width = `${(height - offset) * ratio}px`;
		} else {
			frame.style.width = `${width}px`;
			frame.style.height = `${width / ratio + offset}px`;
		}
	}
}
