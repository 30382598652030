import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import {Store} from '@ngrx/store';

import {RouterLink} from '@angular/router';
import {SubmitQueryAction} from '@search/actions';
import {SearchState} from '@search/reducers/search-state';
import {SearchResultModel} from '@shared/models';
import {ImagePipe} from '@shared/pipes';

@Component({
	selector: 'search-document-images',
	templateUrl: './document-images.component.html',
	styleUrls: ['./document-images.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [RouterLink, ImagePipe],
})
export class DocumentImagesComponent implements OnInit {
	private readonly pageSize = 15;

	images: string[] = [];

	@Input({required: true}) document!: SearchResultModel;
	@Output() search = new EventEmitter();

	constructor(private store$: Store<SearchState>) {}

	ngOnInit(): void {
		this.images = this.document.images.slice(0, this.pageSize);
	}

	scroll(target: EventTarget | null): void {
		const targetElement = target as HTMLElement;
		const loaded = this.images.length;

		if (
			targetElement.scrollTop + targetElement.clientHeight === targetElement.scrollHeight &&
			loaded < this.document.images.length
		) {
			this.images = this.document.images.slice(0, loaded + this.pageSize);
		}
	}

	triggerSearch(event: MouseEvent, hash: string): void {
		if (!event.ctrlKey || !event?.metaKey) {
			event.preventDefault();
			this.store$.dispatch(new SubmitQueryAction(`usppImages:${hash}`));
			this.search.emit();
		}
	}

	getQueryParams(hash: string): string {
		return `{"query":{"submittedQuery":"usppImages:${hash}"}}`;
	}
}
