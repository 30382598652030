import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {map, Observable} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {PreviewCasesTreeSectionComponent} from './preview-cases-tree-section/preview-cases-tree-section.component';
import {PresetLinkComponent} from '@search2/components';
import {SvgIconComponent} from '@pp/svg';
import {DocumentIndexItem, PpFacetResult} from '@shared/api';
import {PresetLinkModel} from '@search2/models';
import {SearchQueryService, SearchStateSerializerService} from '@search2/services';
import {isObjectEmpty} from '@shared/utils';

@Component({
	selector: 'search-preview-cases',
	templateUrl: './preview-cases.component.html',
	styleUrls: ['./preview-cases.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [PresetLinkComponent, SvgIconComponent, PreviewCasesTreeSectionComponent, AsyncPipe],
})
export class PreviewCasesCollectionComponent implements OnInit {
	@Input({required: true}) item!: DocumentIndexItem; // TODO: read from store
	@Input({required: true}) collectionName!: string;

	vm$!: Observable<PreviewCasesViewModel | undefined>;

	constructor(
		private readonly searchService: SearchQueryService,
		private readonly searchStateSerializer: SearchStateSerializerService,
	) {}

	ngOnInit(): void {
		const filterCondition = {
			'spo_presales.case_study_collection.keyword': [this.collectionName],
		};
		const query = this.searchService.createQuery({
			presetId: 'cases',
			facetFilters: filterCondition,
			facets: [
				'spo_presales.crm_reporting_industry.keyword',
				'spo_presales.tags.keyword',
				'spo_presales.crm_account_name_paths',
			],
			size: 0,
		});

		this.vm$ = this.searchService.search$(query).pipe(
			map((resp) => {
				if (!resp.facets || isObjectEmpty(resp.facets)) {
					return undefined;
				}

				const queryState = this.searchStateSerializer.getQueryObject({
					selectedFilters: filterCondition,
				});

				const categories = resp.facets['spo_presales.tags.keyword'].filter((tag) =>
					this.item.spoPresales?.tags.some((x) => x.rawValue === tag.text),
				);

				return {
					casesLink: {
						presetId: 'cases',
						query: queryState,
					},
					sections: [
						{
							title: 'Industries',
							items: resp.facets['spo_presales.crm_reporting_industry.keyword'],
							field: 'spo_presales.crm_reporting_industry.keyword',
							expandByDefault: true,
						},
						{
							title: 'Categories',
							items: categories,
							field: 'spo_presales.tags.keyword',
							expandByDefault: false,
						},
						{
							title: 'Top Accounts',
							items: resp.facets['spo_presales.crm_account_name_paths'].slice(0, 20),
							field: 'spo_presales.crm_account_name_paths',
							expandByDefault: false,
						},
					],
					totalCount: resp.totalCount,
				};
			}),
		);
	}
}

class PreviewCasesViewModel {
	casesLink!: PresetLinkModel;
	sections!: PreviewCasesSection[];
	totalCount!: number;
}

class PreviewCasesSection {
	title!: string;
	items!: PpFacetResult[];
	field!: string;
	expandByDefault!: boolean;
}
