<div class="templates-page-container" [ppCdkUseTheme]="theme()">
	<div class="templates-page-content">
		<h1>Presales Templates</h1>

		<div class="templates-tree">
			@for (section of templatesTree()?.children; track $index) {
				<powerpoint-grouped-section [section]="section"></powerpoint-grouped-section>
			}
		</div>
	</div>
</div>
