@if (filtersSettings$ | async; as filtersSettings) {
	<div ppCdkUseTheme="light" class="search-filters-layout" [ngStyle]="{height: height()}">
		<search-filters-search-bar class="search-filters-layout-bar"></search-filters-search-bar>

		<div class="search-filters-layout-scroll-container">
			<div class="filters-container">
				@for (filter of filtersSettings; track filter.key) {
					@if (filter.value.visible) {
						@if (widgetTypes.LIST === filter.value.type) {
							<ng-container
								*ngTemplateOutlet="
									listTemplate;
									context: {
										source: filter.key,
										filterSetting: filter.value
									}
								"
							></ng-container>
						}

						@if (widgetTypes.TREE === filter.value.type) {
							<ng-container
								*ngTemplateOutlet="
									treeTemplate;
									context: {
										source: filter.key,
										filterSetting: filter.value
									}
								"
							></ng-container>
						}

						@if (widgetTypes.GROUP_LIST === filter.value.type) {
							<ng-container
								*ngTemplateOutlet="
									groupListTemplate;
									context: {
										source: filter.key,
										filterSetting: filter.value
									}
								"
							></ng-container>
						}

						@if (widgetTypes.HISTOGRAM === filter.value.type) {
							<ng-container
								*ngTemplateOutlet="
									histogramTemplate;
									context: {
										source: filter.key,
										filterSetting: filter.value
									}
								"
							></ng-container>
						}
					}
				}
			</div>
		</div>
	</div>
}

<ng-template #listTemplate let-source="source" let-filterSetting="filterSetting">
	<search-filters-widget
		[label]="source | filterSectionName"
		[source]="source"
		[class.hidden]="hiddenSections.has(source)"
		[ngStyle]="{order: filterSetting.order}"
	>
		<search-filters-selected-list
			filterSectionFiltersSelector
			[source]="source"
		></search-filters-selected-list>

		<search-filters-list
			filterSectionListSelector
			[listType]="widgetTypes.LIST"
			[source]="source"
			(toggleOnSearch)="toggleSection($event)"
		></search-filters-list>
	</search-filters-widget>
</ng-template>

<ng-template #treeTemplate let-source="source" let-filterSetting="filterSetting">
	<search-filters-widget
		[label]="source | filterSectionName"
		[source]="source"
		[class.hidden]="hiddenSections.has(source)"
		[ngStyle]="{order: filterSetting.order}"
	>
		<search-filters-selected-list
			filterSectionFiltersSelector
			[source]="source"
		></search-filters-selected-list>

		<search-filters-list-tree
			filterSectionListSelector
			[source]="source"
			level="0"
			(toggleOnSearch)="toggleSection($event)"
		></search-filters-list-tree>
	</search-filters-widget>
</ng-template>

<ng-template #groupListTemplate let-source="source" let-filterSetting="filterSetting">
	<search-filters-widget
		[label]="source | filterSectionName"
		[source]="source"
		[class.hidden]="hiddenSections.has(source)"
		[ngStyle]="{order: filterSetting.order}"
	>
		<search-filters-selected-group-list
			filterSectionFiltersSelector
			[source]="source"
		></search-filters-selected-group-list>

		<search-filters-list
			filterSectionListSelector
			[listType]="widgetTypes.GROUP_LIST"
			[source]="source"
			(toggleOnSearch)="toggleSection($event)"
		></search-filters-list>
	</search-filters-widget>
</ng-template>

<ng-template #histogramTemplate let-source="source" let-filterSetting="filterSetting">
	<search-filters-widget
		[label]="source | filterSectionName"
		[source]="source"
		[class.hidden]="hiddenSections.has(source)"
		[ngStyle]="{order: filterSetting.order}"
	>
		<search-filter-histogram
			filterSectionListSelector
			[filterName]="source"
		></search-filter-histogram>
	</search-filters-widget>
</ng-template>
