import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {SelectionStore} from '../selection.store';
import {UseThemeDirective} from '@shared/directives';
import {HintDirective} from '@pp/hint';
import {SpinnerComponent} from '@pp/spinner';
import {ModalService} from '@shared/modals';
import {DeckBuilderModalComponent} from '@deck-builder/components';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'shared-selection-panel',
	templateUrl: './selection-panel.component.html',
	styleUrls: ['./selection-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [UseThemeDirective, HintDirective, SpinnerComponent],
})
export class SelectionPanelComponent {
	constructor(
		private readonly analytics: AnalyticsService,
		private readonly modalService: ModalService,
	) {}

	private selectionStore = inject(SelectionStore);
	documentIds = this.selectionStore.documentIds;
	isLocked = this.selectionStore.isLocked;

	openDeckBuilder(): void {
		this.modalService.openModal(DeckBuilderModalComponent, {
			type: 'DECK_BUILDER',
			showCloseButton: false,
		});
		this.analytics.trackDeckBuilderAction('open');
	}

	unselectAll(): void {
		this.selectionStore.resetState('user-unselect-all');
		this.analytics.trackSelectionAction('state:reset');
	}
}
