export class TreeNodeContext<T = unknown> {
	get $implicit(): T {
		return this.treeViewItem;
	}

	constructor(
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		readonly treeViewItem: T = null!,
	) {}
}
