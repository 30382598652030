import * as actions from '../../actions';
import {SelectedFiltersPayload} from '../../actions/selected-filters/selected-filters-payload';
import {selectedFiltersInitialState} from './selected-filters-initial-state';
import {SelectedFilters, SelectedFiltersState} from './selected-filters-state';

function addFilter(
	state: SelectedFiltersState,
	condition: string,
	type: string,
	token: string,
): SelectedFiltersState {
	let result = state;

	if (
		state[condition as keyof typeof state][type as keyof SelectedFilters].findIndex(
			([element]) => element === token,
		) === -1
	) {
		const list = state[condition as keyof typeof state][type as keyof SelectedFilters].slice();
		list.push(token);

		result = {
			...state,
			[condition]: {
				...state[condition as keyof typeof state],
				[type]: list,
			},
		};
	}

	return result;
}

function removeConditionFilter(
	state: SelectedFiltersState,
	condition: string,
	type: string,
	token: string,
): string[] {
	let list = state[condition as keyof typeof state][type as keyof SelectedFilters];
	const index = list.findIndex((element: string) => element === token);

	if (index > -1) {
		list = list.filter((element: string) => element !== token);
	}

	return list;
}

function removeFilter(
	state: SelectedFiltersState,
	type: string,
	token: string,
): SelectedFiltersState {
	const include = removeConditionFilter(state, 'include', type, token);
	const exclude = removeConditionFilter(state, 'exclude', type, token);

	return {
		...state,
		include: {
			...state.include,
			[type]: include,
		},
		exclude: {
			...state.exclude,
			[type]: exclude,
		},
	};
}

export function reducer(
	state: SelectedFiltersState = selectedFiltersInitialState,
	action: actions.SelectedFiltersActions,
): SelectedFiltersState {
	const type = (action.payload && (action.payload as SelectedFiltersPayload).type) ?? '';
	const {token = ''} =
		(action.payload && (action.payload as SelectedFiltersPayload).filter) || {};

	switch (action.type) {
		case actions.INIT_SELECTED_FILTERS:
			const payload: SelectedFiltersState = action.payload || {};

			return {
				...selectedFiltersInitialState,
				...payload,
				include: {
					...selectedFiltersInitialState.include,
					...payload.include,
				},
				exclude: {
					...selectedFiltersInitialState.exclude,
					...payload.exclude,
				},
			};
		case actions.ADD_INCLUDE_FILTER:
		case actions.ADD_INCLUDE_FILTER_SILENT:
			return addFilter(state, 'include', type, token);
		case actions.ADD_EXCLUDE_FILTER:
			return addFilter(state, 'exclude', type, token);
		case actions.REMOVE_FILTER:
			return removeFilter(state, type, token);
		case actions.REPLACE_INCLUDE_FILTER:
		case actions.REPLACE_INCLUDE_FILTER_SILENT:
			return {
				...state,
				include: {
					...state.include,
					[action.payload.type]: action.payload.filter
						? [action.payload.filter.token]
						: [],
				},
			};
		case actions.RESET_SELECTED_FILTER:
			return {
				...state,
				include: {
					...state.include,
					[action.payload]:
						selectedFiltersInitialState.include[
							action.payload as keyof SelectedFilters
						],
				},
				exclude: {
					...state.exclude,
					[action.payload]:
						selectedFiltersInitialState.exclude[
							action.payload as keyof SelectedFilters
						],
				},
			};
		case actions.RESET_SELECTED_FILTERS:
		case actions.RESET_SELECTED_FILTERS_SILENT:
			return {
				...selectedFiltersInitialState,
			};
		default:
			return state;
	}
}
