<shared-autocomplete
	#autocomplete
	[placeholder]="'Search' + (presetName() ? ' in '+presetName():presetName())"
	ppCdkUseTheme="dark"
	[formControl]="searchQuery"
	(changeValue)="searchQueryValueChanged($event)"
>
	<shared-option
		(selectionChange)="searchQueryValueChanged(searchQuery.value ?? '')"
		ppCdkUseTheme="dark"
	>
		<div class="tip-container">
			<pp-icon class="item-icon" name="action-search-18"></pp-icon>
			<span class="tip-query">{{ searchQuery.value }}</span>
			<span>Press Enter to perform a search.</span>
		</div>
	</shared-option>
	@if (suggestionByType$ | async; as suggestionsByType) {
		@if (!!suggestionsByType.history.length) {
			<div class="suggestions-group-caption-container">
				<span class="suggestions-group-caption-title">Search History</span>
				<div class="suggestions-group-caption-divider"></div>
			</div>
			@for (item of suggestionsByType.history; track item.history!.text) {
				<div>
					<shared-option
						ppCdkUseTheme="dark"
						(selectionChange)="selectSuggestion($event, item)"
					>
						<pp-icon
							class="item-icon"
							[name]="
								item.history!.type === 'user'
									? 'action-schedule-18'
									: 'action-search-18'
							"
						></pp-icon>
						<span class="item-content">
							<span
								class="item-label"
								[ngClass]="{'user-history': item.history!.type === 'user'}"
								[innerHtml]="item.history!.text | highlight"
							></span>
						</span>
					</shared-option>
				</div>
			}
		}

		@if (!!suggestionsByType.term.length) {
			<div class="suggestions-group-caption-container">
				<span class="suggestions-group-caption-title">Filters</span>
				<div class="suggestions-group-caption-divider"></div>
			</div>
			@for (item of suggestionsByType.term; track item.term!.text) {
				<div>
					<shared-option
						ppCdkUseTheme="dark"
						(selectionChange)="selectSuggestion($event, item)"
					>
						<pp-icon class="item-icon" name="action-hashtag-18"></pp-icon>
						<span class="item-content">
							<span
								class="item-label"
								[innerHtml]="item.term!.text | highlight"
							></span>
							<span>&nbsp;in&nbsp;{{ item.term!.scope }}</span>
						</span>
					</shared-option>
				</div>
			}
		}

		@if (!!suggestionsByType.document.length) {
			<div class="suggestions-group-caption-container">
				<span class="suggestions-group-caption-title">Documents</span>
				<div class="suggestions-group-caption-divider"></div>
			</div>
			@for (item of suggestionsByType.document; track item.document!.document.id) {
				<div>
					<shared-option
						ppCdkUseTheme="dark"
						(selectionChange)="selectSuggestion($event, item)"
					>
						<search-item-card
							[item]="item.document!.document"
							[size]="'small'"
							[showActions]="false"
							[direction]="'horizontal'"
							[blockCardActions]="true"
						></search-item-card>
					</shared-option>
				</div>
			}
		}
	}
</shared-autocomplete>
