import {QueryState} from './query-state';

export const queryInitialState: QueryState = {
	currentQuery: '',
	submittedQuery: '',
	sortBy: {},
	// TODO: Consider move to results reducer.
	isLoading: true,
	groupId: '',
	expandDuplicates: false,
	disableQueryTemplate: false,
};
