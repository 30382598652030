import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass} from '@angular/common';
import {HintDirective} from '@pp/hint';
import {SvgIconComponent} from '@pp/svg';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'search-filter-header',
	templateUrl: './search-filter-header.component.html',
	styleUrls: ['./search-filter-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent, NgClass, HintDirective],
})
export class SearchFilterHeaderComponent {
	@Input() title = '';
	@Input() showResetButton = false;
	@Input() itemsCount = 0;
	@Input() maxCount = 0;

	@Output() resetEventEmitter = new EventEmitter();
	@Output() toggleExpandEventEmitter = new EventEmitter();
	@Output() toggleShowEventEmitter = new EventEmitter();

	isShowAll = false;

	constructor(private readonly analytics: AnalyticsService) {}

	resetEvent(): void {
		this.resetEventEmitter.emit();
	}

	showToggleEvent(): void {
		this.isShowAll = !this.isShowAll;
		this.toggleShowEventEmitter.emit();
		this.analytics.trackDocumentCatalogAction('filter:toggle-show-all', this.title);
	}

	expandToggleEvent(): void {
		this.toggleExpandEventEmitter.emit();
		this.analytics.trackDocumentCatalogAction('filter:toggle-expand', this.title);
	}
}
