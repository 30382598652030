import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AsyncPipe, DecimalPipe} from '@angular/common';
import {SearchFeatureState} from '../../reducers/search-state';
import {SearchFiltersListGroupService} from './search-filters-list-group.service';
import {SearchFiltersListService} from './search-filters-list.service';
import {FilterViewModel, SearchFilterWidgetTypes} from '@search/models';
import {AnalyticsService} from '@shared/analytics';
import {FilterSectionNamePipe, MoreLessPipe} from '@shared/pipes';
import {SvgIconComponent} from '@pp/svg';
import {AnchorDirective} from '@pp/anchor';

@Component({
	selector: 'search-filters-list',
	templateUrl: './search-filters-list.component.html',
	styleUrls: [
		'./search-filters-list-item.component.scss',
		'./search-filters-list.component.scss',
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [AnchorDirective, SvgIconComponent, AsyncPipe, DecimalPipe, MoreLessPipe],
})
export class SearchFiltersListComponent implements OnInit {
	items$!: Observable<FilterViewModel[]>;
	showMore = false;

	listTypes = SearchFilterWidgetTypes;

	get service(): SearchFiltersListService {
		return this.listType === this.listTypes.GROUP_LIST
			? this.filterListGroupService
			: this.filterListService;
	}

	@Input({required: true}) listType!:
		| SearchFilterWidgetTypes.LIST
		| SearchFilterWidgetTypes.GROUP_LIST;
	@Input({required: true}) source!: string;
	@Output() toggleOnSearch = new EventEmitter<[string, boolean]>();

	get anchorName(): string {
		return `search-filter-${this.filterSectionName.transform(this.source).toLowerCase()}`;
	}

	constructor(
		private store$: Store<SearchFeatureState>,
		private analytics: AnalyticsService,
		private filterListService: SearchFiltersListService,
		private filterListGroupService: SearchFiltersListGroupService,
		private readonly filterSectionName: FilterSectionNamePipe,
	) {}

	ngOnInit(): void {
		// ! if you reset all filters you can have a performance issue
		// ! because of large amount of items
		this.items$ = combineLatest([
			this.service.getItems$(this.source),
			this.store$.select((state) => state.search.ui.filterSearchRegex),
		]).pipe(
			map(([items, searchString]) => {
				this.toggleOnSearch.emit([
					this.source,
					searchString !== undefined && items.length === 0,
				]);

				return items;
			}),
		);
	}

	toggleShowMore(): void {
		this.showMore = !this.showMore;
		this.analytics.trackFilterAction(this.showMore ? 'showMore' : 'showLess', this.source);
	}

	includeFilter(item: FilterViewModel): void {
		this.service.includeFilter(item, this.source);
	}

	excludeFilter(item: FilterViewModel): void {
		this.service.excludeFilter(item, this.source);
	}
}
