import {Injectable} from '@angular/core';
import {AccessLevelFilterService} from '../access-level-filter/access-level-filter.service';

import * as actions from '../../actions';
import {DocumentTagsFilterService} from '../document-tags-filter/document-tags-filter.service';
import {FileTypeFilterService} from '../file-type-filter/file-type-filter.service';
import {LanguagesFilterService} from '../languages-filter/languages-filter.service';
import {LocationTreeFilterService} from '../location-tree-filter/location-tree-filter.service';
import {StockListFilterService} from '../stock-list-filter/stock-list-filter.service';
import {StockTreeFilterService} from '../stock-tree-filter/stock-tree-filter.service';
import {TagsFilterService} from '../tags-filter/tags-filter.service';
import {filtersMapping} from '@shared/services';

@Injectable({providedIn: 'root'})
export class FilterFactoryService {
	private readonly mapping = {
		languages: {
			action: actions.UpdateLanguagesFilterAction,
			service: this.languagesFilter,
		},
		usppDocumentTags: {
			action: actions.UpdateDocumentTagsFilterAction,
			service: this.documentTagsFilter,
		},
		usppSourceSystem: {
			action: actions.UpdateSourceSystemFilterAction,
			service: this.stockListFilter,
		},
		usppLocationFilterTree: {
			action: actions.UpdateLocationFilterAction,
			service: this.locationTreeFilter,
		},
		usppContentOwner: {
			action: actions.UpdateContentOwnerFilterAction,
			service: this.stockTreeFilter,
		},
		usppPreparedFor: {
			action: actions.UpdatePreparedForFilterAction,
			service: this.stockTreeFilter,
		},
		usppAbout: {
			action: actions.UpdateAboutFilterAction,
			service: this.stockTreeFilter,
		},
		usppAccessLevel: {
			action: actions.UpdateAccessLevelFilterAction,
			service: this.accessLevelFilter,
		},
		usppSlideMasterLayout: {
			action: actions.UpdateMasterLayoutFilterAction,
			service: this.stockTreeFilter,
		},
		FileExtension: {
			action: actions.UpdateFileTypeFilterAction,
			service: this.fileTypeFilter,
		},
		FileType: {
			action: actions.UpdateFileContentTypeFilterAction,
			service: this.fileTypeFilter,
		},
		Size: {
			action: actions.UpdateSizeFilterAction,
			service: this.stockListFilter,
		},
		Write: {
			action: actions.UpdateModifiedDateFilterAction,
			service: this.stockListFilter,
		},
		DisplayAuthor: {
			action: actions.UpdateDisplayAuthorFilterAction,
			service: this.stockListFilter,
		},
		Tags: {
			action: actions.UpdateTagsFilterAction,
			service: this.tagsFilter,
		},
	};

	constructor(
		private languagesFilter: LanguagesFilterService,
		private tagsFilter: TagsFilterService,
		private stockListFilter: StockListFilterService,
		private stockTreeFilter: StockTreeFilterService,
		private locationTreeFilter: LocationTreeFilterService,
		private fileTypeFilter: FileTypeFilterService,
		private documentTagsFilter: DocumentTagsFilterService,
		private accessLevelFilter: AccessLevelFilterService,
	) {}

	get(name: string) {
		const {action, service} =
			this.mapping[
				(filtersMapping[name as keyof typeof filtersMapping] ||
					name) as keyof typeof this.mapping
			];

		return {
			action,
			service,
		};
	}
}
