import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'hexDecode',
	standalone: true,
})
export class HexDecodePipe implements PipeTransform {
	transform(token: string): string {
		if (!token) {
			return '';
		}

		const hexString = token.replace(/["ǂ]/g, '');

		if (hexString === token) {
			return token;
		}
		const result: number[] = [];

		for (let i = 0; i < hexString.length; i += 2) {
			const code = parseInt(hexString.substr(i, 2), 16);

			if (!isNaN(code)) {
				result.push(code);
			}
		}

		const decoder = new TextDecoder('utf-8');

		return decoder.decode(new Uint8Array([...result]));
	}
}
