<shared-card-actions [size]="size">
	<shared-card-action-item
		*ppAsPrimary
		[name]="primaryIconName"
		[size]="size === 'normal' ? 64 : 60"
		(click)="triggerAction(primaryActionName, $event)"
		[hint]="primaryTitle"
		hintPosition="BOTTOM"
	>
		@if (isNotPreview) {
			<div class="action-text">{{ url | hostname }}</div>
		}
	</shared-card-action-item>
	<shared-card-action-item
		*ppAsSecondary
		name="link-18"
		[size]="size === 'normal' ? 30 : 24"
		[class.copied]="linkCopied"
		[cdkCopyToClipboard]="result.shareLink | encode"
		(cdkCopyToClipboardCopied)="triggerAction('COPY', $event)"
		hint="Copy Link"
		hintPosition="RIGHT"
	></shared-card-action-item>
	@if (result.sourceFilePath) {
		<shared-card-action-item
			*ppAsSecondary
			name="source-link-24"
			[size]="size === 'normal' ? 30 : 24"
			(click)="triggerAction('DOWNLOAD_SOURCE', $event)"
			hint="Download Source"
			hintPosition="RIGHT"
		></shared-card-action-item>
	}
	@if (showDownloadAction) {
		<shared-card-action-item
			*ppAsSecondary
			[size]="size === 'normal' ? 30 : 24"
			name="file-download-24"
			(click)="triggerAction('DOWNLOAD', $event)"
			hint="Download"
			hintPosition="RIGHT"
		></shared-card-action-item>
	}
</shared-card-actions>
