<div class="search-bar-container">
	<pp-icon name="action-search-18" [size]="18" />
	<input
		type="text"
		class="input-search"
		placeholder="Search in Filters"
		autocomplete="off"
		autocorrect="off"
		[value]="searchValue"
		(input)="applyFilterSearch($event)"
	/>
	@if (searchValue.length) {
		<button class="dp-button-null" (click)="clear()" title="Clear">
			<pp-icon name="navigation-close-18" />
		</button>
	}
</div>
