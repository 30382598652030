import {SearchResultResponse} from './search-result-response';

export class DocumentTypeMetadata {
	generateThumbnail(result: SearchResultResponse): {thumbnail?: string; fullPreview?: string} {
		return result.usppOgImage
			? this.generateExternalThumbnail(result)
			: this.generateInternalThumbnail(result.FileExtension, result.ServerRedirectedURL);
	}

	private generateExternalThumbnail(result: SearchResultResponse): IExternalThumbnail {
		return {
			thumbnail: result.usppOgImage.includes('info.epam.com')
				? `${result.usppOgImage}.rend.235x132.crop.img`
				: result.usppOgImage,
			externalThumbnail: true,
		};
	}

	private getSourceDoc(url: string): string {
		return (/sourcedoc=([^&]+)/.exec(url) || [])[1];
	}

	private generateInternalThumbnail(extension: string, url: string): IInternalThumbnail {
		const result: {thumbnail?: string; fullPreview?: string} = {};

		if (!url) {
			return result;
		}

		const {origin, pathname} = new URL(url);

		const documentPath = this.getSourceDoc(url);
		const type = this.getThumbnailType(extension);

		switch (type) {
			case 'advanced':
				result.thumbnail = `${origin}${pathname}?sourcedoc=${documentPath}&action=imagepreview`;
				result.fullPreview = url;
				break;
			case 'thumbnail':
				result.thumbnail = `${origin}${pathname}?sourcedoc=${documentPath}&action=imagepreview`;
				result.fullPreview = `${origin}${pathname}?sourcedoc=${documentPath}&action=interactivepreview`;
				break;
			case 'full':
				result.fullPreview = `${origin}${pathname}?sourcedoc=${documentPath}&action=view`;
				break;
			case 'visio':
				result.fullPreview = url;
				break;
			default:
				break;
		}

		return result;
	}

	private getThumbnailType(extension: string): string {
		const advancedPreview = ['doc', 'docx', 'dot', 'dotx', 'pdf', 'docm'];
		const thumbnailAvailable = ['ppt', 'pptx', 'pot', 'potx', 'pptm'];
		const fullPreview = ['xlsx', 'xlsm'];
		const visioPreview = ['vsdx', 'vsdm', 'vdw'];
		let result = '';

		if (advancedPreview.indexOf(extension) > -1) {
			result = 'advanced';
		} else if (thumbnailAvailable.indexOf(extension) > -1) {
			result = 'thumbnail';
		} else if (fullPreview.indexOf(extension) > -1) {
			result = 'full';
		} else if (visioPreview.indexOf(extension) > -1) {
			result = 'visio';
		}

		return result;
	}
}

interface IExternalThumbnail {
	thumbnail: string;
	externalThumbnail: boolean;
}

interface IInternalThumbnail {
	thumbnail?: string;
	fullPreview?: string;
}
