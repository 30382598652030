import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import {Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, shareReplay, switchMap} from 'rxjs/operators';

import {AsyncPipe, NgClass} from '@angular/common';
import {QuerySuggestionModel} from '@shared/models';
import {SuggestionsService} from '@shared/services';
import {getFirst} from '@shared/utils';
import {SvgIconComponent} from '@pp/svg';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'shared-autosuggest',
	standalone: true,
	imports: [AsyncPipe, NgClass, SvgIconComponent],
	templateUrl: './autosuggest.component.html',
	styleUrls: ['./autosuggest.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutosuggestComponent implements OnInit {
	private readonly debounceTime = 600;

	querySuggestions$!: Observable<QuerySuggestionModel[]>;
	selectedSuggestion?: string;

	@Input({required: true}) suggestionQuery$!: Observable<string>;
	@Output() changeItem = new EventEmitter<string>();
	@Output() submitText = new EventEmitter<string>();

	constructor(
		private suggestions: SuggestionsService,
		private analytics: AnalyticsService,
	) {}

	ngOnInit(): void {
		const maxLength = 100;

		this.querySuggestions$ = this.suggestionQuery$?.pipe(
			debounceTime(this.debounceTime),
			distinctUntilChanged(),
			switchMap((query: string) => {
				if (query && query.length < maxLength) {
					return this.suggestions.getSuggestionList$(query);
				} else {
					return of([]);
				}
			}),
			shareReplay({bufferSize: 1, refCount: true}),
		);
	}

	@HostListener('window:keyup', ['$event'])
	navigate(event: KeyboardEvent): void {
		const suggestions = getFirst(this.querySuggestions$);
		const visible = suggestions && suggestions.length > 0;

		if (visible) {
			if (event.key === 'ArrowDown') {
				this.selectSuggestionByIndex(1);
				event.preventDefault();
			}

			if (event.key === 'ArrowUp') {
				this.selectSuggestionByIndex(-1);
				event.preventDefault();
			}
		}
	}

	@HostListener('window:keydown', ['$event'])
	preventInputBehavior(event: KeyboardEvent): void {
		const suggestions = getFirst(this.querySuggestions$);
		const visible = suggestions && suggestions.length > 0;

		if (visible && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
			event.preventDefault();
		}

		if (visible && event.key === 'Enter' && this.selectedSuggestion !== undefined) {
			event.preventDefault();

			this.submitSuggestion(this.selectedSuggestion);
		}
	}

	selectSuggestionByIndex(diff: -1 | 1): void {
		const querySuggestions: QuerySuggestionModel[] = getFirst(this.querySuggestions$);
		const currentIndex = querySuggestions.findIndex(
			(suggestion) => suggestion.text === this.selectedSuggestion,
		);
		let newIndex = currentIndex + diff;

		if (newIndex >= querySuggestions.length) {
			newIndex = 0;
		} else if (newIndex < 0) {
			newIndex = querySuggestions.length - 1;
		}

		this.selectedSuggestion = querySuggestions[newIndex].text;
		this.changeItem.emit(this.selectedSuggestion);
	}

	selectSuggestionByText(text: string): void {
		this.selectedSuggestion = text;
	}

	submitSuggestion(text: string): void {
		this.analytics.trackSearchBoxAction('changeQuery', text);
		this.submitText.emit(text);
	}
}
