import {Action} from '@ngrx/store';

import {FilterModel} from '@search/models';

export const UPDATE_MODIFIED_DATE_FILTER = '[Search] Update Modified Date Filter';

export class UpdateModifiedDateFilterAction implements Action {
	readonly type = UPDATE_MODIFIED_DATE_FILTER;

	constructor(public payload: FilterModel[]) {}
}
