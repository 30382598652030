<div class="section-item-container">
	<div (click)="onAction(primaryActionInfo.actionType)" class="section-item-title">
		<pp-icon [name]="primaryActionInfo.iconName" [size]="18"></pp-icon>
		<span [class.new-item-label]="item.document.extraTags.includes('New')">{{
			item.document.title.rawValue
		}}</span>
	</div>

	<shared-dropdown
		class="section-item-dropdown-button"
		type="cancel"
		icon="navigation-more_vert-18"
		[iconOnly]="true"
		[showChevron]="false"
		openStrategy="click"
	>
		<ng-template #dropdownContent>
			<shared-card-menu-actions
				[item]="item.document"
				[actionsToExclude]="[primaryActionInfo.actionType]"
			></shared-card-menu-actions>
		</ng-template>
	</shared-dropdown>
</div>
