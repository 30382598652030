import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {PpHighlightableValue} from '@shared/api';

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'highlight',
	standalone: true,
})
export class HighlightPipe implements PipeTransform {
	transform(value: PpHighlightableValue): string {
		return value.highlightedValue ?? value.rawValue;
	}
}
