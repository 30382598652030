export enum AccessLevelType {
	EVERYONE = 'everyone',
	TRUSTED_USERS = 'verified_users',
	PRESALES_GROUPS = 'presales_groups',
	SELECTED_USERS = 'selected_users',
}

export class AccessLevel {
	static from(accessLevelLabel: string): AccessLevel {
		let accessLevelType: AccessLevelType | undefined;

		switch (accessLevelLabel) {
			case 'Everyone':
				accessLevelType = AccessLevelType.EVERYONE;
				break;
			case 'Verified users':
				accessLevelType = AccessLevelType.TRUSTED_USERS;
				break;
			case 'Presales groups':
				accessLevelType = AccessLevelType.PRESALES_GROUPS;
				break;
			case 'Selected users':
				accessLevelType = AccessLevelType.SELECTED_USERS;
				break;
			default:
				break;
		}

		return accessLevelType !== undefined
			? new AccessLevel(accessLevelType, accessLevelLabel)
			: new AccessLevel(AccessLevelType.EVERYONE, 'Unknown');
	}

	constructor(
		readonly type: AccessLevelType,
		readonly label: string,
	) {}
}
