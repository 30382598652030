@if (teams() !== undefined && !(teams() | hasTeam: 'trusted')) {
	<div class="access-info-container">
		<div class="access-info-menu-button" (click)="openAccessInfoPopover($event)">
			<pp-icon name="action-lock-fill-24" />
			@if ((viewed$ | async) === false) {
				<div class="access-info-notification">1</div>
			}
		</div>
	</div>
}
