import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
	selector: 'pp-skeleton-item',
	standalone: true,
	template: '',
	styleUrls: ['./skeleton.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonItemComponent {}
