<div>
	@if (label) {
		<header>{{ label }}</header>
	}
	@for (videoUrl of sanitizedUrls; track videoUrl) {
		<div class="video-container">
			<iframe [src]="videoUrl" allowfullscreen></iframe>
		</div>
	}
	@if (unsupportedUrls.length) {
		<div class="dp-card unsupported-section">
			@for (videoPath of unsupportedUrls; track videoPath) {
				<span
					>Not supported video Url:
					<a [href]="videoPath" target="_blank">{{ videoPath }}</a></span
				>
			}
		</div>
	}
</div>
