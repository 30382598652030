import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {TagHistoryResponse} from './tag-history-response';
import {TagHistoryModel} from '@search/models';
import {CONFIG, EnvConfigType} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class TagHistoryService {
	constructor(
		private http: HttpClient,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	getTagHistory$(filePath: string): Observable<TagHistoryModel[]> {
		const fullUrl = `${
			this.config.serviceUrl
		}/api/documents/taghistory?url=${encodeURIComponent(filePath)}`;

		return this.http
			.get<TagHistoryResponse[]>(fullUrl, {
				headers: this.prepareHeaders(),
			})
			.pipe(
				map((response) =>
					response.map(
						(item) =>
							({
								tag: item.documentTag,
								date: new Date(item.time).getTime(),
								user: item.user,
								value: item.value,
							}) as TagHistoryModel,
					),
				),
			);
	}

	private prepareHeaders(): HttpHeaders {
		const headers = new HttpHeaders({
			Accept: 'application/json',
		});

		return headers;
	}
}
