import {
	ChangeDetectionStrategy,
	Component,
	computed,
	forwardRef,
	inject,
	input,
} from '@angular/core';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {TagsSectionComponent} from '../tags-section/tags-section.component';
import {SearchFileInfoFooterComponent} from '../file-info-footer/file-info-footer.component';
import {ItemThumbnailComponent} from '../item-thumbnail/item-thumbnail.component';
import {TitleVerifiedComponent} from '../title-verified/title-verified.component';
import {HintDirective} from '@pp/hint';
import {SvgIconComponent} from '@pp/svg';
import {SearchStore} from '@search2/state';
import {ChainComponent} from '@shared/components/chain/chain.component';
import {DotDirective} from '@shared/components/chain/directives/dot.directive';
import {CollapseDirective} from '@shared/components/chain/directives/collapse.directive';
import {
	PpHighlightableValue,
	DocumentIndexItem,
	PpSearchResultItemOfDocumentIndexItem,
	DocumentItemParserService,
} from '@shared/api';
import {
	CardComponent,
	CardImageDirective,
	CardTitleDirective,
	SizeType,
	DirectionType,
	CardSubtitleComponent,
	CardFooterComponent,
	CardHeaderComponent,
} from '@shared/card';
import {
	CardActionsService,
	CardMenuActionsComponent,
	DocumentItemActionService,
	SearchItemActionType,
} from '@shared/card-actions';
import {FilterValuePipe, HighlightPipe} from '@shared/pipes';
import {UsagePermissionsComponent} from '@shared/access';
import {DropdownComponent} from '@shared/components/dropdown/dropdown.component';
import {NoteComponent} from '@shared/components/notes/note.component';

@Component({
	selector: 'search-item-card',
	templateUrl: './search-item-card.component.html',
	styleUrls: ['./search-item-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CardActionsService],
	standalone: true,
	imports: [
		CardComponent,
		ItemThumbnailComponent,
		CardImageDirective,
		CardHeaderComponent,
		forwardRef(() => TitleVerifiedComponent),
		CardTitleDirective,
		CardSubtitleComponent,
		UsagePermissionsComponent,
		NgClass,
		SvgIconComponent,
		ChainComponent,
		CollapseDirective,
		DropdownComponent,
		CardMenuActionsComponent,
		HintDirective,
		NgTemplateOutlet,
		NoteComponent,
		TagsSectionComponent,
		CardFooterComponent,
		SearchFileInfoFooterComponent,
		FilterValuePipe,
		HighlightPipe,
		DotDirective,
	],
})
export class SearchItemCardComponent {
	item = input.required<DocumentIndexItem>();
	itemType = computed(() => this.parserService.getItemType(this.item()));

	private actions = computed(() => this.actionService.getActions(this.item()));
	primaryAction = computed(() => this.actionService.getPrimaryAction(this.actions()));
	secondaryActions = computed(() => this.actionService.getSecondaryActions(this.actions()));

	size = input<SizeType>('normal');
	isCardSmall = computed(() => this.size() === 'small');
	iconSize = computed(() => (this.size() === 'normal' ? 18 : 12));

	direction = input<DirectionType>('horizontal');
	showActions = input<boolean>(true);

	lightMode = input<boolean>(false);
	// Displayed together with slides of the same source document, Show Slides/Context
	displayedInTheContext = input<boolean>(false);

	presetId = input<string>();
	query = input<string>();
	blockCardActions = input<boolean>(false);

	context = input<
		DocumentIndexItem[],
		DocumentIndexItem[] | PpSearchResultItemOfDocumentIndexItem[]
	>([], {
		transform: (documents) => {
			if (documents.length > 0 && 'document' in documents[0]) {
				return (documents as PpSearchResultItemOfDocumentIndexItem[]).map(
					(item) => item.document,
				);
			} else {
				return documents as DocumentIndexItem[];
			}
		},
	});

	private selectedAccounts = computed(() => {
		const selectedFilters = this.searchStore.searchQuery.selectedFilters();

		return selectedFilters['spo_presales.crm_account_name_paths'] ?? [];
	});

	accounts = computed(() => {
		const selectedAccounts = this.selectedAccounts();

		const allAccounts = (this.item().spoPresales?.crmAccountNames ?? []).map((account) => ({
			...account.textValue,
			facetValue: account.facetValue,
			isDisabled: selectedAccounts.includes(account.facetValue),
		}));
		allAccounts.sort((a, b) => a.facetValue.localeCompare(b.facetValue));

		return allAccounts.reduce<FilterVM[]>((acc, account) => {
			if (acc.length > 0 && account.facetValue.startsWith(acc[acc.length - 1].facetValue)) {
				acc[acc.length - 1] = account;
			} else {
				acc.push(account);
			}

			return acc;
		}, []);
	});

	itemNotes = computed(() => this.item().spoPresales?.notes);
	itemDescription = computed(() => this.item().description ?? {rawValue: ''});
	fileGeneratedData = computed(() => this.item().file?.generated);
	spoPresalesData = computed(() => this.item().spoPresales);
	industry = computed(() => {
		const spoPresalesData = this.spoPresalesData();

		if (spoPresalesData && (spoPresalesData?.crmReportingIndustry.length ?? 0) > 0) {
			return spoPresalesData.crmReportingIndustry[
				spoPresalesData.crmReportingIndustry.length - 1
			].textValue;
		} else {
			return null;
		}
	});

	private searchStore = inject(SearchStore);
	private parserService = inject(DocumentItemParserService);
	private actionService = inject(DocumentItemActionService);
	private cardActionsService = inject(CardActionsService);

	selectAccount(account: FilterVM): void {
		if (!account.isDisabled && account.facetValue) {
			this.searchStore.selectFilter(
				'spo_presales.crm_account_name_paths',
				account.facetValue,
			);
		}
	}

	onPrimaryAction(event: MouseEvent) {
		event.preventDefault();

		this.onAction(this.primaryAction());
	}

	onAction(actionType: SearchItemActionType) {
		if (this.blockCardActions()) {
			return;
		}

		this.cardActionsService.onAction(actionType, this.item(), {
			context: this.context(),
			presetId: this.presetId(),
			query: this.query(),
		});
	}
}

class FilterVM implements PpHighlightableValue {
	rawValue!: string;
	highlightedValue?: string | undefined;
	isDisabled = false;
	facetValue!: string;
}
