import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import {Observable, Subscription, combineLatest, filter, map, startWith} from 'rxjs';

import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
} from '@angular/router';
import {tap} from 'rxjs/operators';
import {toObservable} from '@angular/core/rxjs-interop';
import {SelectionPersistanceService} from '@core/services';
import {OfficeInitializationService} from '@office/services';
import {SelectionPanelComponent} from '@shared/selection';
import {NavigationService, UserProfileService} from '@shared/services';
import {UiStore} from '@shared/state';
import {ThemeService} from '@shared/theme';
import {OfficeStore} from '@office/state';

type PageType =
	| 'search'
	| 'spaces'
	| 'dashboard'
	| 'otherPage'
	| 'powerpoint'
	| 'preview'
	| 'errorPage';
const pagesWithThemeToggle: PageType[] = [
	'spaces',
	'dashboard',
	'powerpoint',
	'preview',
	'errorPage',
];
const pagesWithMobileSubheader: PageType[] = ['search', 'spaces', 'dashboard'];

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();
	private uiStore = inject(UiStore);
	private officeStore = inject(OfficeStore);

	@ViewChild('appContainerRef', {static: true}) appContainerRef?: ElementRef;

	isDeckBuilderAdviceVisible = this.uiStore.showDownloadAdvice;
	selectionPanelOverlayRef?: OverlayRef;

	routerLoading$ = this.router.events.pipe(
		filter(
			(event) =>
				event instanceof NavigationStart ||
				event instanceof NavigationEnd ||
				event instanceof NavigationCancel ||
				event instanceof NavigationError,
		),
		map((event) => event instanceof NavigationStart),
		startWith(true),
	);

	private isInsidePowerPoint$ = toObservable(this.officeStore.isInsidePowerPoint);
	pageTypeVm$: Observable<PageTypeVm> = combineLatest([
		this.router.events,
		this.isInsidePowerPoint$,
	]).pipe(
		map(([, isPowerPointReady]) => {
			let pageType: PageType;

			if (this.router.url.startsWith('/search')) {
				pageType = 'search';
			} else if (this.router.url.startsWith('/p/')) {
				pageType = 'spaces';
			} else if (this.router.url.startsWith('/preview')) {
				pageType = 'preview';
			} else if (this.router.url.startsWith('/powerpoint/') || isPowerPointReady) {
				pageType = 'powerpoint';
			} else if (this.router.url.startsWith('/dashboard')) {
				pageType = 'dashboard';
			} else if (this.router.url.startsWith('/error')) {
				pageType = 'errorPage';
			} else {
				pageType = 'otherPage';
			}

			this.updateContainerGrid(pagesWithMobileSubheader.includes(pageType));

			return {
				pageType,
				shouldThemeBeVisible: pagesWithThemeToggle.includes(pageType),
			};
		}),
	);

	isMobile = this.uiStore.window.isMobileMode;
	isNavPanelButtonVisible = false;

	constructor(
		private readonly theme: ThemeService,
		private readonly selectionPersistance: SelectionPersistanceService,
		private readonly overlay: Overlay,
		private readonly router: Router,
		private readonly navigationService: NavigationService,
		private readonly matIconRegistry: MatIconRegistry,
		private readonly domSanitizer: DomSanitizer,
		private readonly officeInitializationService: OfficeInitializationService,
		private userProfile: UserProfileService,
	) {
		this.matIconRegistry.addSvgIconResolver((name) => {
			return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${name}.svg`);
		});
	}

	ngOnInit(): void {
		this.theme.setLightMode();
		this.createSelectionPanel();

		this.subscriptions.add(this.selectionPersistance.subscribeToChanges());

		this.officeInitializationService.init();
		this.onResize();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.selectionPanelOverlayRef?.dispose();
	}

	@HostListener('window:keyup', ['$event'])
	toggleDevMode(event: KeyboardEvent): void {
		if (event.ctrlKey && event.shiftKey && event.code === 'KeyD') {
			this.uiStore.toggleDevMode();
		}
		if (event.ctrlKey && event.shiftKey && event.code === 'KeyP') {
			this.officeStore.togglePowerPointReady();
		}
		if (event.ctrlKey && event.shiftKey && event.code === 'KeyA') {
			this.subscriptions.add(
				this.userProfile
					.setUserSetting$('agreementSigned', 'false')
					.pipe(tap(() => window.location.reload()))
					.subscribe(),
			);
		}
	}

	@HostListener('window:resize')
	onResize() {
		this.uiStore.windowResizeAction(window.innerWidth, window.innerHeight);
	}

	toggleNavPanel(): void {
		this.navigationService.toggleNavPanelState();
	}

	navPanelButtonStateListener(state: boolean): void {
		this.isNavPanelButtonVisible = state;
	}

	private createSelectionPanel(): void {
		this.selectionPanelOverlayRef = this.overlay.create({
			panelClass: 'selection-overlay-panel',
		});
		const portal = new ComponentPortal(SelectionPanelComponent);
		this.selectionPanelOverlayRef.attach(portal);
	}

	private updateContainerGrid(isSubheaderExist: boolean): void {
		if (isSubheaderExist) {
			this.appContainerRef?.nativeElement.classList.add('subheader-exist');
		} else {
			this.appContainerRef?.nativeElement.classList.remove('subheader-exist');
		}
	}
}

interface PageTypeVm {
	pageType: PageType;
	shouldThemeBeVisible: boolean;
}
