import {Injectable} from '@angular/core';
import {searchInitialState, SearchQueryState} from '@search2/state';

// Renamed faced field names that we maintain for links backward compatibility
const renamedFieldNames: {[fieldName: string]: string} = {
	'spo_presales.tags': 'spo_presales.tags.keyword',
	'spo_presales.crm_reporting_industry': 'spo_presales.crm_reporting_industry.keyword',
	'spo_presales.case_study_collection': 'spo_presales.case_study_collection.keyword',
	'spo_presales.crm_account_countries': 'spo_presales.crm_account_countries.keyword',
	'spo_presales.project_code': 'spo_presales.project_code.keyword',
};

@Injectable({providedIn: 'root'})
export class SearchStateSerializerService {
	serialize(state: SearchQueryState): string {
		const o = this.toQueryObj(state);

		return JSON.stringify(o);
	}

	getQueryObject(partialState: Partial<SearchQueryState>): SearchQueryState {
		return {
			...searchInitialState.searchQuery,
			...partialState,
		};
	}

	deserialize(str?: string): SearchQueryState {
		if (!str) {
			return searchInitialState.searchQuery;
		}

		try {
			const o: QueryObject = JSON.parse(str);
			const queryState = this.fromQueryObj(o);
			const recoveredState = this.recoverQueryState(queryState);

			return {
				...searchInitialState.searchQuery,
				...recoveredState,
			};
		} catch {
			return searchInitialState.searchQuery;
		}
	}

	private recoverQueryState(state: SearchQueryState): SearchQueryState {
		const {selectedFilters} = state;
		if (!Object.keys(selectedFilters).length) return state;

		// Update facet field names in the url, for link backward compatibility
		const newSelectedFilters: {[fieldName: string]: string[]} = {};
		Object.keys(selectedFilters).forEach((key) => {
			const newKey = renamedFieldNames[key] || key;
			newSelectedFilters[newKey] = selectedFilters[key];
		});

		return {
			...state,
			selectedFilters: newSelectedFilters,
		};
	}

	private toQueryObj(state: SearchQueryState): QueryObject {
		// We do not store presetId in query object
		// because it is part of the route
		return {
			q: state.textQuery,
			o: state.orderByExpression,
			d: state.duplicateGroupId,
			f: this.normalizeFilters(state.selectedFilters),
		};
	}

	private fromQueryObj(o: QueryObject): SearchQueryState {
		return {
			presetId: 'undefined', // we expect that it will be overwritten after deserialization
			textQuery: o.q,
			orderByExpression: o.o,
			duplicateGroupId: o.d,
			selectedFilters: o.f,
		};
	}

	private normalizeFilters(filters: {[fieldName: string]: string[]}): {
		[fieldName: string]: string[];
	} {
		const normalizedFilters: {[fieldName: string]: string[]} = {};

		Object.keys(filters).forEach((fieldName) => {
			const values = filters[fieldName];

			if (values.length > 0) {
				normalizedFilters[fieldName] = values;
			}
		});

		return normalizedFilters;
	}
}

// compact query state representation in url
class QueryObject {
	q!: string;
	o?: string;
	d?: string;
	f!: {[fieldName: string]: string[]};
}
