import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'slideNumber',
	standalone: true,
})
export class SlideNumberPipe implements PipeTransform {
	transform(filePath: string): number {
		const fileName = filePath.split('/').pop() ?? '';
		const slideNumberWithExtension = fileName.split('_').pop() ?? '';
		const slideNumber = slideNumberWithExtension.split('.').reverse().pop() ?? '';

		return +slideNumber;
	}
}
