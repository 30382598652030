import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {UsagePermissionType} from './usage-permission-types.enum';
import {HintDirective} from '@pp/hint';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'shared-usage-permissions',
	templateUrl: './usage-permissions.component.html',
	styleUrls: ['./usage-permissions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HintDirective, SvgIconComponent],
})
export class UsagePermissionsComponent {
	@Input() size = 18;

	@Input()
	@HostBinding('class.with-full-theme')
	@HostBinding('isPreviewFlag')
	isPreviewFlag = false;

	@HostBinding('class') style!: string;

	@HostBinding('class.undefined-mark')
	undefinedMark = false;

	label!: string;
	icon!: string;
	message!: string;

	@Input() set value(value: string) {
		this.label = value;
		this.undefinedMark = false;

		switch (value) {
			case UsagePermissionType.CONFIDENTIAL:
				this.style = 'confidential';
				this.icon = 'action-confidential-18';
				this.message =
					'Requires the formal approval by the respective account team for any client interaction (verbal or written).';
				break;
			case UsagePermissionType.NDA:
				this.style = 'nda';
				this.icon = 'action-nda_required-18';
				this.message =
					'Requires a mutually signed Non Disclosure Agreement (NDA) between company and the prospect or client before sharing. Anonymized case studies shall be kept anonymized even with a signed NDA.';
				break;
			case UsagePermissionType.PUBLIC:
				this.style = 'public';
				this.icon = 'action-lock_open-fill-18';
				this.message =
					'Already available through public websites  and can be shared without restrictions.';
				break;
			case UsagePermissionType.STRICTLY_INTERNAL:
				this.style = 'nda';
				this.icon = 'strictly-internal-access-icon-18';
				this.message =
					'"Strictly Internal" documents are intended for use only within company and are not permitted to be shared outside of the company.';
				break;
			default:
				this.style = 'nda';
				this.icon = 'action-nda_required-18';
				this.message =
					'Unless specified otherwise in the Notes or Public Case Study Usage sections.';
				this.label = 'NDA Required';
				this.undefinedMark = true;
				break;
		}
	}
}
