@if (selectedFilters$ | async; as selectedFilters) {
	@if (selectedFilters.length) {
		<ul class="search-filters-selected-list">
			@for (filter of selectedFilters; track filter.name) {
				<li [class.include]="filter.include" [class.exclude]="!filter.include">
					@if (!filter.include) {
						<pp-icon
							class="search-filters-selected-list-icon-deselect"
							name="navigation_deselect_outline_18"
						/>
					}
					<span class="search-filters-selected-list-name">{{ filter.name }}</span>
					<button
						class="dp-button-null search-filters-selected-list-remove-filter"
						(click)="remove(filter)"
					>
						<pp-icon name="navigation_close_18" />
					</button>
				</li>
			}
		</ul>
	}
}
