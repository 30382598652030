import {Action} from '@ngrx/store';

import {SelectedFiltersState} from '../../reducers/search-state';

export const INIT_SELECTED_FILTERS = '[Search] Init Selected Filters';

export class InitSelectedFiltersAction implements Action {
	readonly type = INIT_SELECTED_FILTERS;

	constructor(public payload: SelectedFiltersState) {}
}
