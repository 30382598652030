import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	Signal,
	ViewEncapsulation,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, tap, withLatestFrom} from 'rxjs/operators';

import {AsyncPipe} from '@angular/common';
import {ResetSelectedFilterAction} from '../../actions';
import {SearchFeatureState} from '../../reducers/search-state';
import {ToggleViewModel} from './toggle.viewmodel';
import {AnalyticsService} from '@shared/analytics';
import {isLoadingFilter} from '@search/reducers/loading-filters/loading-filters.reducer';
import {LoadingFiltersState} from '@search/reducers/loading-filters/loading-filters.state';
import {AnchorDirective} from '@pp/anchor';
import {SpinnerComponent} from '@pp/spinner';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'search-filters-widget',
	templateUrl: './search-filters-widget.component.html',
	styleUrls: ['./search-filters-widget.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [AnchorDirective, SpinnerComponent, SvgIconComponent, AsyncPipe],
})
export class SearchFilterSectionComponent implements OnInit {
	expanded$!: Observable<boolean>;
	toggle$ = new BehaviorSubject<ToggleViewModel>({
		action: 'init',
		value: true,
	});
	isShowWidget$!: Observable<boolean>;
	hasSelectedFilters$!: Observable<boolean>;
	isLoading!: Signal<boolean>;

	defaultExpanded = true;

	@Input({required: true}) label!: string;
	@Input({required: true}) source!: keyof LoadingFiltersState;

	constructor(
		private store$: Store<SearchFeatureState>,
		private analytics: AnalyticsService,
	) {}

	ngOnInit(): void {
		const isSearch$ = this.store$
			.select((state) => state.search.ui.filterSearchRegex)
			.pipe(
				distinctUntilChanged(),
				map((searchString) => !!searchString),
				tap((isSearch) => {
					this.toggle$.next({
						action: 'search',
						value: isSearch ? true : this.defaultExpanded,
					});
				}),
			);

		this.expanded$ = this.toggle$.pipe(
			withLatestFrom(isSearch$),
			map(([toggle]) => {
				if (toggle.action === 'manually') {
					this.defaultExpanded = toggle.value;
				}

				this.analytics.trackFilterAction(
					toggle.value ? 'expand' : 'collapse',
					toggle.action,
				);

				return toggle.value;
			}),
			shareReplay({bufferSize: 1, refCount: true}),
		);

		const include$ = this.store$.select(
			(state) =>
				state.search.selectedFilters.include[
					this.source as keyof typeof state.search.selectedFilters.include
				],
		);
		const exclude$ = this.store$.select(
			(state) =>
				state.search.selectedFilters.exclude[
					this.source as keyof typeof state.search.selectedFilters.exclude
				],
		);

		this.isLoading = this.store$.selectSignal(isLoadingFilter(this.source));

		this.hasSelectedFilters$ = combineLatest([include$, exclude$]).pipe(
			map(
				([includeFilters, excludeFilters]) =>
					includeFilters.length > 0 || excludeFilters.length > 0,
			),
		);

		this.isShowWidget$ = combineLatest([
			this.store$.select(
				(state) => state.search.filters[this.source as keyof typeof state.search.filters],
			),
			this.hasSelectedFilters$,
		]).pipe(
			map(([filters, hasSelectedFilters]) => {
				const hasListFilters =
					Array.isArray(filters) &&
					filters.length > 0 &&
					filters.some((filter) => filter.count && +filter.count);
				const hasTreeFilters = !Array.isArray(filters) && Object.keys(filters).length > 0;

				return hasListFilters || hasTreeFilters || hasSelectedFilters;
			}),
		);
	}

	toggle(): void {
		this.toggle$.next({
			action: 'manually',
			// eslint-disable-next-line rxjs/no-subject-value
			value: !this.toggle$.getValue().value,
		});
	}

	reset(event: Event): void {
		event.stopPropagation();
		this.store$.dispatch(new ResetSelectedFilterAction(this.source));
	}
}
