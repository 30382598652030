import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {first, map, switchMap, tap} from 'rxjs/operators';

import {AsyncPipe} from '@angular/common';
import {CdkCopyToClipboard} from '@angular/cdk/clipboard';
import {ToggleQueryModificationAction} from '@search/actions';
import {SearchFeatureState} from '@search/reducers/search-state';
import {DownloadHistoryService, SearchService} from '@search/services';
import {SearchResultModel} from '@shared/models';
import {DatePipe} from '@shared/pipes';
import {
	CopyLinkService,
	DownloadService,
	ResultsService,
	rangeFiltersService,
} from '@shared/services';
import {SpinnerComponent} from '@pp/spinner';

@Component({
	selector: 'search-admin-panel',
	templateUrl: './admin-panel.component.html',
	styleUrls: ['./admin-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CdkCopyToClipboard, SpinnerComponent, AsyncPipe],
})
export class AdminPanelComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();

	loading = false;
	gridViewLink$!: Observable<string>;
	queryModificationVisible$!: Observable<boolean>;

	constructor(
		private resultsService: ResultsService,
		private searchService: SearchService,
		private downloadHistoryService: DownloadHistoryService,
		private store$: Store<SearchFeatureState>,
		private route: ActivatedRoute,
		private detector: ChangeDetectorRef,
		private datePipe: DatePipe,
		private copyLink: CopyLinkService,
	) {}

	ngOnInit(): void {
		this.gridViewLink$ = this.route.url.pipe(
			map(() => location.href.replace('/search?', '/search/grid?')),
		);
		this.queryModificationVisible$ = this.store$.select(
			(state) => state.search.ui.queryModificationVisible,
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	async downloadAll(): Promise<void> {
		const data = await this.requestData();
		let timeout = 0;

		data.forEach((result) => {
			setTimeout(() => {
				this.download(result.downloadUri);
			}, timeout * 1000);

			timeout++;
		});
	}

	async getResultsFile(): Promise<void> {
		this.loading = true;

		const result = [
			['File Path', 'Source Path', 'Import Source', 'Modified Date', 'Owner', 'Signature'],
			...(await this.requestData()).map((item) => [
				`"${encodeURI(item.filePath)}"`,
				`"${encodeURI(item.sourceFilePath || '')}"`,
				`"${encodeURI(item.importSource || '')}"`,
				`"${this.datePipe.transform(item.modifiedDate)}"`,
				!!item.owner ? `"${item.owner}"` : '',
				`${item.signature ? `"${item.signature.join(';')}"` : ''}`,
			]),
		];

		this.loading = false;
		this.detector.markForCheck();

		const file = this.createFile(result.join('\n'), 'data.csv', 'text/csv');

		this.download(file, 'data.csv');
	}

	getStoreJSON(): void {
		this.subscriptions.add(
			this.store$
				.select((state) => state)
				.pipe(
					first(),
					tap((state) => {
						const file = this.createFile(
							JSON.stringify(state),
							'store.json',
							'application/json',
						);

						this.download(file, 'store.json');
					}),
				)
				.subscribe(),
		);
	}

	getTimeRangeFile(): void {
		const monthCount = 60;
		const hiddenSection = new Set(['modifiedDate']);
		const timeRanges = this.getTimeRanges(monthCount);
		const rangeDate: string = rangeFiltersService.getDates(timeRanges).join('/');
		const customRefiner = [
			{
				id: 'modifiedDate',
				name: 'Write',
				config: `discretize=manual/${rangeDate}`,
			},
		];

		this.subscriptions.add(
			this.searchService
				.getRefinements$('lazy', hiddenSection, customRefiner)
				.pipe(
					tap((data) => {
						const rangeHeader = ['From', 'To', 'Counts'];
						const rangeData =
							data.d.postquery.PrimaryQueryResult.RefinementResults.Refiners.results[0].Entries.results
								.map((result) => ({
									token: result.RefinementToken,
									count: result.RefinementCount,
								}))
								.map(({token, count}) => {
									const dates = token
										.replace('range(', '')
										.replace(')', '')
										.split(', ')
										.map((date) => {
											const num = Date.parse(date);

											return num ? this.datePipe.transform(num) : null;
										})
										.slice(0, 2);

									return [...dates, count];
								});

						const file = this.createFile(
							[rangeHeader, ...rangeData].join('\n'),
							'time-range-data.csv',
							'text/csv',
						);

						this.download(file, 'time-range-data.csv');
					}),
				)
				.subscribe(),
		);
	}

	getDownloadsHistory(): void {
		const count = 500;

		this.loading = true;
		this.subscriptions.add(
			this.searchService
				.getSearchResults$({rowLimit: count})
				.pipe(
					map(
						(response) =>
							this.resultsService.extractData(
								response.d.postquery.PrimaryQueryResult.RelevantResults,
							).data,
					),
					switchMap((results) =>
						this.downloadHistoryService.getDocumentsDownloadHistory$(results),
					),
					map((response) =>
						response.map((item) => [
							`"${this.datePipe.transform(item.date)}"`,
							`"${item.user}"`,
							`"${item.url}"`,
						]),
					),
					tap((data) => {
						const header = ['Date', 'User', 'URL'];
						const fileContent = [header, ...data].join('\n');
						const file = this.createFile(
							fileContent,
							'downloads-history.csv',
							'text/csv',
						);

						this.download(file, 'download-history.csv');
						this.loading = false;
						this.detector.markForCheck();
					}),
				)
				.subscribe(),
		);
	}

	showQueryModification(): void {
		this.store$.dispatch(new ToggleQueryModificationAction(true));
	}

	handleCopiedLink(copied: boolean): void {
		this.copyLink.handleCopied(copied);
	}

	private async requestData(
		data: SearchResultModel[] = [],
		startFrom = 0,
	): Promise<SearchResultModel[]> {
		const rowLimit = 500;
		const totalCount = 10000;
		const result = new Promise<SearchResultModel[]>((resolve) => {
			const searchServiceResults$ =
				startFrom === 0
					? this.searchService.getSearchResults$({rowLimit})
					: this.searchService.getNextPage$(rowLimit, startFrom);

			this.subscriptions.add(
				searchServiceResults$
					.pipe(
						map((response) =>
							this.resultsService.extractData(
								response.d.postquery.PrimaryQueryResult.RelevantResults,
							),
						),
						tap(async (responseData) => {
							const combinedData = data.concat(responseData.data);

							if (
								responseData.data.length === rowLimit &&
								startFrom + rowLimit < totalCount
							) {
								resolve(await this.requestData(combinedData, startFrom + rowLimit));
							} else {
								resolve(combinedData);
							}
						}),
					)
					.subscribe(),
			);
		});

		return result;
	}

	private createFile(data: string, name: string, fileType: string): string {
		const file = new File([data], name, {type: fileType});
		const objectURL = URL.createObjectURL(file);

		return objectURL;
	}

	private getTimeRanges(monthCount: number): number[] {
		const arr = new Array(monthCount).fill(null);
		const date = new Date();

		return arr
			.map((_item, index) =>
				index !== 0 ? date.setMonth(date.getMonth() - 1) : date.setDate(1),
			)
			.reverse();
	}

	private downloadService = inject(DownloadService);
	private download(file: string, fileName?: string) {
		this.downloadService.triggerDownload(file, fileName);
	}
}
