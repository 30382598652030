import {ChangeDetectionStrategy, Component, OnDestroy, inject, computed} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {CdkCopyToClipboard} from '@angular/cdk/clipboard';
import {PreviewService} from '../../services';
import {PreviewStore} from '../preview.store';
import {DownloadOptionsComponent} from './download-options/download-options.component';
import {ButtonComponent} from '@pp/button';
import {SvgIconComponent} from '@pp/svg';
import {HintDirective} from '@pp/hint';
import {CardMenuActionsComponent, SearchItemActionType} from '@shared/card-actions';
import {CopyLinkService} from '@shared/services';
import {PopoverService} from '@shared/popover';
import {ContextualAnalyticsService} from '@shared/analytics';
import {SearchDocumentService} from '@search2/services';
import {DocumentItemParserService} from '@shared/api';
import {DropdownComponent} from '@shared/components/dropdown/dropdown.component';

@Component({
	selector: 'search-preview-actions',
	templateUrl: './preview-actions.component.html',
	styleUrls: ['./preview-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [PopoverService],
	standalone: true,
	imports: [
		HintDirective,
		SvgIconComponent,
		ButtonComponent,
		CdkCopyToClipboard,
		DropdownComponent,
		CardMenuActionsComponent,
	],
})
export class PreviewActionsComponent implements OnDestroy {
	private readonly subscriptions = new Subscription();

	private readonly previewStore = inject(PreviewStore);
	document = this.previewStore.docDetails.document;
	copyUrl = computed(() => this.previewService.getPreviewPageUrl(this.document(), true));
	isVerified = computed(() => {
		const document = this.document();

		return this.resultParser.isVerified(document) || !this.resultParser.isVerifiable(document);
	});
	isDownloadOptionsExists = computed(() => {
		const document = this.document();
		const itemType = this.resultParser.getItemType(document);

		return (
			(itemType === 'story' || itemType === 'offering') &&
			this.resultParser.getBestOfTags(document).length > 0
		);
	});
	downloadHintDescription = computed(() =>
		this.isVerified()
			? 'Download'
			: 'Unverified material without thorough review, proofreading, and polishing.',
	);

	constructor(
		private readonly copyLink: CopyLinkService,
		private readonly previewService: PreviewService,
		private readonly documentService: SearchDocumentService,
		private readonly resultParser: DocumentItemParserService,
		private readonly analytics: ContextualAnalyticsService,
		private readonly popOverService: PopoverService,
	) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	handleCopiedLink(copied: boolean): void {
		this.copyLink.handleCopied(copied);
		this.sendEvent('copy-link');
	}

	download(event?: MouseEvent) {
		event?.preventDefault();
		this.sendEvent('download');
		this.documentService.download(this.document());
	}

	sendEvent(action: SearchItemActionType): void {
		this.analytics.sendDocumentEvent(action);
	}

	openDownloadOptions(event: Event): void {
		const document = this.document();
		const downloadOptionsComponent = this.popOverService.openPopOver(
			DownloadOptionsComponent,
			{
				tagsNames: this.resultParser.getBestOfTags(document).map((tag) => tag.rawValue),
				document,
			},
			event.target as HTMLElement,
			'bottom',
		);

		this.subscriptions.add(
			downloadOptionsComponent.downloadDocumentAction
				.pipe(tap(() => this.download()))
				.subscribe(),
		);
	}
}
