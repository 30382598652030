import {Action} from '@ngrx/store';

import {FilterModel} from '@search/models';

export const UPDATE_SOURCE_SYSTEM_FILTER = '[Search] Update Source System Filter';

export class UpdateSourceSystemFilterAction implements Action {
	readonly type = UPDATE_SOURCE_SYSTEM_FILTER;

	constructor(public payload: FilterModel[]) {}
}
