import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {HintDirective} from '@pp/hint';
import {SvgIconComponent} from '@pp/svg';
import {DocumentIndexItem} from '@shared/api';
import {ViewAccessModalComponent} from '@shared/access';
import {ModalService} from '@shared/modals';
import {UserStore} from '@shared/state';
import {DatePipe, FileExtensionPipe, HostnamePipe} from '@shared/pipes';
import {HasTeamPipe} from '@core/roles';

@Component({
	selector: 'search-file-info-footer',
	templateUrl: './file-info-footer.component.html',
	styleUrls: ['./file-info-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent, HintDirective, HasTeamPipe, HostnamePipe, FileExtensionPipe],
})
export class SearchFileInfoFooterComponent {
	private readonly modalService = inject(ModalService);
	private readonly datePipe = inject(DatePipe);

	document = input.required<DocumentIndexItem>();
	lightMode = input<boolean>(false);
	isCardSmall = input<boolean>(false);

	teams = inject(UserStore).profile.teams;
	fileExtension = computed(() => this.document().file?.extension);
	isWebPageOrLink = computed(
		() => this.fileExtension() === 'url' || this.fileExtension() === undefined,
	);

	dateHint = computed(() => {
		if (this.isWebPageOrLink()) return '';
		const document = this.document();

		return document.spoPresales?.reviewDate
			? `Reviewed: ${this.datePipe.transform(document.spoPresales?.reviewDate, 'short')}`
			: `Modified: ${this.datePipe.transform(document.lastModified, 'short')}`;
	});

	openViewAccessModal(): void {
		this.modalService.openModal(ViewAccessModalComponent, {
			type: 'VIEW-ACCESS-MODAL',
			showCloseButton: false,
			data: {
				documentId: this.document().id,
			},
		});
	}
}
