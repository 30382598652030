import {rangeFiltersService} from '@shared/services';

export const filtersInitialState = {
	languages: [],
	documentTags: [],
	sourceSystem: [],
	locationFilterTree: {},
	contentOwner: {},
	preparedFor: {},
	about: {},
	fileContentType: [],
	fileType: [],
	displayAuthor: [],
	tags: [],
	size: rangeFiltersService.getSizeRanges(),
	modifiedDate: rangeFiltersService.getDateRanges(),
	accessLevel: [],
	masterLayout: {},
};
