import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {BehaviorSubject, filter, Observable, ReplaySubject, switchMap} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BreakpointsType, LayoutStrategyInterface} from '@shared/layout';

@Injectable({providedIn: 'root'})
export class ViewportStrategy implements LayoutStrategyInterface {
	private readonly destroy$ = new ReplaySubject<void>(1);
	private breakpoints$: BehaviorSubject<BreakpointsType> = new BehaviorSubject(
		[] as BreakpointsType,
	);

	set breakpoints(breakpoints: BreakpointsType) {
		this.breakpoints$.next(breakpoints);
	}

	#observer$!: Observable<BreakpointState>;

	get observer$(): Observable<BreakpointState> {
		if (!this.#observer$) {
			this.#observer$ = this.createObserver$();
		}

		return this.#observer$;
	}

	constructor(private readonly breakpointObserver: BreakpointObserver) {}

	createObserver$(): Observable<BreakpointState> {
		return this.breakpoints$.pipe(
			filter((breakpoints) => !!breakpoints.length),
			switchMap((breakpoints) => {
				return this.breakpointObserver.observe(breakpoints).pipe(takeUntil(this.destroy$));
			}),
		);
	}

	destroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
