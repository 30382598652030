import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, pairwise} from 'rxjs/operators';

import * as actions from '../actions';
import {QueryState, SearchFeatureState, SelectedFiltersState} from '../reducers/search-state';
import {StateSerializerService} from '../services';
import {SearchResultModel} from '@shared/models';
import {getFirst} from '@shared/utils';

@Injectable({providedIn: 'root'})
export class RouteEffects {
	serializeState$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(actions.SUBMIT_QUERY),
			map(() => {
				const query = getFirst(this.store$.select((state) => state.search.query));
				const selectedFilters = getFirst(
					this.store$.select((state) => state.search.selectedFilters),
				);
				const data = getFirst(this.store$.select((state) => state.search.results.data));
				const page = this.router.url.split('?').shift();

				return {query, selectedFilters, page, data};
			}),
			pairwise(),
			map(([previous, current]) => {
				const previousString = this.stateSerializer.serialize(previous);
				const currentString = this.stateSerializer.serialize(current);

				// Don't show a loader on several clicks on Reset Filters button
				if (
					!this.hasFirstLoaded(previous, current) &&
					previousString === currentString &&
					previous.page === current.page
				) {
					return new actions.SubmitQuerySuccessAction();
				}

				this.router.navigate([], {
					relativeTo: this.route,
					queryParams: {o: currentString},
					fragment: this.route.snapshot.fragment ?? undefined,
				});

				return new actions.EmptyAction();
			}),
		),
	);

	constructor(
		private actions$: Actions,
		private store$: Store<SearchFeatureState>,
		private stateSerializer: StateSerializerService,
		private router: Router,
		private route: ActivatedRoute,
	) {}

	private hasFirstLoaded(previous: QueryStateModel, current: QueryStateModel): boolean {
		return previous.data?.length === 0 && current.data?.length === 0;
	}
}

interface QueryStateModel {
	query: QueryState;
	selectedFilters: SelectedFiltersState;
	page: string | undefined;
	data: SearchResultModel[];
}
