import {Action} from '@ngrx/store';

import {QueryState} from '../../reducers/search-state';

export const INIT_QUERY = '[Search] Init Query';

export class InitQueryAction implements Action {
	readonly type = INIT_QUERY;

	constructor(public payload: QueryState) {}
}
