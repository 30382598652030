import {
	ChangeDetectionStrategy,
	Component,
	computed,
	EventEmitter,
	HostListener,
	inject,
	input,
	Output,
	Signal,
	signal,
} from '@angular/core';
import {NgIf} from '@angular/common';
import {FlagListComponent} from '../flag-list/flag-list.component';
import {PictureComponent, PictureFallbackDirective} from '../../picture';
import {HintDirective} from '@pp/hint';
import {SelectionStore} from '@shared/selection';
import {OfficeStore} from '@office/state';
import {CardActionsComponent} from '@shared/card-actions/card-actions/card-actions.component';
import {CardActionComponent} from '@shared/card-actions/card-action/card-action.component';
import {DocumentIndexItem, DocumentItemParserService} from '@shared/api';
import {CheckboxComponent} from '@pp/checkbox';
import {FlagSize} from '@pp/flag';
import {SvgIconComponent} from '@pp/svg';
import {AsPrimaryDirective, AsSecondaryDirective, SizeType} from '@shared/card';
import {FileExtensionPipe} from '@shared/pipes';
import {DocumentItemActionService, SearchItemActionType} from '@shared/card-actions';

@Component({
	selector: 'search-item-thumbnail',
	templateUrl: './item-thumbnail.component.html',
	styleUrls: ['./item-thumbnail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		PictureComponent,
		PictureFallbackDirective,
		SvgIconComponent,
		CheckboxComponent,
		CardActionsComponent,
		AsPrimaryDirective,
		CardActionComponent,
		HintDirective,
		AsSecondaryDirective,
		NgIf,
		FlagListComponent,
		FileExtensionPipe,
	],
})
export class ItemThumbnailComponent {
	item = input.required<DocumentIndexItem>();
	size = input<SizeType>('normal');
	flagSize = computed<FlagSize>(() => (this.size() === 'normal' ? 'medium' : 'small'));

	showActions = input<boolean>(true);
	displayedInTheContext = input<boolean>(false);
	@Output() itemAction = new EventEmitter<SearchItemActionType>();

	private selectionStore = inject(SelectionStore);
	private officeStore = inject(OfficeStore);
	private actionsList = computed(() =>
		this.actionService.getActions(this.item(), this.officeStore.isInsidePowerPoint()),
	);
	private primaryActionName = computed(() =>
		this.actionService.getPrimaryAction(this.actionsList()),
	);

	primaryAction = computed(() =>
		this.actionService.getActionInfo(this.primaryActionName(), this.item()),
	);

	secondaryActions = computed(() => {
		const secondaryActions = this.actionService.getSecondaryActions(this.actionsList());

		return secondaryActions.map((actionName) =>
			this.actionService.getActionInfo(actionName, this.item()),
		);
	});

	isHovered = signal(false);

	checkboxState: Signal<CheckboxStateModel> = computed(() => {
		const item = this.item();

		const isSelectable =
			this.primaryActionName() !== 'externalLink' &&
			this.primaryActionName() !== 'not-found' &&
			!this.officeStore.isInsidePowerPoint() &&
			this.selectionStore.isSelectable(item);
		const selectionNotEmpty = this.selectionStore.documentIds().length > 0;

		return {
			isCheckboxVisible:
				isSelectable &&
				(selectionNotEmpty || this.isHovered()) &&
				item.accessType !== 'notFound',
			isCheckboxSelected: this.selectionStore.documentIds().includes(item.id),
			isCheckboxDisabled: this.selectionStore.isLocked(),
		};
	});

	specialCaseFallbackIcon = computed(() => {
		switch (this.accessType()) {
			case 'notFound':
				return 'icon-basket-36';
			case 'limited':
				return 'action-confidential-18';
			default:
				return undefined;
		}
	});
	showAsHiddenSlide = computed(
		() =>
			this.displayedInTheContext() && this.itemFile()?.generated?.slide?.isVisible === false,
	);
	showSlideCount = computed(() => this.slidesCount() > 0 && !this.displayedInTheContext());
	showCheckbox = computed(() => this.showActions() && this.checkboxState().isCheckboxVisible);

	itemFile = computed(() => this.item().file);
	accessType = computed(() => this.item().accessType);
	slidesCount = computed(() => this.item()?.file?.slidesCount ?? 0);

	isCopied = false;

	constructor(
		private readonly parserService: DocumentItemParserService,
		private readonly actionService: DocumentItemActionService,
	) {}

	@HostListener('mouseenter', ['true'])
	@HostListener('mouseleave', ['false'])
	mouseEvent(opened: boolean): void {
		this.isHovered.set(opened);
	}

	onAction(event: MouseEvent, actionType: SearchItemActionType): void {
		event.preventDefault();
		this.itemAction.emit(actionType);

		if (actionType === 'copy-link') {
			this.isCopied = true;
		}
	}

	toggleSelectionState(isSelected: boolean, event: MouseEvent) {
		event.preventDefault();
		const item = this.item();

		const description = `one document: ${this.parserService.getItemType(item)}`;
		if (isSelected) {
			this.selectionStore.unselectItems([item], description);
		} else {
			this.selectionStore.selectItems([item], description);
		}
	}
}

interface CheckboxStateModel {
	isCheckboxVisible: boolean;
	isCheckboxSelected: boolean;
	isCheckboxDisabled: boolean;
}
