<div class="navigation-group-container" [class.selected]="isSelectedLinkExist && !isExpanded">
	<div class="navigation-group-header" (click)="toggleGroup()">
		<span>{{ groupName }}</span>

		<pp-icon
			class="navigation-group-header-chevron"
			[name]="isExpanded ? 'navigation-chevron-down-18' : 'navigation-chevron-right-18'"
		></pp-icon>
	</div>

	<div [class.hidden]="!isExpanded" class="navigation-group-expandable-section">
		<ng-content></ng-content>
	</div>
</div>
