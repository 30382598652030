<div
	*ppAnchor="'shared-with'; with: accessLevel.type"
	class="shared-with"
	[class.active]="popoverActive"
	(click)="openPopOver($event)"
>
	<pp-icon
		[class]="accessLevel.type"
		class="shared-with-icon-access-level"
		name="access-level-12"
	/>
	{{ accessLevel.label }}
</div>
