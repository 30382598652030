import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import {Subscription, combineLatest, tap} from 'rxjs';

import {transition, trigger, useAnimation} from '@angular/animations';
import {Router} from '@angular/router';
import {toObservable} from '@angular/core/rxjs-interop';
import {NavigationPanelComponent} from '../navigation-panel/navigation-panel.component';
import {UiStore} from '../../state';
import {insertAnimation, removeAnimation} from '../../animations';
import {AnalyticsService} from '@shared/analytics';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'shared-vertical-menu',
	templateUrl: './vertical-menu.component.html',
	styleUrls: ['./vertical-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('expandedPanel', [
			transition(':enter', useAnimation(insertAnimation)),
			transition(':leave', useAnimation(removeAnimation)),
		]),
	],
	standalone: true,
	imports: [SvgIconComponent, NavigationPanelComponent],
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();
	visible = false;

	@Output() logout = new EventEmitter<MouseEvent>();

	@ViewChild('wrapper', {read: ElementRef, static: false}) wrapperRef?: ElementRef;

	constructor(
		private analytics: AnalyticsService,
		private router: Router,
	) {}

	private readonly uiStore = inject(UiStore);
	private isMobileMode$ = toObservable(this.uiStore.window.isMobileMode);

	ngOnInit(): void {
		this.subscriptions.add(
			combineLatest([this.isMobileMode$, this.router.events])
				.pipe(
					tap(([isMobile]): void => {
						this.visible = !isMobile;
					}),
				)
				.subscribe(),
		);
	}

	ngOnDestroy(): void {
		this.visible = false;
		this.subscriptions.unsubscribe();
	}

	trackMenu(type: string): void {
		this.analytics.trackHeaderActions(type, 'vertical');
	}

	toggle(): void {
		this.visible = !this.visible;
	}

	emitLogout(event: MouseEvent): void {
		event.stopPropagation();
		this.logout.emit(event);
	}

	closeVerticalMenu(): void {
		this.visible = false;
	}
}
