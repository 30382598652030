import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {NgClass, DecimalPipe} from '@angular/common';
import {SearchFilterHeaderComponent} from '../filter-header/search-filter-header.component';
import {CheckboxComponent} from '@pp/checkbox';
import {TreeNodePayload} from '@search2/models';
import {FacetConfig} from '@shared/api';
import {regexFromString, highlightMatch} from '@shared/utils';
import {SearchStore} from '@search2/state';
import {DocumentTaxonomyService} from '@search2/services/document-taxonomy.service';
import {MultiTreeViewComponent, TreeViewItemDirective, TreeNode} from '@shared/tree';

@Component({
	selector: 'search-tree-filters',
	templateUrl: './tree-filters.component.html',
	styleUrls: ['./tree-filters.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		SearchFilterHeaderComponent,
		NgClass,
		MultiTreeViewComponent,
		TreeViewItemDirective,
		CheckboxComponent,
		DecimalPipe,
	],
})
export class TreeFiltersComponent {
	config = input.required<FacetConfig>();

	isExpanded = true;
	isShowMore = true;

	private searchStore = inject(SearchStore);
	private taxonomyService = inject(DocumentTaxonomyService);

	fieldName = computed(() => this.config().fieldName);
	private facetState = computed(() => {
		const facets = this.searchStore.facets();

		return facets[this.fieldName()] ?? [];
	});
	selectedFilters = computed(() => {
		const selectedFilter = this.searchStore.searchQuery.selectedFilters();

		return selectedFilter[this.fieldName()] ?? [];
	});

	tree = computed(() => {
		let tree = this.taxonomyService.parseTree(this.facetState(), this.config().expandByDefault);

		const regex = regexFromString(this.searchStore.facetSearchRegex());
		if (regex) {
			tree = this.searchAndHighlight(tree, regex);
		}

		return tree;
	});

	isFiltered = computed(() => {
		return regexFromString(this.searchStore.facetSearchRegex()) !== null;
	});

	itemsCount = computed(() => {
		if (this.isFiltered()) {
			return 0;
		}

		if (this.tree().children.length > this.config().showByDefault) {
			return this.tree().children.length;
		}

		return 0;
	});

	isCheckBoxTree = computed(() => {
		return this.config().isMultiSelect;
	});

	toNode(x: unknown): TreeNodePayload {
		return x as TreeNodePayload;
	}

	toggleExpand(): void {
		this.isExpanded = !this.isExpanded;
	}

	reset(): void {
		this.searchStore.resetFilters(this.fieldName());
	}

	private hasSelectedParent(
		node: TreeNodePayload,
		selectedIds: string[],
		includeSelf: boolean,
	): boolean {
		return selectedIds.some(
			(id) =>
				this.isSubNodeOrSelfId(node.id, id) && (includeSelf || id.length < node.id.length),
		);
	}

	private isSubNodeOrSelfId(id: string, parentNodeId: string): boolean {
		return id.startsWith(`${parentNodeId}|`) || id === parentNodeId;
	}

	private hasExcludedParent(node: TreeNodePayload, selectedIds: string[]): boolean {
		return selectedIds.some((id) => id.startsWith('!') && node.id.startsWith(id.substring(1)));
	}

	private hasSelectedChild(node: TreeNodePayload, selectedIds: string[]): boolean {
		return selectedIds.some((id) => id.startsWith(`${node.id}|`));
	}

	private hasExcludedChild(node: TreeNodePayload, selectedIds: string[]): boolean {
		return selectedIds.some(
			(id) => this.isSubNodeOrSelfId(id, `!${node.id}`) && id.length > node.id.length + 1,
		);
	}

	isNodeSelected(node: TreeNodePayload): boolean {
		const selectedIds = this.selectedFilters();

		return (
			this.hasSelectedParent(node, selectedIds, true) &&
			!this.hasExcludedParent(node, selectedIds) &&
			!this.hasExcludedChild(node, selectedIds)
		);
	}

	isNodePartiallySelected(node: TreeNodePayload): boolean {
		const selectedIds = this.selectedFilters();

		return (
			(!this.hasSelectedParent(node, selectedIds, false) &&
				this.hasSelectedChild(node, selectedIds)) ||
			(this.hasSelectedParent(node, selectedIds, true) &&
				this.hasExcludedChild(node, selectedIds))
		);
	}

	toggleNode(node: TreeNodePayload): void {
		const selectedIds = this.selectedFilters();
		if (this.isNodeSelected(node)) {
			// User think that he unselects node
			if (selectedIds.includes(node.id)) {
				this.searchStore.removeFilter(this.fieldName(), node.id);
			} else {
				// Tricky case, checkbox looks like selected for user, but it selected because one of parent
				this.searchStore.selectFilter(this.fieldName(), `!${node.id}`);
			}
		} else {
			// User think that he selects node
			const selectedChildren = selectedIds.filter(
				(id) =>
					this.isSubNodeOrSelfId(id, node.id) ||
					this.isSubNodeOrSelfId(id, `!${node.id}`),
			);

			if (selectedChildren.length > 0) {
				this.searchStore.removeFilterList(this.fieldName(), selectedChildren);
			}
			if (!this.hasSelectedParent(node, selectedIds, false)) {
				this.searchStore.selectFilter(this.fieldName(), node.id);
			}
		}
	}

	selectOneNode(data: TreeNode<TreeNodePayload>): void {
		if (this.isCheckBoxTree()) {
			return;
		}

		const selectedIds = this.selectedFilters();
		if (selectedIds.find((id) => id === data.payload.id)) {
			return;
		}

		const fieldName = this.fieldName();
		this.searchStore.resetFilters(fieldName);
		this.searchStore.selectFilter(fieldName, data.payload.id);
	}

	toggleShowMore(): void {
		this.isShowMore = !this.isShowMore;
	}

	getTextHtml(node: TreeNodePayload): string {
		return node.highlightedText || node.text;
	}

	private searchAndHighlight(
		node: TreeNode<TreeNodePayload>,
		regex: RegExp,
	): TreeNode<TreeNodePayload> {
		const highlightedText = regex.test(node.payload.text)
			? node.payload.text.replace(regex, highlightMatch)
			: undefined;
		const children = node.children
			.map((child) => this.searchAndHighlight(child, regex))
			.filter((child) => child.payload.highlightedText || child.children.length);

		return {
			...node,
			expandedInitialState: children.length > 0,
			payload: {
				...node.payload,
				highlightedText,
			},
			children,
		};
	}
}
