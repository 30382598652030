<ng-content select="ppCardImage, [ppCardImage]"></ng-content>
<section class="content">
	<div class="content-header-container">
		<ng-content select="shared-card-header"></ng-content>
		<ng-content select="[actionsMenu]"></ng-content>
	</div>
	<ng-content select="pp-divider.header"></ng-content>
	<ng-content select="shared-card-body"></ng-content>
	<shared-card-body>
		<ng-content></ng-content>
	</shared-card-body>
	<ng-content select="pp-divider.footer"></ng-content>
	<ng-content select="shared-card-footer"></ng-content>
</section>
