import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {SearchOrderByComponent} from '../order-by/search-order-by.component';
import {ButtonComponent} from '@pp/button';
import {SearchPresetDefinition, SortOrderConfig} from '@shared/api';
import {MailTo} from '@shared/models';
import {AnalyticsService} from '@shared/analytics';
import {SearchStore} from '@search2/state';
import {AnchorDirective} from '@pp/anchor';
import {HintDirective} from '@pp/hint';

@Component({
	selector: 'search-settings-buttons',
	templateUrl: './settings-buttons.component.html',
	styleUrls: ['./settings-buttons.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonComponent, HintDirective, AnchorDirective, SearchOrderByComponent],
})
export class SettingsButtonsComponent {
	currentPreset = input.required<SearchPresetDefinition>();

	private searchStore = inject(SearchStore);
	private analytics = inject(AnalyticsService);
	isGridViewPreferred = this.searchStore.isGridViewPreferred;

	orderByValue = computed(() => {
		const query = this.searchStore.searchQuery();
		const preset = this.currentPreset();

		const orderBy = preset.sortOrderConfigs.find(
			(config) => config.orderByExpression === query.orderByExpression,
		);

		return orderBy || preset.sortOrderConfigs[0];
	});

	submitMailTo: MailTo = {
		to: 'SupportPresalesStories.com',
		subject: 'Add new Presales Story',
		body: '<please attach story presentation>',
	};

	trackAnalytics(label: string): void {
		this.analytics.trackDocumentCatalogAction('open:link', label);
	}

	changeOrderBy(value: SortOrderConfig): void {
		this.searchStore.changeSortOrder(value.orderByExpression);
	}

	switchView(): void {
		this.searchStore.toggleGridViewPreferences();
	}
}
