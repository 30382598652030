import {Highlightable} from '@angular/cdk/a11y';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Output,
} from '@angular/core';

@Component({
	selector: 'shared-option',
	templateUrl: './option.component.html',
	styleUrls: ['./option.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class OptionComponent implements Highlightable {
	@HostBinding('class.active') active = false;

	@Output()
	readonly selectionChange = new EventEmitter<OptionSelectionChangedEvent>();

	constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

	@HostListener('click', ['$event'])
	onSelect(event: MouseEvent): void {
		this.select();
		event.preventDefault();
	}

	@HostListener('keydown', ['$event'])
	onKeydown(event: KeyboardEvent): void {
		if (['Enter', 'Space'].includes(event.key)) {
			this.select();
			event.preventDefault();
		}
	}

	select(): void {
		this.selectionChange.emit({source: this, isSelected: true});
	}

	deselect(): void {
		this.selectionChange.emit({source: this, isSelected: false});
	}

	setActiveStyles(): void {
		if (!this.active) {
			this.active = true;
			this.changeDetectorRef.markForCheck();
		}
	}

	setInactiveStyles(): void {
		if (this.active) {
			this.active = false;
			this.changeDetectorRef.markForCheck();
		}
	}
}

export interface OptionSelectionChangedEvent {
	source: OptionComponent;
	isSelected: boolean;
}
