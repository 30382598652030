import {Action} from '@ngrx/store';

import {SelectedFiltersPayload} from './selected-filters-payload';

export const REPLACE_INCLUDE_FILTER = '[Search] Replace Include Filter';

export class ReplaceIncludeFilterAction implements Action {
	readonly type = REPLACE_INCLUDE_FILTER;

	constructor(public payload: SelectedFiltersPayload) {}
}
