<shared-card [class]="['search-card', direction()]" [direction]="direction()" [size]="size()">
	<search-item-thumbnail
		ppCardImage
		[item]="item()"
		[size]="size()"
		[showActions]="showActions()"
		[displayedInTheContext]="displayedInTheContext()"
		(itemAction)="onAction($event)"
		(click)="size() === 'small' ? onPrimaryAction($event) : null"
	></search-item-thumbnail>
	<shared-card-header>
		<search-title-verified
			(click)="onPrimaryAction($event)"
			ppCardTitle
			[setCardTitleClass]="itemType() !== 'deleted'"
			[item]="item()"
		></search-title-verified>
		<shared-card-subtitle>
			@switch (itemType()) {
				@case ('case') {
					<shared-usage-permissions
						[value]="spoPresalesData()!.usagePermissions"
						[size]="iconSize()"
					></shared-usage-permissions>
					<div class="search-card-accounts">
						<div class="search-card-accounts-list">
							@for (account of accounts(); track account.rawValue) {
								<div
									class="search-card-accounts-item"
									[ngClass]="{disabled: account.isDisabled}"
									(click)="selectAccount(account)"
								>
									<pp-icon
										name="content-account-18"
										[size]="iconSize()"
									></pp-icon>
									<span [innerHTML]="account | highlight"></span>
								</div>
							}
						</div>
					</div>
					@if (direction() === 'horizontal' && !isCardSmall() && industry()) {
						<div
							class="search-card-industry"
							[innerHTML]="industry()! | highlight"
						></div>
					}
				}
				@case ('offering') {
					<shared-chain dot>
						@for (category of spoPresalesData()?.storyCategory; track category) {
							<span>{{ category }}</span>
						}
					</shared-chain>
					<shared-chain>
						@for (owner of item().owners; track owner.facetValue) {
							<span class="owner">
								<pp-icon name="social-person-18" [size]="12"></pp-icon>
								{{ owner.textValue | highlight }}
							</span>
						}
					</shared-chain>
				}
				@case ('toolbox') {
					<shared-chain *collapse="true">
						@for (owner of item().owners; track owner.facetValue) {
							<span class="owner">
								<pp-icon name="social-person-18" [size]="12"></pp-icon>
								{{ owner.textValue | highlight }}
							</span>
						}
					</shared-chain>
				}
				@case ('limited-access') {
					<span>Access is denied.</span>
				}
				@case ('deleted') {
					<span>This document has been deleted.</span>
				}
				@default {
					@if (!displayedInTheContext && fileGeneratedData()?.sourceFileName) {
						<span>
							<span>Slide #{{ fileGeneratedData()?.slide?.number }} from&nbsp;</span>
							<span
								class="show-context-link"
								title="Show context"
								(click)="onAction('show-slides')"
								>{{ fileGeneratedData()?.sourceFileName }}</span
							>
						</span>
					}
				}
			}
		</shared-card-subtitle>
	</shared-card-header>

	@if (showActions()) {
		<div actionsMenu>
			<shared-dropdown
				class="search-card-actions-menu"
				type="link"
				icon="navigation-more_vert-18"
				[iconOnly]="true"
				[showChevron]="false"
				openStrategy="click"
			>
				<ng-template #dropdownContent>
					<shared-card-menu-actions
						[item]="item()"
						[actionsToExclude]="
							size() === 'small'
								? [primaryAction()]
								: secondaryActions().concat(primaryAction())
						"
					></shared-card-menu-actions>
				</ng-template>
			</shared-dropdown>
		</div>
	}

	@if (!!itemDescription().highlightedValue) {
		<div
			[innerHTML]="itemDescription().highlightedValue"
			class="search-card-highlights-content"
		></div>
	} @else {
		@if (!lightMode() && itemDescription().rawValue) {
			<div
				class="tool-box-description"
				[hint]="descriptionText"
				maxWidth="246px"
				hintPosition="BOTTOM"
				[hintDelay]="500"
				[hintTriangle]="true"
			>
				<ng-container [ngTemplateOutlet]="descriptionText"></ng-container>
			</div>
		}
		<ng-template #descriptionText>
			<span [innerHTML]="itemDescription().rawValue"></span>
		</ng-template>
	}
	<ng-template #description>
		@if (!lightMode() && itemDescription().rawValue) {
			<div
				class="tool-box-description"
				[hint]="descriptionText"
				maxWidth="246px"
				hintPosition="BOTTOM"
				[hintDelay]="500"
				[hintTriangle]="true"
			>
				<ng-container [ngTemplateOutlet]="descriptionText"></ng-container>
			</div>
		}
		<ng-template #descriptionText>
			<span [innerHTML]="itemDescription().rawValue"></span>
		</ng-template>
	</ng-template>

	@switch (itemType()) {
		@case ('case') {
			@if (!lightMode() && !isCardSmall() && !!itemNotes()) {
				<shared-note [text]="itemNotes()!"></shared-note>
			}
			@if (!lightMode()) {
				<search-tags-section
					[item]="item()"
					[isCardSmall]="isCardSmall()"
					[iconSize]="iconSize()"
				></search-tags-section>
			}
		}
		@case ('story') {
			@if (!lightMode() && direction() === 'horizontal') {
				<search-tags-section
					[item]="item()"
					[isCardSmall]="isCardSmall()"
					[iconSize]="iconSize()"
				></search-tags-section>
			}
		}
		@case ('offering') {
			@if (!lightMode() && !isCardSmall() && !!itemNotes()) {
				<shared-note [text]="itemNotes()!"></shared-note>
			}
			@if (!lightMode() && direction() === 'horizontal') {
				<search-tags-section
					[item]="item()"
					[isCardSmall]="isCardSmall()"
					[iconSize]="iconSize()"
				></search-tags-section>
			}
		}
	}

	<shared-card-footer class="date date-horizontal">
		<search-file-info-footer
			[document]="item()"
			[lightMode]="lightMode() || direction() !== 'horizontal'"
			[isCardSmall]="isCardSmall()"
		></search-file-info-footer>
	</shared-card-footer>
</shared-card>
