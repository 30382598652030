import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';

import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'shared-query-samples',
	templateUrl: './query-samples.component.html',
	styleUrls: ['./query-samples.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class QuerySamplesComponent {
	@Output() send = new EventEmitter<string>();

	constructor(private analytics: AnalyticsService) {}

	search(query: string): void {
		this.send.emit(query);
		this.analytics.trackSearchQueryTipsActions('samples', query);
	}
}
