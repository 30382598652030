import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	AfterViewInit,
	TemplateRef,
	ViewChild,
	Output,
	EventEmitter,
	inject,
} from '@angular/core';
import {NgTemplateOutlet} from '@angular/common';
import {UseThemeDirective} from '../../../directives';
import {UserStore} from '@shared/state';

@Component({
	selector: 'shared-dropdown-container',
	templateUrl: './dropdown-container.component.html',
	styleUrls: ['./dropdown-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [UseThemeDirective, NgTemplateOutlet],
})
export class DropdownContainerComponent implements AfterViewInit {
	@Input() dropdownContentTemplate!: TemplateRef<unknown>;
	@Output() closeDropDownEvent = new EventEmitter<void>();

	@ViewChild('dropdownPortalContainer') dropdownPortalContainer?: ElementRef;

	theme = inject(UserStore).settings.uiTheme;

	ngAfterViewInit() {
		this.dropdownPortalContainer?.nativeElement.focus();
	}

	containerClick(): void {
		this.closeDropDownEvent.emit();
	}

	clickOutside(): void {
		this.closeDropDownEvent.emit();
	}
}
