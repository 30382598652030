import {Injectable, inject} from '@angular/core';
import {SearchFacetTreeModel, TreeNodePayload} from '@search2/models';
import {PpFacetResult, DocumentIndexItem, PpSearchResultItemOfDocumentIndexItem} from '@shared/api';
import {FilterValuePipe} from '@shared/pipes';
import {TreeNode} from '@shared/tree';

@Injectable({providedIn: 'root'})
export class DocumentTaxonomyService {
	private filterValuePipe = inject(FilterValuePipe);

	parseTree(filters: PpFacetResult[], expandedInitialState = true): TreeNode<TreeNodePayload> {
		const treeRoot: TreeNode<TreeNodePayload> = {
			payload: {id: 'root', text: 'root', count: 0},
			children: [],
			expandedInitialState,
			parent: undefined,
		};

		for (const facetResult of filters) {
			const path = facetResult.value.split('|');
			if (path.length > 0 && this.filterValuePipe.isGuid(path[path.length - 1])) {
				path.pop();
			}

			let root = treeRoot;
			for (const nodeValue of path) {
				let child = root.children.find((el) => el.payload.text === nodeValue);
				if (!child) {
					child = {
						payload: {
							id: facetResult.value,
							text:
								nodeValue === path[path.length - 1] ? facetResult.text : nodeValue,
							count: 0,
						},
						children: [],
						expandedInitialState,
						parent: root.payload,
					};
					root.children.push(child);
				}
				root = child;
			}

			root.payload.id = facetResult.value;
			root.payload.count = facetResult.count;
		}

		return treeRoot;
	}

	getValues(item: DocumentIndexItem, fieldName: string): string[] {
		switch (fieldName) {
			case 'spo_presales.story_taxonomy':
				return item.spoPresales?.storyTaxonomy ?? [];
			case 'spo_presales.toolbox_taxonomy':
				return item.spoPresales?.toolboxTaxonomy ?? [];
			default:
				return [];
		}
	}

	processFacetTree(
		root: TreeNode<TreeNodePayload>,
		resultItems: PpSearchResultItemOfDocumentIndexItem[],
		groupByFieldName: string,
		selectedFilters: string[] | undefined,
	): SearchFacetTreeModel {
		const map = new Map<string, PpSearchResultItemOfDocumentIndexItem[]>();
		resultItems.forEach((item) => {
			const leafs = this.getItemTreeLeafs(item.document, groupByFieldName);
			leafs.forEach((value) => {
				const docs = map.get(value) || [];
				docs.push(item);
				map.set(value, docs);
			});
		});

		function processNode(node: TreeNode<TreeNodePayload>): SearchFacetTreeModel {
			const children = node.children.map(processNode);

			let isVisible = true;
			if (selectedFilters && selectedFilters.length > 0) {
				isVisible = selectedFilters.some(
					(id) =>
						node.payload.id.startsWith(id) || // current node has selected parent node
						id.startsWith(node.payload.id), // current node has selected child node
				);
			}
			if (!isVisible) {
				return {
					name: node.payload.text,
					items: [],
					children,
					count: 0,
				};
			}

			const items = map.get(node.payload.id) || [];

			return {
				name: node.payload.text,
				items,
				children,
				count: items.length + children.reduce((sum, child) => sum + child.count, 0),
			};
		}

		return processNode(root);
	}

	private getItemTreeLeafs(document: DocumentIndexItem, fieldName: string): string[] {
		const taxonomyValues = [...this.getValues(document, fieldName)];

		// array of merged taxonomy, return only most nested subcategories and category without subcategories
		// ex. ['Practices', 'Practices|Intelligent Automation', 'General Decks'] => ['Practices|Intelligent Automation', 'General Decks']
		taxonomyValues.sort((a, b) => a.localeCompare(b));

		return taxonomyValues.reduce<string[]>((acc, taxonomyValue) => {
			if (acc.length > 0 && taxonomyValue.startsWith(acc[acc.length - 1])) {
				acc[acc.length - 1] = taxonomyValue;
			} else {
				acc.push(taxonomyValue);
			}

			return acc;
		}, []);
	}
}
