<div class="search-filters-search-bar">
	<pp-icon name="action-search-18" />
	<input
		type="text"
		class="input-search"
		placeholder="Search in Filters"
		autocomplete="off"
		autocorrect="off"
		[value]="filterSearch$ | async"
		(input)="applyFilterSearch($event)"
		(blur)="blur($event)"
	/>
	@if (showClearButton$ | async) {
		<button class="dp-button-null" (click)="clear()" title="Clear">
			<pp-icon name="navigation-close-18" />
		</button>
	}
</div>
