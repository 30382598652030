import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	inject,
	input,
	signal,
} from '@angular/core';

import {firstValueFrom} from 'rxjs';
import {SearchItemCardComponent} from '../../../preview/item-card/item-card/search-item-card.component';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';
import {PpSearchResultItemOfDocumentIndexItem} from '@shared/api';
import {SearchQueryService} from '@search2/services';
import {CardsSwiperComponent} from '@shared/card-containers';

@Component({
	selector: 'search-preset-ad',
	templateUrl: './preset-ad.component.html',
	styleUrls: ['./preset-ad.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () =>
				new ContextualAnalyticsService(inject(AnalyticsService), 'Featured Content'),
		},
	],
	standalone: true,
	imports: [CardsSwiperComponent, SearchItemCardComponent],
})
export class PresetAdComponent {
	query = input.required<string>();
	presetId = input<string>('sp-suggest');

	title = computed(() => {
		return this.query() === ''
			? 'Featured Content'
			: `Results for "${this.query()}" from Featured Content`;
	});

	items = signal<PpSearchResultItemOfDocumentIndexItem[]>([]);

	constructor(
		private readonly searchService: SearchQueryService,
		private analytics: AnalyticsService,
	) {
		effect(() => {
			const ppQuery = this.searchService.createQuery({
				presetId: this.presetId(),
				queryText: this.query(),
				size: 10,
			});

			firstValueFrom(this.searchService.search$(ppQuery)).then((resp) => {
				this.items.set(resp.isQueryRelaxed ? [] : resp.results);
			});
		});
	}

	track(label: string): void {
		this.analytics.trackFeaturedContentAction('storiesAdNavigation', label);
	}
}
