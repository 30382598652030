<div class="edit-links-container">
	<div class="edit-links-dialog" cdkDrag cdkDragBoundary=".edit-links-container">
		<div class="edit-links-dialog-content">
			<header class="edit-links-header" cdkDragHandle>
				Edit Useful Links
				<pp-icon (click)="closeDialog()" name="navigation-close-36" />
			</header>
			<main class="edit-links-content">
				<ul
					class="edit-links-list"
					cdkDropList
					(cdkDropListDropped)="drop(links(), $event)"
				>
					@if (getLinksFormArray(linksGroup()).length) {
						<li class="edit-links-item">
							<span class="edit-links-label url">URL</span>
							<span class="edit-links-label title">LINK TEXT</span>
						</li>

						@for (
							link of getLinksFormArray(linksGroup());
							track link.value.url;
							let i = $index
						) {
							<li
								class="edit-links-item"
								[ngClass]="{'light-theme': theme() === 'light'}"
								[formGroup]="link"
								cdkDrag
							>
								<div class="edit-links-drag">
									<pp-icon name="action-drag_indicator-18" cdkDragHandle />
								</div>
								<input
									#urlInput
									class="edit-links-input url-input"
									spellcheck="false"
									autocomplete="off"
									autocorrect="off"
									formControlName="url"
									placeholder="https://"
									(blur)="updateUrlIfChanged(links()[i], link.get('url'))"
								/>
								<input
									class="edit-links-input title-input"
									spellcheck="false"
									autocomplete="off"
									autocorrect="off"
									placeholder="Link Text"
									formControlName="linkTitle"
									(blur)="updateTitleIfChanged(links()[i], link.get('linkTitle'))"
								/>
								<pp-icon
									(click)="openDeleteConfirmation($event, links()[i])"
									class="edit-links-remove"
									name="action-delete-24"
								/>
							</li>
						}
					} @else {
						<span class="no-results">Nothing here yet</span>
					}
				</ul>
			</main>

			<div class="edit-links-item-divider"></div>

			<form class="edit-links-creation-section" (submit)="addNew($event)">
				<div class="input-section">
					<pp-icon
						class="input-section-icon"
						[size]="18"
						name="content-plus-24"
					></pp-icon>
					<input
						class="edit-links-input"
						spellcheck="false"
						[formControl]="newLinkUrl"
						autocomplete="off"
						autocorrect="off"
						placeholder="Enter link URL"
					/>

					@if (!newLinkUrl.valid) {
						<span class="invalid-message">The provided link URL is invalid.</span>
					}
				</div>
				<button
					class="dp-button edit-links-add-new-button"
					(click)="addNew()"
					type="button"
					[disabled]="!newLinkUrl.valid"
				>
					Add
				</button>
			</form>
		</div>
	</div>
</div>
