import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
	selector: 'shared-chain',
	templateUrl: './chain.component.html',
	styleUrls: ['./chain.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class ChainComponent {}
