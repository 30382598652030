<div class="document-images-container" (scroll)="scroll($event.target)">
	<div class="document-images-content">
		@if (images.length > 0) {
			@for (hash of images; track hash) {
				<div class="document-images-item-container">
					<a
						class="document-images-hash"
						[routerLink]="['/search']"
						[queryParams]="{o: getQueryParams(hash)}"
						(click)="triggerSearch($event, hash)"
						>{{ hash }}</a
					>
					<img [src]="hash | image" />
				</div>
			}
		} @else {
			<div class="document-images-no-data">This document doesn't have related images.</div>
		}
	</div>
</div>
