import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'new',
	standalone: true,
})
export class NewPipe implements PipeTransform {
	transform(date: number | string): boolean {
		const days = 30 * 24 * 60 * 60 * 1000;
		const dateNum = new Date(date).valueOf();

		return new Date().valueOf() - dateNum < days;
	}
}
