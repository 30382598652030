import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
	selector: 'pp-image',
	standalone: true,
	template: '<img [style.width.px]="width" [style.height.px]="height" [src]="src" />',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgImageComponent {
	src = '';

	@Input({required: true})
	set name(name: string) {
		this.src = `assets/images/${name}.svg`;
	}

	@Input({required: true})
	width = 0;
	@Input({required: true})
	height = 0;
}
