<div
	class="navigation-panel-container opened"
	ppCdkUseTheme="dark"
	[style.height]="height()"
	[class.opened]="isPanelOpened()"
	(mouseenter)="shouldListenHover ? openPanel() : null"
	(mouseleave)="shouldListenHover ? closePanel() : null"
>
	<div class="navigation-panel-content">
		<div class="navigation-panel-items">
			@for (nav of navigationTree(); track nav.name) {
				@if (nav.link) {
					<a
						class="navigation-panel-items-link"
						[routerLinkActive]="'selected'"
						[routerLink]="nav.link"
						(click)="trackAnalytics(nav.name)"
					>
						<pp-icon class="link-logo" [name]="nav.icon ?? ''" [size]="18"></pp-icon>
						<span>{{ nav.name }}</span>
					</a>
				} @else {
					@if (nav.items) {
						<shared-navigation-groups-container
							[groupIcon]="nav.icon ?? ''"
							[title]="nav.name"
							[isNew]="nav.isNew"
							[items]="nav.items"
							[isPanelOpened]="isPanelOpened()"
							(togglePanelEvent)="togglePanel($event)"
						>
						</shared-navigation-groups-container>
					}
				}
			}
		</div>
	</div>
</div>
