import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	inject,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {CdkCopyToClipboard} from '@angular/cdk/clipboard';
import {HostnamePipe} from '../../pipes/hostname.pipe';
import {FileSizePipe} from '../../pipes/file-size.pipe';
import {EncodePipe} from '../../pipes/encode.pipe';
import {CardActionItemComponent} from '../card-action-item/card-action-item.component';
import {AsPrimaryDirective, AsSecondaryDirective, SizeType} from '@shared/card';
import {HintDirective} from '@pp/hint';
import {OpeningMethods} from '@shared/enums/opening-methods.enum';

import {SearchResultModel, ThumbnailAction} from '@shared/models';
import {AnalyticsService} from '@shared/analytics';
import {CardActionsComponent} from '@shared/card-actions';

@Component({
	selector: 'shared-result-overlay',
	templateUrl: './result-overlay.component.html',
	styleUrls: ['./result-overlay.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CardActionsComponent,
		AsPrimaryDirective,
		CardActionItemComponent,
		HintDirective,
		AsSecondaryDirective,
		CdkCopyToClipboard,
		EncodePipe,
		HostnamePipe,
	],
})
export class ResultOverlayComponent implements OnDestroy, OnInit {
	private readonly subscriptions = new Subscription();

	linkCopied = false;
	primaryActionName!: ThumbnailAction;

	@Input({required: true}) result!: SearchResultModel;
	@Input() size: SizeType = 'normal';
	@Output() triggerActionEmitter = new EventEmitter<ThumbnailAction>();

	get isNotPreview(): boolean {
		return !this.result.isOur && this.result.openingMethod !== OpeningMethods.PREVIEW;
	}

	get url(): string {
		return this.result.fileExtension === 'page' && this.result.importSource !== ''
			? this.result.importSource ?? ''
			: this.result.filePath;
	}

	get primaryIconName(): string {
		switch (this.result.openingMethod) {
			case OpeningMethods.EXTERNAL:
				return 'external-link-24';
			case OpeningMethods.PREVIEW:
				return 'action-zoom-64';
			case OpeningMethods.DOWNLOAD:
			default:
				return 'file-download-24';
		}
	}

	get primaryTitle(): string {
		switch (this.result.openingMethod) {
			case OpeningMethods.EXTERNAL:
				return 'Open in new tab';
			case OpeningMethods.PREVIEW:
				return 'Show Preview';
			case OpeningMethods.DOWNLOAD:
			default:
				return `Download File ${this.fileSize.transform(this.result.fileSize)}`;
		}
	}

	get showDownloadAction(): boolean {
		return (
			this.result.downloadAvailable && this.result.openingMethod !== OpeningMethods.DOWNLOAD
		);
	}

	private readonly analytics = inject(AnalyticsService);
	private readonly fileSize = inject(FileSizePipe);
	private readonly hostname = inject(HostnamePipe);

	ngOnInit() {
		this.primaryActionName =
			this.result.openingMethod === OpeningMethods.DOWNLOAD ? 'DOWNLOAD' : 'PREVIEW';
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	triggerAction(action: ThumbnailAction, event: unknown): void {
		switch (action) {
			case 'COPY':
				this.linkCopied = !!event;
				this.analytics.trackSearchResultsAction('copyLink');
				break;
			case 'PREVIEW': {
				if (this.result.openingMethod === OpeningMethods.EXTERNAL) {
					this.analytics.trackSearchResultsAction(
						'openExternal',
						this.hostname.transform(this.url),
					);
				}
				break;
			}
			default:
				break;
		}
		this.triggerActionEmitter.emit(action);

		if (event instanceof MouseEvent) {
			event.stopImmediatePropagation();
			event.preventDefault();
		}
	}
}
