import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'relativeDate',
	standalone: true,
})
export class RelativeDatePipe implements PipeTransform {
	private readonly day = 86400000;

	transform(date: string | undefined): string {
		if (!date) {
			return '';
		}

		const lastModified = Math.floor(
			(new Date().valueOf() - new Date(date).valueOf()) / this.day,
		);
		let result = '';

		if (lastModified === 0) {
			result = 'today';
		} else if (lastModified === 1) {
			result = 'yesterday';
		} else if (lastModified < 30) {
			result = `${lastModified} days ago`;
		} else if (lastModified < 60) {
			result = 'a month ago';
		} else if (lastModified < 365) {
			result = `${Math.floor(lastModified / 30)} months ago`;
		} else if (lastModified < 2 * 365) {
			result = 'a year ago';
		} else {
			result = `${Math.floor(lastModified / 365)} years ago`;
		}

		return result;
	}
}
