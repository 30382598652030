import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SvgIconComponent} from '@pp/svg';
import {AppliedFilterModel} from '@search/models';
import {HexDecodePipe} from '@shared/pipes';

@Component({
	selector: 'search-applied-filter',
	templateUrl: './search-applied-filter.component.html',
	styleUrls: ['./search-applied-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent, HexDecodePipe],
})
export class SearchAppliedFilterComponent {
	@Input() hasCloseButton = true;
	@Input({required: true}) appliedFilterModel!: AppliedFilterModel;

	@Output() removeEmitter = new EventEmitter();
}
