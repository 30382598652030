import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {NgClass} from '@angular/common';
import {SearchItemCardComponent} from '../../../../preview/item-card/item-card/search-item-card.component';
import {CardListComponent} from '@shared/card-containers';
import {PpSearchResultItemOfDocumentIndexItem} from '@shared/api';

@Component({
	selector: 'search-results-list',
	templateUrl: './search-results-list.component.html',
	styleUrls: ['./search-results-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, CardListComponent, SearchItemCardComponent],
})
export class SearchResultsListComponent {
	items = input.required<PpSearchResultItemOfDocumentIndexItem[]>();

	presetId = input<string>();
	query = input<string>();
	isVerticalPreferred = input.required<boolean>();

	cardIdentity(_index: number, item: PpSearchResultItemOfDocumentIndexItem) {
		return item.document.id;
	}

	constructor() {}
}
