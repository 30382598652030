<home-preset-section
	header="Marketing"
	description="Recently created Marketing data"
	[query]="marketingQuery"
	[verified]="false"
></home-preset-section>
<home-preset-section
	header="Sales"
	description="Recently created Sales"
	[query]="salesQuery"
	[verified]="true"
></home-preset-section>
<home-preset-section
	header="Archive"
	description="Recently created Archives"
	[query]="archivesQuery"
	[verified]="true"
></home-preset-section>

@if (!config.serviceless) {
	@if (recommendedDeck$ | async; as results) {
		@if (results.items.length) {
			<search-cards-swiper [items]="results.items" [hideBackground]="true">
				<ng-container header>
					<header class="whats-new-section-header">
						<div class="whats-new-section-header-title">
							<h3>Best Proposals</h3>

							<pp-icon
								name="notification-info-outline-18"
								[hint]="
									'Featured proposals and client presentations you should know about'
								"
							></pp-icon>
						</div>

						<a
							class="whats-new-section-header-link"
							[routerLink]="'/search'"
							[queryParams]="{o: recommendedDecksSearchQuery}"
							target="_blank"
							(click)="trackShowMore('Best Proposals')"
						>
							Show all ({{ results.totalCount | format }})
							<pp-icon name="navigation-chevron-right-18"></pp-icon>
						</a>
					</header>
				</ng-container>
				<ng-template #itemTemplate let-item>
					<ng-template
						[ngTemplateOutlet]="dashboardCards"
						[ngTemplateOutletContext]="{$implicit: item}"
					>
					</ng-template>
				</ng-template>
			</search-cards-swiper>
		}
	}

	<div class="whats-new-container">
		@for (section of separator; track section.header) {
			<div class="whats-new-column">
				@if (section.data$ | async; as results) {
					@if (results.data.length) {
						<div class="whats-new-header">
							<h3>{{ section.header }}</h3>

							@if (section.description) {
								<pp-icon
									name="notification-info-outline-18"
									[hint]="section.description"
								></pp-icon>
							}
						</div>
						<ul class="whats-new-list">
							@for (result of results.data; track result.filePath) {
								<li>
									<pp-icon
										[name]="getResultIcon(result)"
										class="whats-new-list-icon"
										[size]="18"
									></pp-icon>
									<div class="whats-new-link-container">
										<a
											class="whats-new-link"
											[href]="result.filePath"
											(click)="trackDownload(section.header)"
										>
											<span
												[class.new-item-label]="result.modifiedDate | new"
												>{{ result.titleText }}</span
											>
										</a>
									</div>
								</li>
							}
						</ul>
						<a
							class="whats-new-more-link"
							[routerLink]="section.link"
							[queryParams]="section.params"
							(click)="trackShowMore(section.header)"
						>
							Show all ({{ results.count }})
							<pp-icon name="navigation-chevron-right-18"></pp-icon>
						</a>
					}
				}
			</div>
		}
	</div>
}

@if (!config.serviceless) {
	@if (showCases$ | async; as results) {
		@if (results.items.length) {
			<search-cards-swiper [items]="results.items" [hideBackground]="true">
				<ng-container header>
					<header class="whats-new-section-header">
						<div class="whats-new-section-header-title">
							<h3>Recently Updated Showcases</h3>

							<pp-icon
								name="notification-info-outline-18"
								[hint]="''"
							></pp-icon>
						</div>

						<a
							class="whats-new-section-header-link"
							[routerLink]="'/search'"
							[queryParams]="{o: showCasesSearchQuery}"
							target="_blank"
							(click)="trackShowMore('Recently Updated Showcases')"
						>
							Show all ({{ results.totalCount | format }})
							<pp-icon name="navigation-chevron-right-18"></pp-icon>
						</a>
					</header>
				</ng-container>
				<ng-template #itemTemplate let-item>
					<ng-template
						[ngTemplateOutlet]="dashboardCards"
						[ngTemplateOutletContext]="{$implicit: item}"
					>
					</ng-template>
				</ng-template>
			</search-cards-swiper>
		}
	}

	@if (infoNews$ | async; as results) {
		@if (results.items.length) {
			<search-cards-swiper [items]="results.items" [hideBackground]="true">
				<ng-container header>
					<header class="whats-new-section-header">
						<div class="whats-new-section-header-title">
							<h3>Recently Published Articles</h3>

							<pp-icon
								name="notification-info-outline-18"
								[hint]="''"
							></pp-icon>
						</div>

						<a
							class="whats-new-section-header-link"
							[routerLink]="'/search'"
							[queryParams]="{o: infoSearchQuery}"
							target="_blank"
							(click)="trackShowMore('Recently Published Articles')"
						>
							Show all ({{ results.totalCount | format }})
							<pp-icon name="navigation-chevron-right-18"></pp-icon>
						</a>
					</header>
				</ng-container>
				<ng-template #itemTemplate let-item>
					<ng-template
						[ngTemplateOutlet]="dashboardCards"
						[ngTemplateOutletContext]="{$implicit: item}"
					>
					</ng-template>
				</ng-template>
			</search-cards-swiper>
		}
	}
}

<ng-template #dashboardCards let-item>
	<div class="dashboard-section-card">
		<div
			class="dashboard-section-thumbnail"
			(mouseenter)="showOverlay(item)"
			(mouseleave)="hideOverlay()"
		>
			@if (item.fileExtension === 'cloudPage') {
				<cloud-thumbnail [value]="item.thumbnail"></cloud-thumbnail>
			} @else {
				<search-result-thumbnail
					[result]="item"
					(triggerActionEmitter)="triggerAction($event, item)"
				></search-result-thumbnail>
			}
			@if (item.documentId === hoveredDocumentId) {
				<shared-result-overlay
					[result]="item"
					(triggerActionEmitter)="triggerAction($event, item)"
				></shared-result-overlay>
			}
		</div>
		<header class="dashboard-section-item-header" (click)="triggerAction('PREVIEW', item)">
			{{ item.titleText }}
		</header>
	</div>
</ng-template>
@if (documentToConfirm$ | async; as documentToConfirm) {
	<shared-download
		[data]="documentToConfirm"
		(cancel)="handleConfirmation()"
		(done)="handleConfirmation($event)"
	></shared-download>
}
