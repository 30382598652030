import {ScrollStrategy, OverlayRef} from '@angular/cdk/overlay';
import {DocumentScrollService} from '../document-scroll.service';
import {ModalService} from '../modal.service';

export class ModalScrollableStrategy implements ScrollStrategy {
	private overlayRef!: OverlayRef;
	private isEnable = false;

	constructor(
		private readonly documentScrollService: DocumentScrollService,
		private readonly modalService: ModalService,
	) {}

	attach(overlayRef: OverlayRef): void {
		this.overlayRef = overlayRef;
	}

	disable(): void {
		if (!this.isEnable) {
			return;
		}
		this.isEnable = false;

		// last closed modal restore scroll on the document
		if (this.modalService.getModalsCount() === 0 || this.modalService.isRedirect) {
			this.documentScrollService.showDocumentScroll();
		}

		const host = this.overlayRef.hostElement;
		host.style.pointerEvents = 'none';
		host.style.overflowY = '';
	}

	enable(): void {
		if (this.isEnable) {
			return;
		}
		this.isEnable = true;

		// first opened modal remove scroll from the document
		if (this.modalService.getModalsCount() === 0) {
			this.documentScrollService.hideDocumentScroll();
		}

		const host = this.overlayRef.hostElement;
		host.style.pointerEvents = 'auto';
		host.style.overflowY = 'auto';
	}
}
