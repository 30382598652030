@if (height$ | async; as height) {
	<div class="preview-document" [style.height]="height" [class.custom-height]="height">
		@if (isCheckboxVisible()) {
			<div class="preview-document-selection-checkbox">
				<pp-checkbox
					class="filter-item-checkbox"
					[checked]="isCheckboxSelected()"
					[disabled]="isCheckboxDisabled()"
					(clickCheckbox)="toggleSelectionState(isCheckboxSelected(), $event)"
				></pp-checkbox>
			</div>
		}
		<ng-container>
			<div class="preview-document-flag-list">
				@if (getDocumentType() === 'case') {
					<shared-usage-permissions
						class="usage-permission"
						[isPreviewFlag]="true"
						[value]="document().spoPresales!.usagePermissions"
						[size]="12"
					></shared-usage-permissions>
				}

				<search-flag-list [item]="document()"></search-flag-list>
			</div>
		</ng-container>
		@if (canShowSlides) {
			<button
				class="preview-document-button preview-document-slides"
				type="button"
				hintPosition="TOP"
				[hint]="docDetails().sourceDocument ? 'Show context' : 'Show slides'"
				(click)="showSlidesModal($event)"
			>
				<pp-icon
					name="pp-show-slides-24"
					[size]="24"
					*ppAnchor="'preview-document-slides'"
				></pp-icon>
			</button>
		}
		<button
			class="preview-document-button preview-document-expand"
			type="button"
			hintPosition="TOP"
			[hint]="expandModeEnabled ? 'Collapse preview' : 'Expand preview'"
			(click)="togglePreviewMode()"
		>
			<pp-icon
				[name]="
					'cloud-preview_navigation-' +
					(expandModeEnabled ? 'collapse' : 'expand') +
					'-24'
				"
				[size]="24"
			></pp-icon>
		</button>
		@if (docDetails().documentPreviewUrl) {
			<search-preview-content
				class="preview-document-frame"
				[item]="docDetails()"
				[inline]="true"
				[calculateOnResize]="false"
			></search-preview-content>
		}
	</div>
}
