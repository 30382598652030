import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	inject,
	OnInit,
	signal,
} from '@angular/core';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {PreviewDialogComponent} from '../preview-dialog/preview-dialog.component';
import {PreviewRequest, PreviewStore} from '../preview.store';
import {TitleService} from '@shared/services/title/title.service';
import {AlertComponent} from '@pp/alert';
import {UseThemeDirective} from '@shared/directives';
import {SelectionPanelGapComponent} from '@shared/selection';
import {DocumentItemParserService} from '@shared/api';
import {UserStore} from '@shared/state';
import {SearchStore} from '@search2/state';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';

@Component({
	selector: 'search-preview-dialog-page',
	templateUrl: './preview-dialog-page.component.html',
	styleUrls: ['./preview-dialog-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () =>
				new ContextualAnalyticsService(inject(AnalyticsService), 'Document Preview'),
		},
	],
	standalone: true,
	imports: [
		UseThemeDirective,
		AlertComponent,
		RouterLink,
		PreviewDialogComponent,
		SelectionPanelGapComponent,
	],
})
export class PreviewDialogPageComponent implements OnInit {
	theme = inject(UserStore).settings.uiTheme;
	private searchStore = inject(SearchStore);
	private previewStore = inject(PreviewStore);

	previewRequest = signal<PreviewRequest | null>(null);

	private docDetails = this.previewStore.docDetails;
	private hideAlert = signal<boolean>(false);
	preset = computed(() => {
		const document = this.docDetails()?.document;
		if (document === undefined || this.hideAlert()) {
			return undefined;
		}

		const itemType = this.parserService.getItemType(document);
		const presetId = this.parserService.getPresetIdByItemType(itemType);

		return this.searchStore.presetFlatList().find((p) => p.presetId === presetId);
	});

	constructor(
		private readonly route: ActivatedRoute,
		private readonly titleService: TitleService,
		private readonly parserService: DocumentItemParserService,
		private readonly analytics: AnalyticsService,
	) {
		effect(() => {
			const document = this.docDetails()?.document;
			if (document === undefined) {
				return;
			}

			this.titleService.setTitle(document.title.rawValue);
			this.analytics.trackPageNavigation('/ui/preview/:documentId');
			this.analytics.trackDocumentPreviewAction('open:preview', 'page');
		});
	}

	ngOnInit(): void {
		this.previewRequest.set({
			documentId: this.route.snapshot.params['documentId'],
			context: [],
		});
	}

	closeBackAlert(): void {
		this.hideAlert.set(true);
	}
}
