import {Observable} from 'rxjs';

export function impersonate<T>(
	_target: unknown,
	_propertyName: string,
	descriptor: TypedPropertyDescriptor<(query: string, email?: string) => Observable<T>>,
): void {
	const method = descriptor.value;

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	descriptor.value = function (...args: []) {
		const email = localStorage.getItem('uspp.impersonate-user');
		// eslint-disable-next-line prefer-rest-params
		const argument = arguments[arguments.length - 1];
		const modifiedArgs = email && !argument?.includes('@epam.com') ? [...args, email] : args;

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-expect-error
		return method.apply(this, modifiedArgs);
	};
}
