import {Injectable, inject} from '@angular/core';
import {Observable} from 'rxjs';
import {
	HttpClient,
	HttpEvent,
	HttpEventType,
	HttpHeaders,
	HttpProgressEvent,
} from '@angular/common/http';
import {map, last, switchMap} from 'rxjs/operators';
import {API_BASE_URL} from '@shared/api';

export class DownloadProgressState {
	loaded = 0;
	total?: number;
}

@Injectable({providedIn: 'root'})
export class DownloadWithProgress {
	private readonly http = inject(HttpClient);
	private readonly baseUrl = inject(API_BASE_URL);

	getDownloadUrl(documentId: string): string {
		return `${this.baseUrl}/api/documents/${documentId}/download`;
	}

	getFileBase64$(
		documentId: string,
		downloadProgressCallback: (state: DownloadProgressState) => void,
	): Observable<string> {
		const request$: Observable<HttpEvent<Blob>> = this.http.request(
			'get',
			this.getDownloadUrl(documentId),
			{
				observe: 'events',
				responseType: 'blob',
				reportProgress: true,
				headers: new HttpHeaders({
					Accept: 'application/octet-stream',
				}),
			},
		);

		return request$.pipe(
			map((event: HttpEvent<Blob>) => {
				switch (event.type) {
					case HttpEventType.Sent:
						downloadProgressCallback({loaded: 0} as HttpProgressEvent);

						return null;
					case HttpEventType.DownloadProgress:
						downloadProgressCallback({
							loaded: event.loaded,
							total: event.total,
						} as HttpProgressEvent);

						return null;
					case HttpEventType.Response:
						return event.body;
					default:
						return null;
				}
			}),
			last(),
			switchMap((blob) => this.readBlobAsBase64$(blob)),
		);
	}

	private readBlobAsBase64$(blob: Blob | null): Observable<string> {
		const marker = 'base64,';

		return new Observable((observer) => {
			const reader = new FileReader();

			reader.onloadend = () => {
				const content = reader.result as string; // ???
				const startIndex = content.indexOf(marker);
				if (startIndex === -1) {
					observer.error('Cannot find base64 marker');

					return;
				}

				const copyBase64 = content.substring(startIndex + marker.length);
				observer.next(copyBase64);
				observer.complete();
			};

			reader.onerror = (event) => {
				observer.error(event);
			};

			if (blob === null) {
				observer.error('Blob is null');
			} else {
				reader.readAsDataURL(blob);
			}
		});
	}
}
