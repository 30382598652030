import {Injectable, inject} from '@angular/core';
import {Observable, OperatorFunction, filter} from 'rxjs';
import {toObservable} from '@angular/core/rxjs-interop';
import {FiltersSettings} from '@shared/models';
import {UserStore} from '@shared/state';

@Injectable({providedIn: 'root'})
export class FiltersSettingsService {
	private userStore = inject(UserStore);

	filterSettings$: Observable<FiltersSettings> = toObservable(
		this.userStore.settings.filters,
	).pipe(
		filter((filters) => !!filters) as OperatorFunction<
			FiltersSettings | undefined,
			FiltersSettings
		>,
	);
}
