import {
	BasePortalOutlet,
	CdkPortalOutlet,
	ComponentPortal,
	TemplatePortal,
} from '@angular/cdk/portal';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentRef,
	ElementRef,
	EmbeddedViewRef,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	inject,
	signal,
} from '@angular/core';
import {CURRENT_MODAL} from '../current-modal.token';
import {ModalRef} from './modal.ref';
import {ThemeModeType} from '@shared/theme';
import {UserStore} from '@shared/state';
import {OfficeStore} from '@office/state';
import {UseThemeDirective} from '@shared/directives';
import {SvgIconComponent} from '@pp/svg';
import {SelectionPanelGapComponent} from '@shared/selection';

@Component({
	selector: 'shared-modal-container',
	templateUrl: './modal-container.component.html',
	styleUrls: ['./modal-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent, UseThemeDirective, CdkPortalOutlet, SelectionPanelGapComponent],
})
export class ModalContainerComponent extends BasePortalOutlet implements OnInit, OnDestroy {
	@Input() showCloseContainer = true;
	@Input() fixedTheme?: ThemeModeType;

	@ViewChild(CdkPortalOutlet, {static: true}) portalOutlet!: CdkPortalOutlet;

	isScrollTopVisible = false;
	modalWrapperElement!: HTMLElement;

	theme = inject(UserStore).settings.uiTheme;
	isInsidePowerPoint = inject(OfficeStore).isInsidePowerPoint;

	constructor(
		@Inject(CURRENT_MODAL) private readonly modalRef: ModalRef,
		private element: ElementRef,
		private detector: ChangeDetectorRef,
	) {
		super();
	}
	ngOnInit(): void {
		this.modalWrapperElement = this.element.nativeElement.closest(
			'.cdk-global-overlay-wrapper',
		);

		if (!!this.modalWrapperElement) {
			this.modalWrapperElement.addEventListener('scroll', this.calculateScrollTopVisibility);
		}

		if (this.fixedTheme) {
			this.theme = signal(this.fixedTheme);
		}
	}

	ngOnDestroy() {
		this.modalWrapperElement.removeEventListener('scroll', this.calculateScrollTopVisibility);
	}

	private calculateScrollTopVisibility = () => {
		const newState = this.modalWrapperElement.scrollTop > 0;

		if (this.isScrollTopVisible !== newState) {
			this.isScrollTopVisible = newState;
			this.detector.detectChanges();
		}
	};

	scrollToTop(): void {
		this.modalWrapperElement.scrollTo(0, 0);
	}

	close(): void {
		this.modalRef.close();
	}

	attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
		return this.portalOutlet.attachComponentPortal(portal);
	}

	attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
		return this.portalOutlet.attachTemplatePortal(portal);
	}
}
