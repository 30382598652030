import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AsyncPipe} from '@angular/common';
import {RemoveFilterAction} from '../../actions';
import {SearchFeatureState} from '../../reducers/search-state';
import {AppliedGroupFilterViewModel} from './applied-group-filter.viewmodel';
import {FilterFactoryService} from '@search/services';
import {AppliedFilterModel} from '@search/models';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'search-filters-selected-group-list',
	templateUrl: './search-filters-selected-group-list.component.html',
	styleUrls: ['./search-filters-selected-group-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent, AsyncPipe],
})
export class SearchFiltersSelectedGroupListComponent implements OnInit {
	selectedFilters$!: Observable<AppliedGroupFilterViewModel[]>;

	@Input({required: true}) source!: string;

	constructor(
		private store$: Store<SearchFeatureState>,
		private filterFactory: FilterFactoryService,
	) {}

	ngOnInit(): void {
		const excludedFilters$: Observable<AppliedGroupFilterViewModel[]> = this.store$
			.select(
				(state) =>
					state.search.selectedFilters.exclude[
						this.source as keyof typeof state.search.selectedFilters.exclude
					],
			)
			.pipe(map(this.mapFilters.bind(this, false)), map(this.groupByName.bind(this, false)));
		const includedFilters$: Observable<AppliedGroupFilterViewModel[]> = this.store$
			.select(
				(state) =>
					state.search.selectedFilters.include[
						this.source as keyof typeof state.search.selectedFilters.include
					],
			)
			.pipe(map(this.mapFilters.bind(this, true)), map(this.groupByName.bind(this, true)));

		this.selectedFilters$ = combineLatest([includedFilters$, excludedFilters$]).pipe(
			map(([include, exclude]) => [...include, ...exclude]),
			map((filters) => filters.sort((a, b) => a.name.localeCompare(b.name))),
		);
	}

	remove(group: AppliedGroupFilterViewModel): void {
		group.filters.forEach((filter) => {
			this.store$.dispatch(
				new RemoveFilterAction({
					type: this.source,
					filter,
				}),
			);
		});
	}

	private mapFilters(include: boolean, filters: string[]): AppliedFilterModel[] {
		return filters.map((token) => ({
			include,
			token,
			name: this.filterFactory.get(this.source).service.prepareName(token),
		}));
	}

	private groupByName(
		include: boolean,
		filters: AppliedFilterModel[],
	): AppliedGroupFilterViewModel[] {
		const names = new Set(filters.map((item) => item.name));
		const result = Array.from(names).map((name) => {
			const items = filters.filter((filter) => filter.name === name);

			return {
				name,
				include,
				filters: items,
			};
		});

		return result;
	}
}
