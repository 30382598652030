import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'breakLine',
	standalone: true,
})
export class BreakLinePipe implements PipeTransform {
	/*
		Use [innerHtml]="... | breakLine"
	*/
	transform(value: string): string {
		return value?.replace(/(?:\r\n|\r|\n)/g, '<br/>');
	}
}
