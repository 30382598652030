import {inject} from '@angular/core';
import {patchState, signalStore, withMethods, withState} from '@ngrx/signals';
import {AnalyticsService} from '@shared/analytics';

interface UiState {
	devMode: boolean;
	window: {
		width: number;
		height: number;
		isMobileMode: boolean;
	};
	showDownloadAdvice: boolean;
	downloadsItems: {
		docId: string;
		time: number;
	}[];
}

const uiInitialState: UiState = {
	devMode: false,
	window: {
		width: 0,
		height: 0,
		isMobileMode: false,
	},
	showDownloadAdvice: false,
	downloadsItems: [],
};

const DOWNLOAD_WINDOW = 60000;
const MAX_DOWNLOAD_COUNT = 10;

export const UiStore = signalStore(
	{providedIn: 'root'},
	withState(uiInitialState),
	withMethods((store) => {
		const analytics = inject(AnalyticsService);

		return {
			windowResizeAction(width: number, height: number): void {
				analytics.setSizeLabels(width, height);
				const isMobileMode = width <= 767;
				patchState(store, () => ({window: {width, height, isMobileMode}}));
			},
			toggleDevMode(): void {
				patchState(store, (state) => ({devMode: !state.devMode}));
			},
			trackPptxDownloadEvent(docId: string, time: number): void {
				patchState(store, (state) => {
					const newItems = [...state.downloadsItems];
					newItems.push({docId, time});

					if (newItems.length > MAX_DOWNLOAD_COUNT) {
						newItems.shift();
					}

					const showDownloadAdvice =
						newItems.length === MAX_DOWNLOAD_COUNT &&
						Math.abs(newItems[0].time - newItems[newItems.length - 1].time) <
							DOWNLOAD_WINDOW;

					return {
						downloadsItems: [...newItems],
						showDownloadAdvice,
					};
				});
			},
		};
	}),
);
