import {AnalyticsService, EventCategories} from './analytics.service';
import {SearchItemActionType} from '@shared/card-actions';

export class ContextualAnalyticsService {
	constructor(
		private readonly analytics: AnalyticsService,
		private readonly eventCategory: EventCategories,
	) {}

	sendDocumentEvent(action: SearchItemActionType, label?: string) {
		const actionName = `document:${action}`;
		this.analytics.sendEvent(this.eventCategory, actionName, label);
	}

	trackError(error: unknown) {
		this.analytics.trackError(error);
	}
}
