import {Action} from '@ngrx/store';

import {FilterModel} from '@search/models';

export const UPDATE_FILE_CONTENT_TYPE_FILTER = '[Search] Update File Content Type Filter';

export class UpdateFileContentTypeFilterAction implements Action {
	readonly type = UPDATE_FILE_CONTENT_TYPE_FILTER;

	constructor(public payload: FilterModel[]) {}
}
