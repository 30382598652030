@if (downloads$ | async; as downloads) {
	<div class="search-table">
		<div class="search-head">
			<div>User</div>
			<div>Date</div>
		</div>
		@for (item of downloads; track item.date) {
			<div class="search-row">
				<div>
					<a href="mailto:{{ item.user }}">{{ item.user }}</a>
				</div>
				<div>{{ item.date | date }}</div>
			</div>
		}
	</div>
} @else {
	<pp-spinner class="centered" [style]="'dark'"></pp-spinner>
}
