import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'random',
	standalone: true,
})
export class RandomPipe implements PipeTransform {
	transform(value: number): number {
		const spread = 2;

		return Math.floor(value - spread + Math.random() * (spread * 2 + 1));
	}
}
