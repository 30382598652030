import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import {Subscription} from 'rxjs';

import {tap} from 'rxjs/operators';
import {toObservable} from '@angular/core/rxjs-interop';
import {NgClass, AsyncPipe} from '@angular/common';
import {ToastStore} from '@shared/state';
import {UseThemeDirective} from '@shared/directives';
import {SpinnerComponent} from '@pp/spinner';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'core-toast-messages',
	templateUrl: './toast-messages.component.html',
	styleUrls: ['./toast-messages.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, SvgIconComponent, SpinnerComponent, AsyncPipe, UseThemeDirective],
})
export class ToastMessagesComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();
	private timeoutHandlers: Dictionary<number> = {};

	private toastStore = inject(ToastStore);
	toastMessages$ = toObservable(this.toastStore.toasts);

	ngOnInit(): void {
		this.subscriptions.add(
			this.toastMessages$
				.pipe(
					tap((toastMessages) => {
						toastMessages.forEach((toast) => {
							if (toast.marker || toast.id === undefined) {
								return; // do not close toasts with marker by timeout
							}

							if (!this.timeoutHandlers[toast.id]) {
								const toastTimeout = {
									fatalError: 300000,
									success: 10000,
									info: 3000,
									error: 3000,
									alert: 3000,
									loading: 300000,
								};

								this.timeoutHandlers[toast.id] = window.setTimeout(
									this.hideToast.bind(this, toast.id),
									toastTimeout[toast.type],
								);
							}
						});
					}),
				)
				.subscribe(),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	hideToast(toastId: number | undefined): void {
		if (toastId === undefined) {
			return;
		}

		window.clearTimeout(this.timeoutHandlers[toastId]);
		delete this.timeoutHandlers[toastId];
		this.toastStore.removeToastById(toastId);
	}

	refresh(): void {
		window.location.reload();
	}
}
