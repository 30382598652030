import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {SvgIconComponent} from '@pp/svg';
import {CollapseDirective} from '@shared/components/chain/directives/collapse.directive';
import {DocumentIndexItem, DocumentItemParserService, PpHighlightableValue} from '@shared/api';
import {SearchStore} from '@search2/state';
import {HighlightPipe} from '@shared/pipes/highlight.pipe';

@Component({
	selector: 'search-tags-section',
	templateUrl: './tags-section.component.html',
	styleUrls: ['./tags-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HighlightPipe, SvgIconComponent, CollapseDirective],
})
export class TagsSectionComponent {
	item = input.required<DocumentIndexItem>();
	isCardSmall = input<boolean>(false);
	iconSize = input<number>(18);

	private searchStore = inject(SearchStore);
	private resultParser = inject(DocumentItemParserService);

	private selectedTags = computed(
		() => this.searchStore.searchQuery.selectedFilters()['spo_presales.tags.keyword'] ?? [],
	);
	tags = computed(() => {
		const selectedTags = this.selectedTags();

		return (this.item()?.spoPresales?.tags ?? [])
			.filter((tag) => !this.resultParser.isBestOfTag(tag))
			.map((tag) => ({
				...tag,
				isDisabled: selectedTags.includes(tag.rawValue),
			}));
	});

	private selectedCollections = computed(
		() =>
			this.searchStore.searchQuery.selectedFilters()[
				'spo_presales.case_study_collection.keyword'
			] ?? [],
	);
	collections = computed(() => {
		const selectedCollections = this.selectedCollections();

		return (this.item()?.spoPresales?.caseStudyCollection ?? []).map((collection) => ({
			...collection,
			isDisabled: selectedCollections.includes(collection.rawValue),
		}));
	});

	selectCollection(collection: FilterVM): void {
		if (!collection.isDisabled) {
			this.selectFilter('spo_presales.case_study_collection.keyword', collection.rawValue);
		}
	}

	selectTag(tag: FilterVM): void {
		if (!tag.isDisabled) {
			this.selectFilter('spo_presales.tags.keyword', tag.rawValue);
		}
	}

	private selectFilter(fieldName: string, fieldValue: string): void {
		this.searchStore.selectFilter(fieldName, fieldValue);
	}
}

class FilterVM implements PpHighlightableValue {
	rawValue!: string;
	highlightedValue?: string | undefined;
	isDisabled = false;
}
