import {ChangeDetectionStrategy, Component, OnInit, inject} from '@angular/core';
import {Observable, concatMap, map, of} from 'rxjs';
import {toObservable} from '@angular/core/rxjs-interop';
import {AsyncPipe} from '@angular/common';
import {SlideTagsInfo} from '../../services';
import {UseThemeDirective} from '@shared/directives';
import {DocumentIndexItem, SearchClient} from '@shared/api';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';
import {UserStore} from '@shared/state';
import {OfficeStore} from '@office/state';
import {SearchItemCardComponent} from '@preview/item-card';

@Component({
	selector: 'app-slide-tags-page',
	templateUrl: './slide-tags-page.component.html',
	styleUrls: ['./slide-tags-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () => new ContextualAnalyticsService(inject(AnalyticsService), 'Technical'),
		},
	],
	standalone: true,
	imports: [UseThemeDirective, AsyncPipe, SearchItemCardComponent],
})
export class SlideTagsPageComponent implements OnInit {
	private officeStore = inject(OfficeStore);
	theme = inject(UserStore).settings.uiTheme;

	vm$: Observable<SlideInfo[]> = toObservable(this.officeStore.tagsInfo).pipe(
		concatMap((slideTagsInfo) => {
			const docIds = slideTagsInfo
				.map((x) => this.getDocId(x))
				.filter((x) => x !== undefined) as string[];

			const items$ = docIds.length === 0 ? of([]) : this.searchClient.searchList(docIds);

			return items$.pipe(
				map((items) => {
					return slideTagsInfo.map((slideTagInfo) => {
						const docId = this.getDocId(slideTagInfo);
						if (docId === undefined) {
							return {
								slideId: slideTagInfo.slideId,
								message: 'Tag not found',
							};
						}

						const item = items.find((x) => x.id === docId);
						if (item === undefined) {
							return {
								slideId: slideTagInfo.slideId,
								message: 'Slide not found',
							};
						}

						return {
							slideId: slideTagInfo.slideId,
							item,
							message: '',
						};
					});
				}),
			);
		}),
	);

	constructor(private readonly searchClient: SearchClient) {}

	async ngOnInit(): Promise<void> {
		await this.officeStore.loadTagsInfo();
	}

	getDocId(tagsInfo: SlideTagsInfo): string | undefined {
		return tagsInfo.tags.find((tag) => tag.key === 'USPP_DOC_ID')?.value;
	}
}

interface SlideInfo {
	slideId: string;
	item?: DocumentIndexItem;
	message: string;
}
