<div class="first-part">
	<pp-icon
		(click)="openViewAccessModal()"
		[hint]="'View access'"
		[hintPosition]="'BOTTOM'"
		class="not-public-access"
		[name]="document().isPublic ? 'action-lock_open-fill-12' : 'action-lock-fill-12'"
	></pp-icon>

	@if (isWebPageOrLink()) {
		<span class="url-container">
			<pp-icon name="action-external_link-12"></pp-icon>
			<span>{{ document().previewPageUrl | hostname }}</span>
			<div class="count-item-container" [hint]="'Views'" [hintPosition]="'BOTTOM'">
				<pp-icon name="action-eye-12" [size]="12"></pp-icon>
				<span>{{ document().downloadCount }}</span>
			</div>
		</span>
	} @else {
		<span class="file-extension" [class]="document() | fileExtension">{{
			fileExtension()
		}}</span>

		@if (isCardSmall()) {
			<div class="date-icon-container">
				<pp-icon
					name="icon-clock-18"
					[size]="12"
					[hint]="dateHint()"
					[hintPosition]="'BOTTOM'"
				/>
			</div>
		} @else {
			<span class="footer-date">{{ dateHint() }}</span>
		}
	}
</div>

@if (!isWebPageOrLink()) {
	<div class="count-item-container" [hint]="'Downloads'" [hintPosition]="'BOTTOM'">
		<pp-icon name="file-download-18" [size]="12"></pp-icon>
		<span>{{ document().downloadCount }}</span>
	</div>

	@if (lightMode() === false && (teams() | hasTeam: 'admin')) {
		<div
			class="count-item-container"
			[hint]="'Views (visible to admins only)'"
			[hintPosition]="'BOTTOM'"
		>
			<pp-icon name="action-eye-12" [size]="12"></pp-icon>
			<span>{{ document().previewCount }}</span>
		</div>
	}
}
