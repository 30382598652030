import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'modified',
	standalone: true,
})
export class ModifiedPipe implements PipeTransform {
	private readonly day = 86400000;

	transform(date: number): string {
		const lastModified = Math.floor((new Date().valueOf() - date) / this.day);
		let result = '';

		if (lastModified === 0) {
			result = 'today';
		} else if (lastModified === 1) {
			result = 'yesterday';
		} else if (lastModified < 30) {
			result = `${lastModified} days ago`;
		} else if (lastModified < 60) {
			result = 'a month ago';
		} else if (lastModified < 365) {
			result = `${Math.floor(lastModified / 30)} months ago`;
		} else {
			result = `${Math.floor(lastModified / 365)} years ago`;
		}

		return result;
	}
}
