<div class="slides-page-container" [ppCdkUseTheme]="theme()">
	<div class="slides-page-content">
		<header>
			<h1>Reusable Slides</h1>

			<shared-abbreviation-icon
				badge
				class="info-block-badge"
				[label]="'Beta'"
			></shared-abbreviation-icon>
		</header>

		<div class="slides-tree">
			@for (section of reusableSlidesTree()?.children; track $index) {
				<powerpoint-grouped-section [section]="section"></powerpoint-grouped-section>
			}
		</div>
	</div>
</div>
