import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class DistributionListService {
	test(email?: string): boolean {
		if (email) {
			return /^((WFT)|(WFB)|(Special)|(Support)|(Org)|(auto))/i.test(email);
		} else {
			return false;
		}
	}
}
