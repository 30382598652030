import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import {AnchorDirective} from '@pp/anchor';
import {UsagePermissionType} from '@shared/access/usage-permissions/usage-permission-types.enum';
import {SearchResultModel} from '@shared/models';
import {UsagePermissionsComponent} from '@shared/access';
import {SharedWithComponent} from '@shared/components/shared-with/shared-with.component';

@Component({
	selector: 'search-result-subheader',
	templateUrl: './search-result-subheader.component.html',
	styleUrls: ['./search-result-subheader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UsagePermissionsComponent, SharedWithComponent, AnchorDirective],
})
export class SearchResultSubHeaderComponent implements OnInit {
	fileName?: string;
	documentType?: string;
	typeForAnchor?: string;

	@Input({required: true}) result!: SearchResultModel;
	@Input() usagePermission?: UsagePermissionType;
	@Output() queueDownloadSource = new EventEmitter<SearchResultModel>();

	ngOnInit(): void {
		this.fileName = this.result.sourceFilePath?.split('/').pop();

		if (this.result.filePath.includes('/gen/slides')) {
			const match = this.result.fileName?.match(/\d+/g);
			const numberOfSlide = match?.pop() ?? '0';
			this.documentType = `Slide #${+numberOfSlide}`;
			this.typeForAnchor = 'slide';
		} else if (this.result.filePath.includes('/gen/cs')) {
			this.documentType = 'Case Study';
		} else if (this.result.filePath.includes('/gen/qna')) {
			this.documentType = 'Q&A';
		} else if (this.result.filePath.includes('/gen/exec')) {
			this.documentType = 'Executive Summary';
		}

		if (!this.typeForAnchor) {
			this.typeForAnchor = this.documentType;
		}
	}

	triggerQueueDownloadSource(event: SearchResultModel): void {
		this.queueDownloadSource.emit(event);
	}
}
