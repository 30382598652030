import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {AsyncPipe} from '@angular/common';
import {SubmitQueryAction} from '../../actions';
import {SearchFeatureState} from '../../reducers/search-state';

@Component({
	selector: 'search-spell',
	templateUrl: './spell.component.html',
	styleUrls: ['./spell.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [AsyncPipe],
})
export class SpellComponent {
	spellingSuggestion$: Observable<string | undefined> = this.store$.select(
		(state) => state.search.results.spellingSuggestion,
	);

	constructor(private store$: Store<SearchFeatureState>) {}

	updateQuery(spell: string): void {
		this.store$.dispatch(new SubmitQueryAction(spell));
	}
}
