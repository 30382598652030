<li
	[title]="appliedFilterModel.token | hexDecode"
	[class.include]="appliedFilterModel.include"
	[class.exclude]="!appliedFilterModel.include"
>
	<span
		class="search-filters-selected-list-name"
		[class.without-close-btn]="!hasCloseButton"
		[class.exclude]="!appliedFilterModel.include"
		>{{ appliedFilterModel.name }}</span
	>

	@if (hasCloseButton) {
		<button
			class="dp-button-null search-filters-selected-list-remove-filter"
			(click)="removeEmitter.emit()"
		>
			<pp-icon name="navigation_close_18" />
		</button>
	}
</li>
