import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	OnDestroy,
	Output,
	ViewChild,
	OnInit,
	ElementRef,
} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {
	BehaviorSubject,
	fromEvent,
	of,
	OperatorFunction,
	queueScheduler,
	scheduled,
	Subscription,
} from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	mergeAll,
	startWith,
	switchMap,
	tap,
} from 'rxjs/operators';
import {NgTemplateOutlet, DecimalPipe} from '@angular/common';
import {HintDirective} from '@pp/hint';
import {FilterModel} from '@search/models';
import {RangeModel} from '@shared/models';
import {formatRange} from '@shared/utils';

@Component({
	selector: 'shared-range-slider',
	templateUrl: './range-slider.component.html',
	styleUrls: ['./range-slider.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgTemplateOutlet, HintDirective, DecimalPipe],
})
export class RangeSliderComponent implements AfterViewInit, OnDestroy, OnInit {
	@Input({required: true}) filterName!: string;

	@Input()
	set rangeInput(range: RangeModel) {
		this.range$.next(range);
		this.left = range.from;
		this.right = range.to;
	}

	get min(): number {
		return 0;
	}

	get max(): number {
		return this.bars.length;
	}

	get barMax(): number {
		const result = this.bars
			.map((x) => x.count ?? 0)
			.reduce((max, bar) => (max > bar ? max : bar));

		return Math.max(result, 1);
	}

	@HostBinding('style') get style(): SafeStyle {
		return this.sanitizer.bypassSecurityTrustStyle(`
			--bar-max: ${this.barMax};
			--range-slider-left: ${this.left};
			--range-slider-right: ${this.right};
			--range-slider-min: ${this.min};
			--range-slider-max: ${this.max};
		`);
	}

	get leftLabel(): string {
		return this.getLabel(this.bars[this.left], 0);
	}

	get rightLabel(): string {
		return this.getLabel(this.bars[this.right - 1], 1);
	}

	private getLabel(filterModel: FilterModel, index: 0 | 1): string {
		return filterModel.labels ? filterModel.labels[index] : '';
	}

	get count(): number {
		return this.bars
			.filter((_, index) => index >= this.left && index < this.right)
			.reduce((value, {count}) => value + (count ?? 0), 0);
	}

	get formattedRangeValue(): string {
		return formatRange(this.leftLabel, this.rightLabel, this.filterName);
	}

	@ViewChild('histogram')
	private histogram?: ElementRef;

	private readonly subscriptions = new Subscription();

	private range$ = new BehaviorSubject<RangeModel>({from: 0, to: 1});

	@Input()
	bars: FilterModel[] = [];

	@Output()
	rangeChange: EventEmitter<RangeModel> = new EventEmitter<RangeModel>();

	@Output()
	analytics = new EventEmitter();

	left = 0;
	right = 1;

	minLabel!: string;
	maxLabel!: string;

	constructor(
		private detector: ChangeDetectorRef,
		private sanitizer: DomSanitizer,
	) {}

	ngOnInit() {
		this.minLabel = this.getLabel(this.bars[0], 0);
		this.maxLabel = this.getLabel(this.bars[this.bars.length - 1], 1);
	}

	ngAfterViewInit(): void {
		if (!this.histogram) {
			return;
		}

		const mousemove$ = fromEvent(this.histogram.nativeElement, 'mouseover').pipe(
			debounceTime(10) as OperatorFunction<unknown, MouseEvent>,
			map((event: MouseEvent) => {
				const target = event.target as HTMLElement;

				return target.classList.contains('bar-wrap') ? target : target.parentElement;
			}),
			filter((element) => element != null) as OperatorFunction<
				HTMLElement | null,
				HTMLElement
			>,
			distinctUntilChanged((prev, cur) => {
				if (prev.dataset['barIndex'] === cur.dataset['barIndex']) {
					const curBarIndex = parseInt(cur.dataset['barIndex'] ?? '', 10);

					return curBarIndex === this.left;
				} else {
					return false;
				}
			}),
			map((element: HTMLElement) => parseInt(element.dataset['barIndex'] ?? '', 10)),
			map((index: number) => of({from: index, to: index + 1})),
		);
		const mouseleave$ = fromEvent(this.histogram.nativeElement, 'mouseleave');

		this.subscriptions.add(
			mousemove$
				.pipe(
					startWith(scheduled(this.range$, queueScheduler)),
					mergeAll(),
					tap(({from, to}) => this.changeRange(from, to)),
				)
				.subscribe(),
		);

		this.subscriptions.add(
			mouseleave$
				.pipe(
					switchMap(() => this.range$),
					tap(({from, to}) => this.changeRange(from, to)),
				)
				.subscribe(),
		);
	}

	private changeRange(from: number, to: number): void {
		this.left = from;
		this.right = to;

		this.detector.markForCheck();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	@HostListener('input', ['$event'])
	onInput(event: Event): void {
		const target = event.target as HTMLInputElement;
		const {id} = target;
		const value = +target.value;

		if (id === 'left') {
			const right = this.right - 1;
			this.left = value >= right ? right : value;
			target.value = this.left.toString();
		} else if (id === 'right') {
			const left = this.left + 1;
			this.right = value <= left ? left : value;
			target.value = this.right.toString();
		}
	}

	@HostListener('change', ['$event'])
	onChange(event: Event): void {
		const elementTarget = event.target as HTMLElement;
		const range = {from: this.left, to: this.right};
		this.rangeChange.emit(range);
		this.range$.next(range);

		if (elementTarget.id === 'left' || elementTarget.id === 'right') {
			this.analytics.emit({target: 'slide', value: elementTarget.id});
		} else {
			this.analytics.emit({target: 'bar', value: ''});
		}
	}

	barStyle(bar: FilterModel): SafeStyle {
		return this.sanitizer.bypassSecurityTrustStyle(`
			--range-slider-count: ${bar.count ?? 0};
		`);
	}

	isBarSelected(bar: number): boolean {
		return bar >= this.left && bar < this.right;
	}

	identityBar(_: number, bar: FilterModel): string {
		return bar.token;
	}
}
