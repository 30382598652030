<div class="edit-contacts-container">
	<div class="edit-contacts-dialog dp-card" cdkDrag cdkDragBoundary=".edit-contacts-container">
		<header class="edit-contacts-header" cdkDragHandle>
			Edit {{ title }}
			<pp-icon (click)="closeDialog()" name="navigation-close-36" />
		</header>
		<main class="edit-contacts-content">
			@if (contacts().length) {
				<ul cdkDropList (cdkDropListDropped)="drop(contacts(), $event)">
					<li class="edit-contacts-item">
						<span class="edit-contacts-item-label">Expertise/Relationship</span>
					</li>
					@for (
						contact of contacts();
						track contactIdentity(contactIndex, contact);
						let contactIndex = $index
					) {
						<li
							class="edit-contacts-item"
							[ngClass]="{'light-theme': theme() === 'light'}"
							cdkDrag
						>
							<pp-icon
								class="edit-contacts-drag"
								name="action-drag_indicator-18"
								cdkDragHandle
							/>
							<search-preview-user-info
								[userInfo]="contact.data"
							></search-preview-user-info>
							<input
								type="text"
								class="edit-contacts-input"
								spellcheck="false"
								autocomplete="off"
								autocorrect="off"
								[value]="contact.expertiseEditable"
								(input)="updateContact(contact, $event.target)"
								(blur)="saveContactIfChanged(contact)"
							/>
							<div class="edit-contacts-item-buttons-container">
								@if (hasUnsavedChanges(contact)) {
									<pp-icon class="edit-contacts-update" name="verified_tick-12" />
								}
								<pp-icon
									(click)="openDeleteConfirmation($event, contact)"
									class="edit-contacts-remove"
									name="action-delete-24"
								/>
							</div>
						</li>
						<div class="edit-contacts-item-divider"></div>
					}
				</ul>
			} @else {
				<div class="no-results">Nothing here yet</div>
				<div class="edit-contacts-item-divider"></div>
			}
		</main>

		<div class="edit-contacts-search">
			<search-people-picker
				class="edit-contacts-suggestion"
				[docId]="documentId()"
				[addedContacts]="contacts()"
			></search-people-picker>
		</div>
	</div>
</div>
