import {Action} from '@ngrx/store';

import {SelectedFiltersPayload} from './selected-filters-payload';

export const ADD_INCLUDE_FILTER = '[Search] Add Include Filter';

export class AddIncludeFilterAction implements Action {
	readonly type = ADD_INCLUDE_FILTER;

	constructor(public payload: SelectedFiltersPayload) {}
}
