import {Action} from '@ngrx/store';

import {TreeFilterModel} from '@search/models';

export const UPDATE_LOCATION_FILTER = '[Search] Update Location Filter';

export class UpdateLocationFilterAction implements Action {
	readonly type = UPDATE_LOCATION_FILTER;

	constructor(public payload: TreeFilterModel) {}
}
