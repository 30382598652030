import {Directive, HostBinding} from '@angular/core';

@Directive({
	selector: '[ppCardImage]',
	standalone: true,
})
export class CardImageDirective {
	@HostBinding('class.card-image')
	readonly isCardImage = true;
}
