import {ChangeDetectionStrategy, Component, ViewEncapsulation, input} from '@angular/core';
import {NgClass} from '@angular/common';
import {FlagSize, FlagType} from './flag.model';
import {SvgIconComponent} from '@pp/svg';
import {VerifiedComponent} from '@pp/verified';

@Component({
	selector: 'pp-flag',
	standalone: true,
	imports: [SvgIconComponent, NgClass, VerifiedComponent],
	templateUrl: './flag.component.html',
	styleUrls: ['./flag.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class FlagComponent {
	type = input<FlagType>('video');
	size = input<FlagSize>('medium');
}
