import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {toSignal} from '@angular/core/rxjs-interop';
import {GroupedSectionComponent} from '../grouped-section/grouped-section.component';
import {ToolboxTemplatesService} from '@office/services';
import {UseThemeDirective} from '@shared/directives';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';
import {UserStore} from '@shared/state';
import {AbbreviationIconComponent} from '@shared/components/abbreviation-icon/abbreviation-icon.component';

@Component({
	selector: 'powerpoint-reusable-slides-page',
	standalone: true,
	imports: [AsyncPipe, UseThemeDirective, AbbreviationIconComponent, GroupedSectionComponent],
	templateUrl: './reusable-slides-page.component.html',
	styleUrl: './reusable-slides-page.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () =>
				new ContextualAnalyticsService(
					inject(AnalyticsService),
					'PowerReusableSlidesTemplates',
				),
		},
	],
})
export class ReusableSlidesPageComponent implements OnInit {
	theme = inject(UserStore).settings.uiTheme;
	reusableSlidesTree = toSignal(this.templatesService.getReusableContent$());

	constructor(
		private analytics: AnalyticsService,
		private templatesService: ToolboxTemplatesService,
	) {}

	ngOnInit() {
		this.analytics.trackPageNavigation('/powerpoint/reusable-slides');
	}
}
