@if (vm$ | async; as vm) {
	@if (vm) {
		<section class="dp-card preview-cases-container">
			<header class="dp-card-header preview-cases-header">
				{{ collectionName }} Case Studies
				<search-preset-link class="dp-card-header-more-link" [link]="vm.casesLink">
					Show all ({{ vm.totalCount }})
					<pp-icon name="navigation-chevron-right-18"></pp-icon>
				</search-preset-link>
			</header>
			<main class="dp-card-content preview-cases-content">
				@for (section of vm.sections; track section.title) {
					@if (section.items.length > 0) {
						<div class="preview-cases-col">
							<header class="dp-title-h4">{{ section.title }}</header>
							<search-preview-cases-tree-section
								[filters]="section.items"
								[field]="section.field"
								[baseQuery]="vm.casesLink.query"
								[expandByDefault]="section.expandByDefault"
							></search-preview-cases-tree-section>
						</div>
					}
				}
			</main>
		</section>
	}
}
