<div class="panel-container search-history-container">
	<header class="panel-header">My Search History</header>
	@if (results$ | async; as results) {
		@for (result of results; track result.dateLabel) {
			<header class="search-history-date-header">{{ result.dateLabel }}</header>
			<ul class="search-history-queries">
				@for (query of result.queries; track query) {
					<li>
						<a [title]="query" (click)="search(query)">{{ query }}</a>
					</li>
				}
			</ul>
		}
		@if (results.length === 0) {
			<div class="search-history-label">You do not have search history.</div>
		}
	} @else {
		<div class="search-history-label">
			<pp-spinner [style]="'black'"></pp-spinner>
		</div>
	}
</div>
