import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {TreeFilterViewmodel} from './tree-filter.viewmodel';
import {AddExcludeFilterAction, AddIncludeFilterAction} from '@search/actions';
import {FilterViewModel, TreeFilterModel} from '@search/models';
import {SearchFeatureState} from '@search/reducers/search-state';

@Injectable({
	providedIn: 'root',
})
export class SearchFiltersListTreeService {
	constructor(private store$: Store<SearchFeatureState>) {}

	applyFilterSearch(
		data: TreeFilterModel,
		filterSearch: RegExp,
		valueField: symbol,
	): TreeFilterModel {
		const result: TreeFilterModel = {};

		Object.keys(data).forEach((subTree) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			const dataSubTree = data[subTree];
			const filteredSubtree = this.applyFilterSearch(
				dataSubTree as TreeFilterModel,
				filterSearch,
				valueField,
			);
			const value = dataSubTree[valueField];

			if (
				Object.keys(filteredSubtree).length > 0 ||
				(value && filterSearch.test(value.name))
			) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				result[subTree] = {
					[valueField]: {
						...dataSubTree[valueField],
						richName: dataSubTree[valueField]?.name.replace(
							filterSearch,
							this.highlightMatch,
						),
					},
					...filteredSubtree,
				};
			}
		});

		return result;
	}

	filterTree(
		items: TreeFilterViewmodel[],
		includeFilters: string[],
		excludeFilters: string[],
	): TreeFilterViewmodel[] {
		return items.map((item) => {
			const includeFilter = includeFilters.findIndex((token) => token === item.token) >= 0;
			const excludeFilter = excludeFilters.findIndex((token) => token === item.token) >= 0;

			return {
				...item,
				selected: includeFilter || excludeFilter,
				isIncluded: includeFilter,
			};
		});
	}

	order(names: string[]): string[] {
		const index = names.findIndex((value) => value === 'Other');

		if (index >= 0) {
			names.splice(index, 1);
			names.push('Other');
		}

		return names;
	}

	includeFilter(item: FilterViewModel, source: string): void {
		if (item.selected) {
			return;
		}

		item.filters.forEach((filter) => {
			this.store$.dispatch(
				new AddIncludeFilterAction({
					type: source,
					filter,
				}),
			);
		});
	}

	excludeFilter(item: FilterViewModel, source: string): void {
		item.filters.forEach((filter) => {
			this.store$.dispatch(
				new AddExcludeFilterAction({
					type: source,
					filter,
				}),
			);
		});
	}

	private highlightMatch(this: void): string {
		// eslint-disable-next-line prefer-rest-params
		const groups = Array.prototype.slice.call(arguments, 1, -2);

		return groups
			.map((group: string, index: number) =>
				index % 2 === 1 ? `<mark>${group}</mark>` : group,
			)
			.join('');
	}
}
