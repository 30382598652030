import {ChangeDetectionStrategy, Component, ViewEncapsulation, inject} from '@angular/core';

import {AnalyticsService} from '@shared/analytics';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'core-header-menu-info',
	standalone: true,
	imports: [SvgIconComponent],
	templateUrl: './header-menu-info.component.html',
	styleUrls: ['./header-menu-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class HeaderMenuInfoComponent {
	readonly analytics = inject(AnalyticsService);

	openHelpWidget(): void {
		this.analytics.trackHeaderActions('infoMenu');

		const api = window as EpamHiveApi;
		if (api.EpamServices?.ModalFramePublicApi) {
			api.EpamServices.ModalFramePublicApi.openInAppHelp();
		}
	}
}
