import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {NgTemplateOutlet} from '@angular/common';
import {RouterLinkActive, RouterLink} from '@angular/router';
import {AccessInfoPopoverComponent} from '../../../access/access-info-popover/access-info-popover.component';
import {AbbreviationIconComponent} from '../../abbreviation-icon/abbreviation-icon.component';
import {NavigationGroupComponent} from '../navigation-group/navigation-group.component';
import {NavigationTreeNode} from '../../../models';
import {HintDirective} from '@pp/hint';
import {AnalyticsService} from '@shared/analytics';
import {SvgIconComponent} from '@pp/svg';
import {SpinnerComponent} from '@pp/spinner/spinner.component';
import {PopoverService} from '@shared/popover';

@Component({
	selector: 'shared-navigation-groups-container',
	templateUrl: './navigation-groups-container.component.html',
	styleUrls: ['./navigation-groups-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [PopoverService],
	standalone: true,
	imports: [
		RouterLinkActive,
		SvgIconComponent,
		NavigationGroupComponent,
		RouterLink,
		NgTemplateOutlet,
		HintDirective,
		AbbreviationIconComponent,
		SpinnerComponent,
	],
})
export class NavigationGroupsContainerComponent {
	@Input({required: true}) items!: NavigationTreeNode[];
	@Input({required: true}) isPanelOpened!: boolean;
	@Input({required: true}) title!: string;
	@Input({required: true}) groupIcon!: string;
	@Input() isNew?: boolean;

	@Output() togglePanelEvent = new EventEmitter<boolean>();

	isGroupExpanded = true;

	constructor(
		private readonly popOverService: PopoverService,
		private readonly analytics: AnalyticsService,
	) {}
	toggleGroups(): void {
		this.isGroupExpanded = !this.isGroupExpanded;
	}

	togglePanel(): void {
		this.togglePanelEvent.emit(!this.isPanelOpened);
		this.isGroupExpanded = true;
	}

	openAccessInfoPopover(event: Event): void {
		this.popOverService.openPopOver(
			AccessInfoPopoverComponent,
			{},
			event.target as HTMLElement,
			'bottom',
			true,
		);
	}

	trackAnalytics(name: string): void {
		this.analytics.trackNavigationAction('navigate:space', name);
	}

	trackExternalLink(name: string): void {
		this.analytics.trackNavigationAction('navigate:external-link', name);
	}
}
