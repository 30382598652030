import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TeamsListPopupComponent} from './teams-list-popup/teams-list-popup.component';
import {AccessLevel} from './access-level';
import {AnchorDirective} from '@pp/anchor';
import {SvgIconComponent} from '@pp/svg';
import {SearchResultModel} from '@shared/models';
import {AnalyticsService} from '@shared/analytics';
import {PopoverService} from '@shared/popover';

@Component({
	selector: 'shared-with',
	templateUrl: './shared-with.component.html',
	styleUrls: ['./shared-with.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	viewProviders: [PopoverService],
	standalone: true,
	imports: [AnchorDirective, SvgIconComponent],
})
export class SharedWithComponent implements OnInit {
	@Input({required: true}) result!: SearchResultModel;

	popoverActive = false;
	accessLevel!: AccessLevel;

	constructor(
		private readonly analytics: AnalyticsService,
		private readonly popOverService: PopoverService,
	) {}

	ngOnInit(): void {
		const {accessLevel} = this.result;

		this.accessLevel = AccessLevel.from(accessLevel);
	}

	openPopOver(event: Event): void {
		this.popoverActive = true;
		this.analytics.trackSearchResultsAction('openSharedWith');

		this.popOverService.openPopOver(
			TeamsListPopupComponent,
			{
				accessLevel: this.accessLevel,
				result: this.result,
			},
			event.target as HTMLElement,
			'bottom',
		);
	}
}
