import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit,
	OnDestroy,
	inject,
	computed,
} from '@angular/core';
import {Subscription, tap} from 'rxjs';
import {
	FormBuilder,
	AbstractControl,
	FormArray,
	FormGroup,
	FormControl,
	ValidatorFn,
	ReactiveFormsModule,
} from '@angular/forms';
import {CdkDragDrop, CdkDrag, CdkDragHandle, CdkDropList} from '@angular/cdk/drag-drop';
import {NgClass} from '@angular/common';
import {CURRENT_MODAL, ModalRef} from '@shared/modals';
import {DocLink} from '@shared/api';
import {UserStore} from '@shared/state';
import {PreviewStore} from '@preview/document/preview.store';
import {PopoverService} from '@shared/popover';
import {SvgIconComponent} from '@pp/svg';
import {DeleteConfirmationComponent} from '@preview/document/delete-confirmation/delete-confirmation.component';

@Component({
	selector: 'search-edit-links-modal',
	templateUrl: './edit-links-modal.component.html',
	styleUrls: ['./edit-links-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [PopoverService],
	standalone: true,
	imports: [CdkDrag, CdkDragHandle, SvgIconComponent, CdkDropList, NgClass, ReactiveFormsModule],
})
export class EditLinksModalComponent implements OnInit, OnDestroy {
	theme = inject(UserStore).settings.uiTheme;

	private previewStore = inject(PreviewStore);
	documentId = this.previewStore.documentId;
	links = this.previewStore.links;

	private urlValidator: ValidatorFn = (control: AbstractControl) => {
		let validUrl = true;

		if (!control.value && !control.dirty) {
			return null;
		}

		try {
			new URL(control.value);
		} catch {
			validUrl = false;
		}

		return validUrl ? null : {invalidUrl: true};
	};
	newLinkUrl = this.fb.control('', this.urlValidator);
	private readonly subscriptions = new Subscription();

	constructor(
		@Inject(CURRENT_MODAL) readonly modalRef: ModalRef,
		private readonly fb: FormBuilder,
		private readonly popOverService: PopoverService,
	) {}

	linksGroup = computed(() => {
		const linksFormArray: FormArray = this.fb.array([]);
		this.links().forEach((linkItem) => {
			linksFormArray.push(
				this.fb.group({
					url: this.fb.control(linkItem.url, this.urlValidator),
					linkTitle: this.fb.control(linkItem.linkTitle),
				}),
			);
		});

		return this.fb.group({
			links: linksFormArray,
		});
	});

	ngOnInit() {
		this.previewStore.loadLinks(this.documentId());
		this.subscriptions.add(
			this.modalRef.closed$
				.pipe(
					tap(() => {
						const linksFormGroup = this.getLinksFormArray(this.linksGroup());
						this.links().forEach((linkItem, index) => {
							this.updateTitleIfChanged(
								linkItem,
								linksFormGroup[index].get('linkTitle'),
							);
							this.updateUrlIfChanged(linkItem, linksFormGroup[index].get('url'));
						});
					}),
				)
				.subscribe(),
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	addNew(event?: Event): void {
		event?.preventDefault();
		this.newLinkUrl.markAsDirty();
		this.newLinkUrl.updateValueAndValidity();

		if (this.newLinkUrl.valid && !!this.newLinkUrl.value) {
			this.previewStore.addLink({
				documentId: this.documentId(),
				url: this.newLinkUrl.value,
			});

			this.newLinkUrl.patchValue('');
			this.newLinkUrl.reset();
		}
	}

	updateUrlIfChanged(link: DocLink, linkControl: AbstractControl | null): void {
		if (linkControl != null && linkControl.valid && link.url !== linkControl.value) {
			this.updateLink({
				...link,
				url: linkControl.value,
			});
		}
	}

	updateTitleIfChanged(link: DocLink, linkControl: AbstractControl | null): void {
		if (linkControl != null && link.linkTitle !== linkControl.value) {
			this.updateLink({
				...link,
				linkTitle: linkControl.value,
			});
		}
	}

	updateLink(link: DocLink): void {
		this.previewStore.updateLink({
			documentId: this.documentId(),
			link,
		});
	}

	openDeleteConfirmation(event: Event, link: DocLink): void {
		const deleteConfirmation = this.popOverService.openPopOver(
			DeleteConfirmationComponent,
			{
				itemToDeleteName: link.url,
				itemToDeleteType: 'link',
			},
			event.target as HTMLElement,
			'left',
			true,
		);

		this.subscriptions.add(
			deleteConfirmation.confirmDeletingEmitter
				.pipe(
					tap(() => {
						this.deleteLink(link.id);
					}),
				)
				.subscribe(),
		);
	}

	closeDialog(): void {
		this.modalRef.close();
	}

	drop(links: DocLink[], event: CdkDragDrop<DocLink[]>): void {
		const currentLink = links[event.previousIndex];

		this.previewStore.moveLink({
			documentId: this.documentId(),
			link: currentLink,
			newIndex: event.currentIndex,
		});
	}

	getLinksFormArray(group: FormGroup): FormGroup<{linkTitle: FormControl; url: FormControl}>[] {
		return (group.get('links') as FormArray).controls as FormGroup<{
			linkTitle: FormControl;
			url: FormControl;
		}>[];
	}

	private deleteLink(linkId: number): void {
		this.previewStore.deleteLink({
			documentId: this.documentId(),
			linkId,
		});
	}
}
