<shared-autocomplete
	placeholder="Enter document link URL (Copy Link or Preview page URL)"
	[class.invalid]="!searchQuery.valid"
	[formControl]="searchQuery"
>
	@if (documents$ | async; as documents) {
		@if (documents.length) {
			@for (document of documents; track document.id) {
				<shared-option (selectionChange)="triggerSelect(document)">
					<div class="option-container">
						<search-item-card
							[item]="document"
							[size]="'small'"
							[showActions]="false"
							[direction]="'horizontal'"
						></search-item-card>

						<div class="option-cover"></div>
					</div>
				</shared-option>
			}
		} @else {
			@if (searchQuery.value && searchQuery.valid) {
				<shared-option class="no-results">
					<pp-icon name="notification-error-fill-18"></pp-icon>
					<span>Document not found. Please add this link to Useful Links widget.</span>
				</shared-option>
			}
		}
	}
</shared-autocomplete>
@if (!searchQuery.valid) {
	<span class="invalid-message">The provided link URL is invalid. Please enter a valid URL.</span>
}
