<div class="user-info-container">
	@if (userInfo.avatarUrl && !loadingErrorExist) {
		<img
			class="user-info-photo"
			loading="lazy"
			[src]="userInfo.avatarUrl"
			(load)="handleImgError('loaded')"
			(error)="handleImgError('error')"
			alt="User avatar"
		/>
	} @else {
		<div
			class="user-info-photo"
			[ngClass]="{
				'person-fallback': userInfo.externalId,
				'contact-group-fallback': !userInfo.externalId,
				'pending-fallback': loadingErrorExist === undefined
			}"
		></div>
	}
	@if (loadingErrorExist === undefined) {
		<ng-container [ngTemplateOutlet]="fallbackIcon"></ng-container>
	}

	<ng-template #fallbackIcon>
		<div
			class="user-info-photo"
			[ngClass]="{
				'person-fallback': userInfo.externalId,
				'contact-group-fallback': !userInfo.externalId,
				'pending-fallback': loadingErrorExist === undefined
			}"
		></div>
	</ng-template>
	<div class="user-info-data">
		<div class="user-info-data-header">
			<span>
				{{ userInfo.name }}
			</span>
			@if (telescopeLink) {
				<a
					class="user-info-link telescope"
					title="Visit Telescope"
					[href]="telescopeLink"
					target="_blank"
					(click)="trackEvent('telescope')"
				>
					<pp-icon name="logos_telescope_18" />
				</a>
			}
			@if (msTeamsLink) {
				<a
					class="user-info-link teams"
					title="Chat in Microsoft Teams"
					[href]="msTeamsLink"
					target="_blank"
					(click)="trackEvent('teams')"
				>
					<pp-icon name="logos_microsoft_teams_18" />
				</a>
			}
			@if (mailToLink) {
				<a
					class="user-info-link teams"
					title="Mail to Group"
					[href]="mailToLink"
					target="_blank"
					(click)="trackEvent('mailTo')"
				>
					<pp-icon name="communication-mail-18" />
				</a>
			}
		</div>
		<span class="user-info-data-subtitle">{{ userInfo.expertise || userInfo.jobTitle }}</span>
	</div>
</div>
