import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	OnDestroy,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';

import {distinctUntilChanged, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {ThumbnailComponent} from '../thumbnail/thumbnail.component';
import {RecommendSlidesViewmodel} from './recommend-slides.viewmodel';
import {SvgImageComponent} from '@pp/svg';
import {SpinnerComponent} from '@pp/spinner/spinner.component';
import {SearchResultModel} from '@shared/models';
import {PostQueryService, RecommendSlidesService, ResultsService} from '@shared/services';
import {UiStore} from '@shared/state';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'shared-recommend-slides',
	templateUrl: './recommend-slides.component.html',
	styleUrls: ['./recommend-slides.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SvgImageComponent, ThumbnailComponent, SpinnerComponent, AsyncPipe],
})
export class RecommendSlidesComponent implements OnInit, OnChanges, OnDestroy {
	private readonly subscriptions = new Subscription();
	private readonly rowLimit = 50;
	private filePath$ = new BehaviorSubject('');

	slides$!: Observable<RecommendSlidesViewmodel[]>;
	selectedSlide?: RecommendSlidesViewmodel;
	devMode = inject(UiStore).devMode;
	loaded = false;

	@Input({required: true}) base!: SearchResultModel;
	@Input() visible = false;
	@Output() selectSlide = new EventEmitter<SearchResultModel>();
	@Output() replaceSlide = new EventEmitter<SearchResultModel>();

	constructor(
		private postQuery: PostQueryService,
		private recommendedSlidesService: RecommendSlidesService,
		private resultsService: ResultsService,
		private analytics: AnalyticsService,
		private element: ElementRef,
	) {}

	ngOnInit(): void {
		this.slides$ = this.filePath$.pipe(
			distinctUntilChanged(),
			switchMap(() => this.recommendedSlidesService.getQuery$(this.base.filePath)),
			switchMap((recommendSlides) => {
				let result$: Observable<RecommendSlidesViewmodel[]>;

				if (
					recommendSlides &&
					recommendSlides.recommendationsOrder &&
					recommendSlides.recommendationsOrder.length
				) {
					result$ = this.postQuery
						.retrieve$(
							{
								query: recommendSlides.recommendationsQuery,
								sortBy: 'Size',
								sortDirection: 1,
								disableQueryRules: true,
							},
							{rowLimit: this.rowLimit},
						)
						.pipe(
							map((response) => {
								const data = response
									? this.resultsService.extractData(
											response.d.postquery.PrimaryQueryResult.RelevantResults,
										).data
									: [];

								return data
									.map((value) => {
										const order = recommendSlides.recommendationsOrder.find(
											(item) =>
												value.signature
													? value.signature.includes(item.signature)
													: false,
										);

										return {
											...value,
											signatureScore: order?.score ?? 0,
										};
									})
									.sort((a, b) => b.signatureScore - a.signatureScore);
							}),
						);
				} else {
					result$ = of([]);
				}

				return result$;
			}),
			shareReplay({bufferSize: 1, refCount: true}),
		);
		this.subscriptions.add(this.slides$.pipe(tap(this.reset.bind(this))).subscribe());
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnChanges(): void {
		this.filePath$.next(this.base.filePath);
		this.loaded = this.visible || this.loaded;
	}

	selectRecommendation(document: SearchResultModel): void {
		this.selectedSlide = document as RecommendSlidesViewmodel;
		this.selectSlide.emit(document);
		this.analytics.trackPreviewAction('selectSmartContextSlide');
	}

	update(): void {
		this.replaceSlide.emit(this.selectedSlide);
		this.analytics.trackPreviewAction('updateSmartContext');
	}

	private reset(): void {
		this.selectedSlide = this.base as RecommendSlidesViewmodel;
		this.loaded = this.visible;
		this.scrollToTop();
	}

	private scrollToTop(): void {
		if (this.visible) {
			setTimeout(() => {
				const base = this.element.nativeElement.querySelector('.recommend-slides-base');

				if (base) {
					base.scrollIntoView();
				}
			});
		}
	}
}
