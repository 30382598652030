@if (isVisible()) {
	<div class="histogram-filter-container">
		<search-filter-header
			[ngClass]="{expanded: isExpanded}"
			[title]="config().name"
			[showResetButton]="showReset()"
			(resetEventEmitter)="resetFilter()"
			(toggleExpandEventEmitter)="toggleCollapsing()"
		></search-filter-header>

		@if (isExpanded) {
			<shared-range-slider
				class="histogram-filter-range-slider"
				[filterName]="fieldName()"
				[bars]="bars()"
				[rangeInput]="selectedRange()"
				(rangeChange)="onRangeChange($event)"
			></shared-range-slider>
		}
	</div>
}
