import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import {map, Observable} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {FormatPipe} from '../../pipes';
import {CardsSwiperComponent} from '@shared/card-containers';
import {HintDirective} from '@pp/hint';
import {PpSearchQuery, PpSearchResultItemOfDocumentIndexItem} from '@shared/api';
import {SearchQueryService, SearchStateSerializerService} from '@search2/services';
import {PresetLinkModel, ResponseFragment} from '@search2/models';
import {AnalyticsService} from '@shared/analytics';
import {SvgIconComponent} from '@pp/svg';
import {PresetLinkComponent} from '@search2/components';
import {SearchItemCardComponent} from '@preview/item-card';

@Component({
	selector: 'home-preset-section',
	standalone: true,
	imports: [
		CardsSwiperComponent,
		FormatPipe,
		SvgIconComponent,
		HintDirective,
		AsyncPipe,
		SearchItemCardComponent,
		PresetLinkComponent,
	],
	templateUrl: './preset-section.component.html',
	styleUrls: ['./preset-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresetSectionComponent implements OnInit {
	@Input({required: true}) header!: string;
	@Input({required: true}) description!: string;
	@Input({required: true}) query!: PpSearchQuery;
	@Input() verified = false;

	items$!: Observable<ResponseFragment<PpSearchResultItemOfDocumentIndexItem>>;

	constructor(
		private readonly searchQueryService: SearchQueryService,
		private readonly searchStateSerializer: SearchStateSerializerService,
		private readonly analytics: AnalyticsService,
	) {}

	ngOnInit(): void {
		this.items$ = this.searchQueryService.search$(this.query).pipe(
			map((searchResults) => ({
				items: searchResults.results,
				totalCount: searchResults.totalCount,
			})),
		);
	}

	getShowMoreLink(): PresetLinkModel {
		return {
			presetId: this.query.presetId,
			query: this.searchStateSerializer.getQueryObject({
				selectedFilters: this.query.facetFilters ?? {},
				orderByExpression: this.query.orderBy,
			}),
		};
	}

	trackShowMore(title: string): void {
		this.analytics.trackDashboardAction('showMore', title);
	}
}
