import {
	ChangeDetectionStrategy,
	Component,
	computed,
	ElementRef,
	HostBinding,
	inject,
	Input,
	OnInit,
	Renderer2,
} from '@angular/core';
import {NgClass} from '@angular/common';
import {PreviewAccountInfoComponent} from '../preview-account-info/preview-account-info.component';
import {PreviewSourceDocumentComponent} from '../preview-source-document/preview-source-document.component';
import {PreviewRecommendedItemsComponent} from '../preview-recommended-items/preview-recommended-items.component';
import {PreviewCardsWidgetComponent} from '../preview-cards-widget/preview-cards-widget.component';
import {PreviewCasesCollectionComponent} from '../preview-cases/preview-cases.component';
import {PreviewMetadataComponent} from '../preview-metadata/preview-metadata.component';
import {PreviewActionsComponent} from '../preview-actions/preview-actions.component';
import {PreviewHighlightsDescriptionComponent} from '../preview-highlights-description/preview-highlights-description.component';
import {PreviewDocumentComponent} from '../preview-document/preview-document.component';
import {PreviewContactsComponent} from '../preview-contacts/preview-contacts.component';
import {PreviewLinkedDocumentsComponent} from '../preview-linked-documents/preview-linked-documents.component';
import {PreviewLinksWidgetComponent} from '../preview-links/preview-links.component';
import {PreviewVideoComponent} from '../preview-video/preview-video.component';
import {PreviewRequest, PreviewStore} from '../preview.store';
import {NoteComponent} from '@shared/components/notes/note.component';
import {SharedSkeletonComponent} from '@pp/skeleton';
import {SvgIconComponent} from '@pp/svg';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';
import {DocumentIndexItem} from '@shared/api';

@Component({
	selector: 'search-preview-dialog',
	templateUrl: './preview-dialog.component.html',
	styleUrls: ['./preview-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () =>
				new ContextualAnalyticsService(inject(AnalyticsService), 'Document Preview'),
		},
	],
	standalone: true,
	imports: [
		PreviewDocumentComponent,
		PreviewHighlightsDescriptionComponent,
		PreviewActionsComponent,
		PreviewMetadataComponent,
		PreviewCasesCollectionComponent,
		PreviewCardsWidgetComponent,
		PreviewRecommendedItemsComponent,
		NoteComponent,
		PreviewVideoComponent,
		PreviewSourceDocumentComponent,
		PreviewContactsComponent,
		PreviewAccountInfoComponent,
		PreviewLinkedDocumentsComponent,
		PreviewLinksWidgetComponent,
		SharedSkeletonComponent,
		SvgIconComponent,
		NgClass,
	],
})
export class PreviewDialogComponent implements OnInit {
	@Input({required: true}) previewRequest!: PreviewRequest;

	@HostBinding('class.preview-extends')
	expandedPreview = false;

	private readonly previewStore = inject(PreviewStore);

	isDocumentLoaded = computed(() => this.previewStore.status() == 'loaded');
	isContactsLoaded = computed(() => this.previewStore.contactsStatus() == 'loaded');
	isLinkedDocumentsLoaded = computed(() => this.previewStore.linkedDocumentsStatus() == 'loaded');
	isLinksLoaded = computed(() => this.previewStore.linksStatus() == 'loaded');

	docDetails = this.previewStore.docDetails;
	hasOwners = computed(
		() => this.isContactsLoaded() && this.previewStore.contacts.owners.items().length > 0,
	);

	relatedDocs = this.previewStore.relatedDocuments;
	prevDocument = this.previewStore.prevDocument;
	nextDocument = this.previewStore.nextDocument;
	hasPrevNext = computed(() => this.prevDocument() || this.nextDocument());

	constructor(
		private renderer: Renderer2,
		private readonly eRef: ElementRef,
		private readonly analyticsService: AnalyticsService,
	) {}

	ngOnInit(): void {
		this.previewStore.loadPreviewDocument(this.previewRequest);
	}

	toggleExpandedMode(expanded: boolean): void {
		this.expandedPreview = expanded;
	}

	setGridRowHeight(height: number): void {
		const previewContainer = this.eRef.nativeElement.querySelector('.preview-container');
		this.renderer.setStyle(
			previewContainer,
			'grid-template-rows',
			`minmax(0, ${height}px) 1fr`,
		);
	}

	openPreview(item: DocumentIndexItem): void {
		this.analyticsService.trackDocumentPreviewAction('open:next-prev');

		this.previewStore.loadPreviewDocument({
			...this.previewRequest,
			documentId: item.id,
		});
	}
}
