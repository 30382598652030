<li [class.include]="true">
	<span class="search-filters-selected-list-count"
		>{{ appliedFilterModel.filters.length }}
		<div class="search-filters-selected-list-show">
			<ul>
				@for (filter of appliedFilterModel.filters; track filter.token) {
					<search-applied-filter
						[appliedFilterModel]="filter"
						[hasCloseButton]="false"
					></search-applied-filter>
				}
			</ul>
		</div>
	</span>

	<span class="search-filters-selected-list-name"
		>{{ appliedFilterModel.filterName | filterSectionName }}
	</span>

	@if (hasCloseButton) {
		<button
			class="dp-button-null search-filters-selected-list-remove-filter"
			(click)="removeEmitter.emit()"
		>
			<pp-icon name="navigation_close_18" />
		</button>
	}
</li>
