import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {LoginComponent} from './login.component';
import {RedirectComponent} from './redirect.component';
import {userProfileGuard$} from '@core/guards';
import {SearchComponent} from '@search/components';
import {SearchPageComponent} from '@search2/components';
import {HomePageComponent} from '@dashboard/components';
import {ErrorPageComponent} from '@core/components';
import {
	SlideTagsPageComponent,
	TemplatesPageComponent,
	ReusableSlidesPageComponent,
} from '@office/components';
import {PreviewDialogPageComponent} from '@preview/document';
import {PreviewSlidesDialogPageComponent} from '@preview/show-slides';

@NgModule({
	imports: [
		RouterModule.forRoot(
			[
				{
					path: 'login',
					component: LoginComponent,
				},
				{
					path: 'login-blank',
					component: LoginComponent,
				},
				{
					path: 'error/:statusCode',
					component: ErrorPageComponent,
				},
				{
					path: '',
					canActivate: [userProfileGuard$],
					children: [
						{
							path: '',
							children: [
								{
									path: 'p/:presetId',
									component: SearchPageComponent,
								},
								{
									path: 'preview/:documentId',
									component: PreviewDialogPageComponent,
								},
								{
									path: 'preview-slides/:documentId',
									component: PreviewSlidesDialogPageComponent,
								},
								{
									path: 'powerpoint',
									children: [
										{
											path: 'home',
											// Entry route for PowerPoint add-in
											redirectTo: 'dashboard',
										},
										{
											path: 'templates',
											component: TemplatesPageComponent,
										},
										{
											path: 'reusable-slides',
											component: ReusableSlidesPageComponent,
										},
										{
											path: 'slide-tags',
											component: SlideTagsPageComponent,
										},
									],
								},
								{
									path: '',
									children: [
										{
											path: '',
											redirectTo: 'redirect',
											pathMatch: 'full',
										},
										{
											path: 'redirect',
											component: RedirectComponent,
										},
										{
											path: 'search/grid',
											component: RedirectComponent,
										},
										{
											path: 'search',
											component: SearchComponent,
										},
										{
											path: 'dashboard',
											component: HomePageComponent,
										},
										{
											path: '**',
											redirectTo: 'dashboard',
										},
									],
								},
							],
						},
					],
				},
			],
			{scrollPositionRestoration: 'top', anchorScrolling: 'enabled'},
		),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
