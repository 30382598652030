<ul class="query-samples-list">
	<li (click)="search('hybris')">hybris</li>
	<li (click)="search('&quot;change management&quot;')">
		<mark>"</mark>change management<mark>"</mark>
	</li>
	<li (click)="search('warehouse -&quot;data warehouse&quot;')">
		warehouse <mark>-"</mark>data warehouse<mark>"</mark>
	</li>
	<li (click)="search('title:Chevron')"><mark>title:</mark>Chevron</li>
	<li (click)="search('title:Azure OR title:Cloud')">
		<mark>title:</mark>Azure <mark>OR</mark>&nbsp;<mark>title:</mark>Cloud
	</li>
	<li (click)="search('logo:chevron')"><mark>logo:</mark>chevron</li>
	<li (click)="search('html NEAR flash')">html <mark>NEAR</mark> flash</li>
</ul>
