export const filtersMapping = {
	languages: 'languages',
	documentTags: 'usppDocumentTags',
	sourceSystem: 'usppSourceSystem',
	locationFilterTree: 'usppLocationFilterTree',
	contentOwner: 'usppContentOwner',
	preparedFor: 'usppPreparedFor',
	about: 'usppAbout',
	accessLevel: 'usppAccessLevel',
	masterLayout: 'usppSlideMasterLayout',
	fileContentType: 'FileType',
	fileType: 'FileExtension',
	size: 'Size',
	modifiedDate: 'Write',
	displayAuthor: 'DisplayAuthor',
	tags: 'Tags',
};
