import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	computed,
	inject,
	input,
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {HighlightPipe} from '@shared/pipes/highlight.pipe';
import {DocumentIndexItem} from '@shared/api';
import {PreviewService} from '@preview/services';

@Component({
	selector: 'search-title-verified',
	templateUrl: './title-verified.component.html',
	styleUrls: ['./title-verified.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class TitleVerifiedComponent {
	@HostBinding('class.card-title') isCardTitle = true;

	item = input.required<DocumentIndexItem>();

	href = computed(() => this.previewService.getPreviewPageUrl(this.item(), true));
	safeTitle = computed(() => {
		const title = this.highlightPipe.transform(this.item().title);

		return this.domSanitizer.bypassSecurityTrustHtml(title);
	});

	private domSanitizer = inject(DomSanitizer);
	private previewService = inject(PreviewService);
	private highlightPipe = inject(HighlightPipe);
}
