import {Injectable} from '@angular/core';

import {VisitHistoryModel} from './visit-history.model';

@Injectable({providedIn: 'root'})
export class VisitedService {
	private storageKey = 'uspp.visit-history';
	private files = new Set();

	constructor() {
		this.files = new Set(
			JSON.parse(localStorage.getItem(this.storageKey) || '[]').map(
				(item: VisitHistoryModel) => item.path,
			),
		);
	}

	mark(filePath: string): void {
		this.files.clear();
		const newState = this.mergeStorage(filePath).filter((item) => {
			if (!this.files.has(item.path)) {
				this.files.add(item.path);

				return true;
			}

			return false;
		});
		localStorage.setItem(this.storageKey, JSON.stringify(newState));
	}

	check(filePath: string): boolean {
		return this.files.has(filePath);
	}

	private mergeStorage(filePath: string): VisitHistoryModel[] {
		const storage: VisitHistoryModel[] = JSON.parse(
			localStorage.getItem(this.storageKey) || '[]',
		).map((item: VisitHistoryModel) => ({
			...item,
			date: new Date(item.date),
		}));
		storage.push({path: filePath, date: new Date()});
		storage.sort((a, b) => b.date.valueOf() - a.date.valueOf());

		return storage;
	}
}
