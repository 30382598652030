export const selectedFiltersInitialState = {
	include: {
		languages: [],
		documentTags: [],
		sourceSystem: [],
		locationFilterTree: [],
		contentOwner: [],
		preparedFor: [],
		about: [],
		fileContentType: [],
		fileType: [],
		size: [],
		modifiedDate: [],
		displayAuthor: [],
		tags: [],
		accessLevel: [],
		masterLayout: [],
	},
	exclude: {
		languages: [],
		documentTags: [],
		sourceSystem: [],
		locationFilterTree: [],
		contentOwner: [],
		preparedFor: [],
		about: [],
		fileContentType: [],
		fileType: [],
		size: [],
		modifiedDate: [],
		displayAuthor: [],
		tags: [],
		accessLevel: [],
		masterLayout: [],
	},
};
