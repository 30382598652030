import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {AsyncPipe} from '@angular/common';
import {QueryState, SearchFeatureState} from '../../reducers/search-state';
import {AdminPanelComponent} from '../admin-panel/admin-panel.component';
import {UserPanelComponent} from '../user-panel/user-panel.component';
import {SearchHistoryComponent} from '../search-history/search-history.component';
import {PanelType} from './panel-type';
import {UserStore} from '@shared/state';
import {ImpersonateService} from '@shared/services';
import {SubmitQueryAction, ToggleExpandDuplicatesAction} from '@search/actions';
import {UseThemeDirective} from '@shared/directives';
import {AnalyticsService} from '@shared/analytics';
import {CheckboxComponent} from '@pp/checkbox';
import {SvgIconComponent} from '@pp/svg';
import {HasTeamPipe} from '@core/roles';

@Component({
	selector: 'search-action-panel',
	templateUrl: './action-panel.component.html',
	styleUrls: ['./action-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		UseThemeDirective,
		SearchHistoryComponent,
		UserPanelComponent,
		CheckboxComponent,
		AdminPanelComponent,
		SvgIconComponent,
		AsyncPipe,
		HasTeamPipe,
	],
})
export class ActionPanelComponent implements OnInit {
	currentPanel: PanelType = 'none';
	expandDuplicates$!: Observable<boolean>;
	query$!: Observable<string | undefined>;
	queryState$!: Observable<QueryState>;
	teams = inject(UserStore).profile.teams;
	impersonated!: boolean;

	constructor(
		private store$: Store<SearchFeatureState>,
		private analytics: AnalyticsService,
		private impersonate: ImpersonateService,
	) {}

	ngOnInit(): void {
		this.currentPanel = 'none';
		this.query$ = this.store$.select((state) => state.search.query.submittedQuery);
		this.queryState$ = this.store$.select((state) => state.search.query);
		this.expandDuplicates$ = this.store$.select((state) => state.search.query.expandDuplicates);
		this.impersonated = !!this.impersonate.get();
	}

	togglePanel(panelName: PanelType): void {
		if (this.currentPanel === panelName) {
			this.analytics.trackPanelsActions('close', panelName);
			this.currentPanel = 'none';
		} else {
			this.analytics.trackPanelsActions('open', panelName);
			this.currentPanel = panelName;
		}
	}

	changeQuery(query: string): void {
		this.store$.dispatch(new SubmitQueryAction(query));
	}

	toggleExpandDuplicates(target: EventTarget | null, query: string | undefined): void {
		const disable = (target as HTMLInputElement).checked;
		this.store$.dispatch(new ToggleExpandDuplicatesAction(!disable));
		this.store$.dispatch(new SubmitQueryAction(query));
		if (disable) {
			this.analytics.trackUserPanelActions('expandDuplicates');
		}
	}
}
