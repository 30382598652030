import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {SitemapWidgetComponent} from '../sitemap-widget/sitemap-widget.component';
import {SearchNavigationComponent} from '../search-navigation/search-navigation.component';
import {UseThemeDirective} from '@shared/directives';
import {CONFIG} from '@environments/environment';

@Component({
	selector: 'dashboard-top-section',
	standalone: true,
	imports: [SearchNavigationComponent, SitemapWidgetComponent, UseThemeDirective],
	templateUrl: './dashboard-top-section.component.html',
	styleUrls: ['./dashboard-top-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardTopSectionComponent {
	config = inject(CONFIG);
}
