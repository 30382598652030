import {patchState, signalStore, withMethods, withState} from '@ngrx/signals';

import {ToastMessageModel} from '@shared/models';

export interface ToastState {
	toasts: ToastMessageModel[];
}

const toastInitialState: ToastState = {
	toasts: [],
};

let toastGuid = 0;

export const ToastStore = signalStore(
	{providedIn: 'root'},
	withState(toastInitialState),
	withMethods((store) => {
		return {
			addToastMessage(toast: ToastMessageModel): void {
				patchState(store, (state) => {
					const tostExists = state.toasts.some(
						(t) => t.type === toast.type && t.message === toast.message,
					);

					return tostExists
						? {}
						: {
								toasts: [
									...state.toasts,
									{
										...toast,
										id: toastGuid++,
									},
								],
							};
				});
			},
			updateProgress(marker: string, progress: number): void {
				let newProgress = progress;
				if (newProgress < 0) {
					newProgress = 0;
				} else if (newProgress > 100) {
					newProgress = 100;
				}

				patchState(store, (state) => ({
					toasts: state.toasts.map((toast) => {
						if (toast.marker === marker) {
							return {
								...toast,
								progress: newProgress,
							};
						} else {
							return toast;
						}
					}),
				}));
			},
			updateMessage(marker: string, message: string): void {
				patchState(store, (state) => ({
					toasts: state.toasts.map((toast) => {
						if (toast.marker === marker) {
							return {
								...toast,
								message,
							};
						} else {
							return toast;
						}
					}),
				}));
			},
			removeToastById(index: number): void {
				patchState(store, (state) => ({
					toasts: state.toasts.filter((toast) => toast.id !== index),
				}));
			},
			removeToastByMarker(marker: string): void {
				patchState(store, (state) => ({
					toasts: state.toasts.filter((toast) => toast.marker !== marker),
				}));
			},
		};
	}),
);
