import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PageProgressBarComponent} from '../page-progress-bar/page-progress-bar.component';
import {UseThemeDirective} from '@shared/directives';

@Component({
	selector: 'shared-presales-portal-loading',
	templateUrl: './presales-portal-loading.component.html',
	styleUrls: ['./presales-portal-loading.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [PageProgressBarComponent, UseThemeDirective],
})
export class PresalesPortalLoadingComponent {}
