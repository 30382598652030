<div class="dp-search-bar">
	<pp-icon class="dp-search-bar-search-icon" name="action-search-18"></pp-icon>
	<input
		#inputControl
		type="text"
		class="input-search"
		[placeholder]="placeholder"
		autocomplete="off"
		autocorrect="off"
		(blur)="onBlur()"
		(focus)="onFocus()"
		[formControl]="control"
	/>
	<pp-icon
		class="dp-search-bar-clear"
		name="navigation-close-18"
		(click)="controlReset()"
	></pp-icon>
</div>
<ng-template>
	<shared-panel>
		<ng-content></ng-content>
	</shared-panel>
</ng-template>
