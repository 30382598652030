import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BehaviorSubject, Observable, map, switchMap} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {PreviewCardsWidgetComponent} from '../preview-cards-widget/preview-cards-widget.component';
import {DocumentsFragment} from '@search2/models';
import {SearchQueryService} from '@search2/services';

@Component({
	selector: 'search-preview-recommended-items',
	templateUrl: './preview-recommended-items.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [PreviewCardsWidgetComponent, AsyncPipe],
})
export class PreviewRecommendedItemsComponent {
	@Input()
	set documentIds(documentIds: string[]) {
		this.documentIds$.next(documentIds);
	}

	documentIds$ = new BehaviorSubject<string[]>([]);
	collection$: Observable<DocumentsFragment> = this.documentIds$.pipe(
		switchMap((documentIds) => this.searchService.searchByIds$(documentIds)),
		map(
			(searchResult) =>
				({
					items: searchResult,
					totalCount: searchResult.length,
				}) as DocumentsFragment,
		),
	);

	constructor(private readonly searchService: SearchQueryService) {}
}
