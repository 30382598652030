import {Inject, Injectable} from '@angular/core';
import {CONFIG, EnvConfigType} from '@environments/environment';

export type UserRole =
	| 'admin'
	| 'moderator'
	| 'trusted'
	| 'stories-team'
	| 'toolbox-team'
	| 'pipeline-team';

@Injectable({providedIn: 'root'})
export class RoleService {
	constructor(@Inject(CONFIG) private readonly config: EnvConfigType) {}

	isUserInRole(teams: string[] | null | undefined, role: UserRole): boolean {
		if (!teams) {
			// If teams are not loaded yet, we assume the user is not in any role
			return false;
		}

		if (teams.includes('#/teams/ui/admins')) {
			// If the user is in the admins team, they are in all roles
			return true;
		}

		if (teams.includes('#/teams/ui/developers') && this.config.environment !== 'prod') {
			// If the user is in the developers team, they are in all roles (on non-prod environments)
			return true;
		}

		switch (role) {
			case 'admin':
				return false;
			case 'moderator':
				return teams.includes('#/teams/ui/moderators');
			case 'trusted':
				return teams.includes('#/teams/trusted-users');
			case 'stories-team':
				return teams.includes('#/teams/ui/stories');
			case 'toolbox-team':
				return teams.includes('#/teams/ui/toolbox');
			case 'pipeline-team':
				return teams.includes('#/teams/ui/presales-report');
			default:
				return false;
		}
	}
}
