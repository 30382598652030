import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CONFIG, EnvConfigType} from '@environments/environment';

import {impersonate} from '@shared/decorators';
import {SearchHistoryItemModel} from '@shared/models';

@Injectable({providedIn: 'root'})
export class SearchHistoryService {
	constructor(
		private http: HttpClient,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	@impersonate
	getSearchModel$(email?: string): Observable<SearchHistoryItemModel[]> {
		const fullUrl = `${this.config.serviceUrl}/api/search/history/100${
			email ? `?email=${encodeURIComponent(email)}` : ''
		}`;

		return this.http.get<SearchHistoryItemModel[]>(fullUrl, {headers: this.prepareHeaders()});
	}

	getRaw$(email: string): Observable<SearchHistoryItemModel[]> {
		const fullUrl = `${
			this.config.serviceUrl
		}/api/search/rawhistory/10000?email=${encodeURIComponent(email)}`;

		return this.http.get<SearchHistoryItemModel[]>(fullUrl, {headers: this.prepareHeaders()});
	}

	private prepareHeaders(): HttpHeaders {
		const headers = new HttpHeaders({
			Accept: 'application/json',
			'Content-Type': 'application/json',
		});

		return headers;
	}
}
