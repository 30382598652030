import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {FooterComponent} from '../footer/footer.component';
import {DashboardTopSectionComponent} from '../dashboard-top-section/dashboard-top-section.component';
import {WhatsNewComponent} from '../whats-new/whats-new.component';
import {UseThemeDirective} from '@shared/directives';
import {UserStore} from '@shared/state';
import {CONFIG} from '@environments/environment';

@Component({
	selector: 'home-web-layout',
	standalone: true,
	imports: [DashboardTopSectionComponent, WhatsNewComponent, FooterComponent, UseThemeDirective],
	templateUrl: './web-home-layout.component.html',
	styleUrls: ['./web-home-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebHomeLayoutComponent {
	theme = inject(UserStore).settings.uiTheme;
	config = inject(CONFIG);
}
