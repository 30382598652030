import {ChangeDetectionStrategy, Component, OnInit, inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgTemplateOutlet} from '@angular/common';
import {UseThemeDirective} from '@shared/directives';
import {UserStore} from '@shared/state';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'app-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [UseThemeDirective, NgTemplateOutlet],
})
export class ErrorPageComponent implements OnInit {
	route = inject(ActivatedRoute);
	theme = inject(UserStore).settings.uiTheme;
	readonly analytics = inject(AnalyticsService);
	statusCode!: string;

	ngOnInit() {
		this.statusCode = this.route.snapshot.params['statusCode'];
		this.analytics.trackErrorPage(`statusCode=${this.statusCode}`);
	}
	reload(): void {
		window.location.reload();
	}
}
