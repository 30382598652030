import {Injectable} from '@angular/core';

import {FilterService} from '../filter-factory/filter-service';
import {FilterModel} from '@search/models';
import {HexDecodePipe} from '@shared/pipes';
import {Refinement} from '@shared/services';
import {FileTypeMappingService} from '@shared/api';

@Injectable({providedIn: 'root'})
export class FileTypeFilterService implements FilterService {
	constructor(
		private fileTypeMapping: FileTypeMappingService,
		private hexDecode: HexDecodePipe,
	) {}

	convert(data: Refinement[]): FilterModel[] {
		return data.map((refinement, index) => ({
			token: refinement.RefinementToken,
			name: this.prepareName(refinement.RefinementToken),
			value: refinement.RefinementValue,
			count: +refinement.RefinementCount,
			order: index,
		}));
	}

	prepareName(token: string): string {
		return this.fileTypeMapping.map(this.hexDecode.transform(token));
	}
}
