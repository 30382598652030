import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterLink} from '@angular/router';

@Component({
	selector: 'shared-access-info-popover',
	templateUrl: './access-info-popover.component.html',
	styleUrls: ['./access-info-popover.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterLink],
})
export class AccessInfoPopoverComponent {}
