import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
	inject,
	computed,
	input,
} from '@angular/core';
import {RouterLink} from '@angular/router';
import {ButtonComponent} from '@pp/button';
import {UseThemeDirective} from '@shared/directives';
import {AnalyticsService} from '@shared/analytics';
import {SearchStore} from '@search2/state';

@Component({
	selector: 'search-subheader',
	templateUrl: './search-subheader.component.html',
	styleUrls: ['./search-subheader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [UseThemeDirective, ButtonComponent, RouterLink],
})
export class SearchSubheaderComponent {
	presetId = input.required<string>();
	isPreviewPage = input<boolean>(false);

	@Output() toggleMobileFilterEmitter = new EventEmitter<boolean>();

	private searchStore = inject(SearchStore);
	private analytics = inject(AnalyticsService);

	hasSelectedFilters = computed(() => {
		const selectedFilters = this.searchStore.searchQuery.selectedFilters();
		const selectedFiltersCount = Object.values(selectedFilters)
			.map((filterState) => filterState.length)
			.reduce((acc, curr) => acc + curr, 0);

		return selectedFiltersCount > 0;
	});

	isFiltersOpened = false;

	toggleFilters(): void {
		this.isFiltersOpened = !this.isFiltersOpened;
		this.toggleMobileFilterEmitter.emit(this.isFiltersOpened);
	}

	trackBack(): void {
		this.analytics.trackDocumentCatalogAction('open:back-to-catalog');
	}
}
