import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import {map, pairwise, startWith} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {WINDOW} from './tokens/window.token';
import {NetworkService} from './services';
import {StatusType} from '@core/models';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'core-network-status',
	templateUrl: './network-status.component.html',
	styleUrls: ['./network-status.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [AsyncPipe],
})
export class NetworkStatusComponent {
	currentHref = '';

	constructor(
		private readonly network: NetworkService,
		@Inject(WINDOW) private readonly window: Window,
		private readonly analytics: AnalyticsService,
	) {}

	isOffline$: Observable<boolean> = this.network.status$.pipe(
		startWith<StatusType>('online'),
		pairwise<StatusType>(),
		map(([previousStatus, currentStatus]: [StatusType, StatusType]) => {
			const isOnline = currentStatus === 'online';

			this.currentHref = this.window.location.href;

			if (previousStatus === 'offline' && isOnline) {
				this.analytics.trackBackOnline();
			}

			return !isOnline;
		}),
	);
}
