import {BreakpointState} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {interval, Observable, ReplaySubject} from 'rxjs';
import {debounce, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {AndBreakpointRule, MaxBreakpointRule, MinBreakpointRule, OrBreakpointRule} from '../rules';
import {
	BreakpointsType,
	LayoutStrategyInterface,
	BreakpointRulesType,
	BreakpointRuleInterface,
} from '@shared/layout';
import {ResizeObserverWrapper} from '@shared/decorators';

const BREAKPOINT_REGEX = /(?<rule>\((?<option>m(?:ax|in))-width: (?<value>\d+)px\))/gi;
const BREAKPOINT_OPERATOR_REGEX = /\) (?<operator>and|or) \(/i;

@Injectable({providedIn: 'root'})
export class ContainerStrategy implements LayoutStrategyInterface {
	set breakpoints(breakpoints: BreakpointsType) {
		this.#breakpoints = this.breakpointParser(breakpoints);
	}

	get breakpoints(): BreakpointsType {
		return this.#breakpoints.map((breakpoint) => breakpoint.toString());
	}

	get observer$(): Observable<BreakpointState> {
		if (!this.#observer$) {
			this.#observer$ = this.createObserver$();
		}

		return this.#observer$;
	}

	private readonly destroy$ = new ReplaySubject<void>(1);
	#breakpoints: BreakpointRulesType = [];
	#breakpointState: BreakpointState = {matches: false, breakpoints: {}};
	#observer$!: Observable<BreakpointState>;

	element!: Element;

	createObserver$(): Observable<BreakpointState> {
		const observable$ = new Observable<BreakpointState>((subscriber) => {
			const resizeObserver$ = new ResizeObserverWrapper(([entry]) => {
				const contentWidth = entry.contentRect.width;
				const state = {matches: false, breakpoints: {...this.#breakpointState.breakpoints}};

				for (const breakpoint of this.#breakpoints) {
					const matched = breakpoint.isMatch(contentWidth);

					if (matched) {
						state.matches = matched;
					}

					state.breakpoints[breakpoint.raw] = matched;
				}

				subscriber.next(state);
				this.#breakpointState = state;
			});

			resizeObserver$.observe(this.element);

			return (): void => {
				resizeObserver$.unobserve(this.element);
				this.#breakpointState.matches = false;

				for (const [breakpoint] of Object.entries(this.#breakpointState.breakpoints)) {
					this.#breakpointState.breakpoints[breakpoint] = false;
				}
			};
		});

		return observable$.pipe(
			debounce(() => interval(100)),
			distinctUntilChanged((prev, current) => {
				if (prev.matches !== current.matches) {
					return false;
				}

				let resultMatched = true;

				for (const [breakpoint, value] of Object.entries(prev.breakpoints)) {
					if (current.breakpoints[breakpoint] !== value) {
						resultMatched = false;
						break;
					}
				}

				return resultMatched;
			}),
			takeUntil(this.destroy$),
		);
	}

	destroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	breakpointParser(breakpoints: BreakpointsType): BreakpointRulesType {
		const rules: BreakpointRulesType = [];

		for (const breakpoint of breakpoints) {
			this.#breakpointState.breakpoints[breakpoint] = false;
			let buffer: BreakpointRuleInterface[] = [];

			for (const match of breakpoint.matchAll(BREAKPOINT_REGEX)) {
				const option = match.groups ? match.groups['option'] : '';
				const value = match.groups ? match.groups['value'] : '';
				const rule =
					option === 'max'
						? new MaxBreakpointRule(+value, breakpoint)
						: new MinBreakpointRule(+value, breakpoint);

				if (!buffer) {
					buffer = [rule];
				} else {
					buffer.push(rule);
				}
			}

			if (buffer.length > 1) {
				const match = breakpoint.match(BREAKPOINT_OPERATOR_REGEX);
				const [left, right] = buffer;
				const operator = match?.groups ? match.groups['operator'] : '';

				const rule =
					operator === 'and'
						? new AndBreakpointRule(left, right, breakpoint)
						: new OrBreakpointRule(left, right, breakpoint);

				rules.push(rule);

				continue;
			}

			rules.push(...buffer);
		}

		return rules;
	}
}
