import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
	selector: 'powerpoint-info-block',
	standalone: true,
	imports: [],
	templateUrl: './info-block.component.html',
	styleUrl: './info-block.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBlockComponent {}
