import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	ViewChild,
	OnInit,
	Renderer2,
	OnDestroy,
	inject,
	computed,
	Signal,
} from '@angular/core';
import {NgClass, UpperCasePipe, DecimalPipe} from '@angular/common';
import {SettingsButtonsComponent} from '../../search-subheader/settings-buttons/settings-buttons.component';
import {CheckboxSelectionComponent} from '@shared/selection/checkbox-selection/checkbox-selection.component';
import {UseThemeDirective} from '@shared/directives';
import {FilterOperator} from '@shared/api';
import {SearchStore} from '@search2/state';
import {FilterValuePipe} from '@shared/pipes';
import {ResizeObserverWrapper} from '@shared/decorators';
import {SvgIconComponent} from '@pp/svg';
import {ButtonComponent} from '@pp/button';

@Component({
	selector: 'search-context',
	templateUrl: './search-context.component.html',
	styleUrls: ['./search-context.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgClass,
		SvgIconComponent,
		ButtonComponent,
		UseThemeDirective,
		CheckboxSelectionComponent,
		SettingsButtonsComponent,
		UpperCasePipe,
		DecimalPipe,
	],
})
export class SearchContextComponent implements OnInit, OnDestroy {
	resizeObserver$!: ResizeObserverWrapper;

	private searchStore = inject(SearchStore);
	searchResultItems = computed(() => this.searchStore.searchResults()?.items ?? []);
	documentIndexItems = computed(() => this.searchResultItems().map((x) => x.document));

	searchResultTotalCount = computed(() => this.searchStore.searchResults()?.totalCount ?? 0);
	currentPreset = computed(() => this.searchStore.currentPreset());

	selectedFilters: Signal<SelectedFacetVm[]> = computed(() => {
		const selectedFiltersObj = this.searchStore.searchQuery.selectedFilters() ?? {};
		const currentPreset = this.currentPreset();

		return Object.entries(selectedFiltersObj)
			.filter(([, selectedValues]) => !!selectedValues.length)
			.map(([fieldName, selectedValues]) => {
				const facetConfig = currentPreset?.facetConfigs.find(
					(item) => item.fieldName === fieldName,
				);

				const selectedFilters = selectedValues.map((filterValue) => ({
					displayName: this.filterValuePipe.formatFilterValue(filterValue, fieldName),
					filterValue,
					isExcluded: filterValue.startsWith('!'),
					resetFilterValues: selectedValues.filter(
						(x) => x.startsWith(filterValue) || x.startsWith(`!${filterValue}`),
					),
				}));

				return {
					facetName: facetConfig?.name ?? 'Unknown',
					fieldName,
					selectedFilters,
					filterOperator: facetConfig?.filterOperator ?? 'or',
					filterCaption:
						facetConfig?.filterOperator === 'or'
							? 'The item matched at least one of the filters'
							: 'The item matched all of the filters',
				};
			});
	});

	constructor(
		private readonly element: ElementRef,
		private readonly renderer: Renderer2,
	) {}
	private readonly filterValuePipe = inject(FilterValuePipe);

	@ViewChild('contextWidget')
	set contextWidget(contextWidgetContainer: ElementRef) {
		if (contextWidgetContainer) {
			this.resizeObserver$.disconnect();
			this.resizeObserver$.observe(contextWidgetContainer.nativeElement);
		}
	}

	ngOnInit() {
		this.resizeObserver$ = new ResizeObserverWrapper(([entry]) => {
			const countContainerWidth = this.element.nativeElement.querySelector(
				'.results-count-container',
			).clientWidth;
			const settingsButtonsWidth =
				this.element.nativeElement.querySelector('.settings-buttons')?.clientWidth ?? 0;
			const filtersLisContainer = this.element.nativeElement.querySelector('.filters-list');

			const availableWidth =
				entry.target.clientWidth - countContainerWidth - settingsButtonsWidth - 60;

			if (!!filtersLisContainer && availableWidth < filtersLisContainer.clientWidth) {
				this.renderer.setStyle(
					entry.target,
					'grid-template-areas',
					"'count-container settings-buttons' 'filters-list filters-list'",
				);
			} else {
				this.renderer.setStyle(
					entry.target,
					'grid-template-areas',
					"'count-container filters-list settings-buttons'",
				);
			}
		});
	}

	ngOnDestroy() {
		this.resizeObserver$.disconnect();
	}

	resetFilterGroup(fieldName: string): void {
		this.searchStore.resetFilters(fieldName);
	}

	removeFilter(fieldName: string, selectedFilter: SelectedFilterVm): void {
		this.searchStore.removeFilterList(fieldName, selectedFilter.resetFilterValues);
	}

	resetAll(): void {
		this.searchStore.resetAllFilters();
	}
}

class SelectedFacetVm {
	facetName!: string;
	fieldName!: string;
	selectedFilters!: SelectedFilterVm[];
	filterOperator!: FilterOperator;
	filterCaption!: string;
}

class SelectedFilterVm {
	displayName!: string;
	isExcluded!: boolean;
	resetFilterValues!: string[];
}
