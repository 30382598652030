import {Injectable} from '@angular/core';

import {AnalyticsService} from '@shared/analytics';

@Injectable({providedIn: 'root'})
export class ThumbnailService {
	private authorizedDomains: Dictionary<Promise<void>> = {
		'presales.epam.com': new Promise((resolve) => {
			resolve();
		}),
		'stage.presales.epam.com': new Promise((resolve) => {
			resolve();
		}),
		'info.epam.com': new Promise((resolve) => {
			resolve();
		}),
		'showcase.epam.com': new Promise((resolve) => {
			resolve();
		}),
	};

	constructor(private analytics: AnalyticsService) {}

	authorize(thumbnail: string): Promise<void> {
		const domain = new URL(thumbnail).host;
		let result: Promise<void>;

		if (thumbnail.includes('access_token') || thumbnail.includes('access%5Ftoken')) {
			result = new Promise((resolve) => {
				resolve();
			});
		} else {
			result =
				this.authorizedDomains[domain] ||
				new Promise((resolve) => {
					const frame = document.createElement('iframe');

					frame.src = thumbnail;
					frame.classList.add('off-screen');
					frame.onload = (): void => {
						this.analytics.trackSearchResultsAction('requestThumbnail', thumbnail);

						resolve();
					};
					document.body.appendChild(frame);
				});
		}

		this.authorizedDomains[domain] = result;

		return result;
	}
}
