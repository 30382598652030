// Moved to separate file to reduce service file size and because it doesn't contain any useful information.

export const hiddenConstraints = [
	'IsContainer<>true', // exclude folders
	// '-contentclass:STS_ListItem_Links',
	// '-contentclass:STS_List_Links',
	'-contentclass:STS_List_850',
	// '-contentclass:STS_Document',
	'-contentclass:STS_List_DocumentLibrary',
	// '-contentclass:STS_List_WebPageLibrary',
	// '-contentclass:STS_ListItem_WebPageLibrary',
	'-contentclass:STS_Web',
	// '-contentclass:STS_Site',
	// '-contentclass:STS_List_544',
	'-FileExtension:aspx',
].join(' ');
