import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'cloud-thumbnail',
	standalone: true,
	templateUrl: './cloud-thumbnail.component.html',
	styleUrls: ['./cloud-thumbnail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class CloudThumbnailComponent {
	@Input({required: true}) value!: string;

	handleError(target: EventTarget | null): void {
		const targetElement = target as HTMLElement;
		targetElement.remove();
	}
}
