import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Observable} from 'rxjs';

import {Store} from '@ngrx/store';
import {AsyncPipe, DecimalPipe} from '@angular/common';
import {SearchFeatureState} from '@search/reducers/search-state';

@Component({
	selector: 'search-results-count',
	templateUrl: './results-count.component.html',
	styleUrls: ['./results-count.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [AsyncPipe, DecimalPipe],
})
export class ResultsCountComponent {
	private store$ = inject(Store<SearchFeatureState>);

	resultsCount$: Observable<number> = this.store$.select(
		(state) => state.search.results.countWithDuplicates,
	);
}
