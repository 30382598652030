import {Injectable} from '@angular/core';

import {buildQueryTemplate} from './build-query-template';
import {filtersMapping} from './filters-mapping';
import {hiddenConstraints} from './hidden-constraints';
import {RefinementsFilters, RequestData, RequestOptions} from './request-data';
import {ResultsList, SearchRequest, SearchRequestData} from './search-request';
import {selectProperties} from './select-properties';
import {HexDecodePipe} from '@shared/pipes';

/**
 * Request builder class contains logic specific for SharePoint.
 *
 * @see {@link http://blogs.msdn.com/b/nadeemis/archive/2012/08/24/sharepoint-2013-search-rest-api.aspx|Search Results}
 * @see {@link https://docs.microsoft.com/en-us/sharepoint/dev/general-development/sharepoint-workflow-fundamentals|Workflow Fundamentals}
 */
@Injectable({providedIn: 'root'})
export class SearchRequestBuilderService {
	private readonly searchResultsRowLimit = 30;
	private readonly duplicatesRowLimit = 500;
	private readonly summaryLength = 260;
	private readonly defaultQuery = '*';
	private readonly hitHighlightedProperties = ['usppAutoTitle', 'FileName'];

	constructor(private hexDecode: HexDecodePipe) {}

	build(data: RequestData, options: BuildOptions): SearchRequest {
		const {refinementsOnly = false, startFrom = 0, userQuery = false} = options;
		const groupId = data.groupId ?? '';
		const enableQueryTemplate =
			!data.disableQueryTemplate && data.sortBy !== 'RoleAgnosticRelevancy';
		const request: SearchRequestData = {
			ClientType: userQuery ? 'ContentSearchRegular' : 'Custom',
			EnableQueryRules: !data.disableQueryRules,
			EnableSorting: true,
			HiddenConstraints: hiddenConstraints,
			HitHighlightedProperties: {
				results: this.hitHighlightedProperties,
			},
			Querytext: data.query || this.defaultQuery,
			QueryTemplate:
				enableQueryTemplate && options.queryTemplate
					? buildQueryTemplate(
							data,
							options.queryTemplate,
							this.hexDecode.transform.bind(this.hexDecode),
						)
					: '',
			RefinementFilters: this.buildRefinementsFilters(data),
			Refiners: options.refiners,
			SelectProperties: {
				results:
					groupId || data.expandDuplicates
						? selectProperties.filter((value) => value !== 'fcocount')
						: selectProperties,
			},
			TrimDuplicates: !data.expandDuplicates,
			SummaryLength: this.summaryLength,
		};

		this.setRowLimit(request, refinementsOnly, groupId, options.rowLimit);
		this.setStartRow(request, startFrom);
		this.setSortBy(request, refinementsOnly, data);
		this.setGroupId(request, groupId);

		return {request};
	}

	private wrapFilters(filters: string[], operator: string): string {
		return filters.length > 1 ? `${operator}(${filters.join(',')})` : `${filters.join(',')}`;
	}

	private transformFilters(
		filters: RefinementsFilters = {},
		include: boolean,
	): {[name: string]: string[]} {
		const result: {[name: string]: string[]} = {};

		Object.keys(filters).forEach((filter) => {
			if (!result[filter]) {
				result[filter] = [];
			}

			const filterName: string = filtersMapping[filter as keyof typeof filtersMapping];

			filters[filter as keyof typeof filters]?.forEach((token: string) => {
				result[filter].push(
					include ? `${filterName}:${token}` : `not(${filterName}:${token})`,
				);
			});
		});

		return result;
	}

	/**
	 * Builds the refinements filters.
	 *
	 * @see {@link http://msdn.microsoft.com/en-us/library/office/ff394639(v=office.15).aspx#SP15_Adding_refiners|Query Refinements}
	 * @see {@link http://msdn.microsoft.com/en-us/library/ff394606.aspx#fql_int_operator|FQL syntax}
	 */
	private buildRefinementsFilters(data: RequestData): ResultsList<string> {
		const inclusiveRefinements = [
			'fileType',
			'fileContentType',
			'Write',
			'contentOwner',
			'sourceSystem',
			'Size',
			'accessLevel',
		];
		const includeFilters = this.transformFilters(data.includeFilters, true);
		const excludeFilters = this.transformFilters(data.excludeFilters, false);

		const resultArray: string[] = [];
		Object.keys(includeFilters).forEach((filter) => {
			const inclusive = inclusiveRefinements.indexOf(filter) >= 0;
			const list = includeFilters[filter];

			if (inclusive) {
				resultArray.push(this.wrapFilters(list, 'or'));
			} else {
				resultArray.push(...list);
			}
		});
		Object.keys(excludeFilters).forEach((filter) =>
			resultArray.push(...excludeFilters[filter]),
		);

		return {
			results: [
				this.wrapFilters(
					resultArray.filter((value) => !!value),
					'and',
				),
			],
		};
	}

	private setRowLimit(
		request: SearchRequestData,
		refinementsOnly: boolean,
		groupId: string,
		rowLimit?: number,
	): void {
		if (refinementsOnly) {
			request.RowLimit = 1;
		} else if (groupId) {
			request.RowLimit = this.duplicatesRowLimit;
		} else if (rowLimit) {
			request.RowLimit = rowLimit;
		} else {
			request.RowLimit = this.searchResultsRowLimit;
		}
	}

	private setStartRow(request: SearchRequestData, startFrom: number): void {
		if (startFrom > 0) {
			request.StartRow = startFrom;
		}
	}

	private setSortBy(
		request: SearchRequestData,
		refinementsOnly: boolean,
		data: RequestData,
	): void {
		if (data.sortBy && data.sortBy !== 'RoleAgnosticRelevancy' && !refinementsOnly) {
			request.SortList = {
				results: [
					{
						Property: data.sortBy,
						Direction: data.sortDirection,
					},
				],
			};
		}
	}

	private setGroupId(request: SearchRequestData, groupId: string): void {
		if (groupId) {
			request.TrimDuplicatesIncludeId = groupId;
		}
	}
}

interface BuildOptions extends RequestOptions {
	refiners?: string;
	queryTemplate?: string;
}
