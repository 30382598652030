<article class="slides-collector">
	<div
		class="slides-collector-list-content"
		cdkDropList
		[cdkDropListAutoScrollDisabled]="true"
		(cdkDropListDropped)="drop($event, items())"
	>
		@for (item of items(); track item.id) {
			<div
				class="slides-collector-list-item"
				ppCdkUseTheme="dark"
				cdkDrag
				cdkDragBoundary=".slides-collector-list-content"
			>
				<div cdkDragHandle class="slides-collector-list-drag-handle">
					<pp-icon
						[size]="18"
						name="drag-drop-18"
						class="slides-collector-list-item-icon"
					></pp-icon>
				</div>

				<div class="slides-collector-list-item-content">
					<search-item-card
						[item]="item"
						size="medium"
						direction="horizontal"
						[context]="items()"
					></search-item-card>
				</div>
			</div>
		}
	</div>
</article>
