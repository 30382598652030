import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {AlertComponent} from '@pp/alert';
import {SearchStore} from '@search2/state';

@Component({
	selector: 'search-no-results',
	templateUrl: './no-results.component.html',
	styleUrls: ['./no-results.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [AlertComponent],
})
export class NoResultsComponent {
	query = input.required<string>();

	private readonly searchStore = inject(SearchStore);
	isAlertVisible = computed(() => {
		const currentPreset = this.searchStore.currentPreset();

		return currentPreset?.id === 'cases';
	});
}
