import {NgClass} from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostListener,
	OnDestroy,
	OnInit,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
	Inject,
} from '@angular/core';
import {Subscription} from 'rxjs';

import {tap} from 'rxjs/operators';
import {CdkCopyToClipboard} from '@angular/cdk/clipboard';
import {SlideNumberPipe} from '../../pipes/slide-number.pipe';
import {FileSizePipe, EncodePipe} from '../../pipes';
import {PreviewComponent} from '../preview/preview.component';
import {RecommendSlidesComponent} from '../recommend-slides/recommend-slides.component';
import {SlideContextComponent} from '../slide-context/slide-context.component';
import {CURRENT_MODAL, ModalRef} from '@shared/modals';
import {SvgIconComponent} from '@pp/svg';
import {CopyLinkService, RecommendSlidesService} from '@shared/services';
import {PreviewModel, SearchResultModel} from '@shared/models';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'shared-preview-menu',
	templateUrl: './preview-menu.component.html',
	styleUrls: ['./preview-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		NgClass,
		SvgIconComponent,
		CdkCopyToClipboard,
		SlideContextComponent,
		RecommendSlidesComponent,
		PreviewComponent,
		EncodePipe,
		FileSizePipe,
	],
	providers: [SlideNumberPipe],
})
export class PreviewMenuComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();

	navigatedDocument?: SearchResultModel;
	recommendationsQuery?: string;
	asideVisible = false;
	slideIndex: number | null = null;
	linkCopied = false;
	tab: 'context' | 'recommend' = 'context';
	goodRecommendations = false;
	navigationAvailable = false;
	hasContext = false;

	@Input({required: true}) model!: SearchResultModel;
	@Input() data?: PreviewModel;
	@Input({required: true}) context!: SearchResultModel[];
	@Output() queueDownload = new EventEmitter();
	@Output() queueDownloadSource = new EventEmitter();

	constructor(
		private analytics: AnalyticsService,
		private slideNumberPipe: SlideNumberPipe,
		private recommendedSlidesService: RecommendSlidesService,
		private detector: ChangeDetectorRef,
		private copyLink: CopyLinkService,
		@Inject(CURRENT_MODAL) private readonly modalRef: ModalRef,
	) {}

	ngOnInit(): void {
		this.navigate(this.model);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	closePreview(): void {
		this.modalRef.close();
	}

	download(document: SearchResultModel): void {
		this.queueDownload.emit(document);
	}

	downloadSource(document: SearchResultModel): void {
		this.queueDownloadSource.emit(document);
	}

	navigate(document: SearchResultModel): void {
		this.navigatedDocument = document;

		if (document) {
			this.slideIndex = this.extractSlideNumber();
			this.updateGoodRecommendationsStatus();
			this.checkNavigationAvailability();
		} else {
			this.asideVisible = false;
		}
	}

	replace(document: SearchResultModel): void {
		this.model = document;
		this.navigate(document);
	}

	toggleAside(): void {
		this.asideVisible = !this.asideVisible;

		if (this.asideVisible) {
			this.analytics.trackPreviewAction('openContext');
		}

		window.dispatchEvent(new Event('resize'));
	}

	@HostListener('window:keyup', ['$event'])
	handleKeyup(event: KeyboardEvent): void {
		if (event.key === 'Escape') {
			this.closePreview();
		} else if (event.key === 'ArrowLeft') {
			this.showPrevious();
		} else if (event.key === 'ArrowRight') {
			this.showNext();
		}
	}

	switchTab(tab: 'context' | 'recommend'): void {
		this.tab = tab;
		this.analytics.trackPreviewAction(
			tab === 'context' ? 'selectSourceDocumentTab' : 'selectSmartContextTab',
		);
	}

	showPrevious(): void {
		this.analytics.trackPreviewAction('toPrevDoc');
		this.navigateToDocument(-1);
	}

	showNext(): void {
		this.analytics.trackPreviewAction('toNextDoc');
		this.navigateToDocument(1);
	}

	handleCopiedLink(copied: boolean): void {
		this.linkCopied = copied;
		this.copyLink.handleCopied(copied);
		this.analytics.trackPreviewAction('copyLink');
	}

	private navigateToDocument(offset: number): void {
		if (!this.context.length || !this.navigatedDocument) {
			return;
		}
		let index = this.context.findIndex(
			(result) => result.documentId === this.navigatedDocument?.documentId,
		);

		do {
			index += offset;
			if (index >= this.context.length) {
				index = 0;
			}

			if (index < 0) {
				index = this.context.length - 1;
			}
		} while (!this.context[index].fullPreview);

		this.replace(this.context[index]);
	}

	private extractSlideNumber(): number | null {
		let result: number | null = null;

		if (
			this.navigatedDocument?.sourceFilePath &&
			this.navigatedDocument?.fileType === 'PowerPoint'
		) {
			const slideIndex = this.slideNumberPipe.transform(this.navigatedDocument.filePath);

			if (!isNaN(slideIndex)) {
				result = slideIndex;
			}
		}

		return result;
	}

	private updateGoodRecommendationsStatus(): void {
		if (this.navigatedDocument && this.navigatedDocument.slidesCount === 1) {
			const goodRecommendations = 4;

			this.subscriptions.add(
				this.recommendedSlidesService
					.getQuery$(this.navigatedDocument.filePath)
					.pipe(
						tap((data) => {
							this.asideVisible = !this.asideVisible
								? !!data.contextQuery
								: this.asideVisible;
							window.dispatchEvent(new Event('resize'));
							this.hasContext = !!data.recommendationsQuery || !!data.contextQuery;
							this.goodRecommendations =
								data.recommendationsOrder &&
								data.recommendationsOrder.some(
									(t) => t.score > goodRecommendations,
								);
							this.detector.markForCheck();
						}),
					)
					.subscribe(),
			);
		} else {
			this.hasContext = false;
		}
	}

	private checkNavigationAvailability(): void {
		const hasPreview = this.context && this.context.some((result) => !!result.fullPreview);

		this.navigationAvailable =
			hasPreview &&
			this.context.some((result) => result.documentId === this.navigatedDocument?.documentId);
	}
}
