import {Injectable, inject} from '@angular/core';
import {DocumentIndexItem, PpHighlightableValue} from './api-clients-angular.g';
import {SearchItemType} from './document-item-type.model';
import {FileTypeMappingService} from './file-type-mapping.service';

@Injectable({providedIn: 'root'})
export class DocumentItemParserService {
	private readonly fileTypeMapping = inject(FileTypeMappingService);

	getItemType(item: DocumentIndexItem): SearchItemType {
		switch (item.accessType) {
			case 'limited':
				return 'limited-access';
			case 'notFound':
				return 'deleted';
			default:
				switch (item.spoPresales?.contentType) {
					case 'EPAM Story':
						return 'story';
					case 'Presales Offering':
						return 'offering';
					case 'Presales Case Study':
						return 'case';
					case 'WinBoxItem':
						return 'toolbox';
					default:
						return 'document';
				}
		}
	}

	getPresetIdByItemType(itemType: SearchItemType): string | null {
		switch (itemType) {
			case 'story':
				return 'stories';
			case 'offering':
				return 'offerings';
			case 'case':
				return 'cases';
			case 'toolbox':
				return 'toolbox';
			default:
				return null;
		}
	}

	isVerified(item: DocumentIndexItem): boolean {
		return item.spoPresales?.reviewStatus === 'Verified';
	}

	isVerifiable(item: DocumentIndexItem): boolean {
		const type = this.getItemType(item);

		return type === 'story' || type === 'case';
	}

	isAutoGenerated(item: DocumentIndexItem): boolean {
		return item.file?.generated?.sourceDocId !== undefined;
	}

	isPowerPoint(item: DocumentIndexItem): boolean {
		const extension = item.file?.extension ?? '';

		return extension === 'pptx' || extension === 'potx';
	}

	getBestOfTags(item: DocumentIndexItem): PpHighlightableValue[] {
		return item.spoPresales?.tags.filter((tag) => this.isBestOfTag(tag)) ?? [];
	}

	isBestOfTag(tag: PpHighlightableValue): boolean {
		return tag.rawValue.startsWith('Best of');
	}

	getOpenInAppSchema(item: DocumentIndexItem): string | null {
		const extension = item.file?.extension;
		if (!extension) {
			return null;
		}

		switch (this.fileTypeMapping.map(extension)) {
			case 'PowerPoint':
				return 'ms-powerpoint:ofe|u|';
			case 'Word':
				return 'ms-word:ofe|u|';
			case 'Excel':
				return 'ms-excel:ofe|u|';
			default:
				return null;
		}
	}
}
