import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	HostBinding,
	Input,
	OnInit,
	Output,
	EventEmitter,
} from '@angular/core';
import {NgTemplateOutlet} from '@angular/common';
import {TreeViewItemDirective} from '../tree-view-item.directive';
import {TreeNodeContext} from '../types/tree-node.context';
import {TreeNode} from '../types/tree-node.interface';
import {SvgIconComponent} from '@pp/svg';
import {HasIdInterface} from '@shared/models/has-id.interface';

@Component({
	selector: 'shared-tree-view',
	templateUrl: './tree-view.component.html',
	styleUrls: ['./tree-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent, NgTemplateOutlet, TreeViewItemDirective],
})
export class TreeViewComponent<T extends HasIdInterface> implements OnInit {
	@Input({required: true}) tree!: TreeNode<T>;
	@Input() level = 0;

	@ContentChild(TreeViewItemDirective)
	readonly itemView!: TreeViewItemDirective<T>;

	readonly nextLevel = this.level + 1;

	@Input()
	@HostBinding('class.expanded')
	expanded = true;

	isRoot = true;

	@HostBinding('class.have-children')
	isHaveChildren = false;

	@Input() selected!: T;

	@Output()
	selectElement = new EventEmitter<T>();

	ngOnInit(): void {
		this.isRoot = this.tree.parent === null;
		this.isHaveChildren = Array.isArray(this.tree.children);
	}

	context(node: unknown): TreeNodeContext<T> {
		return new TreeNodeContext(node as T);
	}

	trackNode(_: number, {payload: node}: TreeNode<T>): string | number {
		return node.id;
	}

	toggleExpand(event: MouseEvent): void {
		this.expanded = !this.expanded;

		event.stopPropagation();
		event.preventDefault();
	}

	selectNode(node: T): void {
		this.selectElement.emit(node);
	}

	get viewChild(): boolean {
		return this.isHaveChildren && this.expanded;
	}

	isSelected(node: T): boolean {
		return node.id === this.selected?.id;
	}
}
