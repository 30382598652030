<table class="confidential-logos-content">
	<tr>
		<th>CRM Link</th>
		<th>Status</th>
		<th class="confidential-logos-informer">Ref. in public</th>
		<th class="confidential-logos-informer">Ref. under NDA</th>
		<th class="confidential-logos-informer">Logo can be used</th>
		<th>Account manager</th>
	</tr>
	@for (logo of accountLogos; track logo.id) {
		<tr>
			<td>
				<pp-icon
					class="image-icon"
					name="image"
					[size]="16"
					(mouseenter)="showLogo(logo.id)"
					(mouseleave)="hideLogo()"
				></pp-icon>
				<a
					href=""
					target="_blank"
					(click)="trackCrmNavigation(logo.name)"
					>{{ logo.name }}</a
				>
				@if (logo.id === accountId) {
					<img
						class="confidential-logos-preview"
						src="{{ serviceUrl }}/api/images/{{ logo.md5 }}"
					/>
				}
			</td>
			<td>{{ logo.status }}</td>
			<td>
				<pp-icon
					[name]="getIconName(logo, 'public')"
					[class]="getIconName(logo, 'public') + '-icon'"
					[size]="18"
					aria-hidden="true"
					[title]="logo.referenceInPublicComment"
				></pp-icon>
			</td>
			<td>
				<pp-icon
					[name]="getIconName(logo, 'nda')"
					[class]="getIconName(logo, 'nda') + '-icon'"
					[size]="18"
					aria-hidden="true"
					[title]="logo.referenceUnderNdaComment"
				></pp-icon>
			</td>
			<td>
				<pp-icon
					[name]="getIconName(logo, 'logo')"
					[class]="getIconName(logo, 'logo') + '-icon'"
					[size]="18"
					aria-hidden="true"
					[title]="logo.logoCanBeUsedComment"
				></pp-icon>
			</td>
			<td>
				<a href="mailto:{{ logo.managerEmail }}">{{ logo.managerName }}</a>
			</td>
		</tr>
	}
</table>
