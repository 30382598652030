import {Action} from '@ngrx/store';

import {FilterModel} from '@search/models';

export const UPDATE_DOCUMENT_TAGS_FILTER = '[Search] Update Document Tags Filter';

export class UpdateDocumentTagsFilterAction implements Action {
	readonly type = UPDATE_DOCUMENT_TAGS_FILTER;

	constructor(public payload: FilterModel[]) {}
}
