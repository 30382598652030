<div class="no-results-wrapper">
	<header class="header">
		We did not find any results
		<span class="query">for "{{ query() }}"</span>
	</header>
	<div class="no-results-content">
		<div>
			<header>Suggestions:</header>
			<ul class="no-results-content-list">
				<li>Make sure that all words are spelled correctly.</li>
				<li>Try different keywords.</li>
				<li>Try to remove/reset filters.</li>
			</ul>
		</div>
		<div class="no-results-content-image"></div>
	</div>

	@if (isAlertVisible()) {
		<pp-alert [status]="'info'" otherIcon="notification-info-fill-24">
			<span
				>Didn’t find what you were looking for? Let us know what case Study you are missing
				by submitting
				<a
					class="no-results-alert-link"
					href="https://forms.office.com/r/1cGZfFQJzQ"
					target="_blank"
					rel="noopener"
					>this form</a
				>
			</span>
		</pp-alert>
	}
</div>
