<div class="account-container dp-card">
	<header class="section-header">Account</header>

	@switch (type()) {
		@case ('Crm Account') {
			<main class="account-info">
				<div class="account-info-marker" [class.active]="active()"></div>
				<header>
					<div>
						<span>{{ name() }}</span>
						<a
							class="account-info-link"
							[href]="telescopeLink()"
							title="Visit Telescope"
						>
							<pp-icon
								class="social-icon telescope"
								name="logos_telescope_18"
								[size]="18"
							></pp-icon>
						</a>
						<a
							class="account-info-link"
							[href]="crmLink()"
							(click)="trackLink('account-crm')"
							*ppAnchor="'account-crm'"
							title="Open in CRM"
						>
							<pp-icon
								class="social-icon crm"
								name="content_crm_18"
								[size]="18"
							></pp-icon>
						</a>
					</div>
				</header>
				<div class="account-status">
					@if (active()) {
						<span class="account-status-active">Current Client</span>
					} @else {
						<span class="account-status-inactive">Former Client</span>
					}
					<div class="dp-dot dp-dot-18"></div>
					<search-preset-link
						[link]="casesLink()"
						(beforeNavigation)="trackLink('account-cases-pp')"
					>
						Show all Case Studies
					</search-preset-link>
				</div>
			</main>

			@if (account().accountManager; as manager) {
				<main class="account-manager">
					<search-preview-user-info [userInfo]="manager"></search-preview-user-info>
				</main>
			}
			@if (account().caseStudyUsage || account().caseStudyUsageComments) {
				<div
					class="usage-permission"
					[ngClass]="usagePermissionStyle(account().caseStudyUsage)"
				>
					@if (account().caseStudyUsage) {
						<h4>Public Case Study Usage</h4>
						<p>{{ account().caseStudyUsage }}</p>
					}
					@if (account().caseStudyUsageComments) {
						<h4>Usage Comments</h4>
						<p>{{ account().caseStudyUsageComments }}</p>
					}
					<pp-icon
						hint="When using these materials, adhere to the case study usage policy specified on the CRM account page."
						maxWidth="200px"
						hintPosition="TOP"
						name="notification-help-outline-24"
						[size]="16"
					></pp-icon>
				</div>
			}
			<div class="account-projects">
				Projects
				<div class="account-divider"></div>
			</div>
			@if (account().projects && account().projects.length > 0) {
				@for (project of account().projects; track project.code) {
					<search-project-info
						[project]="project"
						[accountAm]="account().accountManager?.name ?? ''"
					></search-project-info>
				}
			}
		}

		@case ('Pre-Acquisition Client') {
			<main class="account-info">
				<div class="account-info-marker"></div>
				<header class="account-info-link">
					{{ name() }}
				</header>
				<div class="account-status">
					@if (!active()) {
						<span class="account-status-inactive">Former Client</span>
					}
				</div>
			</main>
		}

		@case ('Unknown') {
			<main class="account-info">
				<div class="account-info-marker unknown"></div>
				<header class="account-info-link">
					{{ name() }}
				</header>
				<div class="account-status">
					<span class="account-status-unknown">Unknown</span>
				</div>
			</main>
			<div>
				In case you know details about this case study, please, click the "Request changes"
				button and send them to us.
			</div>
		}
	}
</div>
