import {ChangeDetectionStrategy, Component, inject, input} from '@angular/core';
import {
	CdkDragDrop,
	moveItemInArray,
	CdkDropList,
	CdkDrag,
	CdkDragHandle,
} from '@angular/cdk/drag-drop';
import {UseThemeDirective} from '@shared/directives';
import {SearchItemCardComponent} from '@preview/item-card';
import {SvgIconComponent} from '@pp/svg';
import {SelectionStore} from '@shared/selection';
import {DocumentIndexItem} from '@shared/api';

@Component({
	selector: 'deck-builder-section',
	templateUrl: './deck-builder-section.component.html',
	styleUrls: ['./deck-builder-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CdkDropList,
		UseThemeDirective,
		CdkDrag,
		CdkDragHandle,
		SvgIconComponent,
		SearchItemCardComponent,
	],
})
export class DeckBuilderSectionComponent {
	items = input.required<DocumentIndexItem[]>();
	private selectionStore = inject(SelectionStore);

	drop(event: CdkDragDrop<unknown>, items: DocumentIndexItem[]): void {
		const documentIds = items.map((item: DocumentIndexItem) => item.id);
		moveItemInArray(documentIds, event.previousIndex, event.currentIndex);

		this.selectionStore.changeOrder(documentIds);
	}
}
