import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Output,
	inject,
	input,
} from '@angular/core';
import {SvgIconComponent} from '@pp/svg';
import {SortOrderConfig} from '@shared/api';
import {DropdownComponent} from '@shared/components/dropdown/dropdown.component';
import {UiStore} from '@shared/state';

@Component({
	selector: 'search-order-by',
	templateUrl: './search-order-by.component.html',
	styleUrls: ['./search-order-by.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [DropdownComponent, SvgIconComponent],
})
export class SearchOrderByComponent {
	expanded!: boolean;

	list = input.required<SortOrderConfig[]>();
	value = input.required<SortOrderConfig>();
	@Output() changeOrder = new EventEmitter<SortOrderConfig>();

	isMobile = inject(UiStore).window.isMobileMode;

	constructor(private element: ElementRef) {}

	open(): void {
		this.expanded = !this.expanded;
	}

	@HostListener('window:click', ['$event'])
	close(event: Event): void {
		if (!this.element.nativeElement.contains(event.target)) {
			this.expanded = false;
		}
	}

	select(item: SortOrderConfig): void {
		this.expanded = false;
		this.changeOrder.emit(item);
	}
}
