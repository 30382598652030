import {DOCUMENT} from '@angular/common';
import {
	Directive,
	ElementRef,
	Inject,
	Input,
	OnInit,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import {AnalyticsService} from '@shared/analytics';

@Directive({
	selector: 'ppAnchor, [ppAnchor]',
	standalone: true,
})
export class AnchorDirective implements OnInit {
	#element!: ElementRef<HTMLElement>;
	#when = true;
	#anchor = '';
	#at = 'pp-anchor';

	@Input('ppAnchor')
	set anchor(anchor: string) {
		if (anchor !== this.#anchor) {
			this.#anchor = anchor;
		}
	}

	@Input('ppAnchorWith')
	set with(anchorWith: string | undefined | null) {
		if (anchorWith !== this.#anchor && anchorWith !== undefined && anchorWith !== null) {
			try {
				this.#anchor = AnchorDirective.generateArchonAttributeName(
					this.#anchor,
					anchorWith,
				);
			} catch (e) {
				this.analytics.trackException(
					`AnchorWith is error prefix: ${this.#anchor}, with: ${anchorWith}`,
				);
			}
		}

		if (anchorWith === null || anchorWith === undefined) {
			this.analytics.trackException(
				`AnchorWith is null or undefined, prefix: ${this.#anchor}`,
			);
		}
	}

	@Input('ppAnchorWhen')
	set when(when: boolean) {
		if (when !== this.#when) {
			this.#when = when;

			if (this.#when) {
				this.setAttribute();
			} else {
				this.removeAttribute();
			}
		}
	}

	@Input('ppAnchorAt')
	set at(at: string) {
		if (at !== this.#at) {
			this.removeAttribute();
			this.#at = at;
			this.setAttribute();
		}
	}

	constructor(
		private readonly templateRef: TemplateRef<unknown>,
		private readonly containerRef: ViewContainerRef,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly analytics: AnalyticsService,
	) {}

	static generateArchonAttributeName(anchor: string, anchorWith: string): string {
		return `${anchor}_${anchorWith.toLocaleLowerCase().replace(/\s+/g, '_')}`;
	}

	ngOnInit(): void {
		const view = this.containerRef.createEmbeddedView(this.templateRef);
		this.#element = new ElementRef<HTMLElement>(view.rootNodes[0]);

		if (this.#when) {
			this.setAttribute();
		}
	}

	private setAttribute(): void {
		const attribute = this.document.createAttribute(this.#at);
		attribute.value = this.#anchor;

		this.#element?.nativeElement.setAttributeNode(attribute);
	}

	private removeAttribute(): void {
		if (this.#element?.nativeElement.hasAttribute(this.#at)) {
			this.#element.nativeElement.removeAttribute(this.#at);
		}
	}
}
