import {FilterModel} from '@search/models';

const TIME_RANGES = [
	365 * 8, // 8 years
	365 * 4, // 4 years
	365 * 2, // 2 years
	365,
	182, // 6 month
	91, // 3 month
	30,
	7,
];

const SIZE_RANGES = [10000, 40000, 70000, 150000, 5000000, 5e7];

// There is a reason for making this service non-injectable. Currently reducers
// should be pure functions and cannot inject any services, hence there is some
// sort of limitations in code sharing. That's why this service is written in the
// old-fashion way as a collection of static functions joined by the domain area.
export const rangeFiltersService = {
	getDates(dates?: number[]): string[] {
		const currentDates = dates || this.getTimeRanges();

		return currentDates.map((date: number | Date) => {
			const currentDate = new Date(date);

			currentDate.setHours(0, 0, 0, 0);

			return currentDate.toISOString().replace('.000Z', 'Z');
		});
	},

	getTimeRanges(): number[] {
		return TIME_RANGES.map((range) => {
			const date = new Date();

			return date.setDate(date.getDate() - range);
		});
	},

	getSizes(): number[] {
		return SIZE_RANGES;
	},

	getDateRanges(): FilterModel[] {
		return ['min', ...this.getDates()].map((value: string, index: number, array: string[]) => {
			const typeRange: string = array[index + 1] || 'max';

			return {
				token: `range(${value}, ${typeRange})`,
				count: 0,
				name: '',
			} as FilterModel;
		});
	},

	getSizeRanges(): FilterModel[] {
		return ['min', ...this.getSizes()].map(
			(value: string | number, index: number, array: (string | number)[]) => {
				const typeRange: string | number = array[index + 1] || 'max';

				return {
					token: `range(${value}, ${typeRange})`,
					count: 0,
					name: '',
				} as FilterModel;
			},
		);
	},
};
