import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	ElementRef,
	Input,
	TemplateRef,
	ViewEncapsulation,
	Renderer2,
	AfterViewInit,
	ViewChild,
} from '@angular/core';
import {elementScrollTo} from 'seamless-scroll-polyfill';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {AnalyticsService} from '@shared/analytics';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'search-cards-swiper',
	standalone: true,
	imports: [SvgIconComponent, NgClass, NgTemplateOutlet],
	templateUrl: './cards-swiper.component.html',
	styleUrls: ['./cards-swiper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class CardsSwiperComponent<T> implements AfterViewInit {
	private readonly tileWidth = 3 * (270 + 24);

	cards!: T[];

	@Input({required: true}) set items(value: T[]) {
		this.cards = value;

		this.updateSwiperGrid();
	}

	@Input({required: false}) hideBackground = false;

	@ContentChild('itemTemplate', {static: false})
	itemTemplateRef!: TemplateRef<unknown>;

	scrollButtonsState = {
		leftBtnDisabled: true,
		rightBtnDisabled: false,
		controlsVisible: true,
	};

	cardsContainer!: HTMLElement;

	@ViewChild('cardsContainerRef', {static: true}) cardsContainerRef?: ElementRef;

	constructor(
		private readonly analytics: AnalyticsService,
		private renderer: Renderer2,
	) {}

	ngAfterViewInit() {
		this.scrollButtonsState.controlsVisible =
			this.cardsContainer.scrollWidth > this.cardsContainer.clientWidth;
	}

	scrollLeft(): void {
		const {scrollWidth, offsetWidth} = this.cardsContainer;
		let {scrollLeft} = this.cardsContainer;

		if (scrollLeft > 0) {
			scrollLeft -= this.tileWidth;

			if (scrollLeft < 0) {
				scrollLeft = 0;
			}
		}

		this.changeScrollButtonsState(scrollLeft, scrollWidth, offsetWidth);
		this.scrollTo(this.cardsContainer, scrollLeft);
	}

	scrollRight(): void {
		const {scrollWidth, offsetWidth}: {scrollWidth: number; offsetWidth: number} =
			this.cardsContainer;
		let scrollLeft: number = this.cardsContainer.scrollLeft;

		if (scrollLeft + offsetWidth < scrollWidth) {
			scrollLeft += this.tileWidth;

			if (scrollLeft + offsetWidth > scrollWidth) {
				scrollLeft = scrollWidth - offsetWidth;
			}
		}

		this.changeScrollButtonsState(scrollLeft, scrollWidth, offsetWidth);
		this.scrollTo(this.cardsContainer, scrollLeft);
	}

	private scrollTo(container: HTMLElement, left: number): void {
		this.analytics.trackDashboardAction('scroll', 'sp-suggest');

		elementScrollTo(container, {left, behavior: 'smooth'});
	}

	private changeScrollButtonsState(
		scrollLeft: number,
		scrollWidth: number,
		offsetWidth: number,
	): void {
		this.scrollButtonsState.leftBtnDisabled = scrollLeft === 0;
		this.scrollButtonsState.rightBtnDisabled = scrollLeft === scrollWidth - offsetWidth;
	}

	private updateSwiperGrid(): void {
		this.cardsContainer = this.cardsContainerRef?.nativeElement;
		this.renderer.setStyle(
			this.cardsContainer,
			'grid-template-columns',
			`repeat(${this.cards.length >= 8 ? this.cards.length : 7}, 1fr)`,
		);
	}
}
