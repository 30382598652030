import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'queryString',
	standalone: true,
})
export class QueryStringPipe implements PipeTransform {
	transform(value: string): string {
		const selectedFilters = {
			include: {
				modifiedDate: ['4,9'],
			},
			exclude: {
				documentTags: ['"ǂǂ4175746f2d67656e657261746564"'],
			},
		};

		if (value) {
			return JSON.stringify({
				query: {
					submittedQuery: value,
				},
				selectedFilters,
			});
		} else {
			return JSON.stringify({
				selectedFilters,
			});
		}
	}
}
