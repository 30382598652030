/* eslint-disable rxjs/no-nested-subscribe */
/* eslint-disable rxjs/no-subscribe-handlers */
/* eslint-disable rxjs/no-ignored-subscription */
import {AsyncPipe, NgTemplateOutlet} from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	Inject,
	inject,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';

import {Router, RouterLink} from '@angular/router';
import {ThemeToggleComponent} from '../theme-toggle/theme-toggle.component';
import {HeaderMenuInfoComponent} from '../header-menu-info/header-menu-info.component';
import {PowerPointToggleComponent} from '../powerpoint-toggle/powerpoint-toggle.component';
import {CloudAuthenticationService} from '../../services';
import {ImpersonateService} from '@shared/services';
import {CONFIG, EnvConfigType} from '@environments/environment';
import {SvgIconComponent} from '@pp/svg';
import {UserStore} from '@shared/state';
import {UseThemeDirective} from '@shared/directives';
import {OfficeStore} from '@office/state';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';
import {HasTeamPipe} from '@core/roles';
import {VerticalMenuComponent} from '@shared/components/vertical-menu/vertical-menu.component';
import {AccessInfoComponent} from '@shared/access';
import {UserClient} from '@shared/api/api-clients-angular.g';

@Component({
	selector: 'core-header',
	standalone: true,
	imports: [
		AsyncPipe,
		SvgIconComponent,
		RouterLink,
		NgTemplateOutlet,
		ThemeToggleComponent,
		HeaderMenuInfoComponent,
		PowerPointToggleComponent,
		HasTeamPipe,
		VerticalMenuComponent,
		AccessInfoComponent,
		UseThemeDirective,
	],
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () => new ContextualAnalyticsService(inject(AnalyticsService), 'Header'),
		},
	],
})
export class HeaderComponent implements OnInit {
	@Input() isThemeVisible = false;
	private userStore = inject(UserStore);
	private userClient = inject(UserClient);
	teams = this.userStore.profile.teams;

	isInsidePowerPoint = inject(OfficeStore).isInsidePowerPoint;
	currentUser: string | null = null;
	userMenuVisible = false;

	constructor(
		private impersonateService: ImpersonateService,
		private analytics: AnalyticsService,
		private element: ElementRef,
		private cloudAuthenticationService: CloudAuthenticationService,
		@Inject(CONFIG) readonly config: EnvConfigType,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.currentUser = this.impersonateService.get();
		this.userClient.currentUserId.subscribe((id) => {
			if (!id) return;
			this.userClient.getUserPhoto(id).subscribe((resp) => {
				const avatarUrl = URL.createObjectURL(resp.data);
				const elements = document.querySelectorAll('.person-fallback');
				elements.forEach((element) => {
					element.setAttribute('src', avatarUrl);
				});
			});
		});
	}

	@HostListener('window:click', ['$event'])
	hideMenu(event: MouseEvent): void {
		const menu = this.element.nativeElement.querySelector('#header-user-menu');
		if (!menu?.contains(event.target)) {
			this.userMenuVisible = false;
		}
	}

	trackUsage(target: EventTarget | null): void {
		const targetElement = target as HTMLElement;
		if (targetElement.tagName.toUpperCase() === 'A') {
			this.analytics.trackUserPanelActions('navigate', targetElement.innerText);
		}
	}

	async logout(event: Event): Promise<void> {
		event.preventDefault();

		if (this.currentUser) {
			this.impersonateService.clear();
			this.router.navigateByUrl('/admin/users/all');
		} else {
			await this.cloudAuthenticationService.logout();
		}
	}

	trackMenu(type: string): void {
		this.analytics.trackHeaderActions(type);
	}

	toggleUserMenu(): void {
		this.userMenuVisible = !this.userMenuVisible;
	}
}
