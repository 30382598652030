import {createActionGroup, props} from '@ngrx/store';

export const UiActions = createActionGroup({
	source: 'Loading filters',
	events: {
		filtersLoading: props<{filters: string[]}>(),
		filtersLoaded: props<{filters: string[]}>(),
	},
});

export const {filtersLoaded, filtersLoading} = UiActions;
