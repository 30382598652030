<div class="dp-search-bar search-bar-container">
	<button
		class="dp-search-bar-tips-icon dp-button-null search-bar-button-tips"
		title="Open tips"
		(click)="toggleTips()"
	>
		<pp-icon name="notification-help-outline-18" />
	</button>
	@if (tipsVisible) {
		<shared-query-tips (append)="appendQuery($event)" (replace)="replaceQuery($event)">
		</shared-query-tips>
	}

	<input
		#searchInput
		type="text"
		class="input-search"
		placeholder="Search in Presales Portal"
		autocomplete="off"
		autocorrect="off"
		[formControl]="queryControl"
		(blur)="blur()"
	/>
	<pp-icon class="dp-search-bar-clear" name="navigation-close-18" (click)="replaceQuery('')" />
</div>
