import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'date',
	standalone: true,
})
export class DatePipe implements PipeTransform {
	transform(dateNum: number | string | undefined, dateFormat = 'full'): string {
		if (!dateNum) {
			return '';
		}

		const twoDigits = 10;
		const date = new Date(dateNum);
		const format = (value: number): string =>
			value < twoDigits ? `0${value}` : value.toString();
		const time = ` ${format(date.getHours())}:${format(date.getMinutes())}:${format(
			date.getSeconds(),
		)}`;
		const dateString = `${date.getFullYear()}-${format(date.getMonth() + 1)}-${format(
			date.getDate(),
		)}`;

		return `${dateString}${dateFormat === 'full' ? time : ''}`;
	}
}
