import {patchState, signalStore, withComputed, withMethods, withState} from '@ngrx/signals';
import {computed, inject} from '@angular/core';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import {tapResponse} from '@ngrx/operators';
import {debounceTime, filter, map, pipe, switchMap, tap} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchQueryState, StatusState, searchInitialState} from './search.model';
import {
	ApiException,
	PpSearchQuery,
	PresetsClient,
	SearchPresetDefinition,
	SearchPresetTreeNode,
} from '@shared/api';
import {TitleService, UtilsService} from '@shared/services';
import {SearchQueryService, SearchStateSerializerService} from '@search2/services';
import {AnalyticsService, DocumentCatalogAction} from '@shared/analytics';

export const SearchStore = signalStore(
	{providedIn: 'root'},
	withState(searchInitialState),
	withComputed((store) => ({
		presetFlatList: computed(() => {
			const result: SearchPresetTreeNode[] = [];
			function dfs(items: SearchPresetTreeNode[]): void {
				for (const item of items) {
					if (item.presetId) {
						result.push(item);
					}
					if (item.items) {
						dfs(item.items);
					}
				}
			}

			dfs(store.presetsGroups());

			return result;
		}),
	})),
	withMethods((store) => {
		const presetClient = inject(PresetsClient);
		const analytics = inject(AnalyticsService);
		const utilsService = inject(UtilsService);
		const titleService = inject(TitleService);
		const searchQueryService = inject(SearchQueryService);

		// Dependencies for state serialization
		const stateSerializer = inject(SearchStateSerializerService);
		const initialStateString = stateSerializer.serialize(searchInitialState.searchQuery);
		const router = inject(Router);
		const route = inject(ActivatedRoute);

		function getSearchQuery(
			preset: SearchPresetDefinition,
			query: SearchQueryState,
		): PpSearchQuery {
			return searchQueryService.createQuery({
				presetId: preset.id,
				queryText: query.textQuery,
				facetFilters: query.selectedFilters,
				orderBy: query.orderByExpression,
				facets: preset.facetConfigs.map((x) => x.fieldName),
				size: !!preset.groupByFieldName ? 200 : 30,
			});
		}

		function patchFilters(fieldName: string, newState: string[]) {
			patchState(store, (state) => {
				const selectedFilters = {
					...state.searchQuery.selectedFilters,
					[fieldName]: newState,
				};

				if (newState.length === 0) {
					delete selectedFilters[fieldName];
				}

				return {
					searchQuery: {
						...state.searchQuery,
						selectedFilters,
					},
				};
			});
		}

		function track(action: DocumentCatalogAction, label?: string) {
			analytics.trackDocumentCatalogAction(action, label);
		}

		function trackSearchQuery(query: string) {
			track('search:run', query);
			analytics.trackSiteSearch(query);
		}

		const methods = {
			loadPresetTree$: rxMethod<void>(
				pipe(
					switchMap(() => presetClient.getPresetTree()),
					tapResponse({
						next: (presetGroups) => {
							patchState(store, () => ({
								presetsGroups: presetGroups,
								searchStatus: 'loaded' as StatusState,
							}));
						},
						error: (error) => {
							const e = error as ApiException;
							analytics.trackError(e.message);
							utilsService.navigateToErrorPage(e);
						},
					}),
				),
			),
			loadPresetDefinition$: rxMethod<string>(
				pipe(
					tap((presetId) => {
						track('preset:request', presetId);
						patchState(store, () => ({
							currentPreset: undefined,
							searchStatus: 'loading' as StatusState,
							// show skeleton for facets
							facets: {},
							searchResults: {
								...searchInitialState.searchResults,
								items: [],
							},
						}));
					}),
					switchMap((presetId) => presetClient.getPreset(presetId)),
					tapResponse({
						next: (preset) => {
							patchState(store, () => ({currentPreset: preset}));
							methods.updateFacetSearchQuery('');
							methods.loadSearchResults();
						},
						error: (error) => {
							const e = error as ApiException;
							analytics.trackError(error);
							utilsService.navigateToErrorPage(e);
						},
					}),
				),
			),
			restoreQueryState(queryState: SearchQueryState) {
				patchState(store, () => ({searchQuery: {...queryState}}));

				const presetId = store.currentPreset()?.id ?? '';
				if (queryState.presetId !== presetId) {
					methods.loadPresetDefinition$(queryState.presetId);
				} else {
					methods.loadSearchResults();
				}

				// Track the search query
				trackSearchQuery(queryState.textQuery);
				if (queryState.orderByExpression) {
					track('search:order-by', queryState.orderByExpression);
				}
				if (queryState.duplicateGroupId) {
					track('search:run', 'duplicateGroup');
				}
				const selectedFilters = queryState.selectedFilters;
				for (const filterName of Object.keys(selectedFilters)) {
					if (selectedFilters[filterName].length > 0) {
						track('filter:select', filterName);
					}
				}
			},
			changeSearchQueryText(textQuery: string) {
				trackSearchQuery(textQuery);

				patchState(store, (state) => ({searchQuery: {...state.searchQuery, textQuery}}));

				methods.loadSearchResults();
			},
			changeSortOrder(orderByExpression: string) {
				track('search:order-by', orderByExpression);

				patchState(store, (state) => ({
					searchQuery: {...state.searchQuery, orderByExpression},
				}));

				methods.loadSearchResults();
			},
			loadSearchResults() {
				let preset = store.currentPreset();
				if (preset === undefined) {
					preset = {
						facetConfigs: [],
						groupByFieldName: undefined,
						id: 'default',
						name: 'Global Search',
						sortOrderConfigs: [],
					};
				}

				const searchQuery = store.searchQuery();

				// Update the URL with the current search query
				const queryString = stateSerializer.serialize(searchQuery);
				const urlTree = router.createUrlTree([], {
					relativeTo: route,
					queryParams: queryString !== initialStateString ? {o: queryString} : undefined,
					fragment: route.snapshot.fragment ?? undefined,
				});
				let url = router.serializeUrl(urlTree);

				analytics.trackPageNavigation(`/p/${preset.id}`);
				titleService.setSearch2Title(preset.name, searchQuery.textQuery);
				if (url.includes('/dashboard')) {
					url = url.replace('/dashboard', '/p/default');
				}
				router.navigateByUrl(url);

				// Query the search results
				const queryObject = getSearchQuery(preset, store.searchQuery());
				methods.executeSearchQuery$(queryObject);
			},
			executeSearchQuery$: rxMethod<PpSearchQuery>(
				pipe(
					tap(() => patchState(store, () => ({searchStatus: 'loading' as StatusState}))),
					switchMap((query) =>
						searchQueryService.search$(query).pipe(
							tapResponse({
								next: (searchResults) => {
									patchState(store, (state) => ({
										searchStatus: 'loaded' as StatusState,
										searchResults: {
											...state.searchResults,
											items: searchResults.results,
											isQueryRelaxed: searchResults.isQueryRelaxed,
											totalCount: searchResults.totalCount,
										},
										facets: searchResults.facets,
									}));

									if (searchResults.totalCount === 0) {
										track('search:no-results', query.queryText);
									}
								},
								error: (error) => {
									const e = error as ApiException;
									analytics.trackError(e.message);
									utilsService.navigateToErrorPage(e);
								},
							}),
						),
					),
				),
			),
			loadMoreSearchResults$: rxMethod<void>(
				pipe(
					debounceTime(300),
					filter(() => {
						if (store.searchStatus() === 'loading') return false;

						const currentResults = store.searchResults();
						if (currentResults.totalCount === undefined) return false;
						if (currentResults.totalCount === currentResults.items.length) return false;

						const preset = store.currentPreset();
						if (preset === undefined) return false;

						track('search:load-more', currentResults.items.length.toString());

						return true;
					}),
					map(() => {
						const currentResults = store.searchResults();
						const preset = store.currentPreset()!;

						const queryObject = getSearchQuery(preset, store.searchQuery());
						queryObject.skip = currentResults.items.length;
						queryObject.facets = [];

						return queryObject;
					}),
					switchMap((query) => searchQueryService.search$(query)),
					tapResponse({
						next: (searchResults) => {
							patchState(store, (state) => ({
								searchResults: {
									...state.searchResults,
									items: [...state.searchResults.items, ...searchResults.results],
								},
							}));
						},
						error: (error) => analytics.trackError(error as ApiException),
					}),
				),
			),
			selectFilter(fieldName: string, fieldValue: string, replace?: boolean) {
				track('filter:select', fieldName);

				const oldState = store.searchQuery.selectedFilters()[fieldName] || [];
				const newState = replace ? [fieldValue] : [...oldState, fieldValue];
				patchFilters(fieldName, newState);

				methods.loadSearchResults();
			},
			removeFilter(fieldName: string, fieldValue: string) {
				track('filter:remove', fieldName);

				const oldState = store.searchQuery.selectedFilters()[fieldName] || [];
				const newState = oldState.filter((x) => x !== fieldValue);
				patchFilters(fieldName, newState);

				methods.loadSearchResults();
			},
			removeFilterList(fieldName: string, fieldValue: string[]) {
				track('filter:remove', fieldName);

				const oldState = store.searchQuery.selectedFilters()[fieldName] || [];
				const newState = oldState.filter((x) => !fieldValue.includes(x));
				patchFilters(fieldName, newState);

				methods.loadSearchResults();
			},
			resetFilters(fieldName: string) {
				track('filter:reset', fieldName);

				patchFilters(fieldName, []);

				methods.loadSearchResults();
			},
			resetAllFilters() {
				track('filter:reset', 'all');

				patchState(store, (state) => ({
					searchQuery: {
						...state.searchQuery,
						selectedFilters: {},
					},
				}));

				methods.loadSearchResults();
			},
			updateFacetSearchQuery(value: string) {
				const minFilterLength = 3;
				const query = value.length >= minFilterLength ? value : '';

				const regex = getFilterRegex(query) ?? '';
				const currentQuery = store.facetSearchRegex();
				if (currentQuery !== regex) {
					track('filter:search', query);
					patchState(store, () => ({facetSearchRegex: regex}));
				}
			},
			toggleGridViewPreferences() {
				patchState(store, (state) => ({
					isGridViewPreferred: !state.isGridViewPreferred,
				}));
			},
		};

		return methods;
	}),
);

function getFilterRegex(value: string): string | null {
	let regex: RegExp | null = null;

	if (value) {
		try {
			const escapedValue = value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

			regex = value
				? new RegExp(`(^|\\s|_)(${escapedValue.split(' ').join(')(.*\\W)(')})`, 'i')
				: null;
		} catch {
			regex = null;
		}
	}

	return regex?.toString() ?? null;
}
