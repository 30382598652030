import {Injectable} from '@angular/core';

import {FilterService} from '../filter-factory/filter-service';
import {languageMapping} from './language-mapping';
import {FilterModel} from '@search/models';
import {HexDecodePipe} from '@shared/pipes';
import {Refinement} from '@shared/services';

@Injectable({providedIn: 'root'})
export class LanguagesFilterService implements FilterService {
	constructor(private hexDecode: HexDecodePipe) {}

	convert(data: Refinement[]): FilterModel[] {
		return data.map((refinement, index) => ({
			token: refinement.RefinementToken,
			name: this.prepareName(refinement.RefinementToken),
			value: refinement.RefinementValue,
			count: +refinement.RefinementCount,
			order: index,
		}));
	}

	prepareName(token: string): string {
		const name = this.hexDecode.transform(token);

		return languageMapping[name as keyof typeof languageMapping] || name;
	}
}
