import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable, inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, EMPTY, from, Observable} from 'rxjs';
import {catchError, map, retry, switchMap, tap} from 'rxjs/operators';
import {RequestData, RequestOptions} from '../search-request-builder/request-data';
import {PostQueryResponseWithRequestedRefiners} from './post-query-response';
import {AnalyticsService} from '@shared/analytics';
import {CONFIG, EnvConfigType} from '@environments/environment';
import {
	filtersLoaded,
	filtersLoading,
} from '@search/actions/loading-filters/loading-filters.actions';

import {RefinersBuilderService, SearchRequestBuilderService} from '@shared/services';
import {UserStore} from '@shared/state';

@Injectable({providedIn: 'root'})
export class PostQueryService {
	constructor(
		private http: HttpClient,
		private store$: Store,
		private searchRequestBuilder: SearchRequestBuilderService,
		private refinersBuilderService: RefinersBuilderService,
		private analytics: AnalyticsService,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	private userStore = inject(UserStore);

	retrieve$(
		data: RequestData,
		options: RequestOptions,
		requestRefinements?: Set<string>,
	): Observable<PostQueryResponseWithRequestedRefiners> {
		return combineLatest([this.refinersBuilderService.getRefinementsSettings$()]).pipe(
			switchMap(([refinementsSettings]) => {
				const fullUrl = `${this.config.serviceUrl}/_api/search/postquery`;
				const refiners = this.refinersBuilderService.getRefiners(
					{
						type: options.refinementsType,
						custom: options.customRefiners,
						exclude: options.excludeRefiners || [],
					},
					requestRefinements || refinementsSettings,
				);
				const queryTemplate = this.userStore.profile.queryTemplate();
				const requestSettings: {refiners: string; queryTemplate: string} = {
					refiners: this.refinersBuilderService.makeRequest(refiners),
					queryTemplate,
					...options,
				};
				const request = this.searchRequestBuilder.build(data, requestSettings);
				const body = JSON.stringify(request);
				let result$: Observable<PostQueryResponseWithRequestedRefiners>;

				if (options.refinementsOnly && !request.request.Refiners) {
					result$ = from([]);
				} else {
					const filters = refiners.map(({id}) => id);

					if (
						options.emitEvents === true &&
						(options.skipFilterLoadIndicator === void 0 ||
							!options.skipFilterLoadIndicator)
					) {
						this.store$.dispatch(filtersLoading({filters}));
					}

					result$ = this.http
						.post<PostQueryResponseWithRequestedRefiners>(fullUrl, body, {
							headers: this.prepareHeaders(''),
						})
						.pipe(
							map((response) => ({...response, requestedRefiners: refiners})),
							retry(3),
							catchError((error: unknown) => {
								let message = 'Unexpected error';

								if (error instanceof Error) {
									message = error.message;
								} else if (typeof error === 'string') {
									message = error;
								}

								this.analytics.trackException(
									`cannot get result of postquery search ${message}`,
								);

								return EMPTY;
							}),
							tap(() => {
								if (
									options.emitEvents === true &&
									(options.skipFilterLoadIndicator === void 0 ||
										!options.skipFilterLoadIndicator)
								) {
									this.store$.dispatch(filtersLoaded({filters}));
								}
							}),
						);
				}

				return result$;
			}),
			tap((result) => {
				if (!result.d.postquery.PrimaryQueryResult.RelevantResults) {
					this.analytics.trackException(
						`Post query empty RelevantResults: ${JSON.stringify(result)}`,
					);
				}
			}),
		);
	}

	private prepareHeaders(digest: string): HttpHeaders {
		const headers = new HttpHeaders({
			Accept: 'application/json;odata=verbose',
			'Content-Type': 'application/json;odata=verbose',
			'X-RequestDigest': digest,
		});

		return headers;
	}
}
