import {BreakpointRuleOperatorInterface, BreakpointRuleInterface} from '../interfaces';

export class OrBreakpointRule implements BreakpointRuleOperatorInterface {
	readonly operator = 'or';

	constructor(
		public left: BreakpointRuleInterface,
		public right: BreakpointRuleInterface,
		public raw: string,
	) {}

	isMatch(value: number): boolean {
		return this.left.isMatch(value) || this.right.isMatch(value);
	}

	toString(): string {
		return this.raw;
	}
}
