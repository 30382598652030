import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {AnalyticsService} from '@shared/analytics';

@Injectable({providedIn: 'root'})
export class UrlEncodeInterceptor implements HttpInterceptor {
	constructor(private analytics: AnalyticsService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let urlSearch: string;

		try {
			urlSearch = new URL(request.url).search;
		} catch {
			urlSearch = request.url;
		}

		if (urlSearch.includes('+')) {
			this.analytics.trackException(`Url contains + character: ${request.url}`);
		}

		return next.handle(request);
	}
}
