import {Injectable} from '@angular/core';
import {FilterFactoryService} from '../filter-factory/filter-factory.service';
import {FiltersActions, ResetFiltersAction} from '@search/actions';

import {RefinementResults, RefinerModel} from '@shared/services';

@Injectable({providedIn: 'root'})
export class FiltersService {
	constructor(private filterFactory: FilterFactoryService) {}

	extractData(
		data: RefinementResults,
		requestedRefiners: RefinerModel[] = [],
	): (ResetFiltersAction | FiltersActions)[] {
		const result: (ResetFiltersAction | FiltersActions)[] = [];
		const unvisited = [...requestedRefiners];

		if (data && data.Refiners) {
			data.Refiners.results.forEach((refiner) => {
				const handler = this.filterFactory.get(refiner.Name);
				unvisited.splice(
					unvisited.findIndex((refinerModel) => refinerModel.name === refiner.Name),
					1,
				);

				if (handler) {
					const {action, service} = handler;
					const payload = service.convert(refiner.Entries.results);

					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-expect-error
					result.push(new action(payload));
				}
			});
		}

		if (unvisited.length > 0) {
			result.push(new ResetFiltersAction(unvisited.map((refiner) => refiner.id)));
		}

		return result;
	}
}
