import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
	computed,
	effect,
	inject,
} from '@angular/core';
import {HasTeamPipe} from '@core/roles';
import {OfficeStore} from '@office/state';
import {AnalyticsService} from '@shared/analytics';

import {BrowserDetectorService} from '@shared/services';
import {UserStore} from '@shared/state';

@Component({
	selector: 'core-outdated-browser',
	templateUrl: './outdated-browser.component.html',
	styleUrls: ['./outdated-browser.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [HasTeamPipe],
})
export class OutdatedBrowserComponent {
	private userStore = inject(UserStore);
	private officeStore = inject(OfficeStore);

	userAgent: string;

	supportedBrowser = computed(
		() =>
			this.officeStore.isInsidePowerPoint() ||
			this.browserDetectorService.isSupportedBrowser(),
	);
	teams = this.userStore.profile.teams;

	constructor(
		private analytics: AnalyticsService,
		private browserDetectorService: BrowserDetectorService,
	) {
		this.userAgent = this.browserDetectorService.userAgent;

		const userEmail = this.userStore.profile.userEmail;
		effect(() => {
			if (!this.supportedBrowser() && userEmail() !== '') {
				this.analytics.trackOutdatedBrowsers(this.userAgent);
			}
		});
	}
}
