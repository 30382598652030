import {patchState, signalStore, withComputed, withMethods, withState} from '@ngrx/signals';
import {computed, inject} from '@angular/core';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import {delay, distinctUntilChanged, filter, map, of, pipe, switchMap, tap} from 'rxjs';
import {tapResponse} from '@ngrx/operators';
import {
	CreateDeckBuilderJobRequestModel,
	DeckBuilderJobStatusModel,
	DocumentIndexItem,
	ToolsClient,
} from '@shared/api';
import {DownloadService} from '@shared/services';
import {ToastStore} from '@shared/state';
import {SearchQueryService} from '@search2/services';
import {AnalyticsService} from '@shared/analytics';
import {SelectionStore} from '@shared/selection';

export interface DeckBuilderState {
	jobStatus?: DeckBuilderJobStatusModel;
	cover: CoverSlideState;
	coverFormError: string;

	loaded: boolean;
	unorderedItems: DocumentIndexItem[];
}

export interface CoverSlideState {
	title: string;
	subTitle: string;
}

export const deckBuilderInitialState: DeckBuilderState = {
	jobStatus: undefined,
	cover: {
		title: '',
		subTitle: '',
	},
	coverFormError: '',

	loaded: false,
	unorderedItems: [],
};

export const DeckBuilderStore = signalStore(
	{providedIn: 'root'},
	withState(deckBuilderInitialState),
	withComputed((store) => {
		const selectionStore = inject(SelectionStore);

		function slidesCount(item: DocumentIndexItem): number {
			return item?.file?.slidesCount ?? 0;
		}

		return {
			decks: computed(() => {
				const unorderedItems = store.unorderedItems();
				const items = selectionStore
					.documentIds()
					.map((id) => unorderedItems.find((item) => item.id === id)!);

				return items.filter((item) => item && slidesCount(item) > 0);
			}),
			totalSlideCount: computed(() =>
				store.unorderedItems().reduce((acc, item) => acc + slidesCount(item), 0),
			),
			unsupportedItems: computed(() =>
				store.unorderedItems().filter((item) => slidesCount(item) === 0),
			),
		};
	}),
	// Methods for UI related state management
	withMethods((store) => {
		const searchService = inject(SearchQueryService);

		return {
			updateCover: (cover: CoverSlideState) => {
				patchState(store, {cover: cover, coverFormError: ''});
			},
			updateCoverError: (error: string) => {
				patchState(store, {coverFormError: error});
			},
			loadItems: rxMethod<string[]>(
				pipe(
					map((documentIds) => [...documentIds].sort((a, b) => a.localeCompare(b))),
					distinctUntilChanged(
						(a, b) => a.length === b.length && a.every((v, i) => v === b[i]),
					),
					tap(() => {
						patchState(store, {loaded: false});
					}),
					switchMap((ids) => (ids.length > 0 ? searchService.searchByIds$(ids) : of([]))),
					tap((items) => {
						patchState(store, {unorderedItems: items, loaded: true});
					}),
				),
			),
		};
	}),
	// Methods for interaction with deck builder api
	withMethods((store) => {
		const analytics = inject(AnalyticsService);
		const toastStore = inject(ToastStore);
		const selectionStore = inject(SelectionStore);
		const toolsClient = inject(ToolsClient);
		const downloadService = inject(DownloadService);

		function createErrorToast(message?: string) {
			toastStore.addToastMessage({
				type: 'error',
				message: message ?? 'An unknown error occurred. Please merge decks manually.',
			});
		}

		function resetJob(reason: string) {
			patchState(store, {jobStatus: undefined});
			analytics.trackDeckBuilderAction('reset', reason);
		}

		function recovery(error?: unknown) {
			analytics.trackError(error);
			createErrorToast();
			selectionStore.lockState(false);
			resetJob('error-recovery');
		}

		const methods: {
			buildDeck: () => void;
			buildStoryDeck: (
				documentIds: string[],
				cover: CoverSlideState,
				insertTemplate: boolean,
			) => void;
			startDeckBuilder$: (insertTemplate: boolean) => void;
			updateJobStatus: (payload: {
				jobStatus: DeckBuilderJobStatusModel;
				attempt: number;
			}) => void;
		} = {
			buildDeck: () => {
				analytics.trackDeckBuilderAction('build');
				methods.startDeckBuilder$(false);
			},
			buildStoryDeck: (
				documentIds: string[],
				cover: CoverSlideState,
				insertTemplate: boolean,
			) => {
				patchState(store, {cover: cover ?? deckBuilderInitialState.cover});
				selectionStore.replaceSelection(documentIds);
				methods.startDeckBuilder$(insertTemplate);
			},
			startDeckBuilder$: rxMethod<boolean>(
				pipe(
					tap(() => selectionStore.lockState(true)),
					switchMap((insertTemplate) => {
						const request: CreateDeckBuilderJobRequestModel = {
							title: store.cover.title(),
							subtitle: store.cover.subTitle(),
							insertTemplate,
							documentIds: selectionStore.documentIds(),
						};

						return toolsClient.createJob(request).pipe(
							tapResponse({
								next: (jobStatus) =>
									methods.updateJobStatus({jobStatus, attempt: 1}),
								error: (error) => recovery(error),
							}),
						);
					}),
				),
			),
			updateJobStatus: rxMethod<{jobStatus: DeckBuilderJobStatusModel; attempt: number}>(
				pipe(
					tap(({jobStatus, attempt}) => {
						patchState(store, {jobStatus: jobStatus});
						analytics.trackDeckBuilderAction('updateStatus', jobStatus.status, attempt);
					}),
					filter(({jobStatus}) => {
						switch (jobStatus.status) {
							case 'done':
								if (jobStatus.downloadUrl) {
									downloadService.triggerDownload(jobStatus.downloadUrl);
								}
								toastStore.addToastMessage({
									type: 'success',
									message: 'The deck is ready',
								});
								selectionStore.resetState('deck-built');
								resetJob('deck-built');
								store.updateCover(deckBuilderInitialState.cover);

								return false;
							case 'error':
								recovery();

								return false;
							default:
								// wait and request next status
								return true;
						}
					}),
					delay(3000),
					switchMap(({jobStatus, attempt}) =>
						toolsClient.getJobStatus(jobStatus.jobId).pipe(
							tapResponse({
								next: (newJobStatus) =>
									methods.updateJobStatus({
										jobStatus: newJobStatus,
										attempt: attempt + 1,
									}),
								error: (error) => recovery(error),
							}),
						),
					),
				),
			),
		};

		return methods;
	}),
);
