export function formatRange(from: string, to: string, filterName: string): string {
	if (
		filterName.includes('Date') ||
		filterName.includes('modified') ||
		filterName.includes('created')
	) {
		if (to === 'Now') {
			if (from === 'Long ago') {
				return 'Anytime';
			}

			return `Last ${from}`;
		}

		if (from === 'Long ago') {
			return `Earlier than ${to} ago`;
		}

		return `Between ${from} and ${to} ago`;
	}

	return `From ${from} To ${to}`;
}
