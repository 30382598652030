import {Injectable, computed, inject} from '@angular/core';
import {fromEvent, Subscription, map} from 'rxjs';
import {distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {toObservable} from '@angular/core/rxjs-interop';
import {SelectionState, SelectionStore, selectionInitialState} from '@shared/selection';

const SELECTION_LOCAL_STORAGE_KEY = 'uspp.selection-state';

@Injectable({providedIn: 'root'})
export class SelectionPersistanceService {
	private selectionStore = inject(SelectionStore);

	currentSelection = computed(() => ({
		isLocked: this.selectionStore.isLocked(),
		documentIds: this.selectionStore.documentIds(),
		documentIdsSnapshot: [],
	}));

	private currentSelection$ = toObservable(this.currentSelection);

	subscribeToChanges(): Subscription {
		this.initializeFromLocalStorage();

		const subscriptions = new Subscription();

		subscriptions.add(
			this.currentSelection$
				.pipe(
					map((state) => {
						const stateJson = JSON.stringify(state);
						const localStorageState = localStorage.getItem(SELECTION_LOCAL_STORAGE_KEY);
						if (stateJson !== localStorageState) {
							localStorage.setItem(SELECTION_LOCAL_STORAGE_KEY, stateJson);
						}
					}),
				)
				.subscribe(),
		);

		subscriptions.add(
			fromEvent<StorageEvent>(window, 'storage')
				.pipe(
					filter(
						(storageState: StorageEvent) =>
							storageState.key === SELECTION_LOCAL_STORAGE_KEY,
					),
					map((storageState: StorageEvent) => storageState.newValue ?? ''),
					distinctUntilChanged(),
					tap((storageStateJson) => {
						const currentStateJson = JSON.stringify(this.currentSelection());
						if (!!storageStateJson && currentStateJson !== storageStateJson) {
							const parsedState = JSON.parse(storageStateJson) as SelectionState;
							this.selectionStore.restoreState(parsedState);
						}
					}),
				)
				.subscribe(),
		);

		return subscriptions;
	}

	private initializeFromLocalStorage(): void {
		const localStorageState = localStorage.getItem(SELECTION_LOCAL_STORAGE_KEY);
		if (localStorageState && localStorageState !== 'null') {
			try {
				const parsedState: SelectionState = JSON.parse(localStorageState);
				const newState: SelectionState = {
					...selectionInitialState,
					...parsedState,
					isLocked: false,
				};
				this.selectionStore.restoreState(newState);
			} catch (error) {
				localStorage.removeItem(SELECTION_LOCAL_STORAGE_KEY);
			}
		}
	}
}
