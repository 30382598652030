import {ChangeDetectionStrategy, Component, Input, inject} from '@angular/core';
import {DocumentIndexItem, DocumentItemParserService} from '@shared/api';
import {FlagSize, FlagComponent} from '@pp/flag';
import {OfficeStore} from '@office/state';

@Component({
	selector: 'search-flag-list',
	templateUrl: './flag-list.component.html',
	styleUrls: ['./flag-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FlagComponent],
})
export class FlagListComponent {
	@Input() item!: DocumentIndexItem;
	@Input() flagSize: FlagSize = 'medium';

	private readonly parserService = inject(DocumentItemParserService);

	isInsidePowerPoint = inject(OfficeStore).isInsidePowerPoint;

	get isVerified(): boolean {
		return this.parserService.isVerified(this.item);
	}
}
