<search-cards-swiper [items]="productNews.items" [hideBackground]="true">
	<ng-container header>
		<header class="news-section-header">
			<h3>Product News</h3>

			<a
				class="news-section-header-link"
				href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI1MzIyNjAzIn0"
				target="_blank"
				(click)="trackShowMore('Product News')"
			>
				Yammer
				<pp-icon [name]="'navigation-chevron-right-18'"></pp-icon>
			</a>
		</header>
	</ng-container>
	<ng-template #itemTemplate let-item let-index="index">
		<dashboard-news-cards
			[item]="item"
			[index]="productNews.totalCount - index"
			(clickButtonEvent)="newsCardsEventListener($event)"
		></dashboard-news-cards>
	</ng-template>
</search-cards-swiper>
