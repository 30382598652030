import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject, from, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {AsyncPipe} from '@angular/common';
import {DetailsDialogComponent} from '../details-dialog/details-dialog.component';
import {ChangeFilterSearchAction} from '@search/actions';
import {SearchState} from '@search/reducers/search-state';
import {DocumentService, UIService} from '@search/services';
import {SearchResultModel} from '@shared/models';
import {ModalService} from '@shared/modals';
import {getFirst} from '@shared/utils';
import {CheckboxComponent} from '@pp/checkbox';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'search-quick-edit',
	templateUrl: './quick-edit.component.html',
	styleUrls: ['./quick-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CheckboxComponent, AsyncPipe],
})
export class QuickEditComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();

	private readonly order = [
		'Final',
		'Output',
		'Materials',
		'Input',
		'Slide',
		'Q&A',
		'Auto-generated',
	];
	private readonly analyticsMapping: Dictionary<'updateCvTag' | 'updateCaseStudyTag'> = {
		isCaseStudy: 'updateCaseStudyTag',
		isCv: 'updateCvTag',
	};

	isCv$!: BehaviorSubject<boolean>;
	isCaseStudy$!: BehaviorSubject<boolean>;
	tags!: string[];
	showMetadata = this.uiService.showMetadata;

	@Input({required: true}) result!: SearchResultModel;

	constructor(
		private analytics: AnalyticsService,
		private documentService: DocumentService,
		private store$: Store<SearchState>,
		private uiService: UIService,
		private readonly modalService: ModalService,
	) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit(): void {
		this.isCv$ = new BehaviorSubject(this.result.tags.includes('CV'));
		this.isCaseStudy$ = new BehaviorSubject(this.result.tags.includes('Case study'));

		const tags = new Set(this.result.tags);
		this.tags = this.order.filter((tag) => tags.has(tag));
	}

	showDetails(): void {
		this.analytics.trackExtendedInfoActions('open');

		this.modalService.openModal(DetailsDialogComponent, {
			type: 'QUICK-EDIT-DETAILS',
			showCloseButton: false,
			data: {
				document: this.result,
			},
		});
	}

	setProperty(propertyName: 'isCaseStudy' | 'isCv', eventTarget: EventTarget | null): void {
		const propertyValue = (eventTarget as HTMLInputElement).checked;
		this.setPropertyValue(propertyName, propertyValue);
	}

	private setPropertyValue(propertyName: 'isCaseStudy' | 'isCv', propertyValue: boolean): void {
		const request = {
			documentUrl: this.result.filePath,
			propertyName,
			propertyValue: propertyValue.toString(),
		};

		this[`${propertyName}$`].next(propertyValue);
		this.analytics.trackQuickEditActions(this.analyticsMapping[propertyName]);
		this.subscriptions.add(
			this.documentService
				.setProperty$(request)
				.pipe(
					tap(() => {
						// TODO: Type definition required only by TypeScript...
						const secondProperty: 'isCaseStudy' | 'isCv' =
							propertyName === 'isCaseStudy' ? 'isCv' : 'isCaseStudy';

						if (propertyValue && getFirst(this[`${secondProperty}$`])) {
							this.setPropertyValue(secondProperty, false);
						}
					}),
					catchError(() => {
						this[`${propertyName}$`].next(!propertyValue);

						return from([]);
					}),
				)
				.subscribe(),
		);
	}

	filter(tag: string): void {
		this.store$.dispatch(new ChangeFilterSearchAction(tag));
	}
}
