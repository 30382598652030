import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';

@Component({
	selector: 'shared-card-body',
	templateUrl: './card-body.component.html',
	styleUrls: ['./card-body.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
})
export class CardBodyComponent implements OnInit {
	@ViewChild('content', {static: true})
	content!: TemplateRef<unknown>;

	@ViewChild('container', {static: true, read: ViewContainerRef})
	container!: ViewContainerRef;

	@HostBinding('class.without-content')
	withoutContent = true;

	ngOnInit(): void {
		const viewRef = this.content.createEmbeddedView(null);
		const nodes = viewRef.rootNodes.filter((node) => node instanceof HTMLElement);

		if (nodes.length > 0) {
			this.withoutContent = false;
			this.container.insert(viewRef);
		}
	}
}
