import * as actions from '../../actions';
import {queryInitialState} from './query-initial-state';
import {QueryState} from './query-state';

const defaultQuery = '';

export function reducer(
	state: QueryState = queryInitialState,
	action: actions.QueryActions,
): QueryState {
	switch (action.type) {
		case actions.INIT_QUERY:
			const query = action.payload?.submittedQuery || defaultQuery;

			return {
				...queryInitialState,
				...action.payload,
				currentQuery: query,
			};
		case actions.CHANGE_QUERY:
			return {
				...state,
				currentQuery: action.payload,
			};
		case actions.SUBMIT_QUERY:
			const newQuery =
				(action.payload === undefined ? state.submittedQuery : action.payload) ||
				defaultQuery;

			return {
				...state,
				currentQuery: newQuery,
				submittedQuery: newQuery,
				isLoading: true,
			};
		case actions.SUBMIT_QUERY_FAIL:
		case actions.SUBMIT_QUERY_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case actions.CHANGE_SORT_BY:
			return {
				...state,
				isLoading: true,
				sortBy: {
					value: action.payload.value,
					direction: action.payload.direction,
				},
			};
		case actions.CHANGE_GROUP_ID:
			return {
				...state,
				groupId: action.payload,
			};
		case actions.TOGGLE_EXPAND_DUPLICATES:
			return {
				...state,
				expandDuplicates: action.payload,
			};
		case actions.TOGGLE_QUERY_TEMPLATE:
			return {
				...state,
				disableQueryTemplate: action.payload,
			};
		default:
			return state;
	}
}
