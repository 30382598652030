import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {HistoryResponse} from './history-response';
import {SuggestResponse} from './suggest-response';
import {AnalyticsService} from '@shared/analytics';
import {QuerySuggestionModel} from '@shared/models';
import {impersonate} from '@shared/decorators';
import {CONFIG, EnvConfigType} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class SuggestionsService {
	protected readonly suggestionsUrn = '/_api/search/suggest';
	protected readonly historyUri = '/api/search/query/suggest/3';

	constructor(
		protected http: HttpClient,
		protected analytics: AnalyticsService,
		@Inject(CONFIG) protected readonly config: EnvConfigType,
	) {}

	getSuggestionList$(query: string): Observable<QuerySuggestionModel[]> {
		return zip(
			this.getHistory$(query).pipe(catchError(this.catchError$.bind(this))),
			this.getSuggestions$(query).pipe(catchError(this.catchError$.bind(this))),
		).pipe(map(SuggestionsService.deduplicate));
	}

	isSuggestionUri(uri: string): boolean {
		return [this.suggestionsUrn, this.historyUri].some((urn) => uri.includes(urn));
	}

	protected catchError$(error: ErrorInterface): Observable<QuerySuggestionModel[]> {
		if (error.status === 500) {
			if (!!error.message) {
				this.analytics.trackException(
					`Message: ${error.message}. ${error.stack ? `Stack: ${error.stack}` : ''}`,
				);
			}

			return of([] as QuerySuggestionModel[]);
		}

		throw error;
	}

	protected static deduplicate(
		this: void,
		[history, suggestions]: QuerySuggestionModel[][],
	): QuerySuggestionModel[] {
		return [
			...history,
			...suggestions.filter(
				(suggestion) =>
					history.findIndex(
						(item) => item.text.toLowerCase() === suggestion.text.toLowerCase(),
					) === -1,
			),
		];
	}

	protected getSuggestions$(query: string): Observable<QuerySuggestionModel[]> {
		const fullUrl = `${this.config.serviceUrl}${this.suggestionsUrn}?${this.getQueryString(
			query,
		)}`;

		return this.http
			.get<SuggestResponse>(fullUrl, {headers: this.prepareHeaders()})
			.pipe(map((response) => this.extractSuggestionResults(response)));
	}

	@impersonate
	protected getHistory$(query: string, email?: string): Observable<QuerySuggestionModel[]> {
		const fullUrl = `${this.config.serviceUrl}${this.historyUri}?prefix=${encodeURIComponent(
			query,
		)}${email ? `&email=${encodeURIComponent(email)}` : ''}`;

		return this.http
			.get<HistoryResponse[]>(fullUrl, {headers: this.prepareHeaders()})
			.pipe(map((response) => this.extractHistoryResults(query, response)));
	}

	protected prepareHeaders(): HttpHeaders {
		const headers = new HttpHeaders({
			Accept: 'application/json;odata=verbose',
		});

		return headers;
	}

	protected getQueryString(query: string): string {
		const queryText = `'${query.trim().replace(/'/g, "''")}'`;
		const params = [
			'fprequerysuggestions=true',
			'inumberofquerysuggestions=5',
			`querytext=${encodeURIComponent(queryText)}`,
		];

		return params.join('&');
	}

	protected extractHistoryResults(
		query: string,
		response: HistoryResponse[],
	): QuerySuggestionModel[] {
		const escapedQuery = query.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

		return response.map((value) => ({
			text: value.queryText,
			reachText: value.queryText.replace(new RegExp(escapedQuery, 'i'), '<mark>$&</mark>'),
			userHistory: true,
		}));
	}

	protected extractSuggestionResults(response: SuggestResponse): QuerySuggestionModel[] {
		return response.d.suggest.Queries.results.map((result) => {
			const text = result.Query.replace(/<b>/gi, '').replace(/<\/b>/gi, '');
			const reachText = result.Query.replace(/<b>/gi, '<mark>').replace(/<\/b>/gi, '</mark>');

			return {
				text,
				reachText,
			};
		});
	}
}

interface ErrorInterface extends Error {
	status?: number;
}
