import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';

import {ChangeViewAction} from '@search/actions/ui/change-view.action';
import {SearchFeatureState} from '@search/reducers/search-state';

@Component({
	selector: 'app-redirect',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class RedirectComponent implements OnInit {
	constructor(
		private router: Router,
		private store$: Store<SearchFeatureState>,
	) {}

	ngOnInit(): void {
		const currentURL = this.router.url;

		if (currentURL.includes('search/grid?')) {
			this.store$.dispatch(new ChangeViewAction('grid'));
			this.router.navigateByUrl(currentURL.replace('search/grid', 'search'));
		} else {
			this.router.navigate(['/dashboard']);
		}
	}
}
