@if (visible$ | async) {
	<div class="query-modification-container" cdkDrag>
		<header class="query-modification-handle" cdkDragHandle>
			Query Modification
			<pp-icon name="navigation-close-36" aria-hidden="true" (click)="close()"></pp-icon>
		</header>
		<label class="query-modification-toggle">
			@if (disableQueryTemplate$ | async; as disableQueryTemplate) {
				<pp-checkbox
					[checked]="disableQueryTemplate"
					(change)="toggleQueryTemplate($event.target)"
					text="Remove query template"
				></pp-checkbox>
			}
		</label>
		<textarea [value]="query$ | async"></textarea>
	</div>
}
