import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {CONFIG, EnvConfigType} from '@environments/environment';

import {RecommendSlidesModel} from '@shared/models';

@Injectable({providedIn: 'root'})
export class RecommendSlidesService {
	private lastQuery?: string;
	private lastStream$?: Observable<RecommendSlidesModel>;

	constructor(
		private http: HttpClient,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	getQuery$(filePath: string): Observable<RecommendSlidesModel> {
		if (this.lastQuery !== filePath) {
			const fullUrl = `${
				this.config.serviceUrl
			}/api/documents/recommendslides?url=${encodeURIComponent(filePath)}`;

			this.lastQuery = filePath;
			this.lastStream$ = this.http
				.get<RecommendSlidesModel>(fullUrl)
				.pipe(shareReplay({bufferSize: 1, refCount: true}));
		}

		return this.lastStream$ ?? EMPTY;
	}
}
