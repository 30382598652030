import {Action} from '@ngrx/store';

import {FilterModel} from '@search/models';

export const UPDATE_SIZE_FILTER = '[Search] Update Size Filter';

export class UpdateSizeFilterAction implements Action {
	readonly type = UPDATE_SIZE_FILTER;

	constructor(public payload: FilterModel[]) {}
}
