import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
} from '@angular/core';
import {SvgIconComponent} from '@pp/svg';

const ICONS = {
	warning: 'notification-warning-fill-24',
	info: 'notification-info-fill-24',
	success: 'notification-check_circle-fill-24',
	error: 'notification-error-fill-24',
};

type StatusType = 'warning' | 'error' | 'success' | 'info';

@Component({
	selector: 'pp-alert',
	standalone: true,
	imports: [SvgIconComponent],
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
	@Input()
	@HostBinding('class')
	set status(status: StatusType) {
		if (this.#status !== status) {
			this.#status = status;

			this.icon = ICONS[status as keyof typeof ICONS];
		}
	}
	get status(): StatusType {
		return this.#status;
	}

	#status: StatusType = 'warning';

	icon = ICONS[this.#status as keyof typeof ICONS];

	@Input()
	otherIcon = '';

	@Input()
	@HostBinding('class.closable')
	closable = false;

	@Output()
	closed = new EventEmitter<boolean>();

	close(): void {
		this.closed.emit(true);
	}
}
