import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {HintDirective} from '@pp/hint';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'search-preview-highlights-description',
	templateUrl: './preview-highlights-description.component.html',
	styleUrls: ['./preview-highlights-description.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent, HintDirective],
})
export class PreviewHighlightsDescriptionComponent {
	@Input({required: true}) highlightedDescription!: string;

	constructor() {}
}
