import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
	selector: '[ppCardProjectionContent]',
	standalone: true,
})
export class CardProjectionContentDirective {
	@Input('ppCardProjectionContent') content!: TemplateRef<unknown>;
	@Input('ppCardProjectionContentWhen') set when(value: boolean) {
		if (value) {
			this.containerRef.createEmbeddedView(this.content);
		} else {
			this.containerRef.clear();
		}
	}

	constructor(private containerRef: ViewContainerRef) {}
}
