import {Action} from '@ngrx/store';

import {TreeFilterModel} from '@search/models';

export const UPDATE_CONTENT_OWNER_FILTER = '[Search] Update Content Owner Filter';

export class UpdateContentOwnerFilterAction implements Action {
	readonly type = UPDATE_CONTENT_OWNER_FILTER;

	constructor(public payload: TreeFilterModel) {}
}
