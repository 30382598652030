import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation,
	inject,
} from '@angular/core';

import {Subscription} from 'rxjs';
import {first, switchMap, tap} from 'rxjs/operators';
import {NgClass} from '@angular/common';
import {ConfidentialLogosComponent} from '../confidential-logos/confidential-logos.component';
import {ConfidentialType} from './confidential.enum';
import {Setting} from './setting.enum';
import {CrmAccountModel, SearchResultModel} from '@shared/models';
import {UserProfileService} from '@shared/services';
import {CURRENT_MODAL, ModalRef} from '@shared/modals';
import {UserStore} from '@shared/state';
import {CheckboxComponent} from '@pp/checkbox';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'shared-confidential',
	templateUrl: './confidential.component.html',
	styleUrls: ['./confidential.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, SvgIconComponent, ConfidentialLogosComponent, CheckboxComponent],
})
export class ConfidentialComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();

	confidentialTypes = ConfidentialType;

	@Input() confidentialType: ConfidentialType = ConfidentialType.CONTENT;
	@Input({required: true}) document!: SearchResultModel;
	@Input({required: true}) accountLogos!: CrmAccountModel[];
	@Output() confirm = new EventEmitter();

	setting!: Setting;

	settings: Dictionary<Setting> = {
		[ConfidentialType.CONTENT]: {
			title: 'Warning',
			checkbox: true,
			downloadButton: 'Take risk and download',
		},
		[ConfidentialType.DOCUMENT]: {
			title: 'Confidential document',
			checkbox: false,
			downloadButton: 'Download',
		},
	};

	constructor(
		private userProfile: UserProfileService,
		@Inject(CURRENT_MODAL) private readonly modalRef: ModalRef,
	) {}

	ngOnInit(): void {
		this.setting = this.settings[this.confidentialType];
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private userStore = inject(UserStore);
	rememberChoice(target: EventTarget | null): void {
		const checked = target instanceof HTMLInputElement && target.checked;
		this.subscriptions.add(
			this.userProfile
				.setUserSetting$('warningMode', checked ? 'disable' : 'enable')
				.pipe(
					switchMap(() => this.userProfile.getUserProfile$()),
					first(),
					tap((profile) => {
						this.userStore.updateUserProfile(profile);
						this.userStore.updateUserSettings(profile.settings);
					}),
				)
				.subscribe(),
		);
	}

	cancel(): void {
		this.confirm.emit(null);
		this.modalRef.close();
	}

	confirmRisk(): void {
		this.confirm.emit(this.document);
		this.modalRef.close();
	}
}
