<div class="shared-dropdown" [class.opened]="isDropDownOpened">
	<button
		#dropDownButton
		btn
		[btnType]="type"
		[withIconBefore]="icon"
		[withIconAfter]="chevronStateIcon"
		[onlyIcon]="iconOnly"
		(click)="toggleDropdown()"
	>
		<ng-content select="[dropdownHeader]"></ng-content>
	</button>
</div>
