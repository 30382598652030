import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
	Input,
	Inject,
	OnInit,
	OnDestroy,
	inject,
} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ModalRef} from '@shared/modals';
import {SearchQueryService} from '@search2/services';
import {DownloadService, ToastService} from '@shared/services';
import {DocumentIndexItem} from '@shared/api';
import {PreviewService} from '@preview/services';
import {DeckBuilderStore} from '@deck-builder/state';
import {POPOVER_REF} from '@shared/popover';
import {CheckboxComponent} from '@pp/checkbox';

@Component({
	selector: 'search-download-options',
	templateUrl: './download-options.component.html',
	styleUrls: ['./download-options.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CheckboxComponent],
})
export class DownloadOptionsComponent implements OnInit, OnDestroy {
	@Input() tagsNames: string[] = [];
	@Input({required: true}) document!: DocumentIndexItem;
	@Output() downloadDocumentAction = new EventEmitter<void>();

	private readonly subscriptions = new Subscription();
	private downloadService = inject(DownloadService);
	private deckBuilderStore = inject(DeckBuilderStore);

	constructor(
		@Inject(POPOVER_REF) private readonly popOverRef: ModalRef,
		private readonly searchService: SearchQueryService,
		private readonly toastService: ToastService,
		private readonly previewService: PreviewService,
	) {}

	isGeneralSlidesChecked = true;
	collectionSelection: CollectionSelectionState[] = [];
	isSomeCollectionChecked = false;

	ngOnInit() {
		this.collectionSelection = this.tagsNames.map((tag) => ({
			collectionName: tag,
			checked: false,
		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	download(event: Event): void {
		event.preventDefault();
		this.downloadDocumentAction.emit();
		this.closePopOver();
	}

	buildDeck(event: Event): void {
		event.preventDefault();

		const selectedCollections = this.collectionSelection
			.filter((collection) => collection.checked)
			.map((collection) => collection.collectionName);

		const query = this.searchService.createQuery({
			presetId: 'cases',
			facetFilters: {
				'spo_presales.tags.keyword': selectedCollections,
			},
			size: 200,
		});

		this.subscriptions.add(
			this.searchService
				.search$(query)
				.pipe(
					map((result) => {
						const totalSlidesCount = result.results.reduce(
							(count, document) => count + (document.document.file?.slidesCount || 0),
							this.document.file?.slidesCount || 0,
						);

						if (totalSlidesCount <= 200) {
							const collectionDocumentIds = result.results.map(
								(item) => item.document.id,
							);

							if (this.isGeneralSlidesChecked) {
								this.deckBuilderStore.buildStoryDeck(
									[this.document.id, ...collectionDocumentIds],
									{title: this.document.title.rawValue, subTitle: ''},
									false,
								);

								this.downloadService.logFileDownload(
									this.previewService.getPreviewPageUrl(this.document, true),
									this.document.spoPresales?.docId,
								);
							} else {
								this.deckBuilderStore.buildStoryDeck(
									collectionDocumentIds,
									{title: selectedCollections.join(', '), subTitle: ''},
									true,
								);
							}

							return true;
						} else {
							this.toastService.show({
								type: 'alert',
								message: 'Please reduce total number of slides to 200',
							});

							return false;
						}
					}),
					tap(() => this.closePopOver()),
				)
				.subscribe(),
		);
	}

	toggleCheckbox(event: Event, index: number): void {
		event.preventDefault();
		event.stopPropagation();

		if (index === -1) {
			this.isGeneralSlidesChecked = !this.isGeneralSlidesChecked;
		} else {
			this.collectionSelection[index].checked = !this.collectionSelection[index].checked;
		}

		this.isSomeCollectionChecked = this.collectionSelection.some(
			(collection) => collection.checked,
		);
	}

	closePopOver(): void {
		this.popOverRef.close();
	}
}

interface CollectionSelectionState {
	collectionName: string;
	checked: boolean;
}
