import {Action} from '@ngrx/store';

import {TreeFilterModel} from '@search/models';

export const UPDATE_ABOUT_FILTER = '[Search] Update About Filter';

export class UpdateAboutFilterAction implements Action {
	readonly type = UPDATE_ABOUT_FILTER;

	constructor(public payload: TreeFilterModel) {}
}
