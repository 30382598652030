import {Inject, Injectable, inject} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {EMPTY, catchError, firstValueFrom} from 'rxjs';
import {BrowserDetectorService} from '../browser-detector/browser-detector.service';
import {AnalyticsService} from '@shared/analytics';
import {CONFIG, EnvConfigType} from '@environments/environment';
import {UiStore, ToastStore} from '@shared/state';
import {DocDownloadUrls, DocumentsClient} from '@shared/api';
import {OfficeStore} from '@office/state';

@Injectable({providedIn: 'root'})
export class DownloadService {
	private analytics = inject(AnalyticsService);
	private toastStore = inject(ToastStore);
	private uiStore = inject(UiStore);
	private officeStore = inject(OfficeStore);
	private documentClient = inject(DocumentsClient);

	constructor(
		private readonly http: HttpClient,
		@Inject(CONFIG) private readonly config: EnvConfigType,
		private readonly browser: BrowserDetectorService,
	) {}

	logFileDownload(url: string, spDocId?: string): Promise<unknown> {
		if (!this.config.serviceless) {
			return firstValueFrom(
				this.http
					.post(`${this.config.serviceUrl}/api/o365/DownloadEvent`, {
						url: url,
						tag: spDocId,
					})
					.pipe(
						catchError(() => {
							this.analytics.trackError('Error while logging download event');

							return EMPTY;
						}),
					),
			);
		} else {
			return Promise.resolve();
		}
	}

	trackPptxDownloadEvent(docId: string, time: number): void {
		this.uiStore.trackPptxDownloadEvent(docId, time);
	}

	triggerDownload(fileBlobUrl: string, name?: string): void {
		const isSameOrigin = this.isSameOriginUrl(fileBlobUrl);
		const link = this.makeLink(fileBlobUrl);

		// Browsers support only same-origin download links
		// https://caniuse.com/download
		if (name || isSameOrigin) {
			link.setAttribute('download', name ?? '');
		}

		if (isSameOrigin || this.browser.isChromium() || this.officeStore.isInsidePowerPoint()) {
			link.target = '_self';
		} else {
			link.target = this.getDownloadIframe().name;
		}

		this.triggerClick(link);
	}

	async downloadDocument(docId: string, spDocId?: string): Promise<void> {
		const urls = await this.getDocumentUrl(docId);
		if (urls.blobUrl) {
			this.downloadFile(urls.blobUrl, urls.sourceUrl, spDocId);
		} else {
			this.analytics.trackError('Document `blobUrl` is empty');
			this.toastStore.addToastMessage({
				type: 'alert',
				message: 'The file cannot be downloaded',
			});
		}
	}
	downloadFile(fileBlobUrl: string, fileAnalyticsUrl?: string, spDocId?: string): void {
		this.toastStore.addToastMessage({type: 'info', message: 'Downloading...'});
		this.triggerDownload(fileBlobUrl);
		if (!this.isSameOriginUrl(fileBlobUrl)) {
			this.logFileDownload(fileAnalyticsUrl ?? fileBlobUrl, spDocId);
		}
	}

	openPageInNewTab(pageUrl: string): void {
		this.logFileDownload(pageUrl);
		this.openInNewTab(pageUrl);
	}
	async openDocumentInNewTab(docId: string, pageUrl: string): Promise<void> {
		await this.getDocumentUrl(docId); // Log click event in PostreSQL
		this.openInNewTab(pageUrl);
	}
	async openDocumentInApp(docId: string, appSchema: string): Promise<void> {
		const urls = await this.getDocumentUrl(docId);
		if (urls.sourceUrl) {
			this.openInNewTab(`${appSchema + decodeURI(urls.sourceUrl)}`);
		} else {
			this.analytics.trackError('Document `sourceUrl` is empty');
			this.toastStore.addToastMessage({
				type: 'alert',
				message: 'The file cannot be opened in the application',
			});
		}
	}

	private openInNewTab(pageUrl: string): void {
		const link = this.makeLink(pageUrl);
		link.setAttribute('target', '_blank');
		link.setAttribute('rel', 'noreferrer noopener');

		this.triggerClick(link);
	}

	private getDocumentUrl(docId: string): Promise<DocDownloadUrls> {
		return firstValueFrom(
			this.documentClient.getDownloadUrl(docId).pipe(
				catchError((error: unknown) => {
					this.analytics.trackError(error);

					return EMPTY;
				}),
			),
		);
	}

	isSameOriginUrl(url: string) {
		const urlOrigin = new URL(url).origin;
		const currentOrigin = window.location.origin;

		return urlOrigin === currentOrigin;
	}

	private iframe?: HTMLIFrameElement;

	private getDownloadIframe(): HTMLIFrameElement {
		const iframeName = 'download-iframe';

		if (!this.iframe) {
			const iframe = document.createElement('iframe');
			iframe.style.display = 'none';
			iframe.setAttribute('src', 'about:blank');
			iframe.id = iframeName;
			iframe.name = iframeName;
			document.body.appendChild(iframe);
			this.iframe = iframe;
		}

		return this.iframe;
	}

	private makeLink(href: string): HTMLAnchorElement {
		const element = document.createElement('a');
		element.style.display = 'none';
		element.setAttribute('href', href);

		return element;
	}

	private triggerClick(element: HTMLAnchorElement): void {
		document.body.appendChild(element);

		element.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: window,
			}),
		);

		// To make this work on Firefox we need to wait
		// a little while before removing it.
		setTimeout(() => {
			URL.revokeObjectURL(element.href);
			document.body.removeChild(element);
		}, 200);
	}
}
