<shared-autocomplete
	placeholder="Enter contact username or email address"
	[formControl]="searchQuery"
>
	@for (person of people$ | async; track person.aadId) {
		<shared-option (selectionChange)="triggerSelect(person)">
			<search-preview-user-info [userInfo]="person"></search-preview-user-info>
		</shared-option>
	}
</shared-autocomplete>
