import {ChangeDetectionStrategy, Component, OnInit, inject} from '@angular/core';
import {WebHomeLayoutComponent} from '../web-home-layout/web-home-layout.component';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';
import {OfficeStore} from '@office/state';
import {OfficeHomeLayoutComponent} from '@office/components';
import {TitleService} from '@shared/services';

@Component({
	selector: 'home-page',
	standalone: true,
	imports: [WebHomeLayoutComponent, OfficeHomeLayoutComponent],
	templateUrl: './home-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () => new ContextualAnalyticsService(inject(AnalyticsService), 'Dashboard'),
		},
	],
})
export class HomePageComponent implements OnInit {
	private readonly analytics = inject(AnalyticsService);
	private readonly titleService = inject(TitleService);

	isInsidePowerPoint = inject(OfficeStore).isInsidePowerPoint;

	ngOnInit(): void {
		this.titleService.setDashboardTitle();
		this.analytics.trackPageNavigation('/ui/dashboard');
	}
}
