import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ThemeModeType} from '../typings/theme-mode.type';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	private readonly mode$ = new BehaviorSubject<ThemeModeType>('light');

	private darkMode = false;

	currentMode(): ThemeModeType {
		// eslint-disable-next-line rxjs/no-subject-value
		return this.mode$.getValue();
	}

	isDark(): boolean {
		return this.darkMode;
	}

	isLight(): boolean {
		return !this.darkMode;
	}

	setMode(mode: ThemeModeType): void {
		if (mode === 'dark') {
			this.setDarkMode();
		} else {
			this.setLightMode();
		}
	}

	setDarkMode(): void {
		if (this.isDark()) {
			return;
		}

		this.darkMode = true;
		this.mode$.next('dark');
	}

	setLightMode(): void {
		if (this.isLight()) {
			return;
		}

		this.darkMode = false;
		this.mode$.next('light');
	}
}
