import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import {NgClass} from '@angular/common';
import {ResultsService} from '@shared/services';
import {SearchResultModel, ThumbnailAction} from '@shared/models';
import {FileTypePipe} from '@shared/pipes/file-type.pipe';
import {FlagComponent, FlagType} from '@pp/flag';
import {SpinnerComponent} from '@pp/spinner';
import {SvgImageComponent, SvgIconComponent} from '@pp/svg';
import {ThumbnailComponent} from '@shared/components/thumbnail/thumbnail.component';
import {ResultOverlayComponent} from '@shared/components/result-overlay/result-overlay.component';

@Component({
	selector: 'search-result-thumbnail',
	templateUrl: './search-result-thumbnail.component.html',
	styleUrls: ['./search-result-thumbnail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		SvgImageComponent,
		ThumbnailComponent,
		NgClass,
		FlagComponent,
		SvgIconComponent,
		SpinnerComponent,
		ResultOverlayComponent,
		FileTypePipe,
	],
})
export class SearchResultThumbnailComponent implements OnInit {
	readonly fileSizeLimit = ResultsService.MaxFileSizeForPreview;
	overlayVisible = false;
	forceLoadThumbnail = false;
	thumbnailLoaded = false;
	flag?: FlagType;
	hasDEP = false;
	isVerified = false;

	@Input({required: true}) result!: SearchResultModel;
	@Output() triggerActionEmitter = new EventEmitter<ThumbnailAction>();

	ngOnInit(): void {
		this.hasDEP = this.result.filePath.includes('/depInt/expr/Presales Recommends/');
		this.isVerified = this.result.tags?.includes('Verified') ?? false;
	}

	showOverlay(): void {
		this.overlayVisible = true;
		this.forceLoadThumbnail = true;
	}

	hideOverlay(): void {
		this.overlayVisible = false;
	}

	triggerAction(event: ThumbnailAction): void {
		this.triggerActionEmitter.emit(event);
	}

	thumbnailPreviewLoaded(): void {
		this.thumbnailLoaded = true;
	}
}
