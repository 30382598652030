<div [ppCdkUseTheme]="theme()" class="missing-result-container">
	<div class="missing-result-content">
		@switch (statusCode) {
			@case ('404') {
				<div class="missing-result-content-image not-found"></div>
				<h2 class="missing-result-content-header">Oooops! We couldn’t find this page</h2>
			}

			@case ('403') {
				<div class="missing-result-content-image forbidden"></div>
				<h2 class="missing-result-content-header">You have no permissions!</h2>
			}

			@case ('400') {
				<div class="missing-result-content-image bad-request"></div>
				<h2 class="missing-result-content-header">Bad Request</h2>
			}

			@case ('500') {
				<div class="missing-result-content-image internal-server-error"></div>
				<h2 class="missing-result-content-header">Internal Server Error</h2>
			}

			@case ('0') {
				<div class="missing-result-content-image internal-server-error"></div>
				<h2 class="missing-result-content-header">Oops, Something Went Wrong...</h2>
				<span class="missing-result-content-caption"
					>We're sorry for the inconvenience, but it looks like we're experiencing a
					technical glitch with your HTTP request. The error was reported with status 0,
					which usually means there is no internet connection, a network issue occurred or
					you manually stopped page loading.</span
				>
				<button (click)="reload()" class="dp-button dp-big missing-result-content-button">
					Refresh the Page
				</button>
			}

			@default {
				<div class="missing-result-content-image internal-server-error"></div>
				<h2 class="missing-result-content-header">Oops, Something Went Wrong...</h2>
				<span class="missing-result-content-caption"
					>There was an error. Please try again later or contact our
					<a
						class="missing-result-content-link"
						href=""
						target="_blank"
						>Support Team</a
					>.</span
				>
			}
		}
	</div>
</div>
