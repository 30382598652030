import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'fileName',
	standalone: true,
})
export class FileNamePipe implements PipeTransform {
	transform(value: string): string {
		const fileName = value.split('/').pop() ?? '';

		return fileName.replace(/\.\w+$/g, '').replace(/_/g, ' ');
	}
}
