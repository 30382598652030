import {ElementRef, Injectable, Injector, OnDestroy} from '@angular/core';
import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ModalRef} from '../modals/modal-container/modal.ref';
import {PopoverContainerComponent} from './popover-container/popover-container.component';
import {POPOVER_REF} from './popover-content.token';

@Injectable()
export class PopoverService implements OnDestroy {
	private modalRef?: ModalRef;

	constructor(
		private overlay: Overlay,
		private elementRef: ElementRef,
		private readonly injector: Injector,
	) {}

	ngOnDestroy(): void {
		this.modalRef?.close();
	}

	openPopOver<T>(
		cmp: ComponentType<T>,
		options: Partial<T>,
		connectedToElement: HTMLElement,
		popoverPosition?: 'left' | 'bottom',
		isScrollingBlocked = false,
	): T {
		const overlayRef = this.createOverlay(
			connectedToElement,
			popoverPosition,
			isScrollingBlocked,
		);
		overlayRef.hostElement.classList.add('popover-overlay-panel');

		this.modalRef = new ModalRef(overlayRef);

		const portal = new ComponentPortal(
			PopoverContainerComponent,
			null,
			this.createInjector(this.modalRef),
		);
		const {instance: portalInstance} = overlayRef.attach(portal);
		portalInstance.popoverPosition = popoverPosition;

		const {instance} = portalInstance.attachComponentPortal(new ComponentPortal(cmp));

		for (const key of Object.keys(options)) {
			Object.defineProperty(instance, key, {
				value: options[key as keyof typeof options],
				writable: true,
				configurable: true,
			});
		}

		return instance;
	}

	private createOverlay(
		connectedTo: HTMLElement,
		popoverPosition: 'left' | 'bottom' = 'bottom',
		isScrollingBlocked: boolean,
	): OverlayRef {
		const scrollStrategy = isScrollingBlocked
			? this.overlay.scrollStrategies.block()
			: this.overlay.scrollStrategies.close();
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(connectedTo)
			.withPositions([
				{
					originX: 'start',
					originY: popoverPosition === 'left' ? 'top' : 'bottom',
					overlayX: popoverPosition === 'left' ? 'end' : 'start',
					overlayY: 'top',
					offsetY: popoverPosition === 'left' ? -6 : 9,
					offsetX: popoverPosition === 'left' ? -8 : 0,
				},
			]);

		const overlay = this.overlay.create({
			scrollStrategy,
			positionStrategy,
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-transparent-backdrop',
			panelClass: this.getCurrentTheme(this.elementRef),
		});

		return overlay;
	}

	private getCurrentTheme(elementRef: ElementRef): string[] {
		const closestWithTheme = elementRef.nativeElement.closest('.dark-theme, .light-theme');

		return closestWithTheme?.classList.contains('light-theme') === true
			? ['uui-light-theme', 'light-theme']
			: ['uui-dark-theme', 'dark-theme'];
	}

	private createInjector(modalRef: ModalRef): Injector {
		return Injector.create({
			providers: [{provide: POPOVER_REF, useValue: modalRef}],
			parent: this.injector,
		});
	}
}
