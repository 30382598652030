<div class="cards-swiper-container dp-card" [ngClass]="{'hide-background': hideBackground}">
	<ng-content select="[header]"></ng-content>
	<div class="cards-swiper-list-container">
		@if (scrollButtonsState.controlsVisible && !scrollButtonsState.leftBtnDisabled) {
			<div class="swiper-shadow left" (click)="scrollLeft()">
				<pp-icon [name]="'navigation-chevron-left-18'" [size]="40"></pp-icon>
			</div>
		}
		<ul #cardsContainerRef class="cards-swiper-list">
			@for (item of cards; track index; let index = $index) {
				<li>
					<ng-container
						[ngTemplateOutlet]="itemTemplateRef"
						[ngTemplateOutletContext]="{$implicit: item, index}"
					></ng-container>
				</li>
			}
		</ul>

		@if (scrollButtonsState.controlsVisible && !scrollButtonsState.rightBtnDisabled) {
			<div class="swiper-shadow right" (click)="scrollRight()">
				<pp-icon [name]="'navigation-chevron-right-18'" [size]="40"></pp-icon>
			</div>
		}
	</div>
</div>
