@switch (type) {
	@case ('circle') {
		<div class="spinner-fading-circle" [ngClass]="getClass()">
			@for (index of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]; track $index) {
				<div class="spinner-circle{{ index }}"></div>
			}
		</div>
	}
	@case ('uui') {
		<div class="spinner-dp" [ngClass]="getClass()">
			<div class="spinner-dp-dot"></div>
		</div>
	}
}
