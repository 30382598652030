<shared-dropdown
	type="secondary"
	[iconOnly]="isMobile()"
	[icon]="isMobile() ? 'sorting-icon' : ''"
	openStrategy="click"
>
	<div dropdownHeader>Sort by {{ value().name }}</div>

	<ng-template #dropdownContent>
		@for (item of list(); track item.orderByExpression) {
			<a (click)="select(item)" class="sort-by-list-item">
				{{ item.name }}
				@if (item.orderByExpression === value().orderByExpression) {
					<pp-icon name="notification-done-18" />
				}
			</a>
		}
	</ng-template>
</shared-dropdown>
