import {Component, Input} from '@angular/core';
import {NgStyle} from '@angular/common';
import {BreakLinePipe} from '../../pipes';

@Component({
	selector: 'shared-note',
	templateUrl: './note.component.html',
	styleUrls: ['./note.component.scss'],
	standalone: true,
	imports: [NgStyle, BreakLinePipe],
})
export class NoteComponent {
	@Input({required: true}) text!: string;
	@Input() fontSize = 12;
}
