<div class="filters-settings-container">
	<header class="filters-settings-header" (click)="toggle()">
		Customize Filters
		<pp-icon
			[name]="expanded ? 'navigation-chevron-down-18' : 'navigation-chevron-right-18'"
			[size]="18"
		></pp-icon>
	</header>
	@if (expanded) {
		<ul class="filters-settings-list" cdkDropList (cdkDropListDropped)="drop($event)">
			@for (item of list; track item.id) {
				<li class="filters-settings-item" cdkDrag>
					<label>
						<pp-checkbox
							[checked]="item.visible"
							(change)="toggleVisibility($event.target, item)"
							[text]="item.name ?? ''"
						></pp-checkbox>
					</label>
					<pp-icon
						name="list"
						[size]="16"
						class="filters-settings-drag"
						aria-hidden="true"
						cdkDragHandle
					></pp-icon>
				</li>
			}
		</ul>
	}
	<div class="filters-settings-buttons">
		<button class="filters-settings-save" (click)="save()">Save</button>
		<button class="filters-settings-reset" (click)="reset()">Reset</button>
	</div>
</div>
