<div class="preview-container content-container" [class.no-calculate]="!calculateOnResize">
	<div
		class="preview-wrapper"
		[class.inline]="inline"
		[class.preview-wrapper-big]="isBig"
		[class.preview-hide-header]="isExtraHeaderExist"
		[class.preview-wrapper-highlighted]="isHighlightsExist"
	>
		@if (previewSource) {
			<iframe [src]="previewSource" allowfullscreen></iframe>
		}
	</div>
</div>
