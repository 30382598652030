<div class="link-confirmation-container">
	<div class="link-confirmation-content">
		<span
			>You are about to redirect the page that is not under
			<a
				class="link-confirmation-content-link"
				target="_blank"
				href="https://presales.com"
				>presales.com</a
			>
			control.</span
		>
		<span
			>Do you want to open
			<a
				class="link-confirmation-content-link"
				target="_blank"
				rel="noopener noreferrer"
				[href]="url"
				>{{ url }}</a
			>
			?
		</span>
	</div>

	<div class="link-confirmation-buttons">
		<button class="dp-button" type="button" (click)="openLink()">Ok</button>
		<button
			class="dp-button secondary confirmation-cancel"
			type="button"
			(click)="closePopOver()"
		>
			Cancel
		</button>
	</div>
</div>
