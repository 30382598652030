import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
	selector: 'pp-alert-action',
	standalone: true,
	templateUrl: './alert-action.component.html',
	styleUrls: ['./alert-action.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertActionComponent {}
