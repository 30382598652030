import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'hostname',
	standalone: true,
})
export class HostnamePipe implements PipeTransform {
	transform(value?: string): string {
		if (typeof value !== 'string' || value === '' || !value.startsWith('http')) {
			return '';
		}

		const url = new URL(value);

		return url.hostname;
	}
}
