@switch (type()) {
	@case ('verified') {
		<div class="flag-container flag-verified" [ngClass]="size()">
			<div class="flag-content">
				<pp-verified [iconSize]="12"></pp-verified>
				Verified
			</div>
		</div>
	}
	@case ('new') {
		<div class="flag-container flag-new" [ngClass]="size()">
			<div class="flag-content">New</div>
		</div>
	}
	@case ('video') {
		<div class="flag-container flag-video" [ngClass]="size()">
			<div class="flag-content">
				<pp-icon name="media-play-12" />
				Video
			</div>
		</div>
	}
	@case ('sanctioned') {
		<div class="flag-container flag-sanctioned" [ngClass]="size()">
			<div class="flag-content">Sanctioned</div>
		</div>
	}
	@case ('decommissioned') {
		<div class="flag-container flag-sanctioned" [ngClass]="size()">
			<div class="flag-content">Decommissioned</div>
		</div>
	}
	@case ('dep') {
		<div class="flag-container flag-dep" [ngClass]="size()">
			<div class="flag-content">
				<pp-icon name="dep_best_pitches_12" />
				DEP Best Pitches
			</div>
		</div>
	}
}
