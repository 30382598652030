import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
	ViewEncapsulation,
} from '@angular/core';

import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'shared-query-keywords',
	templateUrl: './query-keywords.component.html',
	styleUrls: ['./query-keywords.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
})
export class QueryKeywordsComponent {
	@Output() send = new EventEmitter<string>();

	constructor(private analytics: AnalyticsService) {}

	search(query: string): void {
		this.send.emit(query);
		this.analytics.trackSearchQueryTipsActions('keywords', query);
	}
}
