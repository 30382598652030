export function focusOnInput(control: HTMLElement | undefined): void {
	if (!control) {
		return;
	}

	control.focus({preventScroll: true});
	window.scrollTo(0, 0);

	if (document.scrollingElement) {
		document.scrollingElement.scrollTop = 0;
	}
}
