import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {SearchItemActionInfo} from '../types/search-item-action-info.model';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'shared-card-action, [card-action]',
	templateUrl: './card-action.component.html',
	styleUrls: ['./card-action.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent],
})
export class CardActionComponent {
	@Input() name = '';
	@Input() size = 0;
	@Input({required: true}) actionInfo!: SearchItemActionInfo;

	@HostBinding('class.card-action')
	readonly isCardAction = true;

	@HostBinding('class.normal-gap')
	get isNormal() {
		return this.size === 30;
	}

	@HostBinding('class.medium-gap')
	get isMedium() {
		return this.size === 24;
	}
}
