import {Component, OnDestroy, ViewEncapsulation, inject} from '@angular/core';

import {Subscription} from 'rxjs';
import {first, switchMap, tap} from 'rxjs/operators';
import {UserProfileService} from '@shared/services';
import {UserStore} from '@shared/state';
import {CheckboxComponent} from '@pp/checkbox';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'panels-user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SvgIconComponent, CheckboxComponent],
})
export class UserSettingsComponent implements OnDestroy {
	private readonly subscriptions = new Subscription();
	private userStore = inject(UserStore);

	expanded = true;
	disabled = this.userStore.settings.disableConfidentialConfirmation;

	constructor(private userProfile: UserProfileService) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	toggle(): void {
		this.expanded = !this.expanded;
	}

	toggleWarningsDisplay(target: EventTarget | null): void {
		const checked = (target as HTMLInputElement).checked;
		this.subscriptions.add(
			this.userProfile
				.setUserSetting$('warningMode', checked ? 'disable' : 'enable')
				.pipe(
					switchMap(() => this.userProfile.getUserProfile$()),
					first(),
					tap((profile) => {
						this.userStore.updateUserProfile(profile);
						this.userStore.updateUserSettings(profile.settings);
					}),
				)
				.subscribe(),
		);
	}
}
