import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, shareReplay, tap, withLatestFrom} from 'rxjs/operators';

import {NgClass, AsyncPipe, DecimalPipe} from '@angular/common';
import {SearchFeatureState} from '../../reducers/search-state';
import {SearchModule} from '../../search.module';
import {SearchFiltersListTreeService} from './search-filters-list-tree.service';
import {TreeFilterViewmodel} from './tree-filter.viewmodel';
import {FilterModel, TreeFilterModel} from '@search/models';
import {RefinersBuilderService} from '@shared/services';
import {regexFromString} from '@shared/utils';
import {SvgIconComponent} from '@pp/svg';
import {MoreLessPipe} from '@shared/pipes';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'search-filters-list-tree',
	templateUrl: './search-filters-list-tree.component.html',
	styleUrls: [
		'./search-filters-list-tree.component.scss',
		'../search-filters-list/search-filters-list-item.component.scss',
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, SvgIconComponent, SearchModule, AsyncPipe, DecimalPipe, MoreLessPipe],
})
export class SearchFiltersListTreeComponent implements OnInit {
	filterSearch$!: Observable<RegExp | null>;
	items$!: Observable<TreeFilterViewmodel[]>;
	valueField = Symbol.for('value');

	showMore = true;
	enableShowMore = false;

	@Input({required: true}) source!: string;
	@Input() data$!: Observable<FilterModel | TreeFilterModel>;
	@Input() level: string | number = 0;
	@Output() toggleOnSearch = new EventEmitter<[string, boolean]>();

	expandedItems = this.getEmptyExpandedStore();
	searchExpandedItems = this.getEmptyExpandedStore();
	isSearchStarted = false;
	isSearch = false;

	constructor(
		private store$: Store<SearchFeatureState>,
		private treeListService: SearchFiltersListTreeService,
		private analytics: AnalyticsService,
		private refinersBuilder: RefinersBuilderService,
	) {}

	ngOnInit(): void {
		this.enableShowMore = +this.level === 0;
		this.showMore = !this.enableShowMore;

		this.filterSearch$ = this.store$
			.select((state) => state.search.ui.filterSearchRegex)
			.pipe(
				tap((searchRegexp) => {
					this.isSearch = !!searchRegexp;
					this.isSearchStarted = this.isSearch;

					if (!this.isSearch) {
						this.searchExpandedItems = this.getEmptyExpandedStore();
					}
				}),
				map(regexFromString),
			);

		if (!this.data$) {
			this.data$ = combineLatest([
				this.store$.select(
					(state) =>
						state.search.filters[
							this.source as keyof typeof state.search.filters
						] as TreeFilterModel,
				),
				this.filterSearch$,
			]).pipe(
				map(([data, filterSearch]) => {
					if (filterSearch) {
						const result = this.treeListService.applyFilterSearch(
							data,
							filterSearch,
							this.valueField,
						);

						this.toggleOnSearch.emit([this.source, Object.keys(result).length === 0]);

						return result;
					}

					this.toggleOnSearch.emit([this.source, false]);

					return data;
				}),
				shareReplay({bufferSize: 1, refCount: true}),
			);
		}

		// ! if you reset all filters you can have a performance issue
		// ! because of huge amount of items
		const items$ = this.data$.pipe(
			filter((value) => !!value),
			withLatestFrom(this.filterSearch$),
			map(([value, isSearch]) => {
				const keys = Object.entries(value)
					.sort(
						([, a], [, b]) =>
							(a[this.valueField]?.order ?? Infinity) -
							(b[this.valueField]?.order ?? Infinity),
					)
					.map(([key]) => key);
				const items = this.treeListService.order(keys);
				const itemsCount = items.length;

				if (!isSearch) {
					this.expandedItems = this.getEmptyExpandedStore();
				}

				// ! Sergey Tihon found an error where filter(s) = undefined but I can't replicate it
				// ! And it's unknown how undefined filters goes to the items array
				// ! So I changed .map() to .reduce() to filter undefined items.
				return items.reduce<TreeFilterViewmodel[]>((output, key) => {
					const filterDef = value[key as keyof typeof value][
						this.valueField
					] as TreeFilterViewmodel;

					if (!filterDef) {
						return output;
					}

					const hasChildren = Object.keys(value[key as keyof typeof value]).length > 0;

					if (hasChildren) {
						if (this.isSearchStarted) {
							this.toggleExpanded(this.searchExpandedItems, filterDef.token);
						}
						// ! We made a rule if there is only one item in list we show the item as expanded at once
						else if (itemsCount === 1) {
							this.addToExpanded(this.expandedItems, filterDef.token);
						}
					}

					output.push({
						...filterDef,
						hasChildren,
						filters: [filterDef],
					});

					return output;
				}, []);
			}),
		);

		const include$ = this.store$.select(
			(state) =>
				state.search.selectedFilters.include[
					this.source as keyof typeof state.search.selectedFilters.include
				],
		);
		const exclude$ = this.store$.select(
			(state) =>
				state.search.selectedFilters.exclude[
					this.source as keyof typeof state.search.selectedFilters.exclude
				],
		);

		this.items$ = combineLatest([items$, include$, exclude$]).pipe(
			map(([items, includeFilters, excludeFilters]) =>
				this.treeListService.filterTree(items, includeFilters, excludeFilters),
			),
		);
	}

	getSubtree$(nodeValue: string): Observable<FilterModel | TreeFilterModel> {
		return this.data$.pipe(
			filter((value) => !!value),
			map((tree) => tree[nodeValue as keyof typeof tree]),
		);
	}

	getLevel(): number {
		return +this.level + 1;
	}

	includeFilter(item: TreeFilterViewmodel): void {
		this.treeListService.includeFilter(item, this.source);
	}

	excludeFilter(item: TreeFilterViewmodel): void {
		this.treeListService.excludeFilter(item, this.source);
	}

	isExpanded(item: TreeFilterViewmodel): boolean {
		if (this.isSearch) {
			return this.isSearchStarted || this.searchExpandedItems.has(item.token);
		}

		return this.expandedItems.has(item.token);
	}

	toggleItem(item: TreeFilterViewmodel): void {
		if (this.isSearch) {
			this.isSearchStarted = false;
			this.toggleExpanded(this.searchExpandedItems, item.token);
		} else {
			this.toggleExpanded(this.expandedItems, item.token);
		}
	}

	toggleShowMore(): void {
		this.showMore = !this.showMore;
		this.analytics.trackFilterAction(this.showMore ? 'showMore' : 'showLess', this.source);
	}

	hasLazy(): boolean {
		return this.refinersBuilder.hasRefineLazy(this.source);
	}

	private toggleExpanded(expandedStore: Set<string>, token: string): void {
		const isExpanded = expandedStore.has(token);
		if (isExpanded) {
			this.removeFromExpanded(expandedStore, token);
		} else {
			this.addToExpanded(expandedStore, token);
		}
	}

	private removeFromExpanded(expandedStore: Set<string>, token: string): void {
		expandedStore.delete(token);
	}

	private addToExpanded(expandedStore: Set<string>, token: string): void {
		expandedStore.add(token);
	}

	private getEmptyExpandedStore(): Set<string> {
		return new Set<string>();
	}
}
