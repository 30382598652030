import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import {RouterLink} from '@angular/router';
import {NewsCardModel} from '../../models';
import {AnalyticsService} from '@shared/analytics';
import {ButtonComponent} from '@pp/button';

@Component({
	selector: 'dashboard-news-cards',
	standalone: true,
	imports: [RouterLink, ButtonComponent],
	templateUrl: './news-cards.component.html',
	styleUrls: ['./news-cards.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCardsComponent implements OnInit {
	@Input({required: true}) item!: NewsCardModel;
	@Input({required: true}) index!: number;
	@Output() clickButtonEvent = new EventEmitter<string>();

	gradientTheme!: number;

	constructor(private readonly analytics: AnalyticsService) {}

	ngOnInit(): void {
		this.gradientTheme = this.index % 6;
	}

	clickButton(event: Event, eventName: string | undefined, link: string | undefined): void {
		if (eventName) {
			event.preventDefault();
			this.clickButtonEvent.emit(eventName);
		} else {
			this.analytics.trackDashboardAction('productNews', link);
		}
	}
}
