import {ChangeDetectionStrategy, Component, forwardRef, input} from '@angular/core';
import {SearchItemCardComponent} from '@preview/item-card';
import {DocumentIndexItem} from '@shared/api';

@Component({
	selector: 'search-preview-source-document',
	templateUrl: './preview-source-document.component.html',
	styleUrls: ['./preview-source-document.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [forwardRef(() => SearchItemCardComponent)],
})
export class PreviewSourceDocumentComponent {
	item = input.required<DocumentIndexItem>();
}
