import {Injectable} from '@angular/core';

import {QueryState, SelectedFiltersState} from '../../reducers/search-state';

@Injectable({providedIn: 'root'})
export class StateSerializerService {
	serialize({
		query,
		selectedFilters,
	}: {
		query: QueryState;
		selectedFilters: SelectedFiltersState;
	}): string {
		return JSON.stringify(
			this.filterData({
				query: this.filterData(query, ['currentQuery', 'isLoading']),
				selectedFilters: this.filterData({
					include: this.filterData(selectedFilters.include),
					exclude: this.filterData(selectedFilters.exclude),
				}),
			}),
		);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private filterData(object: object, skipProperties: string[] = []) {
		const result: {[key: string]: object} = {};

		Object.keys(object).forEach((property) => {
			const value = object[property as keyof typeof object];
			const nonEmptyArray = (t: unknown): boolean => Array.isArray(t) && t.length > 0;
			const nonEmptyString = (t: unknown): boolean => typeof t === 'string' && t !== '';
			const nonEmptyObject = (t: unknown): boolean =>
				typeof t === 'object' && t !== null && Object.keys(t).length > 0;
			const nonFalseBoolean = (t: unknown): boolean => typeof t === 'boolean' && t;

			if (
				skipProperties.indexOf(property) === -1 &&
				(nonEmptyArray(value) ||
					nonEmptyString(value) ||
					nonEmptyObject(value) ||
					nonFalseBoolean(value))
			) {
				result[property] = value;
			}
		});

		return result;
	}
}
