@if (items$ | async; as section) {
	<search-cards-swiper [items]="section.items" [hideBackground]="true">
		<ng-container header>
			<header class="preset-section-header">
				<div class="preset-section-header-title">
					<h3>{{ header }}</h3>

					<pp-icon [name]="'notification-info-outline-18'" [hint]="description"></pp-icon>
				</div>

				<search-preset-link
					class="dp-card-header-more-link preset-section-header-link"
					[link]="getShowMoreLink()"
					(beforeNavigation)="trackShowMore(header)"
				>
					Show all ({{ section.totalCount | format }})
					<pp-icon [name]="'navigation-chevron-right-18'"></pp-icon>
				</search-preset-link>
			</header>
		</ng-container>
		<ng-template #itemTemplate let-item>
			<search-item-card
				[item]="item.document"
				size="normal"
				direction="vertical"
				[lightMode]="true"
				[context]="section.items"
			></search-item-card>
		</ng-template>
	</search-cards-swiper>
}
