import {Action} from '@ngrx/store';

import {FilterModel} from '@search/models';

export const UPDATE_ACCESS_LEVEL_FILTER = '[Search] Update Access Level Filter';

export class UpdateAccessLevelFilterAction implements Action {
	readonly type = UPDATE_ACCESS_LEVEL_FILTER;

	constructor(public payload: FilterModel[]) {}
}
