import {ChangeDetectionStrategy, Component, inject, ViewEncapsulation} from '@angular/core';
import {OfficeStore} from '@office/state';

import {SvgImageComponent} from '@pp/svg';
import {HintDirective} from '@pp/hint';

@Component({
	selector: 'core-powerpoint-toggle',
	standalone: true,
	imports: [SvgImageComponent, HintDirective],
	templateUrl: './powerpoint-toggle.component.html',
	styleUrls: ['./powerpoint-toggle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class PowerPointToggleComponent {
	officeStore = inject(OfficeStore);

	showOnlyPowerPoint = this.officeStore.showOnlyPowerPoint;

	toggle(): void {
		this.officeStore.toggleShowOnlyPowerPoint();
	}
}
