import {
	ChangeDetectionStrategy,
	Component,
	effect,
	inject,
	computed,
	signal,
	HostBinding,
	input,
	HostListener,
} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {transition, trigger, style, animate} from '@angular/animations';
import {UseThemeDirective} from '@shared/directives';
import {SvgIconComponent} from '@pp/svg';
import {NavigationService} from '@shared/services';
import {UiStore, UserStore} from '@shared/state';
import {DocumentScrollService} from '@shared/modals';

@Component({
	selector: 'shared-mobile-filters-toggler',
	standalone: true,
	imports: [SvgIconComponent, UseThemeDirective, AsyncPipe],
	templateUrl: './mobile-filters-toggler.component.html',
	styleUrl: './mobile-filters-toggler.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('expandedFilters', [
			transition(':enter', [
				style({width: '0', opacity: 0}),
				animate('100ms ease-out', style({width: '*', opacity: 1})),
			]),
			transition(':leave', [
				style({width: '*', opacity: 1}),
				animate('200ms ease-out', style({width: '0', opacity: 0})),
			]),
		]),
	],
})
export class MobileFiltersTogglerComponent {
	strictLightMode = input<boolean>(false);

	private windowState = inject(UiStore).window;
	theme = inject(UserStore).settings.uiTheme;

	filtersOpened = signal(false);

	@HostBinding('class.opened')
	get opened(): boolean {
		return this.filtersOpened();
	}

	@HostListener('window:keyup', ['$event'])
	handleKeyup(event: KeyboardEvent): void {
		if (this.isMobileFiltersVisible() && event.key === 'Escape') {
			this.closeFilters();
		}
	}

	isMobileFiltersVisible = computed(
		() => this.filtersOpened() && this.windowState().width <= 1000,
	);

	isNavPanelOpened = this.navigationService.getNavPanelState();

	constructor(
		private readonly documentScrollService: DocumentScrollService,
		private readonly navigationService: NavigationService,
	) {
		effect(() => {
			if (this.isMobileFiltersVisible()) {
				this.documentScrollService.hideDocumentScroll();
			} else {
				this.documentScrollService.showDocumentScroll();
			}
		});
	}

	toggleFilters(): void {
		this.filtersOpened.update((value) => !value);
	}

	closeFilters(): void {
		this.filtersOpened.set(false);
	}
}
