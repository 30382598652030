<div class="preview-slides-section">
	@if (sectionName()) {
		<div class="preview-slides-section-header" [class.powerpoint-mode]="isInsidePowerPoint()">
			<pp-icon
				class="preview-slides-section-header-chevron"
				[name]="expanded() ? 'navigation-chevron-down-18' : 'navigation-chevron-right-18'"
				(click)="toggleExpand($event)"
			></pp-icon>

			@if (!isInsidePowerPoint()) {
				<pp-checkbox-selection
					[items]="slides()"
					description="section"
					[alwaysVisible]="true"
					[labelTemplate]="labelTemplate"
				>
				</pp-checkbox-selection>
			}

			<ng-template #labelTemplate>
				<span class="preview-slides-section-header-title">
					{{ sectionName() }}
					<span
						>({{ slidesCount() }} {{ slidesCount() === 1 ? 'slide' : 'slides' }})</span
					>
				</span>
			</ng-template>

			@if (isInsidePowerPoint()) {
				<div class="preview-slides-section-header-title" (click)="toggleExpand($event)">
					{{ sectionName() }}
					<span
						>({{ slidesCount() }} {{ slidesCount() === 1 ? 'slide' : 'slides' }})</span
					>
				</div>
			}

			@if (isInsidePowerPoint() && actionInfo().length > 0) {
				<shared-dropdown
					class="preview-slides-section-dropdown-button"
					type="cancel"
					icon="navigation-more_vert-18"
					[iconOnly]="true"
					[showChevron]="false"
					openStrategy="click"
				>
					<ng-template #dropdownContent>
						@for (info of actionInfo(); track info.actionType) {
							<a (click)="insertSection(info.actionType)">
								<pp-icon [name]="info.iconName" [size]="24"></pp-icon>
								<span>{{ info.name }}</span>
							</a>
						}
					</ng-template>
				</shared-dropdown>
			}
		</div>
	}
	@if (expanded()) {
		<div
			class="preview-slides-section-content"
			[ngClass]="{'without-tile': !sectionName()}"
			[class.expanded]="expanded()"
		>
			@for (item of slides(); track item.id) {
				<div
					class="preview-slides-section-item"
					[class.active]="item.id === selectedSlideId()"
				>
					<search-item-card
						[item]="item"
						size="normal"
						direction="vertical"
						[lightMode]="true"
						[displayedInTheContext]="true"
						[context]="slidesInOrder()"
					></search-item-card>
					<span class="slide-index">{{ item.file?.generated?.slide?.number ?? 0 }}</span>
				</div>
			}
		</div>
	}
</div>
