import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {NgClass, DecimalPipe} from '@angular/common';
import {SearchFilterHeaderComponent} from '../filter-header/search-filter-header.component';
import {CheckboxComponent} from '@pp/checkbox';
import {MoreLessPipe} from '@shared/pipes';
import {PpFacetWithHighlight} from '@search2/models';
import {FacetConfig, PpFacetResult} from '@shared/api';
import {regexFromString, highlightMatch} from '@shared/utils';
import {SearchStore} from '@search2/state';

@Component({
	selector: 'search-checkbox-filters',
	templateUrl: './checkbox-filters.component.html',
	styleUrls: ['./checkbox-filters.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SearchFilterHeaderComponent, NgClass, CheckboxComponent, DecimalPipe, MoreLessPipe],
})
export class SearchCheckboxFiltersComponent {
	config = input.required<FacetConfig>();

	isExpanded = true;
	isShowMore = true;

	searchStore = inject(SearchStore);

	fieldName = computed(() => this.config().fieldName);
	private allFilters = computed(() => {
		const facets = this.searchStore.facets();

		return facets[this.fieldName()] ?? [];
	});
	selectedFilters = computed(() => {
		const selectedFilter = this.searchStore.searchQuery.selectedFilters();

		return selectedFilter[this.fieldName()] ?? [];
	});

	filters = computed(() => {
		let filters: PpFacetWithHighlight[] = this.allFilters();

		const regex = regexFromString(this.searchStore.facetSearchRegex());
		if (regex) {
			filters = this.allFilters()
				.filter((f) => regex.test(f.text))
				.map(
					(f): PpFacetWithHighlight => ({
						text: f.text,
						count: f.count,
						value: f.value,
						highlightedText: f.text.replace(regex, highlightMatch),
					}),
				);
		}

		return filters;
	});

	isFiltered = computed(() => {
		return this.filters().length !== this.allFilters().length;
	});

	itemsCount = computed(() => {
		if (this.isFiltered()) {
			return 0;
		}

		if (this.filters().length > this.config().showByDefault) {
			return this.filters().length;
		}

		return 0;
	});

	isEmpty(facet: PpFacetResult): boolean {
		const config = this.config();
		const filters = this.selectedFilters();
		if (config.filterOperator === 'or' && config.isMultiSelect && filters.length > 0) {
			return !this.checkboxIsSelected(facet.value);
		}

		return facet.count === 0;
	}

	toggleExpand(): void {
		this.isExpanded = !this.isExpanded;
	}

	reset(): void {
		this.searchStore.resetFilters(this.fieldName());
	}

	checkboxIsSelected(fieldValue: string) {
		return this.selectedFilters().includes(fieldValue);
	}

	checkboxToggle(fieldValue: string) {
		const fieldNames = this.fieldName();
		if (this.selectedFilters().includes(fieldValue)) {
			this.searchStore.removeFilter(fieldNames, fieldValue);
		} else {
			this.searchStore.selectFilter(fieldNames, fieldValue);
		}
	}

	toggleShowMore(): void {
		this.isShowMore = !this.isShowMore;
	}
}
