import {ChangeDetectionStrategy, Component, input, computed, inject} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DocDetailsCrmProject} from '@shared/api';
import {AnalyticsService} from '@shared/analytics';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'search-project-info',
	templateUrl: './project-info.component.html',
	styleUrls: ['./project-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent, DatePipe],
})
export class ProjectInfoComponent {
	project = input.required<DocDetailsCrmProject>();
	accountAm = input<string>();

	constructor(private sanitizer: DomSanitizer) {}

	isActive = computed<boolean>(() => this.project().status === 'Active');
	telescopeLink = computed<SafeResourceUrl>(() =>
		this.sanitizer.bypassSecurityTrustResourceUrl(
			`https://telescope.epam.com/project/${this.project().code}`,
		),
	);

	private analytics = inject(AnalyticsService);
	trackProjectNavigation(): void {
		this.analytics.trackDocumentPreviewAction('open:link', 'project-telescope');
	}
}
