import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'format',
	standalone: true,
})
export class FormatPipe implements PipeTransform {
	transform(num: number): string {
		return new Intl.NumberFormat('en-US').format(num);
	}
}
