import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {SearchFacetTreeModel} from '@search2/models';
import {DocumentTaxonomyService, SearchQueryService} from '@search2/services';

@Injectable({providedIn: 'root'})
export class ToolboxTemplatesService {
	constructor(
		private readonly searchService: SearchQueryService,
		private readonly taxonomyService: DocumentTaxonomyService,
	) {}

	getTemplates$(): Observable<SearchFacetTreeModel> {
		return this.getSectionByName$('Templates', false);
	}

	getReusableContent$(): Observable<SearchFacetTreeModel> {
		return this.getSectionByName$('Reusable Content', true);
	}

	private getSectionByName$(
		name: string,
		sortByTitle: boolean,
	): Observable<SearchFacetTreeModel> {
		return this.getToolboxPowerPoints$(sortByTitle).pipe(
			map((toolboxTemplates) => {
				const templates = toolboxTemplates.children.find((item) => item.name === name);

				return templates ?? this.emptyTree;
			}),
		);
	}

	private emptyTree: SearchFacetTreeModel = {
		name: 'empty',
		children: [],
		items: [],
		count: 0,
	};

	private getToolboxPowerPoints$(sortByTitle: boolean): Observable<SearchFacetTreeModel> {
		const toolboxTaxonomyFieldName = 'spo_presales.toolbox_taxonomy';
		const query = this.searchService.createQuery({
			presetId: 'toolbox',
			facets: [toolboxTaxonomyFieldName],
			hiddenConstraints: 'doc_type:"PowerPoint"',
			size: 100,
		});

		return this.searchService.search$(query).pipe(
			map((searchResult) => {
				const toolboxTaxonomy = searchResult.facets?.[toolboxTaxonomyFieldName];
				if (!toolboxTaxonomy) {
					return this.emptyTree;
				}

				if (sortByTitle && searchResult.results) {
					// sort by title, it probably should be done on the backend in the future
					searchResult.results.sort((a, b) => {
						const aTitle = a.document.file?.name.toLowerCase() ?? '';
						const bTitle = b.document.file?.name.toLowerCase() ?? '';

						return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0;
					});
				}

				const tree = this.taxonomyService.parseTree(toolboxTaxonomy);

				return this.taxonomyService.processFacetTree(
					tree,
					searchResult.results,
					toolboxTaxonomyFieldName,
					undefined,
				);
			}),
		);
	}
}
