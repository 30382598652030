import {Location} from '@angular/common';
import {Inject, Injectable, inject} from '@angular/core';
import {Router} from '@angular/router';
import {
	BrowserAuthError,
	BrowserAuthErrorCodes,
	Configuration,
	EndSessionRequest,
	LogLevel,
	PublicClientApplication,
} from '@azure/msal-browser';
import {CONFIG, EnvConfigType} from '@environments/environment';

import {ImpersonateService, ToastService} from '@shared/services';
import {ToastMessageModel} from '@shared/models';
import {UserStore} from '@shared/state';
import {AnalyticsService} from '@shared/analytics';

@Injectable({providedIn: 'root'})
export class CloudAuthenticationService {
	private readonly returnUrlKey = 'uspp.returnUrl';
	private loginHint?: string = undefined;
	private msalInstance?: PublicClientApplication;
	private inProgress?: Promise<string>;

	constructor(
		private router: Router,
		private location: Location,
		private analytics: AnalyticsService,
		private impersonate: ImpersonateService,
		@Inject(CONFIG) private readonly config: EnvConfigType,
		private readonly toastService: ToastService,
	) {}

	private userEmail = inject(UserStore).profile.userEmail;

	/**
	 * Primary way to request access token for Cloud API
	 * This method ensure that we don't call MSAL API multiple times in "parallel"
	 * and return the same promise for all callers.
	 *
	 * Note: There is no protection for broken state in MSAL cache (localStorage in our case).
	 * Incorrect redirects handling or parallel login in multiple tabs still can lead to login issues
	 *
	 * @returns {Promise<string>} - access token
	 */
	getApiAccessToken(): Promise<string> {
		if (this.inProgress) {
			return this.inProgress;
		}

		return (this.inProgress = new Promise((resolve, reject) => {
			this.acquireAccessTokenRaw()
				.then((accessToken) => {
					this.inProgress = undefined;
					resolve(accessToken);
				})
				.catch((error) => {
					reject(error);
				});
		}));
	}

	/**
	 * Raw MSAL login & token acquisition flow
	 *
	 * Note: implemented using async/await to simplify code
	 * (no need to handle Promise `.then`, `.catch` and `.finally`)
	 *
	 * @returns {Promise<string>} - access token
	 */
	private async acquireAccessTokenRaw(): Promise<string> {
		// Initialize MSAL instance and try to pick active account
		const msalInstance = await this.getMsalInstance();
		// Login user if there is no active account
		await this.login(msalInstance);

		const tokenRequest = this.getTokenRequest();

		const activeAccount = msalInstance.getActiveAccount();
		if (activeAccount !== null) {
			// Try to get access token silently, if we know user
			// fallback to interactive login otherwise
			try {
				const response = await msalInstance.acquireTokenSilent({
					...tokenRequest,
					account: activeAccount,
				});

				return response.accessToken;
			} catch (silentError) {
				const accessToken = await this.acquireTokenInteractive(msalInstance);
				if (accessToken !== null) {
					return accessToken;
				}
			}
		} else {
			// If we don't know user, we need to login interactively
			const accessToken = await this.acquireTokenInteractive(msalInstance);
			if (accessToken !== null) {
				return accessToken;
			}
		}

		throw new Error('Failed `requestApiToken`');
	}

	/**
	 * Request access token interactively
	 * - if we are inside iframe, try to use `acquireTokenPopup` (and show error if popups are blocked)
	 * - otherwise use `acquireTokenRedirect` and start redirect flow
	 */
	private async acquireTokenInteractive(
		msalInstance: PublicClientApplication,
	): Promise<string | null> {
		const tokenRequest = this.getTokenRequest();
		if (this.isInsideIframe()) {
			try {
				const response = await msalInstance.acquireTokenPopup(tokenRequest);

				return response.accessToken;
			} catch (error) {
				if (
					error instanceof BrowserAuthError &&
					error.errorCode === BrowserAuthErrorCodes.popupWindowError
				) {
					this.showPopupError(error);

					return null;
				} else {
					this.trackError('Unable to get access token', error);
					throw error;
				}
			}
		} else {
			this.saveReturnUrl();
			await msalInstance.acquireTokenRedirect(tokenRequest);

			return null; // should not happen
		}
	}

	// https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-sso#sso-without-msaljs-login
	private getTokenRequest() {
		return {
			scopes: [`api://${this.config.apiAppId}/.default`],
			loginHint: this.loginHint,
		};
	}

	/**
	 * Login user to Azure AD, if there is no active account
	 *
	 * 1. Try `ssoSilent` with iframe login (if user already authenticated in another tab and browser support it)
	 * 2. If previous step failed, try `loginPopup` (if we hosted in iframe and we can't use `loginRedirect`)
	 * 3. Otherwise start `loginRedirect` flow
	 */
	private async login(msalInstance: PublicClientApplication): Promise<void> {
		if (msalInstance.getActiveAccount() !== null) {
			return;
		}

		const loginRequest = this.getTokenRequest();

		try {
			const loginResponse = await msalInstance.ssoSilent(loginRequest);
			if (loginResponse.account) {
				msalInstance.setActiveAccount(loginResponse.account);
			}
		} catch (ssoError) {
			if (this.isInsideIframe()) {
				try {
					// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
					const loginResponse = await msalInstance.loginPopup({
						...loginRequest,
						redirectUri: `${window.location.origin}${this.config.blankPagePath}`,
					});
					if (loginResponse.account) {
						msalInstance.setActiveAccount(loginResponse.account);
					}
				} catch (error) {
					if (
						error instanceof BrowserAuthError &&
						error.errorCode === BrowserAuthErrorCodes.popupWindowError
					) {
						this.showPopupError(error);
					} else {
						this.trackError('Unable to get SSO token in popup', error);
					}
				}
			} else {
				this.saveReturnUrl();
				await msalInstance.loginRedirect(loginRequest);
			}
		}
	}

	/**
	 * Handle redirect from Azure AD (receive access token from hash fragment)
	 * and redirect user to the page where he was before login
	 *
	 * Note: should be called on the page without authentication required (/login)
	 */
	async handleRedirectPromise(): Promise<void> {
		try {
			const msalInstance = await this.getMsalInstance();
			const authResult = await msalInstance.handleRedirectPromise();

			if (authResult !== null) {
				msalInstance.setActiveAccount(authResult.account);
			} else {
				this.analytics.trackException('handleRedirectPromise return null', false);
			}

			const returnUrl = window.sessionStorage.getItem(this.returnUrlKey);
			window.sessionStorage.removeItem(this.returnUrlKey);
			await this.router.navigateByUrl(returnUrl || this.config.serviceUrl);
		} catch (error) {
			this.trackError('Unable to handle redirect', error);
		}
	}

	/**
	 * Logout use from MSAL and Azure AD
	 * and then redirect to SharePoint SignOut page
	 */
	async logout(): Promise<void> {
		const msalInstance = await this.getMsalInstance();
		const endRequest: EndSessionRequest = {
			account: msalInstance.getActiveAccount(),
			postLogoutRedirectUri: `${this.config.serviceUrl}/_layouts/15/SignOut.aspx`,
		};

		if (this.isInsideIframe()) {
			await msalInstance.logoutPopup(endRequest);
		} else {
			await msalInstance.logoutRedirect(endRequest);
		}
	}

	private saveReturnUrl() {
		const currentLocation = this.location.path();

		if (currentLocation && !currentLocation.includes('login')) {
			window.sessionStorage.setItem(this.returnUrlKey, currentLocation);
		}
	}

	/**
	 * MSAL instance is created and configured only once and stored in the service
	 * @returns {Promise<PublicClientApplication>} - msalInstance
	 */
	private async getMsalInstance(): Promise<PublicClientApplication> {
		if (!this.msalInstance) {
			const msalConfig: Configuration = {
				auth: {
					clientId: this.config.applicationId,
					authority: 'https://login.microsoftonline.com/medecision.onmicrosoft.com',
					redirectUri: `${window.location.origin}${this.config.redirectPath}`,
					navigateToLoginRequestUrl: false,
				},
				cache: {
					// https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-sso#sso-between-browser-tabs
					cacheLocation: 'localStorage',
					storeAuthStateInCookie: false,
				},
				system: {
					asyncPopups: true,
					loggerOptions: {
						loggerCallback: (
							level: LogLevel,
							message: string,
							containsPii: boolean,
						): void => {
							if (containsPii) {
								if (this.config.enableMsalLogging) {
									console.log('MSAL PII Message is ignored');
								}

								return;
							}

							switch (level) {
								case LogLevel.Error:
									this.analytics.trackException(message);
									console.error(message);
									break;
								case LogLevel.Warning:
									console.warn(message);
									break;
								default:
									if (this.config.enableMsalLogging) {
										console.log(message);
									}
									break;
							}
						},
						piiLoggingEnabled: false,
						logLevel: this.config.enableMsalLogging
							? LogLevel.Verbose
							: LogLevel.Warning,
					},
				},
			};

			if (!this.impersonate.get()) {
				// User already authenticated, we got profile from SharePoint
				// We can use it as login hint to avoid unnecessary redirects
				const email = this.userEmail();
				this.loginHint = email === '' ? undefined : email;
			}

			this.msalInstance = new PublicClientApplication(msalConfig);
			await this.msalInstance.initialize();
		}

		// If there is no active account, but we have more than one account in cache (we may pick one as active)
		if (this.msalInstance.getActiveAccount() === null) {
			let currentAccounts = this.msalInstance.getAllAccounts();

			if (currentAccounts && currentAccounts.length > 1) {
				const epamAccounts = currentAccounts.filter((account) =>
					account.username.includes('@epam'),
				);
				if (epamAccounts.length > 0) {
					currentAccounts = epamAccounts;
				}
			}

			const numberOfAccounts = currentAccounts?.length || 0;
			if (numberOfAccounts === 1) {
				this.msalInstance.setActiveAccount(currentAccounts[0]);
			} else if (numberOfAccounts > 1) {
				this.analytics.trackException('User authenticated in more than one account');
			}
		}

		return this.msalInstance;
	}

	private isInsideIframe(): boolean {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	private showPopupError(error: BrowserAuthError): void {
		const marker = Date.now().toString();

		const toast: ToastMessageModel = {
			type: 'fatalError',
			message: 'Pop-ups blocked. Please enable and refresh the page.',
			marker: marker,
		};

		this.toastService.show(toast);
		this.analytics.trackException(`MSAL popup window error: ${error.message}`);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private trackError(message: string, error: any) {
		if (error) {
			const errorMessage: string = (error.errorMessage ?? error.message ?? '')
				.split('.')
				.shift();
			const errorCode = error.errorCode ?? '<undefined>';
			this.analytics.trackException(`${message}[ErrorCode=${errorCode}]: ${errorMessage}`);
		}
	}
}
