import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	Output,
} from '@angular/core';
import {ModalRef} from '@shared/modals';
import {POPOVER_REF} from '@shared/popover';

@Component({
	selector: 'search-delete-confirmation',
	templateUrl: './delete-confirmation.component.html',
	styleUrls: ['./delete-confirmation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class DeleteConfirmationComponent {
	@Input({required: true}) itemToDeleteName!: string;
	@Input({required: true}) itemToDeleteType!: string;
	@Output() confirmDeletingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(@Inject(POPOVER_REF) private readonly popOverRef: ModalRef) {}

	cancelDeleting(): void {
		this.popOverRef.close();
	}

	confirmDeleting(): void {
		this.confirmDeletingEmitter.emit();
		this.cancelDeleting();
	}
}
