import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';

import {ThumbnailService} from '@shared/services';

@Component({
	selector: 'shared-thumbnail',
	templateUrl: './thumbnail.component.html',
	styleUrls: ['./thumbnail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
})
export class ThumbnailComponent implements OnInit, OnDestroy {
	private readonly maxRetryCount = 3;
	private readonly retryInterval = 10000;
	private retryCount = this.maxRetryCount;
	private timeoutHandle?: number;

	authorized = false;

	@Input() src?: string;
	@Input() external?: boolean;
	@Output() finishLoad = new EventEmitter();

	constructor(
		private thumbnail: ThumbnailService,
		private detector: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		if (this.src) {
			this.thumbnail.authorize(this.src).then(() => {
				this.authorized = true;
				this.detector.markForCheck();
			});
		}
	}

	ngOnDestroy(): void {
		clearTimeout(this.timeoutHandle);
	}

	handleError(event: Event): void {
		const image = event.target as HTMLImageElement;

		this.retryCount--;

		if (this.retryCount > 0 && !this.external) {
			image.removeAttribute('src');
			image.classList.add('hidden');

			setTimeout(
				() => {
					image.setAttribute('src', this.src ?? '');
					image.classList.remove('hidden');
				},
				(this.maxRetryCount - this.retryCount) * this.retryInterval,
			);
		} else {
			// Currently we don't track errors for OOS thumbnail images, because they will be proxies by our cloud service
			// if (!this.external) {
			// 	this.analytics.trackException(`An error ocurred during loading document thumbnail/preview. Number of retries: ${this.maxRetryCount}`);
			// }

			image.parentNode?.removeChild(image);
			this.finishLoad.emit(true);
		}
	}

	handleLoad(event: Event): void {
		const image = event.target as HTMLImageElement;

		image.classList.add('thumbnail-background');
		this.finishLoad.emit(true);
	}
}
