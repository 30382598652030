<shared-modal-window windowTitle="Deck Builder" class="sb-window v2-deck-builder">
	<ng-container main-footer-content>
		@if (loaded()) {
			@if (totalSlideCount() > 0) {
				<article class="sb-main" cdkScrollable>
					@if (unsupportedItems().length) {
						<div class="warning-section">
							<pp-alert [status]="'warning'" otherIcon="notification-warning-fill-24">
								<span
									>Deck Builder supports only PowerPoint presentations
									(*.pptx)</span
								>
							</pp-alert>
						</div>
					}
					<deck-builder-cover class="sb-main-wrapper"></deck-builder-cover>
					<div class="sb-main-wrapper">
						<deck-builder-section [items]="decks()"></deck-builder-section>
					</div>
				</article>
			} @else {
				<article class="sb-intro">
					<div class="sb-intro-info">
						<h2>
							Merge PowerPoint presentations<br />
							into one deck without copypasting
						</h2>
						<div class="sb-intro-info-text">
							<span
								>To get started, visit
								<a [routerLink]="['/p/stories']">Stories</a> or
								<a [routerLink]="['/p/cases']">Case Studies</a> sections
							</span>
							<span>select presentations and open Deck Builder.</span>
						</div>
					</div>
					<div class="sb-intro-img">
						<pp-spinner size="big"></pp-spinner>
						<div class="sb-intro-img-preload"></div>
					</div>
				</article>
			}
		} @else {
			<div class="sb-main skeleton-container">
				<pp-skeleton
					[type]="'horizontal-card'"
					[showOnlyCardsOnHorizontal]="true"
				></pp-skeleton>
			</div>
		}

		@if (!(loaded() && !totalSlideCount())) {
			<div class="sb-buttons">
				<span class="slides-count-container"
					>Total Number of Slides:
					<span
						[ngClass]="{
							'maximum-slides-indicator': totalSlideCount() >= 200
						}"
						hint="Please reduce total number of slides to 200"
						[hintPosition]="'RIGHT'"
						[hintDisabled]="totalSlideCount() < 200"
						>{{ totalSlideCount() }}</span
					></span
				>
				<button
					btn
					btnType="primary"
					[disabled]="!loaded()"
					class="sb-buttons-build"
					withIconBefore="download-slide-18"
					hintPosition="BOTTOM"
					(click)="buildAndClear()"
				>
					Build & Download
				</button>
			</div>
		}
	</ng-container>
</shared-modal-window>
