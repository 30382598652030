<aside class="filters-content" [style.height]="height()">
	@if (isLoading()) {
		<pp-skeleton type="aside"></pp-skeleton>
	} @else {
		<search-filter-bar></search-filter-bar>

		<nav class="filters-content-menu">
			@if (facetConfigs().length > 0) {
				<article class="filters-content-scroll-container">
					@for (config of facetConfigs(); track config.fieldName) {
						@switch (config.facetType) {
							@case ('list') {
								<search-checkbox-filters
									*ppAnchor="'filter-section'; with: config.name"
									[config]="config"
								></search-checkbox-filters>
							}
							@case ('tree') {
								<search-tree-filters
									*ppAnchor="'filter-section'; with: config.name"
									[config]="config"
								></search-tree-filters>
							}
							@default {
								<search-histogram
									*ppAnchor="'filter-section'; with: config.name"
									[config]="config"
								></search-histogram>
							}
						}
					}
				</article>
			}
		</nav>
	}
</aside>
