import {Directive, HostBinding, InjectFlags} from '@angular/core';
import {Constructor, HasInjector, IWithTheme, ThemeService} from '@shared/theme';

export function withTheme<T extends Constructor<HasInjector>>(
	Base: T,
): Constructor<IWithTheme> & T {
	@Directive()
	class WithTheme extends Base {
		@HostBinding('class.theme-context--light')
		get themeContextLight(): boolean {
			return this.themeService?.isLight();
		}

		@HostBinding('class.theme-context--dark')
		get themeContextDark(): boolean {
			return this.themeService?.isDark();
		}

		private themeService: ThemeService;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		constructor(...args: any[]) {
			super(...args);

			this.themeService = this.injector.get(ThemeService, InjectFlags.Optional);
		}
	}

	return WithTheme;
}
