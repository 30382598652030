import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

import {Observable} from 'rxjs';
import {SearchResultsModel, SearchResultModel} from '@shared/models';
import {PostQueryService, ResultsService, RequestData} from '@shared/services';
import {ResponseFragment} from '@search2/models';

@Injectable({providedIn: 'root'})
export class WhatsNewSpService {
	private readonly count = 10;

	constructor(
		private postQueryService: PostQueryService,
		private resultsService: ResultsService,
	) {}

	getShowcase$(): Observable<ResponseFragment<SearchResultModel>> {
		const requestData: RequestData = {
			includeFilters: {
				documentTags: ['"ǂǂ43617365207374756479"'],
				sourceSystem: ['"ǂǂ73686f77636173652e6570616d2e636f6d"'],
			},
			sortBy: 'Write',
			sortDirection: 1,
			query: 'usppOgImage:showcase',
		};

		return this.getData$(requestData).pipe(
			map((data) => ({
				items: data.data,
				totalCount: data.count,
			})),
		);
	}

	getInfoNews$(): Observable<ResponseFragment<SearchResultModel>> {
		const requestData: RequestData = {
			includeFilters: {
				documentTags: ['"ǂǂ43617365207374756479"'],
				sourceSystem: ['"ǂǂ696e666f2e6570616d2e636f6d"'],
			},
			sortBy: 'Write',
			sortDirection: 1,
			query: 'usppOgImage:info',
		};

		return this.getData$(requestData).pipe(
			map((data) => ({
				items: data.data,
				totalCount: data.count,
			})),
		);
	}

	getElementsCases$(): Observable<SearchResultsModel> {
		const count = 7;
		const requestData: RequestData = {
			includeFilters: {
				documentTags: ['"ǂǂ43617365207374756479"'],
				sourceSystem: ['"ǂǂ656c656d656e74732e6570616d2e636f6d"'],
			},
			sortBy: 'Write',
			sortDirection: 1,
		};

		return this.getData$(requestData, count);
	}

	getEpamComPages$(): Observable<SearchResultsModel> {
		const count = 7;
		const requestData: RequestData = {
			includeFilters: {
				sourceSystem: ['"ǂǂ7777772e6570616d2e636f6d"'],
			},
			sortBy: 'Write',
			sortDirection: 1,
			query: '-title:sitemap',
		};

		return this.getData$(requestData, count);
	}

	getRecommendedDecks$(): Observable<ResponseFragment<SearchResultModel>> {
		const requestData: RequestData = {
			excludeFilters: {
				documentTags: ['"ǂǂ4175746f2d67656e657261746564"'],
			},
			includeFilters: {
				locationFilterTree: [
					'"ǂǂ4f66666572696e677320616e64206578706572746973653a50726573616c6573205265636f6d6d656e6473"',
				],
				fileType: ['"ǂǂ70707478"'],
			},
			sortBy: 'Write',
			sortDirection: 1,
		};

		return this.getData$(requestData).pipe(
			map((data) => ({
				items: data.data,
				totalCount: data.count,
			})),
		);
	}

	private getData$(requestData: RequestData, count = this.count): Observable<SearchResultsModel> {
		return this.postQueryService
			.retrieve$(requestData, {rowLimit: count, skipFilterLoadIndicator: true}, new Set())
			.pipe(
				map((response) => {
					const postquery = response.d.postquery;

					return this.resultsService.extractData(
						postquery.PrimaryQueryResult.RelevantResults,
						postquery.SpellingSuggestion,
						postquery.Properties.results,
					);
				}),
			);
	}
}
