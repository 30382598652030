@if (querySuggestions$ | async; as querySuggestions) {
	@if (querySuggestions.length) {
		<div class="container dp-autosuggest">
			<ul>
				@for (suggestion of querySuggestions; track suggestion.text) {
					<li
						class="dp-autosuggest-item"
						[ngClass]="{
							selected: suggestion.text === selectedSuggestion,
							'user-history': suggestion.userHistory
						}"
						(mouseover)="selectSuggestionByText(suggestion.text)"
						(click)="submitSuggestion(suggestion.text)"
					>
						<pp-icon
							class="dp-autosuggest-item-icon"
							[name]="
								suggestion.userHistory ? 'action-schedule-18' : 'action-search-18'
							"
						/>
						<span [innerHTML]="suggestion.reachText"></span>
					</li>
				}
			</ul>
		</div>
	}
}
