<div>
	@if (isLoading() && items().length === 0) {
		<shared-page-progress-bar></shared-page-progress-bar>
		<pp-skeleton
			[type]="!showGroupedItems() ? 'horizontal-card' : 'vertical-card'"
			[isChipsVisibleOnVertical]="true"
		></pp-skeleton>
	} @else {
		@if (!isInsidePowerPoint()) {
			@if (isInformativeBannerVisible()) {
				<search-informative-banner
					[presetId]="presetId()"
					[class.search-context-grouped]="showGroupedItems()"
				>
				</search-informative-banner>
			}
		}

		@if (isQueryRelaxed()) {
			<pp-alert
				class="query-relaxed-alert"
				[class.grouped-mode]="showGroupedItems()"
				[status]="'warning'"
				otherIcon="notification-info-fill-24"
			>
				<span
					>We couldn't find an exact match and expanded the search for more results.</span
				>
			</pp-alert>
		}

		<search-context [class.search-context-grouped]="showGroupedItems()"></search-context>

		@if (!isLoading() && items().length === 0) {
			<search-no-results [query]="textQuery()"></search-no-results>
		} @else {
			<div class="card-list-container">
				@if (showGroupedItems()) {
					<search-results-grouped> </search-results-grouped>
				} @else {
					<search-results-list
						[items]="items()"
						[presetId]="presetId()"
						[query]="textQuery()"
						[isVerticalPreferred]="isGridViewPreferred()"
					></search-results-list>
				}

				@if (isLoading() && items().length > 0) {
					<div
						class="loading-overlay"
						[ngClass]="{'horizontal-mode': !showGroupedItems()}"
					>
						<div class="loading-overlay-content">
							<pp-spinner class="loading-overlay-spinner" type="uui"></pp-spinner>
							Loading
						</div>
					</div>
				}
			</div>
		}
	}
</div>

<search-selection-panel-gap></search-selection-panel-gap>
