import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	computed,
	effect,
	inject,
} from '@angular/core';
import {NgClass} from '@angular/common';
import {PresetAdComponent} from '../preset-ad/preset-ad.component';
import {InformativeBannerComponent} from '../informative-banner/informative-banner.component';
import {SearchContextComponent} from './search-context/search-context.component';
import {NoResultsComponent} from './no-results/no-results.component';
import {SearchResultsGroupedComponent} from './search-results-grouped/search-results-grouped.component';
import {SearchResultsListComponent} from './search-results-list/search-results-list.component';
import {SelectionPanelGapComponent} from '@shared/selection/selection-panel-gap/selection-panel-gap.component';
import {SearchStore} from '@search2/state';
import {OfficeStore} from '@office/state';
import {UiStore} from '@shared/state';
import {NavigationService} from '@shared/services';
import {AlertComponent} from '@pp/alert';
import {SharedSkeletonComponent} from '@pp/skeleton';
import {SpinnerComponent} from '@pp/spinner';
import {PageProgressBarComponent} from '@shared/components/page-progress-bar/page-progress-bar.component';

@Component({
	selector: 'search-content',
	templateUrl: './search-content.component.html',
	styleUrls: ['./search-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		PageProgressBarComponent,
		SharedSkeletonComponent,
		InformativeBannerComponent,
		PresetAdComponent,
		AlertComponent,
		SearchContextComponent,
		NoResultsComponent,
		SearchResultsGroupedComponent,
		SearchResultsListComponent,
		NgClass,
		SpinnerComponent,
		SelectionPanelGapComponent,
	],
})
export class SearchContentComponent implements OnInit, OnDestroy {
	isInsidePowerPoint = inject(OfficeStore).isInsidePowerPoint;
	private searchStore = inject(SearchStore);

	isLoading = computed(() => this.searchStore.searchStatus() === 'loading');
	items = this.searchStore.searchResults.items;
	private preset = computed(() => this.searchStore.currentPreset());
	presetId = computed(() => this.preset()?.id ?? '');
	textQuery = this.searchStore.searchQuery.textQuery;
	hasSelectedFilters = computed(() => {
		const selectedFilters = this.searchStore.searchQuery.selectedFilters();

		return !!Object.values(selectedFilters).length;
	});

	isGridViewPreferred = this.searchStore.isGridViewPreferred;
	showGroupedItems = computed(() => {
		const textQuery = this.textQuery();
		const currentPreset = this.preset();

		return (
			textQuery === '' &&
			!!currentPreset?.groupByFieldName &&
			!this.searchStore.searchQuery.orderByExpression() &&
			!this.isInsidePowerPoint() &&
			this.isGridViewPreferred()
		);
	});

	private isMobileMode = inject(UiStore).window.isMobileMode;
	isInformativeBannerVisible = computed(() => {
		const currentPreset = this.preset();

		return (
			!!currentPreset?.id &&
			this.navigationService.bannerPresets.includes(currentPreset?.id) &&
			!this.textQuery().length &&
			!this.hasSelectedFilters() &&
			!this.searchStore.searchQuery.orderByExpression() &&
			!this.isMobileMode()
		);
	});
	isQueryRelaxed = this.searchStore.searchResults.isQueryRelaxed;

	constructor(private readonly navigationService: NavigationService) {
		effect(() => {
			if (this.isLoading() && this.items().length > 0) {
				this.scrollToTop();
			}
		});
	}

	ngOnDestroy(): void {
		window.removeEventListener('scroll', this.loadOnScroll);
	}

	ngOnInit(): void {
		window.addEventListener('scroll', this.loadOnScroll);
	}

	private loadOnScroll = () => {
		const threshold = 0.95;
		const target = document.documentElement;
		const scrollTop = window.scrollY;

		if ((scrollTop + target.clientHeight) / target.scrollHeight > threshold) {
			this.searchStore.loadMoreSearchResults$();
		}
	};

	private scrollToTop = () => {
		window.scrollTo({top: 0});
	};
}
