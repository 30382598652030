import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SkeletonItemComponent} from '../skeleton-item/skeleton.component';

@Component({
	selector: 'pp-skeleton-card',
	standalone: true,
	imports: [SkeletonItemComponent],
	templateUrl: './skeleton-card.component.html',
	styleUrls: ['./skeleton-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonCardComponent {}
