import {ChangeDetectionStrategy, Component, computed, forwardRef, inject} from '@angular/core';
import {SearchItemCardComponent} from '@preview/item-card';
import {DocumentTaxonomyService} from '@search2/services';
import {SearchStore} from '@search2/state';

@Component({
	selector: 'search-results-grouped',
	templateUrl: './search-results-grouped.component.html',
	styleUrls: ['./search-results-grouped.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [forwardRef(() => SearchItemCardComponent)],
})
export class SearchResultsGroupedComponent {
	private readonly searchStore = inject(SearchStore);
	private readonly taxonomyService = inject(DocumentTaxonomyService);

	private groupByFieldName = computed(() => {
		const currentPreset = this.searchStore.currentPreset();

		return currentPreset?.groupByFieldName ?? '';
	});

	private searchQuery = this.searchStore.searchQuery;
	private selectedFilters = computed(
		() => this.searchQuery().selectedFilters[this.groupByFieldName()],
	);

	private facetTree = computed(() => {
		const facets = this.searchStore.facets();
		const currentFacets = facets?.[this.groupByFieldName()] ?? [];

		return this.taxonomyService.parseTree(currentFacets);
	});

	isLoaded = computed(() => this.searchStore.searchStatus() === 'loaded');

	private resultItems = computed(() => {
		if (!this.isLoaded()) {
			return [];
		}

		return this.searchStore.searchResults.items();
	});

	tree = computed(() =>
		this.taxonomyService.processFacetTree(
			this.facetTree(),
			this.resultItems(),
			this.groupByFieldName(),
			this.selectedFilters(),
		),
	);
}
