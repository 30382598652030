export const resultsInitialState = {
	data: [],
	count: 0,
	countWithDuplicates: 0,
	pageLoading: false,
	spellingSuggestion: '',
	pageImpression: '',
	sourceId: '',
	queryModification: '',
};
