<div class="manage-access-container">
	<div class="manage-access-dialog" cdkDrag cdkDragBoundary=".manage-access-container">
		<div class="manage-access-dialog-content">
			<header class="manage-access-header" cdkDragHandle>
				View Access
				<pp-icon (click)="closeDialog()" name="navigation-close-36" />
			</header>
			<main class="manage-access-content">
				@if (permissions$ | async; as permissions) {
					@for (group of permissions.write; track group.aadId) {
						<div class="manage-access-item-container">
							<search-preview-user-info [userInfo]="group"></search-preview-user-info>

							<pp-icon
								name="content-edit-18"
								[hint]="'Can edit'"
								[hintPosition]="'BOTTOM'"
								[size]="18"
							></pp-icon>
						</div>
					}

					@for (group of permissions.read; track group.aadId) {
						<div class="manage-access-item-container">
							<search-preview-user-info [userInfo]="group"></search-preview-user-info>

							<pp-icon
								name="action-eye-18"
								[hint]="'Can see'"
								[hintPosition]="'BOTTOM'"
								[size]="18"
							></pp-icon>
						</div>
					}
				} @else {
					<pp-spinner></pp-spinner>
				}
			</main>
		</div>
	</div>
</div>
