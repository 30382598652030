import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {NgClass} from '@angular/common';
import {SearchFilterHeaderComponent} from '../filter-header/search-filter-header.component';
import {RangeSliderComponent} from '@shared/components/range-slider/range-slider.component';
import {RangeModel} from '@shared/models';
import {FacetConfig} from '@shared/api';
import {SearchStore} from '@search2/state';

@Component({
	selector: 'search-histogram',
	templateUrl: './histogram.component.html',
	styleUrls: ['./histogram.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SearchFilterHeaderComponent, NgClass, RangeSliderComponent],
})
export class HistogramComponent {
	config = input.required<FacetConfig>();

	isExpanded = true;

	private readonly searchStore = inject(SearchStore);

	fieldName = computed(() => this.config().fieldName);
	private facetState = computed(() => this.searchStore.facets()[this.fieldName()] ?? []);
	private selectedFilters = computed(
		() => this.searchStore.searchQuery.selectedFilters()[this.fieldName()] ?? [],
	);

	private parseRange(value: string): [string, string] {
		const range = value.split(' TO ');
		range[0] = range[0].slice(1);
		range[1] = range[1].slice(0, range[1].length - 1);

		return range as [string, string];
	}

	bars = computed(() => {
		return this.facetState().map((item) => ({
			token: item.value,
			name: item.text,
			count: item.count,
			labels: this.parseRange(item.value),
		}));
	});
	barsTotalCount = computed(() => {
		return this.bars().reduce((sum, item) => sum + (item.count ?? 0), 0);
	});

	selectedRange = computed(() => {
		const selectedFilterItems = this.selectedFilters();
		const bars = this.bars();

		let selectedRange: RangeModel = {from: 0, to: bars.length, count: this.barsTotalCount()};

		if (selectedFilterItems.length === 1) {
			const [from, to] = this.parseRange(selectedFilterItems[0]);
			const fromIndex = bars.findIndex((item) =>
				item.labels ? item.labels[0] === from : false,
			);
			const toIndex = bars.findIndex((item) => (item.labels ? item.labels[1] === to : false));

			selectedRange = {
				from: fromIndex,
				to: toIndex + 1,
				count: selectedFilterItems.length,
			};
		}

		return selectedRange;
	});

	showReset = computed(() => {
		return this.selectedFilters().length > 0;
	});

	isVisible = computed(() => {
		const searchRegex = this.searchStore.facetSearchRegex();

		return !(this.barsTotalCount() === 0 || searchRegex);
	});

	onRangeChange(range: RangeModel): void {
		const bars = this.bars();
		const from = bars[range.from].labels ?? ['', ''];
		const to = bars[range.to - 1].labels ?? ['', ''];
		const fieldValue = `[${from[0]} TO ${to[1]}]`;

		this.searchStore.selectFilter(this.fieldName(), fieldValue, true);
	}

	toggleCollapsing(): void {
		this.isExpanded = !this.isExpanded;
	}

	resetFilter(): void {
		this.searchStore.resetFilters(this.fieldName());
	}
}
