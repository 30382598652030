import {ChangeDetectionStrategy, Component, computed, inject, input, signal} from '@angular/core';
import {EditContactsModalComponent} from './edit-contacts-modal/edit-contacts-modal.component';
import {PreviewStore} from '@preview/document/preview.store';
import {ButtonComponent} from '@pp/button';
import {PreviewUserInfoComponent} from '@shared/access';
import {SvgIconComponent} from '@pp/svg';
import {MoreLessPipe} from '@shared/pipes';
import {DocContactType} from '@shared/api';
import {ModalService} from '@shared/modals';

@Component({
	selector: 'search-preview-contacts',
	templateUrl: './preview-contacts.component.html',
	styleUrls: ['./preview-contacts.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonComponent, PreviewUserInfoComponent, SvgIconComponent, MoreLessPipe],
})
export class PreviewContactsComponent {
	title = input.required<string>();
	contactType = input.required<DocContactType>();

	isShowMore = signal(true);

	private previewStore = inject(PreviewStore);
	private modalService = inject(ModalService);

	private contacts = computed(() => {
		const contacts = this.previewStore.contacts();
		const fieldName = this.contactType() === 'owners' ? 'owners' : 'additionalContacts';

		return contacts[fieldName] ?? [];
	});

	canManage = computed(() => this.contacts().canManage);
	items = computed(() => this.contacts().items);
	itemsCount = computed(() => this.items().length);

	showEditDialog(): void {
		this.modalService.openModal(EditContactsModalComponent, {
			type: 'EDIT-CONTACTS',
			showCloseButton: false,
			data: {
				title: this.title(),
				contactType: this.contactType(),
			},
		});
	}

	toggleShowMore(): void {
		this.isShowMore.update((x) => !x);
	}
}
