<div class="shared-modal-video">
	<div class="shared-modal-video-content" [ngStyle]="styles()" [ngClass]="class()">
		<shared-preview
			class="shared-modal-video-preview"
			[document]="fakeDoc"
			[videoPreview]="true"
			[inline]="true"
			[calculateOnResize]="false"
		></shared-preview>
	</div>
</div>
