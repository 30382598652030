<div class="dp-card">
	<header class="dp-card-header">
		Linked Documents

		<button
			btn
			btnType="link"
			withIconBefore="action-settings-18"
			title="Manage Linked Documents"
			(click)="showEditDialog()"
		>
			Manage
		</button>
	</header>
	<main class="dp-card-content">
		@if (itemsCount() === 0) {
			<div class="no-results">Nothing here yet</div>
		} @else {
			<ul class="linked-documents-container">
				@for (document of items() | moreLess: !isShowMore() : 3; track document.id) {
					<li>
						<search-item-card
							[item]="document.document"
							[size]="'small'"
							[showActions]="false"
							[direction]="'horizontal'"
							[lightMode]="true"
						></search-item-card>
					</li>
				}
			</ul>
			@if (itemsCount() > 3) {
				<button
					(click)="toggleShowMore()"
					class="dp-card-header-more-link cloud-links-widget-show-button"
				>
					Show {{ isShowMore() ? 'all' : 'less' }}
					{{ isShowMore() ? '(' + itemsCount() + ')' : '' }}

					<pp-icon
						[name]="
							isShowMore() ? 'navigation-chevron-down-18' : 'navigation-chevron-up-18'
						"
						[size]="18"
					></pp-icon>
				</button>
			}
		}
	</main>
</div>
