export class ResizeObserverWrapper extends ResizeObserver {
	constructor(callback: ResizeObserverCallback) {
		super((entries, observer) => {
			// solution taken from here
			// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
			window.requestAnimationFrame(() => {
				callback(entries, observer);
			});
		});
	}
}
