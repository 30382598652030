import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';
import {SearchFeatureState} from '@search/reducers/search-state';
import {StateSerializerService} from '@search/services';
import {getFirst} from '@shared/utils';

@Pipe({
	name: 'duplicatesQueryString',
	standalone: true,
})
export class DuplicatesQueryStringPipe implements PipeTransform {
	constructor(
		private store$: Store<SearchFeatureState>,
		private stateSerializer: StateSerializerService,
	) {}

	transform(groupId: string): {o: string} {
		const query = getFirst(this.store$.select((state) => state.search.query));
		const selectedFilters = getFirst(
			this.store$.select((state) => state.search.selectedFilters),
		);

		const result = this.stateSerializer.serialize({
			query: {
				...query,
				groupId,
			},
			selectedFilters,
		});

		return {o: result};
	}
}
