import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {SelectionStore} from '../selection.store';

@Component({
	selector: 'search-selection-panel-gap',
	templateUrl: './selection-panel-gap.component.html',
	styleUrls: ['./selection-panel-gap.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class SelectionPanelGapComponent {
	private selectionStore = inject(SelectionStore);
	isGapVisible = computed(() => this.selectionStore.documentIds().length > 0);
}
