import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';

import {AsyncPipe} from '@angular/common';
import {ChangeFilterSearchAction, ChangeFilterSearchRegexAction} from '../../actions';
import {SearchFeatureState} from '../../reducers/search-state';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'search-filters-search-bar',
	templateUrl: './search-filters-search-bar.component.html',
	styleUrls: ['./search-filters-search-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SvgIconComponent, AsyncPipe],
})
export class SearchFiltersSearchBarComponent implements OnInit, OnDestroy {
	private readonly debounceTime = 300;
	private filterSearchStream$ = new Subject<string>();
	private subscription: Subscription = new Subscription();

	showClearButton$ = new BehaviorSubject(false);

	filterSearch$: Observable<string> = this.store$
		.select((state) => state.search.ui.filterSearch)
		.pipe(tap((value) => this.toggleCLearButton(value)));

	constructor(private store$: Store<SearchFeatureState>) {}

	ngOnInit(): void {
		this.subscription.add(
			this.filterSearchStream$
				.pipe(
					distinctUntilChanged(),
					debounceTime(this.debounceTime),
					tap((value) => {
						this.store$.dispatch(new ChangeFilterSearchRegexAction(value));
					}),
				)
				.subscribe(),
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	applyFilterSearch(event: Event): void {
		const value = (event.target as HTMLInputElement).value;

		this.filterSearchStream$.next(value);
		this.toggleCLearButton(value);
	}

	clear(): void {
		this.store$.dispatch(new ChangeFilterSearchAction(''));
	}

	blur(event: Event): void {
		this.store$.dispatch(
			new ChangeFilterSearchAction((event.target as HTMLInputElement).value),
		);
	}

	toggleCLearButton(value: string): void {
		this.showClearButton$.next(!!value.length);
	}
}
