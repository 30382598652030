import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ImpersonateService {
	private readonly key = 'uspp.impersonate-user';

	get(): string | null {
		return localStorage.getItem(this.key);
	}

	set(email: string): void {
		localStorage.setItem(this.key, email);
	}

	clear(): void {
		localStorage.removeItem(this.key);
	}
}
