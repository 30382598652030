@if (isRoot === false) {
	<label
		class="multi-tree-header"
		(click)="selectNode(treeNode)"
		[class.selected]="isSelected(treeNode)"
		[ngClass]="{
			'has-children': isHaveChildren || isFlat,
			'no-children': !isHaveChildren && !isFlat
		}"
	>
		@if (isHaveChildren) {
			<pp-icon
				class="multi-tree-header-chevron"
				name="navigation-chevron-right-18"
				(click)="toggleExpand($event)"
			></pp-icon>
		}
		<ng-template
			[ngTemplateOutlet]="itemView.template"
			[ngTemplateOutletContext]="context(treeNode.payload)"
		></ng-template>
	</label>
}
@if (viewChild) {
	@for (
		child of treeNode.children | moreLess: !isShowMore : maxToShow;
		track trackNode($index, child)
	) {
		<shared-multi-tree-view
			[tree]="child"
			[expanded]="child.expandedInitialState"
			[level]="nextLevel"
			class="multi-tree-element"
			[selectedIds]="selectedIds"
			(selectElement)="selectNode($event)"
			[hasCheckbox]="hasCheckbox"
			[isFlat]="isFlat"
		>
			<ng-template
				*treeViewItem="let node"
				[ngTemplateOutlet]="itemView.template"
				[ngTemplateOutletContext]="context(node)"
			></ng-template>
		</shared-multi-tree-view>
	}
}
