<div class="download-options-container">
	<div class="download-options-content">
		<header class="download-options-header">Include in the downloaded file:</header>
		<div class="download-options-checkbox-container">
			<pp-checkbox
				[checked]="isGeneralSlidesChecked"
				class="filter-item-checkbox"
				[text]="'General slides'"
				(clickCheckbox)="toggleCheckbox($event, -1)"
			></pp-checkbox>
			@for (
				collection of collectionSelection;
				track collection.collectionName;
				let i = $index
			) {
				<pp-checkbox
					[checked]="collection.checked"
					class="filter-item-checkbox"
					[text]="collection.collectionName + ' case studies'"
					(clickCheckbox)="toggleCheckbox($event, i)"
				></pp-checkbox>
			}
		</div>
	</div>
	<div class="download-options-buttons">
		@if (!isSomeCollectionChecked) {
			<button
				class="dp-button"
				[disabled]="!isGeneralSlidesChecked"
				(click)="download($event)"
				type="button"
			>
				Download
			</button>
		} @else {
			<button class="dp-button" (click)="buildDeck($event)" type="button">Build Deck</button>
		}
		<button
			class="dp-button secondary confirmation-cancel"
			(click)="closePopOver()"
			type="button"
		>
			Cancel
		</button>
	</div>
</div>
