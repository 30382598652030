import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgClass} from '@angular/common';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'shared-json-preview',
	templateUrl: './json-preview.component.html',
	styleUrls: ['./json-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SvgIconComponent, NgClass],
})
export class JsonPreviewComponent implements OnInit {
	isArray!: boolean;
	data: {referenceType: boolean; key: string; value: string; emptyValue: boolean}[] = [];
	expanded = true;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() object: any;
	@Input() last = true;

	ngOnInit(): void {
		this.isArray = Array.isArray(this.object);
		this.object = {...this.object};

		this.data = Object.keys(this.object).map((property) => {
			const value = this.object[property as keyof typeof this.object];

			return {
				referenceType: this.isReferenceType(value),
				key: property,
				value: this.isReferenceType(value) ? value : JSON.stringify(value) || 'undefined',
				emptyValue: value === null || value === undefined,
			};
		});
	}

	toggle(): void {
		this.expanded = !this.expanded;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private isReferenceType(value: any): boolean {
		return (
			value &&
			typeof value === 'object' &&
			!Date.prototype.isPrototypeOf(value) &&
			(value.length !== 0 || Object.keys(value).length !== 0)
		);
	}
}
