import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import {Observable} from 'rxjs';
import {SearchFeatureState, SelectedFilters} from '../../reducers/search-state';
import {
	PostQueryService,
	PostQueryResponseWithRequestedRefiners,
	rangeFiltersService,
	RefinerModel,
	RequestData,
	RequestOptions,
} from '@shared/services';
import {getFirst} from '@shared/utils';

@Injectable({providedIn: 'root'})
export class SearchService {
	histogramLabels: Map<string, string> = new Map<string, string>();
	private withoutFilters: string[] = [];

	constructor(
		private store$: Store<SearchFeatureState>,
		private postQueryService: PostQueryService,
	) {}

	getSearchResults$({
		rowLimit,
		excludeRefiners,
	}: {
		rowLimit?: number;
		excludeRefiners?: string[];
	} = {}): Observable<PostQueryResponseWithRequestedRefiners> {
		return this.getData$({
			refinementsType: 'regular',
			userQuery: true,
			rowLimit,
			excludeRefiners,
		});
	}

	getNextPage$(
		rowLimit?: number,
		startFrom?: number,
	): Observable<PostQueryResponseWithRequestedRefiners> {
		return this.getData$({
			skipFilterLoadIndicator: true,
			rowLimit,
			startFrom: startFrom || this.getResultsCount(),
		});
	}

	getRefinements$(
		refinementsType: 'any' | 'lazy' | 'regular',
		requestRefinements?: Set<string>,
		customRefiners?: RefinerModel[],
	): Observable<PostQueryResponseWithRequestedRefiners> {
		return this.getData$(
			{refinementsOnly: true, refinementsType, customRefiners},
			requestRefinements,
		);
	}

	/**
	 * Exclude filter values from the store.
	 *
	 * @param filters
	 */
	withoutFiltersStore(filters: string[]): this {
		this.withoutFilters = filters;

		return this;
	}

	private extractDataFromStore(): RequestData {
		const query =
			getFirst(this.store$.select((store) => store.search.query.submittedQuery)) ?? '';
		const filters = getFirst(this.store$.select((store) => store.search.selectedFilters));
		const sortBy = getFirst(this.store$.select((store) => store.search.query.sortBy));
		const groupId = getFirst(this.store$.select((store) => store.search.query.groupId));
		const expandDuplicates = getFirst(
			this.store$.select((store) => store.search.query.expandDuplicates),
		);
		const disableQueryTemplate = getFirst(
			this.store$.select((store) => store.search.query.disableQueryTemplate),
		);

		const include = Object.keys(filters.include)
			.filter((filterName) => !this.withoutFilters.includes(filterName))
			.reduce<SelectedFilters>(
				(results, filterName) => ({
					[filterName]: filters.include[filterName as keyof typeof filters.include],
					...results,
				}),
				Object.create({}),
			);

		return {
			query,
			groupId,
			sortBy: sortBy.value,
			sortDirection: sortBy.direction,
			includeFilters: this.updateRangeFilters(include),
			excludeFilters: filters.exclude,
			expandDuplicates,
			disableQueryTemplate,
		};
	}

	private updateRangeFilters(filters: SelectedFilters): SelectedFilters {
		const result = {...filters};

		if (filters.size.length) {
			const [token] = filters.size;
			const [from, to] = token.split(',');

			const tokens = ['min', ...rangeFiltersService.getSizes(), 'max'];

			result.size = [`range(${tokens[+from]}, ${tokens[+to]})`];
		}

		if (filters.modifiedDate?.length) {
			const [token] = filters.modifiedDate;
			const [from, to] = token.split(',');

			const tokens: string[] = ['min', ...rangeFiltersService.getDates(), 'max'];

			result.modifiedDate = [`range(${tokens[+from]}, ${tokens[+to]})`];
		}

		return result;
	}

	private getResultsCount(): number {
		return getFirst(this.store$.select((store) => store.search.results.data.length));
	}

	private getData$(
		options: RequestOptions,
		requestRefinements?: Set<string>,
	): Observable<PostQueryResponseWithRequestedRefiners> {
		const data = this.extractDataFromStore();
		this.withoutFilters = [];

		return this.postQueryService.retrieve$(data, options, requestRefinements);
	}
}
