<div ppCdkUseTheme="light" class="search-layout">
	@if (resultsLoading$ | async) {
		<shared-page-progress-bar></shared-page-progress-bar>
	}

	<search-action-panel class="search-action-panel"></search-action-panel>

	@if ((firstLoad$ | async) === false) {
		<search-filters-layout class="search-filters"></search-filters-layout>
		<search-results class="search-content"></search-results>
	} @else {
		<!-- <search-skeleton class="search-filters" section="filters"></search-skeleton>
		<search-skeleton class="search-content" section="content"></search-skeleton> -->
		<pp-skeleton class="search-filters" type="aside"></pp-skeleton>
		<pp-skeleton class="search-content" type="horizontal-card"></pp-skeleton>
	}

	<search-query-effects></search-query-effects>
</div>
