import {
	ChangeDetectionStrategy,
	Component,
	Output,
	EventEmitter,
	ElementRef,
	OnInit,
	AfterViewInit,
	inject,
	computed,
	input,
} from '@angular/core';
import {debounceTime, fromEvent, map, merge, Observable, startWith} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {PreviewContentComponent} from './preview-content/preview-content.component';
import {CheckboxComponent} from '@pp/checkbox';
import {UsagePermissionsComponent} from '@shared/access';
import {FlagListComponent} from '@preview/item-card';
import {HintDirective} from '@pp/hint';
import {AnchorDirective} from '@pp/anchor';
import {SvgIconComponent} from '@pp/svg';
import {DocDetailsItem, DocumentItemParserService, SearchItemType} from '@shared/api';
import {PreviewService} from '@preview/services';
import {AnalyticsService} from '@shared/analytics';
import {SelectionStore} from '@shared/selection';

const EXPAND_PREVIEW = 'uspp.expand-preview';

@Component({
	selector: 'search-preview-document',
	templateUrl: './preview-document.component.html',
	styleUrls: ['./preview-document.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CheckboxComponent,
		UsagePermissionsComponent,
		FlagListComponent,
		HintDirective,
		AnchorDirective,
		SvgIconComponent,
		PreviewContentComponent,
		AsyncPipe,
	],
})
export class PreviewDocumentComponent implements OnInit, AfterViewInit {
	docDetails = input.required<DocDetailsItem>();
	@Output() toggleMode = new EventEmitter<boolean>();
	@Output() heightEmitter = new EventEmitter<number>();

	document = computed(() => this.docDetails().document);

	height$!: Observable<string>;
	canShowSlides = false;
	expandModeEnabled = false;

	private readonly heights = {
		firstHeader: 60,
		secondHeader: 51,
		margin: 18,
		actions: 36,
		marginCloseButton: 40,
		closeButton: 36,
	};

	private selectionStore = inject(SelectionStore);
	isCheckboxVisible = computed(() => this.selectionStore.isSelectable(this.document()));
	isCheckboxSelected = computed(() =>
		this.selectionStore.documentIds().includes(this.document().id),
	);
	isCheckboxDisabled = computed(() => this.selectionStore.isLocked());

	constructor(
		private readonly eRef: ElementRef,
		private readonly previewService: PreviewService,
		private readonly analytics: AnalyticsService,
		private readonly parserService: DocumentItemParserService,
	) {}

	ngOnInit(): void {
		const slidesCount = this.document().file?.slidesCount ?? 0;
		const srcSlidesCount = this.docDetails().sourceDocument?.file?.slidesCount ?? 0;
		this.canShowSlides = slidesCount > 1 || (slidesCount === 1 && srcSlidesCount > 1);

		this.height$ = merge(fromEvent(window, 'resize'), this.toggleMode.asObservable()).pipe(
			debounceTime(100),
			startWith(false),
			map(() => {
				const blocksHeight =
					this.heights.marginCloseButton * 2 + this.heights.actions + this.heights.margin;
				const availableHeight = window.innerHeight - blocksHeight;
				const maxHeight = (this.eRef.nativeElement.clientWidth / 16) * 9;
				const height = Math.max(200, Math.min(maxHeight, availableHeight));

				this.heightEmitter.emit(height);

				return `${height}px`;
			}),
		);
	}

	ngAfterViewInit() {
		this.expandModeEnabled = localStorage.getItem(EXPAND_PREVIEW) === 'true';
		this.toggleMode.emit(this.expandModeEnabled);
	}

	get isVerified(): boolean {
		return this.parserService.isVerified(this.document());
	}

	showSlidesModal(event: MouseEvent) {
		event.preventDefault();

		this.previewService.openPreviewSlidesDialog(this.docDetails());
	}

	togglePreviewMode(): void {
		this.analytics.trackDocumentPreviewAction('toggle:expand-mode');

		this.expandModeEnabled = !this.expandModeEnabled;
		localStorage.setItem(EXPAND_PREVIEW, String(this.expandModeEnabled));
		this.toggleMode.emit(this.expandModeEnabled);
	}

	toggleSelectionState(isSelected: boolean, event: Event): void {
		event.preventDefault();

		const description = `one document: ${this.getDocumentType()}`;
		if (isSelected) {
			this.selectionStore.unselectItems([this.document()], description);
		} else {
			this.selectionStore.selectItems([this.document()], description);
		}
	}

	getDocumentType(): SearchItemType {
		return this.parserService.getItemType(this.document());
	}
}
