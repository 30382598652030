import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'pp-verified',
	standalone: true,
	imports: [SvgIconComponent],
	templateUrl: './verified.component.html',
	styleUrls: ['./verified.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifiedComponent {
	iconSize = input<number>(12);
}
