import {ChangeDetectionStrategy, Component, Input, forwardRef} from '@angular/core';
import {CardsSwiperComponent} from '@shared/card-containers/cards-swiper/cards-swiper.component';
import {SvgIconComponent} from '@pp/svg';
import {PresetLinkComponent} from '@search2/components';
import {DocumentsFragment} from '@search2/models';
import {SearchItemCardComponent} from '@preview/item-card';

@Component({
	selector: 'search-preview-cards-widget',
	templateUrl: './preview-cards-widget.component.html',
	styleUrls: ['./preview-cards-widget.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CardsSwiperComponent,
		PresetLinkComponent,
		SvgIconComponent,
		forwardRef(() => SearchItemCardComponent),
	],
})
export class PreviewCardsWidgetComponent {
	@Input({required: true}) title!: string;
	@Input({required: true}) collection!: DocumentsFragment;
}
