import {inject} from '@angular/core';
import {patchState, signalStore, withMethods, withState} from '@ngrx/signals';
import {AnalyticsService} from '@shared/analytics';
import {DocumentIndexItem} from '@shared/api';

export interface SelectionState {
	isLocked: boolean;
	documentIds: string[];
	documentIdsSnapshot: string[];
}

export const selectionInitialState: SelectionState = {
	isLocked: false,
	documentIds: [],
	documentIdsSnapshot: [],
};

export const SelectionStore = signalStore(
	{providedIn: 'root'},
	withState(selectionInitialState),
	withMethods((store) => {
		const analytics = inject(AnalyticsService);

		return {
			selectItems(ids: string[], description: string): void {
				analytics.trackSelectionAction('select', description);

				patchState(store, (state) => {
					const selectedIds = state.documentIds;
					const newDocIds = ids.filter((id) => !selectedIds.includes(id));

					return {
						documentIds: [...selectedIds, ...newDocIds],
					};
				});
			},
			unselectItems(ids: string[], description: string): void {
				analytics.trackSelectionAction('unselect', description);

				patchState(store, (state) => {
					const documentIds = state.documentIds.filter((id) => !ids.includes(id));

					return {
						documentIds,
					};
				});
			},
			changeOrder(ids: string[]): void {
				analytics.trackSelectionAction('changeOrder');

				patchState(store, (state) => {
					const unchanged = state.documentIds.filter((id) => !ids.includes(id));

					return {
						documentIds: [...unchanged, ...ids],
					};
				});
			},
			restoreState(state: SelectionState): void {
				analytics.trackSelectionAction('state:restore');

				patchState(store, () => ({
					isLocked: state.isLocked,
					documentIds: [...state.documentIds],
					documentIdsSnapshot: [...state.documentIdsSnapshot],
				}));
			},
			resetState(reason: string): void {
				analytics.trackSelectionAction('state:reset', reason);

				patchState(store, () => ({
					...selectionInitialState,
					documentIds: store.documentIdsSnapshot(),
					documentIdsSnapshot: [],
				}));
			},
			lockState(isLocked: boolean): void {
				analytics.trackSelectionAction('state:lock', isLocked ? 'locked' : 'unlocked');

				patchState(store, () => ({
					isLocked,
				}));
			},
			replaceSelection(documentIds: string[]): void {
				patchState(store, (state) => ({
					documentIdsSnapshot: [...state.documentIds],
					documentIds: [...documentIds],
				}));
			},
		};
	}),
	withMethods((store) => {
		const methods = {
			isSelectable(item: DocumentIndexItem): boolean {
				return (item.file?.slidesCount ?? 0) > 0;
			},
			selectItems(items: DocumentIndexItem[], description: string): void {
				const ids = items.filter((x) => methods.isSelectable(x)).map((x) => x.id);

				if (ids.length > 0) {
					store.selectItems(ids, description);
				}
			},
			unselectItems(items: DocumentIndexItem[], description: string): void {
				const ids = items.map((x) => x.id);

				if (ids.length > 0) {
					store.unselectItems(ids, description);
				}
			},
		};

		return methods;
	}),
);
