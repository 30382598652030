export const tagsOrder = [
	'Case study',
	'Slide',
	'Q&A',
	'Verified',
	'Final',
	'Auto-generated',
	'Output',
	'CV',
];
