import {ChangeDetectionStrategy, Component, OnInit, OnDestroy, inject} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, debounceTime, tap} from 'rxjs/operators';
import {SvgIconComponent} from '@pp/svg';
import {SearchStore} from '@search2/state';

@Component({
	selector: 'search-filter-bar',
	templateUrl: './search-filter-bar.component.html',
	styleUrls: ['./search-filter-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent],
})
export class SearchFilterBarComponent implements OnInit, OnDestroy {
	private readonly debounceTime = 300;
	private subscription: Subscription = new Subscription();
	private searchStore = inject(SearchStore);

	private filterSearchStream$ = new Subject<string>();
	searchValue = '';

	ngOnInit() {
		this.subscription.add(
			this.filterSearchStream$
				.pipe(
					distinctUntilChanged(),
					debounceTime(this.debounceTime),
					tap((value) => this.searchStore.updateFacetSearchQuery(value)),
				)
				.subscribe(),
		);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	applyFilterSearch(event: Event): void {
		const value = (event.target as HTMLTextAreaElement).value;

		this.searchValue = value;
		this.filterSearchStream$.next(value);
	}

	clear(): void {
		this.searchStore.updateFacetSearchQuery('');
		this.searchValue = '';
	}
}
