import {ChangeDetectionStrategy, Component, effect, inject, Inject} from '@angular/core';
import {NgClass} from '@angular/common';
import {RouterLink} from '@angular/router';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {DeckBuilderSectionComponent} from '../deck-builder-section/deck-builder-section.component';
import {DeckBuilderStore} from '../../state';
import {DeckBuilderCoverSlideComponent} from '../deck-builder-cover/deck-builder-cover.component';
import {HintDirective} from '@pp/hint';
import {SelectionStore} from '@shared/selection';
import {ToastService} from '@shared/services';
import {CURRENT_MODAL, ModalRef} from '@shared/modals';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';
import {SharedSkeletonComponent} from '@pp/skeleton';
import {ButtonComponent} from '@pp/button';
import {SpinnerComponent} from '@pp/spinner';
import {AlertComponent} from '@pp/alert';
import {ModalWindowComponent} from '@shared/components/modal-window/modal-window.component';

@Component({
	selector: 'deck-builder-modal',
	templateUrl: './deck-builder-modal.component.html',
	styleUrls: ['./deck-builder-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () =>
				new ContextualAnalyticsService(inject(AnalyticsService), 'Deck Builder'),
		},
	],
	standalone: true,
	imports: [
		ModalWindowComponent,
		CdkScrollable,
		DeckBuilderSectionComponent,
		DeckBuilderCoverSlideComponent,
		RouterLink,
		SpinnerComponent,
		SharedSkeletonComponent,
		HintDirective,
		NgClass,
		ButtonComponent,
		AlertComponent,
	],
})
export class DeckBuilderModalComponent {
	private deckBuilderStore = inject(DeckBuilderStore);

	decks = this.deckBuilderStore.decks;
	totalSlideCount = this.deckBuilderStore.totalSlideCount;
	unsupportedItems = this.deckBuilderStore.unsupportedItems;
	loaded = this.deckBuilderStore.loaded;

	private selectionStore = inject(SelectionStore);

	constructor(
		@Inject(CURRENT_MODAL) private readonly modalRef: ModalRef,
		private readonly toastService: ToastService,
		private readonly analytics: AnalyticsService,
	) {
		effect(
			() => {
				const documentIds = this.selectionStore.documentIds();
				this.deckBuilderStore.loadItems(documentIds);
			},
			{allowSignalWrites: true},
		);
	}

	buildAndClear(): void {
		const errorMessage = this.getErrorMessage();
		if (errorMessage) {
			this.toastService.show({type: 'alert', message: errorMessage});
		} else {
			this.deckBuilderStore.buildDeck();
			this.modalRef.close();
		}
	}

	private getErrorMessage(): string {
		if (this.deckBuilderStore.coverFormError()) {
			return this.deckBuilderStore.coverFormError();
		}
		if (!this.deckBuilderStore.cover.title()) {
			return 'Please enter a Title for the presentation';
		}
		if (this.totalSlideCount() > 200) {
			this.analytics.trackDeckBuilderAction(
				'error',
				'total-slides-exceeded',
				this.totalSlideCount(),
			);

			return 'Please reduce total number of slides to 200';
		}

		return '';
	}
}
