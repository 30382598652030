import {ChangeDetectionStrategy, Component, ViewEncapsulation, inject} from '@angular/core';
import {FiltersSettingsComponent} from '../filters-settings/filters-settings.component';
import {UserSettingsComponent} from '../user-settings/user-settings.component';
import {UIService} from '@search/services';
import {UserStore} from '@shared/state';

@Component({
	selector: 'search-user-panel',
	templateUrl: './user-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UserSettingsComponent, FiltersSettingsComponent],
})
export class UserPanelComponent {
	userProfile = inject(UserStore).profile;
	showFiltersSettings = this.uiService.showFiltersSettings;

	constructor(private uiService: UIService) {}
}
