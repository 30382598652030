import {ChangeDetectionStrategy, Component, inject, ViewEncapsulation} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {HintDirective} from '@pp/hint';
import {SvgIconComponent} from '@pp/svg';
import {UserStore} from '@shared/state';

@Component({
	selector: 'core-theme-toggle',
	standalone: true,
	imports: [AsyncPipe, SvgIconComponent, HintDirective],
	templateUrl: './theme-toggle.component.html',
	styleUrls: ['./theme-toggle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ThemeToggleComponent {
	private userStore = inject(UserStore);

	theme = this.userStore.settings.uiTheme;

	toggleTheme(): void {
		this.userStore.toggleUiTheme();
	}
}
