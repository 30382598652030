import {ChangeDetectionStrategy, Component, Inject, Input, inject} from '@angular/core';
import {ModalRef} from '@shared/modals';
import {POPOVER_REF} from '@shared/popover';
import {DownloadService} from '@shared/services';

@Component({
	selector: 'search-unsafe-link-confirmation',
	templateUrl: './unsafe-link-confirmation.component.html',
	styleUrls: ['./unsafe-link-confirmation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class UnsafeLinkConfirmationComponent {
	@Input({required: true}) url!: string;

	constructor(@Inject(POPOVER_REF) private readonly popOverRef: ModalRef) {}
	private downloadService = inject(DownloadService);

	closePopOver(): void {
		this.popOverRef.close();
	}

	openLink(): void {
		this.downloadService.openPageInNewTab(this.url);
		this.closePopOver();
	}
}
