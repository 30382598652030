import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

import {AsyncPipe} from '@angular/common';
import {CrawlService, CrawlStateResponse} from '@search/services';
import {SpinnerComponent} from '@pp/spinner';
import {JsonPreviewComponent} from '@shared/components/json-preview/json-preview.component';

@Component({
	selector: 'search-crawl-state',
	templateUrl: './crawl-state.component.html',
	standalone: true,
	imports: [JsonPreviewComponent, SpinnerComponent, AsyncPipe],
})
export class CrawlStateComponent implements OnInit {
	crawlState$!: Observable<CrawlStateResponse>;

	@Input({required: true}) filePath!: string;

	constructor(private crawl: CrawlService) {}

	ngOnInit(): void {
		this.crawlState$ = this.crawl.getCrawlState$(this.filePath);
	}
}
