@if (filterInfoVm$ | async; as filterInfoVm) {
	<div class="filter-info-container" #filterInfoContainer>
		<div class="results-count">
			<button
				class="mobile-filters-button"
				btn
				btnType="secondary"
				[onlyIcon]="true"
				[class.attention]="filterInfoVm.appliedFiltersGroups.length"
				[withIconBefore]="'content-filtration-18'"
				(click)="toggleFiltersPanel()"
			></button>

			<search-results-count></search-results-count>
		</div>
		<search-context-sp
			filterSectionFiltersSelector
			class="filters-list"
			[appliedFiltersGroups]="filterInfoVm.appliedFiltersGroups"
		></search-context-sp>

		<div class="settings-buttons">
			@if (filterInfoVm.view) {
				<button
					title="Switch to {{ filterInfoVm.view === 'list' ? 'Grid View' : 'List View' }}"
					(click)="changeView(filterInfoVm.view)"
					btn
					btnType="secondary"
					[withIconBefore]="
						filterInfoVm.view === 'list' ? 'navigation-grid-18' : 'navigation-menu-18'
					"
					[onlyIcon]="true"
				></button>
			}
			<div class="settings-sort-by">
				<search-sort-by
					[list]="sortByList"
					[value$]="sortByValue$"
					(changeOrder)="changeSortBy($event)"
				></search-sort-by>
			</div>
		</div>
	</div>
}
