import {BreakpointRuleInterface} from '../interfaces';

export class MaxBreakpointRule implements BreakpointRuleInterface {
	readonly option = 'max';

	constructor(
		public value: number,
		public raw: string,
	) {}

	isMatch(value: number): boolean {
		return value <= this.value;
	}

	toString(): string {
		return this.raw;
	}
}
