import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';

import {SearchFeatureState} from '../../reducers/search-state';
import {ChangeFilterSearchAction} from '@search/actions';
import {SearchResultModel} from '@shared/models';

@Component({
	selector: 'search-metadata',
	templateUrl: './metadata.component.html',
	styleUrls: ['./metadata.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
})
export class MetadataComponent implements OnInit {
	type!: string;
	branch!: string;
	leaf!: string;

	@Input({required: true}) result!: SearchResultModel;

	constructor(private store$: Store<SearchFeatureState>) {}

	ngOnInit(): void {
		const tree = this.result.locationTree;
		const [type, branch, leaf] = (tree?.[tree.length - 1] || '').split(':');

		this.type = type;
		this.branch = branch;
		this.leaf = leaf;
	}

	filter(value: string): void {
		this.store$.dispatch(new ChangeFilterSearchAction(value));
	}
}
