import {ChangeDetectionStrategy, Component, OnInit, inject} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {UserStore} from '../../state';
import {AccessInfoPopoverComponent} from '../access-info-popover/access-info-popover.component';
import {SvgIconComponent} from '@pp/svg';
import {PopoverService} from '@shared/popover';
import {HasTeamPipe} from '@core/roles';

@Component({
	selector: 'shared-access-info',
	templateUrl: './access-info.component.html',
	styleUrls: ['./access-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [PopoverService],
	standalone: true,
	imports: [SvgIconComponent, AsyncPipe, HasTeamPipe],
})
export class AccessInfoComponent implements OnInit {
	private viewedStorage = {
		key: 'uspp.access-info-viewed',
		value: 'true',
	};

	teams = inject(UserStore).profile.teams;
	viewed$ = new BehaviorSubject<boolean>(false);

	constructor(private readonly popOverService: PopoverService) {}

	ngOnInit() {
		const isViewed = localStorage.getItem(this.viewedStorage.key) === this.viewedStorage.value;

		this.viewed$.next(isViewed);
	}

	openAccessInfoPopover(event: Event): void {
		localStorage.setItem(this.viewedStorage.key, this.viewedStorage.value);

		this.viewed$.next(true);

		this.popOverService.openPopOver(
			AccessInfoPopoverComponent,
			{},
			event.target as HTMLElement,
			'bottom',
			true,
		);
	}
}
