@if (appliedFiltersGroups.length > 0) {
	<ul class="search-filters-selected-list search-filters-selected-list-multi-source">
		@for (appliedFilterGroup of appliedFiltersGroups; track appliedFilterGroup.filterName) {
			@if (appliedFilterGroup.filters.length === 1) {
				<search-applied-filter
					(click)="scrollAndFocus(appliedFilterGroup.filterName)"
					[appliedFilterModel]="appliedFilterGroup.filters[0]"
					(removeEmitter)="removeFilter(appliedFilterGroup.filterName)"
				></search-applied-filter>
			} @else {
				<search-applied-filter-group
					(click)="scrollAndFocus(appliedFilterGroup.filterName)"
					[appliedFilterModel]="appliedFilterGroup"
					(removeEmitter)="removeFilter(appliedFilterGroup.filterName)"
				>
				</search-applied-filter-group>
			}
		}

		<button
			btn
			btnType="link"
			withIconBefore="navigation-refresh-18"
			(click)="resetFilters()"
			class="reset-filters"
		>
			Reset Filters
		</button>
	</ul>
}
