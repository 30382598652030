import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';

import {ResetSelectedFilterAction, ResetSelectedFiltersAction} from '../../actions';
import {SearchFeatureState} from '../../reducers/search-state';
import {SearchAppliedFilterComponent} from '../search-applied-filter/search-applied-filter.component';
import {AppliedFilterGroupViewModel} from './applied-filter-group.viewmodel';
import {SearchAppliedFilterGroupComponent} from './applied-filter-group/search-applied-filter-group.component';
import {ButtonComponent} from '@pp/button';
import {FilterSectionNamePipe} from '@shared/pipes';
import {AnchorDirective} from '@pp/anchor';
import {ToastService} from '@shared/services';

@Component({
	selector: 'search-context-sp',
	templateUrl: './search-context.component.html',
	styleUrls: ['./search-context.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SearchAppliedFilterComponent, SearchAppliedFilterGroupComponent, ButtonComponent],
})
export class SearchContextComponent {
	@Input({required: true}) appliedFiltersGroups!: AppliedFilterGroupViewModel[];

	constructor(
		private store$: Store<SearchFeatureState>,
		private readonly filterSectionNamePipe: FilterSectionNamePipe,
		private readonly toastService: ToastService,
	) {}

	scrollAndFocus(filterName: string): void {
		const filterWidgetName = this.filterSectionNamePipe.transform(filterName);
		const filterWidgetAnchor = AnchorDirective.generateArchonAttributeName(
			'search-filter-section',
			filterWidgetName,
		);
		const filterWidgetSelectorElement = document.querySelectorAll(
			`[pp-anchor="${filterWidgetAnchor}"]`,
		)[0];

		if (filterWidgetSelectorElement) {
			this.scrollAndHighlightFilter(filterWidgetSelectorElement);
		} else {
			this.toastService.show({
				type: 'info',
				message: `The '${filterWidgetName}' filter is hidden on your Filter Panel.`,
			});
		}
	}

	removeFilter(filterName: string): void {
		this.store$.dispatch(new ResetSelectedFilterAction(filterName, 'search-context'));
	}

	private scrollAndHighlightFilter(filterNativeElement: Element): void {
		filterNativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
		});

		// '#ebedf5'
		window.requestAnimationFrame(() => {
			filterNativeElement.animate(
				[
					{backgroundColor: 'rgb(196, 234, 245, 1);'},
					{backgroundColor: 'rgb(196, 234, 245, .6)'},
					{backgroundColor: 'rgb(196, 234, 245, .3)'},
					{backgroundColor: 'initial'},
				],
				{
					duration: 2000,
				},
			);
		});
	}

	resetFilters(): void {
		this.store$.dispatch(new ResetSelectedFiltersAction());
	}
}
