import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {SkeletonItemComponent} from '../skeleton-item/skeleton.component';
import {SkeletonCardComponent} from '../skeleton-card/skeleton-card.component';
import {RandomPipe} from '../random.pipe';

@Component({
	selector: 'pp-skeleton',
	standalone: true,
	imports: [SkeletonItemComponent, SkeletonCardComponent, RandomPipe],
	templateUrl: './skeleton.component.html',
	styleUrls: ['./skeleton.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class SharedSkeletonComponent {
	@Input({required: true}) type!:
		| 'panel'
		| 'horizontal-card'
		| 'aside'
		| 'vertical-card'
		| 'preview'
		| 'slides';
	@Input() isChipsVisibleOnVertical = false;
	@Input() showOnlyCardsOnHorizontal = false;
}
