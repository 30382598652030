import * as actions from '../../actions';
import {uiInitialState} from './ui-initial-state';
import {UIState} from './ui-state';

const minFilterLength = 3;

function getFilterRegex(value: string): RegExp | null {
	let regex: RegExp | null = null;

	if (value) {
		try {
			const escapedValue = value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

			regex = value
				? new RegExp(`(^|\\s|_)(${escapedValue.split(' ').join(')(.*\\W)(')})`, 'i')
				: null;
		} catch {
			regex = null;
		}
	}

	return regex;
}

function normalizeFilterSearch(value: string): string {
	return value.length >= minFilterLength ? value : '';
}

export function reducer(state: UIState = uiInitialState, action: actions.UIActions): UIState {
	switch (action.type) {
		case actions.CHANGE_FILTER_SEARCH: {
			const regex = getFilterRegex(normalizeFilterSearch(action.payload));
			const replace =
				!state.filterSearchRegex ||
				!regex ||
				state.filterSearchRegex.toString() !== regex.toString();

			return {
				...state,
				filterSearch: action.payload,
				filterSearchRegex: (replace ? regex : state.filterSearchRegex)?.toString(),
			};
		}
		case actions.CHANGE_FILTER_SEARCH_REGEX: {
			const regex = getFilterRegex(normalizeFilterSearch(action.payload));
			const replace =
				!state.filterSearchRegex ||
				!regex ||
				state.filterSearchRegex.toString() !== regex.toString();

			return {
				...state,
				filterSearchRegex: (replace ? regex : state.filterSearchRegex)?.toString(),
			};
		}
		case actions.CHANGE_VIEW:
			return {
				...state,
				view: action.payload,
			};
		case actions.TOGGLE_QUERY_MODIFICATION:
			return {
				...state,
				queryModificationVisible: action.payload,
			};
		case actions.RESET_UI:
			return {
				...state,
			};
		default:
			return state;
	}
}
