<div class="project-info-container">
	<main class="project-info-content">
		<a
			class="project-info-link"
			title="Visit Telescope"
			[href]="telescopeLink()"
			(click)="trackProjectNavigation()"
		>
			{{ project().code }}
			<pp-icon name="logos_telescope_18" [size]="18"></pp-icon>
		</a>
		<div class="project-info-status">
			@if (!isActive()) {
				@if (project().endDate) {
					<div class="project-info-date">
						{{ project().endDate | date: 'yyyy-MM-dd' }}
					</div>
					<div class="dp-dot dp-dot-18"></div>
				}
				<div class="closed-status">Closed</div>
			} @else {
				<div class="active-status">Active</div>
			}
		</div>
	</main>
	<div class="project-info-metadata">
		@if (project().deliveryManager) {
			<div class="project-info-metadata-manager">
				DM: {{ project().deliveryManager!.name }}
			</div>
		}
	</div>
	<div class="project-info-metadata">
		@if (project().accountManager && project().accountManager?.name !== accountAm()) {
			<div class="project-info-metadata-manager">
				AM: {{ project().accountManager!.name }}
			</div>
		}
	</div>
</div>
<div class="project-info-divider"></div>
