import {Injectable, inject} from '@angular/core';
import {Observable, mergeMap} from 'rxjs';
import {toObservable} from '@angular/core/rxjs-interop';
import {
	SearchClient,
	PpSearchResultsOfDocumentIndexItem,
	PpSearchQuery,
	DocumentIndexItem,
	PpAutocompleteItem,
} from '@shared/api';
import {OfficeStore} from '@office/state';

@Injectable({providedIn: 'root'})
export class SearchQueryService {
	constructor(private readonly searchClient: SearchClient) {}

	createQuery(queryObj: Partial<PpSearchQuery>): PpSearchQuery {
		return {
			...queryObj,
			presetId: queryObj.presetId ?? 'default',
			queryText: queryObj.queryText ?? '',
			hiddenConstraints: queryObj.hiddenConstraints,
			orderBy: queryObj.orderBy,
			facets: queryObj.facets,
			facetFilters: queryObj.facetFilters,
			skip: queryObj.skip ?? 0,
			size: queryObj.size ?? 30,
		};
	}

	private showOnlyPowerPoint$ = toObservable(inject(OfficeStore).showOnlyPowerPoint);
	search$(query: PpSearchQuery): Observable<PpSearchResultsOfDocumentIndexItem> {
		return this.showOnlyPowerPoint$.pipe(
			mergeMap((showOnlyPowerPoint) => {
				const queryClone = {...query};
				if (showOnlyPowerPoint) {
					const constraint = 'doc_type:"PowerPoint"';
					queryClone.hiddenConstraints = queryClone.hiddenConstraints
						? `${queryClone.hiddenConstraints} AND ${constraint}`
						: constraint;
				}

				return this.searchClient.search(queryClone);
			}),
		);
	}

	searchByIds$(documentIds: string[]): Observable<DocumentIndexItem[]> {
		return this.searchClient.searchList(documentIds);
	}

	suggestions$(query: string | null, presetId: string): Observable<PpAutocompleteItem[]> {
		return this.searchClient.getSuggestions(query, presetId);
	}
}
