<ul class="query-keywords-list">
	<li (click)="search(' title:')">title:</li>
	<li (click)="search(' logo:')">logo:</li>
	<li (click)="search(' about:')">about:</li>
	<li (click)="search(' filename:')">filename:</li>
	<li (click)="search(' fileExtension:')">fileExtension:</li>
	<li (click)="search(' slidesCount&gt;')">slidesCount&gt;</li>
</ul>
<ul class="query-keywords-list">
	<li (click)="search(' AND')">AND</li>
	<li (click)="search(' OR')">OR</li>
	<li (click)="search(' NOT')">NOT</li>
	<li (click)="search(' NEAR ')">NEAR</li>
	<li (click)="search(' ONEAR')">ONEAR</li>
	<li (click)="search(' ANY()')">ANY()</li>
</ul>
