@if (slides$ | async; as slides) {
	@if (slides.length) {
		@if (loaded || visible) {
			<div class="slide-context-container" [class.hidden]="!visible">
				@for (slide of slides; track slide.documentId) {
					<div
						class="slide-context-thumbnail"
						[ngClass]="{
							selected:
								slide.filePath === base.filePath ||
								slide.filePath === base.sourceFilePath
						}"
						(click)="selectRecommendation(slide)"
					>
						<pp-image name="logo_ppt_64" [width]="64" [height]="64" />
						<div class="slide-context-number">#{{ slide.filePath | slideNumber }}</div>
						<shared-thumbnail
							[src]="slide.thumbnail"
							[external]="slide.externalThumbnail === true"
						></shared-thumbnail>
					</div>
				}
			</div>
		}
	} @else {
		@if (visible) {
			<span class="slide-context-no-data">Source Document does not exist.</span>
		}
	}
} @else {
	@if (visible) {
		<pp-spinner class="slide-context-no-data"></pp-spinner>
	}
}
