import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {MatIcon} from '@angular/material/icon';

const NAME_REGEXP = /[-_](\d{2})$/;

@Component({
	selector: 'pp-icon',
	template: '',
	standalone: true,
	providers: [{provide: MatIcon, useExisting: SvgIconComponent}],
	styleUrls: ['./icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent extends MatIcon {
	@Input({required: true})
	set name(name: string) {
		this.svgIcon = name;

		if (this.size === 0) {
			this.size = this.getSizeFromName(name);
		}
	}

	@Input() size = 0;

	@HostBinding('class.mat-icon') matIconClass = true;
	@HostBinding('class.notranslate') noTranslateClass = true;

	@HostBinding('style.width.px') get width() {
		return this.size;
	}
	@HostBinding('style.height.px') get height() {
		return this.size;
	}

	/**
	 * Name structure
	 * icon-name-18
	 * icon-name_18
	 */
	private getSizeFromName(name: string): number {
		return +(NAME_REGEXP.exec(name) || [])[1];
	}
}
