<div class="informative-banner-container">
	@switch (presetId) {
		@case ('cases') {
			<span class="informative-banner-caption"> Presales Case Studies </span>
			<article class="informative-banner-article">
				<header class="informative-banner-header">
					Explore thousands of client-facing project stories summarized in one pagers
				</header>
				<ul>
					<li>Pinpoint the right project story with tags and filters</li>
					<li>Learn more from project members and supporting documents</li>
					<li>Rely on trusted content verified by case study creators</li>
				</ul>

				<div class="informative-banner-buttons-container">
					<a
						class="read-more"
						(click)="trackAnalytics('readMore', presetId)"
						btn
						btnType="secondary"
						[href]="
							''
						"
						target="_blank"
						>Read More</a
					>
				</div>
			</article>
		}

		@case ('stories') {
			<span class="informative-banner-caption">Presales Stories</span>
			<article class="informative-banner-article">
				<header class="informative-banner-header">
					Explore client-ready sales presentations capabilities across
					practices, locations, and industries
				</header>
				<ul>
					<li>
						Trusted, up-to-date content verified by Practice Leads and Presales Experts
					</li>
					<li>“How to pitch this” videos</li>
					<li>Consistent look & feel guaranteed by the Presales-approved template</li>
				</ul>

				<div class="informative-banner-buttons-container">
					<a
						class="read-more"
						(click)="trackAnalytics('readMore', presetId)"
						btn
						btnType="secondary"
						[href]="
							''
						"
						target="_blank"
						>Read More</a
					>

					@if (videoUrl) {
						<button
							(click)="openVideoModal()"
							class="video-button"
							btn
							withIconBefore="media-play-12"
						>
							Watch Video
						</button>
					}
				</div>
			</article>
		}

		@case ('offerings') {
			<span class="informative-banner-caption"> Presales Offerings </span>
			<article class="informative-banner-article">
				<header class="informative-banner-header">
					Propose proven solutions for your client’s needs
				</header>
				<ul>
					<li>
						Ready-to-use solution and service offerings that address the challenges that
						businesses often face and include typical pricing model, timeline, and
						deliverables
					</li>
					<li>Created and maintained by Practice Leads and Subject Matter Experts</li>
					<li>
						Designed to unlock large-scale engagements by delivering tangible value in a
						short term
					</li>
				</ul>
				<div class="informative-banner-buttons-container">
					<a
						class="read-more"
						(click)="trackAnalytics('readMore', presetId)"
						btn
						btnType="secondary"
						[href]="''"
						target="_blank"
						>Read More</a
					>
					@if (videoUrl) {
						<button
							(click)="openVideoModal()"
							class="video-button"
							btn
							withIconBefore="media-play-12"
						>
							Watch Video
						</button>
					}
				</div>
			</article>
		}

		@case ('toolbox') {
			<span class="informative-banner-caption"> Presales ToolBox </span>
			<article class="informative-banner-article">
				<header class="informative-banner-header">
					Kick-off your sales activities with curated templates and tools
				</header>
				<ul>
					<li>
						Start with Training to take the max value of the Portal and Presales
						services
					</li>
					<li>Select a suitable Template according to your request</li>
					<li>
						Check out our Toolkit for the "best of" Presales materials & tools,
						including Rate Cards, RFI Q&As and many more
					</li>
				</ul>
			</article>
		}
	}

	<pp-image
		class="informative-banner-image"
		[name]="'informative-group-' + imageGroupNumber"
		[width]="147"
		[height]="183"
	></pp-image>
</div>
