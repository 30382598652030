import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnInit,
} from '@angular/core';
import {SvgIconComponent} from '@pp/svg';

export type ButtonType =
	| 'primary'
	| 'secondary'
	| 'link'
	| 'cancel'
	| 'call'
	| 'accent'
	| 'secondary-cancel';

const ButtonTypes: readonly ButtonType[] = [
	'primary',
	'secondary',
	'secondary-cancel',
	'link',
	'cancel',
	'call',
	'accent',
];

const BorderedButtonTypes: readonly ButtonType[] = ['secondary', 'secondary-cancel', 'cancel'];

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'button[btn], a[btn]',
	standalone: true,
	imports: [SvgIconComponent],
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input()
	set btnType(type: ButtonType) {
		if (type !== this.#btnType) {
			this.#btnType = type;

			this.setHostClass(type);
		}
	}

	get hasIconBefore(): boolean {
		return this.withIconBefore !== '';
	}

	get hasIconAfter(): boolean {
		return this.withIconAfter !== '';
	}

	@HostBinding('class.bordered')
	get bordered(): boolean {
		return BorderedButtonTypes.includes(this.#btnType);
	}

	get hasText(): boolean {
		return !this.onlyIcon;
	}

	#btnType: ButtonType = 'primary';

	@Input() withIconBefore = '';
	@Input() withIconAfter = '';
	@Input() iconSize = 18;

	@Input()
	@HostBinding('class.disabled')
	@HostBinding('disabled')
	disabled = false;

	@Input()
	@HostBinding('class.only-icon')
	onlyIcon = false;

	constructor(
		private readonly elementRef: ElementRef,
		private readonly detectorRef: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.setHostClass(this.#btnType);
	}

	showText(): void {
		this.onlyIcon = false;

		this.detectorRef.detectChanges();
	}

	hideText(): void {
		this.onlyIcon = true;

		this.detectorRef.detectChanges();
	}

	private setHostClass(type: ButtonType): void {
		const {nativeElement} = this.elementRef;

		nativeElement.classList.remove(...ButtonTypes);
		nativeElement.classList.add(type);
	}
}
