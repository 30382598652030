import {Directive, HostBinding, Input, Self} from '@angular/core';
import {ThemeService} from '../../theme/services';
import {ThemeModeType} from '../../theme/typings/theme-mode.type';

@Directive({
	selector: '[ppCdkUseTheme]',
	standalone: true,
	providers: [ThemeService],
})
export class UseThemeDirective {
	#themeMode: ThemeModeType = 'light';

	@Input('ppCdkUseTheme')
	set themeMode(themeMode: ThemeModeType | null) {
		const mode = themeMode ?? 'light';

		if (mode !== this.#themeMode) {
			this.#themeMode = mode;
			this.theme.setMode(mode);
		}
	}

	@HostBinding('class')
	get themeClasses(): string {
		return this.theme.isLight() ? 'uui-light-theme light-theme' : 'uui-dark-theme dark-theme';
	}

	constructor(@Self() private readonly theme: ThemeService) {}
}
