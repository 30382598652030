import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input,
	OnInit,
	OnDestroy,
	inject,
	computed,
	signal,
} from '@angular/core';
import {CdkDragDrop, CdkDrag, CdkDragHandle, CdkDropList} from '@angular/cdk/drag-drop';
import {Subscription, tap} from 'rxjs';
import {NgClass} from '@angular/common';
import {PeoplePickerComponent} from '../people-picker/people-picker.component';
import {UsppUserVm} from '../uspp-user-vm';
import {DeleteConfirmationComponent} from '../../delete-confirmation/delete-confirmation.component';
import {SvgIconComponent} from '@pp/svg';
import {PreviewUserInfoComponent} from '@shared/access';
import {CURRENT_MODAL, ModalRef} from '@shared/modals';
import {UsppUserInfo, DocContactType} from '@shared/api';
import {UserStore} from '@shared/state';
import {PopoverService} from '@shared/popover';
import {PreviewStore} from '@preview/document/preview.store';

@Component({
	selector: 'search-edit-contacts-modal',
	templateUrl: './edit-contacts-modal.component.html',
	styleUrls: ['./edit-contacts-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [PopoverService],
	standalone: true,
	imports: [
		CdkDrag,
		CdkDragHandle,
		SvgIconComponent,
		CdkDropList,
		NgClass,
		PreviewUserInfoComponent,
		PeoplePickerComponent,
	],
})
export class EditContactsModalComponent implements OnInit, OnDestroy {
	@Input({required: true}) title!: string;

	@Input({required: true})
	set contactType(value: DocContactType) {
		this.contactTypeSignal.set(value);
	}
	private contactTypeSignal = signal<DocContactType>('additional');

	private readonly subscriptions = new Subscription();
	theme = inject(UserStore).settings.uiTheme;
	documentId = inject(PreviewStore).documentId;

	contacts = computed(() => {
		const contacts = this.previewStore.contacts();
		const field = this.contactTypeSignal() == 'owners' ? 'owners' : 'additionalContacts';
		const items = contacts[field].items ?? [];

		return items.map(
			(contact) =>
				({
					data: contact,
					expertiseEditable: contact.expertise,
				}) as UsppUserVm,
		);
	});

	constructor(
		@Inject(CURRENT_MODAL) readonly modalRef: ModalRef,
		private readonly popOverService: PopoverService,
	) {}

	private previewStore = inject(PreviewStore);

	ngOnInit() {
		this.previewStore.loadContacts(this.documentId());

		this.subscriptions.add(
			this.modalRef.closed$
				.pipe(
					tap(() => {
						this.contacts().forEach((item) => {
							this.saveContactIfChanged(item);
						});
					}),
				)
				.subscribe(),
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	closeDialog(): void {
		this.modalRef.close();
	}

	drop(contacts: UsppUserVm[], event: CdkDragDrop<UsppUserInfo[]>): void {
		const contact = contacts[event.previousIndex].data;
		this.previewStore.moveContact({
			documentId: this.documentId(),
			contactType: this.contactTypeSignal(),
			contact,
			newIndex: event.currentIndex,
		});
	}

	removeContact(contact: UsppUserVm): void {
		this.previewStore.removeContact({
			documentId: this.documentId(),
			contact: contact.data,
			contactType: this.contactTypeSignal(),
		});
	}

	saveContactIfChanged(contact: UsppUserVm): void {
		if (this.hasUnsavedChanges(contact)) {
			const updatedContact: UsppUserInfo = {
				...contact.data,
				expertise: contact.expertiseEditable,
			};

			this.previewStore.updateContact({
				documentId: this.documentId(),
				contact: updatedContact,
				contactType: this.contactTypeSignal(),
			});
		}
	}

	updateContact(contact: UsppUserVm, eventTarget: EventTarget | null): void {
		const target = eventTarget as HTMLInputElement;
		if (target) {
			contact.expertiseEditable = target.value;
		}
	}

	hasUnsavedChanges(contact: UsppUserVm) {
		return contact.expertiseEditable !== contact.data.expertise;
	}

	contactIdentity(_: number, contact: UsppUserVm): string {
		return contact.data.aadId || contact.data.externalId || '';
	}

	openDeleteConfirmation(event: Event, contact: UsppUserVm): void {
		const deleteConfirmation = this.popOverService.openPopOver(
			DeleteConfirmationComponent,
			{
				itemToDeleteName: contact.data.name,
				itemToDeleteType: 'person',
			},
			event.target as HTMLElement,
			'left',
			true,
		);

		this.subscriptions.add(
			deleteConfirmation.confirmDeletingEmitter
				.pipe(
					tap(() => {
						this.removeContact(contact);
					}),
				)
				.subscribe(),
		);
	}
}
