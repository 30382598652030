import {Inject, Injectable, Signal, computed, inject, signal} from '@angular/core';
import {NavigationTreeNode} from '../../models';
import {UserStore} from '../../state';
import {SearchPresetTreeNode} from '@shared/api';
import {OfficeStore} from '@office/state';
import {SearchStore} from '@search2/state';
import {RoleService} from '@core/roles';
import {CONFIG, EnvConfigType} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class NavigationService {
	constructor(
		private readonly roleService: RoleService,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	private userStore = inject(UserStore);
	private officeStore = inject(OfficeStore);
	private searchStore = inject(SearchStore);
	readonly bannerPresets = ['stories', 'offerings', 'cases', 'toolbox'];

	private teams = this.userStore.profile.teams;
	private isInPowerPoint = this.officeStore.isInsidePowerPoint;
	private showOnlyPowerPoint = this.officeStore.showOnlyPowerPoint;

	navigationTree = computed(() => {
		const teams = this.teams();

		// Home
		const navigation: NavigationTreeNode[] = [
			{
				icon: 'house-icon',
				name: 'Home',
				link: '/dashboard',
				isLocked: false,
			},
		];

		// Presales for Office
		if (this.isInPowerPoint()) {
			const items: NavigationTreeNode[] = [
				{
					name: 'Presales Templates',
					isLocked: false,
					link: '/powerpoint/templates',
				},
				{
					name: 'Reusable Slides',
					isLocked: false,
					link: '/powerpoint/reusable-slides',
					badge: 'Beta',
				},
			];

			if (this.roleService.isUserInRole(teams, 'admin')) {
				items.push({
					name: 'Slide Tags',
					isLocked: false,
					link: '/powerpoint/slide-tags',
					badge: 'Preview',
				});
			}

			navigation.push({
				icon: 'logos_ppt_colorless_24',
				name: 'Presales for Office',
				items,
				isLocked: false,
			});
		}

		// Spaces
		const presetGroups = this.searchStore.presetsGroups();
		const presetTree = presetGroups
			.filter((presetGroup) => {
				if (!this.showOnlyPowerPoint()) {
					return true;
				}
				if (presetGroup.presetId) {
					return this.bannerPresets.includes(presetGroup.presetId);
				} else {
					return presetGroup.name === 'Hidden';
				}
			})
			.map((presetGroup) => this.transformPreset(presetGroup));

		navigation.push({
			icon: 'planet-icon',
			name: 'Spaces',
			items: presetTree,
			isLocked: false,
		});

		// PresalesGPT
		// navigation.push({
		// 	icon: 'masha-logo-bold',
		// 	name: 'PresalesGPT',
		// 	items: [
		// 		{
		// 			name: 'Ask Presales',
		// 			isLocked: false,
		// 			hint: '',
		// 			externalLink: '',
		// 		},
		// 		{
		// 			name: 'SalesBuddy',
		// 			isLocked: false,
		// 			hint: '',
		// 			externalLink: '',
		// 			isNew: true,
		// 		},
		// 		{
		// 			name: 'Compliance Q&A',
		// 			isLocked: false,
		// 			hint: '',
		// 			externalLink: '',
		// 			isNew: true,
		// 		},
		// 	],
		// 	isLocked: false,
		// });

		if (!this.config.serviceless && (!this.isInPowerPoint() || !this.showOnlyPowerPoint())) {
			if (this.roleService.isUserInRole(teams, 'pipeline-team')) {
				navigation.push({
					icon: 'file-file_text-24',
					name: 'Ongoing Report',
					link: '/presales-report',
					isLocked: false,
				});
			}
			if (this.roleService.isUserInRole(teams, 'admin')) {
				navigation.push({
					icon: 'account-icon',
					name: 'Admin',
					link: '/admin/users/all',
					isLocked: false,
				});
			}
		}

		return navigation;
	});

	private isNavigationPanelOpened = signal(false);

	setNavPanelState(state: boolean): void {
		this.isNavigationPanelOpened.set(state);
	}

	toggleNavPanelState(): void {
		this.isNavigationPanelOpened.update((value) => !value);
	}

	getNavPanelState(): Signal<boolean> {
		return this.isNavigationPanelOpened;
	}

	private transformPreset(node: SearchPresetTreeNode): NavigationTreeNode {
		return {
			name: node.name,
			link: node.presetId ? `/p/${node.presetId}` : undefined,
			items: node.items ? node.items.map((item) => this.transformPreset(item)) : undefined,
			isLocked: false,
		};
	}

	private getSearchNavLinks(): NavigationTreeNode[] {
		return [
			{
				name: 'Coming soon',
				link: '/',
				queryParams: {
					o: '',
				},
				isLocked: true,
			},
		];
	}

	private checkLinkExistence(
		presetGroup: SearchPresetTreeNode,
		currentLink: string | undefined,
	): boolean {
		return !!presetGroup.items
			? presetGroup.items.some((item) => {
					if (!!item.items) {
						return this.checkLinkExistence(item, currentLink);
					} else {
						return item.presetId === currentLink;
					}
				})
			: presetGroup.presetId === currentLink;
	}

	private linksArray: NavigationTreeNode[] = [
		{
			name: 'Marketing',
			link: 'marketing',
			queryParams: undefined,
			isLocked: false,
		},
	];

	getSalesPitchesLinks = computed(() => {
		const presetGroups = this.searchStore.presetsGroups();

		return this.linksArray.map((link) => {
			const isLinkExist = presetGroups.some((presetGroup) =>
				this.checkLinkExistence(presetGroup, link.link),
			);

			return {
				...link,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				link: `/p/${link.link!}`,
				isLocked: !isLinkExist,
			};
		});
	});

	getProposalLinks = computed(() => {
		const results: NavigationTreeNode[] = [
			{
				name: 'Sales',
				link: '/p/sales',
				queryParams: undefined,
				isLocked: false,
			},
			{
				name: 'Archive',
				link: '/p/archive',
				queryParams: undefined,
				isLocked: false,
			},
		];

		return results;
	});

	getInsightsLinks = computed(() => {
		const insightsLinksFromNavLinks = this.getSearchNavLinks();

		return insightsLinksFromNavLinks;
	});

	getContributeLinks = [
		{
			name: 'PresalesHUB',
			link: '',
			queryParams: undefined,
			isLocked: false,
			externalLink: 'https://medecision.sharepoint.com/sites/PresaleHUB',
		},
		{
			name: 'Confluence',
			link: '',
			queryParams: undefined,
			isLocked: false,
			externalLink: 'https://aerialproduct.atlassian.net',
		},
	];
}
