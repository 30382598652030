import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import {QuerySamplesComponent} from '../query-samples/query-samples.component';
import {QueryKeywordsComponent} from '../query-keywords/query-keywords.component';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'shared-query-tips',
	templateUrl: './query-tips.component.html',
	styleUrls: ['./query-tips.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [QueryKeywordsComponent, QuerySamplesComponent, SvgIconComponent],
})
export class QueryTipsComponent {
	selectedTab: 'keywords' | 'samples' = 'keywords';

	@Output() append = new EventEmitter<string>();
	@Output() replace = new EventEmitter<string>();

	switchTab(tab: 'keywords' | 'samples'): void {
		this.selectedTab = tab;
	}

	processQuerySample(query: string): void {
		this.replace.emit(query);
	}

	processKeyword(query: string): void {
		this.append.emit(query);
	}
}
