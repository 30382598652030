import {Injectable} from '@angular/core';
import {SearchFiltersListService} from './search-filters-list.service';
import {FilterModel, FilterViewModel} from '@search/models';

@Injectable({providedIn: 'root'})
export class SearchFiltersListGroupService extends SearchFiltersListService {
	// group the same (content) file types by name
	protected override groupByName(items: FilterModel[]): FilterViewModel[] {
		const names = new Set(items.map((item) => item.name));
		const result = this.order(Array.from(names)).map((name) => {
			const filters = items.filter((filter) => filter.name === name);

			return {
				count: filters.reduce((sum, filter) => sum + +(filter.count ?? 0), 0),
				name,
				filters,
			};
		});

		return result;
	}

	private order(names: string[]): string[] {
		const index = names.findIndex((value) => value === 'Other');

		if (index >= 0) {
			names.splice(index, 1);
			names.push('Other');
		}

		return names;
	}
}
