import {Action} from '@ngrx/store';

import {SelectedFiltersPayload} from './selected-filters-payload';

export const RESET_SELECTED_FILTERS = '[Search] Reset Selected Filters';

export class ResetSelectedFiltersAction implements Action {
	readonly type = RESET_SELECTED_FILTERS;
	// Actually it's not used. But... TypeScript wants it really bad.
	payload?: SelectedFiltersPayload;
}
