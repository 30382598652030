import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {NgFor, AsyncPipe} from '@angular/common';
import {toSignal} from '@angular/core/rxjs-interop';
import {ToolboxTemplatesService} from '../../services';
import {GroupedSectionComponent} from '../grouped-section/grouped-section.component';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';
import {UseThemeDirective} from '@shared/directives';
import {UserStore} from '@shared/state';

@Component({
	selector: 'powerpoint-templates-page',
	standalone: true,
	imports: [UseThemeDirective, NgFor, AsyncPipe, GroupedSectionComponent],
	templateUrl: './templates-page.component.html',
	styleUrl: './templates-page.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () =>
				new ContextualAnalyticsService(inject(AnalyticsService), 'PowerPointTemplates'),
		},
	],
})
export class TemplatesPageComponent implements OnInit {
	theme = inject(UserStore).settings.uiTheme;
	templatesTree = toSignal(this.templatesService.getTemplates$());

	constructor(
		private analytics: AnalyticsService,
		private templatesService: ToolboxTemplatesService,
	) {}

	ngOnInit() {
		this.analytics.trackPageNavigation('/powerpoint/templates');
	}
}
