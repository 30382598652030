import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {StoreModule} from '@ngrx/store';

import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {ApmModule, ApmService} from '@elastic/apm-rum-angular';
import {MatIconModule} from '@angular/material/icon';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login.component';
import {APP_MODULES} from './modules';
import {RedirectComponent} from './redirect.component';
import {DocumentScrollService} from '@shared/modals';
import {AnchorDirective} from '@pp/anchor';
import {SearchFiltersComponent, SearchSuggestionComponent} from '@search2/components';
import {
	HeaderComponent,
	OutdatedBrowserComponent,
	ToastMessagesComponent,
	NetworkStatusComponent,
} from '@core/components';
import {SearchBarComponent, SearchFiltersLayoutComponent} from '@search/components';
import {SvgIconComponent} from '@pp/svg';
import {MobileFiltersTogglerComponent} from '@shared/components/mobile-filters-toggler/mobile-filters-toggler.component';
import {NavigationPanelComponent} from '@shared/components/navigation-panel';
import {PresalesPortalLoadingComponent} from '@shared/components/presales-portal-loading/presales-portal-loading.component';

@NgModule({
	declarations: [AppComponent, RedirectComponent, LoginComponent],
	providers: [ApmService, DocumentScrollService],
	bootstrap: [AppComponent],
	imports: [
		ApmModule,
		BrowserModule,
		BrowserAnimationsModule,
		DragDropModule,
		...APP_MODULES,
		StoreModule.forRoot(
			{},
			{
				runtimeChecks: {
					strictStateImmutability: true,
					strictActionImmutability: true,
					strictStateSerializability: true,
					strictActionSerializability: true,
					strictActionWithinNgZone: true,
					strictActionTypeUniqueness: true,
				},
			},
		),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: true,
			connectInZone: true,
		}),
		AppRoutingModule,
		MatIconModule,
		HttpClientModule,
		AnchorDirective,
		HeaderComponent,
		PresalesPortalLoadingComponent,
		NavigationPanelComponent,
		OutdatedBrowserComponent,
		ToastMessagesComponent,
		NetworkStatusComponent,
		MobileFiltersTogglerComponent,
		SearchFiltersComponent,
		SearchSuggestionComponent,
		SearchBarComponent,
		SearchFiltersLayoutComponent,
		SvgIconComponent,
	],
})
export class AppModule {}
