import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {NgClass} from '@angular/common';
import {SpinnerType} from './spinner-type.enum';

@Component({
	selector: 'pp-spinner',
	standalone: true,
	imports: [NgClass],
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent {
	private styles: ISpinnerComponent = {
		default: '',
		dark: 'spinner-dark',
		black: 'spinner-black',
	};

	private contexts: IContexts = {
		default: 'default-context',
		bookmark: 'loading-bookmark-context',
	};

	@Input() style = 'default';
	@Input() context = 'default';
	@Input() type: SpinnerType = 'circle';
	@Input() size: 'big' | 'medium' | 'small' = 'big';

	getClass(): string | string[] {
		return this.type === 'circle'
			? [
					this.styles[this.style as keyof typeof this.styles],
					this.contexts[this.context as keyof typeof this.contexts],
				]
			: `spinner-dp-size-${this.size}`;
	}
}

interface ISpinnerComponent {
	default: string;
	dark: string;
	black: string;
}

interface IContexts {
	default: string;
	bookmark: string;
}
