import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {UtilsService} from '@shared/services';

@Component({
	selector: 'search-preview-video',
	templateUrl: './preview-video.component.html',
	styleUrls: ['./preview-video.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class PreviewVideoComponent {
	@Input({required: true}) set videoUrls(value: string[] | null) {
		if (!!value) {
			this.sanitizedUrls = [];
			value.forEach((path) => {
				const embedUrl = this.utilsService.getEmbeddedVideoUrl(path);

				if (embedUrl) {
					this.sanitizedUrls.push(
						this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl),
					);
				} else {
					this.unsupportedUrls.push(path);
				}
			});
		}
	}

	@Input() label?: string;

	sanitizedUrls: SafeResourceUrl[] = [];
	unsupportedUrls: string[] = [];

	constructor(
		private sanitizer: DomSanitizer,
		private readonly utilsService: UtilsService,
	) {}
}
