import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {RouterLink} from '@angular/router';
import {HomeFooterComponent} from '../home-footer/home-footer.component';
import {InfoBlockComponent} from './info-block/info-block.component';
import {UseThemeDirective} from '@shared/directives';
import {SvgImageComponent} from '@pp/svg';
import {UserStore} from '@shared/state';
import {AbbreviationIconComponent} from '@shared/components/abbreviation-icon/abbreviation-icon.component';

@Component({
	selector: 'powerpoint-home-layout',
	templateUrl: './office-home-layout.component.html',
	styleUrls: ['./office-home-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		UseThemeDirective,
		SvgImageComponent,
		InfoBlockComponent,
		RouterLink,
		AbbreviationIconComponent,
		HomeFooterComponent,
	],
})
export class OfficeHomeLayoutComponent {
	theme = inject(UserStore).settings.uiTheme;

	existingContentCards = [
		{
			title: 'Presales Stories',
			link: '/p/stories',
			content: 'Client-ready sales decks on company capabilities',
		},
		{
			title: 'Presales Case Studies',
			link: '/p/cases',
			content: 'Project stories summarized in one pagers',
		},
		{
			title: 'Presales Offerings',
			link: '/p/offerings',
			content: 'Proposal templates for company repeatable services and solutions',
		},
		{
			title: 'Reusable Slides',
			link: '/powerpoint/reusable-slides',
			badge: 'Beta',
			content: 'Icons and typical slide layouts for timelines, team composition, etc.',
		},
	];
}
