import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AddExcludeFilterAction, AddIncludeFilterAction} from '@search/actions';
import {FilterModel, FilterViewModel} from '@search/models';
import {SearchFeatureState} from '@search/reducers/search-state';
import {regexFromString} from '@shared/utils';

@Injectable({providedIn: 'root'})
export class SearchFiltersListService {
	filterSearch$: Observable<RegExp | null>;

	constructor(protected store$: Store<SearchFeatureState>) {
		this.filterSearch$ = this.store$
			.select((state) => state.search.ui.filterSearchRegex)
			.pipe(map(regexFromString));
	}

	getItems$(source: string): Observable<FilterViewModel[]> {
		const relevantItems$ = this.getRelevantItems$(source).pipe(
			map((items) => this.groupByName(items)),
		);

		return this.filterItemsBySearch$(relevantItems$);
	}

	includeFilter(item: FilterViewModel, source: string): void {
		if (item.selected) {
			return;
		}

		item.filters.forEach((filter) => {
			this.store$.dispatch(
				new AddIncludeFilterAction({
					type: source,
					filter,
				}),
			);
		});
	}

	excludeFilter(item: FilterViewModel, source: string): void {
		item.filters.forEach((filter) => {
			this.store$.dispatch(
				new AddExcludeFilterAction({
					type: source,
					filter,
				}),
			);
		});
	}

	protected getRelevantItems$(source: string): Observable<FilterModel[]> {
		return combineLatest([
			this.store$.select(
				(state) =>
					state.search.filters[
						source as keyof typeof state.search.filters
					] as FilterModel[],
			),
			this.store$.select(
				(state) =>
					state.search.selectedFilters.exclude[
						source as keyof typeof state.search.selectedFilters.exclude
					],
			),
			this.store$.select(
				(state) =>
					state.search.selectedFilters.include[
						source as keyof typeof state.search.selectedFilters.include
					],
			),
		]).pipe(
			map(([items, includeFilters, excludeFilters]) =>
				this.filterRelevantItems(items, includeFilters, excludeFilters),
			),
		);
	}

	protected groupByName(items: FilterModel[]): FilterViewModel[] {
		return items.map((item) => ({
			count: item.count,
			name: item.name ?? '',
			filters: [item],
		}));
	}

	protected filterItemsBySearch$(
		items$: Observable<FilterViewModel[]>,
	): Observable<FilterViewModel[]> {
		return combineLatest([items$, this.filterSearch$]).pipe(
			map(([items, filterSearch]) => this.applyFilterSearch(items, filterSearch)),
		);
	}

	protected applyFilterSearch(
		items: FilterViewModel[],
		filterSearch: RegExp | null,
	): FilterViewModel[] {
		return items
			.filter((item) => !filterSearch || filterSearch.test(item.name))
			.map((item) => {
				const richName = filterSearch
					? item.name.replace(filterSearch, this.highlightMatch)
					: item.name;

				return {
					...item,
					richName,
					hasChildren: false,
				};
			});
	}

	protected filterRelevantItems(
		items: FilterModel[],
		includeFilters: string[],
		excludeFilters: string[],
	): FilterModel[] {
		return items.filter((item) => {
			const notIncludeFilter =
				includeFilters.findIndex((token) => token === item.token) === -1;
			const notExcludeFilter =
				excludeFilters.findIndex((token) => token === item.token) === -1;

			return notIncludeFilter && notExcludeFilter;
		});
	}

	protected highlightMatch(this: void): string {
		// eslint-disable-next-line prefer-rest-params
		const groups = Array.prototype.slice.call(arguments, 1, -2);

		return groups
			.map((group: string, index: number) =>
				index % 2 === 1 ? `<mark>${group}</mark>` : group,
			)
			.join('');
	}
}
