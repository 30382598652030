import {Directive, TemplateRef} from '@angular/core';
import {TreeNodeContext} from './types/tree-node.context';

@Directive({
	selector: '[treeViewItem]',
	standalone: true,
})
export class TreeViewItemDirective<T = unknown> {
	static ngTemplateContextGuard<T>(
		_dir: TreeViewItemDirective<T>,
		_ctx: unknown,
	): _ctx is TreeNodeContext<T> {
		return true;
	}

	constructor(readonly template: TemplateRef<TreeNodeContext<T>>) {}
}
