import {inject} from '@angular/core';

import {combineLatest, concatMap, from, map, Observable, of, tap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UtilsService} from '@shared/services';
import {CloudAuthenticationService} from '@core/services';
import {ApiException, UserClient} from '@shared/api';
import {UserStore} from '@shared/state';
import {SearchStore} from '@search2/state';

export function userProfileGuard$(): Observable<boolean> {
	const userClient = inject(UserClient);
	const utilsService = inject(UtilsService);
	const cloudAuthService = inject(CloudAuthenticationService);
	const userStore = inject(UserStore);
	const searchStore = inject(SearchStore);

	return from(cloudAuthService.getApiAccessToken()).pipe(
		tap(() => {
			searchStore.loadPresetTree$();
		}),
		concatMap(() => combineLatest([userClient.getUserProfile()])),
		tap(([profile]) => {
			userClient.currentUserId.next(profile.userId);
		}),
		tap(([newProfile]) => {
			userStore.updateNewUserProfile(newProfile);
		}),
		map(() => true),
		catchError((error: unknown) => {
			utilsService.navigateToErrorPage(error as ApiException);

			return of(false);
		}),
	);
}
