<div class="json-preview-container">
	<pp-icon
		[name]="expanded ? 'minus' : 'plus'"
		aria-hidden="true"
		(click)="toggle()"
		[size]="12"
	></pp-icon>
	{{ isArray ? '[' : '{' }}
	@if (expanded) {
		<div class="json-preview-object-container">
			@for (item of data; track $index; let last = $last) {
				<div class="json-preview-pair">
					@if (!item.referenceType) {
						@if (!isArray) {
							<span class="json-preview-property">"{{ item.key }}": </span>
						}
						<span
							[ngClass]="{
								'json-preview-empty-value': item.emptyValue,
								'json-preview-value': !item.emptyValue
							}"
						>
							{{ item.value }}
							{{ last ? '' : ',' }}
						</span>
					} @else {
						@if (!isArray) {
							<span class="json-preview-property">"{{ item.key }}": </span>
						}
						<div class="json-preview-object-container">
							<shared-json-preview
								[object]="item.value"
								[last]="last"
							></shared-json-preview>
						</div>
					}
				</div>
			}
		</div>
	}
	{{ isArray ? ']' : '}' }}
	{{ last ? '' : ',' }}
</div>
