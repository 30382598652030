import {Injectable, Pipe, PipeTransform} from '@angular/core';

const KIBI = 1024;
const PRECISION = 0.1;

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'fileSize',
	standalone: true,
})
export class FileSizePipe implements PipeTransform {
	transform(bytes: number | undefined): string {
		if (!bytes) {
			return '';
		}

		let result: string;

		if (bytes / Math.pow(KIBI, 3) >= PRECISION) {
			result = `${(bytes / Math.pow(KIBI, 3)).toFixed(2)} GB`;
		} else if (bytes / Math.pow(KIBI, 2) >= PRECISION) {
			result = `${(bytes / Math.pow(KIBI, 2)).toFixed(2)} MB`;
		} else if (bytes / Math.pow(KIBI, 1) >= PRECISION) {
			result = `${(bytes / KIBI).toFixed(2)} KB`;
		} else {
			result = `${bytes} bytes`;
		}

		return result;
	}
}
