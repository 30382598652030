import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewChild,
	computed,
	effect,
	inject,
} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {Router} from '@angular/router';
import {NgClass, AsyncPipe} from '@angular/common';
import {Observable, debounce, interval, switchMap, of, map, tap} from 'rxjs';
import {HighlightPipe} from '../../../shared/pipes/highlight.pipe';
import {SearchItemCardComponent} from '../../../preview/item-card/item-card/search-item-card.component';
import {UseThemeDirective} from '@shared/directives/theme/use-theme.directive';
import {SvgIconComponent} from '@pp/svg';
import {
	OptionComponent,
	OptionSelectionChangedEvent,
} from '@shared/components/autocomplete/option/option.component';
import {AutocompleteComponent} from '@shared/components/autocomplete/autocomplete.component';

import {PpAutocompleteItem} from '@shared/api';
import {AnalyticsService} from '@shared/analytics';
import {SearchStateSerializerService} from '@search2/services/search-state-serializer.service';
import {SearchQueryState, SearchStore} from '@search2/state';
import {CardActionsService, DocumentItemActionService} from '@shared/card-actions';
import {SearchQueryService} from '@search2/services';

@Component({
	selector: 'search-suggestions',
	templateUrl: './search-suggestions.component.html',
	styleUrls: ['./search-suggestions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CardActionsService],
	standalone: true,
	imports: [
		UseThemeDirective,
		ReactiveFormsModule,
		OptionComponent,
		SvgIconComponent,
		NgClass,
		SearchItemCardComponent,
		AsyncPipe,
		HighlightPipe,
		AutocompleteComponent,
	],
})
export class SearchSuggestionComponent {
	@Input() useNavigation = false;

	@ViewChild('autocomplete', {read: AutocompleteComponent})
	readonly autocomplete!: AutocompleteComponent;

	searchQuery = new FormControl('');

	private searchStore = inject(SearchStore);
	presetId = computed(() => this.searchStore.currentPreset()?.id ?? '');
	presetName = computed(() => this.searchStore.currentPreset()?.name ?? '');

	suggestionByType$: Observable<SuggestionsByType> = this.searchQuery.valueChanges.pipe(
		debounce(() => interval(300)),
		switchMap((query) => {
			let suggestions$ = of([]) as Observable<PpAutocompleteItem[]>;
			const presetId = this.searchStore.searchQuery.presetId();

			if ((query?.length ?? 0) > 0) {
				suggestions$ = this.searchService.suggestions$(query, presetId);
			}

			return suggestions$.pipe(
				map((items) => (items.length ? items.map((item) => this.simplify(item)) : items)),
				map((suggestions) => ({
					history: suggestions.filter(
						(item) => item.type === 'history' && item.history?.text.rawValue !== query,
					),
					term: suggestions.filter((item) => item.type === 'term'),
					document: suggestions.filter((item) => item.type === 'document'),
				})),
			);
		}),
		tap(() => {
			// 1st item (full text search) is selected by default
			this.autocomplete.setActiveItem(0);
		}),
	);

	constructor(
		private readonly searchService: SearchQueryService,
		private readonly analytics: AnalyticsService,
		private readonly router: Router,
		private readonly stateSerializer: SearchStateSerializerService,
		private readonly actionService: DocumentItemActionService,
		private cardActionsService: CardActionsService,
	) {
		effect(() => {
			if (this.presetId() !== '') {
				this.autocomplete?.focusOnInput();
			}
		});
		effect(() => {
			const query = this.searchStore.searchQuery.textQuery();
			if (query !== this.searchQuery.value) {
				this.searchQuery.setValue(query);
			}
		});
	}

	private navigateToCatalogWithQuery(presetId: string, query: SearchQueryState): void {
		const serializedString = this.stateSerializer.serialize(query);

		this.router.navigate(['/p/', presetId], {
			queryParams: {o: serializedString},
		});
	}

	searchQueryValueChanged(value: string): void {
		if (this.useNavigation) {
			const presetId = this.presetId();
			this.navigateToCatalogWithQuery(presetId, {
				selectedFilters: {},
				textQuery: value,
				presetId,
				orderByExpression: undefined,
				duplicateGroupId: undefined,
			});
		} else {
			this.searchStore.changeSearchQueryText(value);
		}
	}

	selectSuggestion(event: OptionSelectionChangedEvent, item: PpAutocompleteItem): void {
		if (!event.isSelected) {
			return;
		}

		const presetId = this.presetId();
		switch (item.type) {
			case 'history':
				if (item.history) {
					this.analytics.trackDocumentCatalogAction(
						'suggestion:pick',
						`history:${item.history.type}`,
					);
					this.searchQueryValueChanged(item.history.text.rawValue);
				}
				break;
			case 'term':
				if (item.term) {
					if (this.useNavigation) {
						this.navigateToCatalogWithQuery(presetId, {
							selectedFilters: item.term?.filters,
							textQuery: '',
							presetId,
							orderByExpression: undefined,
							duplicateGroupId: undefined,
						});
					} else {
						Object.entries(item.term.filters).forEach(([fieldName, values]) => {
							values.forEach((fieldValue) => {
								this.analytics.trackDocumentCatalogAction(
									'suggestion:pick',
									`term:${fieldName}`,
								);
								this.searchStore.selectFilter(fieldName, fieldValue);
							});
						});
					}
				}
				break;
			case 'document':
				if (item.document) {
					this.analytics.trackDocumentCatalogAction('suggestion:pick', 'document');

					const actions = this.actionService.getActions(item.document.document);
					const primaryAction = this.actionService.getPrimaryAction(actions);

					this.cardActionsService.onAction(primaryAction, item.document.document);
				}
				break;
			default:
				break;
		}
	}

	simplify(item: PpAutocompleteItem): PpAutocompleteItem {
		if (item.document) {
			const document = item.document.document;
			if (document.spoPresales) {
				document.spoPresales.caseStudyCollection =
					document.spoPresales.caseStudyCollection.filter((x) => x.highlightedValue);
				document.spoPresales.tags = document.spoPresales.tags.filter(
					(x) => x.highlightedValue,
				);
			}
		}

		return item;
	}
}
interface SuggestionsByType {
	history: PpAutocompleteItem[];
	term: PpAutocompleteItem[];
	document: PpAutocompleteItem[];
}
