<div
	class="search-result-thumbnail-container"
	(mouseenter)="showOverlay()"
	(mouseleave)="hideOverlay()"
>
	<pp-image
		[name]="'logo_' + (result | fileType) + '_64'"
		[width]="64"
		[height]="64"
		[class.opacity]="
			result.thumbnail && (result.fileSize ?? 0) >= fileSizeLimit && !forceLoadThumbnail
		"
		class="search-result-thumbnail-file-image"
	/>
	@if ((result.fileSize ?? 0) < fileSizeLimit || forceLoadThumbnail) {
		<shared-thumbnail
			[src]="result.thumbnail"
			[external]="result.externalThumbnail!"
			(finishLoad)="thumbnailPreviewLoaded()"
		></shared-thumbnail>
	}

	<div
		class="search-result-thumbnail-flag-list"
		[ngClass]="{'search-result-thumbnail-flag-list-hover': overlayVisible}"
	>
		@if (isVerified) {
			<pp-flag class="search-result-thumbnail-flag" type="verified"></pp-flag>
		}
		@if (hasDEP) {
			<pp-flag class="search-result-thumbnail-flag" type="dep"></pp-flag>
		}
	</div>
	<div class="search-result-thumbnail-info-box">
		@if (result.thumbnail && (result.fileSize ?? 0) >= fileSizeLimit) {
			<div class="search-result-thumbnail-preview-loader">
				@if (!forceLoadThumbnail) {
					<pp-icon [size]="24" name="cloud-download-24" />
				}
				@if (forceLoadThumbnail && !thumbnailLoaded) {
					<pp-spinner size="small" type="uui"></pp-spinner>
				}
			</div>
		}
		@if (result.slidesCount) {
			<div class="search-result-thumbnail-slides-count">
				{{ result.slidesCount }} {{ result.slidesCount === 1 ? 'Slide' : 'Slides' }}
			</div>
		}
	</div>
	@if (overlayVisible) {
		<shared-result-overlay
			[result]="result"
			(triggerActionEmitter)="triggerAction($event)"
		></shared-result-overlay>
	}
</div>
