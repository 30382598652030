<div class="secondary-actions">
	@for (action of secondary; track $index) {
		<ng-template [ngTemplateOutlet]="action.template"></ng-template>
	}
</div>
<div
	class="primary-action"
	[ngClass]="{
		'primary-action-24': size === 'normal',
		'primary-action-18': size === 'medium'
	}"
>
	<ng-template [ngTemplateOutlet]="primary.template"></ng-template>
</div>
