import * as actions from '../../actions';
import {filtersInitialState} from './filters-initial-state';
import {FiltersState} from './filters-state';

export function reducer(
	state: FiltersState = filtersInitialState,
	action: actions.FiltersActions,
): FiltersState {
	switch (action.type) {
		case actions.UPDATE_LANGUAGES_FILTER:
			return {
				...state,
				languages: action.payload,
			};
		case actions.UPDATE_DOCUMENT_TAGS_FILTER:
			return {
				...state,
				documentTags: action.payload,
			};
		case actions.UPDATE_SOURCE_SYSTEM_FILTER:
			return {
				...state,
				sourceSystem: action.payload,
			};
		case actions.UPDATE_LOCATION_FILTER:
			return {
				...state,
				locationFilterTree: action.payload,
			};
		case actions.UPDATE_CONTENT_OWNER_FILTER:
			return {
				...state,
				contentOwner: action.payload,
			};
		case actions.UPDATE_PREPARED_FOR_FILTER:
			return {
				...state,
				preparedFor: action.payload,
			};
		case actions.UPDATE_ABOUT_FILTER:
			return {
				...state,
				about: action.payload,
			};
		case actions.UPDATE_FILE_CONTENT_TYPE_FILTER:
			return {
				...state,
				fileContentType: action.payload,
			};
		case actions.UPDATE_FILE_TYPE_FILTER:
			return {
				...state,
				fileType: action.payload,
			};
		case actions.UPDATE_SIZE_FILTER:
			return {
				...state,
				size: action.payload,
			};
		case actions.UPDATE_DISPLAY_AUTHOR_FILTER:
			return {
				...state,
				displayAuthor: action.payload,
			};
		case actions.UPDATE_TAGS_FILTER:
			return {
				...state,
				tags: action.payload,
			};
		case actions.UPDATE_MODIFIED_DATE_FILTER:
			return {
				...state,
				modifiedDate: action.payload,
			};
		case actions.UPDATE_ACCESS_LEVEL_FILTER:
			return {
				...state,
				accessLevel: action.payload,
			};
		case actions.UPDATE_MASTER_LAYOUT_FILTER:
			return {
				...state,
				masterLayout: action.payload,
			};
		case actions.RESET_FILTERS:
			return action.payload
				? {
						...state,
						...action.payload.reduce(
							(filters, refiner) => ({
								...filters,
								[refiner]:
									filtersInitialState[
										refiner as keyof typeof filtersInitialState
									],
							}),
							{},
						),
					}
				: {
						...filtersInitialState,
					};
		default:
			return state;
	}
}
