import {OverlayRef} from '@angular/cdk/overlay';
import {fromEvent, Subject, Subscription} from 'rxjs';
import {filter, take, tap} from 'rxjs/operators';

export class ModalRef {
	private readonly subscriptions = new Subscription();

	readonly closed$ = new Subject<void>();

	constructor(private readonly overlayRef: OverlayRef) {
		this.subscriptions.add(
			this.overlayRef
				.detachments()
				.pipe(tap(() => this.overlayRef.dispose()))
				.subscribe(),
		);
		this.subscriptions.add(
			this.overlayRef
				.keydownEvents()
				.pipe(
					filter((event: KeyboardEvent) => event.code === 'Escape'),
					tap(() => this.close()),
				)
				.subscribe(),
		);
		this.subscriptions.add(
			fromEvent<MouseEvent>(this.overlayRef.hostElement, 'click')
				.pipe(
					filter(
						(event: MouseEvent) =>
							event.target === this.overlayRef.hostElement ||
							event.target === this.overlayRef.hostElement.firstChild,
					),
					take(1),
					tap(() => this.close()),
				)
				.subscribe(),
		);
		this.subscriptions.add(
			this.overlayRef
				.backdropClick()
				.pipe(tap(() => this.close()))
				.subscribe(),
		);
	}

	close(): void {
		// Close event should be fires before overlay detach()
		// Modal dialog may listen for close event (like Escape / Click) and react
		this.closed$.next();
		this.closed$.complete();

		this.overlayRef.detachBackdrop();
		this.overlayRef.detach();

		this.subscriptions.unsubscribe();
	}
}
