import {Injectable} from '@angular/core';
import {FilterModel} from '@search/models';
import {tagsOrder, FilterService} from '@search/services';
import {HexDecodePipe} from '@shared/pipes';
import {Refinement} from '@shared/services';

@Injectable({providedIn: 'root'})
export class AccessLevelFilterService implements FilterService {
	constructor(private hexDecode: HexDecodePipe) {}

	convert(data: Refinement[]): FilterModel[] {
		const result = data.map((refinement, index) => ({
			token: refinement.RefinementToken,
			name: this.prepareName(refinement.RefinementToken),
			value: refinement.RefinementValue,
			count: +refinement.RefinementCount,
			order: index,
		}));
		const order: {[property: string]: number} = {};
		const maxWeight = 999;

		tagsOrder.forEach((tag, index) => (order[tag] = index + 1));
		result.sort((a, b) => (order[a.name] || maxWeight) - (order[b.name] || maxWeight));

		return result;
	}

	prepareName(token: string): string {
		return this.hexDecode.transform(token);
	}
}
