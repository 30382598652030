@if (vm$ | async; as slideInfos) {
	<div [ppCdkUseTheme]="theme()" class="page-container">
		@for (slideInfo of slideInfos; track slideInfo.slideId) {
			<div>
				<h5>Slide Id: {{ slideInfo.slideId }}</h5>
				@if (slideInfo.item) {
					<div>
						<search-item-card
							[item]="slideInfo.item"
							size="normal"
							direction="vertical"
							[lightMode]="true"
						/>
					</div>
				} @else {
					<p>{{ slideInfo.message }}</p>
				}
			</div>
		}
	</div>
}
