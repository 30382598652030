import {Action} from '@ngrx/store';

import {SearchResultsModel} from '@shared/models';

export const UPDATE_RESULTS = '[Search] Update Results';

export class UpdateResultsAction implements Action {
	readonly type = UPDATE_RESULTS;

	constructor(public payload: SearchResultsModel) {}
}
