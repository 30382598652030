import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'abbreviation',
	standalone: true,
})
export class AbbreviationPipe implements PipeTransform {
	transform(label: string): string {
		const phraseArray = label.split(' ');
		if (phraseArray.length > 1) {
			return phraseArray[0][0] + phraseArray[1][0];
		} else {
			return label.slice(0, 3);
		}
	}
}
