import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {ChainComponent} from '../chain.component';
import {CollapseComponent} from '../collapse/collapse.component';

@Directive({
	selector: '[collapse]',
	standalone: true,
})
export class CollapseDirective {
	@Input() set collapse(conditional: boolean) {
		if (conditional) {
			const viewRef = this.viewContainer.createComponent(CollapseComponent);
			viewRef.instance.chainTemplate = this.template;
		} else {
			this.viewContainer.createEmbeddedView(this.template);
		}
	}

	constructor(
		private readonly template: TemplateRef<ChainComponent>,
		private readonly viewContainer: ViewContainerRef,
	) {}
}
