import {ChangeDetectionStrategy, Component, ViewEncapsulation, inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {AsyncPipe} from '@angular/common';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import {ToggleQueryModificationAction, ToggleQueryTemplateAction} from '@search/actions';
import {SearchFeatureState} from '@search/reducers/search-state';
import {CheckboxComponent} from '@pp/checkbox';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'search-query-modification',
	templateUrl: './query-modification.component.html',
	styleUrls: ['./query-modification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CdkDrag, CdkDragHandle, SvgIconComponent, CheckboxComponent, AsyncPipe],
})
export class QueryModificationComponent {
	private readonly store$ = inject(Store<SearchFeatureState>);

	query$: Observable<string | undefined> = this.store$.select(
		(state) => state.search.results.queryModification,
	);
	visible$: Observable<boolean> = this.store$.select(
		(state) => state.search.ui.queryModificationVisible,
	);
	disableQueryTemplate$: Observable<boolean> = this.store$.select(
		(state) => state.search.query.disableQueryTemplate,
	);

	close(): void {
		this.store$.dispatch(new ToggleQueryModificationAction(false));
	}

	toggleQueryTemplate(target: EventTarget | null): void {
		const value = (target as HTMLInputElement).checked;
		this.store$.dispatch(new ToggleQueryTemplateAction(value));
	}
}
