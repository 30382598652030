/// <reference types="@types/gtag.js" />
import {Injectable} from '@angular/core';
import {ApmBase} from '@elastic/apm-rum';
import {BrowserDetectorService} from '../services/browser-detector/browser-detector.service';
import {FilterSectionNamePipe} from '../pipes';

import {OfficeInfo} from '@office/state';
import {ApiException} from '@shared/api';

export type EventCategories =
	// Shared + Technical
	| 'Header'
	| 'Dashboard'
	| 'Agreement'
	| 'OutdatedBrowser'
	| 'NetworkIssue'
	| 'Technical'
	| 'Navigation'
	// Search by Preset
	| 'Selection'
	| 'Deck Builder'
	| 'Document Catalog'
	| 'Document Preview'
	| 'Search Filters'
	// Decommission (Phase 1)
	| 'Stories'
	| 'Story'
	| 'ToolBox'
	| 'CaseStudies'
	// Decommission (Phase 2)
	| 'Search results'
	| 'SearchBox'
	| 'SearchQueryTips'
	| 'Preview| OOS'
	| 'Panels' // ??
	| 'User Panel'
	| 'ExtendedInfo'
	| 'QuickEdit'
	| 'Featured Content'
	| 'InformativeBanner'
	| 'PowerPointHome'
	| 'PowerPointTemplates'
	| 'PowerReusableSlidesTemplates';
export type FilterAction =
	| 'filter'
	| 'exclude'
	| 'reset'
	| 'expand'
	| 'collapse'
	| 'showMore'
	| 'showLess'
	| 'slide'
	| 'bar';
type PreviewAction =
	| 'open'
	| 'close'
	| 'openContext'
	| 'selectSourceDocumentTab'
	| 'selectSmartContextTab'
	| 'selectSourceSlide'
	| 'selectSmartContextSlide'
	| 'updateSmartContext'
	| 'toPrevDoc'
	| 'toNextDoc'
	| 'previewTime'
	| 'copyLink';
type SearchResultsAction =
	| 'sortBy'
	| 'changeViewMode'
	| 'duplicates'
	| 'duplicatesExit'
	| 'requestThumbnail'
	| 'download'
	| 'downloadSourceDocument'
	| 'copyLink'
	| 'openCrmAccount'
	| 'storiesAdNavigation'
	| 'storiesDownload'
	| 'showMorePermissionWarning'
	| 'closePermissionWarning'
	| 'openSharedWith'
	| 'openExternal'
	| 'reset';
type SearchBoxAction = 'changeQuery';
type PanelAction = 'searchHistoryClick';
type PanelsAction = 'open' | 'close';
type UserPanelAction =
	| 'navigate'
	| 'expandDuplicates'
	| 'changeLanding'
	| 'saveFiltersSettings'
	| 'resetFiltersSettings';
type ExtendedInfoAction = 'open' | 'close';
type QuickEditAction = 'open' | 'updateCvTag' | 'updateCaseStudyTag';
type StoriesAction =
	| 'taxonomyNavigation'
	| 'editStory'
	| 'download'
	| 'filter'
	| 'filterNoData'
	| 'search'
	| 'bannerNavigation'
	| 'suggestionNavigation'
	| 'contactOwner'
	| 'showTaxonomyMap'
	| 'backToAllStories'
	| 'searchInSlides'
	| 'suggestionDownload'
	| 'bookmark'
	| 'videoPreview'
	| 'preview'
	| 'subheader'
	| 'openRecommendation';
type StoryAction =
	| 'download'
	| 'contactOwner'
	| 'openRelated'
	| 'editExperts'
	| 'saveExperts'
	| 'selectTag'
	| 'caseStudyDownload'
	| 'selectCaseStudiesCategory'
	| 'selectCaseStudiesIndustry'
	| 'selectCaseStudy'
	| 'editLinks'
	| 'saveLinks'
	| 'openLink'
	| 'copyLink'
	| 'expandPreview'
	| 'collapsePreview'
	| 'proposeChanges'
	| 'openRecommendation';
type DashboardAction =
	| 'preview'
	| 'scroll'
	| 'download'
	| 'showMore'
	| 'sitemapNavigation'
	| 'search'
	| 'trendClick'
	| 'productNews';
type SearchQueryTipsAction = 'open' | 'keywords' | 'samples';
type AgreementAction = 'load' | 'confirm';
type CasesAction =
	| 'addFilter'
	| 'filter'
	| 'filterNoData'
	| 'editCase'
	| 'removeFilter'
	| 'resetFilters'
	| 'selectChips'
	| 'selectTag'
	| 'selectAccount'
	| 'selectSuggestion'
	| 'download'
	| 'preview'
	| 'navigateProject'
	| 'navigateAccount'
	| 'navigateCrm'
	| 'navigateSimilarCase'
	| 'backToSearchResults'
	| 'proposeChanges'
	| 'navigatePerson'
	| 'selectProject'
	| 'bookmark'
	| 'search'
	| 'resetSearch'
	| 'filterSuggestions'
	| 'dashboardBannerNavigation'
	| 'editLinks'
	| 'saveLinks'
	| 'editExperts'
	| 'openLink'
	| 'contactTeam'
	| 'copyLink'
	| 'expandPreview'
	| 'collapsePreview'
	| 'subheader'
	| 'openRecommendation';
export type DeckBuilderAction =
	| 'add'
	| 'addAll'
	| 'remove'
	| 'removeSection'
	| 'removeAll'
	| 'dropItem'
	| 'unselectAll'
	| 'error'
	| 'open'
	| 'build'
	| 'reset'
	| 'updateStatus';
type ToolBoxAction =
	| 'bookmark'
	| 'filter'
	| 'filterNoData'
	| 'suggestionNavigation'
	| 'search'
	| 'resetSearch'
	| 'addFilter'
	| 'resetFilter'
	| 'resetFilters'
	| 'removeFilter'
	| 'contactOwner'
	| 'proposeChanges'
	| 'download'
	| 'preview'
	| 'openLink'
	| 'editToolBox'
	| 'subheader'
	| 'copyLink';
export type DocumentCatalogAction =
	| 'preset:request'
	| 'search:run'
	| 'search:order-by'
	| 'search:no-results'
	| 'search:load-more'
	| 'filter:select'
	| 'filter:remove'
	| 'filter:reset'
	| 'filter:toggle-show-all'
	| 'filter:toggle-expand'
	| 'filter:search'
	| 'suggestion:pick'
	| 'open:link'
	| 'open:back-to-catalog';
type DocumentPreviewAction =
	| 'open:preview'
	| 'open:show-slides'
	| 'toggle:expand-mode'
	| 'open:link'
	| 'open:cs-catalog'
	| 'open:next-prev'
	| 'linked-document:not-found';
export type SelectionAction =
	| 'select'
	| 'unselect'
	| 'changeOrder'
	| 'state:restore'
	| 'state:reset'
	| 'state:lock';
type NavigationAction = 'navigate:space' | 'navigate:scope' | 'navigate:external-link';
export type InformativeBannerAction = 'readMore' | 'openVideo' | 'openVideo:first-visit';

@Injectable({providedIn: 'root'})
export class AnalyticsService {
	constructor(
		private filterSectionName: FilterSectionNamePipe,
		private browserDetector: BrowserDetectorService,
	) {}

	private apm?: ApmBase;

	setUserContext(userId: string, employeeId: string, currentUserEmail: string): void {
		const enableAnalyticsLogging =
			location.hostname !== 'localhost' &&
			!this.browserDetector.isE2eInstance() &&
			!this.isKnownAutoUser(currentUserEmail);

		if (enableAnalyticsLogging) {
			gtag('set', {
				user_id: userId,
				employeeId,
			});

			if (this.apm) {
				this.apm.setUserContext({
					username: userId,
					id: employeeId,
				});
			}
		} else {
			this.mockGaCalls();
		}
	}

	private isKnownAutoUser(userId: string): boolean {
		return (
			userId === 'auto_kmc@.com' ||
			userId === 'auto_epm-isrd_sdp_check@.com' ||
			userId === 'auto_qualys_scan@.com' ||
			userId.startsWith('auto_epm-ssdl')
		);
	}

	private mockGaCalls(): void {
		gtag = console.warn.bind(window, 'GA: ');
	}

	setOfficeLabels(officeInfo: OfficeInfo): void {
		const labels = {
			office_host: officeInfo.host!,
			office_platform: officeInfo.platform!,
			office_version: officeInfo.version!,
		};

		this.apm?.addLabels(labels);
		gtag('set', labels);
	}

	setSizeLabels(width: number, height: number): void {
		const labels = {
			screen_width: width,
			screen_height: height,
		};

		this.apm?.addLabels(labels);
	}

	sendEvent(category: EventCategories, action: string, label?: string, value?: number): void {
		gtag('event', action, {
			event_category: category,
			event_label: label,
			value,
		});
	}

	trackException(message: string, fatal = false): void {
		console.error(`[Exception]: ${message}`);

		gtag('event', 'exception', {
			description: message,
			fatal,
		});

		if (this.apm) {
			this.apm.captureError(message);
		}
	}

	trackError(error: unknown, userErrorMessage?: string): void {
		let message = 'Unexpected error';

		if (error instanceof Error) {
			message = error.stack
				? `Error: ${error.message}. Stack: ${error.stack}`
				: `Error: ${error.message}`;
		} else if (error instanceof ApiException) {
			message = `ApiException: ${error.message}`;
		} else if (typeof error === 'string') {
			message = `Unexpected error '${error}'`;
		}

		if (userErrorMessage) {
			message += ` User message: ${userErrorMessage}`;
		}

		this.trackException(message, false);
	}

	trackPageNavigation(url: string): void {
		gtag('event', 'page_view', {page_path: url});
	}

	trackHeaderActions(action: string, label?: string): void {
		this.sendEvent('Header', action, label);
	}

	trackFilterAction(action: FilterAction, filterName: string, filterValue?: string): void {
		gtag('event', action, {
			event_category: `Refinement| ${
				this.filterSectionName.transform(filterName) || filterName
			}`,
			event_label: filterValue,
		});
	}

	trackPreviewAction(action: PreviewAction, label?: string, value?: number): void {
		this.sendEvent('Preview| OOS', action, label, value);
	}

	trackSearchResultsAction(action: SearchResultsAction, label?: string): void {
		this.sendEvent('Search results', action, label);
	}

	trackFeaturedContentAction(action: string, label?: string): void {
		this.sendEvent('Featured Content', action, label);
	}

	trackStoriesAction(action: StoriesAction, label?: string, value?: number): void {
		this.sendEvent('Stories', action, label, value);
	}

	trackStoryAction(action: StoryAction, label?: string): void {
		this.sendEvent('Story', action, label);
	}

	trackDashboardAction(action: DashboardAction, label?: string): void {
		this.sendEvent('Dashboard', action, label);
	}

	trackSearchBoxAction(action: SearchBoxAction, label?: string, value?: number): void {
		this.sendEvent('SearchBox', action, label, value);
	}

	trackPanelActions(action: PanelAction, panelName: string): void {
		gtag('event', action, {event_category: `Panel| ${panelName}`});
	}

	trackPanelsActions(action: PanelsAction, panelName: string): void {
		this.sendEvent('Panels', action, panelName);
	}

	trackUserPanelActions(action: UserPanelAction, link?: string): void {
		this.sendEvent('User Panel', action, link);
	}

	trackExtendedInfoActions(action: ExtendedInfoAction): void {
		this.sendEvent('ExtendedInfo', action);
	}

	trackQuickEditActions(action: QuickEditAction): void {
		this.sendEvent('QuickEdit', action);
	}

	trackSearchQueryTipsActions(action: SearchQueryTipsAction, tip?: string): void {
		this.sendEvent('SearchQueryTips', action, tip);
	}

	trackOutdatedBrowsers(userAgent: string): void {
		this.sendEvent('OutdatedBrowser', '', userAgent);
	}

	trackAgreementActions(action: AgreementAction): void {
		this.sendEvent('Agreement', action);
	}

	trackCasesActions(action: CasesAction, label?: string, value?: number): void {
		this.sendEvent('CaseStudies', action, label, value);
	}

	trackToolBoxAction(action: ToolBoxAction, label?: string, value?: number): void {
		this.sendEvent('ToolBox', action, label, value);
	}

	trackBackOnline(timeInOffline = 0): void {
		this.sendEvent('NetworkIssue', 'BackOnline', undefined, timeInOffline);
	}

	trackSiteSearch(query: string) {
		gtag('event', 'search', {
			search_term: query,
		});
	}

	trackDeckBuilderAction(action: DeckBuilderAction, label?: string, value?: number): void {
		this.sendEvent('Deck Builder', action, label, value);
	}

	trackDocumentCatalogAction(action: DocumentCatalogAction, label?: string): void {
		this.sendEvent('Document Catalog', action, label);
	}

	trackDocumentPreviewAction(action: DocumentPreviewAction, label?: string): void {
		this.sendEvent('Document Preview', action, label);
	}

	trackSelectionAction(action: SelectionAction, label?: string): void {
		this.sendEvent('Selection', action, label);
	}

	trackNavigationAction(action: NavigationAction, label?: string): void {
		this.sendEvent('Navigation', action, label);
	}

	trackInformativeBannerAction(action: InformativeBannerAction, label: string): void {
		this.sendEvent('InformativeBanner', action, label);
	}

	trackErrorPage(message: string): void {
		this.apm?.captureError(`ErrorPage: ${message}`);
	}
}
