import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {AsyncPipe} from '@angular/common';
import {ReplaceIncludeFilterAction} from '../../actions';
import {SearchFeatureState} from '../../reducers/search-state';
import {FilterModel} from '@search/models';
import {RangeModel} from '@shared/models';
import {AnalyticsService, FilterAction} from '@shared/analytics';
import {SearchService} from '@search/services';
import {formatRange} from '@shared/utils';
import {UseThemeDirective} from '@shared/directives';
import {FormatterService} from '@shared/services';
import {RangeSliderComponent} from '@shared/components/range-slider/range-slider.component';

@Component({
	selector: 'search-filter-histogram',
	templateUrl: './search-filter-histogram.component.html',
	styleUrls: ['./search-filter-histogram.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [RangeSliderComponent, UseThemeDirective, AsyncPipe],
})
export class SearchFilterHistogramComponent {
	private readonly rangeRegex = /^range\((.*)\)$/;
	private itemsCount = 0;

	items$: Observable<FilterModel[]> = this.store$
		.select(
			(state) =>
				state.search.filters[
					this.filterName as keyof typeof state.search.filters
				] as FilterModel[],
		)
		.pipe(
			map((items) =>
				items.map((item) => {
					const regexResult = this.rangeRegex.exec(item.token);
					const [left, right] = regexResult ? regexResult[1].split(', ') : ['0', '0'];

					return {
						...item,
						name: '',
						labels: [
							this.formatter.format(left, this.filterName),
							this.formatter.format(right, this.filterName),
						],
						range: [left, right],
					};
				}),
			),
		);
	range$: Observable<RangeModel> = combineLatest([
		this.items$.pipe(tap((item) => (this.itemsCount = item.length))),
		this.store$
			.select(
				(state) =>
					state.search.selectedFilters.include[
						this.filterName as keyof typeof state.search.selectedFilters.include
					],
			)
			.pipe(
				map((filters) => (filters.length === 1 ? filters[0].split(',').map(Number) : [])),
				map(([from = 0, to = -1]) => ({from, to})),
			),
	]).pipe(
		tap(([items, range]) => {
			this.setHistogramLabels(
				items[range.from].labels![0],
				items[range.to === -1 ? items.length - 1 : range.to - 1].labels?.[1] ?? '',
			);
		}),
		map(([items, range]) => ({...range, to: range.to === -1 ? items.length : range.to})),
	);

	@Input({required: true}) filterName!: string;

	constructor(
		private store$: Store<SearchFeatureState>,
		private formatter: FormatterService,
		private analytics: AnalyticsService,
		private searchService: SearchService,
	) {}

	onRangeChange(range: RangeModel, bars: FilterModel[]): void {
		const filter: FilterModel | undefined =
			range.from === 0 && range.to === this.itemsCount
				? undefined
				: {token: `${range.from},${range.to}`, name: ''};

		this.setHistogramLabels(
			bars[range.from].labels?.[0] ?? '',
			bars[range.to - 1].labels?.[1] ?? '',
		);

		this.store$.dispatch(
			new ReplaceIncludeFilterAction({
				filter,
				type: this.filterName,
			}),
		);
	}

	onAnalytics({target, value}: {target: FilterAction; value: string}): void {
		this.analytics.trackFilterAction(target, this.filterName, value);
	}

	private setHistogramLabels(leftLabel: string, rightLabel: string) {
		this.searchService.histogramLabels.set(
			this.filterName,
			formatRange(leftLabel, rightLabel, this.filterName),
		);
	}
}
