@if (isDownloadOptionsExists()) {
	<button
		class="dp-button action-download"
		type="button"
		[hint]="'Download options'"
		hintPosition="BOTTOM"
		(click)="openDownloadOptions($event)"
	>
		<pp-icon name="file-download-18"></pp-icon>
		Download options
	</button>
} @else {
	<button
		class="dp-button action-download"
		type="button"
		[hint]="downloadHintDescription()"
		[class.warning]="!isVerified"
		(click)="download($event)"
		hintPosition="BOTTOM"
	>
		<pp-icon name="file-download-18"></pp-icon>
		Download
	</button>
}

<button
	btn
	btnType="secondary"
	withIconBefore="copy-link-18"
	type="button"
	hint="Copy Link"
	hintPosition="BOTTOM"
	[cdkCopyToClipboard]="copyUrl()"
	(cdkCopyToClipboardCopied)="handleCopiedLink($event)"
>
	Copy Link
</button>

<shared-dropdown
	class="cloud-actions-action"
	type="secondary"
	icon="navigation-more_vert-18"
	[iconOnly]="true"
	[showChevron]="false"
	openStrategy="click"
>
	<ng-template #dropdownContent>
		<shared-card-menu-actions
			[item]="document()"
			[actionsToExclude]="['download', 'copy-link', 'preview']"
		></shared-card-menu-actions>
	</ng-template>
</shared-dropdown>
