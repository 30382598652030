import {Action} from '@ngrx/store';

import {TreeFilterModel} from '@search/models';

export const UPDATE_MASTER_LAYOUT_FILTER = '[Search] Update Master Layout Filter';

export class UpdateMasterLayoutFilterAction implements Action {
	readonly type = UPDATE_MASTER_LAYOUT_FILTER;

	constructor(public payload: TreeFilterModel) {}
}
