import {Action} from '@ngrx/store';

import {SearchResultsModel} from '@shared/models';

export const APPEND_RESULTS = '[Search] Append Results';

export class AppendResultsAction implements Action {
	readonly type = APPEND_RESULTS;

	constructor(public payload: SearchResultsModel) {}
}
