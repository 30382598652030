import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NewsCardsComponent} from '../news-cards/news-cards.component';
import {NewsCardsService} from '@dashboard/services';
import {AnalyticsService} from '@shared/analytics';
import {SvgIconComponent} from '@pp/svg';
import {CardsSwiperComponent} from '@shared/card-containers';

@Component({
	selector: 'dashboard-news-section',
	standalone: true,
	imports: [SvgIconComponent, NewsCardsComponent, CardsSwiperComponent],
	templateUrl: './news-section.component.html',
	styleUrls: ['./news-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsSectionComponent {
	productNews = this.newsCardsService.productNews;

	constructor(
		private newsCardsService: NewsCardsService,
		private readonly analytics: AnalyticsService,
	) {}

	newsCardsEventListener(eventName: string) {
		this.newsCardsService.setCardEventListener(eventName);
		this.analytics.trackDashboardAction('productNews', eventName);
	}

	trackShowMore(title: string): void {
		this.analytics.trackDashboardAction('showMore', title);
	}
}
