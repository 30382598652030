@if (isLoading()) {
	<pp-skeleton type="slides"></pp-skeleton>
} @else {
	<div class="preview-slides-container">
		<div class="preview-slides-header">Slides from</div>
		<div #cardList>
			<shared-card-list
				#cardListRef
				[list]="[item()]"
				[containerRef]="cardList"
				[hasSeparator]="false"
				[viewBehaviour]="['small', 'medium', 'normal']"
				[breakpoints]="[
					'(max-width: 399px)',
					'(min-width: 400px) and (max-width: 749px)',
					'(min-width: 750px)'
				]"
				class="source-card"
			>
				<ng-template #cardTemplate let-item>
					<search-item-card
						[item]="item"
						[size]="cardListRef.size()"
						[direction]="'horizontal'"
						[showActions]="cardListRef.size() !== 'small'"
						[lightMode]="cardListRef.size() === 'small'"
					>
					</search-item-card>
				</ng-template>
			</shared-card-list>
		</div>
	</div>

	<div class="preview-slides-divider"></div>

	<div class="preview-slides-container">
		<div class="preview-slides-header">Content</div>
		@for (section of sections(); track section.name) {
			<search-preview-slides-section
				[section]="section"
				[item]="item()"
				[slidesInOrder]="slidesInOrder()"
				[selectedSlideId]="selectedSlideId()"
				[expandedByDefault]="expanded() || section.hasSelectedSlide"
			></search-preview-slides-section>
		}
	</div>
}
