import {patchState, signalStore, withComputed, withMethods, withState} from '@ngrx/signals';
import {computed, inject} from '@angular/core';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import {Observable, pipe, switchMap, tap} from 'rxjs';
import {tapResponse} from '@ngrx/operators';
import {
	ApiException,
	DocAttachment,
	DocContactType,
	DocDetailsItem,
	DocLink,
	DocumentIndexItem,
	DocumentItemParserService,
	DocumentsClient,
	ListContactsResult,
	UsppUserInfo,
} from '@shared/api';
import {UtilsService} from '@shared/services';
import {ToastStore} from '@shared/state';
import {RelatedDocumentsService, RelatedDocumentsSet} from '@search2/services';
import {AnalyticsService} from '@shared/analytics';

export interface PreviewRequest {
	documentId: string;
	presetId?: string;
	query?: string;
	context: DocumentIndexItem[];
}

export interface PreviewState {
	documentId: string;
	context: DocumentIndexItem[];

	status: LoadingStatus;
	docDetails: DocDetailsItem;
	relatedDocuments: RelatedDocumentsSet;

	contactsStatus: LoadingStatus;
	contacts: ListContactsResult;

	linkedDocumentsStatus: LoadingStatus;
	linkedDocuments: DocAttachment[];

	linksStatus: LoadingStatus;
	links: DocLink[];
}

export type LoadingStatus = 'loading' | 'loaded';
export const nullDocument: DocumentIndexItem = {
	id: 'undefined',
	isPublic: false,
	canEdit: false,
	title: {rawValue: ''},
	description: {rawValue: ''},
	type: 'zip',
	downloadCount: 0,
	previewCount: 0,
	entityType: [],
	extraTags: [],
	owners: [],
};

const previewInitialState: PreviewState = {
	documentId: '',
	context: [],

	status: 'loading',
	docDetails: {
		document: {
			id: 'undefined',
			isPublic: false,
			canEdit: false,
			title: {rawValue: ''},
			description: {rawValue: ''},
			type: 'zip',
			downloadCount: 0,
			previewCount: 0,
			entityType: [],
			extraTags: [],
			owners: [],
		},
		crmAccounts: [],
		documentPreviewUrl: undefined,
		isPersonalizedPreviewUrl: false,
		sourceDocument: undefined,
		recommendations: [],
	},
	relatedDocuments: {
		relatedStories: {
			items: [],
			totalCount: 0,
		},
		relatedOfferings: {
			items: [],
			totalCount: 0,
		},
		recommendationIds: [],
	},

	contactsStatus: 'loading',
	contacts: {
		additionalContacts: {canManage: false, items: []},
		owners: {canManage: false, items: []},
	},

	linkedDocumentsStatus: 'loading',
	linkedDocuments: [],

	linksStatus: 'loading',
	links: [],
};

export const PreviewStore = signalStore(
	{providedIn: 'root'},
	withState(previewInitialState),
	withComputed((store) => {
		const searchResultParser = inject(DocumentItemParserService);
		const documentIndex = computed(() => {
			const documentId = store.documentId();

			return store.context().findIndex((document) => document.id === documentId);
		});

		return {
			docType: computed(() => {
				const details = store.docDetails();

				return details ? searchResultParser.getItemType(details.document) : 'document';
			}),
			prevDocument: computed(() => {
				const index = documentIndex();

				return index > 0 ? store.context()[index - 1] : undefined;
			}),
			nextDocument: computed(() => {
				const index = documentIndex();
				const items = store.context();

				return index + 1 < items.length ? items[index + 1] : undefined;
			}),
		};
	}),
	// Methods for managing contacts
	withMethods((store) => {
		const documentsClient = inject(DocumentsClient);
		const analytics = inject(AnalyticsService);
		const utilsService = inject(UtilsService);
		const toastStore = inject(ToastStore);

		function handleResponse<T>(
			documentId: string,
			errorMessage: string,
		): (source: Observable<T>) => Observable<T> {
			return tapResponse({
				next: () => {
					methods.loadContacts(documentId);
				},
				error: (error) => {
					toastStore.addToastMessage({
						type: 'error',
						message: errorMessage,
					});
					analytics.trackError(error, errorMessage);
				},
			});
		}

		function patchContacts(
			contactType: DocContactType,
			operation: (contacts: UsppUserInfo[]) => UsppUserInfo[],
		) {
			patchState(store, (state) => {
				const fieldName = contactType === 'owners' ? 'owners' : 'additionalContacts';
				const contacts = operation(state.contacts[fieldName].items);

				return {
					contacts: {
						...state.contacts,
						[fieldName]: {
							...state.contacts[fieldName],
							items: contacts,
						},
					},
				};
			});
		}

		const methods = {
			loadContacts: rxMethod<string>(
				pipe(
					tap(() => {
						patchState(store, {contactsStatus: 'loading'});
					}),
					switchMap((documentId) =>
						documentsClient.listContacts(documentId).pipe(
							tapResponse({
								next: (result) => {
									patchState(store, {
										contactsStatus: 'loaded',
										contacts: result,
									});
								},
								error: (error) => {
									const e = error as ApiException;
									analytics.trackError(e.message);
									utilsService.navigateToErrorPage(e);
								},
							}),
						),
					),
				),
			),
			addContact: rxMethod<{
				documentId: string;
				contactType: DocContactType;
				contact: UsppUserInfo;
			}>(
				pipe(
					tap((request) => {
						patchContacts(request.contactType, (contacts) => [
							...contacts,
							request.contact,
						]);
					}),
					switchMap((request) =>
						documentsClient
							.createContact(request.documentId, {
								aadId: request.contact.aadId ?? '',
								contactType: request.contactType,
								expertise: undefined,
							})
							.pipe(
								handleResponse(request.documentId, 'Contact has not been added!'),
							),
					),
				),
			),
			removeContact: rxMethod<{
				documentId: string;
				contactType: DocContactType;
				contact: UsppUserInfo;
			}>(
				pipe(
					tap((request) => {
						patchContacts(request.contactType, (contacts) =>
							contacts.filter((c) => c.aadId !== request.contact.aadId),
						);
					}),
					switchMap((request) =>
						documentsClient
							.deleteContact(request.documentId, {
								aadId: request.contact.aadId ?? '',
								contactType: request.contactType,
							})
							.pipe(
								handleResponse(request.documentId, 'Contact has not been removed!'),
							),
					),
				),
			),
			updateContact: rxMethod<{
				documentId: string;
				contactType: DocContactType;
				contact: UsppUserInfo;
			}>(
				pipe(
					tap((request) => {
						patchContacts(request.contactType, (contacts) =>
							contacts.map((c) =>
								c.aadId === request.contact.aadId ? request.contact : c,
							),
						);
					}),
					switchMap((request) =>
						documentsClient
							.updateContact(request.documentId, {
								aadId: request.contact.aadId ?? '',
								contactType: request.contactType,
								expertise: request.contact.expertise,
							})
							.pipe(
								handleResponse(request.documentId, 'Contact has not been updated!'),
							),
					),
				),
			),
			moveContact: rxMethod<{
				documentId: string;
				contactType: DocContactType;
				contact: UsppUserInfo;
				newIndex: number;
			}>(
				pipe(
					tap((request) => {
						patchContacts(request.contactType, (contacts) => {
							const newContacts = contacts.filter(
								(c) => c.aadId !== request.contact.aadId,
							);
							newContacts.splice(request.newIndex, 0, request.contact);

							return newContacts;
						});
					}),
					switchMap((request) =>
						documentsClient
							.moveContact(request.documentId, {
								aadId: request.contact.aadId ?? '',
								contactType: request.contactType,
								newOrder: request.newIndex,
							})
							.pipe(
								handleResponse(request.documentId, 'Contact has not been moved!'),
							),
					),
				),
			),
		};

		return methods;
	}),
	// Methods for managing linked documents
	withMethods((store) => {
		const documentsClient = inject(DocumentsClient);
		const analytics = inject(AnalyticsService);
		const toastStore = inject(ToastStore);

		function handleResponse<T>(
			documentId: string,
			errorMessage: string,
		): (source: Observable<T>) => Observable<T> {
			return tapResponse({
				next: () => {
					methods.loadLinkedDocuments(documentId);
				},
				error: (error) => {
					toastStore.addToastMessage({
						type: 'error',
						message: errorMessage,
					});
					analytics.trackError(error, errorMessage);
				},
			});
		}

		const methods = {
			loadLinkedDocuments: rxMethod<string>(
				pipe(
					tap(() => {
						patchState(store, {linkedDocumentsStatus: 'loading'});
					}),
					switchMap((documentId) =>
						documentsClient.listAttachments(documentId).pipe(
							tapResponse({
								next: (result) => {
									patchState(store, {
										linkedDocumentsStatus: 'loaded',
										linkedDocuments: result,
									});
								},
								error: (error) => {
									analytics.trackError(error);
								},
							}),
						),
					),
				),
			),
			addLinkedDocument: rxMethod<{documentId: string; documentToLinkId: string}>(
				pipe(
					switchMap(({documentId, documentToLinkId}) =>
						documentsClient
							.createAttachment(documentId, {
								documentId: documentToLinkId,
							})
							.pipe(handleResponse(documentId, 'Link has not been added')),
					),
				),
			),
			deleteLinkedDocument: rxMethod<{documentId: string; attachmentId: number}>(
				pipe(
					switchMap(({documentId, attachmentId}) =>
						documentsClient
							.deleteAttachment(documentId, {
								attachmentId,
							})
							.pipe(handleResponse(documentId, 'Link has not been removed')),
					),
				),
			),
			moveLinkedDocument: rxMethod<{
				documentId: string;
				linkedDocument: DocAttachment;
				newIndex: number;
			}>(
				pipe(
					tap(({linkedDocument, newIndex}) => {
						patchState(store, (state) => {
							const linkedDocuments = state.linkedDocuments.filter(
								(document) => document.id !== linkedDocument.id,
							);
							linkedDocuments.splice(newIndex, 0, linkedDocument);

							return {linkedDocuments};
						});
					}),
					switchMap(({documentId, linkedDocument, newIndex}) =>
						documentsClient
							.moveAttachment(documentId, {
								attachmentId: linkedDocument.id,
								newOrder: newIndex,
							})
							.pipe(handleResponse(documentId, 'Link has not been moved')),
					),
				),
			),
		};

		return methods;
	}),
	// Methods for managing links
	withMethods((store) => {
		const documentsClient = inject(DocumentsClient);
		const analytics = inject(AnalyticsService);
		const toastStore = inject(ToastStore);

		function handleResponse<T>(
			documentId: string,
			errorMessage: string,
		): (source: Observable<T>) => Observable<T> {
			return tapResponse({
				next: () => {
					methods.loadLinks(documentId);
				},
				error: (error) => {
					toastStore.addToastMessage({
						type: 'error',
						message: errorMessage,
					});
					analytics.trackError(error, errorMessage);
				},
			});
		}

		const methods = {
			loadLinks: rxMethod<string>(
				pipe(
					tap(() => {
						patchState(store, {linksStatus: 'loading'});
					}),
					switchMap((documentId) =>
						documentsClient.listLinks(documentId).pipe(
							tapResponse({
								next: (result) => {
									patchState(store, {
										linksStatus: 'loaded',
										links: result,
									});
								},
								error: (error) => {
									analytics.trackError(error);
								},
							}),
						),
					),
				),
			),
			addLink: rxMethod<{documentId: string; url: string}>(
				pipe(
					switchMap(({documentId, url}) =>
						documentsClient
							.createLink(documentId, {
								url,
							})
							.pipe(handleResponse(documentId, 'Link has not been added!')),
					),
				),
			),
			deleteLink: rxMethod<{documentId: string; linkId: number}>(
				pipe(
					switchMap(({documentId, linkId}) =>
						documentsClient
							.deleteLink(documentId, {
								id: linkId,
							})
							.pipe(handleResponse(documentId, 'Link has not been removed!')),
					),
				),
			),
			updateLink: rxMethod<{documentId: string; link: DocLink}>(
				pipe(
					switchMap(({documentId, link}) =>
						documentsClient
							.updateLink(documentId, {
								...link,
							})
							.pipe(handleResponse(documentId, 'Link has not been updated!')),
					),
				),
			),
			moveLink: rxMethod<{documentId: string; link: DocLink; newIndex: number}>(
				pipe(
					tap(({link, newIndex}) => {
						patchState(store, (state) => {
							const links = state.links.filter((l) => l.id !== link.id);
							links.splice(newIndex, 0, link);

							return {links};
						});
					}),
					switchMap(({documentId, link, newIndex}) =>
						documentsClient
							.moveLink(documentId, {
								id: link.id,
								newOrder: newIndex,
							})
							.pipe(handleResponse(documentId, 'Link has not been moved!')),
					),
				),
			),
		};

		return methods;
	}),
	// Methods for loading preview page
	withMethods((store) => {
		const documentsClient = inject(DocumentsClient);
		const relatedDocumentsService = inject(RelatedDocumentsService);
		const analytics = inject(AnalyticsService);
		const utilsService = inject(UtilsService);

		const methods = {
			loadPreviewDocument: rxMethod<PreviewRequest>(
				pipe(
					tap(({documentId, context}) =>
						patchState(store, {
							status: 'loading',
							documentId,
							context,
						}),
					),
					switchMap(({documentId, presetId, query}) =>
						documentsClient.getDetails(documentId, presetId, query).pipe(
							tapResponse({
								next: (details) => {
									patchState(store, {
										status: 'loaded',
										documentId: details.document.id,
										docDetails: details,
									});

									store.loadContacts(documentId);
									store.loadLinkedDocuments(documentId);
									store.loadLinks(documentId);
									methods.loadRelatedDocuments({docDetails: details});
								},
								error: (error) => {
									analytics.trackError(error);
									utilsService.navigateToErrorPage(error as ApiException);
								},
							}),
						),
					),
				),
			),
			loadRelatedDocuments: rxMethod<{docDetails: DocDetailsItem}>(
				pipe(
					switchMap(({docDetails}) =>
						relatedDocumentsService.getRelatedDocuments$(docDetails).pipe(
							tap((relatedDocuments) => {
								patchState(store, {
									relatedDocuments,
								});
							}),
						),
					),
				),
			),
		};

		return methods;
	}),
);
