<div class="header-powerpoint-toggle" (click)="toggle()">
	<pp-image
		class="powerpoint-image"
		[width]="20"
		[height]="20"
		[hint]="hintPowerPointToggle"
		[hintPosition]="'BOTTOM'"
		name="{{ showOnlyPowerPoint() ? 'logo_ppt_64' : 'logo_ppt_gray_64' }}"
	/>

	<ng-template #hintPowerPointToggle>
		<span>{{
			showOnlyPowerPoint() ? 'Show only PowerPoint files' : 'Show all documents'
		}}</span>
	</ng-template>
</div>
