import {InjectionToken} from '@angular/core';
import {SearchFilterWidgetTypes as types} from '@search/models';

export type EnvConfigType = typeof defaultEnvConfig;
export const CONFIG = new InjectionToken<EnvConfigType>('app-base-config');

export const defaultEnvConfig = {
	appVersion: 'ngrx@24.04.4',

	filtersSettings: {
		documentTags: {order: 0, visible: true, type: types.LIST},
		modifiedDate: {order: 1, visible: true, type: types.HISTOGRAM},
		fileType: {order: 2, visible: true, type: types.GROUP_LIST},
		locationFilterTree: {order: 3, visible: true, type: types.TREE},
		contentOwner: {order: 4, visible: true, type: types.TREE},
		about: {order: 5, visible: false, type: types.TREE},
		sourceSystem: {order: 6, visible: true, type: types.LIST},
		size: {order: 7, visible: false, type: types.HISTOGRAM},
		preparedFor: {order: 8, visible: false, type: types.TREE},
		languages: {order: 9, visible: false, type: types.LIST},
		fileContentType: {order: 10, visible: false, type: types.GROUP_LIST},
		accessLevel: {order: 11, visible: false, type: types.LIST},
		masterLayout: {order: 12, visible: false, type: types.TREE},
	},
	cloudFilterSettings: {
		modifiedDate: {order: 1, visible: true, type: types.HISTOGRAM},
		fileType: {order: 2, visible: true, type: types.GROUP_LIST},
		displayAuthor: {order: 3, visible: true, type: types.LIST},
		tags: {order: 4, visible: true, type: types.LIST},
		size: {order: 5, visible: true, type: types.HISTOGRAM},
	},

	applicationId: 'a8bcf695-0f94-4981-9b80-78ac5a2c0f3a',
	redirectPath: '/ui/login',
	blankPagePath: '/ui/login-blank',
	enableMsalLogging: false,
	epamHiveUrl:
		'https://globalmenu-stage.epm-ppa.projects.epam.com/scripts/menu.js?app=PresalesPortal',

	serviceless: true, // if true, the app should not call API on `serviceUrl` domain, and use `apiUrl` instead
	serviceUrl: 'https://stage.presales.epam.com',

	environment: 'development',
	apiUrl: 'https://stage.cloud.presales.epam.com',
	apiAppId: '8eca52ee-ae6d-40b4-866c-959ad42cdb86',

	enableAnalyticsLogging: false,
	googleAnalyticsAccountId: '',
	gtagStreamId: '',
	apmServerUrl: 'https://apm-sandbox.cloudapp.epam.com/',
};
