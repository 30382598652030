import {ChangeDetectionStrategy, Component, Inject, Input, ViewEncapsulation} from '@angular/core';
import {SvgIconComponent} from '@pp/svg';
import {ModalRef, CURRENT_MODAL} from '@shared/modals';

@Component({
	selector: 'shared-modal-window-header',
	templateUrl: './modal-window-header.component.html',
	styleUrls: ['./modal-window-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SvgIconComponent],
})
export class ModalWindowHeaderComponent {
	@Input() headerTitle = '';

	constructor(@Inject(CURRENT_MODAL) private readonly modalRef: ModalRef) {}

	closeModalWindow() {
		this.modalRef.close();
	}
}
