import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {map, Observable} from 'rxjs';
import {SvgIconComponent} from '@pp/svg';
import {DocDetailsItem, DocumentsClient, PpSearchResultItemOfDocumentIndexItem} from '@shared/api';
import {MailTo} from '@shared/models';
import {
	CardActionsService,
	CardMenuActionsComponent,
	DocumentItemActionService,
	SearchItemActionInfo,
	SearchItemActionType,
} from '@shared/card-actions';
import {MailToService} from '@shared/services';
import {DropdownComponent} from '@shared/components/dropdown/dropdown.component';

@Component({
	selector: 'powerpoint-section-item',
	templateUrl: './section-item.component.html',
	styleUrls: ['./section-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CardActionsService],
	standalone: true,
	imports: [SvgIconComponent, DropdownComponent, CardMenuActionsComponent],
})
export class SectionItemComponent implements OnInit {
	@Input() item!: PpSearchResultItemOfDocumentIndexItem;

	mailTo$!: Observable<MailTo>;

	primaryActionInfo!: SearchItemActionInfo;

	constructor(
		private readonly actionService: DocumentItemActionService,
		private readonly mailToService: MailToService,
		private readonly documentsClient: DocumentsClient,
		private readonly cardActionsService: CardActionsService,
	) {}

	ngOnInit() {
		this.mailTo$ = this.documentsClient.listContacts(this.item.document.id).pipe(
			map((contacts) => {
				return this.mailToService.getMailToRequest(this.item as DocDetailsItem, contacts);
			}),
		);

		const actions = this.actionService.getActions(this.item.document);
		this.primaryActionInfo = this.actionService.getActionInfo(
			this.actionService.getPrimaryAction(actions),
			this.item.document,
		);
	}

	onAction(action: SearchItemActionType): void {
		this.cardActionsService.onAction(action, this.item.document);
	}
}
