<div class="card-list" #cardList [ngClass]="{vertical: cardListRef.direction() === 'vertical'}">
	<shared-card-list
		#cardListRef
		[list]="items()"
		[containerRef]="cardList"
		[breakpoints]="
			isVerticalPreferred() ? ['(max-width: 753px)', '(min-width: 754px)'] : undefined
		"
		[viewBehaviour]="
			isVerticalPreferred()
				? ['vertical', 'horizontal']
				: ['vertical', 'small', 'medium', 'normal']
		"
		[hasSeparator]="true"
		[trackBy]="cardIdentity"
		[layoutStrategy]="'viewport'"
	>
		<ng-template #cardTemplate let-item>
			<search-item-card
				[item]="item.document"
				[size]="cardListRef.size()"
				[direction]="cardListRef.direction()"
				[presetId]="presetId()"
				[query]="query()"
				[context]="items()"
				[lightMode]="cardListRef.size() !== 'normal'"
			>
			</search-item-card>
		</ng-template>
	</shared-card-list>
</div>
