<div class="preview-container" [class.no-calculate]="!calculateOnResize">
	<div
		class="preview-wrapper"
		[class.inline]="inline"
		[class.preview-wrapper-big]="isBig"
		[class.preview-wrapper-highlighted]="!!this.document.description"
	>
		@if (previewSource) {
			<iframe [src]="previewSource" allowfullscreen></iframe>
		}
	</div>
</div>
