import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Params, RouterLink} from '@angular/router';
import {PresetLinkModel} from '@search2/models';
import {SearchStateSerializerService} from '@search2/services';

@Component({
	selector: 'search-preset-link',
	templateUrl: './preset-link.component.html',
	styleUrls: ['./preset-link.component.scss'],
	standalone: true,
	imports: [RouterLink],
})
export class PresetLinkComponent implements OnInit {
	@Input({required: true}) link!: PresetLinkModel;
	@Output() beforeNavigation = new EventEmitter<Event>();

	queryParams!: Params;

	constructor(private readonly searchStateSerializer: SearchStateSerializerService) {}

	ngOnInit(): void {
		this.queryParams = {
			o: this.searchStateSerializer.serialize(this.link.query),
		};
	}

	onClick(event: Event) {
		this.beforeNavigation.next(event);
	}
}
