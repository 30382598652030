@if (hasIconBefore) {
	<pp-icon [name]="withIconBefore" class="icon" [size]="iconSize"></pp-icon>
}
@if (hasText) {
	<div class="text-wrap">
		<ng-content></ng-content>
	</div>
}
@if (hasIconAfter) {
	<pp-icon [name]="withIconAfter" class="icon" [size]="iconSize"></pp-icon>
}
