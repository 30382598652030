import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {from, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CONFIG, EnvConfigType} from '@environments/environment';

import {ErrorTracker} from '@core/error-tracker/error-tracker';

const unauthorizedStatus = [401, 403];
const interval = 30000;

@Injectable({providedIn: 'root'})
export class AuthenticationInterceptor implements HttpInterceptor {
	private sessionExpired = false;

	constructor(
		@Inject(CONFIG) private readonly config: EnvConfigType,
		private errorTracker: ErrorTracker,
	) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		// Add cookies to all request to SharePoint-hosted API
		const enrichedRequest = request.url.startsWith(this.config.serviceUrl)
			? request.clone({withCredentials: true})
			: request;
		const isRequestWithCookieAuth = request.url.includes(this.config.serviceUrl);

		return next.handle(enrichedRequest).pipe(
			catchError((error: unknown) => {
				if (
					isRequestWithCookieAuth &&
					error instanceof HttpErrorResponse &&
					unauthorizedStatus.includes(error.status)
				) {
					if (!this.sessionExpired) {
						this.sessionExpired = true;

						if (this.config.environment !== 'development') {
							window.location.reload();
						} else {
							const child = window.open(this.config.serviceUrl);

							window.setTimeout(() => {
								child?.close();
								window.location.reload();
							}, interval);
						}
					}

					return from([]);
				}

				this.errorTracker.logErrorToAnalytics(error);

				return throwError(() => error);
			}),
		);
	}
}
