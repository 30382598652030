import {Inject, Injectable} from '@angular/core';
import {fromEvent, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {WINDOW} from '../tokens/window.token';
import {StatusType} from '@core/models';

@Injectable({providedIn: 'root'})
export class NetworkService {
	private online$: Observable<boolean> = fromEvent(this.window, 'online').pipe(map(() => true));

	private offline$: Observable<boolean> = fromEvent(this.window, 'offline').pipe(
		map(() => false),
	);

	readonly status$: Observable<StatusType> = merge(this.online$, this.offline$).pipe(
		map((status: boolean) => (status ? 'online' : 'offline') as StatusType),
	);

	constructor(@Inject(WINDOW) private readonly window: Window) {}
}
