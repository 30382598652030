<div class="powerpoint-home-container" [ppCdkUseTheme]="theme()">
	<header>
		<pp-image
			[hidden]="theme() === 'light'"
			[name]="'office_presales_logo'"
			[width]="208"
			[height]="72"
		></pp-image>
		<pp-image
			[hidden]="theme() === 'dark'"
			[name]="'office_presales_logo-light'"
			[width]="208"
			[height]="72"
		></pp-image>
	</header>

	<div class="content-section">
		<h3>Create a presentation from a template</h3>
		<powerpoint-info-block>
			<span title>Templates</span>
			<a content href="" target="_blank"
				>Standard templates directly in PowerPoint</a
			>
		</powerpoint-info-block>

		<powerpoint-info-block>
			<a [routerLink]="'/powerpoint/templates'" title>Presales Templates</a>
			<span content
				>Custom templates for proposals, case studies, stories, and other Presales
				deliverables</span
			>
		</powerpoint-info-block>

		<h3>Reuse existing content</h3>

		@for (card of existingContentCards; track card.title) {
			<powerpoint-info-block>
				<a [routerLink]="card.link" title>{{ card.title }}</a>
				@if (!!card.badge) {
					<shared-abbreviation-icon
						badge
						class="info-block-badge"
						[label]="card.badge"
					></shared-abbreviation-icon>
				}
				<span content>{{ card.content }}</span>
			</powerpoint-info-block>
		}
	</div>
</div>
<powerpoint-home-footer></powerpoint-home-footer>
