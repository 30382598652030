import {ChangeDetectionStrategy, Component, OnInit, inject} from '@angular/core';

import {BehaviorSubject} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {PopoverService} from '@shared/popover';
import {UserStore} from '@shared/state';
import {AnalyticsService} from '@shared/analytics';
import {AlertComponent, AlertActionComponent} from '@pp/alert';
import {HasTeamPipe} from '@core/roles';
import {AccessInfoPopoverComponent} from '@shared/access';

@Component({
	selector: 'search-permission-warning',
	templateUrl: './search-permission-warning.component.html',
	styleUrls: ['search-permission-warning.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [PopoverService],
	standalone: true,
	imports: [AlertComponent, AlertActionComponent, AsyncPipe, HasTeamPipe],
})
export class SearchPermissionWarningComponent implements OnInit {
	private viewedStorage = {
		key: 'uspp.warning-info-viewed',
		value: 'true',
	};
	readonly messageWarning =
		'You have partial access to Presales Portal that is limited to public materials';
	viewed$ = new BehaviorSubject<boolean>(false);
	teams: string[] | undefined;

	constructor(
		private analytics: AnalyticsService,
		private readonly popOverService: PopoverService,
	) {}

	private userStore = inject(UserStore);
	ngOnInit(): void {
		this.teams = this.userStore.profile.teams();
		const isViewed = localStorage.getItem(this.viewedStorage.key) === this.viewedStorage.value;
		this.viewed$.next(isViewed);
	}

	getMoreInfo(event: MouseEvent): void {
		event.stopPropagation();
		this.analytics.trackSearchResultsAction('showMorePermissionWarning');
		this.popOverService.openPopOver(
			AccessInfoPopoverComponent,
			{},
			event.target as HTMLElement,
			'bottom',
			true,
		);
	}

	closeWarning(): void {
		this.viewed$.next(true);
		this.analytics.trackSearchResultsAction('closePermissionWarning');
		localStorage.setItem(this.viewedStorage.key, 'true');
	}
}
