import {Injectable, Injector, OnDestroy} from '@angular/core';
import {LayoutStrategyInterface} from './interfaces/layout-strategy.interface';
import {ViewportStrategy} from './strategies/viewport.strategy';
import {ContainerStrategy} from './strategies/container.strategy';
import {LayoutStrategyType} from './types/layout-strategy.type';

@Injectable({providedIn: 'root'})
export class LayoutService implements OnDestroy {
	private strategy?: LayoutStrategyInterface;

	constructor(private readonly injector: Injector) {}

	useStrategy<T extends LayoutStrategyInterface>(layoutStrategy: LayoutStrategyType): T {
		this.strategy?.destroy();
		this.strategy =
			layoutStrategy === 'container'
				? this.injector.get(ContainerStrategy)
				: this.injector.get(ViewportStrategy);

		return this.strategy as T;
	}

	ngOnDestroy(): void {
		this.strategy?.destroy();
	}
}
