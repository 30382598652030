import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
	selector: '[ppCardTitle]',
	standalone: true,
})
export class CardTitleDirective {
	@HostBinding('class.card-title')
	@Input()
	setCardTitleClass = true;
}
