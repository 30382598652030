import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'moreLess',
	standalone: true,
})
export class MoreLessPipe implements PipeTransform {
	transform<T>(value: T[], fullView: boolean, count: number): T[] {
		return fullView ? value : value.slice(0, count);
	}
}
