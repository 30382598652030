import {Inject, Injectable} from '@angular/core';
import {CONFIG, EnvConfigType} from '@environments/environment';

import {FiltersSettings, FilterState, UserSettingsModel} from '@shared/models';
import {parseJson} from '@shared/utils';

@Injectable({providedIn: 'root'})
export class UserSettingsService {
	private readonly handlers = [
		this.extractUiTheme.bind(this),
		this.extractFiltersState.bind(this),
		this.extractWarningMode.bind(this),
		this.extractAgreementSigned.bind(this),
	];

	constructor(@Inject(CONFIG) private readonly config: EnvConfigType) {}

	extract(settings: Dictionary<string>): UserSettingsModel {
		let result = {} as UserSettingsModel;

		this.handlers.forEach((handler) => {
			result = handler.call(this, settings, result);
		});

		return result;
	}

	private extractUiTheme(
		settings: Dictionary<string>,
		result: UserSettingsModel,
	): UserSettingsModel {
		const theme = settings['theme'] === 'light' ? 'light' : 'dark';

		return {
			...result,
			uiTheme: theme,
		};
	}

	private extractWarningMode(
		settings: Dictionary<string>,
		result: UserSettingsModel,
	): UserSettingsModel {
		const disable = settings['warningMode'] === 'disable';

		return {
			...result,
			disableConfidentialConfirmation: disable,
		};
	}

	private extractFiltersState(
		settings: Dictionary<string>,
		result: UserSettingsModel,
	): UserSettingsModel {
		const filtersSettings: object =
			(settings['filtersSettings'] && parseJson(settings['filtersSettings'], null)) || {};
		const filters = {} as FiltersSettings;
		const envFiltersSettings = this.config.filtersSettings;

		Object.keys(envFiltersSettings).forEach((key) => {
			const filter: FilterState =
				filtersSettings[key as keyof typeof filtersSettings] ||
				envFiltersSettings[key as keyof typeof envFiltersSettings];

			filters[key as keyof typeof filters] = {
				...filter,
				type: envFiltersSettings[key as keyof typeof envFiltersSettings].type,
			};
		});

		return {
			...result,
			filters,
		};
	}

	private extractAgreementSigned(
		settings: Dictionary<string>,
		result: UserSettingsModel,
	): UserSettingsModel {
		const signed = settings['agreementSigned'] === 'true';

		return {
			...result,
			agreementSigned: signed,
		};
	}
}
