@if (type === 'horizontal-card') {
	@if (!showOnlyCardsOnHorizontal) {
		<pp-skeleton-item class="pp-skeleton-horizontal-card-chips"></pp-skeleton-item>
	}
	@for (i of [].constructor(7 | random); track $index; let first = $first) {
		<div class="pp-skeleton-horizontal-card-deck" [class.first]="first">
			<pp-skeleton-item class="pp-skeleton-horizontal-card-thumbnail"></pp-skeleton-item>
			<div class="pp-skeleton-horizontal-card-content">
				<pp-skeleton-item class="pp-skeleton-horizontal-card-line"></pp-skeleton-item>
				<pp-skeleton-item class="pp-skeleton-horizontal-card-header"></pp-skeleton-item>
				<pp-skeleton-item class="pp-skeleton-horizontal-card-subheader"></pp-skeleton-item>
			</div>
		</div>
	}
}
@if (type === 'panel') {
	<div class="pp-skeleton-panel-container">
		@for (i of [].constructor(2); track $index) {
			<div class="pp-skeleton-panel-section">
				<pp-skeleton-item class="pp-skeleton-panel-header"></pp-skeleton-item>
				<div class="pp-skeleton-panel-content">
					@for (i of [].constructor(10 | random); track $index) {
						<pp-skeleton-item class="pp-skeleton-panel-line"></pp-skeleton-item>
					}
				</div>
			</div>
		}
	</div>
}
@if (type === 'aside') {
	<div class="pp-skeleton-aside-container">
		<pp-skeleton-item class="pp-skeleton-aside-back"></pp-skeleton-item>
		@for (i of [].constructor(4); track $index) {
			<div class="pp-skeleton-aside-section">
				<pp-skeleton-item class="pp-skeleton-aside-header"></pp-skeleton-item>
				<div class="pp-skeleton-aside-content">
					@for (i of [].constructor(7 | random); track $index) {
						<pp-skeleton-item class="pp-skeleton-aside-line"></pp-skeleton-item>
					}
				</div>
			</div>
		}
	</div>
}
@if (type === 'vertical-card') {
	@if (isChipsVisibleOnVertical) {
		<pp-skeleton-item class="pp-skeleton-vertical-card-chips"></pp-skeleton-item>
	}
	<pp-skeleton-item class="pp-skeleton-vertical-card-header"></pp-skeleton-item>
	<pp-skeleton-item class="pp-skeleton-vertical-card-subheader"></pp-skeleton-item>
	<div class="pp-skeleton-vertical-card-cards">
		<pp-skeleton-card></pp-skeleton-card>
		<pp-skeleton-card></pp-skeleton-card>
		<pp-skeleton-card></pp-skeleton-card>
	</div>
	<pp-skeleton-item class="pp-skeleton-vertical-card-header"></pp-skeleton-item>
	<pp-skeleton-item class="pp-skeleton-vertical-card-subheader"></pp-skeleton-item>
	<div class="pp-skeleton-vertical-card-cards">
		<pp-skeleton-card></pp-skeleton-card>
		<pp-skeleton-card></pp-skeleton-card>
	</div>
	<pp-skeleton-item class="pp-skeleton-vertical-card-header"></pp-skeleton-item>
	<pp-skeleton-item class="pp-skeleton-vertical-card-subheader"></pp-skeleton-item>
	<div class="pp-skeleton-vertical-card-cards">
		<pp-skeleton-card></pp-skeleton-card>
		<pp-skeleton-card></pp-skeleton-card>
		<pp-skeleton-card></pp-skeleton-card>
	</div>
}
@if (type === 'preview') {
	<div class="pp-skeleton-preview">
		<pp-skeleton-item class="pp-skeleton-preview-thumbnail"></pp-skeleton-item>
		<div class="pp-skeleton-preview-aside">
			<pp-skeleton-item class="pp-skeleton-preview-aside-actions"></pp-skeleton-item>
			<pp-skeleton-item class="pp-skeleton-preview-aside-widget"></pp-skeleton-item>
			<pp-skeleton-item class="pp-skeleton-preview-aside-widget-second"></pp-skeleton-item>
		</div>
		<div class="pp-skeleton-preview-content">
			<pp-skeleton-item class="pp-skeleton-preview-content-header"></pp-skeleton-item>
			<pp-skeleton-item class="pp-skeleton-preview-content-cards"></pp-skeleton-item>
		</div>
	</div>
}
@if (type === 'slides') {
	<pp-skeleton-item class="pp-skeleton-slides-subheader"></pp-skeleton-item>
	<pp-skeleton-item class="pp-skeleton-slides-header"></pp-skeleton-item>

	<pp-skeleton-item class="pp-skeleton-slides-section-title first"></pp-skeleton-item>
	<div class="pp-skeleton-slides-card-deck">
		@for (i of [].constructor(7 | random); track $index; let first = $first) {
			<pp-skeleton-item class="pp-skeleton-slides-thumbnail"></pp-skeleton-item>
		}
	</div>

	<pp-skeleton-item class="pp-skeleton-slides-section-title"></pp-skeleton-item>

	<pp-skeleton-item class="pp-skeleton-slides-section-title"></pp-skeleton-item>
	<div class="pp-skeleton-slides-card-deck">
		@for (i of [].constructor(7 | random); track $index; let first = $first) {
			<pp-skeleton-item class="pp-skeleton-slides-thumbnail"></pp-skeleton-item>
		}
	</div>
}
