import {Observable} from 'rxjs';
import {first, tap} from 'rxjs/operators';

/**
 * @deprecated The method should not be used
 */
export function getFirst<T>(stream$: Observable<T>): T {
	const stack = new Error().stack;
	let result!: T;

	stream$
		.pipe(
			first(),
			tap(
				(value) => (result = value),
				(error: unknown) => {
					if (error instanceof Error) {
						error.stack = stack;
						throw error;
					}
				},
			),
		)
		// eslint-disable-next-line rxjs/no-ignored-subscription
		.subscribe();

	return result;
}
