import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {CONFIG, EnvConfigType, defaultEnvConfig} from '@environments/environment';

import {injectScripts, isE2eInstance, isInsideIframe} from '@shared/utils';

(function (): void {
	const config = getEnvConfig(window.location.hostname);
	if (config.environment === 'production') {
		enableProdMode();
	}

	platformBrowserDynamic([{provide: CONFIG, useValue: config}])
		.bootstrapModule(AppModule)
		.then(() => {
			if (!isE2eInstance() && isInsideIframe()) {
				setTimeout(() => {
					injectScripts([config.epamHiveUrl]);
				}, 5000);
			}
		})
		.catch((err) => console.error(err));
})();

function getEnvConfig(hostname: string): EnvConfigType {
	switch (hostname) {
		case 'medc-prpo-stage-web.azurewebsites.net':
			return {
				...defaultEnvConfig,
				environment: 'staging',
				serviceUrl: '',
				apiUrl: 'https://medc-prpo-stage-web.azurewebsites.net',
				apiAppId: '3a65850d-4db7-4125-9c4f-4514cd85e3ce',
				googleAnalyticsAccountId: '',
				gtagStreamId: '',
				enableMsalLogging: true,
				enableAnalyticsLogging: false,
				serviceless: true,
				applicationId: '56b1891c-a7f3-44be-b8cb-90629134c3b5',
			};
		case 'presales.medecision.com':
			return {
				...defaultEnvConfig,
				environment: 'staging',
				serviceUrl: '',
				apiUrl: 'https://presales-api.medecision.com',
				apiAppId: '3a65850d-4db7-4125-9c4f-4514cd85e3ce',
				redirectPath: '/login',
				blankPagePath: '/login-blank',
				googleAnalyticsAccountId: '',
				gtagStreamId: '',
				enableMsalLogging: true,
				enableAnalyticsLogging: false,
				serviceless: true,
				applicationId: '56b1891c-a7f3-44be-b8cb-90629134c3b5',
			};
		case 'localhost':
		default:
			return {
				...defaultEnvConfig,
				environment: 'development',
				serviceless: true, // toggle to mimic serviceless mode locally
				redirectPath: '/login',
				apiUrl: 'https://medc-prpo-stage-web.azurewebsites.net',
				apiAppId: '3a65850d-4db7-4125-9c4f-4514cd85e3ce',
				enableAnalyticsLogging: false,
				enableMsalLogging: false,
				gtagStreamId: 'G-FDP6S5DCK4',
				applicationId: '56b1891c-a7f3-44be-b8cb-90629134c3b5',
				serviceUrl: '',
			};
	}
}
