<ng-template [ngTemplateOutlet]="chainTemplate"></ng-template>
@if (isCollapsed) {
	<span class="counter" [hint]="hintTemplate" hintPosition="TOP"
		><span class="counter-value">+{{ collapsed }}</span></span
	>
}

<ng-template #hintTemplate>
	<div class="chain-column">
		<ng-template [ngTemplateOutlet]="chainTemplate"></ng-template>
	</div>
</ng-template>
