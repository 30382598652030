import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AppliedFilterGroupViewModel} from '../applied-filter-group.viewmodel';
import {SearchAppliedFilterComponent} from '../../search-applied-filter/search-applied-filter.component';
import {FilterSectionNamePipe} from '@shared/pipes';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'search-applied-filter-group',
	templateUrl: './search-applied-filter-group.component.html',
	styleUrls: ['./search-applied-filter-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SearchAppliedFilterComponent, SvgIconComponent, FilterSectionNamePipe],
})
export class SearchAppliedFilterGroupComponent {
	@Input() hasCloseButton = true;
	@Input({required: true}) appliedFilterModel!: AppliedFilterGroupViewModel;

	@Output() removeEmitter = new EventEmitter();
}
