import {ChangeDetectionStrategy, Component, OnInit, effect, inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PreviewSlidesDialogComponent} from '../preview-slides-dialog/preview-slides-dialog.component';
import {ShowSlidesStore} from '../show-slides.store';
import {UseThemeDirective} from '@shared/directives';
import {TitleService} from '@shared/services';
import {UserStore} from '@shared/state';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'search-preview-slides-dialog-page',
	templateUrl: './preview-slides-dialog-page.component.html',
	styleUrls: ['./preview-slides-dialog-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [UseThemeDirective, PreviewSlidesDialogComponent],
})
export class PreviewSlidesDialogPageComponent implements OnInit {
	theme = inject(UserStore).settings.uiTheme;

	private showSlidesStore = inject(ShowSlidesStore);

	constructor(
		private readonly route: ActivatedRoute,
		private readonly titleService: TitleService,
		private readonly analytics: AnalyticsService,
	) {
		effect(() => {
			this.titleService.setTitle(this.showSlidesStore.item().title.rawValue);
			this.analytics.trackPageNavigation('/ui/preview-slides/:documentId');
		});
	}

	ngOnInit(): void {
		const documentId = this.route.snapshot.params['documentId'];
		this.showSlidesStore.loadSlidesByDocId$(documentId);
		this.analytics.trackDocumentPreviewAction('open:show-slides', 'page');
	}
}
