import {Action} from '@ngrx/store';

import {SelectedFiltersPayload} from './selected-filters-payload';

export const RESET_SELECTED_FILTERS_SILENT = '[Search] Reset Selected Filters Silent';

// TODO: Silent events are probably result of bad architecture decisions. Consider redesign.
export class ResetSelectedFiltersSilentAction implements Action {
	readonly type = RESET_SELECTED_FILTERS_SILENT;
	// Actually it's not used. But... TypeScript wants it really bad.
	payload?: SelectedFiltersPayload;
}
