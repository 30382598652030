<div #appContainerRef class="app-container">
	@if (pageTypeVm$ | async; as pageTypeVm) {
		<core-header [isThemeVisible]="pageTypeVm.shouldThemeBeVisible" class="page-header">
			<ng-container suggestions>
				@switch (pageTypeVm.pageType) {
					@case ('spaces') {
						<search-suggestions
							class="page-subheader-autocomplete"
						></search-suggestions>
					}
					@case ('search') {
						<search-bar></search-bar>
					}
					@case ('dashboard') {
						@if (isMobile()) {
							<search-bar></search-bar>
						} @else {
							<search-suggestions
								class="page-subheader-autocomplete"
							></search-suggestions>
						}
					}
				}
			</ng-container>

			<ng-container mobile-filters>
				@switch (pageTypeVm.pageType) {
					@case ('spaces') {
						<shared-mobile-filters-toggler>
							<search-filters></search-filters>
						</shared-mobile-filters-toggler>
					}
					@case ('search') {
						<shared-mobile-filters-toggler [strictLightMode]="true">
							<search-filters-layout></search-filters-layout>
						</shared-mobile-filters-toggler>
					}
				}
			</ng-container>

			@if (isNavPanelButtonVisible) {
				<button (click)="toggleNavPanel()" menuButton class="menu-button">
					<pp-icon name="navigation-menu-24" />
				</button>
			}
		</core-header>
	}
	<div class="app-content">
		@if (routerLoading$ | async) {
			<shared-presales-portal-loading></shared-presales-portal-loading>
		}
		<router-outlet></router-outlet>
	</div>
	<div class="nav-panel">
		<shared-navigation-panel
			[containerSelector]="'.app-container'"
			(navPanelButtonStateAction)="navPanelButtonStateListener($event)"
		></shared-navigation-panel>
	</div>
</div>

<core-toast-messages></core-toast-messages>
<core-outdated-browser></core-outdated-browser>
<core-network-status></core-network-status>
@if (isDeckBuilderAdviceVisible()) {
	<div *ppAnchor="'deck-builder-advice'" class="downloads-advice"></div>
}
