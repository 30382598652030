import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {DownloadHistoryResponse} from './download-history-response';
import {CONFIG, EnvConfigType} from '@environments/environment';

import {DownloadHistoryModel} from '@search/models';
import {PostQueryService, ResultsService} from '@shared/services';
import {SearchResultModel} from '@shared/models';

@Injectable({providedIn: 'root'})
export class DownloadHistoryService {
	constructor(
		private http: HttpClient,
		private postQueryService: PostQueryService,
		private results: ResultsService,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	getDocumentDownloadHistoryWithDuplicates$(
		documentId: string,
	): Observable<DownloadHistoryModel[]> {
		return this.postQueryService.retrieve$({groupId: documentId}, {}, new Set()).pipe(
			map(
				(response) =>
					this.results.extractData(
						response.d.postquery.PrimaryQueryResult.RelevantResults,
					).data,
			),
			switchMap((results) => this.getDownloadHistory$(results)),
		);
	}

	getDocumentsDownloadHistory$(results: SearchResultModel[]): Observable<IHistoryOptions[]> {
		return this.getDownloadHistory$(results);
	}

	private getDownloadHistory$(results: SearchResultModel[]): Observable<IHistoryOptions[]> {
		const request = results.flatMap(({filePath, fileExtension, importSource}) =>
			fileExtension === 'page' ? [importSource?.toLowerCase(), filePath] : filePath,
		);
		const fullUrl = `${this.config.serviceUrl}/api/documents/downloadhistory`;

		return this.http
			.post<DownloadHistoryResponse[]>(fullUrl, request, {headers: this.prepareHeaders()})
			.pipe(
				map((response) =>
					response.map((item) => ({
						date: new Date(item.time).valueOf(),
						user: item.user,
						url: item.url,
					})),
				),
			);
	}

	private prepareHeaders(): HttpHeaders {
		const headers = new HttpHeaders({
			Accept: 'application/json',
		});

		return headers;
	}
}

interface IHistoryOptions {
	date: number;
	user: string;
	url: string;
}
