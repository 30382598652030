import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable, Subject, Subscription} from 'rxjs';

import {ActivatedRoute, RouterLink} from '@angular/router';
import {map, tap} from 'rxjs/operators';
import {toSignal} from '@angular/core/rxjs-interop';
import {NgClass, AsyncPipe, DatePipe} from '@angular/common';
import {ChangeGroupIdAction, RequestNextPageAction} from '../../actions';
import {SearchFeatureState} from '../../reducers/search-state';
import {QueryModificationComponent} from '../query-modification/query-modification.component';
import {QuickEditComponent} from '../quick-edit/quick-edit.component';
import {MetadataComponent} from '../metadata/metadata.component';
import {SearchResultSubHeaderComponent} from '../search-result-sub-header/search-result-subheader.component';
import {SearchResultThumbnailComponent} from '../search-result-thumbnail/search-result-thumbnail.component';
import {DuplicatesComponent} from '../duplicates/duplicates.component';
import {SpellComponent} from '../spell/spell.component';
import {SearchPermissionWarningComponent} from '../search-permission-warning/search-permission-warning.component';
import {FilterInfoComponent} from './filter-info/filter-info.component';
import {SelectionPanelGapComponent} from '@shared/selection';
import {PreviewService} from '@preview/services';
import {UiStore, UserStore} from '@shared/state';
import {
	CopyLinkService,
	DownloadService,
	PostQueryService,
	ResultsService,
	ToastService,
	VisitedService,
} from '@shared/services';
import {PreviewModel, SearchResultModel, ThumbnailAction} from '@shared/models';
import {OpeningMethods} from '@shared/enums/opening-methods.enum';
import {UIService} from '@search/services';
import {UseThemeDirective} from '@shared/directives';
import {PresetAdComponent} from '@search2/components';
import {AnchorDirective} from '@pp/anchor';
import {DuplicatesQueryStringPipe} from '@search/pipes';
import {AnalyticsService} from '@shared/analytics';
import {ModalService} from '@shared/modals';
import {SpinnerComponent} from '@pp/spinner';
import {SvgIconComponent} from '@pp/svg';
import {FileTypePipe} from '@shared/pipes/file-type.pipe';
import {HasTeamPipe} from '@core/roles';
import {DownloadComponent} from '@shared/components/download/download.component';
import {PreviewMenuComponent} from '@shared/components/preview-menu/preview-menu.component';
import {NoResultsComponent} from '@shared/components/no-results/no-results.component';

@Component({
	selector: 'search-results',
	templateUrl: './search-results.component.html',
	styleUrls: ['./search-results.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		UseThemeDirective,
		NgClass,
		SearchPermissionWarningComponent,
		SpellComponent,
		DuplicatesComponent,
		PresetAdComponent,
		FilterInfoComponent,
		AnchorDirective,
		SearchResultThumbnailComponent,
		SearchResultSubHeaderComponent,
		MetadataComponent,
		RouterLink,
		SvgIconComponent,
		QuickEditComponent,
		NoResultsComponent,
		SpinnerComponent,
		DownloadComponent,
		QueryModificationComponent,
		SelectionPanelGapComponent,
		AsyncPipe,
		DatePipe,
		HasTeamPipe,
		FileTypePipe,
		DuplicatesQueryStringPipe,
	],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();

	results$: Observable<SearchResultModel[]> = this.store$.select(
		(state) => state.search.results.data,
	);
	query$: Observable<string> = this.store$
		.select((state) => state.search.query.submittedQuery)
		.pipe(map((query) => query ?? ''));
	querySignal = toSignal(this.query$, {initialValue: ''});
	previewData?: PreviewModel;
	view$: Observable<string> = this.store$.select((state) => state.search.ui.view);
	documentToConfirm$ = new Subject<SearchResultModel | null>();
	quickEditExpanded = new Set<string>();
	devMode = inject(UiStore).devMode;
	showMetadata = this.uiService.showMetadata;
	teams = inject(UserStore).profile.teams;
	resultsLoading$: Observable<boolean | undefined> = this.store$.select(
		(state) => state.search.query.isLoading,
	);
	showWarning: boolean = this.uiService.showWarning;
	showFeaturedContentFromPreset$!: Observable<string | null>;

	constructor(
		private store$: Store<SearchFeatureState>,
		private analytics: AnalyticsService,
		private postQueryService: PostQueryService,
		private resultsService: ResultsService,
		private visitedService: VisitedService,
		private uiService: UIService,
		public route: ActivatedRoute,
		private readonly copyService: CopyLinkService,
		private readonly toastService: ToastService,
		private readonly previewService: PreviewService,
		private readonly modalService: ModalService,
	) {}
	private downloadService = inject(DownloadService);

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit(): void {
		this.showFeaturedContentFromPreset$ = combineLatest([
			this.store$.select((state) => state.search.selectedFilters),
			this.view$,
		]).pipe(
			map(([filters, view]) => {
				// We only want to show featured content in list view
				if (view !== 'list') {
					return null;
				}

				// When user search for Case Studies we show Featured content from Case Studies preset
				const isCaseStudySearch = filters.include.documentTags.includes(
					'"ǂǂ43617365207374756479"',
				); // 'Case study'
				if (isCaseStudySearch) {
					// TODO: provide preset link to Case Studies preset
					return 'cases';
				}

				// By default we show Featured content from 'sp-suggest' preset (if no filters are applied)
				const isDefaultFiltersApplied = Object.keys(filters.include)
					.filter((filter) => filter !== 'modifiedDate')
					.every(
						(filter) => !filters.include[filter as keyof typeof filters.include].length,
					);
				if (isDefaultFiltersApplied) {
					return 'sp-suggest';
				}

				// Otherwise we don't show Featured content
				return null;
			}),
		);
	}

	@HostListener('window:scroll')
	scroll(): void {
		const threshold = 0.95;
		const target = document.documentElement;
		const scrollTop = window.scrollY;

		if (
			(scrollTop + target.clientHeight) / target.scrollHeight > threshold &&
			this.requestNextPage()
		) {
			this.store$.dispatch(new RequestNextPageAction());
		}
	}

	openDocument(
		searchResult: SearchResultModel,
		results: SearchResultModel[],
		event?: MouseEvent,
	): void {
		if (event?.ctrlKey || event?.metaKey) {
			return;
		}
		switch (searchResult.openingMethod) {
			case OpeningMethods.EXTERNAL:
				const filePath =
					searchResult.fileExtension === 'page'
						? searchResult.importSource ?? ''
						: searchResult.filePath;

				this.downloadService.openPageInNewTab(filePath);
				break;
			case OpeningMethods.PREVIEW:
				this.openPreview(searchResult, results);
				break;
			case OpeningMethods.DOWNLOAD:
			default:
				this.downloadService.downloadFile(searchResult.filePath);
				break;
		}

		if (event) {
			event.preventDefault();
		}

		this.visitedService.mark(searchResult.filePath);
	}

	showDuplicates(result: SearchResultModel, event: Event): void {
		event.preventDefault();
		this.store$.dispatch(new ChangeGroupIdAction(result.documentId));
	}

	queueDownload(result: SearchResultModel): void {
		this.documentToConfirm$.next(result);
		this.visitedService.mark(result.filePath);
	}

	handleConfirmation(document?: SearchResultModel): void {
		if (document) {
			this.analytics.trackSearchResultsAction(
				document.isDocumentSource ? 'downloadSourceDocument' : 'download',
				document.filePath,
			);
		}

		this.documentToConfirm$.next(null);
	}

	downloadSource(result: SearchResultModel): void {
		const url = result.sourceFilePath;
		if (url === undefined) {
			return;
		}
		this.toastService.show({type: 'info', message: 'Searching for document source...'});
		this.subscriptions.add(
			this.postQueryService
				.retrieve$({query: `path="${url}"`}, {}, new Set())
				.pipe(
					tap((data) => {
						const [sourceResult] = this.resultsService.extractData(
							data.d.postquery.PrimaryQueryResult.RelevantResults,
						).data;

						// * sometimes can happen that source file doesn't exist  on the server,
						// * even if we found documentSourceUrl through getDocumentSource()
						if (!sourceResult) {
							this.analytics.trackException(`Cannot find document source: ${url}`);
							this.toastService.show({
								type: 'error',
								message: 'Cannot find document source',
							});

							return;
						}

						this.queueDownload({
							...sourceResult,
							filePath: url,
							isDocumentSource: true,
						});
					}),
				)
				.subscribe(),
		);
	}

	toggleQuickEdit(documentId: string): void {
		if (this.quickEditExpanded.has(documentId)) {
			this.quickEditExpanded.delete(documentId);
		} else {
			this.quickEditExpanded.add(documentId);
			this.analytics.trackQuickEditActions('open');
		}
	}

	visited(filePath: string): boolean {
		return this.visitedService.check(filePath);
	}

	triggerAction(
		action: ThumbnailAction,
		result: SearchResultModel,
		results: SearchResultModel[],
	): void {
		switch (action) {
			case 'DOWNLOAD':
				this.queueDownload(result);
				break;
			case 'DOWNLOAD_SOURCE':
				this.downloadSource(result);
				break;
			case 'COPY':
				this.copyService.handleCopied(true);
				break;
			case 'PREVIEW':
				this.openDocument(result, results);
				break;
			default:
				this.analytics.trackException(`Unknown action triggered: ${action as string}`);
				break;
		}
	}

	private openPreview(searchResult: SearchResultModel, results: SearchResultModel[]): void {
		const documentId = searchResult.importSourceDocId ?? '';
		const isNewPreview =
			documentId.startsWith('EPAMSTORY-1389392072-') || // Stories
			documentId.startsWith('EPAMSTORY-1162883870-') || // Cases
			documentId.startsWith('EPAMSTORY-190753167-'); // Toolbox

		if (isNewPreview) {
			this.previewService.openPreviewDialogFromSearch(documentId, searchResult.title);
		} else {
			this.previewData = this.preparePreviewData(searchResult);

			const previewMenuInstance = this.modalService.openModal(PreviewMenuComponent, {
				type: 'PREVIEW-MENU',
				showCloseButton: false,
				data: {
					model: searchResult,
					data: this.previewData,
					context: results,
				},
			});

			this.subscriptions.add(
				previewMenuInstance.queueDownload
					.pipe(
						tap((document: SearchResultModel) => {
							this.queueDownload(document);
						}),
					)
					.subscribe(),
			);

			this.subscriptions.add(
				previewMenuInstance.queueDownloadSource
					.pipe(
						tap((document: SearchResultModel) => {
							this.downloadSource(document);
						}),
					)
					.subscribe(),
			);
		}
	}

	private searchResults = this.store$.selectSignal((state) => state.search.results);
	private requestNextPage(): boolean {
		const results = this.searchResults();

		return results.count > results.data.length && !results.pageLoading;
	}

	private preparePreviewData(model: SearchResultModel): PreviewModel {
		const results = this.searchResults().data;
		const index = results.findIndex((result) => result.documentId === model.documentId);
		const query = this.store$.selectSignal((state) => state.search.query.submittedQuery)();

		return {
			query,
			index,
		};
	}
}
