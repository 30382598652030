export const languageMapping = {
	af: 'Afrikaans',
	sq: 'Albanian',
	ar: 'Arabic',
	hy: 'Armenian',
	az: 'Azerbaijani',
	bn: 'Bangla',
	eu: 'Basque (Basque)',
	be: 'Belarusian',
	bs: 'Bosnian',
	br: 'Breton',
	bg: 'Bulgarian',
	ca: 'Catalan',
	'zh-cn': 'Chinese Simplified',
	'zh-tw': 'Chinese Traditional',
	hr: 'Croatian',
	cs: 'Czech',
	da: 'Danish',
	nl: 'Dutch',
	en: 'English',
	et: 'Estonian',
	fo: 'Faeroese',
	fa: 'Farsi',
	tl: 'Filipino',
	fi: 'Finnish',
	fr: 'French',
	fy: 'Frisian',
	gl: 'Galician',
	ka: 'Georgian',
	de: 'German',
	el: 'Greek',
	gu: 'Gujarati',
	ha: 'Hausa',
	he: 'Hebrew',
	hi: 'Hindi',
	hu: 'Hungarian',
	is: 'Icelandic',
	id: 'Indonesian',
	ga: 'Irish Gaelic',
	it: 'Italian',
	ja: 'Japanese',
	kn: 'Kannada',
	kk: 'Kazakh',
	ky: 'Kirghiz',
	ko: 'Korean',
	ku: 'Kurdish',
	la: 'Latin',
	lv: 'Latvian',
	lt: 'Lithuanian',
	lb: 'Luxembourgish',
	mk: 'Macedonian',
	ms: 'Malay',
	ml: 'Malayalam',
	mt: 'Maltese',
	mi: 'Maori',
	mr: 'Marathi',
	mn: 'Mongolian',
	nb: 'Norwegian Bokmål',
	nn: 'Norwegian Nynorsk',
	ps: 'Pashto',
	pl: 'Polish',
	pt: 'Portuguese',
	pa: 'Punjabi',
	ro: 'Romanian',
	rm: 'Romansh',
	ru: 'Russian',
	se: 'Sami Nothern',
	sr: 'Serbian',
	sk: 'Slovak',
	sl: 'Slovenian',
	wen: 'Sorbian',
	es: 'Spanish',
	sw: 'Swahili',
	sv: 'Swedish',
	ta: 'Tamil',
	te: 'Telugu',
	th: 'Thai',
	tr: 'Turkish',
	uk: 'Ukrainian',
	ur: 'Urdu',
	uz: 'Uzbek',
	vi: 'Vietnamese',
	cy: 'Welsh',
	yi: 'Yiddish',
	zu: 'Zulu',
};
