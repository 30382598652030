import {Injectable} from '@angular/core';
import {Observable, map, of} from 'rxjs';
import {DocumentItemParserService} from '../../shared/api/document-item-parser.service';
import {SearchQueryService} from './search-query.service';
import {SearchStateSerializerService} from './search-state-serializer.service';
import {DocumentsFragment, EMPTY_DOCUMENT_FRAGMENT} from '@search2/models';
import {DocDetailsItem, DocumentIndexItem} from '@shared/api';

@Injectable({providedIn: 'root'})
export class RelatedDocumentsService {
	constructor(
		private readonly searchService: SearchQueryService,
		private readonly searchStateSerializer: SearchStateSerializerService,
		private readonly searchResultParser: DocumentItemParserService,
	) {}

	getRelatedDocuments$(docDetails: DocDetailsItem): Observable<RelatedDocumentsSet> {
		const getItemType = this.searchResultParser.getItemType.bind(this);
		const document = docDetails.document;
		const documentType = getItemType(document);
		const collections = (document.spoPresales?.caseStudyCollection ?? []).map(
			(x) => x.rawValue,
		);

		return this.getStoriesAndOfferings$(collections).pipe(
			map((items) => {
				const relatedStories: DocumentIndexItem[] = [];
				const relatedStoriesCollections: string[] = [];
				const relatedOfferings: DocumentIndexItem[] = [];
				const relatedOfferingsCollections: string[] = [];
				let collectionName: string | undefined;

				collections.forEach((collection) => {
					const collectionItems = items.filter((item) =>
						(item.spoPresales?.caseStudyCollection ?? [])
							.map((x) => x.rawValue)
							.includes(collection),
					);

					// `collectionItems.length === 0` is only when current document is case study
					/// and collection doesn't contain stories or offerings, so we ignore it
					if (collectionItems.length === 1) {
						const item = collectionItems[0];
						if (item.id === document.id) {
							if (collectionName === undefined) {
								collectionName = collection;
							}
						} else {
							if (getItemType(item) === 'story') {
								relatedStories.push(item);
								relatedStoriesCollections.push(collection);
							} else {
								relatedOfferings.push(item);
								relatedOfferingsCollections.push(collection);
							}
						}
					} else if (collectionItems.length > 1) {
						const stories = collectionItems.filter((x) => getItemType(x) === 'story');
						if (documentType === 'case' || documentType === 'offering') {
							stories.forEach((x) => relatedStories.push(x));
							relatedStoriesCollections.push(collection);
						} else if (documentType === 'story') {
							if (
								stories.length === 1 &&
								stories[0].id === document.id &&
								collectionName === undefined
							) {
								collectionName = collection;
							}
						}

						const offerings = collectionItems.filter(
							(x) => getItemType(x) === 'offering' && x.id !== document.id,
						);
						if (documentType === 'story' || documentType === 'offering') {
							offerings.forEach((x) => relatedOfferings.push(x));
							relatedOfferingsCollections.push(collection);
						}
					}
				});

				const visibleDocumentIds = relatedStories
					.map((x) => x.id)
					.concat(relatedOfferings.map((x) => x.id));
				let recommendationIds = (docDetails.recommendations ?? []).filter(
					(id) => !visibleDocumentIds.includes(id),
				);
				if (recommendationIds.length > 8) {
					recommendationIds = recommendationIds.slice(0, 8);
				}

				return {
					relatedStories: this.createDocumentFragment(
						relatedStories,
						relatedStoriesCollections,
						'stories',
					),
					relatedOfferings: this.createDocumentFragment(
						relatedOfferings,
						relatedOfferingsCollections,
						'offerings',
					),
					collectionName,
					recommendationIds,
				};
			}),
		);
	}

	private getStoriesAndOfferings$(collections: string[]): Observable<DocumentIndexItem[]> {
		if (collections.length === 0) {
			return of([]);
		}

		const query = this.searchService.createQuery({
			presetId: 'sp-suggest',
			facetFilters: {
				entity_type: ['Presales|Offerings', 'Presales|Story'],
				'spo_presales.case_study_collection.keyword': [...new Set(collections)],
			},
			size: 100,
		});

		return this.searchService
			.search$(query)
			.pipe(map((resp) => resp.results.map((x) => x.document)));
	}

	private createDocumentFragment(
		items: DocumentIndexItem[],
		collections: string[],
		presetId: string,
	): DocumentsFragment {
		if (items.length === 0) {
			return EMPTY_DOCUMENT_FRAGMENT;
		}

		const uniqueItems: DocumentIndexItem[] = [];
		items
			.sort((a, b) => b.downloadCount - a.downloadCount)
			.forEach((item) => {
				if (
					uniqueItems.length === 0 ||
					uniqueItems[uniqueItems.length - 1].id !== item.id
				) {
					uniqueItems.push(item);
				}
			});

		return {
			items: uniqueItems,
			totalCount: items.length,
			presetLink: {
				presetId,
				query: this.searchStateSerializer.getQueryObject({
					selectedFilters: {
						'spo_presales.case_study_collection.keyword': collections,
					},
				}),
			},
		};
	}
}

export interface RelatedDocumentsSet {
	relatedStories: DocumentsFragment;
	relatedOfferings: DocumentsFragment;
	collectionName?: string;
	recommendationIds: string[];
}
