import {Action} from '@ngrx/store';

import {FilterModel} from '@search/models';

export const UPDATE_LANGUAGES_FILTER = '[Search] Update Languages Filter';

export class UpdateLanguagesFilterAction implements Action {
	readonly type = UPDATE_LANGUAGES_FILTER;

	constructor(public payload: FilterModel[]) {}
}
