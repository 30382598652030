import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Actions, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';

import {from, Subscription, zip} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import * as actions from '../../actions';
import {FiltersService, SearchService} from '../../services';
import {resultsInitialState} from '@search/reducers/results/results-initial-state';
import {SearchFeatureState} from '@search/reducers/search-state';
import {ResultsService, rangeFiltersService} from '@shared/services';

@Component({
	template: '',
	selector: 'search-query-effects',
	standalone: true,
})
export class QueryEffectsComponent implements OnInit, OnDestroy {
	private subscription: Subscription = new Subscription();

	constructor(
		private store$: Store<SearchFeatureState>,
		private actions$: Actions,
		private searchService: SearchService,
		private filtersService: FiltersService,
		private resultsService: ResultsService,
		private route: ActivatedRoute,
	) {}

	ngOnInit(): void {
		this.subscription.add(this.changeQueryOptions());
		this.subscription.add(this.submitQuery());
		this.subscription.add(this.getAdditionalRefinements());
		this.subscription.add(this.getModifiedDateRefinements());
		this.subscription.add(this.requestNextPage());
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private changeQueryOptions(): Subscription {
		return this.actions$
			.pipe(
				ofType(
					actions.CHANGE_SORT_BY,
					actions.CHANGE_GROUP_ID,
					actions.TOGGLE_QUERY_TEMPLATE,
				),
				map(() => new actions.SubmitQueryAction()),
				tap((action) => {
					this.store$.dispatch(action);
				}),
			)
			.subscribe();
	}

	private submitQuery(): Subscription {
		// Make query request depended on route updates
		// because the first action should update url then we do request with new url
		// and get correct result.
		// ! Case: a user has expired session and typed a new query and typed Enter.
		// ! Then after redirection of getting new fedAuth cookie
		// ! we have to show correct search results with the typed query earlier
		return zip(this.route.queryParams, this.actions$.pipe(ofType(actions.SUBMIT_QUERY)))
			.pipe(
				switchMap(() =>
					this.searchService.getSearchResults$({excludeRefiners: ['modifiedDate']}).pipe(
						map((data) => {
							// TODO: Refactor
							let result: Action[] = [];

							if (data) {
								const requestedRefiners = data.requestedRefiners;
								const {postquery} = data.d;
								const primaryResults = postquery.PrimaryQueryResult;
								const actionList = this.filtersService.extractData(
									primaryResults.RefinementResults,
									requestedRefiners,
								);
								const resultData = this.resultsService.extractData(
									primaryResults.RelevantResults,
									postquery.SpellingSuggestion,
									postquery.Properties.results,
								);

								window.scrollTo(0, 0);

								result = [
									new actions.ChangeFilterSearchAction(''),
									new actions.SubmitQuerySuccessAction(),
									...actionList,
									new actions.UpdateResultsAction(resultData),
								];
							} else {
								result = [
									new actions.ResetFiltersAction(),
									new actions.SubmitQueryFailAction(),
									new actions.UpdateResultsAction(resultsInitialState),
								];
							}

							return result;
						}),
						catchError((error: unknown) => {
							const syntaxErrorMessage =
								"We didn't understand your search terms. Make sure they're using proper syntax.";
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							const err = error as any;
							const errorMessage = err?.error?.error?.message?.value;

							if (errorMessage !== syntaxErrorMessage) {
								throw err;
							}

							return from([null]);
						}),
					),
				),
				tap((actionList) => {
					actionList?.forEach((action) => {
						this.store$.dispatch(action);
					});
				}),
			)
			.subscribe();
	}

	private getAdditionalRefinements(): Subscription {
		return this.actions$
			.pipe(
				ofType(actions.SUBMIT_QUERY),
				switchMap(() => this.searchService.getRefinements$('lazy')),
				map(({d, requestedRefiners}) => {
					const primaryResults = d.postquery.PrimaryQueryResult;
					const actionList = this.filtersService.extractData(
						primaryResults.RefinementResults,
						requestedRefiners,
					);

					return actionList;
				}),
				tap((actionList) => {
					actionList.forEach((action) => {
						this.store$.dispatch(action);
					});
				}),
			)
			.subscribe();
	}

	private getModifiedDateRefinements(): Subscription {
		return this.actions$
			.pipe(
				ofType(actions.SUBMIT_QUERY),
				switchMap(() => {
					const section = new Set(['modifiedDate']);
					const rangeDate: string = rangeFiltersService.getDates().join('/');
					const customRefiner = [
						{
							id: 'modifiedDate',
							name: 'Write',
							config: `discretize=manual/${rangeDate}`,
						},
					];

					return this.searchService
						.withoutFiltersStore(['modifiedDate'])
						.getRefinements$('regular', section, customRefiner)
						.pipe(
							map(({requestedRefiners, d}) =>
								this.filtersService.extractData(
									d.postquery.PrimaryQueryResult.RefinementResults,
									requestedRefiners,
								),
							),
						);
				}),
				tap((actionList) => {
					actionList.forEach((action) => {
						this.store$.dispatch(action);
					});
				}),
			)
			.subscribe();
	}

	private requestNextPage(): Subscription {
		return this.actions$
			.pipe(
				ofType(actions.REQUEST_NEXT_PAGE),
				switchMap(() => this.searchService.getNextPage$()),
				map((data) => {
					const {postquery} = data.d;
					const primaryResults = postquery.PrimaryQueryResult;

					if (primaryResults.RelevantResults === null) {
						return new actions.EmptyAction();
					}

					const resultsModel = this.resultsService.extractData(
						primaryResults.RelevantResults,
						postquery.SpellingSuggestion,
						postquery.Properties.results,
					);

					return new actions.AppendResultsAction(resultsModel);
				}),
				tap((action) => {
					this.store$.dispatch(action);
				}),
			)
			.subscribe();
	}
}
