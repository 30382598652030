@if (isShowWidget$ | async) {
	<div class="search-filters-widget" *ppAnchor="'search-filter-section'; with: label">
		<header class="search-filters-widget-header" (click)="toggle()">
			{{ label }}
			<div class="search-filters-widget-actions">
				@if (isLoading()) {
					<pp-spinner type="uui" size="small"></pp-spinner>
				}
				@if (hasSelectedFilters$ | async) {
					<button
						class="search-filters-widget-reset dp-button-null"
						title="Reset"
						(click)="reset($event)"
					>
						<pp-icon name="navigation_refresh_2_18" />
					</button>
				}

				<button
					class="search-filters-widget-chevron dp-button-null"
					[class.collapsed]="(expanded$ | async) === false"
				>
					<pp-icon name="navigation-chevron-down-18" />
				</button>
			</div>
		</header>
		<ng-content select="[filterSectionFiltersSelector]"></ng-content>

		@if (expanded$ | async) {
			<ng-content select="[filterSectionListSelector]"></ng-content>
		}
	</div>
}
