import {ActiveDescendantKeyManager} from '@angular/cdk/a11y';
import {ConnectionPositionPair, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
	AfterContentInit,
	AfterViewInit,
	ContentChildren,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostListener,
	OnDestroy,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	inject,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import {defer, fromEvent, merge, Subscription} from 'rxjs';
import {distinctUntilChanged, startWith, switchMap, take, tap} from 'rxjs/operators';
import {toObservable} from '@angular/core/rxjs-interop';
import {OptionComponent} from './option/option.component';
import {PanelComponent} from './panel/panel.component';
import {SvgIconComponent} from '@pp/svg';
import {focusOnInput} from '@shared/utils';
import {UiStore} from '@shared/state';

@Component({
	exportAs: 'autocomplete',
	selector: 'shared-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AutocompleteComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [SvgIconComponent, ReactiveFormsModule, PanelComponent],
})
export class AutocompleteComponent
	implements ControlValueAccessor, OnDestroy, AfterContentInit, AfterViewInit
{
	@Input() set defaultValue(value: string) {
		this.control.setValue(value);
	}
	@Output() isPanelOpened = new EventEmitter<boolean>();

	@Input()
	get value(): string {
		return this.control.value;
	}

	set value(value: string) {
		this.control.setValue(value);
		this.onChange(value);
	}

	private keyManager!: ActiveDescendantKeyManager<OptionComponent>;
	private overlayRef?: OverlayRef;

	private readonly subscriptions = new Subscription();

	@ViewChild(TemplateRef, {static: true}) template!: TemplateRef<unknown>;

	@ViewChild('inputControl', {static: false}) inputControl!: ElementRef;

	@Input() placeholder = '';

	@ContentChildren(OptionComponent, {descendants: true}) options!: QueryList<OptionComponent>;
	readonly optionSelections$ = defer(() =>
		this.options.changes.pipe(
			startWith(this.options.toArray()),
			switchMap((options) =>
				merge(...options.map((option: OptionComponent) => option.selectionChange)),
			),
		),
	);

	control: FormControl = new FormControl('', {updateOn: 'change'});

	panelOpened = false;
	focused = false;

	@Output() readonly changeValue: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private readonly elementRef: ElementRef,
		private readonly viewContainerRef: ViewContainerRef,
		private readonly overlay: Overlay,
	) {}

	private isMobileMode$ = toObservable(inject(UiStore).window.isMobileMode);
	ngAfterViewInit() {
		this.subscriptions.add(
			this.isMobileMode$
				.pipe(
					take(1),
					tap((isMobileMode) => {
						if (!isMobileMode) {
							this.control.setValue(this.control.value);
						}
					}),
				)
				.subscribe(),
		);
		this.subscriptions.add(
			merge(
				fromEvent(this.inputControl.nativeElement, 'keyup'),
				fromEvent(this.inputControl.nativeElement, 'click'),
				// we need to listen window click to close panel because 'popover-overlay-panel' move backdrop overlay down
				// this is needed to allow double click on input value
				fromEvent(window, 'click'),
			)
				.pipe(
					tap((event) => {
						if (
							(event instanceof KeyboardEvent &&
								(event.key === 'Escape' || event.key === 'Enter')) ||
							(event instanceof MouseEvent &&
								event.target !== this.inputControl.nativeElement)
						) {
							this.closePanel();

							return;
						}

						if (!this.panelOpened && !!this.options.length) {
							this.openPanel();
							requestAnimationFrame(() => this.updateSize());
						}
					}),
				)
				.subscribe(),
		);

		this.subscriptions.add(
			this.optionSelections$.pipe(tap(() => this.closePanel())).subscribe(),
		);
	}

	focusOnInput(): void {
		focusOnInput(this.inputControl.nativeElement);
	}

	@HostListener('keydown', ['$event'])
	handleKeyDown(event: KeyboardEvent): void {
		if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
			if (!this.panelOpened) {
				this.openPanel();
			}

			this.keyManager.onKeydown(event);
			event.preventDefault();

			return;
		}

		if (event.key === 'Enter') {
			const activeItemIndex = this.keyManager.activeItemIndex;

			if (!this.panelOpened || activeItemIndex === -1) {
				this.changeValue.emit(this.control.value);
				this.closePanel();
			} else if (this.panelOpened && (activeItemIndex ?? 0) >= 0) {
				const activeItem = this.keyManager.activeItem;
				this.select(activeItem as OptionComponent);
			}

			event.preventDefault();

			return;
		}

		if (event.key === 'Escape') {
			this.closePanel();
			event.preventDefault();

			return;
		}
	}

	setActiveItem(index: number): void {
		this.keyManager.setActiveItem(index);
	}

	select(item: OptionComponent): void {
		item.select();
	}

	onBlur(): void {
		this.focused = false;
		this.onTouched();
	}

	onFocus(): void {
		this.focused = true;
	}

	controlReset(): void {
		this.control.reset('');
		this.changeValue.emit('');
	}

	registerOnChange(fn: () => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	writeValue(value: string): void {
		this.value = value;
	}

	ngAfterContentInit(): void {
		this.keyManager = new ActiveDescendantKeyManager<OptionComponent>(this.options).withWrap();
		this.subscriptions.add(
			this.control.valueChanges
				.pipe(
					distinctUntilChanged(),
					tap((value) => {
						this.onChange(value);

						if (this.canOpen() && !!this.options.length) {
							this.openPanel();
						}
					}),
				)
				.subscribe(),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	closePanel(): void {
		this.panelOpened = false;
		this.keyManager.setActiveItem(-1);
		this.inputControl.nativeElement.blur();

		if (this.overlayRef && this.overlayRef.hasAttached()) {
			this.destroyOverlay();
			this.isPanelOpened.emit(false);
		}
	}

	canOpen(): boolean {
		return this.focused && !this.panelOpened;
	}

	openPanel(): void {
		if (this.overlayRef?.hasAttached()) {
			return;
		}

		this.overlayRef = this.createOverlay();
		this.overlayRef.hostElement.classList.add('popover-overlay-panel');

		const portal = new TemplatePortal(this.template, this.viewContainerRef, null);

		this.overlayRef.attach(portal);
		this.isPanelOpened.emit(true);
		this.subscriptions.add(
			this.overlayRef
				.backdropClick()
				.pipe(
					tap(() => {
						this.closePanel();
					}),
				)
				.subscribe(),
		);

		this.panelOpened = true;
	}

	// eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
	private onChange: (value: string) => void = () => {};
	// eslint-disable-next-line no-empty, no-empty-function, @typescript-eslint/no-empty-function
	private onTouched: () => void = (): void => {};

	private createOverlay(): OverlayRef {
		const scrollStrategy = this.overlay.scrollStrategies.reposition();
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(this.elementRef)
			.withFlexibleDimensions(false)
			.withPush(false)
			.withPositions([
				new ConnectionPositionPair(
					{originX: 'start', originY: 'bottom'},
					{overlayX: 'start', overlayY: 'top'},
					0,
					3,
				),
				new ConnectionPositionPair(
					{originX: 'start', originY: 'top'},
					{overlayX: 'start', overlayY: 'bottom'},
					0,
					-3,
				),
			]);

		return this.overlay.create({
			scrollStrategy,
			positionStrategy,
			hasBackdrop: true,
			backdropClass: 'popover-overlay-panel',
			width: this.elementRef.nativeElement.getBoundingClientRect().width,
		});
	}

	private updateSize(width = this.elementRef.nativeElement.getBoundingClientRect().width): void {
		this.overlayRef?.updateSize({width});
		this.overlayRef?.updatePosition();
	}

	private destroyOverlay(): void {
		if (!this.overlayRef) {
			return;
		}

		this.overlayRef.dispose();
		this.overlayRef = undefined;
	}
}
