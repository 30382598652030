import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit,
	OnDestroy,
	inject,
	forwardRef,
} from '@angular/core';
import {Subscription, tap} from 'rxjs';
import {CdkDragDrop, CdkDrag, CdkDragHandle, CdkDropList} from '@angular/cdk/drag-drop';
import {NgClass} from '@angular/common';
import {DocumentsPickerComponent} from '../documents-picker/documents-picker.component';
import {SvgIconComponent} from '@pp/svg';
import {CURRENT_MODAL, ModalRef} from '@shared/modals';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';
import {PreviewService} from '@preview/services';
import {UserStore} from '@shared/state';
import {PreviewStore} from '@preview/document/preview.store';
import {PopoverService} from '@shared/popover';
import {DeleteConfirmationComponent} from '@preview/document/delete-confirmation/delete-confirmation.component';
import {SearchItemCardComponent} from '@preview/item-card';
import {DocAttachment} from '@shared/api';

@Component({
	selector: 'search-edit-linked-documents',
	templateUrl: './edit-linked-documents.component.html',
	styleUrls: ['./edit-linked-documents.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [PopoverService],
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () =>
				new ContextualAnalyticsService(inject(AnalyticsService), 'Document Preview'),
		},
	],
	standalone: true,
	imports: [
		CdkDrag,
		CdkDragHandle,
		SvgIconComponent,
		CdkDropList,
		NgClass,
		forwardRef(() => SearchItemCardComponent),
		DocumentsPickerComponent,
	],
})
export class EditLinkedDocumentsComponent implements OnInit, OnDestroy {
	private previewStore = inject(PreviewStore);
	documentId = this.previewStore.documentId;
	linkedDocuments = this.previewStore.linkedDocuments;

	private readonly subscriptions = new Subscription();
	theme = inject(UserStore).settings.uiTheme;

	constructor(
		@Inject(CURRENT_MODAL) readonly modalRef: ModalRef,
		private readonly popOverService: PopoverService,
		private readonly previewService: PreviewService,
	) {}

	ngOnInit() {
		this.previewStore.loadLinkedDocuments(this.documentId());

		this.subscriptions.add(
			this.previewService.openPreviewTrigger$
				.pipe(
					tap(() => {
						this.closeDialog();
					}),
				)
				.subscribe(),
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	closeDialog(): void {
		this.modalRef.close();
	}

	drop(documents: DocAttachment[], event: CdkDragDrop<DocAttachment[]>): void {
		const draggedDocument = documents[event.previousIndex];

		this.previewStore.moveLinkedDocument({
			documentId: this.documentId(),
			linkedDocument: draggedDocument,
			newIndex: event.currentIndex,
		});
	}

	openDeleteConfirmation(event: Event, linkedDocument: DocAttachment): void {
		const deleteConfirmation = this.popOverService.openPopOver(
			DeleteConfirmationComponent,
			{
				itemToDeleteName: linkedDocument.document.title.rawValue,
				itemToDeleteType: 'document',
			},
			event.target as HTMLElement,
			'left',
			true,
		);

		this.subscriptions.add(
			deleteConfirmation.confirmDeletingEmitter
				.pipe(
					tap(() => {
						this.deleteDocument(linkedDocument.id);
					}),
				)
				.subscribe(),
		);
	}

	private deleteDocument(attachmentId: number): void {
		this.previewStore.deleteLinkedDocument({
			documentId: this.documentId(),
			attachmentId,
		});
	}
}
