import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

import {SEARCH_EFFECTS} from './effects';
import {reducers} from './reducers/reducers';

@NgModule({
	imports: [StoreModule.forFeature('search', reducers), EffectsModule.forFeature(SEARCH_EFFECTS)],
})
export class SearchModule {}
