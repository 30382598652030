<div class="shared-with-popup">
	<div class="shared-with-popup-header">
		<div class="shared-with-popup-header-container">
			<div class="shared-with-popup-header-icon" [class]="accessLevel.type">
				<pp-icon name="action-lock-fill-24" />
			</div>

			@switch (accessLevel.type) {
				@case (accessLevelType.EVERYONE) {
					<p>
						@if (isExternal) {
							Access rules for this item are managed by external system.
						} @else {
							<strong>{{ result.owner }}</strong> team (document owner) shared
							@if (result.locationTree) {
								<strong>'{{ result.locationTree[0] }}'</strong>
							}
							folder with <strong>Everyone in Company</strong>.
						}
					</p>
				}
				@case (accessLevelType.TRUSTED_USERS) {
					<p>
						<strong>{{ result.owner }}</strong> team (owner) shared
						@if (result.locationTree) {
							<strong>'{{ result.locationTree[0] }}'</strong>
						}
						folder with group of <strong>Trusted Users</strong> who received approvals
						through
						<a
							href=""
							target="_blank"
							>Company&nbsp;Support</a
						>.
					</p>
				}
				@case (accessLevelType.PRESALES_GROUPS) {
					<p>
						<strong>{{ result.owner }}</strong> team (document owner) shared
						@if (result.locationTree) {
							<strong>'{{ result.locationTree[0] }}'</strong>
						}
						folder only with <strong>Presales</strong> groups.
					</p>
				}
				@case (accessLevelType.SELECTED_USERS) {
					<p>
						<strong>{{ result.owner }}</strong> team (document owner) shared
						@if (result.locationTree) {
							<strong>'{{ result.locationTree[0] }}'</strong>
						}
						folder only with <strong>Selected users</strong> (including you).
					</p>
				}
			}
		</div>
	</div>
	@if (aclList$ | async; as aclList) {
		<div class="shared-with-popup-content">
			@if (aclList.value) {
				<h3 class="shared-with-popup-content-header">Shared with</h3>
				<a
					class="shared-with-popup-content-action"
					target="_blank"
					>Show all in Telescope</a
				>

				@for (acl of aclList.value; track acl.name) {
					<div class="shared-with-popup-row">
						<a
							class="shared-with-popup-row-title"
							target="_blank"
							>{{ acl.name }}<pp-icon name="communication-telescope-18"
						/></a>
						@for (member of acl.members; track member.email) {
							<p class="shared-with-popup-row-email">
								{{ member.email }}
							</p>
						}
					</div>
				}
			}
			@if (aclList.loading) {
				<pp-spinner
					size="medium"
					class="search-results-loading-spinner"
					type="uui"
				></pp-spinner>
			}
			@if (aclList.error) {
				<div class="shared-with-popup-content-error">
					<h4>Something went wrong</h4>
					<p>Please, try again later</p>
				</div>
			}
		</div>
	}
</div>
