import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {CONFIG, EnvConfigType} from '@environments/environment';
import {SearchTrendModel} from '@shared/models';

@Injectable({providedIn: 'root'})
export class SearchTrendsService {
	constructor(
		private http: HttpClient,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	getGlobalTrends$(count = 50): Observable<SearchTrendModel[]> {
		const fullUrl = `${this.config.serviceUrl}/api/search/trends/${count}`;

		return this.http.get<SearchTrendModel[]>(fullUrl, {headers: this.prepareHeaders()});
	}

	private prepareHeaders(): HttpHeaders {
		const headers = new HttpHeaders({
			Accept: 'application/json',
		});

		return headers;
	}
}
