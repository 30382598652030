import {ChangeDetectionStrategy, Component, Input, OnInit, inject} from '@angular/core';
import {Observable, of, map} from 'rxjs';
import {distinctUntilChanged, switchMap, debounceTime} from 'rxjs/operators';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {AsyncPipe} from '@angular/common';
import {UsppUserVm} from '../uspp-user-vm';
import {PreviewStore} from '@preview/document/preview.store';
import {SearchClient, UsppUserInfo} from '@shared/api';
import {PreviewUserInfoComponent} from '@shared/access';
import {AutocompleteComponent, OptionComponent} from '@shared/components/autocomplete';

@Component({
	selector: 'search-people-picker',
	templateUrl: './people-picker.component.html',
	styleUrls: ['./people-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AutocompleteComponent,
		ReactiveFormsModule,
		OptionComponent,
		PreviewUserInfoComponent,
		AsyncPipe,
	],
})
export class PeoplePickerComponent implements OnInit {
	@Input({required: true}) docId!: string;
	@Input({required: true}) addedContacts!: UsppUserVm[];

	searchQuery = new FormControl('');

	people$!: Observable<UsppUserInfo[]>;
	private readonly debounceTime = 300;

	private previewStore = inject(PreviewStore);
	private searchClient = inject(SearchClient);

	ngOnInit(): void {
		this.people$ = this.searchQuery.valueChanges?.pipe(
			distinctUntilChanged(),
			debounceTime(this.debounceTime),
			switchMap((query) => {
				if (!!query && query.length > 2) {
					return this.searchClient.searchContacts(query, 5);
				}

				return of([]);
			}),
			map((contacts) => {
				const addedContactsId = this.addedContacts.map((contact) => contact.data.aadId);

				return contacts.filter((contact) => addedContactsId.indexOf(contact.aadId) === -1);
			}),
		);
	}

	triggerSelect(person: UsppUserInfo): void {
		this.previewStore.addContact({
			documentId: this.docId,
			contact: person,
			contactType: 'additional',
		});
		this.searchQuery.setValue('');
	}
}
