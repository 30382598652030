<div class="quick-edit-container">
	<header class="quick-edit-header">
		<span
			class="quick-edit-filename"
			[title]="result.fileNameText"
			[innerHTML]="result.fileName"
		></span>
		<button class="quick-edit-details" (click)="showDetails()">Document Details</button>
	</header>
	@if (showMetadata) {
		<div class="quick-edit-content">
			<ul class="quick-edit-tags">
				@for (tag of tags; track tag) {
					<li (click)="filter(tag)">#{{ tag }}</li>
				}
			</ul>
			<ul class="quick-edit-scopes">
				<li>
					<label>
						CV
						@if ({value: isCv$ | async}; as isCv) {
							@if (isCv.value !== null) {
								<pp-checkbox
									[checked]="isCv.value"
									[type]="'switcher'"
									(change)="setProperty('isCv', $event.target)"
								></pp-checkbox>
							}
						}
					</label>
				</li>
				<li>
					<label>
						Case Study
						@if ({value: isCaseStudy$ | async}; as isCaseStudy) {
							@if (isCaseStudy.value !== null) {
								<pp-checkbox
									[checked]="isCaseStudy.value"
									[type]="'switcher'"
									(change)="setProperty('isCaseStudy', $event.target)"
								></pp-checkbox>
							}
						}
					</label>
				</li>
			</ul>
		</div>
	}
</div>
