import {
	PpSearchResultItemOfDocumentIndexItem,
	SearchPresetDefinition,
	PpFacetResult,
	SearchPresetTreeNode,
} from '@shared/api';

export interface SearchState {
	presetsGroups: SearchPresetTreeNode[];
	currentPreset: SearchPresetDefinition | undefined;
	searchQuery: SearchQueryState;
	searchStatus: StatusState;
	searchResults: SearchResultsState;
	facets: SearchFacetsState;
	facetSearchRegex: string;
	isGridViewPreferred: boolean;
}

export interface SearchQueryState {
	presetId: string;
	textQuery: string;
	orderByExpression: string | undefined;
	duplicateGroupId: string | undefined;
	selectedFilters: {[fieldName: string]: string[]};
}

export interface SearchResultsState {
	totalCount: number | undefined;
	items: PpSearchResultItemOfDocumentIndexItem[];
	isQueryRelaxed: boolean;
}
export type StatusState = 'loading' | 'loaded';
export interface SearchFacetsState {
	[fieldName: string]: PpFacetResult[];
}

export const searchInitialState: SearchState = {
	presetsGroups: [],
	currentPreset: undefined,
	searchQuery: {
		presetId: 'undefined',
		textQuery: '',
		orderByExpression: undefined,
		duplicateGroupId: undefined,
		selectedFilters: {},
	},
	searchStatus: 'loading',
	searchResults: {
		totalCount: undefined,
		items: [],
		isQueryRelaxed: false,
	},
	facets: {},
	facetSearchRegex: '',
	isGridViewPreferred: true,
};
