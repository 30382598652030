<header ppCdkUseTheme="dark" class="page-subheader-content">
	<aside class="page-subheader-aside">
		@if (isPreviewPage()) {
			<a
				btn
				btnType="link"
				routerLink="/p/{{ presetId() }}"
				withIconBefore="navigation-back-18"
				(click)="trackBack()"
				>Back to Catalog</a
			>
		}
	</aside>

	@if (!isPreviewPage) {
		<div class="page-subheader-filter-button">
			<button
				btn
				btnType="secondary"
				[onlyIcon]="true"
				[withIconBefore]="isFiltersOpened ? 'navigation-close-18' : 'content-filtration-18'"
				[class.attention]="hasSelectedFilters()"
				(click)="toggleFilters()"
			></button>
		</div>
	}
</header>
