import {Action} from '@ngrx/store';

export const RESET_SELECTED_FILTER = '[Search] Reset Selected Filter';

export class ResetSelectedFilterAction implements Action {
	readonly type = RESET_SELECTED_FILTER;

	constructor(
		public payload: string,
		public initiator?: string,
	) {}
}
