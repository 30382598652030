import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CONFIG, EnvConfigType} from '@environments/environment';
import {AclModel} from '@search/services';

@Injectable({providedIn: 'root'})
export class DocumentAclService {
	constructor(
		private http: HttpClient,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	getAcl$(filePath: string): Observable<AclModel[]> {
		return this.http.get<AclModel[]>(
			`${this.config.serviceUrl}/api/documents/acl?url=${encodeURI(filePath)}`,
		);
	}
}
