import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	NgZone,
	TemplateRef,
} from '@angular/core';
import {NgTemplateOutlet} from '@angular/common';
import {ChainComponent} from '../chain.component';
import {HintDirective} from '@pp/hint';

@Component({
	selector: 'shared-chain-collapse',
	templateUrl: './collapse.component.html',
	styleUrls: ['./collapse.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgTemplateOutlet, HintDirective],
})
export class CollapseComponent implements AfterViewInit, AfterViewChecked {
	@Input({required: true}) chainTemplate!: TemplateRef<ChainComponent>;

	@HostBinding('class.collapse')
	isCollapsed = false;

	collapsed = 0;

	chainRef!: ElementRef;

	constructor(
		private readonly elementRef: ElementRef,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly zone: NgZone,
	) {}

	ngAfterViewInit(): void {
		this.chainRef = new ElementRef(this.elementRef.nativeElement.children[0]);

		// TODO: the chain a children component is not yet rendered, so its property "offsetWidth" is zero
		// TODO: we need to find out the reason and refactored.
		// TODO: problem found in the deck component.
		this.zone.runOutsideAngular(() =>
			requestAnimationFrame(() => this.detectItemCollapse(this.chainRef)),
		);
	}

	ngAfterViewChecked(): void {
		this.detectItemCollapse(this.chainRef);
	}

	@HostListener('window:resize')
	onResize(): void {
		this.detectItemCollapse(this.chainRef);
	}

	private detectItemCollapse({nativeElement}: ElementRef): void {
		const child = [...nativeElement.children];
		const length = child.length;

		for (const children of child) {
			children.classList.remove('collapsed');
		}

		if (length <= 1) {
			return;
		}

		let indexChildCollapsed = child.findIndex((item) =>
			this.hasExceededBoundaries(this.getWidth(nativeElement), this.getWidth(item)),
		);

		if (indexChildCollapsed > -1) {
			this.isCollapsed = true;
			this.collapsed = length - indexChildCollapsed;

			this.changeDetector.detectChanges();
		}

		if (!this.isCollapsed) {
			return;
		}

		if (this.isCollapsed && indexChildCollapsed > 1) {
			const prevChild = child[indexChildCollapsed - 1];

			if (
				this.hasExceededBoundaries(this.getWidth(nativeElement), this.getWidth(prevChild))
			) {
				indexChildCollapsed--;
				this.collapsed++;

				this.changeDetector.detectChanges();
			}
		}

		for (const children of child.slice(indexChildCollapsed)) {
			children.classList.add('collapsed');
		}
	}

	private getWidth({offsetWidth, offsetLeft}: HTMLElement): number {
		return offsetLeft + offsetWidth;
	}

	private hasExceededBoundaries(containerWidth: number, childWidth: number): boolean {
		return childWidth > containerWidth;
	}
}
