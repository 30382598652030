<div class="no-results-container">
	<div class="no-results-icon" aria-hidden="true"></div>
	<header class="no-results-header">
		We did not find any results for
		<span class="no-results-query">"{{ query }}"</span>
	</header>
	<header class="no-results-subheader">Suggestions:</header>
	<ul class="no-results-list">
		<li>Make sure that all words are spelled correctly.</li>
		<li>Try different keywords.</li>
		<li>Try to remove/reset filters.</li>
	</ul>
</div>
