import {FlexibleConnectedPositionStrategy, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {
	ComponentRef,
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnDestroy,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {tap} from 'rxjs/operators';
import {HintComponent} from '@pp/hint';
import {ThemeModeType} from '@shared/theme';
import {getConnectionPosition, PositionDirectionOption} from '@core/popup/position';

@Directive({
	selector: '[hint]',
	standalone: true,
})
export class HintDirective implements OnDestroy {
	private subscription?: Subscription;
	private overlayRef?: OverlayRef;
	private hintRef: ComponentRef<HintComponent> | null = null;

	private messageOverlay: TemplateRef<unknown> | string | null = null;
	@Input('hint')
	get message(): TemplateRef<unknown> | string | null {
		return this.messageOverlay;
	}
	set message(message: TemplateRef<unknown> | string | null) {
		if (message !== this.messageOverlay) {
			this.messageOverlay = message;

			if (this.hintRef) {
				this.hintRef.instance.message = message;
				this.hintRef.changeDetectorRef.detectChanges();
			}

			this.updatePosition();
		}
	}

	private positionOverlay: PositionDirectionOption = 'RIGHT';
	@Input('hintPosition')
	get position(): PositionDirectionOption {
		return this.positionOverlay;
	}
	set position(position: PositionDirectionOption) {
		if (position !== this.positionOverlay) {
			this.positionOverlay = position;

			this.updatePosition();
		}
	}

	private bigHint = false;
	@Input('hintBig')
	get big(): boolean {
		return this.bigHint;
	}
	set big(big: boolean) {
		if (big !== this.bigHint) {
			this.bigHint = big;

			this.updatePosition();
		}
	}

	private offsetOverlay = 6;
	@Input('hintOffset')
	get offset(): number {
		return this.offsetOverlay;
	}
	set offset(offset: number) {
		if (offset !== this.offsetOverlay) {
			this.offsetOverlay = offset;

			this.updatePosition();
		}
	}

	@Input('hintDelay') delay = 100;
	@Input() maxWidth = '380px';
	@Input('hintTriangle') triangle = false;

	private disabledHint = false;
	@Input('hintDisabled')
	get disabled(): boolean {
		return this.disabledHint;
	}
	set disabled(disable: boolean) {
		if (disable !== this.disabledHint) {
			this.disabledHint = disable;

			this.hide();
		}
	}

	constructor(
		private readonly overlay: Overlay,
		private readonly elementRef: ElementRef,
		private readonly viewContainerRef: ViewContainerRef,
	) {}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
		this.hide();
	}

	@HostListener('mouseenter')
	show(): void {
		if (this.disabledHint) {
			return;
		}

		this.subscription = timer(this.delay)
			.pipe(
				tap(() => {
					this.detach();
					this.createOverlay();
					if (this.overlayRef) {
						const portal = new ComponentPortal(HintComponent, this.viewContainerRef);
						this.hintRef = this.overlayRef.attach(portal);

						this.hintRef.instance.message = this.messageOverlay;
						this.hintRef.instance.big = this.bigHint;
						this.hintRef.instance.maxWidth = this.maxWidth;
						this.hintRef.instance.triangle = this.triangle;

						this.hintRef.changeDetectorRef.detectChanges();
					}
					this.updatePosition();
				}),
			)
			.subscribe();
	}

	@HostListener('mouseleave')
	hide(): void {
		this.subscription?.unsubscribe();

		if (this.overlayRef) {
			if (this.overlayRef.hasAttached()) {
				this.overlayRef.detach();
			}
			this.overlayRef.dispose();
			this.hintRef = null;
		}
	}

	private createOverlay(): void {
		const scrollStrategy = this.overlay.scrollStrategies.reposition({scrollThrottle: 20});
		const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef);

		const currentTheme = this.getCurrentTheme(this.elementRef);
		this.overlayRef = this.overlay.create({
			scrollStrategy,
			positionStrategy,
			panelClass: currentTheme === 'light' ? 'dark-theme' : 'light-theme',
		});

		// to reset class of .cdk-overlay-connected-position-bounding-box
		this.overlayRef.hostElement.classList.add('hint-overlay-panel');

		this.updatePosition();
	}

	private getCurrentTheme(elementRef: ElementRef): ThemeModeType {
		const closestWithTheme = elementRef.nativeElement.closest('.dark-theme, .light-theme');

		return closestWithTheme?.classList.contains('light-theme') === true ? 'light' : 'dark';
	}

	private detach(): void {
		if (this.overlayRef && this.overlayRef.hasAttached()) {
			this.overlayRef.detach();
		}
	}

	private updatePosition(): void {
		if (!this.overlayRef) {
			return;
		}

		const position = this.overlayRef.getConfig()
			.positionStrategy as FlexibleConnectedPositionStrategy;
		position.withPositions([
			getConnectionPosition(this.positionOverlay, this.offsetOverlay),
			getConnectionPosition(this.positionOverlay, this.offsetOverlay, true),
		]);

		if (this.overlayRef.hasAttached()) {
			this.overlayRef.updatePosition();
		}
	}
}
