<div
	#dropdownPortalContainer
	tabindex="1"
	(click)="containerClick()"
	(blur)="clickOutside()"
	[ppCdkUseTheme]="theme()"
	class="dropdown-container"
>
	<div class="dp-dropdown-list-inner">
		<ng-template [ngTemplateOutlet]="dropdownContentTemplate"></ng-template>
	</div>
</div>
