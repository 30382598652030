import {Injectable} from '@angular/core';

import {BrowserDetectorService} from '../browser-detector/browser-detector.service';
import {FileTypeMappingService} from '../../api/file-type-mapping.service';
import {UtilsService} from '../utils/utils.service';
import {DocumentTypeMetadata} from './document-type-metadata';
import {SearchResultResponse} from './search-result-response';
import {UsagePermissionType} from '@shared/access/usage-permissions/usage-permission-types.enum';
import {RelevantResults, RelevantResultsRow} from '@shared/services';
import {SearchResultModel, SearchResultsModel} from '@shared/models';
import {OpeningMethods} from '@shared/enums/opening-methods.enum';

@Injectable({providedIn: 'root'})
export class ResultsService {
	static readonly MaxFileSizeForPreview = 128 * 1024 * 1024;
	private documentTypeMetadata = new DocumentTypeMetadata();

	constructor(
		private utils: UtilsService,
		private fileTypeMapping: FileTypeMappingService,
		private readonly browserDetectorService: BrowserDetectorService,
	) {}

	extractData(
		data: RelevantResults,
		spellingSuggestion?: string,
		properties: KeyValuePair[] = [],
	): SearchResultsModel {
		const rows = data.Table.Rows.results;
		const responseProperties = this.mapProperties(properties);
		const resultsProperties = this.mapProperties(data.Properties.results);

		const searchResults = rows.map(this.mapRow).map(this.convertToSearchResult.bind(this));

		const result = {
			data: searchResults,
			count: data.TotalRows,
			countWithDuplicates: data.TotalRowsIncludingDuplicates,
			spellingSuggestion,
			pageImpression: responseProperties['piPageImpression'],
			sourceId: responseProperties['SourceId'],
			queryModification: resultsProperties['QueryModification'],
		};

		return result;
	}

	private mapProperties(properties: KeyValuePair[]): Dictionary<string> {
		const result: Dictionary<string> = {};

		properties.forEach((property) => {
			result[property.Key] = property.Value;
		});

		return result;
	}

	private mapRow(this: void, row: RelevantResultsRow): SearchResultResponse {
		const result: ConvertInterfaceToDict<SearchResultResponse> =
			{} as ConvertInterfaceToDict<SearchResultResponse>;
		row.Cells.results.forEach((cell) => {
			if (cell.Value !== null) {
				Object.defineProperty(result, cell.Key, {
					value: cell.Value,
					writable: true,
					configurable: true,
				});
			}
		});

		return result;
	}

	private convertToSearchResult(result: SearchResultResponse): SearchResultModel {
		// eslint-disable-next-line max-len
		// @see difference between WopiFrame.aspx and WopiFrame2.aspx https://www.wictorwilen.se/blog/an-explanation-to-to-start-seeing-previews-please-log-on-by-opening-the-document-in-sharepoint-2013/#:~:text=The%20WopiFrame.aspx%20will%20not%20do%20that%20for%20us%20%E2%80%93%20it%20will%20just%20say%20sorry%20you%E2%80%99re%20anonymous%20and%20show%20the%20message
		if (result.ServerRedirectedURL) {
			result.ServerRedirectedURL = result.ServerRedirectedURL.replace(
				'WopiFrame.aspx',
				'WopiFrame2.aspx',
			);
		}

		const title = this.extractTitle(result);
		const extension = result.FileExtension || 'www';
		// FileType field can be empty for large documents
		const fileType = this.fileTypeMapping.map(extension);
		const thumbnail = this.documentTypeMetadata.generateThumbnail(result);

		const filePath = result.Path || '';
		const isOur = this.checkWhose(extension === 'page' ? result.usppImportSourceUrl : filePath);
		const accessLevel = result.usppAccessLevel || '';
		const confidentialDocument =
			accessLevel === 'Selected users' ||
			filePath
				.split('/')
				.some((token) => ['confidential', 'proj'].includes(token.toLocaleLowerCase()));

		const importSourceDocId = (result.usppImportSourceUrl || '').split(';docId=')[1];
		const downloadUri = this.getDownloadLink(result);
		const tags = (result.usppDocumentTags || '').split(';').filter((tag) => tag);
		const displaySettings = this.getDisplaySettings(
			fileType,
			extension,
			result.Path,
			thumbnail.fullPreview ?? '',
			isOur,
		);

		let openingMethod: OpeningMethods = OpeningMethods.DOWNLOAD;

		if (
			displaySettings.previewAvailable &&
			+result.Size < ResultsService.MaxFileSizeForPreview
		) {
			openingMethod =
				fileType === 'Web page' ? OpeningMethods.EXTERNAL : OpeningMethods.PREVIEW;
		}
		const importSource = (result.usppImportSourceUrl || '').split(',')[0];

		return {
			title,
			sourceSystem: result.usppSourceSystem,
			titleText: result.usppAutoTitle,
			fileName: this.extractFileName(result),
			fileNameText: result.FileName,
			description: this.highlightText(result.HitHighlightedSummary),
			modifiedDate: new Date(result.Write).valueOf(),
			documentId: result.DocId,
			duplicates: result.fcocount || 1,
			owner: (result.usppContentOwner || '').split(':').pop(),
			locationTree: (result.usppLocationFilterTree || '').split(';'),
			importSourceDocId,
			importSource,
			fileExtension: extension,
			fileType,
			filePath,
			downloadUri,
			shareLink: this.getShareLink(
				importSourceDocId,
				openingMethod === OpeningMethods.EXTERNAL && importSource !== ''
					? importSource
					: downloadUri,
			),
			sourceFilePath: result.usppSourceDocument || '',
			images: (result.usppImages || '').split(';').filter((image) => image),
			fileSize: +result.Size,
			src: result,
			tags,
			slidesCount: +result.usppSlidesCount,
			signature: result.DocumentSignature.split(';'),
			confidentialDocument,
			accessLevel,
			isAutoGenerated: tags.includes('Auto-generated'),
			...thumbnail,
			...displaySettings,
			openingMethod,
			isOur,
			usagePermission: this.getUsagePermissionType(filePath),
		};
	}

	private extractTitle(result: SearchResultResponse): string {
		const highlights = result.HitHighlightedProperties.replace(/(\r\n|\n|\r)/gm, '.');
		const match = /<usppautotitle hashh="\d*">(.*)<\/usppautotitle>/.exec(highlights);
		const title = match ? match[1] : '';

		return this.highlightText(title);
	}

	private extractFileName(result: SearchResultResponse): string {
		const highlights = result.HitHighlightedProperties.replace(/(\r\n|\n|\r)/gm, '.');
		const match = /<filename hashh="\d*">(.*)<\/filename>/.exec(highlights);
		const fileName = match ? match[1] : '';

		return this.highlightText(fileName);
	}

	private highlightText(text: string): string {
		return text
			.replace(/<ddd\/>/g, ' ... ')
			.replace(/<ddd \/>/g, ' ... ')
			.replace(/<c0>/g, '<mark>')
			.replace(/<\/c0>/g, '</mark>')
			.replace(/<b>/g, '<mark>')
			.replace(/<\/b>/g, '</mark>')
			.replace(/ǂ/g, '')
			.replace(/\s+/g, ' ');
	}

	private getDisplaySettings(
		fileType: string,
		extension: string,
		filePath: string,
		fullPreview: string,
		isOur: boolean,
	): IDisplaySettings {
		const webPage = (fileType === 'Web page' && !isOur) || extension === 'page';

		return {
			previewAvailable: !!fullPreview || webPage,
			downloadAvailable: !!(filePath && !webPage),
		};
	}

	private getDownloadLink(result: SearchResultResponse): string {
		if (!result.Path) {
			return '';
		}

		// Edge Chromium intercepts file type header
		// and suggests opening  in the corresponding program
		if (this.browserDetectorService.isEdgOnWindows() && result.SPSiteURL) {
			const {pathname} = new URL(result.Path);

			return `${result.SPSiteURL}/_layouts/15/download.aspx?SourceUrl=${decodeURIComponent(
				pathname,
			)}`;
		}

		return result.Path;
	}

	private getShareLink(docId: string, downloadUri: string): string {
		if (docId?.includes('EPAMSTORY')) {
			if (downloadUri?.includes('/CaseStudies/')) {
				if (!downloadUri.includes('/Public Attachments/')) {
					return this.utils.makePreviewAbsoluteUrl(docId);
				}
			} else if (downloadUri?.includes('/Stories/')) {
				return this.utils.makePreviewAbsoluteUrl(docId);
			}
		}

		return downloadUri;
	}

	private checkWhose(path: string): boolean {
		let isOur = false;

		try {
			const {hostname}: URL = new URL(path);
			isOur = hostname.includes('presales');
			// eslint-disable-next-line no-empty, no-empty-function, @typescript-eslint/no-empty-function
		} catch {}

		return isOur;
	}

	private getUsagePermissionType(filePath: string): UsagePermissionType | undefined {
		if (filePath.includes('/Analyst Reports -') || filePath.includes('/Sales Research/')) {
			return UsagePermissionType.STRICTLY_INTERNAL;
		}

		if (filePath.includes('/Confidential/') || filePath.includes('/proj/')) {
			return UsagePermissionType.CONFIDENTIAL;
		}

		return undefined;
	}
}

interface IDisplaySettings {
	previewAvailable: boolean;
	downloadAvailable: boolean;
}

type ConvertInterfaceToDict<T> = {
	[K in keyof T]: T[K];
};
