import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {MailTo} from '../models/mailto.model';

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'mailto',
	standalone: true,
})
export class MailToPipe implements PipeTransform {
	transform(data?: MailTo): string {
		if (!data) {
			return '';
		}
		const email = this.getEmailString(data.to);
		const cc = data.cc ? `cc=${this.getEmailString(data.cc)}` : null;
		const bcc = data.bcc ? `bcc=${this.getEmailString(data.bcc)}` : null;
		const subject = data.subject ? `subject=${encodeURIComponent(data.subject)}` : null;
		const body = data.body ? `body=${encodeURIComponent(data.body)}` : null;

		const result = [cc, bcc, subject, body].filter(Boolean).join('&');

		return `mailto:${email}?${result}`;
	}

	getEmailString(email: string | string[]): string {
		const emails = Array.isArray(email) ? email : [email];

		return emails.join(';');
	}
}
