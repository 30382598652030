import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class FormatterService {
	format(data: string, source: string, base?: string): string {
		switch (source) {
			case 'size':
			case 'Size':
				return this.formatSize(data);
			case 'modifiedDate':
			case 'Write':
				return this.formatDate(data, base);
			default:
				return '';
		}
	}

	// Warning! Multiple return statements!
	private formatSize(data: string): string {
		const toKilobytes = 1000;
		const toMegabytes = 1000000;
		const kilobytes = 'KB';
		const megabytes = 'MB';
		const min = '0';
		const max = '50+ MB';

		if (data === 'min') {
			return min;
		}

		if (data === 'max') {
			return max;
		}

		let size = +data / toMegabytes;
		if (size >= 1) {
			return `${size} ${megabytes}`;
		}

		size = +data / toKilobytes;

		return `${size} ${kilobytes}`;
	}

	private formatDate(data: string, base?: string): string {
		// These are not magic numbers
		const millisecondsToMonths = (1000 * 60 * 60 * 24 * 365) / 12;
		const mapping: Dictionary<string> = {
			min: 'Long ago',
			96: '8 years',
			48: '4 years',
			24: '2 years',
			12: 'year',
			6: '6 months',
			3: '3 months',
			1: 'month',
			0: 'week',
			max: 'Now',
		};
		const baseDate = base ? new Date(base) : new Date();
		const months = Math.round(
			(baseDate.valueOf() - new Date(data).valueOf()) / millisecondsToMonths,
		);

		return mapping[data] || mapping[months];
	}
}
