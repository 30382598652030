import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	Inject,
	Input,
	OnInit,
	ViewEncapsulation,
	inject,
} from '@angular/core';

import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {CrawlStateComponent} from '../crawl-state/crawl-state.component';
import {DownloadHistoryComponent} from '../download-history/download-history.component';
import {TagHistoryComponent} from '../tag-history/tag-history.component';
import {DocumentImagesComponent} from '../document-images/document-images.component';
import {UserStore} from '@shared/state';
import {CrmAccountModel, SearchResultModel} from '@shared/models';
import {UIService} from '@search/services';
import {SvgIconComponent} from '@pp/svg';
import {AnalyticsService} from '@shared/analytics';
import {CURRENT_MODAL, ModalRef} from '@shared/modals';
import {RoleService, UserRole} from '@core/roles/role.service';
import {CrmAccountsService} from '@shared/services';
import {ConfidentialLogosComponent} from '@shared/components/confidential-logos/confidential-logos.component';
import {JsonPreviewComponent} from '@shared/components/json-preview/json-preview.component';

@Component({
	selector: 'search-details-dialog',
	templateUrl: './details-dialog.component.html',
	styleUrls: ['./details-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		SvgIconComponent,
		DocumentImagesComponent,
		ConfidentialLogosComponent,
		TagHistoryComponent,
		DownloadHistoryComponent,
		JsonPreviewComponent,
		CrawlStateComponent,
		AsyncPipe,
	],
})
export class DetailsDialogComponent implements OnInit {
	tabs: {id: string; label: string; role?: UserRole}[] = [
		{id: 'images', label: 'Images'},
		{id: 'logos', label: 'Logos'},
		{id: 'events', label: 'Events', role: 'moderator'},
		{id: 'downloads', label: 'Downloads Info', role: 'moderator'},
		{id: 'search', label: 'Search Results', role: 'admin'},
		{id: 'crawling', label: 'Crawling State', role: 'admin'},
	];
	selectedTab!: string;

	logos$!: Observable<CrmAccountModel[]>;

	@Input({required: true}) document!: SearchResultModel;

	constructor(
		private element: ElementRef,
		private readonly roleService: RoleService,
		private uiService: UIService,
		@Inject(CURRENT_MODAL) private readonly modalRef: ModalRef,
		private analytics: AnalyticsService,
		private accounts: CrmAccountsService,
	) {}

	teams = inject(UserStore).profile.teams;

	ngOnInit(): void {
		this.logos$ = this.accounts.getAccountsByImage$(this.document.images).pipe(
			map((accounts) => accounts.sort((a, b) => a.name.localeCompare(b.name))),
			shareReplay({bufferSize: 1, refCount: true}),
		);

		this.tabs = this.tabs.filter((tab) =>
			this.uiService.showMetadata
				? !tab.role || this.roleService.isUserInRole(this.teams(), tab.role)
				: tab.id === 'search',
		);
		this.selectedTab = this.tabs[0].id;
	}

	triggerClose(): void {
		this.analytics.trackExtendedInfoActions('close');
		this.modalRef.close();
	}

	switchTab(tab: string): void {
		this.selectedTab = tab;
	}

	@HostListener('window:keyup', ['$event'])
	selectAll(event: KeyboardEvent): void {
		if (event.ctrlKey && event.code === 'KeyA') {
			// TODO: Investigate is there any better way to do it.
			const range = document.createRange();
			const node = this.element.nativeElement.querySelector('.details-dialog-content');
			const selection = window.getSelection();

			selection?.empty();
			range.selectNodeContents(node);
			selection?.addRange(range);
			event.preventDefault();
		}
	}
}
