import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {AsyncPipe} from '@angular/common';
import {RemoveFilterAction} from '../../actions';
import {SearchFeatureState} from '../../reducers/search-state';
import {SearchAppliedFilterComponent} from '../search-applied-filter/search-applied-filter.component';
import {AppliedFilterModel} from '@search/models';
import {FilterFactoryService} from '@search/services';

@Component({
	selector: 'search-filters-selected-list',
	templateUrl: './search-filters-selected-list.component.html',
	styleUrls: ['./search-filters-selected-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SearchAppliedFilterComponent, AsyncPipe],
})
export class SearchFiltersSelectedListComponent implements OnInit {
	@Input() source!: string;
	selectedFilters$!: Observable<AppliedFilterModel[]>;

	constructor(
		private store$: Store<SearchFeatureState>,
		private filterFactory: FilterFactoryService,
	) {}

	ngOnInit(): void {
		const excludedFilters$: Observable<AppliedFilterModel[]> = this.store$
			.select(
				(state) =>
					state.search.selectedFilters.exclude[
						this.source as keyof typeof state.search.selectedFilters.exclude
					],
			)
			.pipe(
				filter((filters) => !!filters),
				map(this.mapFilters.bind(this, false)),
			);
		const includedFilters$: Observable<AppliedFilterModel[]> = this.store$
			.select(
				(state) =>
					state.search.selectedFilters.include[
						this.source as keyof typeof state.search.selectedFilters.include
					],
			)
			.pipe(
				filter((filters) => !!filters),
				map(this.mapFilters.bind(this, true)),
			);

		this.selectedFilters$ = combineLatest([includedFilters$, excludedFilters$]).pipe(
			map(([include, exclude]) => [...include, ...exclude]),
			map((filters) => filters.sort((a, b) => a.name.localeCompare(b.name))),
		);
	}

	remove(selectedFilter: AppliedFilterModel): void {
		this.store$.dispatch(
			new RemoveFilterAction({
				type: this.source,
				filter: selectedFilter,
			}),
		);
	}

	private mapFilters(include: boolean, filters: string[]): AppliedFilterModel[] {
		return filters.map((token) => ({
			include,
			token,
			name: this.filterFactory.get(this.source).service.prepareName(token),
		}));
	}
}
