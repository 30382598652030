import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	EventEmitter,
	TemplateRef,
	input,
	Output,
	computed,
} from '@angular/core';
import {NgTemplateOutlet} from '@angular/common';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'pp-checkbox',
	standalone: true,
	imports: [SvgIconComponent, NgTemplateOutlet],
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
	type = input<'default' | 'switcher'>('default');
	checked = input<boolean>(false);
	isPartiallySelected = input<boolean>(false);

	@HostBinding('class.disabled')
	get disabledClass() {
		return this.isDisabled();
	}

	disabled = input<boolean>(false);

	@Output() clickCheckbox = new EventEmitter<MouseEvent>();

	text = input<string>('');
	title = input<string>();
	labelTemplate = input<TemplateRef<unknown> | undefined>();

	isChecked = computed(() => this.isPartiallySelected() || this.checked());
	iconName = computed(() =>
		this.isPartiallySelected() ? 'selection-checkbox' : 'notification-done-12',
	);
	isDisabled = computed(() => (this.disabled() ? true : null));

	updateValue(event: Event): void {
		if (!this.disabled()) {
			this.clickCheckbox.emit(event as MouseEvent);
		}
	}
}
