import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Subscription} from 'rxjs';

import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import * as actions from '../../actions';
import {SearchFeatureState} from '../../reducers/search-state';
import {AutosuggestComponent} from '../autosuggest/autosuggest.component';
import {focusOnInput} from '@shared/utils';
import {AnalyticsService} from '@shared/analytics';
import {SvgIconComponent} from '@pp/svg';
import {QueryStringPipe} from '@shared/pipes';
import {QueryTipsComponent} from '@shared/components/query-tips/query-tips.component';

@Component({
	selector: 'search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [QueryStringPipe],
	standalone: true,
	imports: [SvgIconComponent, QueryTipsComponent, ReactiveFormsModule, AutosuggestComponent],
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
	private readonly subscriptions = new Subscription();
	readonly querySubject$ = new BehaviorSubject<string>('');
	autosuggestVisible = false;
	tipsVisible = false;

	queryControl = new FormControl('');

	@ViewChild('searchInput')
	searchInput?: ElementRef<HTMLInputElement>;

	constructor(
		private store$: Store<SearchFeatureState>,
		private element: ElementRef,
		private analytics: AnalyticsService,
		private router: Router,
		private queryString: QueryStringPipe,
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.store$
				.select((state) => state.search.query.currentQuery)
				.pipe(tap((query) => this.queryControl.setValue(query, {emitEvent: false})))
				.subscribe(),
		);

		this.subscriptions.add(
			this.queryControl.valueChanges
				.pipe(
					tap((value) => {
						this.autosuggestVisible = true;
						this.querySubject$.next(value ?? '');
					}),
				)
				.subscribe(),
		);
	}

	ngAfterViewInit(): void {
		focusOnInput(this.searchInput?.nativeElement);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	updateCurrentQuery(query: string): void {
		this.store$.dispatch(new actions.ChangeQueryAction(query));
	}

	submitQuery(event: string): void {
		this.autosuggestVisible = false;
		this.querySubject$.next(event);
		this.dispatchSubmitQuery(event);
	}

	focus(): void {
		this.autosuggestVisible = true;
	}

	blur(): void {
		this.updateCurrentQuery(this.queryControl.value ?? '');
	}

	toggleTips(): void {
		this.tipsVisible = !this.tipsVisible;
		if (this.tipsVisible) {
			this.analytics.trackSearchQueryTipsActions('open');
		}
	}

	appendQuery(query: string): void {
		const queryControlValue: string = (this.queryControl.value ?? '').trim();
		const value = `${queryControlValue}${query}`;

		this.store$.dispatch(new actions.ChangeQueryAction(value));
		this.queryControl.setValue(value);
		this.searchInput?.nativeElement.focus();
	}

	replaceQuery(query: string): void {
		this.dispatchSubmitQuery(query);
		this.queryControl.setValue(query);
		this.searchInput?.nativeElement.focus();
	}

	@HostListener('window:click', ['$event'])
	hideDialogs(event: Event): void {
		if (!this.element.nativeElement.contains(event.target)) {
			this.autosuggestVisible = false;
			this.tipsVisible = false;
		}
	}

	@HostListener('window:keypress', ['$event'])
	submit(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.autosuggestVisible = false;
			this.dispatchSubmitQuery(this.queryControl.value ?? '');
		}
	}

	private dispatchSubmitQuery(queryText: string): void {
		if (!this.router.url.startsWith('/search')) {
			this.router.navigateByUrl(
				`/search?o=${encodeURIComponent(this.queryString.transform(queryText))}`,
			);
		} else {
			this.store$.dispatch(new actions.SubmitQueryAction(queryText));
		}
	}
}
