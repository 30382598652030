import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import {Router} from '@angular/router';

import {CloudAuthenticationService} from '@core/services';
import {AnalyticsService} from '@shared/analytics';
import {TitleService} from '@shared/services';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
	cookieEnabled = navigator.cookieEnabled;
	errorOccurred = false;

	constructor(
		private cloudAuthentication: CloudAuthenticationService,
		private detector: ChangeDetectorRef,
		private analytics: AnalyticsService,
		private titleService: TitleService,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.titleService.loginTitle();
		this.analytics.trackPageNavigation('/ui/login');

		// Use as redirect URL for loginPopup
		const isBlankPage = this.router.url.includes('login-blank');

		if (this.cookieEnabled && !isBlankPage) {
			this.cloudAuthentication.handleRedirectPromise().catch(() => {
				this.errorOccurred = true;
				this.detector.markForCheck();
			});
		}
	}

	logout(): Promise<void> {
		return this.cloudAuthentication.logout();
	}
}
