import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DecimalPipe} from '@angular/common';
import {TreeNodePayload} from '@search2/models';
import {PpFacetResult} from '@shared/api';
import {SearchQueryState} from '@search2/state';
import {AnalyticsService} from '@shared/analytics';
import {DocumentTaxonomyService, SearchStateSerializerService} from '@search2/services';
import {MultiTreeViewComponent, TreeViewItemDirective, TreeNode} from '@shared/tree';

@Component({
	selector: 'search-preview-cases-tree-section',
	templateUrl: './preview-cases-tree-section.component.html',
	styleUrls: ['./preview-cases-tree-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MultiTreeViewComponent, TreeViewItemDirective, DecimalPipe],
})
export class PreviewCasesTreeSectionComponent implements OnInit {
	@Input({required: true}) filters!: PpFacetResult[];
	@Input({required: true}) field!: string;
	@Input({required: true}) baseQuery!: SearchQueryState;
	@Input() expandByDefault = false;

	tree!: TreeNode<TreeNodePayload>;
	isFlat = false;

	constructor(
		private readonly router: Router,
		private readonly analytics: AnalyticsService,
		private readonly taxonomyService: DocumentTaxonomyService,
		private readonly searchStateSerializer: SearchStateSerializerService,
	) {}

	ngOnInit(): void {
		this.tree = this.taxonomyService.parseTree(this.filters, this.expandByDefault);
		this.isFlat = !this.tree.children.some((x) => x.children.length > 0);
	}

	toNode(x: unknown): TreeNodePayload {
		return x as TreeNodePayload;
	}

	selectOneNode(data: TreeNode<TreeNodePayload>): void {
		this.analytics.trackDocumentPreviewAction('open:cs-catalog', data.payload.id);
		const query = {
			...this.baseQuery,
			selectedFilters: {
				...this.baseQuery.selectedFilters,
				[this.field]: [data.payload.id],
			},
		};

		this.router.navigate(['/p/cases'], {
			queryParams: {
				o: this.searchStateSerializer.serialize(query),
			},
		});
	}
}
