import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {HistogramComponent} from './histogram/histogram.component';
import {TreeFiltersComponent} from './tree/tree-filters.component';
import {SearchCheckboxFiltersComponent} from './checkbox/checkbox-filters.component';
import {SearchFilterBarComponent} from './search-filter-bar/search-filter-bar.component';
import {SharedSkeletonComponent} from '@pp/skeleton';
import {SelectionStore} from '@shared/selection';
import {SearchStore} from '@search2/state';
import {AnchorDirective} from '@pp/anchor';

@Component({
	selector: 'search-filters',
	templateUrl: './search-filters.component.html',
	styleUrls: ['./search-filters.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		SharedSkeletonComponent,
		SearchFilterBarComponent,
		SearchCheckboxFiltersComponent,
		TreeFiltersComponent,
		HistogramComponent,
		AnchorDirective,
	],
})
export class SearchFiltersComponent {
	private searchStore = inject(SearchStore);
	private selectionStore = inject(SelectionStore);

	isLoading = computed(() => {
		const status = this.searchStore.searchStatus();
		const items = this.searchStore.searchResults.items();

		return status === 'loading' && items.length === 0;
	});

	facetConfigs = computed(() => {
		const currentPreset = this.searchStore.currentPreset();
		const facetConfigs = currentPreset?.facetConfigs ?? [];

		return facetConfigs.filter((facetConfig) => facetConfig.isVisible);
	});

	height = computed(() => {
		if (this.selectionStore.documentIds().length) {
			return 'calc(100vh - 164px)';
		} else {
			return 'calc(100vh - 84px)';
		}
	});
}
