<div [class]="['news-card-content', 'gradient-' + gradientTheme]">
	<h3>{{ item.title }}</h3>

	<span class="description">{{ item.description }}</span>

	<div class="buttons-container">
		@for (action of item.actions; track action.label) {
			@if (action.routerLink) {
				<a
					btn
					[btnType]="action.type"
					(click)="clickButton($event, undefined, action.routerLink)"
					[routerLink]="action.routerLink"
					>{{ action.label }}</a
				>
			} @else {
				<a
					btn
					[btnType]="action.type"
					(click)="clickButton($event, action.emitEvent, action.link)"
					[href]="action.link || '#'"
					target="_blank"
					>{{ action.label }}</a
				>
			}
		}
	</div>
</div>
