import {Injectable} from '@angular/core';

const powerPoint = 'PowerPoint';
const word = 'Word';
const excel = 'Excel';
const pdf = 'PDF';
const visio = 'Visio';
const webPage = 'Web page';
const microsoftProject = 'Microsoft Project';
const mails = 'Mails';
const archAssets = 'Architectural Assets';
const other = 'Other';

@Injectable({providedIn: 'root'})
export class FileTypeMappingService {
	private readonly mapping: Dictionary<string> = {
		pptx: powerPoint,
		ppt: powerPoint,
		ppsx: powerPoint,
		pot: powerPoint,
		potx: powerPoint,

		docx: word,
		doc: word,
		docm: word,
		rtf: word,
		dotx: word,
		wordml: word,
		dot: word,

		xlsx: excel,
		xls: excel,
		xlsb: excel,
		xlsm: excel,
		excelml: excel,
		xltx: excel,
		xlt: excel,

		pdf,

		vsdx: visio,
		vsd: visio,
		vsx: visio,
		vdx: visio,

		html: webPage,
		htm: webPage,
		mhtml: webPage,
		mhtm: webPage,
		aspx: webPage,
		www: webPage,
		page: webPage,

		mpp: microsoftProject,

		msg: mails,
		eml: mails,

		eap: archAssets,
		archimate: archAssets,

		csv: other,
		txt: other,
	};

	map(fileType: string): string {
		return this.mapping[fileType] || other;
	}
}
