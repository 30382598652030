import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {NgClass} from '@angular/common';
import {ProjectInfoComponent} from './project-info/project-info.component';
import {SvgIconComponent} from '@pp/svg';
import {AnchorDirective} from '@pp/anchor';
import {PresetLinkComponent} from '@search2/components';
import {PreviewUserInfoComponent} from '@shared/access';
import {HintDirective} from '@pp/hint';
import {SearchStateSerializerService} from '@search2/services';
import {AnalyticsService} from '@shared/analytics';
import {DocDetailsCrmAccount} from '@shared/api';
@Component({
	selector: 'search-preview-account-info',
	templateUrl: './preview-account-info.component.html',
	styleUrls: ['./preview-account-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		SvgIconComponent,
		AnchorDirective,
		PresetLinkComponent,
		PreviewUserInfoComponent,
		NgClass,
		HintDirective,
		ProjectInfoComponent,
	],
})
export class PreviewAccountInfoComponent {
	account = input.required<DocDetailsCrmAccount>();

	constructor(
		private readonly analytics: AnalyticsService,
		private readonly searchStateSerializer: SearchStateSerializerService,
	) {}

	type = computed(() => this.getAccountType(this.account()));
	active = computed(() => this.account().status === 'Current Client');
	telescopeLink = computed(
		() => `https://telescope.com/companies/profile/key/crmId/${this.account().crmId}`,
	);
	crmLink = computed(
		() =>
			`https://crm.com/main.aspx?etc=1&id=%7b${this.account().crmId}%7d&pagetype=entityrecord`,
	);
	name = computed(() => this.account().name);
	casesLink = computed(() => ({
		presetId: 'cases',
		query: this.searchStateSerializer.getQueryObject({
			selectedFilters: {
				'spo_presales.crm_account_name_paths': [this.account().namePath],
			},
		}),
	}));

	trackLink(linkType: string): void {
		this.analytics.trackDocumentPreviewAction('open:link', linkType);
	}

	usagePermissionStyle(usagePermission?: string): 'grey' | 'red' | 'green' {
		switch (usagePermission) {
			case 'Yes, no restrictions':
				return 'green';
			case 'No, strictly confidential':
			case 'No, anonymized only':
				return 'red';
			case 'No, only with mutual NDA':
			case 'No, requires customer approval':
			default:
				return 'grey';
		}
	}

	private getAccountType(account: DocDetailsCrmAccount): AccountType {
		if (account.status === 'Unknown') {
			return 'Unknown';
		}
		if (account.name === 'Pre-Acquisition Client') {
			return 'Pre-Acquisition Client';
		}

		return 'Crm Account';
	}
}

type AccountType = 'Unknown' | 'Pre-Acquisition Client' | 'Crm Account';
