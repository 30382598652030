<ul class="toast-messages-container" ppCdkUseTheme="dark">
	@if (toastMessages$ | async; as toastMessages) {
		@for (toast of toastMessages; track toast.id ? toast.id.toString() : toast.message) {
			<li>
				<div class="toast-messages-border" [ngClass]="toast.type"></div>
				@if (toast.type === 'loading' && toast.progress !== undefined) {
					<div class="toast-messages-loading-progress-container">
						<div class="progress-bar" [style.width]="toast.progress + '%'"></div>
					</div>
				}
				<main class="toast-messages-content">
					<span>
						@switch (toast.type) {
							@case ('success') {
								<pp-icon
									class="toast-messages-success-icon"
									name="notification-check_circle-fill-24"
									[size]="24"
								>
								</pp-icon>
							}
							@case ('info') {
								<pp-icon
									class="toast-messages-info-icon"
									name="notification-help-fill-24"
									[size]="24"
								>
								</pp-icon>
							}
							@case (['error', 'fatalError'].includes(toast.type) ? toast.type : '') {
								<pp-icon
									class="toast-messages-error-icon"
									name="notification-error-fill-24"
									[size]="24"
								>
								</pp-icon>
							}
							@case ('loading') {
								<pp-spinner
									class="toast-messages-loading-icon"
									size="small"
									context="bookmark"
								></pp-spinner>
							}
							@case ('alert') {
								<pp-icon
									class="toast-messages-alert-icon"
									name="notification-alert-24"
									[size]="24"
								>
								</pp-icon>
							}
							@default {
								<pp-icon name="notification-error-fill-24" [size]="24"> </pp-icon>
							}
						}
					</span>
					<span class="toast-messages-text">
						{{ toast.message }}
					</span>
					@if (['alert', 'success', 'info'].includes(toast.type)) {
						<pp-icon
							class="toast-messages-close"
							(click)="hideToast(toast.id)"
							name="navigation-close-24"
							[size]="24"
						></pp-icon>
					}
					@if (toast.type === 'fatalError') {
						<span class="toast-messages-refresh" (click)="refresh()">Refresh</span>
					}
				</main>
			</li>
		}
	}
</ul>
