@if (items$ | async; as items) {
	@if (items.length) {
		<ul class="search-filters-list-tree level-{{ level }} dp-list" [attr.source]="source">
			@for (item of items | moreLess: showMore : 5; track item.token) {
				<li
					class="search-filters-list-tree-item dp-list-item"
					[ngClass]="item.hasChildren ? 'collapsible' : 'dot'"
				>
					<div class="search-filters-list-item-content dp-list-item-content">
						@if (item.hasChildren) {
							<button
								[class.collapsed]="!isExpanded(item)"
								[title]="isExpanded(item) ? 'Collapse list' : 'Expand list'"
								class="search-filters-list-item-collapser dp-button-null dp-list-item-collapser"
								(click)="toggleItem(item)"
							>
								<pp-icon
									name="navigation-chevron-down-18"
									aria-hidden="true"
								></pp-icon>
							</button>
						}

						<div class="search-filters-list-item-text dp-list-item-text">
							<a
								class="search-filters-list-item-include"
								[class.selected]="item.selected"
								title="{{ item.name }} ({{ item.count }})"
								(click)="includeFilter(item)"
							>
								<span
									class="search-filters-list-item-label"
									[innerHTML]="item.richName || item.name"
								></span>
								<span class="search-filters-list-item-count dp-list-item-count"
									>({{ item.count | number }})</span
								>
							</a>
						</div>

						<button
							class="search-filters-list-item-exclude dp-button-null"
							[class.selected]="item.selected"
							title="Exclude {{ item.name }}"
							(click)="excludeFilter(item)"
						>
							<pp-icon
								name="navigation_deselect_outline_18"
								aria-hidden="true"
							></pp-icon>
						</button>
					</div>

					@if (item.hasChildren && isExpanded(item)) {
						<search-filters-list-tree
							[source]="source"
							[data$]="getSubtree$(item.nodeValue!)"
							[level]="getLevel()"
						></search-filters-list-tree>
					}
				</li>
			}

			@if (enableShowMore && items.length > 5) {
				<li>
					<button
						class="search-filters-list-tree-toggle dp-button-null"
						(click)="toggleShowMore()"
					>
						{{ showMore ? 'Show less' : 'Show more' }}
					</button>
				</li>
			}
		</ul>
	}
}
