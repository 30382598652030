import {DocumentIndexItem} from '@shared/api';
import {SearchQueryState} from '@search2/state';

export interface ResponseFragment<T> {
	// Top N items from the response (fragment)
	items: T[];
	// Total number of items in the response
	totalCount: number;
	// Link to the preset that was used to fetch the fragment
	presetLink?: PresetLinkModel;
}

export interface PresetLinkModel {
	presetId: string;
	query: SearchQueryState;
}

export type DocumentsFragment = ResponseFragment<DocumentIndexItem>;

export const EMPTY_DOCUMENT_FRAGMENT: DocumentsFragment = {
	items: [],
	totalCount: 0,
};
