import {LoadingFiltersState} from '@search/reducers/loading-filters/loading-filters.state';

export const loadingFiltersInitialState: LoadingFiltersState = {
	languages: false,
	documentTags: false,
	sourceSystem: false,
	locationFilterTree: false,
	contentOwner: false,
	preparedFor: false,
	about: false,
	fileContentType: false,
	fileType: false,
	size: false,
	modifiedDate: false,
	displayAuthor: false,
	tags: false,
	accessLevel: false,
};
