import {Action} from '@ngrx/store';

import {TreeFilterModel} from '@search/models';

export const UPDATE_PREPARED_FOR_FILTER = '[Search] Update Prepared For Filter';

export class UpdatePreparedForFilterAction implements Action {
	readonly type = UPDATE_PREPARED_FOR_FILTER;

	constructor(public payload: TreeFilterModel) {}
}
