@if (filters().length > 0) {
	<search-filter-header
		[ngClass]="{expanded: isExpanded}"
		[title]="config().name"
		[itemsCount]="itemsCount()"
		[maxCount]="config().maxCount ?? 0"
		[showResetButton]="selectedFilters().length > 0"
		(resetEventEmitter)="reset()"
		(toggleShowEventEmitter)="toggleShowMore()"
		(toggleExpandEventEmitter)="toggleExpand()"
		class="fitler-header"
	></search-filter-header>
	@if (isExpanded) {
		<ul>
			@for (
				filter of filters()
					| moreLess: !isShowMore || isFiltered() : config().showByDefault;
				track filter.value
			) {
				<li class="filter-section">
					<label
						class="filter-item"
						[class.empty]="isEmpty(filter)"
						[class.selected]="checkboxIsSelected(filter.value)"
					>
						<pp-checkbox
							class="filter-item-checkbox"
							[checked]="checkboxIsSelected(filter.value)"
							(change)="checkboxToggle(filter.value)"
							[text]="filter.highlightedText || filter.text"
							[title]="filter.text"
						></pp-checkbox>
						<span class="counter">{{ filter.count | number }}</span>
					</label>
				</li>
			}
		</ul>
	}
}
