import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';

import {AsyncPipe, DatePipe} from '@angular/common';
import {TagHistoryModel} from '@search/models';
import {TagHistoryService} from '@search/services';
import {SpinnerComponent} from '@pp/spinner';

@Component({
	selector: 'search-tag-history',
	templateUrl: './tag-history.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SpinnerComponent, AsyncPipe, DatePipe],
})
export class TagHistoryComponent implements OnInit {
	tagHistory$!: Observable<TagHistoryModel[]>;

	@Input({required: true}) filePath!: string;

	constructor(private tagHistoryService: TagHistoryService) {}

	ngOnInit(): void {
		this.tagHistory$ = this.tagHistoryService.getTagHistory$(this.filePath);
	}
}
