import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {CONFIG, EnvConfigType} from '@environments/environment';
import {UseThemeDirective} from '@shared/directives';

@Component({
	selector: 'powerpoint-home-footer',
	standalone: true,
	imports: [UseThemeDirective],
	templateUrl: './home-footer.component.html',
	styleUrl: './home-footer.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeFooterComponent {
	serviceUrl!: string;

	constructor(@Inject(CONFIG) private readonly config: EnvConfigType) {
		this.serviceUrl = this.config.serviceUrl;
	}
}
