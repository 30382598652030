<div class="preview-page-container" [ppCdkUseTheme]="theme()">
	<div class="preview-page-content">
		@if (preset()) {
			<pp-alert
				class="back-alert"
				[status]="'info'"
				[closable]="true"
				(closed)="closeBackAlert()"
				otherIcon="notification-info-fill-24"
			>
				<span
					>You’re now viewing an item from the
					<a routerLink="/p/{{ preset()!.presetId }}">{{ preset()!.name }}</a>
					catalog</span
				>
			</pp-alert>
		}
		@if (previewRequest()) {
			<search-preview-dialog [previewRequest]="previewRequest()!"></search-preview-dialog>
		}

		<search-selection-panel-gap></search-selection-panel-gap>
	</div>
</div>
