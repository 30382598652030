import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SectionItemComponent} from '../section-item/section-item.component';
import {SvgIconComponent} from '@pp/svg';
import {PpSearchResultItemOfDocumentIndexItem} from '@shared/api';

@Component({
	selector: 'powerpoint-grouped-section',
	templateUrl: './grouped-section.component.html',
	styleUrls: ['./grouped-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent, SectionItemComponent],
})
export class GroupedSectionComponent {
	@Input() section!: SearchResultsGroupedTree;

	isOpened = true;

	toggleSection(): void {
		this.isOpened = !this.isOpened;
	}
}

class SearchResultsGroupedTree {
	name!: string;
	items!: PpSearchResultItemOfDocumentIndexItem[];
	children!: SearchResultsGroupedTree[];
	count!: number;
}
