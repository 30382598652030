import {Injectable} from '@angular/core';

import {FilterService} from '../filter-factory/filter-service';
import {TreeFilterModel} from '@search/models';
import {HexDecodePipe} from '@shared/pipes';
import {Refinement} from '@shared/services';

@Injectable({providedIn: 'root'})
export class StockTreeFilterService implements FilterService {
	private readonly delimiter = ':';

	constructor(private hexDecode: HexDecodePipe) {}

	convert(data: Refinement[]): TreeFilterModel {
		const result: TreeFilterModel = {};

		data.forEach((refinement, refinementIndex) => {
			const valueField: symbol = Symbol.for('value');
			const path = refinement.RefinementValue.split(this.delimiter);
			let subtree = result;
			path.forEach((value, index) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				if (!subtree[value]) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-expect-error
					subtree[value] = {};
				}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				subtree = subtree[value] as TreeFilterModel;

				if (path.length - 1 === index) {
					subtree[valueField] = {
						token: refinement.RefinementToken,
						name: this.prepareName(refinement.RefinementToken),
						nodeValue: value,
						value: refinement.RefinementValue.replace(/:/g, '/'),
						count: +refinement.RefinementCount,
						order: refinementIndex,
					};
				}
			});
		});

		return result;
	}

	prepareName(token: string): string {
		const name = this.hexDecode.transform(token);
		const path = name.split(this.delimiter);

		return path[path.length - 1];
	}
}
