<div class="dp-card">
	<header class="dp-card-header cloud-links-widget-header">
		Useful Links

		<button
			btn
			btnType="link"
			withIconBefore="action-settings-18"
			title="Manage Useful Links"
			(click)="showEditDialog()"
		>
			Manage
		</button>
	</header>
	<main class="dp-card-content">
		@if (linksCount() === 0) {
			<div class="cloud-links-widget-no-data">Nothing here yet</div>
		} @else {
			<ul class="cloud-links-widget-list">
				@for (link of links() | moreLess: !isShowMore() : 3; track link.url) {
					<li class="cloud-links-widget-item">
							<a (click)="openUnsafeLinkPopOver($event, link)">
								<pp-icon [name]="getHostIcon(link)" />
								{{ link.linkTitle || link.hostname }}
							</a>
					</li>
				}
			</ul>
			@if (linksCount() > 3) {
				<button
					(click)="toggleShowMore()"
					class="dp-card-header-more-link cloud-links-widget-show-button"
				>
					Show {{ isShowMore() ? 'all' : 'less' }}
					{{ isShowMore() ? '(' + linksCount() + ')' : '' }}

					<pp-icon
						[name]="
							isShowMore() ? 'navigation-chevron-down-18' : 'navigation-chevron-up-18'
						"
						[size]="18"
					></pp-icon>
				</button>
			}
		}
	</main>
</div>
