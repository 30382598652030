<ng-template #defaultTemplate let-cardTitle let-index="index" let-first="isFirst" let-last="isLast">
	Card #{{ index }}: {{ cardTitle }}
	@if (first) {
		<small>first</small>
	}
	@if (last) {
		<small>last</small>
	}
</ng-template>

@for (item of list; track trackBy(index, item); let index = $index) {
	<section [class.vertical-mode]="direction() === 'vertical'">
		<ng-container
			[ngTemplateOutlet]="cardTemplateRef || defaultTemplate"
			[ngTemplateOutletContext]="context(item, index)"
		></ng-container>
		@if (hasSeparator && direction() === 'horizontal') {
			<hr />
		}
	</section>
}
