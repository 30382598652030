import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {AsyncPipe} from '@angular/common';
import {ChangeGroupIdAction} from '../../actions';
import {SearchFeatureState} from '../../reducers/search-state';
import {AlertActionComponent, AlertComponent} from '@pp/alert';

@Component({
	selector: 'search-duplicates',
	templateUrl: './duplicates.component.html',
	styleUrls: ['./duplicates.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [AlertComponent, AlertActionComponent, AsyncPipe],
})
export class DuplicatesComponent {
	groupId$: Observable<string> = this.store$.select((state) => state.search.query.groupId);

	documentTitle$: Observable<string> = combineLatest([
		this.store$.select((state) => state.search.results.data),
		this.groupId$,
	]).pipe(
		map(([results, groupId]) => results.find((result) => result.documentId === groupId)),
		filter((document) => !!document),
		map((document) => document?.titleText ?? ''),
	);

	constructor(private store$: Store<SearchFeatureState>) {}

	discard(): void {
		this.store$.dispatch(new ChangeGroupIdAction(''));
	}
}
