<div class="info-block">
	<h5 class="info-block-title">
		<ng-content select="[title]"></ng-content>
		<ng-content select="[badge]"></ng-content>
	</h5>

	<div class="info-block-content">
		<ng-content select="[content]"></ng-content>
	</div>
</div>
