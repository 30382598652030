<div class="access-popover-container">
	<main class="access-popover-content">
		<header class="access-popover-header">
			<span>You don't have enough permission</span>
		</header>
		<header class="access-popover-subheader">Please submit access request if:</header>
		<ul class="access-popover-list">
			<li>
				<span class="access-popover-highlight">Client has requested</span>
				information about capabilities and experience (not addressed by
				<a routerLink="/p/stories">Presales Stories</a>);
			</li>
			<li>
				You are participating in preparation
				<span class="access-popover-highlight">of a RFI/RFP response</span>;
			</li>
			<li>
				You are
				<span class="access-popover-highlight"
					>participating and/or preparing a client pitch</span
				>.
			</li>
		</ul>
	</main>
	<footer class="access-popover-footer">
		<a
			class="access-popover-submit dp-button"
			href=""
			>Submit request</a
		>
	</footer>
</div>
