import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription, filter, map, startWith, tap, withLatestFrom} from 'rxjs';
import {SearchContentComponent} from '../search-content/search-content.component';
import {SearchFiltersComponent} from '../search-filters/search-filters.component';
import {SearchStore} from '../../state';
import {SearchStateSerializerService} from '../../services';
import {UseThemeDirective} from '@shared/directives';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';
import {UserStore} from '@shared/state';

@Component({
	selector: 'search-page',
	templateUrl: './search-page.component.html',
	styleUrls: ['./search-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () =>
				new ContextualAnalyticsService(inject(AnalyticsService), 'Document Catalog'),
		},
	],
	standalone: true,
	imports: [UseThemeDirective, SearchFiltersComponent, SearchContentComponent],
})
export class SearchPageComponent implements OnInit, OnDestroy {
	private readonly subscription = new Subscription();
	private searchStore = inject(SearchStore);
	theme = inject(UserStore).settings.uiTheme;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly stateSerializer: SearchStateSerializerService,
	) {}

	ngOnInit(): void {
		this.subscription.add(
			this.router.events
				.pipe(
					// Ensure that we re-parse url only after navigation ends
					filter((event) => event instanceof NavigationEnd),
					// Start with null to trigger initial parsing
					startWith(null),
					// Get snapshot of current route
					withLatestFrom(this.route.paramMap, this.route.queryParamMap),
					map(([, paramMap, queryParamMap]) => {
						const presetId = paramMap.get('presetId') ?? 'default';
						const o = queryParamMap.get('o') ?? undefined;
						const queryObj = this.stateSerializer.deserialize(o);

						return {
							...queryObj,
							presetId,
						};
					}),
					tap((queryFromUrl) => {
						const queryFromStore = this.searchStore.searchQuery();

						if (JSON.stringify(queryFromUrl) !== JSON.stringify(queryFromStore))
							this.searchStore.restoreQueryState(queryFromUrl);
					}),
				)
				.subscribe(),
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
