import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay, startWith} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {AccessLevel, AccessLevelType} from '../access-level';
import {DocumentAclService, AclModel} from '@search/services';
import {OpeningMethods} from '@shared/enums/opening-methods.enum';
import {SpinnerComponent} from '@pp/spinner/spinner.component';
import {SvgIconComponent} from '@pp/svg';
import {SearchResultModel} from '@shared/models';

interface ShareWithViewmodel {
	loading: boolean;
	value?: AclModel[];
	error?: boolean;
}

@Component({
	selector: 'shared-teams-list-popup',
	templateUrl: './teams-list-popup.component.html',
	styleUrls: ['./teams-list-popup.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent, SpinnerComponent, AsyncPipe],
})
export class TeamsListPopupComponent implements OnInit {
	@Input({required: true}) accessLevel!: AccessLevel;
	@Input({required: true}) result!: SearchResultModel;

	isExternal!: boolean;
	aclList$!: Observable<ShareWithViewmodel>;

	accessLevelType = AccessLevelType;

	constructor(private readonly documentAclService: DocumentAclService) {}

	ngOnInit(): void {
		this.isExternal = this.result.openingMethod === OpeningMethods.EXTERNAL;

		this.aclList$ = this.documentAclService.getAcl$(this.result.filePath).pipe(
			map((value) => ({value, loading: false})),
			startWith({loading: true}),
			shareReplay({bufferSize: 1, refCount: true}),
			catchError(() => of({loading: false, error: true})),
		);
	}
}
