<header ppCdkUseTheme="dark" class="header-container">
	<div class="header-menu-container">
		<shared-vertical-menu
			class="header-mobile-only"
			(logout)="logout($event)"
		></shared-vertical-menu>
		<ng-content select="[menuButton]"></ng-content>

		<a class="header-home-link" [routerLink]="'/dashboard'">
			<pp-icon class="header-home-link-logo" name="presales-logo-30" />
			<div>
				<div class="header-presales-title">Presales</div>
				@if (config.environment === 'production') {
					<div class="header-digital-platform">Digital Platform</div>
				} @else {
					<div class="header-environment {{ config.environment }}">
						{{ config.environment }}
					</div>
				}
			</div>
		</a>
		@if (isInsidePowerPoint()) {
			<core-powerpoint-toggle />
		}
	</div>

	<div class="header-suggestions">
		<ng-content select="[suggestions]"></ng-content>
	</div>

	<div class="header-settings">
		<shared-access-info></shared-access-info>
		@if (isThemeVisible) {
			<core-theme-toggle></core-theme-toggle>
		}
		<div class="header-profile-picture-container header-mobile-only">
			<div class="header-profile-picture-container">
				<img class="header-photo person-fallback"/>
			</div>
		</div>
		<nav
			id="header-user-menu"
			class="header-user-menu"
			[class.selected]="userMenuVisible"
			(click)="toggleUserMenu()"
		>
			<div class="header-profile-picture-container">
				<img class="header-photo person-fallback"/>
			</div>
			<pp-icon class="arrow-icon" name="navigation-chevron-down-18" [size]="18"></pp-icon>
			@if (userMenuVisible) {
				<ul class="header-navigation-list" (click)="trackUsage($event.target)">
					@if (teams() | hasTeam: 'moderator') {
						<div class="header-separator">
							<span class="header-separator-label">Presales Links</span>
							<div class="header-separator-line"></div>
						</div>
						<li>
							<a
								href=""
								target="_blank"
								>Presales Taxonomies</a
							>
						</li>
						<li>
							<a
								href=""
								target="_blank"
								>Presales Taxonomies (Old UI)</a
							>
						</li>
						<li>
							<a
								href=""
								target="_blank"
								>Presales Reports</a
							>
						</li>
						<li>
							<a
								href=""
								target="_blank"
								>Presales Video Channel</a
							>
						</li>
						<li>
							<a
								href=""
								target="_blank"
								>Presales Board</a
							>
						</li>
						<li>
							<a
								href=""
								target="_blank"
								>My tasks without CRM link</a
							>
						</li>
					}
					@if (teams() | hasTeam: 'admin') {
						<div class="header-separator">
							<span class="header-separator-label">System Settings</span>
							<div class="header-separator-line"></div>
						</div>
						<li>
							<a href="/_layouts/15/settings.aspx" target="_blank">Site Settings</a>
						</li>
						<li>
							<a href="" target="_blank"
								>TeamCity</a
							>
						</li>
						<li>
							<a
								href=""
								target="_blank"
								>Octopus Deploy</a
							>
						</li>
						<li>
							<a
								href=""
								target="_blank"
								>Vault / Dev</a
							>
						</li>
						<li>
							<a
								href=""
								target="_blank"
								>Vault / Prod</a
							>
						</li>
						<li>
							<a href="/api" target="_blank">Swagger API</a>
						</li>
					}
					<div class="header-separator-line"></div>
					<li>
						<a class="header-logout" href="/" (click)="logout($event)">
							<pp-icon name="navigation-logout-18" />
							Logout
						</a>
					</li>
				</ul>
			}
		</nav>
	</div>
</header>

<ng-content select="[mobile-filters]"></ng-content>
