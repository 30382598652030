<header class="search-filter-header">
	<span>{{ title }}</span>
	<div class="search-filter-wrapper">
		@if (showResetButton) {
			<pp-icon name="navigation-refresh-18" (click)="resetEvent()"></pp-icon>
		}
		@if (itemsCount) {
			<div class="search-filter-show" (click)="showToggleEvent()">
				<span
					class="search-filter-show-title"
					[ngClass]="{'count-excess-mark': itemsCount === maxCount}"
					[hint]="hintItemCountExcess"
					[hintDisabled]="itemsCount !== maxCount"
					[hintPosition]="'BOTTOM'"
					[hintTriangle]="true"
					>{{ isShowAll ? 'Hide' : 'All' }} {{ isShowAll ? '' : itemsCount }}</span
				>

				<ng-template #hintItemCountExcess>
					<span
						>Only {{ maxCount }} items are shown.<br />
						<strong>To find more</strong>, use the search box.</span
					>
				</ng-template>
				<pp-icon
					class="search-filter-show-icon"
					name="plus-with-border-18"
					[name]="isShowAll ? 'minus-with-border-18' : 'plus-with-border-18'"
				></pp-icon>
			</div>
		}
		<pp-icon name="navigation-chevron-right-18" (click)="expandToggleEvent()"></pp-icon>
	</div>
</header>
