import {ChangeDetectionStrategy, Component, ElementRef, HostBinding, Injector} from '@angular/core';
import {ThemableComponent} from '@shared/theme/themable-component';

@Component({
	selector: 'shared-panel',
	templateUrl: './panel.component.html',
	styleUrls: ['./panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class PanelComponent extends ThemableComponent {
	@HostBinding('class.light-theme')
	get lightThemeClass(): boolean {
		return this.elementRef.nativeElement.classList.contains('theme-context--light');
	}

	@HostBinding('class.dark-theme')
	get darkThemeClass(): boolean {
		return this.elementRef.nativeElement.classList.contains('theme-context--dark');
	}

	constructor(
		override readonly injector: Injector,
		private readonly elementRef: ElementRef,
	) {
		super(injector);
	}
}
