import {DOCUMENT, LocationStrategy} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Router, UrlCreationOptions, UrlTree} from '@angular/router';

import {AnalyticsService} from '@shared/analytics';
import {ApiException} from '@shared/api';

@Injectable({providedIn: 'root'})
export class UtilsService {
	constructor(
		private location: LocationStrategy,
		private analytics: AnalyticsService,
		@Inject(DOCUMENT) private readonly doc: Document,
		private readonly router: Router,
	) {}

	getEmbeddedVideoUrl(videoUrl: string): string | null {
		const link = (videoUrl || '').split(',')[0];

		if (link.includes('videoportal')) {
			return `//videoportal.epam.com/video/iframe.html?video=${this.parseUrl(link)}`;
		}
		if (link.includes('microsoftstream')) {
			return `https://web.microsoftstream.com/embed/video/${this.parseUrl(
				link,
			)}?autoplay=false&showinfo=true`;
		}
		if (link.includes('youtu.be')) {
			return `https://www.youtube.com/embed/${this.parseUrl(link, 1)}`;
		}

		if (link.includes('vimeo.com')) {
			return `https://player.vimeo.com/video/${this.parseUrl(link, 1)}`;
		}

		return null;
	}

	makePreviewAbsoluteUrl(docId: string, options?: UrlCreationOptions): string {
		return this.makeModuleAbsoluteUrl('preview', docId, options);
	}

	navigateToErrorPage(error: ApiException): void {
		this.router.navigate([`/error/${error.status}`], {skipLocationChange: true});
	}

	private makeModuleAbsoluteUrl(
		module: string,
		path: string,
		options?: UrlCreationOptions,
	): string {
		const url = this.router.serializeUrl(this.urlTree([module, path], options));

		return `${this.doc.location.origin}${this.location.prepareExternalUrl(url)}`;
	}

	private parseUrl(link: string, pathNameNumber = 2): string {
		let result = '';

		try {
			result = new URL(link).pathname.split('/')[pathNameNumber];
		} catch {
			this.analytics.trackException(`Invalid video link: ${link}`);
		}

		return result;
	}

	private urlTree(commands: string[], options?: UrlCreationOptions): UrlTree {
		return this.router.createUrlTree(commands, options);
	}
}
