import {DOCUMENT, LocationStrategy} from '@angular/common';
import {Inject, Injectable, inject} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {TitleService} from '@shared/services';
import {DocDetailsItem, DocumentIndexItem} from '@shared/api';
import {AnalyticsService} from '@shared/analytics';
import {ModalService} from '@shared/modals';
import {PreviewDialogComponent} from '@preview/document';
import {PreviewSlidesDialogComponent, ShowSlidesStore} from '@preview/show-slides';

@Injectable({providedIn: 'root'})
export class PreviewService {
	private openPreviewSubject$ = new Subject<void>();

	openPreviewTrigger$: Observable<void> = this.openPreviewSubject$.asObservable();

	constructor(
		private readonly router: Router,
		private readonly modalService: ModalService,
		private readonly titleService: TitleService,
		private readonly location: LocationStrategy,
		private readonly analytics: AnalyticsService,
		@Inject(DOCUMENT) private readonly doc: Document,
	) {}

	openPreviewDialog(
		item: DocumentIndexItem,
		context: DocumentIndexItem[],
		presetId?: string,
		query?: string,
	): void {
		this.analytics.trackDocumentPreviewAction('open:preview', 'modal');

		this.modalService.openModal(PreviewDialogComponent, {
			type: 'PREVIEW-DOCUMENT',
			showCloseButton: true,
			data: {
				previewRequest: {
					documentId: item.id,
					presetId,
					query,
					context,
				},
			},
			underSelectionPanel: true,
			url: this.getPreviewPageUrl(item, false),
			title: this.titleService.getTitle(item.title.rawValue),
		});

		this.openPreviewSubject$.next();
	}

	// Temporary solution for previewing documents from the old Search screen
	openPreviewDialogFromSearch(documentId: string, title: string): void {
		this.analytics.trackDocumentPreviewAction('open:preview', 'modal');
		const urlTree = this.router.createUrlTree([`/preview/${documentId}`]);
		this.modalService.openModal(PreviewDialogComponent, {
			type: 'PREVIEW-DOCUMENT',
			showCloseButton: true,
			data: {
				previewRequest: {
					documentId,
					context: [],
				},
			},

			url: this.router.serializeUrl(urlTree),
			title,
		});

		this.openPreviewSubject$.next();
	}

	openPreviewSlidesDialog(docDetails: DocDetailsItem): void {
		const slidesCount = docDetails.document.file?.slidesCount ?? 0;
		const document =
			slidesCount === 1
				? docDetails.sourceDocument ?? docDetails.document
				: docDetails.document;

		this.showSlidesDialog(document, docDetails.document.id);
	}

	private showSlidesStore = inject(ShowSlidesStore);
	showSlidesDialog(document: DocumentIndexItem, selectedSlideId?: string): void {
		this.analytics.trackDocumentPreviewAction('open:show-slides', 'modal');
		this.showSlidesStore.loadSlides$({item: document, selectedSlideId});
		this.modalService.openModal(PreviewSlidesDialogComponent, {
			type: 'SHOW-DOCUMENT-SLIDES',
			showCloseButton: true,
			underSelectionPanel: true,
			title: this.titleService.getPreviewSlidesTitle(document?.title.rawValue),
			url: this.getPreviewSlidesPageUrl(document, false),
		});

		this.openPreviewSubject$.next();
	}

	getPreviewPageUrl(item: DocumentIndexItem, isAbsolute: boolean): string {
		if (item.previewPageUrl && item.previewPageUrl.startsWith('http')) {
			if (item.previewPageUrl === decodeURI(item.previewPageUrl)) {
				return encodeURI(item.previewPageUrl);
			} else {
				return item.previewPageUrl;
			}
		}

		const urlTree = this.router.createUrlTree([`/preview/${item.id}`]);
		const url = this.router.serializeUrl(urlTree);
		if (isAbsolute) {
			return `${this.doc.location.origin}${this.location.prepareExternalUrl(url)}`;
		}

		return url;
	}

	getPreviewSlidesPageUrl(item: DocumentIndexItem, isAbsolute: boolean): string {
		const urlTree = this.router.createUrlTree([`/preview-slides/${item.id}`]);
		const url = this.router.serializeUrl(urlTree);
		if (isAbsolute) {
			return `${this.doc.location.origin}${this.location.prepareExternalUrl(url)}`;
		}

		return url;
	}
}
