import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DistributionListService} from './../services';

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'teams',
	standalone: true,
})
export class TeamsPipe implements PipeTransform {
	constructor(private deliveryList: DistributionListService) {}

	transform(email?: string): string {
		const isDeliveryList = this.deliveryList.test(email);

		return isDeliveryList || !email
			? ''
			: `https://teams.microsoft.com/l/chat/0/0?users=${email}`;
	}
}
