import {ChangeDetectionStrategy, Component, Inject, Input, ViewEncapsulation} from '@angular/core';
import {SvgIconComponent} from '@pp/svg';
import {CONFIG, EnvConfigType} from '@environments/environment';

import {CrmAccountModel} from '@shared/models';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'shared-confidential-logos',
	templateUrl: './confidential-logos.component.html',
	styleUrls: ['./confidential-logos.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SvgIconComponent],
})
export class ConfidentialLogosComponent {
	accountId?: string;
	serviceUrl: string;

	@Input({required: true}) images!: string[];
	@Input() showAll = false;
	@Input({required: true}) accountLogos!: CrmAccountModel[];

	constructor(
		private analytics: AnalyticsService,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {
		this.serviceUrl = this.config.serviceUrl;
	}

	getIconName(logo: CrmAccountModel, type: 'logo' | 'nda' | 'public'): string {
		let comment: string;
		let allowed: boolean | undefined;

		switch (type) {
			case 'logo':
				comment = logo.logoCanBeUsedComment;
				allowed = logo.logoCanBeUsed;
				break;
			case 'nda':
				comment = logo.referenceUnderNdaComment;
				allowed = logo.referenceUnderNda;
				break;
			case 'public':
				comment = logo.referenceInPublicComment;
				allowed = logo.referenceInPublic;
				break;
			default:
				throw new Error('Unsupported type!');
		}

		let result: string;

		if (allowed === undefined || allowed === null) {
			result = 'unknown';
		} else {
			result = allowed ? 'allowed' : 'denied';
		}

		return comment ? `${result}-comment` : `${result}`;
	}

	showLogo(id: string): void {
		this.accountId = id;
	}

	hideLogo(): void {
		this.accountId = undefined;
	}

	trackCrmNavigation(account: string): void {
		this.analytics.trackSearchResultsAction('openCrmAccount', account);
	}
}
