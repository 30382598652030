import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'filterSectionName',
	standalone: true,
})
@Injectable({providedIn: 'root'})
export class FilterSectionNamePipe implements PipeTransform {
	private readonly filterNameMapping: Dictionary<string> = {
		documentTags: 'Scope',
		locationFilterTree: 'Location Tree',
		fileType: 'File Type',
		fileContentType: 'Content Type',
		modifiedDate: 'Modified Date',
		contentOwner: 'Content Owner',
		sourceSystem: 'Source System',
		size: 'File Size',
		languages: 'Languages',
		preparedFor: 'Prepared For',
		about: 'About',
		displayAuthor: 'Display Author',
		tags: 'Tags',
		accessLevel: 'Shared With',
		masterLayout: 'Master Layout',
	};

	transform(value: string): string {
		return this.filterNameMapping[value] || '';
	}
}
