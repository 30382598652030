import {Injectable} from '@angular/core';
import {SortByModel} from '@search/models';

@Injectable({providedIn: 'root'})
export class SortByService {
	private readonly sortBy: SortByModel[] = [
		{
			name: 'Relevancy',
		},
		{
			name: 'Rank',
			value: 'RoleAgnosticRelevancy',
		},
		{
			name: 'Date (Newest)',
			value: 'Write',
			direction: 1,
		},
		{
			name: 'Date (Oldest)',
			value: 'Write',
			direction: 0,
		},
		{
			name: 'Size (Largest)',
			value: 'Size',
			direction: 1,
		},
	];

	getSortByList(): SortByModel[] {
		return this.sortBy;
	}
}
