import {Action} from '@ngrx/store';

import {SelectedFiltersPayload} from './selected-filters-payload';

export const REPLACE_INCLUDE_FILTER_SILENT = '[Search] Replace Include Filter Silent';

export class ReplaceIncludeFilterSilentAction implements Action {
	readonly type = REPLACE_INCLUDE_FILTER_SILENT;

	constructor(public payload: SelectedFiltersPayload) {}
}
