<article class="sb-main-wrapper">
	<form [formGroup]="clientForm" class="title-slide">
		<pp-icon name="presentation_title" class="title-slide-image"></pp-icon>
		<div class="title-slide-fields">
			<div class="title-slide-field">
				<label for="Title">Title</label>
				<span class="title-slide-field-required">*</span>
				<input
					id="Title"
					type="text"
					formControlName="title"
					placeholder="Presentation Title"
					autocomplete="off"
				/>
				@if (titleControl.invalid && (titleControl.touched || titleControl.dirty)) {
					<div class="title-slide-field-errors">
						@if (titleControl.errors?.['required']) {
							<span> Title is required. </span>
						}
						@if (titleControl.errors?.['pattern']) {
							<span> Title contains illegal characters. </span>
						}
						@if (titleControl.errors?.['minlength']) {
							<span> Title must be at least 3 characters long. </span>
						}
						@if (titleControl.errors?.['maxlength']) {
							<span> Title has a limit of 128 characters. </span>
						}
					</div>
				}
			</div>
			<div class="title-slide-field">
				<label for="SubTitle">Subtitle</label>
				<input
					id="SubTitle"
					type="text"
					formControlName="subTitle"
					placeholder="e.g. Customer Name"
					autocomplete="off"
				/>
				@if (
					subTitleControl.invalid && (subTitleControl.touched || subTitleControl.dirty)
				) {
					<div class="title-slide-field-errors">
						@if (subTitleControl.errors?.['maxlength']) {
							<span> Subtitle has a limit of 128 characters. </span>
						}
					</div>
				}
			</div>
		</div>
	</form>
</article>
