<div class="vertical-menu-container">
	<button class="vertical-menu-button" (click)="toggle()">
		@if (!visible) {
			<pp-icon name="navigation-menu-24" />
		} @else {
			<pp-icon name="navigation-close-24" />
		}
	</button>
	@if (visible) {
		<ul [@expandedPanel]="visible" class="vertical-menu-list">
			<shared-navigation-panel
				[containerSelector]="'.vertical-menu-list'"
			></shared-navigation-panel>
			<li>
				<a class="vertical-menu-link logout" href="/" (click)="emitLogout($event)">
					<pp-icon name="navigation-logout-18" />
					Logout
				</a>
			</li>
		</ul>
	}
</div>
<div #wrapper [hidden]="!visible" class="vertical-menu-wrapper" (click)="closeVerticalMenu()"></div>
