import {Pipe, PipeTransform} from '@angular/core';
import {RoleService, UserRole} from './role.service';

@Pipe({
	name: 'hasTeam',
	standalone: true,
})
export class HasTeamPipe implements PipeTransform {
	constructor(private readonly roleService: RoleService) {}

	transform(teams: string[] | null | undefined, role: UserRole): boolean {
		return this.roleService.isUserInRole(teams, role);
	}
}
