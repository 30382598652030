import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	ContentChildren,
	HostBinding,
	Input,
	QueryList,
} from '@angular/core';
import {NgTemplateOutlet, NgClass} from '@angular/common';
import {AsPrimaryDirective, AsSecondaryDirective} from '@shared/card';

@Component({
	selector: 'shared-card-actions',
	templateUrl: './card-actions.component.html',
	styleUrls: ['./card-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgTemplateOutlet, NgClass],
})
export class CardActionsComponent {
	@ContentChildren(AsSecondaryDirective) readonly secondary!: QueryList<AsSecondaryDirective>;
	@ContentChild(AsPrimaryDirective) readonly primary!: AsPrimaryDirective;

	@HostBinding('class.only-primary')
	get onlyPrimaryClass(): boolean {
		return !this.secondary.length;
	}

	@Input() size = 'normal';
}
