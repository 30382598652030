@if (navigatedDocument; as document) {
	<div class="preview-menu-container">
		<header class="preview-menu-header">
			<div class="preview-menu-actions">
				@if (hasContext) {
					<div
						class="preview-menu-button"
						[ngClass]="{active: asideVisible}"
						(click)="toggleAside()"
					>
						<pp-icon name="list" [size]="36" aria-hidden="true"></pp-icon>
						Context
					</div>
				}
			</div>
			<div class="preview-menu-filename">
				@if (navigationAvailable) {
					<pp-icon
						class="arrow-left"
						name="navigation-chevron-left-18"
						[size]="40"
						(click)="showPrevious()"
					></pp-icon>
				}

				{{ document.filePath.split('/').pop() }}
				@if (navigationAvailable) {
					<pp-icon
						class="arrow-right"
						name="navigation-chevron-right-18"
						[size]="40"
						(click)="showNext()"
					></pp-icon>
				}
			</div>
			<div class="preview-menu-actions">
				@if (document.filePath) {
					<pp-icon
						name="download"
						title="Download File {{ document.fileSize | fileSize }}"
						aria-hidden="true"
						(click)="download(document)"
						[size]="24"
					></pp-icon>
				}
				@if (document.sourceFilePath) {
					<pp-icon
						aria-hidden="true"
						title="Download Source"
						(click)="downloadSource(document)"
						name="source"
						[size]="24"
					></pp-icon>
				}
				<pp-icon
					name="link"
					aria-hidden="true"
					[cdkCopyToClipboard]="document.shareLink | encode"
					(cdkCopyToClipboardCopied)="handleCopiedLink($event)"
					[class.copied]="linkCopied"
					title="Copy Link"
					[size]="24"
				></pp-icon>
				<span class="preview-menu-close" (click)="closePreview()">Close</span>
			</div>
		</header>
		<main class="preview-menu-main">
			@if (hasContext && asideVisible) {
				<aside class="preview-menu-aside">
					<nav class="preview-menu-tabs">
						<div
							class="preview-menu-tab"
							[class.active]="tab === 'context'"
							(click)="switchTab('context')"
						>
							Source Document
						</div>
						<div
							class="preview-menu-tab"
							[ngClass]="{active: tab === 'recommend', info: goodRecommendations}"
							(click)="switchTab('recommend')"
						>
							Multi-context
						</div>
					</nav>
					<main class="preview-menu-tab-content">
						<shared-slide-context
							[base]="document"
							[visible]="tab === 'context'"
							(selectSlide)="replace($event)"
						></shared-slide-context>
						<shared-recommend-slides
							[base]="model"
							[visible]="tab === 'recommend'"
							(selectSlide)="navigate($event)"
							(replaceSlide)="replace($event)"
						></shared-recommend-slides>
					</main>
				</aside>
			}
			<shared-preview
				class="preview-menu-content"
				[document]="document"
				[query]="data?.query ?? ''"
				(click)="closePreview()"
				(closePreview)="closePreview()"
			></shared-preview>
		</main>
	</div>
}
