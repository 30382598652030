import {Injectable} from '@angular/core';
import {ToastService} from '../toast/toast.service';
import {AnalyticsService} from '@shared/analytics';

@Injectable({
	providedIn: 'root',
})
export class CopyLinkService {
	constructor(
		private analytics: AnalyticsService,
		private readonly toastService: ToastService,
	) {}

	handleCopied(copied: boolean): void {
		if (copied) {
			this.handleSuccess();
		} else {
			this.handleError();
		}
	}

	handleSuccess(): void {
		this.toastService.show({
			type: 'success',
			message: 'Link copied!',
		});
	}

	handleError(): void {
		this.analytics.trackException('copyLink');
		this.toastService.show({
			type: 'error',
			message: 'Link copying failed!',
		});
	}
}
