@if (slides$ | async; as slides) {
	@if (slides.length) {
		@if (loaded || visible) {
			<div class="recommend-slides-container" [class.hidden]="!visible">
				<div class="recommend-slides-base">
					<div
						class="recommend-slides-thumbnail"
						[class.selected]="base === selectedSlide"
						(click)="selectRecommendation(base)"
					>
						<pp-image name="logo_ppt_64" [width]="64" [height]="64" />
						<shared-thumbnail
							[src]="base.thumbnail"
							[external]="base.externalThumbnail!"
						></shared-thumbnail>
					</div>
					<div class="recommend-slides-separator">usually goes together with</div>
				</div>
				@for (slide of slides; track slide.documentId) {
					<div
						class="recommend-slides-thumbnail"
						[class.selected]="slide === selectedSlide"
						(click)="selectRecommendation(slide)"
					>
						<pp-image name="logo_ppt_64" [width]="64" [height]="64" />
						@if (devMode()) {
							<div class="recommend-slides-score">
								{{ slide.signatureScore }}
							</div>
						}
						<shared-thumbnail
							[src]="slide.thumbnail"
							[external]="slide.externalThumbnail!"
						></shared-thumbnail>
						@if (slide === selectedSlide) {
							<div class="recommend-slides-overlay">
								<button class="recommend-slides-update" (click)="update()">
									Show multi-context for selected slide
								</button>
							</div>
						}
					</div>
				}
			</div>
		}
	} @else {
		@if (visible) {
			<span class="recommend-slides-no-data">Multi-context is not available.</span>
		}
	}
} @else {
	@if (visible) {
		<pp-spinner class="recommend-slides-no-data"></pp-spinner>
	}
}
