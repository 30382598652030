@if (isLoaded()) {
	@for (nodeLvl1 of tree()!.children; track nodeLvl1.name) {
		<div class="taxonomy-block">
			@if (nodeLvl1.count > 0) {
				<h2 class="taxonomy-header">{{ nodeLvl1.name }}</h2>
				@if (nodeLvl1.items.length) {
					<div class="taxonomy-card-list">
						@for (item of nodeLvl1.items; track item.document.id) {
							<search-item-card
								[item]="item.document"
								[direction]="'vertical'"
								[context]="nodeLvl1.items"
							>
							</search-item-card>
						}
					</div>
				}

				@for (nodeLvl2 of nodeLvl1.children; track nodeLvl2.name) {
					@if (nodeLvl2.count > 0) {
						<h4 class="taxonomy-subheader">{{ nodeLvl2.name }}</h4>
						<div class="taxonomy-card-list">
							@for (item of nodeLvl2.items; track item.document.id) {
								<search-item-card
									[item]="item.document"
									[direction]="'vertical'"
									[context]="nodeLvl2.items"
								>
								</search-item-card>
							}
						</div>
					}
				}
			}
		</div>
	}
}
