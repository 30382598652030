import {Injectable, inject} from '@angular/core';
import {DocumentItemParserService} from '../../shared/api/document-item-parser.service';
import {DocumentIndexItem} from '@shared/api';
import {DownloadService} from '@shared/services';

@Injectable({providedIn: 'root'})
export class SearchDocumentService {
	private downloadService = inject(DownloadService);
	private parserService = inject(DocumentItemParserService);

	download(item: DocumentIndexItem) {
		if (this.parserService.isPowerPoint(item)) {
			this.downloadService.trackPptxDownloadEvent(item.id, new Date().getTime());
		}
		this.downloadService.downloadDocument(item.id, item.spoPresales?.docId);
	}

	openInNewTab(item: DocumentIndexItem) {
		this.downloadService.openDocumentInNewTab(item.id, item.previewPageUrl ?? '/');
	}
}
