import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorHandler, inject, NgModule} from '@angular/core';

import {API_BASE_URL} from '../shared/api';
import {ErrorTracker} from './error-tracker/error-tracker';
import {
	AuthenticationInterceptor,
	CloudAuthenticationInterceptor,
	UrlEncodeInterceptor,
	XHRHeaderInterceptor,
} from './http';
import {CONFIG} from '@environments/environment';

@NgModule({
	providers: [
		{
			provide: API_BASE_URL,
			useFactory: () => inject(CONFIG).apiUrl,
		},
		{provide: HTTP_INTERCEPTORS, useClass: XHRHeaderInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: CloudAuthenticationInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: UrlEncodeInterceptor, multi: true},
		{provide: ErrorHandler, useClass: ErrorTracker},
	],
})
export class CoreModule {}
