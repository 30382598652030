import * as actions from '../../actions';
import {resultsInitialState} from './results-initial-state';
import {ResultsState} from './results-state';

export function reducer(
	state: ResultsState = resultsInitialState,
	action: actions.ResultsActions,
): ResultsState {
	switch (action.type) {
		case actions.UPDATE_RESULTS:
			return {
				...state,
				...action.payload,
			};
		case actions.APPEND_RESULTS:
			return {
				...state,
				data: [...state.data, ...action.payload.data],
				count: action.payload.count,
				countWithDuplicates: action.payload.countWithDuplicates,
				spellingSuggestion: action.payload.spellingSuggestion,
				pageLoading: false,
			};
		case actions.REQUEST_NEXT_PAGE:
			return {
				...state,
				pageLoading: true,
			};
		default:
			return state;
	}
}
