import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {CrmAccountModel} from '../../models';
import {CrmAccountResponse} from './crm-accounts.response';
import {CONFIG, EnvConfigType} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class CrmAccountsService {
	constructor(
		private http: HttpClient,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	getAccountsByImage$(images: string[]): Observable<CrmAccountModel[]> {
		const fullUrl = `${this.config.serviceUrl}/api/images/accounts`;
		const body = images;

		return this.http
			.post<CrmAccountResponse[]>(fullUrl, body, {headers: this.prepareHeaders()})
			.pipe(
				map((response: CrmAccountResponse[]) =>
					response.map((account) => {
						const crmAccount = account.crmAccount;

						return {
							id: crmAccount.accountId,
							managerEmail: crmAccount.accountManagerEmail,
							managerName: crmAccount.accountManagerName,
							name: crmAccount.assignment.entityName,
							logoCanBeUsed: crmAccount.logoCanBeUsedPublicly,
							logoCanBeUsedComment: crmAccount.logoCanBeUsedPubliclyComment,
							referenceInPublic: crmAccount.referenceInPublic,
							referenceInPublicComment: crmAccount.referenceInPublicComment,
							referenceUnderNda: crmAccount.referenceUnderNda,
							referenceUnderNdaComment: crmAccount.referenceUnderNdaComment,
							status: crmAccount.status,
							md5: account.md5,
						};
					}),
				),
			);
	}

	private prepareHeaders(): HttpHeaders {
		const headers = new HttpHeaders({
			Accept: 'application/json',
		});

		return headers;
	}
}
