import {
	ConnectionPositionPair,
	HorizontalConnectionPos,
	OriginConnectionPosition,
	OverlayConnectionPosition,
	VerticalConnectionPos,
} from '@angular/cdk/overlay';

export enum PositionDirection {
	TOP = 'top',
	RIGHT = 'right',
	BOTTOM = 'bottom',
	LEFT = 'left',
}

export type PositionDirectionOption = keyof typeof PositionDirection;

export class Direction {
	#isTop = false;
	get isTop(): boolean {
		return this.#isTop;
	}

	#isRight = false;
	get isRight(): boolean {
		return this.#isRight;
	}

	#isBottom = false;
	get isBottom(): boolean {
		return this.#isBottom;
	}

	#isLeft = false;
	get isLeft(): boolean {
		return this.#isLeft;
	}

	static from(positionDirectionOption: PositionDirectionOption): Direction {
		const direction = PositionDirection[positionDirectionOption];

		return new Direction(direction);
	}

	constructor(readonly direction: PositionDirection) {
		switch (direction) {
			case PositionDirection.TOP:
				this.#isTop = true;
				break;
			case PositionDirection.RIGHT:
				this.#isRight = true;
				break;
			case PositionDirection.BOTTOM:
				this.#isBottom = true;
				break;
			case PositionDirection.LEFT:
				this.#isLeft = true;
				break;
			default:
				break;
		}
	}
}

export function getConnectionPosition(
	directionOption: PositionDirectionOption,
	offset = 0,
	inverted = false,
): ConnectionPositionPair {
	const direction = Direction.from(directionOption);
	let offsetHorizontal = 0;
	let offsetVertical = 0;
	let originHorizontal: HorizontalConnectionPos = 'center';
	let originVertical: VerticalConnectionPos = 'center';
	let overlayHorizontal: HorizontalConnectionPos = 'center';
	let overlayVertical: VerticalConnectionPos = 'center';

	switch (true) {
		case direction.isTop:
			originVertical = inverted ? invert<VerticalConnectionPos>('top') : 'top';
			overlayVertical = inverted ? invert<VerticalConnectionPos>('bottom') : 'bottom';
			offsetVertical = invertOffset(inverted, offset * -1);
			break;
		case direction.isRight:
			originHorizontal = inverted ? invert<HorizontalConnectionPos>('end') : 'end';
			overlayHorizontal = inverted ? invert<HorizontalConnectionPos>('start') : 'start';
			offsetHorizontal = invertOffset(inverted, offset);
			break;
		case direction.isBottom:
			originVertical = inverted ? invert<VerticalConnectionPos>('bottom') : 'bottom';
			overlayVertical = inverted ? invert<VerticalConnectionPos>('top') : 'top';
			offsetVertical = invertOffset(inverted, offset);
			break;
		case direction.isLeft:
			originHorizontal = inverted ? invert<HorizontalConnectionPos>('start') : 'start';
			overlayHorizontal = inverted ? invert<HorizontalConnectionPos>('end') : 'end';
			offsetHorizontal = invertOffset(inverted, offset * -1);
			break;
		default:
			break;
	}

	const origin: OriginConnectionPosition = {originX: originHorizontal, originY: originVertical};
	const overlay: OverlayConnectionPosition = {
		overlayX: overlayHorizontal,
		overlayY: overlayVertical,
	};

	return new ConnectionPositionPair(origin, overlay, offsetHorizontal, offsetVertical);
}

function invert<T extends string>(position: T): T {
	let inverted: string = position;

	switch (position) {
		case 'top':
			inverted = 'bottom';
			break;
		case 'start':
			inverted = 'end';
			break;
		case 'end':
			inverted = 'start';
			break;
		case 'bottom':
			inverted = 'top';
			break;
		case 'center':
		default:
			break;
	}

	return inverted as T;
}

function invertOffset(isInverted: boolean, offset: number): number {
	return isInverted ? offset * -1 : offset;
}
