@if (errorOccurred) {
	<div class="login-container">
		@if (!cookieEnabled) {
			<div>
				<p>It looks like currently you block cookies in your browser.</p>
				<p>
					Unfortunately to authenticate you need to enable cookies
					and login.microsoftonline.com.
				</p>
				<p>
					<a href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/auto"
						>How to enable cookies in my browser?</a
					>
				</p>
			</div>
		} @else {
			<p>
				It looks like an error occurred during login process. Please, try to logout and
				login again!
			</p>
			<button class="login-button" (click)="logout()">Logout</button>
		}
	</div>
} @else {
	<shared-presales-portal-loading></shared-presales-portal-loading>
}
