/// <reference types="@types/office-js" />

import {Injectable, NgZone, inject} from '@angular/core';
import {OfficeInfo, OfficeStore} from '@office/state';

@Injectable({providedIn: 'root'})
export class OfficeInitializationService {
	private zone = inject(NgZone);
	private officeStore = inject(OfficeStore);

	init() {
		if (!Office) {
			return;
		}

		Office.onReady((info) => {
			if (info.host === Office.HostType.PowerPoint) {
				const officeInfo: OfficeInfo = {
					host: Office.HostType[info.host],
					platform: Office.PlatformType[info.platform],
					version: Office.context.diagnostics.version,
				};

				this.zone.run(() => this.officeStore.setOfficeReady(officeInfo));
			}
		});
	}
}
