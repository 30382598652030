import {RequestData} from './request-data';

const defaultRules: [string, string][] = [
	// Boost all opportunities
	/* +0.3 */ ['pb=5E-1', 'usppDocumentTags=Verified'],
	/* +0.5 */ ['pb=5E-1', 'usppLocationFilterTree=Opportunities'],
	// Boost by doc type
	/* +0.3  */ ['pb=3E-1', 'usppDocumentTags=Final'],
	/* +0.2 */ ['pb=2E-1', 'usppDocumentTags=Output'],
	/* -0.2 */ ['pb=-2E-1', 'usppDocumentTags="Auto generated"'],
	/* -9.9  */ ['pb=-99E-1', 'usppDocumentTags="Divider Slide"'],
	/* -0.7  */ ['pb=-7E-1', 'fileExtension="page"'],
];

const casesRules: [string, string][] = [
	/* +0.5 */ ['pb=5E-1', 'usppDocumentTags=Verified'],
	// Boost by doc type
	/* +0.3  */ ['pb=3E-1', 'usppDocumentTags=Final'],
	/* +0.2  */ ['pb=2E-1', 'usppDocumentTags=Output'],
	/* -0.2  */ ['pb=-2E-1', 'usppDocumentTags="Auto generated"'],
];

const qnaRules: [string, string][] = [
	/* +0.5  */ ['pb=5E-1', 'fileExtension="docx"'],
	/* -0.6  */ ['pb=-6E-1', 'fileExtension="page"'],
];

function reduceRules(rules: [string, string][], query = '{searchTerms}'): string {
	return rules.reduce(
		(result, [boost, condition]) => `(${result}) XRANK(${boost}) (${condition})`,
		query,
	);
}

export function buildQueryTemplate(
	data: RequestData,
	rootTemplate: string,
	transform: (str: string) => string,
): string {
	const keywords = [
		':',
		'(',
		')',
		'AND',
		'OR',
		'NEAR',
		'=',
		'<>',
		'*',
		'NOT',
		'ONEAR',
		'WORDS',
		'XRANK',
		'-',
		'ALL',
		'ANY',
		'NONE',
	];
	const tags = data.includeFilters?.documentTags || [];

	const filterCS = tags.some((name) => transform(name) === 'Case study');
	const filterQA = tags.some((name) => transform(name) === 'Q&A');
	const complexQuery = keywords.some((keyword) => data.query && data.query.indexOf(keyword) > -1);
	const defaultTemplate = `{searchTerms}`;

	if (!data.sortBy) {
		if (filterCS) {
			if (complexQuery) {
				return reduceRules(casesRules, rootTemplate);
			} else {
				const template = `(${rootTemplate}) XRANK(pb=-2) (usppLocationFilterTree:"{searchTerms}")`;

				return reduceRules(casesRules, template);
			}
		} else if (filterQA) {
			if (complexQuery) {
				return reduceRules(qnaRules, defaultTemplate);
			} else {
				const template = `(${defaultTemplate}) XRANK(pb=5) (title:({searchTerms}))`;

				return reduceRules(qnaRules, template);
			}
		} else {
			const template = `(${rootTemplate}) XRANK(pb=2) (title:"{searchTerms}")`;

			return reduceRules(defaultRules, template);
		}
	}

	return '';
}
