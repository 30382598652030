@if (userProfile()) {
	<div class="panel-container">
		<header class="panel-header" [title]="userProfile.userDisplayName()">
			{{ userProfile.userDisplayName() }}
		</header>
		<panels-user-settings>
			<ng-content></ng-content>
		</panels-user-settings>
		@if (showFiltersSettings) {
			<panels-filters-settings></panels-filters-settings>
		}
	</div>
}
