import {ErrorHandler, Inject, Injectable, Injector, NgZone} from '@angular/core';

import {APM} from '@elastic/apm-rum-angular';
import {ApmBase} from '@elastic/apm-rum';
import {ToastService} from '@shared/services';
import {ToastMessageModel} from '@shared/models';
import {AnalyticsService} from '@shared/analytics';

@Injectable({providedIn: 'root'})
export class ErrorTracker extends ErrorHandler {
	constructor(
		private injector: Injector,
		private ngZone: NgZone,
		@Inject(APM) public apm: ApmBase,
	) {
		super();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override handleError(error: any): void {
		if (
			(error.status !== undefined && error.status !== 0) ||
			!error?.message?.includes('.svg: 0 Unknown Error')
		) {
			this.apm.captureError(error.originalError || error);
			this.logErrorToAnalytics(error);

			this.ngZone.run(() => {
				const toast: ToastMessageModel = {
					type: 'fatalError',
					message: 'An error occurred. Try to refresh a page.',
				};
				this.injector.get(ToastService).show(toast);
			});
		}

		super.handleError(error);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	logErrorToAnalytics(error: any): void {
		let message: string = error.message;

		if (error.url) {
			const [url] = error.url.split('?');
			const clearUrl = url
				.replace(/users\/[^\/]*/, 'users/{user}')
				.replace(/drives\/[^\/]*/, 'drives/{drive}')
				.replace(/items\/[^\/]*/, 'items/{item}')
				.replace(/s\/.*\/_api\/contextinfo/, 's/{site}/_api/contextinfo')
				.replace(/sites\/.*\/_api\/contextinfo/, 'sites/{site}/_api/contextinfo')
				.replace(/sites\/[^\/]*/, 'sites/{site}')
				.replace(/api\/documents\/[^\/]*/, 'api/documents/{documentId}')
				.replace(/api\/user\/get\/[^\/]*/, 'api/user/get')
				.replace(/api\/presets\/[^\/]*/, 'api/presets/{presetId}');
			message = message.replace(error.url, clearUrl);
		}

		const errorMessage = error.stack
			? `Message: ${message}. Stack: ${error.stack as string}`
			: `Message: ${message}`;

		this.injector.get(AnalyticsService).trackException(errorMessage);
	}
}
