<div class="info-widget-container">
	@for (scope of scopes(); track scope.title) {
		<div class="info-widget-section-container">
			<div
				class="info-widget-section"
				[class.active]="scope.isOpened"
				(click)="toggleListLinks($event, scope.title)"
			>
				<pp-icon
					class="info-widget-section-image"
					[name]="scope.icon"
					[size]="40"
				></pp-icon>

				<h3 class="info-widget-section-title">
					{{ scope.title }}
				</h3>

				<pp-icon
					class="info-widget-section-arrow-icon"
					[name]="
						scope.isOpened ? 'navigation-chevron-up-12' : 'navigation-chevron-down-12'
					"
					[size]="18"
				></pp-icon>

				@if (isMobile() === false) {
					@if (scope.isOpened && scope.items.length) {
						<div class="info-widget-section-links-list">
							@for (link of scope.items; track link.name) {
								@if (!link.isLocked) {
									@if (!link.externalLink) {
										<a
											class="link-item"
											[routerLink]="link.link"
											[queryParams]="link.queryParams"
											(click)="trackAnalytics(link.name)"
											>{{ link.name }}</a
										>
									} @else {
										<a
											class="link-item"
											[href]="link.externalLink"
											(click)="trackAnalytics(link.name)"
											target="_blank"
											>{{ link.name }}</a
										>
									}

									<ng-template #externalLink>
										<a
											class="link-item"
											[href]="link.externalLink"
											target="_blank"
											(click)="trackAnalytics(link.name)"
											>{{ link.name }}</a
										>
									</ng-template>
								} @else {
									<div class="link-item access-denied">
										<span>{{ link.name }}</span>

										<pp-icon
											(click)="openAccessInfoPopover($event)"
											class="access-icon"
											name="action-lock-fill-18"
										></pp-icon>
									</div>
								}

								<ng-template #accessDeniedLink>
									<div class="link-item access-denied">
										<span>{{ link.name }}</span>

										<pp-icon
											(click)="openAccessInfoPopover($event)"
											class="access-icon"
											name="action-lock-fill-18"
										></pp-icon>
									</div>
								</ng-template>
							}
						</div>
					}
				}
			</div>

			@if (isMobile()) {
				@if (scope.isOpened && scope.items.length) {
					<div class="info-widget-section-links-list mobile">
						@for (link of scope.items; track link.name) {
							@if (!link.isLocked) {
								@if (!link.externalLink) {
									<a
										class="link-item"
										[routerLink]="link.link"
										[queryParams]="link.queryParams"
										(click)="trackAnalytics(link.name)"
										>{{ link.name }}</a
									>
								} @else {
									#externalLink|
								}

								<ng-template #externalLink>
									<a
										class="link-item"
										[href]="link.externalLink"
										(click)="trackAnalytics(link.name)"
										target="_blank"
										>{{ link.name }}</a
									>
								</ng-template>
							} @else {
								#accessDeniedLink|
							}

							<ng-template #accessDeniedLink>
								<div class="link-item access-denied">
									<span>{{ link.name }}</span>

									<pp-icon
										(click)="openAccessInfoPopover($event)"
										class="access-icon"
										name="action-lock-fill-18"
									></pp-icon>
								</div>
							</ng-template>
						}
					</div>
				}
			}
		</div>
	}
</div>
