import {patchState, signalStore, withComputed, withMethods, withState} from '@ngrx/signals';
import {computed, inject} from '@angular/core';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import {pipe, switchMap, tap} from 'rxjs';
import {tapResponse} from '@ngrx/operators';
import {ApiException, DocumentIndexItem, DocumentsClient} from '@shared/api';
import {SearchQueryService} from '@search2/services';
import {OfficeStore} from '@office/state';
import {UtilsService} from '@shared/services';
import {AnalyticsService} from '@shared/analytics';
import {nullDocument} from '@preview/document/preview.store';

export interface ShowSlidesRequest {
	item: DocumentIndexItem;
	selectedSlideId: string | undefined;
}

export interface DeckSection {
	name?: string;
	slides: DocumentIndexItem[];
	hasSelectedSlide: boolean;
}

export interface ShowSlidesState {
	item: DocumentIndexItem;
	selectedSlideId: string | undefined;

	isLoading: boolean;
	slidesInOrder: DocumentIndexItem[];
	sections: DeckSection[];
}

const initialShowSlidesState: ShowSlidesState = {
	item: nullDocument,
	selectedSlideId: undefined,

	isLoading: true,
	slidesInOrder: [],
	sections: [],
};

export const ShowSlidesStore = signalStore(
	{providedIn: 'root'},
	withState(initialShowSlidesState),
	withComputed((store) => {
		const isInsidePowerPoint = inject(OfficeStore).isInsidePowerPoint;

		return {
			expanded: computed(() => !isInsidePowerPoint() || store.sections().length <= 1),
			sections: computed(() => {
				const slides = store.slidesInOrder();
				const selectedSlideId = store.selectedSlideId();

				const sections: DeckSection[] = [];
				slides.forEach((x) => {
					const sectionName = x.file?.generated?.slide?.sectionName;
					if (
						sections.length === 0 ||
						sectionName !== sections[sections.length - 1].name
					) {
						sections.push({
							name: sectionName,
							slides: [x],
							hasSelectedSlide: x.id === selectedSlideId,
						});
					} else {
						const section = sections[sections.length - 1];
						section.slides.push(x);
						section.hasSelectedSlide =
							section.hasSelectedSlide || x.id === selectedSlideId;
					}
				});

				return sections;
			}),
		};
	}),
	withMethods((store) => {
		const searchQueryService = inject(SearchQueryService);
		const documentsClient = inject(DocumentsClient);
		const analytics = inject(AnalyticsService);
		const utilsService = inject(UtilsService);

		const methods = {
			loadSlidesByDocId$: rxMethod<string>(
				pipe(
					tap(() => {
						patchState(store, {
							isLoading: true,
							item: nullDocument,
							selectedSlideId: undefined,
							slidesInOrder: [],
						});
					}),
					switchMap((id) => {
						// NOTE: Document Details endpoint is slow, because it currenlty request preview url from Graph API, but we have to use it here, basause we want to log preview events
						return documentsClient.getDetails(id, null, null).pipe(
							tapResponse({
								next: (details) => {
									methods.loadSlides$({
										item: details.document,
										selectedSlideId: undefined,
									});
								},
								error: (error) => {
									const e = error as ApiException;
									analytics.trackError(e.message);
									utilsService.navigateToErrorPage(e);
								},
							}),
						);
					}),
				),
			),
			loadSlides$: rxMethod<ShowSlidesRequest>(
				pipe(
					tap(({item, selectedSlideId}) => {
						patchState(store, {
							isLoading: true,
							item,
							selectedSlideId,
							slidesInOrder: [],
						});
					}),
					switchMap(({item}) => {
						const query = searchQueryService.createQuery({
							hiddenConstraints: `file.generated.source_doc_id:"${item.id}" AND last_modified:"${item.lastModified}"`,
							presetId: 'slides',
							size: item.file?.slidesCount ?? 200,
						});

						return searchQueryService.search$(query).pipe(
							tapResponse({
								next: (resp) => {
									patchState(store, {
										isLoading: false,
										slidesInOrder: resp.results
											.map((result) => result.document)
											.sort(
												(a, b) =>
													(a.file?.generated?.slide?.number ?? 0) -
													(b.file?.generated?.slide?.number ?? 0),
											),
									});
								},
								error: (error) => {
									const e = error as ApiException;
									analytics.trackError(e.message);
									utilsService.navigateToErrorPage(e);
								},
							}),
						);
					}),
				),
			),
		};

		return methods;
	}),
);
