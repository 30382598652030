import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {HighlightPipe, FilterValuePipe} from '@shared/pipes';
import {PreviewStore} from '@preview/document/preview.store';
import {SearchFileInfoFooterComponent, TagsSectionComponent} from '@preview/item-card';

@Component({
	selector: 'search-preview-metadata',
	templateUrl: './preview-metadata.component.html',
	styleUrls: ['./preview-metadata.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SearchFileInfoFooterComponent, TagsSectionComponent, HighlightPipe, FilterValuePipe],
})
export class PreviewMetadataComponent {
	private readonly previewStore = inject(PreviewStore);
	item = this.previewStore.docDetails.document;
	itemType = this.previewStore.docType;

	reportingIndustry = computed(() => {
		const industry = this.item().spoPresales!.crmReportingIndustry;

		return industry[industry.length - 1].textValue;
	});
}
