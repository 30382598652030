import {
	ChangeDetectionStrategy,
	Component,
	AfterViewInit,
	ElementRef,
	inject,
	input,
	computed,
	effect,
	signal,
	forwardRef,
} from '@angular/core';
import {NgClass} from '@angular/common';
import {SvgIconComponent} from '@pp/svg';
import {CheckboxSelectionComponent} from '@shared/selection';
import {SearchItemCardComponent} from '@preview/item-card';
import {DocumentIndexItem} from '@shared/api';
import {OfficeStore} from '@office/state';
import {DocumentItemActionService, SearchItemActionType} from '@shared/card-actions';
import {DeckSection} from '@preview/show-slides/show-slides.store';
import {DropdownComponent} from '@shared/components/dropdown/dropdown.component';

@Component({
	selector: 'search-preview-slides-section',
	templateUrl: './preview-slides-section.component.html',
	styleUrls: ['./preview-slides-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		SvgIconComponent,
		forwardRef(() => CheckboxSelectionComponent),
		DropdownComponent,
		NgClass,
		forwardRef(() => SearchItemCardComponent),
	],
})
export class PreviewSlidesSectionComponent implements AfterViewInit {
	section = input.required<DeckSection>();
	item = input.required<DocumentIndexItem>();
	slidesInOrder = input.required<DocumentIndexItem[]>();
	selectedSlideId = input<string>();
	expandedByDefault = input<boolean>(true);

	expanded = signal<boolean>(true);
	sectionName = computed(() => this.section().name);
	slides = computed(() => this.section().slides);
	slidesCount = computed(() => this.slides().length);

	private readonly officeStore = inject(OfficeStore);
	isInsidePowerPoint = this.officeStore.isInsidePowerPoint;
	actionInfo = computed(() => {
		if (this.isInsidePowerPoint()) {
			const actions: SearchItemActionType[] = [
				'powerpoint-insert-with-formatting',
				'powerpoint-insert-without-formatting',
			];

			return actions.map((action) => this.actionService.getActionInfo(action, this.item()));
		}

		return [];
	});

	constructor(
		private readonly eRef: ElementRef,
		private readonly actionService: DocumentItemActionService,
	) {
		effect(
			() => {
				this.expanded.set(this.expandedByDefault());
			},
			{allowSignalWrites: true},
		);
	}
	ngAfterViewInit() {
		setTimeout(() => {
			const activeSlide = this.eRef.nativeElement.querySelector('.active');

			if (activeSlide) {
				activeSlide.scrollIntoView();
			}
		}, 200);
	}

	toggleExpand(event: MouseEvent): void {
		event.stopPropagation();
		event.preventDefault();

		this.expanded.update((expanded) => !expanded);
	}

	insertSection(action: SearchItemActionType) {
		this.officeStore.insertInPresentation(
			this.section().slides.map((slide) => slide.id),
			action === 'powerpoint-insert-with-formatting',
		);
	}
}
