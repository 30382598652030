import {Action} from '@ngrx/store';

import {FilterModel} from '@search/models';

export const UPDATE_DISPLAY_AUTHOR_FILTER = '[Search] Update Display Author Filter';

export class UpdateDisplayAuthorFilterAction implements Action {
	readonly type = UPDATE_DISPLAY_AUTHOR_FILTER;

	constructor(public payload: FilterModel[]) {}
}
