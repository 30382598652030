<div class="preview-source-document">
	<header class="preview-source-document-title">Source Document</header>
	<main class="preview-source-document-card">
		<search-item-card
			[item]="item()"
			size="normal"
			direction="vertical"
			[lightMode]="true"
		></search-item-card>
	</main>
</div>
