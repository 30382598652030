import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {AsyncPipe} from '@angular/common';
import {SearchHistoryViewModel} from './search-history.viewmodel';
import {SearchHistoryService} from '@shared/services';
import {SpinnerComponent} from '@pp/spinner';
import {AnalyticsService} from '@shared/analytics';

const millisecondsInDay = 86400000;

@Component({
	selector: 'panels-search-history',
	templateUrl: './search-history.component.html',
	styleUrls: ['./search-history.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SpinnerComponent, AsyncPipe],
})
export class SearchHistoryComponent {
	private readonly dayMapping: Dictionary<string> = {
		[this.toStr(new Date())]: 'Today',
		[this.toStr(new Date(Date.now() - millisecondsInDay))]: 'Yesterday',
	};

	results$: Observable<SearchHistoryViewModel[]> = this.searchHistory.getSearchModel$().pipe(
		map((history) =>
			history.map((item) => ({
				...item,
				label: this.dateToText(item.date),
			})),
		),
		map((history) =>
			Array.from(new Set(history.map((item) => item.label))).map((label) => ({
				dateLabel: label,
				queries: history
					.filter((item) => item.label === label)
					.map((item) => item.queryText),
			})),
		),
		catchError(() => {
			this.analytics.trackException('An error ocurred during loading User Search History.');

			return [];
		}),
	);

	@Output() selectQuery = new EventEmitter();

	constructor(
		private searchHistory: SearchHistoryService,
		private analytics: AnalyticsService,
	) {}

	search(query: string): void {
		this.analytics.trackPanelActions('searchHistoryClick', 'Search History');
		this.selectQuery.emit(query);
	}

	private dateToText(isoDate: string): string {
		const name = this.toStr(new Date(isoDate));

		return this.dayMapping[name] || name;
	}

	private toStr(date: Date): string {
		const options = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		} as Intl.DateTimeFormatOptions;

		return date.toLocaleDateString('en-US', options);
	}
}
