<div class="delete-confirmation-container">
	<p class="delete-confirmation-text">
		You are going to delete this {{ itemToDeleteType }} {{ itemToDeleteName }}. Do you want to
		continue this action?
	</p>

	<div class="delete-confirmation-buttons">
		<button class="dp-button" type="button" (click)="confirmDeleting()">Delete</button>
		<button
			class="dp-button secondary confirmation-cancel"
			type="button"
			(click)="cancelDeleting()"
		>
			Cancel
		</button>
	</div>
</div>
