import {ChangeDetectionStrategy, Component, Input, OnInit, forwardRef, inject} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn, ReactiveFormsModule} from '@angular/forms';
import {Observable, of, tap} from 'rxjs';
import {distinctUntilChanged, debounceTime, switchMap} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {SvgIconComponent} from '@pp/svg';
import {AnalyticsService} from '@shared/analytics';
import {PreviewStore} from '@preview/document/preview.store';
import {SearchItemCardComponent} from '@preview/item-card';
import {DocumentIndexItem, SearchClient} from '@shared/api';
import {AutocompleteComponent, OptionComponent} from '@shared/components/autocomplete';

@Component({
	selector: 'search-documents-picker',
	templateUrl: './documents-picker.component.html',
	styleUrls: ['./documents-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AutocompleteComponent,
		ReactiveFormsModule,
		OptionComponent,
		forwardRef(() => SearchItemCardComponent),
		SvgIconComponent,
		AsyncPipe,
	],
})
export class DocumentsPickerComponent implements OnInit {
	@Input({required: true}) documentId!: string;

	documents$!: Observable<DocumentIndexItem[]>;
	private readonly debounceTime = 300;
	private urlValidator: ValidatorFn = (control: AbstractControl) => {
		let validUrl = true;

		if (!control.value) {
			return null;
		}

		try {
			new URL(control.value);
		} catch {
			validUrl = false;
		}

		return validUrl ? null : {invalidUrl: true};
	};

	searchQuery = new FormControl('', this.urlValidator);

	private previewStore = inject(PreviewStore);
	private searchClient = inject(SearchClient);
	private analyticsService = inject(AnalyticsService);

	ngOnInit() {
		this.documents$ = this.searchQuery.valueChanges.pipe(
			distinctUntilChanged(),
			debounceTime(this.debounceTime),
			switchMap((query) => {
				if (!!query && query.length > 2) {
					return this.searchClient.searchList([query]).pipe(
						tap((value) => {
							if (!value.length) {
								this.analyticsService.trackDocumentPreviewAction(
									'linked-document:not-found',
									query,
								);
							}
						}),
					);
				}

				return of([]);
			}),
		);
	}

	triggerSelect(document: DocumentIndexItem): void {
		this.previewStore.addLinkedDocument({
			documentId: this.documentId,
			documentToLinkId: document.id,
		});
		this.searchQuery.setValue('');
	}
}
