import {Action} from '@ngrx/store';

import {SelectedFiltersPayload} from './selected-filters-payload';

export const ADD_INCLUDE_FILTER_SILENT = '[Search] Add Include Filter Silent';

// TODO: Silent events are probably result of bad architecture decisions. Consider redesign.
export class AddIncludeFilterSilentAction implements Action {
	readonly type = ADD_INCLUDE_FILTER_SILENT;

	constructor(public payload: SelectedFiltersPayload) {}
}
