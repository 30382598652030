<div class="edit-documents-container">
	<div class="edit-documents-dialog" cdkDrag cdkDragBoundary=".edit-documents-container">
		<div class="edit-documents-dialog-content">
			<header class="edit-documents-header" cdkDragHandle>
				Edit Linked Documents
				<pp-icon (click)="closeDialog()" name="navigation-close-36" />
			</header>
			<main class="edit-documents-content">
				@if (linkedDocuments().length) {
					<div
						class="edit-documents-item-container"
						cdkDropList
						(cdkDropListDropped)="drop(linkedDocuments(), $event)"
					>
						@for (linkedItem of linkedDocuments(); track linkedItem.id) {
							<div
								cdkDrag
								class="edit-documents-item"
								[ngClass]="{'light-theme': theme() === 'light'}"
							>
								<div class="edit-documents-drag">
									<pp-icon name="action-drag_indicator-18" cdkDragHandle />
								</div>
								<search-item-card
									[item]="linkedItem.document"
									[size]="'small'"
									[showActions]="false"
									[direction]="'horizontal'"
								></search-item-card>

								@if (linkedItem.document.accessType !== 'limited') {
									<pp-icon
										(click)="openDeleteConfirmation($event, linkedItem)"
										class="edit-documents-item-remove"
										name="action-delete-24"
									/>
								}
							</div>
						}
					</div>
				} @else {
					<span class="no-results">Nothing here yet</span>
				}
			</main>

			<div class="edit-documents-divider"></div>

			<div class="edit-documents-search">
				<search-documents-picker [documentId]="documentId()"></search-documents-picker>
			</div>
		</div>
	</div>
</div>
