<div class="query-tips-container">
	<nav class="query-tips-header">
		<ul class="query-tips-tabs">
			<li [class.selected]="selectedTab === 'keywords'" (click)="switchTab('keywords')">
				Keywords
			</li>
			<li [class.selected]="selectedTab === 'samples'" (click)="switchTab('samples')">
				Samples
			</li>
		</ul>
	</nav>
	<main class="query-tips-content">
		@if (selectedTab === 'keywords') {
			<shared-query-keywords (send)="processKeyword($event)"></shared-query-keywords>
		}
		@if (selectedTab === 'samples') {
			<shared-query-samples (send)="processQuerySample($event)"></shared-query-samples>
		}
	</main>
	<footer class="query-tips-footer">
		<a
			class="query-tips-link"
			href="https://msdn.microsoft.com/en-us/library/office/ee558911.aspx"
			target="_blank"
		>
			<pp-icon name="action-external_link-18" />
			Search Query Syntax
		</a>
	</footer>
</div>
