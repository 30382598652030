<div class="subheader-settings-container">
	<button
		class="switch-view-button"
		btn
		btnType="secondary"
		[withIconBefore]="isGridViewPreferred() ? 'navigation-menu-18' : 'navigation-grid-18'"
		[hint]="isGridViewPreferred() ? 'Switch to List View' : 'Switch to Grid View'"
		[hintPosition]="'BOTTOM'"
		[onlyIcon]="true"
		(click)="switchView()"
	></button>
	@switch (currentPreset().id) {
		@case ('stories') {
			<a
				href="https://app.powerbi.com/groups/ea2e6aea-e496-4afb-8d6a-67fc51abeebc/dashboards/a5506221-a563-46fb-a4a9-d304dc5c332f"
				target="_blank"
				btn
				btnType="secondary"
				withIconBefore="editor-chart-bar_vert-18"
				[hint]="'Analytics'"
				[hintPosition]="'BOTTOM'"
				[onlyIcon]="true"
				*ppAnchor="'stories-button_analytics'"
				(click)="trackAnalytics('analytics')"
				>Analytics</a
			>

			<a
				href="https://forms.office.com/r/NcwTBqRh0w"
				target="_blank"
				rel="noopener"
				btn
				btnType="secondary"
				withIconBefore="communication-mail-18"
				[hint]="'Submit a Story'"
				[hintPosition]="'BOTTOM'"
				[onlyIcon]="true"
				*ppAnchor="'stories-button_submit-story'"
				(click)="trackAnalytics('submitStory')"
				>Submit a Story</a
			>
		}

		@case ('cases') {
			<a
				href="https://app.powerbi.com/groups/ea2e6aea-e496-4afb-8d6a-67fc51abeebc/dashboards/b40612db-dc39-4300-8626-5396a6b45666"
				target="_blank"
				btn
				btnType="secondary"
				withIconBefore="editor-chart-bar_vert-18"
				[hint]="'Analytics'"
				[hintPosition]="'BOTTOM'"
				[onlyIcon]="true"
				*ppAnchor="'stories-button_analytics'"
				(click)="trackAnalytics('analytics')"
				>Analytics</a
			>

			<a
				href="https://forms.office.com/r/1cGZfFQJzQ"
				target="_blank"
				rel="noopener"
				btn
				btnType="secondary"
				withIconBefore="communication-mail-18"
				[hint]="'Submit a Case Study'"
				[hintPosition]="'BOTTOM'"
				[onlyIcon]="true"
				*ppAnchor="'cases-button_submit-case'"
				(click)="trackAnalytics('submitCaseStudy')"
				>Submit a Case Study</a
			>
		}

		@case ('toolbox') {
			<a
				href="https://app.powerbi.com/groups/ea2e6aea-e496-4afb-8d6a-67fc51abeebc/dashboards/490702c6-a587-467d-8ef5-b698b9e08509"
				target="_blank"
				btn
				btnType="secondary"
				withIconBefore="editor-chart-bar_vert-18"
				[hint]="'Analytics'"
				[hintPosition]="'BOTTOM'"
				[onlyIcon]="true"
				*ppAnchor="'stories-button_analytics'"
				(click)="trackAnalytics('analytics')"
				>Analytics</a
			>
		}
	}

	<div class="subheader-settings-sort-by">
		<search-order-by
			[list]="currentPreset().sortOrderConfigs"
			[value]="orderByValue()"
			(changeOrder)="changeOrderBy($event)"
		></search-order-by>
	</div>
</div>
