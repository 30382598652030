<ul class="dp-chips search-tags" [class.small]="isCardSmall()" *collapse="isCardSmall()">
	@for (collection of collections(); track collection.rawValue) {
		<li [class.disabled]="collection.isDisabled">
			<div class="tag-item-container">
				<pp-icon
					class="dp-chips-left-icon"
					[size]="iconSize()"
					name="reward-icon"
				></pp-icon>
				<span
					class="dp-chips-label"
					[innerHTML]="collection | highlight"
					(click)="selectCollection(collection)"
				></span>
			</div>
		</li>
	}
	@for (tag of tags(); track tag.rawValue) {
		<li [class.disabled]="tag.isDisabled">
			<span
				class="dp-chips-label"
				[innerHTML]="tag | highlight"
				(click)="selectTag(tag)"
			></span>
		</li>
	}
</ul>
