import {Inject, Injectable, inject} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {tap} from 'rxjs/operators';
import {CopyLinkService} from '../../services/copy-link/copy-link.service';
import {DownloadService} from '../../services/download/download.service';
import {SearchItemActionType} from '../types/search-item-action-type.model';
import {RequestChangesService} from './request-changes.service';
import {DocumentIndexItem, DocumentItemParserService, SearchClient} from '@shared/api';
import {ContextualAnalyticsService} from '@shared/analytics';
import {PreviewService} from '@preview/services';
import {ViewAccessModalComponent} from '@shared/access';
import {CONFIG, EnvConfigType} from '@environments/environment';
import {OfficeStore} from '@office/state';
import {SearchDocumentService} from '@search2/services';
import {ModalService} from '@shared/modals';
import {HostnamePipe} from '@shared/pipes/hostname.pipe';

@Injectable()
export class CardActionsService {
	constructor(
		private readonly documentService: SearchDocumentService,
		private clipboard: Clipboard,
		private readonly previewService: PreviewService,
		private readonly resultParser: DocumentItemParserService,
		private readonly searchClient: SearchClient,
		private readonly modalService: ModalService,
		private readonly copyService: CopyLinkService,
		private readonly analytics: ContextualAnalyticsService,
		@Inject(CONFIG) private readonly config: EnvConfigType,
		private readonly requestChangesService: RequestChangesService,
	) {}
	private downloadService = inject(DownloadService);
	private officeStore = inject(OfficeStore);
	private readonly hostnamePipe = inject(HostnamePipe);

	onAction(
		actionType: SearchItemActionType,
		item: DocumentIndexItem,
		previewData?: PreviewData,
	): void {
		switch (actionType) {
			case 'preview':
				this.openPreview(item, previewData);
				break;
			case 'externalLink':
				this.openLink(item);
				break;
			case 'copy-link':
				this.copyLink(item);
				break;
			case 'download':
				this.download(item);
				break;
			case 'open-in-app':
				this.openInApp(item);
				break;
			case 'request-access':
				this.requestAccess(item);
				break;
			case 'show-slides':
				this.showSlides(item);
				break;
			case 'powerpoint-new':
				this.createNewPresentation(item);
				break;
			case 'powerpoint-insert-with-formatting':
				this.insertWithFormatting(item);
				break;
			case 'powerpoint-insert-without-formatting':
				this.insertWithoutFormatting(item);
				break;
			case 'edit-metadata':
				this.editMetadata(item);
				break;
			case 'view-access':
				this.viewAccess(item);
				break;
			case 'request-changes':
				this.requestChanges(item);
				break;
			case 'not-found':
				break;
			default:
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				throw new Error(`Unknown action type: ${actionType}`);
		}
	}

	private openPreview(item: DocumentIndexItem, previewData?: PreviewData): void {
		this.previewService.openPreviewDialog(
			item,
			previewData?.context ?? [],
			previewData?.presetId,
			previewData?.query,
		);
	}

	private openLink(item: DocumentIndexItem) {
		const hostname = this.hostnamePipe.transform(item.previewPageUrl);
		this.analytics.sendDocumentEvent('externalLink', hostname);

		this.documentService.openInNewTab(item);
	}

	private copyLink(item: DocumentIndexItem): void {
		this.clipboard.copy(this.previewService.getPreviewPageUrl(item, true));
		this.copyService.handleCopied(true);
		this.analytics.sendDocumentEvent('copy-link', item.title.rawValue);
	}

	private download(item: DocumentIndexItem): void {
		this.analytics.sendDocumentEvent('download', item.title.rawValue);
		this.documentService.download(item);
	}

	private openInApp(item: DocumentIndexItem): void {
		this.analytics.sendDocumentEvent('open-in-app', item.title.rawValue);
		this.downloadService.openDocumentInApp(
			item.id,
			this.resultParser.getOpenInAppSchema(item)!,
		);
	}

	private requestAccess(item: DocumentIndexItem): void {
		this.analytics.sendDocumentEvent('request-access', item.title.rawValue);

		if (item.previewPageUrl) {
			this.downloadService.openPageInNewTab(item.previewPageUrl);
		}
	}

	private showSlides(item: DocumentIndexItem): void {
		this.analytics.sendDocumentEvent('show-slides', item.title.rawValue);

		const sourceDocId = item.file?.generated?.sourceDocId;
		if (sourceDocId && this.resultParser.isAutoGenerated(item)) {
			const subscription = this.searchClient
				.searchList([sourceDocId])
				.pipe(
					tap((items) => {
						if (items.length > 0) {
							this.previewService.showSlidesDialog(items[0], item.id);
						} else {
							this.analytics.trackError(`Source document ${sourceDocId} not found`);
						}

						subscription.unsubscribe();
					}),
					// eslint-disable-next-line rxjs/no-ignored-subscription
				)
				.subscribe();
		} else {
			this.previewService.showSlidesDialog(item);
		}
	}

	private createNewPresentation(item: DocumentIndexItem): void {
		this.analytics.sendDocumentEvent('powerpoint-new', item.title.rawValue);

		this.officeStore.createNewPresentation(item.id);
		this.logDownloadEvents(item);
	}

	private insertWithFormatting(item: DocumentIndexItem): void {
		this.insertInPresentation(item, true);
	}

	private insertWithoutFormatting(item: DocumentIndexItem): void {
		this.insertInPresentation(item, false);
	}

	private insertInPresentation(item: DocumentIndexItem, withFormatting: boolean): void {
		const eventName = withFormatting
			? 'powerpoint-insert-with-formatting'
			: 'powerpoint-insert-without-formatting';
		this.analytics.sendDocumentEvent(eventName, item.title.rawValue);

		this.officeStore.insertInPresentation([item.id], withFormatting);
		this.logDownloadEvents(item);
	}

	private logDownloadEvents(item: DocumentIndexItem) {
		if (item.spoPresales?.docId && item.previewPageUrl) {
			const url = this.config.serviceUrl + item.previewPageUrl;
			this.downloadService.logFileDownload(url, item.spoPresales.docId);
		}
	}

	private editMetadata(item: DocumentIndexItem): void {
		this.analytics.sendDocumentEvent('edit-metadata', item.title.rawValue);

		const editLink = this.getEditMetadataLink(item);
		this.downloadService.openPageInNewTab(editLink);
	}

	private getEditMetadataLink(item: DocumentIndexItem): string {
		let type = 'Stories';
		let link = '';

		switch (this.resultParser.getItemType(item)) {
			case 'case':
				type = 'CaseStudies';
				break;
			case 'toolbox':
				type = 'WinBox';
				break;
			case 'offering':
				type = 'Offerings';
				break;
			default:
			case 'story':
				type = 'Stories';
				break;
		}

		if (!!item.spoPresales) {
			link = `https://sharepoint.com/sites/PresalesStories/${type}/Forms/AllItems.aspx?view=7&q=DlcDocId%3A${item.spoPresales.docId}`;
		}

		return link;
	}

	private viewAccess(item: DocumentIndexItem): void {
		this.analytics.sendDocumentEvent('view-access', item.title.rawValue);

		this.modalService.openModal(ViewAccessModalComponent, {
			type: 'VIEW-ACCESS-MODAL',
			showCloseButton: false,
			data: {
				documentId: item.id,
			},
		});
	}

	private requestChanges(item: DocumentIndexItem): void {
		this.analytics.sendDocumentEvent('request-changes', item.title.rawValue);
		this.requestChangesService.requestDocumentChanges(item.id);
	}
}

interface PreviewData {
	context: DocumentIndexItem[];
	presetId?: string;
	query?: string;
}
