@if (collection.items.length) {
	<div class="widget-container">
		<search-cards-swiper [items]="collection.items">
			<ng-container header>
				<header class="widget-container-header">
					<h3 class="widget-container-title">{{ title }}</h3>

					@if (collection.presetLink !== undefined) {
						<search-preset-link
							class="dp-card-header-more-link widget-container-link"
							[link]="collection.presetLink"
						>
							Show all ({{ collection.totalCount }})
							<pp-icon name="navigation-chevron-right-18"></pp-icon>
						</search-preset-link>
					}
				</header>
			</ng-container>
			<ng-template #itemTemplate let-item>
				<search-item-card
					[item]="item"
					[direction]="'vertical'"
					[lightMode]="true"
					[context]="collection.items"
				></search-item-card>
			</ng-template>
		</search-cards-swiper>
	</div>
}
