import {
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewEncapsulation,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	OnDestroy,
} from '@angular/core';
import {Subject, Observable, of, Subscription} from 'rxjs';

import {distinctUntilChanged, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {NgClass, AsyncPipe} from '@angular/common';
import {SlideNumberPipe} from '../../pipes/slide-number.pipe';
import {ThumbnailComponent} from '../thumbnail/thumbnail.component';
import {SvgImageComponent} from '@pp/svg';
import {SpinnerComponent} from '@pp/spinner/spinner.component';
import {PostQueryService, RecommendSlidesService, ResultsService} from '@shared/services';
import {SearchResultModel} from '@shared/models';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'shared-slide-context',
	templateUrl: './slide-context.component.html',
	styleUrls: ['./slide-context.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		NgClass,
		SvgImageComponent,
		ThumbnailComponent,
		SpinnerComponent,
		AsyncPipe,
		SlideNumberPipe,
	],
})
export class SlideContextComponent implements OnChanges, OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();
	private contextModel$ = new Subject<SearchResultModel>();
	private readonly rowLimit = 500;

	slides$!: Observable<SearchResultModel[]>;
	loaded = false;

	@Input({required: true}) base!: SearchResultModel;
	@Input() visible = false;
	@Output() selectSlide = new EventEmitter<SearchResultModel>();

	constructor(
		private postQuery: PostQueryService,
		private recommendedSlidesService: RecommendSlidesService,
		private resultsService: ResultsService,
		private element: ElementRef,
		private analytics: AnalyticsService,
	) {}

	ngOnInit(): void {
		this.slides$ = this.contextModel$.pipe(
			map((model) => model.filePath),
			distinctUntilChanged(),
			switchMap((filePath) =>
				this.recommendedSlidesService
					.getQuery$(filePath)
					.pipe(
						map((recommendSlides) => recommendSlides && recommendSlides.contextQuery),
					),
			),
			distinctUntilChanged(),
			switchMap((contextQuery) => {
				let result$: Observable<SearchResultModel[]>;

				if (contextQuery) {
					result$ = this.postQuery
						.retrieve$(
							{
								query: contextQuery,
								expandDuplicates: true,
								sortBy: 'FileName',
								sortDirection: 0,
								disableQueryRules: true,
							},
							{rowLimit: this.rowLimit, emitEvents: false},
						)
						.pipe(
							map((response) => {
								const data = response
									? this.resultsService.extractData(
											response.d.postquery.PrimaryQueryResult.RelevantResults,
										).data
									: [];

								return data;
							}),
						);
				} else {
					result$ = of([]);
				}

				return result$;
			}),
			shareReplay({bufferSize: 1, refCount: true}),
		);

		this.subscriptions.add(this.slides$.pipe(tap(this.reset.bind(this))).subscribe());
		this.contextModel$.next(this.base);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.contextModel$.next(this.base);
		this.loaded = this.visible || this.loaded;

		if (changes['visible']) {
			this.scrollToSelected();
		}
	}

	selectRecommendation(document: SearchResultModel): void {
		this.selectSlide.emit(document);
		this.analytics.trackPreviewAction('selectSourceSlide');
	}

	private reset(): void {
		this.loaded = this.visible;
		this.scrollToSelected();
	}

	private scrollToSelected(): void {
		if (this.visible) {
			setTimeout(() => {
				const selected = this.element.nativeElement.querySelector('.selected');

				if (selected) {
					selected.scrollIntoView();
				}
			});
		}
	}
}
