import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CONFIG, EnvConfigType} from '@environments/environment';

import {DocumentPropertyModel} from '@search/models';

@Injectable({providedIn: 'root'})
export class DocumentService {
	constructor(
		private http: HttpClient,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	// eslint-disable-next-line @typescript-eslint/ban-types
	setProperty$(data: DocumentPropertyModel): Observable<Object> {
		const fullUrl = `${this.config.serviceUrl}/api/documents/tag`;

		return this.http.put(fullUrl, data);
	}
}
