import {
	ChangeDetectionStrategy,
	Component,
	computed,
	forwardRef,
	inject,
	signal,
} from '@angular/core';
import {EditLinkedDocumentsComponent} from './edit-linked-documents/edit-linked-documents.component';
import {ButtonComponent} from '@pp/button';
import {SvgIconComponent} from '@pp/svg';
import {MoreLessPipe} from '@shared/pipes';
import {PreviewStore} from '@preview/document/preview.store';
import {ModalService} from '@shared/modals';
import {SearchItemCardComponent} from '@preview/item-card';

@Component({
	selector: 'search-preview-linked-documents',
	templateUrl: './preview-linked-documents.component.html',
	styleUrls: ['./preview-linked-documents.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ButtonComponent,
		forwardRef(() => SearchItemCardComponent),
		SvgIconComponent,
		MoreLessPipe,
	],
})
export class PreviewLinkedDocumentsComponent {
	private previewStore = inject(PreviewStore);
	private modalService = inject(ModalService);

	items = this.previewStore.linkedDocuments;
	itemsCount = computed(() => this.items().length);
	isShowMore = signal(true);

	showEditDialog(): void {
		this.modalService.openModal(EditLinkedDocumentsComponent, {
			type: 'EDIT-LINKED-DOCUMENTS-MODAL',
			showCloseButton: false,
		});
	}

	toggleShowMore(): void {
		this.isShowMore.update((value) => !value);
	}
}
