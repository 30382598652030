import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {CrawlStateResponse} from './crawl-state-response';
import {CONFIG, EnvConfigType} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class CrawlService {
	constructor(
		private http: HttpClient,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	getCrawlState$(filePath: string): Observable<CrawlStateResponse> {
		const fullUrl = `${
			this.config.serviceUrl
		}/api/documents/crawlstate?url=${encodeURIComponent(filePath)}`;

		return this.http.get<CrawlStateResponse>(fullUrl, {headers: this.prepareHeaders()});
	}

	private prepareHeaders(): HttpHeaders {
		const headers = new HttpHeaders({
			Accept: 'application/json',
		});

		return headers;
	}
}
