import {ComponentType, Overlay, OverlayRef, ScrollStrategy} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {LocationStrategy, Location} from '@angular/common';
import {Injectable, Injector} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {filter, tap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {ModalContainerComponent} from './modal-container/modal-container.component';
import {ModalRef} from './modal-container/modal.ref';
import {ModalScrollableStrategy} from './modal-container/modal-scrollable.strategy';
import {CURRENT_MODAL} from './current-modal.token';
import {DocumentScrollService} from './document-scroll.service';
import {AnalyticsService} from '@shared/analytics';
import {ThemeModeType} from '@shared/theme';

interface IModalState {
	type: string;
	ref?: ModalRef;
	title?: string;
	url?: string;
	subscription?: Subscription;
}

interface IOpenOptions<T> {
	type: string;
	showCloseButton: boolean;
	data?: Partial<T>;
	title?: string;
	url?: string;
	underSelectionPanel?: boolean;
	theme?: ThemeModeType;
}

@Injectable({providedIn: 'root'})
export class ModalService {
	private orderedModals: IModalState[] = [];
	private navigationSubscription: Subscription = new Subscription();
	private isRedirectClose = false;

	get isRedirect() {
		return this.isRedirectClose;
	}

	constructor(
		private readonly overlay: Overlay,
		private readonly injector: Injector,
		private readonly documentScrollService: DocumentScrollService,
		private readonly router: Router,
		private readonly location: Location,
		private readonly locationStrategy: LocationStrategy,
		private readonly title: Title,
		private readonly analytics: AnalyticsService,
	) {}

	getModalsCount() {
		return this.orderedModals.length === 0 ? 0 : this.orderedModals.length - 1;
	}

	openModal<T>(cmp: ComponentType<T>, options: IOpenOptions<T>): T {
		// If we open a first modal
		if (this.orderedModals.length === 0) {
			// save url & title before fist modal
			const currentUrl = this.router.url;
			this.orderedModals.push({
				type: 'ROOT',
				url: currentUrl,
				title: this.title.getTitle(),
			});

			// replace url for top record of browser history with current url
			// note: we don't want to add new record to browser history, because bach() trigger navigation and mess up scroll position
			this.setUrl(currentUrl);

			// Navigation should close all modals
			this.navigationSubscription.unsubscribe();
			this.navigationSubscription = this.router.events
				.pipe(
					filter((event) => event instanceof NavigationStart),
					tap(() => this.closeBeforeNavigation()),
				)
				.subscribe();

			// Browser back also close all modals
			// note: in the future we may want to support close top-most modal only
			this.locationStrategy.onPopState(() => this.closeBeforeNavigation());
		}

		// We should close old modals before opening new one
		this.closeModalByType(options.type);

		const [modalRef, component] = this.createModal<T>(
			cmp,
			options.showCloseButton,
			options.data,
			options.underSelectionPanel,
			options.theme,
		);
		const subscription = modalRef.closed$
			.pipe(tap(() => this.removeModal(modalRef)))
			.subscribe();

		this.addNewModal({
			type: options.type,
			ref: modalRef,
			title: options.title,
			url: options.url,
			subscription,
		});

		return component;
	}

	private closeBeforeNavigation() {
		this.isRedirectClose = true;
		this.closeAllModals();
		this.isRedirectClose = false;
	}

	private closeModalByType(type: string): void {
		for (let index = 0; index < this.orderedModals.length; ) {
			const currentModal = this.orderedModals[index];
			if (currentModal.type === type) {
				currentModal.subscription?.unsubscribe();
				currentModal.ref?.close();
				this.orderedModals.splice(index, 1);
			} else {
				index++;
			}
		}
	}

	private addNewModal(state: IModalState): void {
		// add modal to the top and update url/title if needed
		this.orderedModals.push(state);

		if (state.url) {
			this.setUrl(state.url);
		}
		if (state.title) {
			this.setTitle(state.title);
		}
	}

	private removeModal(modalRef: ModalRef): void {
		const index = this.orderedModals.findIndex((x) => x.ref === modalRef);
		if (index < 0) {
			this.analytics.trackException('ModalService.removeModal: Modal not found');

			return;
		}

		const modal = this.orderedModals.splice(index, 1)[0];
		modal.subscription?.unsubscribe();
		modal.ref?.close();

		// if we close the top-most modal we need to update title and url
		if (index === this.orderedModals.length) {
			let previousIndex = index - 1;
			while (
				previousIndex > 0 &&
				(!this.orderedModals[previousIndex].url || !this.orderedModals[previousIndex].title)
			) {
				previousIndex--;
			}
			const previousModal = this.orderedModals[previousIndex];
			if (previousModal.url) {
				this.setUrl(previousModal.url);
			}
			if (previousModal.title) {
				this.setTitle(previousModal.title);
			}
		}

		// if we closed all modals we need to reset state
		if (this.orderedModals.length === 1) {
			this.closeAllModals();
		}
	}

	private closeAllModals(): void {
		for (const modal of this.orderedModals) {
			modal.subscription?.unsubscribe();
			modal.ref?.close();
		}
		this.orderedModals = [];
		this.navigationSubscription.unsubscribe();
	}

	private setUrl(url: string): void {
		this.location.replaceState(url);
		this.analytics.trackPageNavigation(url);
	}

	private setTitle(title: string): void {
		const titleWithoutMarkup = title.replace(/<mark>/g, '').replace(/<\/mark>/g, '');
		this.title.setTitle(titleWithoutMarkup);
	}

	private createModal<T>(
		cmp: ComponentType<T>,
		showCloseButton: boolean,
		options?: Partial<T>,
		underSelectionPanel = false,
		fixedTheme?: ThemeModeType,
	): [ModalRef, T] {
		const overlayRef = this.createOverlay(underSelectionPanel);

		if (underSelectionPanel) {
			overlayRef.hostElement.classList.add('lower-overlay-wrapper');
		} else {
			overlayRef.hostElement.classList.add('modal-overlay-panel');
		}

		const modalRef = new ModalRef(overlayRef);

		const portal = new ComponentPortal(
			ModalContainerComponent,
			null,
			this.createInjector(modalRef),
		);
		const {instance: portalInstance} = overlayRef.attach(portal);
		portalInstance.showCloseContainer = showCloseButton;
		portalInstance.fixedTheme = fixedTheme;

		const {instance} = portalInstance.attachComponentPortal(new ComponentPortal(cmp));

		if (options) {
			for (const key of Object.keys(options)) {
				instance[key as keyof typeof instance] = options[
					key as keyof typeof options
				] as T[keyof T];
			}
		}

		return [modalRef, instance];
	}

	private createOverlay(underSelectionPanel: boolean): OverlayRef {
		const scrollStrategy: ScrollStrategy = new ModalScrollableStrategy(
			this.documentScrollService,
			this,
		);
		const positionStrategy = this.overlay.position().global();
		positionStrategy.centerHorizontally();

		return this.overlay.create({
			scrollStrategy,
			positionStrategy,
			hasBackdrop: true,
			backdropClass: underSelectionPanel
				? ['cdk-overlay-dark-backdrop', 'lower-overlay-wrapper']
				: 'cdk-overlay-dark-backdrop',
		});
	}

	private createInjector(modalRef: ModalRef): Injector {
		return Injector.create({
			providers: [{provide: CURRENT_MODAL, useValue: modalRef}],
			parent: this.injector,
		});
	}
}
