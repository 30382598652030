import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'shared-no-results',
	templateUrl: './no-results.component.html',
	styleUrls: ['./no-results.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
})
export class NoResultsComponent {
	@Input({required: true}) query!: string;
}
