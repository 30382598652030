<div class="dp-card metadata">
	<header class="metadata-header" [innerHTML]="item().title | highlight"></header>

	@if (itemType() === 'case') {
		<div class="metadata-subheader">
			@if ((item().spoPresales?.crmReportingIndustry?.length ?? 0) > 0) {
				<div
					class="metadata-subheader-industry dp-dot-chain-link"
					[innerHTML]="reportingIndustry() | highlight"
				></div>
			}
		</div>
	}

	@if (!item.description.highlightedValue) {
		<div class="metadata-description">
			<span [innerHTML]="item().description | highlight"></span>
		</div>
	}

	@switch (itemType()) {
		@case ('story') {
			<div class="metadata-categories">
				<div>
					Story categories:
					@for (category of item().spoPresales?.storyCategory; track category) {
						<span class="dp-dot-chain-link">{{ category }}</span>
					}
				</div>
				<div>
					Story taxonomy:
					@for (taxonomy of item().spoPresales?.storyTaxonomy; track taxonomy) {
						<span class="dp-dot-chain-link">{{ taxonomy | filterName }}</span>
					}
				</div>
			</div>
		}

		@case ('offering') {
			<div class="metadata-categories">
				<div>
					Story categories:
					@for (category of item().spoPresales?.storyCategory; track category) {
						<span class="dp-dot-chain-link">{{ category }}</span>
					}
				</div>
				<div>
					Story taxonomy:
					@for (taxonomy of item().spoPresales?.storyTaxonomy; track taxonomy) {
						<span class="dp-dot-chain-link">{{ taxonomy | filterName }}</span>
					}
				</div>
			</div>
		}

		@case ('toolbox') {
			<div class="metadata-categories">
				<div>
					Toolbox taxonomy:
					@for (taxonomy of item().spoPresales?.toolboxTaxonomy; track taxonomy) {
						<span class="dp-dot-chain-link">{{ taxonomy | filterName }}</span>
					}
				</div>
			</div>
		}
	}

	<div class="metadata-date-info-container">
		<search-file-info-footer [document]="item()"></search-file-info-footer>
	</div>

	@if (itemType() === 'case' || itemType() === 'offering') {
		<div class="case-tags-divider"></div>
		<search-tags-section [item]="item()"></search-tags-section>
	}
</div>
