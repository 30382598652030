import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import {PreviewUserInfoComponent} from '../preview-user-info/preview-user-info.component';
import {SpinnerComponent} from '@pp/spinner/';
import {HintDirective} from '@pp/hint';
import {SvgIconComponent} from '@pp/svg';
import {CURRENT_MODAL, ModalRef} from '@shared/modals';
import {DocumentsClient, ListPermissionsResult} from '@shared/api';

@Component({
	selector: 'shared-view-access-modal',
	templateUrl: './view-access-modal.component.html',
	styleUrls: ['./view-access-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CdkDrag,
		CdkDragHandle,
		SvgIconComponent,
		PreviewUserInfoComponent,
		HintDirective,
		SpinnerComponent,
		AsyncPipe,
	],
})
export class ViewAccessModalComponent implements OnInit {
	@Input({required: true}) documentId!: string;

	permissions$!: Observable<ListPermissionsResult>;

	constructor(
		private readonly documentClient: DocumentsClient,
		@Inject(CURRENT_MODAL) readonly modalRef: ModalRef,
	) {}

	ngOnInit() {
		this.permissions$ = this.documentClient.listPermissions(this.documentId);
	}

	closeDialog(): void {
		this.modalRef.close();
	}
}
