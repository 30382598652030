import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Injector,
	Input,
	TemplateRef,
} from '@angular/core';
import {OverlayModule} from '@angular/cdk/overlay';
import {NgTemplateOutlet} from '@angular/common';
import {ThemableComponent} from '@shared/theme/themable-component';

@Component({
	selector: 'pp-hint',
	standalone: true,
	imports: [OverlayModule, NgTemplateOutlet],
	templateUrl: './hint.component.html',
	styleUrls: ['./hint.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintComponent extends ThemableComponent {
	hintTemplate: TemplateRef<unknown> | null = null;
	hintMessage: string | null = null;

	@Input()
	set message(msg: TemplateRef<unknown> | string | null) {
		if (typeof msg === 'string') {
			this.hintMessage = msg;
			this.hintTemplate = null;
		} else {
			this.hintTemplate = msg;
			this.hintMessage = null;
		}
	}

	@Input()
	@HostBinding('class.big')
	big = false;

	@Input()
	@HostBinding('style.--max-width')
	maxWidth = '380px';

	@Input()
	@HostBinding('class.triangle')
	triangle = false;

	constructor(override readonly injector: Injector) {
		super(injector);
	}
}
