/// <reference types="@types/office-js" />

import {Injectable, NgZone, inject} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PowerPointService {
	private zone = inject(NgZone);

	// Check if current PowerPoint version is supported
	isPowerPointVersionSupported(minVersion: string) {
		if (Office.context.requirements) {
			return Office.context.requirements.isSetSupported('PowerPointApi', minVersion);
		}

		return false;
	}

	// Create new presentation from base64 string
	createPresentation(base64File: string): Promise<void> {
		return PowerPoint.createPresentation(base64File);
	}

	// Insert slides from base64 string after selected slide using source or destination formatting
	insertAfter$(
		slideId: string | undefined,
		base64File: string,
		keepSourceFormatting: boolean,
	): Observable<void> {
		const formatting = keepSourceFormatting
			? PowerPoint.InsertSlideFormatting.keepSourceFormatting
			: PowerPoint.InsertSlideFormatting.useDestinationTheme;

		const promise = PowerPoint.run(async function (context) {
			context.presentation.insertSlidesFromBase64(base64File, {
				formatting,
				targetSlideId: slideId,
			});

			await context.sync();
		});

		return this.fromPromise(promise);
	}

	// Get ID of the last selected slide in selection Slide Range or undefined if no slides are selected
	getLastSelectedSlideId$(): Observable<string | undefined> {
		return new Observable((observer) => {
			Office.context.document.getSelectedDataAsync(
				Office.CoercionType.SlideRange,
				function (asyncResult: Office.AsyncResult<SlideRange>) {
					try {
						if (asyncResult.status === Office.AsyncResultStatus.Failed) {
							observer.next(undefined);
						} else {
							const slides = asyncResult.value.slides;
							if (slides.length > 0) {
								const slideId = slides[slides.length - 1].id;
								observer.next(`${slideId}#`);
							} else {
								observer.next(undefined);
							}
						}
					} catch (error) {
						observer.next(undefined);
					}
					observer.complete();
				},
			);
		});
	}

	// Get all slide IDs (ordered)
	getAllSlideIds$(): Observable<string[]> {
		const promise = PowerPoint.run(async (context) => {
			const slides = context.presentation.slides;
			slides.load('items/id');
			await context.sync();

			return slides.items.map((slide) => slide.id);
		});

		return this.fromPromise(promise);
	}

	// Select slides by IDs
	selectSlides$(slideIds: string[]): Observable<void> {
		const promise = PowerPoint.run(async function (context) {
			context.presentation.setSelectedSlides(slideIds);
			await context.sync();
		});

		return this.fromPromise(promise);
	}

	// Get all tags for all slides
	getSlideTags(): Promise<SlideTagsInfo[]> {
		const promise = PowerPoint.run(async (context) => {
			const slides = context.presentation.slides;
			//slides.load('items');
			slides.load('tags/key, tags/value');
			await context.sync();

			return slides.items.map((slide) => {
				const tags = slide.tags.items.map((x: PowerPoint.Tag) => ({
					key: x.key,
					value: x.value,
				}));

				return {
					slideId: slide.id,
					tags,
				} as SlideTagsInfo;
			});
		});

		return promise;
	}

	private fromPromise<T>(promise: PromiseLike<T>) {
		return new Observable((subscriber: Subscriber<T>) => {
			promise.then(
				(value) => {
					if (!subscriber.closed) {
						this.zone.run(() => {
							subscriber.next(value);
							subscriber.complete();
						});
					}
				},
				(err) => this.zone.run(() => subscriber.error(err)),
			);
		});
	}
}

interface SlideRange {
	slides: {
		id: number;
		title: string;
		index: number;
	}[];
}

export interface TagInfo {
	key: string;
	value: string;
}

export interface SlideTagsInfo {
	slideId: string;
	tags: TagInfo[];
}
