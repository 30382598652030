import {Action, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {
	filtersLoaded,
	filtersLoading,
} from '@search/actions/loading-filters/loading-filters.actions';
import {loadingFiltersInitialState} from '@search/reducers/loading-filters/loading-filters.initial-state';
import {LoadingFiltersState} from '@search/reducers/loading-filters/loading-filters.state';
import {SearchState} from '@search/reducers/search-state';

const loadingFilters = createReducer(
	loadingFiltersInitialState,
	on(filtersLoading, (state, {filters}) => ({...state, ...mapState(filters, true)})),
	on(filtersLoaded, (state, {filters}) => ({...state, ...mapState(filters, false)})),
);

export function loadingFiltersReducer(
	state: LoadingFiltersState | undefined,
	action: Action,
): LoadingFiltersState {
	return loadingFilters(state, action);
}

function mapState(filters: string[], activator = true): {[key: string]: boolean} {
	return filters.reduce((state, filterName) => ({[filterName]: activator, ...state}), {});
}

const searchStateSelector = createFeatureSelector<SearchState>('search');
const loadingFiltersSelector = createFeatureSelector<LoadingFiltersState>('loadingFilters');

const stateSelector = createSelector(searchStateSelector, loadingFiltersSelector);

export const isLoadingFilter = (name: keyof LoadingFiltersState) =>
	createSelector(stateSelector, (state: LoadingFiltersState) => state[name]);
