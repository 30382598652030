import {Injectable} from '@angular/core';
import {MailTo} from '@shared/models';
import {DocDetailsItem, DocumentItemParserService, ListContactsResult} from '@shared/api';
import {getUrlWithoutContext} from '@shared/utils';

@Injectable({
	providedIn: 'root',
})
export class MailToService {
	constructor(private readonly resultParser: DocumentItemParserService) {}

	getMailToRequest(itemDetails: DocDetailsItem, contacts: ListContactsResult): MailTo {
		let request: MailTo;
		const document = itemDetails.document;
		const ownerEmails = contacts.owners.items.map((x) => x.email ?? '');

		switch (this.resultParser.getItemType(document)) {
			case 'story':
				request = {
					to: 'SupportPresalesStories@epam.com',
					cc: ownerEmails,
					subject: `Change Request for "${document.title.rawValue}" Story`,
					body: `${document.title.rawValue}: ${getUrlWithoutContext()}`,
				};
				break;
			case 'offering':
				request = {
					to: ownerEmails,
					cc: 'SupportPresalesStories@epam.com',
					subject: `Change Request for "${document.title.rawValue}" Offering`,
					body: `${document.title.rawValue}: ${getUrlWithoutContext()}`,
				};
				break;
			case 'case':
				request = {
					to: 'SupportPresalesStories@epam.com',
					cc: this.getCaseStudyEmails(itemDetails).concat(ownerEmails),
					subject: `Change Request for "${document.title.rawValue}" Case Study`,
					body: `${document.title.rawValue}: ${getUrlWithoutContext()}`,
				};
				break;
			case 'toolbox':
				request = {
					to: 'OrgPresalesBelarus@epam.com',
					cc: ownerEmails,
					subject: `Change Request for "${document.title.rawValue}" Toolbox`,
					body: `${document.title.rawValue}: ${getUrlWithoutContext()}`,
				};
				break;
			case 'document':
			default:
				request = {
					to: 'SupportEPM-USPP@epam.com',
					subject: `Change Request for "${document.title.rawValue}"`,
					body: `${document.title.rawValue}: ${getUrlWithoutContext()}`,
				};
		}

		return request;
	}

	private getCaseStudyEmails(itemDetails: DocDetailsItem): string[] {
		const result: string[] = [];

		itemDetails.crmAccounts.forEach((account) => {
			if (account.accountManager?.email) {
				result.push(account.accountManager.email);
			}
			account.projects.forEach((project) => {
				if (project.accountManager?.email) {
					result.push(project.accountManager.email);
				}
				if (project.deliveryManager?.email) {
					result.push(project.deliveryManager.email);
				}
			});
		});

		return [...new Set(result)];
	}
}
