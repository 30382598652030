@if (isRoot === false) {
	<section [class.selected]="isSelected(tree.payload)" (click)="selectNode(tree.payload)">
		@if (isHaveChildren) {
			<pp-icon name="navigation-chevron-right-18" (click)="toggleExpand($event)"></pp-icon>
		}
		<ng-template
			[ngTemplateOutlet]="itemView.template"
			[ngTemplateOutletContext]="context(tree.payload)"
		></ng-template>
	</section>
}
@if (viewChild) {
	@for (child of tree.children; track trackNode($index, child)) {
		<shared-tree-view
			[tree]="child"
			[level]="nextLevel"
			[selected]="selected"
			(selectElement)="selectNode($event)"
		>
			<ng-template
				*treeViewItem="let node"
				[ngTemplateOutlet]="itemView.template"
				[ngTemplateOutletContext]="context(node)"
			></ng-template>
		</shared-tree-view>
	}
}
