import {
	ChangeDetectionStrategy,
	Component,
	ComponentRef,
	EmbeddedViewRef,
	Input,
	ViewChild,
} from '@angular/core';
import {
	BasePortalOutlet,
	CdkPortalOutlet,
	ComponentPortal,
	TemplatePortal,
} from '@angular/cdk/portal';

@Component({
	selector: 'shared-popup-container',
	templateUrl: './popover-container.component.html',
	styleUrls: ['./popover-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CdkPortalOutlet],
})
export class PopoverContainerComponent extends BasePortalOutlet {
	@ViewChild(CdkPortalOutlet, {static: true}) portalOutlet!: CdkPortalOutlet;

	@Input() popoverPosition? = 'bottom';

	constructor() {
		super();
	}

	attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
		return this.portalOutlet.attachComponentPortal(portal);
	}

	attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
		return this.portalOutlet.attachTemplatePortal(portal);
	}
}
