<div class="metadata-container">
	@if (type === 'Opportunities' || type === 'Projects') {
		<span class="metadata-filter" (click)="filter(branch)">{{ branch }}</span>
		@if (leaf) {
			\
			<span class="metadata-filter" (click)="filter(leaf)">{{ leaf }}</span>
		}
	} @else {
		<span class="metadata-filter" (click)="filter(type)">{{ type }}</span>
		@if (branch) {
			\
			<span class="metadata-filter" (click)="filter(branch)">{{ branch }}</span>
		}
	}
	@if (result.owner && result.owner !== 'Other') {
		by
		<span class="metadata-filter metadata-filter-owner" (click)="filter(result.owner)">{{
			result.owner
		}}</span>
	}
</div>
