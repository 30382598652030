import {Action} from '@ngrx/store';

import {FilterModel} from '@search/models';

export const UPDATE_TAGS_FILTER = '[Search] Update Tags Filter';

export class UpdateTagsFilterAction implements Action {
	readonly type = UPDATE_TAGS_FILTER;

	constructor(public payload: FilterModel[]) {}
}
