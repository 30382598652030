import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';

import {AsyncPipe, DatePipe} from '@angular/common';
import {DownloadHistoryModel} from '@search/models';
import {DownloadHistoryService} from '@search/services';
import {SpinnerComponent} from '@pp/spinner';

@Component({
	selector: 'search-download-history',
	templateUrl: './download-history.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SpinnerComponent, AsyncPipe, DatePipe],
})
export class DownloadHistoryComponent implements OnInit {
	downloads$!: Observable<DownloadHistoryModel[]>;

	@Input({required: true}) documentId!: string;

	constructor(private downloadHistoryService: DownloadHistoryService) {}

	ngOnInit(): void {
		this.downloads$ = this.downloadHistoryService.getDocumentDownloadHistoryWithDuplicates$(
			this.documentId,
		);
	}
}
