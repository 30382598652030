@if (isDocumentLoaded()) {
	<div class="preview-container">
		<search-preview-document
			class="preview-document-container"
			[docDetails]="docDetails()"
			(heightEmitter)="setGridRowHeight($event)"
			(toggleMode)="toggleExpandedMode($event)"
		>
		</search-preview-document>

		<main>
			@if (docDetails().document.description.highlightedValue) {
				<search-preview-highlights-description
					class="preview-highlights"
					[highlightedDescription]="docDetails().document.description.highlightedValue!"
				></search-preview-highlights-description>
			}

			<search-preview-actions class="preview-actions mobile"></search-preview-actions>

			<search-preview-metadata></search-preview-metadata>

			<!-- Case Studies from the Collection-->
			@if (relatedDocs().collectionName) {
				<search-preview-cases
					[collectionName]="relatedDocs().collectionName!"
					[item]="docDetails().document"
				></search-preview-cases>
			}
			<!-- Related Stories -->
			@if (relatedDocs().relatedStories.items.length > 0) {
				<search-preview-cards-widget
					[title]="'Related Presales Stories'"
					[collection]="relatedDocs().relatedStories"
				></search-preview-cards-widget>
			}
			<!-- Related Offering -->
			@if (relatedDocs().relatedOfferings.items.length > 0) {
				<search-preview-cards-widget
					[title]="'Related Presales Offerings'"
					[collection]="relatedDocs().relatedOfferings"
				></search-preview-cards-widget>
			}
			<!-- Often downloaded together -->
			@if (relatedDocs().recommendationIds.length > 0) {
				<search-preview-recommended-items
					[documentIds]="relatedDocs().recommendationIds"
				></search-preview-recommended-items>
			}
		</main>

		<aside>
			<search-preview-actions class="preview-actions web"></search-preview-actions>

			@if (docDetails().document.spoPresales?.notes) {
				<shared-note
					[text]="docDetails().document.spoPresales?.notes!"
					[fontSize]="14"
				></shared-note>
			}

			@if ((docDetails().document.videoUrl?.length ?? 0) > 0) {
				<search-preview-video
					[videoUrls]="docDetails().document.videoUrl ?? []"
					label="Watch How to Pitch This"
				></search-preview-video>
			}

			@if (docDetails().sourceDocument) {
				<search-preview-source-document
					[item]="docDetails().sourceDocument!"
				></search-preview-source-document>
			}

			@if (hasOwners()) {
				<search-preview-contacts
					title="Content Owners"
					[contactType]="'owners'"
				></search-preview-contacts>
			}

			@for (account of docDetails().crmAccounts; track account.cdmId) {
				<search-preview-account-info [account]="account"></search-preview-account-info>
			}

			@if (isContactsLoaded()) {
				<search-preview-contacts
					title="Additional Contacts"
					[contactType]="'additional'"
				></search-preview-contacts>
			}
			@if (isLinkedDocumentsLoaded()) {
				<search-preview-linked-documents />
			}
			@if (isLinksLoaded()) {
				<search-preview-links />
			}
		</aside>
	</div>
} @else {
	<pp-skeleton type="preview"></pp-skeleton>
}

@if (hasPrevNext()) {
	<pp-icon
		class="document-slider prev"
		[ngClass]="{disabled: !prevDocument()}"
		name="arrow-slider"
		[size]="64"
		(click)="prevDocument() ? openPreview(prevDocument()!) : null"
	></pp-icon>
	<pp-icon
		class="document-slider next"
		[ngClass]="{disabled: !nextDocument()}"
		name="arrow-slider"
		[size]="64"
		(click)="nextDocument() ? openPreview(nextDocument()!) : null"
	></pp-icon>
}
