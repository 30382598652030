import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ElementRef,
	HostListener,
	Input,
	OnDestroy,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DropdownContainerComponent} from './dropdown-container/dropdown-container.component';
import {ButtonComponent, ButtonType} from '@pp/button';

@Component({
	selector: 'shared-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [ButtonComponent],
})
export class DropdownComponent implements OnDestroy {
	@Input() type: ButtonType = 'primary';
	@Input() icon = '';
	@Input() iconOnly = false;
	@Input() showChevron = true;
	@Input() openStrategy: 'hover' | 'click' = 'hover';
	@ViewChild('dropDownButton') dropDownButton!: ElementRef;

	@ContentChild('dropdownContent')
	readonly dropdownContentTemplate!: TemplateRef<unknown>;
	dropDownOverlayRef?: OverlayRef;
	private readonly subscriptions = new Subscription();

	isOpened = false;

	constructor(
		private readonly overlay: Overlay,
		private readonly elementRef: ElementRef,
		private detector: ChangeDetectorRef,
	) {}

	get chevronStateIcon(): string {
		return this.showChevron
			? !!this.dropDownOverlayRef
				? 'navigation-chevron-up-12'
				: 'navigation-chevron-down-12'
			: '';
	}

	@HostListener('mouseenter', ['true'])
	@HostListener('mouseleave', ['false'])
	onHover(opened: boolean): void {
		if (this.openStrategy === 'hover') {
			this.isOpened = opened;
		}
	}

	ngOnDestroy() {
		this.hideDropdown();
		this.subscriptions.unsubscribe();
	}

	toggleDropdown(): void {
		if (!!this.dropDownOverlayRef) {
			this.hideDropdown();
		} else {
			this.openDropdown();
		}
	}

	get isDropDownOpened(): boolean {
		return !!this.dropDownOverlayRef;
	}

	private openDropdown(): void {
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(this.elementRef)
			.withPositions([
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top',
				},
			]);

		const scrollStrategy = this.overlay.scrollStrategies.reposition();

		this.dropDownOverlayRef = this.overlay.create({
			panelClass: 'hint-overlay-panel',
			positionStrategy,
			scrollStrategy,
		});

		this.dropDownOverlayRef.hostElement.classList.add('modal-overlay-panel');

		const portal = new ComponentPortal(DropdownContainerComponent);

		const {instance: portalInstance} = this.dropDownOverlayRef.attach(portal);

		if (this.dropdownContentTemplate) {
			portalInstance.dropdownContentTemplate = this.dropdownContentTemplate;

			this.subscriptions.add(
				portalInstance.closeDropDownEvent
					.pipe(
						tap(() => {
							this.hideDropdown();
						}),
					)
					.subscribe(),
			);
		}
	}

	private hideDropdown(): void {
		if (!!this.dropDownOverlayRef) {
			this.dropDownOverlayRef.dispose();
			this.dropDownOverlayRef = undefined;
			this.detector.detectChanges();
		}
	}
}
