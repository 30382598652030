import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {CONFIG, EnvConfigType} from '@environments/environment';

import {CloudAuthenticationService} from '@core/services';

@Injectable({providedIn: 'root'})
export class CloudAuthenticationInterceptor implements HttpInterceptor {
	constructor(
		private cloudAuthentication: CloudAuthenticationService,
		@Inject(CONFIG) private readonly config: EnvConfigType,
	) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (request.url.startsWith(this.config.apiUrl)) {
			return from(this.cloudAuthentication.getApiAccessToken()).pipe(
				switchMap((token) => {
					const clientName =
						this.config.environment === 'development' ? 'development' : 'presales-ui';
					const requestWithExtraHeaders = request.clone({
						headers: request.headers
							.set('X-Client-Name', clientName)
							.set('Authorization', `Bearer ${token}`),
					});

					return next.handle(requestWithExtraHeaders);
				}),
			);
		} else {
			return next.handle(request);
		}
	}
}
