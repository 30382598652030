import {ChangeDetectionStrategy, Component, ElementRef, Input} from '@angular/core';

@Component({
	selector: 'shared-abbreviation-icon',
	standalone: true,
	templateUrl: './abbreviation-icon.component.html',
	styleUrls: ['./abbreviation-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbbreviationIconComponent {
	@Input({required: true}) label!: string;

	@Input()
	set coloringType(coloringType: number) {
		if (coloringType !== undefined) {
			this.element.nativeElement
				.querySelector('.abbreviation-container')
				.classList.add(`coloring-type-${coloringType % 8}`);
		}
	}

	constructor(private readonly element: ElementRef) {}
}
