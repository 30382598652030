@if (items$ | async; as items) {
	<div class="histogram-filter-container">
		@if (range$ | async; as range) {
			<shared-range-slider
				ppCdkUseTheme="light"
				[filterName]="filterName"
				[bars]="items"
				[rangeInput]="range"
				(rangeChange)="onRangeChange($event, items)"
				(analytics)="onAnalytics($event)"
			></shared-range-slider>
		}
	</div>
}
