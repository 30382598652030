<div class="preview-description-container">
	<div class="preview-description-header">
		<span>Search highlights summary</span>
		<pp-icon
			hint="Text could be not displayed in preview because it is located in other slides or in slide notes"
			maxWidth="200px"
			hintPosition="RIGHT"
			name="notification-info-outline-24"
			[size]="16"
		></pp-icon>
	</div>
	<div [innerHTML]="highlightedDescription"></div>
</div>
