import {NgZone, computed, inject} from '@angular/core';
import {patchState, signalStore, withComputed, withMethods, withState} from '@ngrx/signals';
import {combineLatest, firstValueFrom, from, map, mergeMap, toArray} from 'rxjs';
import {
	DownloadWithProgress,
	PowerPointService,
	SlideTagsInfo,
	DownloadProgressState,
} from '@office/services';
import {ToastMessageType} from '@shared/models';
import {AnalyticsService} from '@shared/analytics';
import {ToastStore} from '@shared/state';

export interface OfficeInfo {
	host?: string;
	platform?: string;
	version?: string;
}

export interface OfficeState {
	officeInfo: OfficeInfo;
	tagsInfo: SlideTagsInfo[];
	showOnlyPowerPoint: boolean;
}

export const officeInitialState: OfficeState = {
	officeInfo: {
		host: undefined,
		platform: undefined,
		version: undefined,
	},
	tagsInfo: [],
	showOnlyPowerPoint: false,
};

export const OfficeStore = signalStore(
	{providedIn: 'root'},
	withState(officeInitialState),
	withComputed((state) => ({
		isInsidePowerPoint: computed(() => state.officeInfo().host === 'PowerPoint'),
	})),
	withMethods((store) => {
		const zone = inject(NgZone);
		const analytics = inject(AnalyticsService);
		const toastStore = inject(ToastStore);
		const powerPointService = inject(PowerPointService);
		const downloadWithProgress = inject(DownloadWithProgress);

		function toast(type: ToastMessageType, message: string, marker?: string): void {
			zone.run(() => {
				toastStore.addToastMessage({
					message,
					type,
					marker,
				});
			});
		}
		function updateProgressToastByMarker(marker: string, progress: DownloadProgressState[]) {
			let totalSize = 0;
			let loadedSize = 0;

			progress.forEach((state) => {
				if (state.total) {
					totalSize += state.total;
					loadedSize += state.loaded;
				}
			});

			const totalProgress = totalSize === 0 ? 0 : Math.floor((100 * loadedSize) / totalSize);
			toastStore.updateProgress(marker, totalProgress);
		}
		function processError(error: unknown): void {
			analytics.trackError(error);

			let message = 'Unexpected error';
			if (error instanceof Error) {
				message = error.message;
			} else if (typeof error === 'string') {
				message = error;
			}

			toast('error', message);
		}

		return {
			setOfficeReady(officeInfo: OfficeInfo): void {
				analytics.setOfficeLabels(officeInfo);

				patchState(store, () => ({
					officeInfo: {
						...officeInfo,
					},
					showOnlyPowerPoint: officeInfo.host === 'PowerPoint',
				}));
			},
			togglePowerPointReady(): void {
				patchState(store, (state) => ({
					showOnlyPowerPoint: state.officeInfo.host === 'PowerPoint' ? false : true,
					officeInfo: {
						host: state.officeInfo.host === 'PowerPoint' ? undefined : 'PowerPoint',
					},
				}));
			},
			toggleShowOnlyPowerPoint(): void {
				patchState(store, (state) => ({
					showOnlyPowerPoint: !state.showOnlyPowerPoint,
				}));
			},
			async createNewPresentation(documentId: string): Promise<void> {
				if (!store.isInsidePowerPoint()) {
					toast('error', 'App is running outside of PowerPoint');
				} else if (!powerPointService.isPowerPointVersionSupported('1.1')) {
					toast('error', 'PowerPoint does not supported API Set 1.1');
				} else {
					const marker = Date.now().toString();
					toast('loading', 'Downloading template...', marker);

					const progressState: DownloadProgressState[] = [
						{loaded: 0} as DownloadProgressState,
					];

					try {
						const request$ = downloadWithProgress.getFileBase64$(
							documentId,
							(state) => {
								progressState[0] = state;
								updateProgressToastByMarker(marker, progressState);
							},
						);
						const blob = await firstValueFrom(request$);

						toastStore.updateMessage(marker, 'Creating new presentation...');
						await powerPointService.createPresentation(blob);

						toastStore.removeToastByMarker(marker);
						toast('success', 'New presentation created');
					} catch (error: unknown) {
						toastStore.removeToastByMarker(marker);
						processError(error);
					}
				}
			},
			async insertInPresentation(
				documentIds: string[],
				withFormatting: boolean,
			): Promise<void> {
				if (!store.isInsidePowerPoint()) {
					toast('error', 'App is running outside of PowerPoint');
				} else if (!powerPointService.isPowerPointVersionSupported('1.2')) {
					toast('error', 'PowerPoint does not supported API Set 1.2');
				} else {
					const marker = Date.now().toString();
					toast('loading', 'Downloading documents...', marker);

					const progressState: DownloadProgressState[] = new Array(
						documentIds.length,
					).fill({loaded: 0});

					const job$ = combineLatest([
						powerPointService.getLastSelectedSlideId$(),
						powerPointService.getAllSlideIds$(),
					]).pipe(
						// insert slides
						mergeMap(([targetSlideId, slideIdsBeforeInsert]) =>
							from(documentIds).pipe(
								mergeMap((documentId, index) =>
									downloadWithProgress
										.getFileBase64$(documentId, (state) => {
											progressState[index] = state;
											updateProgressToastByMarker(marker, progressState);
										})
										.pipe(map((fileBase64) => ({index, fileBase64}))),
								),
								toArray(),
								mergeMap((files) => {
									toastStore.updateMessage(marker, 'Inserting documents...');

									// we sort file is reverse order to insert them in correct order
									// because we insert each file after fixed target slide
									return files.sort((a, b) => b.index - a.index);
								}),
								mergeMap((file) =>
									powerPointService.insertAfter$(
										targetSlideId,
										file.fileBase64,
										withFormatting,
									),
								),
								toArray(),
								map(() => slideIdsBeforeInsert),
							),
						),
						// select inserted slides
						mergeMap((slideIdsBeforeInsert) =>
							powerPointService.getAllSlideIds$().pipe(
								mergeMap((slideIds) => {
									const newSlides = slideIds.filter(
										(slideId) => !slideIdsBeforeInsert.includes(slideId),
									);

									return powerPointService.selectSlides$(newSlides);
								}),
							),
						),
					);

					try {
						await firstValueFrom(job$);
						toastStore.removeToastByMarker(marker);
						toast('success', 'Documents inserted');
					} catch (error) {
						toastStore.removeToastByMarker(marker);
						processError(error);
					}
				}
			},
			async loadTagsInfo(): Promise<void> {
				if (!store.isInsidePowerPoint()) {
					toast('error', 'App is running outside of PowerPoint');
				} else if (!powerPointService.isPowerPointVersionSupported('1.3')) {
					toast('error', 'PowerPoint does not supported API Set 1.3');
				} else {
					try {
						const tagsInfo = await powerPointService.getSlideTags();
						patchState(store, () => ({
							tagsInfo,
						}));
					} catch (error: unknown) {
						processError(error);
					}
				}
			},
		};
	}),
);
