import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'shared-page-progress-bar',
	templateUrl: './page-progress-bar.component.html',
	styleUrls: ['./page-progress-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
})
export class PageProgressBarComponent {}
