import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class TitleService {
	readonly postfix = 'Presales Portal';
	readonly divider = ' - ';
	constructor(private title: Title) {}

	loginTitle(): void {
		this.setTitle('Login');
	}

	setAdminTitle(): void {
		this.setTitle('Admin');
	}

	setAgreementTitle(): void {
		this.setTitle('Agreement');
	}

	setDashboardTitle(): void {
		this.setTitle('Home');
	}

	setPresalesReportTitle(query?: string): void {
		this.setSearchTitle(query, 'Ongoing Report');
	}

	setSearchTitle(query?: string, prefix = 'Search'): void {
		const queryTitle = query?.length ? `${this.divider}"${query}"` : '';

		this.setTitle(`${prefix}${queryTitle}`);
	}

	getTitle(title: string): string {
		return `${title}${this.divider}${this.postfix}`;
	}

	setTitle(title: string): void {
		const formattedTitle = this.getTitle(title);
		this.title.setTitle(formattedTitle);
	}

	setSearch2Title(preset: string, query?: string): void {
		const prefix = query?.length ? `Search in ${preset}` : preset;
		this.setSearchTitle(query, prefix);
	}

	getPreviewSlidesTitle(title: string): string {
		return `Slides from '${title}`;
	}
}
