<div class="section-header" (click)="toggleSection()">
	<pp-icon
		[name]="isOpened ? 'navigation-chevron-down-18' : 'navigation-chevron-right-18'"
		[size]="18"
	></pp-icon>

	<span>{{ section.name }}</span>
</div>

@if (isOpened) {
	<div class="section-content">
		@for (item of section.items; track item.document.id) {
			<powerpoint-section-item [item]="item"></powerpoint-section-item>
		}
	</div>
}
