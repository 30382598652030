@if (isVerified) {
	<pp-flag class="flag" [size]="flagSize" type="verified"></pp-flag>
}
@if (item.extraTags.includes('Sanctioned')) {
	<pp-flag class="flag" [size]="flagSize" type="sanctioned"></pp-flag>
}
@if (item.extraTags.includes('Decommissioned')) {
	<pp-flag class="flag" [size]="flagSize" type="decommissioned"></pp-flag>
}
@if ((item.videoUrl?.length ?? 0 > 0) && !isInsidePowerPoint()) {
	<pp-flag class="flag" [size]="flagSize" type="video"></pp-flag>
}
@if (item.extraTags.includes('New')) {
	<pp-flag class="flag" [size]="flagSize" type="new"></pp-flag>
}
