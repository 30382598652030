<div class="action-panel-container" ppCdkUseTheme="light">
	@if (currentPanel === 'searchHistory') {
		<panels-search-history (selectQuery)="changeQuery($event)"></panels-search-history>
	}
	@if (currentPanel === 'user') {
		<search-user-panel>
			@if (queryState$ | async; as queryState) {
				<label>
					<pp-checkbox
						[checked]="!queryState.expandDuplicates"
						(change)="toggleExpandDuplicates($event.target, queryState.submittedQuery)"
						text="Trim Duplicates"
					></pp-checkbox>
				</label>
				@if (queryState.expandDuplicates) {
					<div
						class="warning-sign"
						title="Warning: duplicates are expanded, results can be different from what you expect"
					></div>
				}
			}
		</search-user-panel>
	}
	@if (currentPanel === 'admin') {
		<search-admin-panel></search-admin-panel>
	}
	<nav class="action-panel">
		<ul class="action-panel-top">
			<li (click)="togglePanel('searchHistory')">
				<pp-icon
					name="history"
					aria-hidden="true"
					[size]="24"
					title="My Search History"
					class="action-panel-scale-icon"
					[class.active]="currentPanel === 'searchHistory'"
				></pp-icon>
			</li>
			<li (click)="togglePanel('user')">
				@if (expandDuplicates$ | async) {
					<div
						class="action-panel-notification"
						title="Warning: duplicates are expanded, results can be different from what you expect"
					>
						<div class="warning-sign"></div>
					</div>
				}
				<pp-icon
					name="user"
					aria-hidden="true"
					title="Open User Menu"
					[class.active]="currentPanel === 'user'"
					class="action-panel-scale-icon"
					[size]="24"
				></pp-icon>
			</li>
			@if ((teams() | hasTeam: 'admin') || impersonated) {
				<li (click)="togglePanel('admin')">
					<pp-icon
						name="yoda"
						aria-hidden="true"
						title="Open Admin Menu"
						[class.active]="currentPanel === 'admin'"
						class="action-panel-scale-icon"
						[size]="24"
					></pp-icon>
				</li>
			}
		</ul>
	</nav>
</div>
