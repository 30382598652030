import {ChangeDetectionStrategy, Component, forwardRef, inject} from '@angular/core';
import {ShowSlidesStore} from '../show-slides.store';
import {PreviewSlidesSectionComponent} from './preview-slides-section/preview-slides-section.component';
import {SharedSkeletonComponent} from '@pp/skeleton';
import {CardListComponent} from '@shared/card-containers';
import {SearchItemCardComponent} from '@preview/item-card';
import {AnalyticsService, ContextualAnalyticsService} from '@shared/analytics';

@Component({
	selector: 'search-preview-slides-dialog',
	templateUrl: './preview-slides-dialog.component.html',
	styleUrls: ['./preview-slides-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: ContextualAnalyticsService,
			useFactory: () =>
				new ContextualAnalyticsService(inject(AnalyticsService), 'Document Preview'),
		},
	],
	standalone: true,
	imports: [
		SharedSkeletonComponent,
		CardListComponent,
		forwardRef(() => SearchItemCardComponent),
		PreviewSlidesSectionComponent,
	],
})
export class PreviewSlidesDialogComponent {
	private showSlidesStore = inject(ShowSlidesStore);
	item = this.showSlidesStore.item;
	selectedSlideId = this.showSlidesStore.selectedSlideId;
	isLoading = this.showSlidesStore.isLoading;

	sections = this.showSlidesStore.sections;
	expanded = this.showSlidesStore.expanded;
	slidesInOrder = this.showSlidesStore.slidesInOrder;
}
