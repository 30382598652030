import {Injectable, inject} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {MailToService} from '../../services/mailTo/mail-to.service';
import {DownloadService} from '../../services/download/download.service';
import {AnalyticsService} from '@shared/analytics';
import {DocumentsClient} from '@shared/api';
import {ToastStore} from '@shared/state';
import {MailToPipe} from '@shared/pipes';

@Injectable({providedIn: 'root'})
export class RequestChangesService {
	private toastStore = inject(ToastStore);
	private documentsClient = inject(DocumentsClient);
	private downloadService = inject(DownloadService);
	private mailToService = inject(MailToService);
	private mailToPipe = inject(MailToPipe);
	private analytics = inject(AnalyticsService);

	async requestDocumentChanges(documentId: string): Promise<void> {
		const marker = Date.now().toString();

		this.toastStore.addToastMessage({
			type: 'loading',
			message: 'Creating email template...',
			marker,
		});

		try {
			const [documentDetails, contacts] = await Promise.all([
				firstValueFrom(this.documentsClient.getDetails(documentId, null, null)),
				firstValueFrom(this.documentsClient.listContacts(documentId)),
			]);
			const url = this.mailToPipe.transform(
				this.mailToService.getMailToRequest(documentDetails, contacts),
			);

			this.toastStore.removeToastByMarker(marker);
			this.downloadService.openPageInNewTab(url);
		} catch (error) {
			this.analytics.trackError('Error while creating email template');

			this.toastStore.removeToastByMarker(marker);
			this.toastStore.addToastMessage({
				type: 'error',
				message: 'Error while creating email template',
			});
		}
	}
}
