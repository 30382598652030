import {Injectable, inject} from '@angular/core';
import {ToastMessageModel} from '@shared/models';
import {ToastStore} from '@shared/state';

@Injectable({providedIn: 'root'})
export class ToastService {
	private toastStore = inject(ToastStore);

	show(toast: ToastMessageModel): void {
		this.toastStore.addToastMessage(toast);
	}
}
