import {Injectable} from '@angular/core';
import {isE2eInstance} from '@shared/utils';

@Injectable({providedIn: 'root'})
export class BrowserDetectorService {
	userAgent = window.navigator.userAgent;
	platform = window.navigator.platform;

	isE2eInstance(): boolean {
		return isE2eInstance();
	}

	getBrowserVersion(): IBrowserVersion {
		return {
			edge: +((/Edg\/(\d+)/.exec(this.userAgent) || [])[1] || 0),
			// CriOS is iPhone Chrome based on Safari
			chrome: +((/(Chrome|CriOS)\/(\d+)/.exec(this.userAgent) || [])[2] || 0),
			firefox: +((/Firefox\/(\d+)/.exec(this.userAgent) || [])[1] || 0),
			safari: +((/Version\/(\d+\.\d+)/.exec(this.userAgent) || [])[1] || 0),
			electron: +((/Electron\/(\d+\.\d+)/.exec(this.userAgent) || [])[1] || 0),
		};
	}

	isEdgOnWindows(): boolean {
		return this.isEdgeChromium() && this.isWindows();
	}

	isChromium() {
		return this.getBrowserVersion().chrome > 0 || this.isEdgeChromium();
	}

	isSupportedBrowser(): boolean {
		const version = this.getBrowserVersion();
		const edge = 100;
		const chrome = 100;
		const safari = 16.0; // we does not work correctly on Safari 15.6.1 (17613.3.9.1.16) MacOS Monterey 12.5.1 (21G83)
		const firefox = 83;
		const electron = 3.1;

		const hasSupport =
			version.edge >= edge ||
			version.chrome >= chrome ||
			version.safari >= safari ||
			version.firefox >= firefox ||
			version.electron >= electron;

		return hasSupport;
	}

	private isEdgeChromium(): boolean {
		return /edg/.test(this.userAgent.toLowerCase());
	}

	private isWindows(): boolean {
		return /win/.test(this.platform.toLowerCase());
	}
}

interface IBrowserVersion {
	edge: number;
	chrome: number;
	firefox: number;
	safari: number;
	electron: number;
}
