import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	EventEmitter,
	HostBinding,
	Input,
	Output,
} from '@angular/core';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {MoreLessPipe} from '../../pipes';
import {TreeViewItemDirective} from '../tree-view-item.directive';
import {TreeNodeContext} from '../types/tree-node.context';
import {TreeNode} from '../types/tree-node.interface';
import {HasIdInterface} from '@shared/models/has-id.interface';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'shared-multi-tree-view',
	templateUrl: './multi-tree-view.component.html',
	styleUrls: ['./multi-tree-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, SvgIconComponent, NgTemplateOutlet, TreeViewItemDirective, MoreLessPipe],
})
export class MultiTreeViewComponent<T extends HasIdInterface> {
	treeNode!: TreeNode<T>;

	@Input({required: true}) set tree(value: TreeNode<T> | null) {
		if (!!value) {
			this.treeNode = value;
			this.isHaveChildren = !!value.children.length;
			this.isRoot = !value.parent;
		}
	}

	@Input() isShowMore = false;
	@Input() maxToShow = 5;
	@Input() isFlat = false; // used to render flat trees / lists

	@HostBinding('style.--tree-view-level')
	@Input()
	level = 0;

	@Input()
	@HostBinding('class.checkbox')
	hasCheckbox = true;

	@ContentChild(TreeViewItemDirective)
	readonly itemView!: TreeViewItemDirective<T>;

	get nextLevel(): number {
		return this.level + 1;
	}

	@Input()
	@HostBinding('class.expanded')
	expanded = true;

	isRoot = true;

	@HostBinding('class.have-children')
	isHaveChildren = false;

	@Input() selectedIds: string[] = [];

	@Output() selectElement = new EventEmitter<TreeNode<T>>();

	trackNode(index: number, item: TreeNode<T>): number | string {
		return item.payload.id ?? index;
	}

	get viewChild(): boolean {
		return this.isHaveChildren && this.expanded;
	}

	selectNode(node: TreeNode<T>): void {
		this.selectElement.emit(node);
	}

	context(node: unknown): TreeNodeContext<T> {
		return new TreeNodeContext(node as T);
	}

	toggleExpand(event: MouseEvent): void {
		this.expanded = !this.expanded;

		event.stopPropagation();
		event.preventDefault();
	}

	isSelected(node: TreeNode<T>): boolean {
		if (!this.selectedIds || this.hasCheckbox) {
			return false;
		}

		return !!this.selectedIds.find((id) => id === node.payload.id);
	}
}
