import {Action} from '@ngrx/store';

import {SelectedFiltersPayload} from './selected-filters-payload';

export const ADD_EXCLUDE_FILTER = '[Search] Add Exclude Filter';

export class AddExcludeFilterAction implements Action {
	readonly type = ADD_EXCLUDE_FILTER;

	constructor(public payload: SelectedFiltersPayload) {}
}
