<div class="details-dialog-container">
	<div class="details-dialog-modal">
		<header class="details-dialog-header">
			<span [innerHTML]="document.titleText"></span>

			<pp-icon
				name="navigation-close-36"
				title="Close (Esc)"
				aria-hidden="true"
				(click)="triggerClose()"
			></pp-icon>
		</header>
		<ul class="details-dialog-tabs">
			@for (tab of tabs; track tab.id) {
				<li [class.selected]="tab.id === selectedTab" (click)="switchTab(tab.id)">
					{{ tab.label }}
				</li>
			}
		</ul>
		<main class="details-dialog-content">
			@if (selectedTab === 'images') {
				<search-document-images
					[document]="document"
					(search)="triggerClose()"
				></search-document-images>
			}
			<div class="details-dialog-content-wrapper">
				@if (selectedTab === 'logos') {
					@if (logos$ | async; as logos) {
						<shared-confidential-logos
							[images]="document.images"
							[showAll]="true"
							[accountLogos]="logos"
						></shared-confidential-logos>
					}
				}
				@if (selectedTab === 'events') {
					<search-tag-history [filePath]="document.filePath"></search-tag-history>
				}
				@if (selectedTab === 'downloads') {
					<search-download-history
						[documentId]="document.documentId"
					></search-download-history>
				}
				@if (selectedTab === 'search') {
					<shared-json-preview [object]="document"></shared-json-preview>
				}
				@if (selectedTab === 'crawling') {
					<search-crawl-state [filePath]="document.filePath"></search-crawl-state>
				}
			</div>
		</main>
	</div>
</div>
