@if (tree.children.length > 0) {
	<shared-multi-tree-view
		[tree]="tree"
		[level]="-1"
		[isShowMore]="true"
		[hasCheckbox]="false"
		(selectElement)="selectOneNode($event)"
		[maxToShow]="20"
		[isFlat]="isFlat"
	>
		<div class="tree-filters-item" *treeViewItem="let node">
			<span
				class="tree-filters-text"
				[class.tree-filters-zero-items]="toNode(node).count === 0"
				>{{ toNode(node).text }}</span
			>
			<span class="tree-filters-count">{{ toNode(node).count | number }}</span>
		</div>
	</shared-multi-tree-view>
}
