import {Action} from '@ngrx/store';

import {FilterModel} from '@search/models';

export const UPDATE_FILE_TYPE_FILTER = '[Search] Update File Type Filter';

export class UpdateFileTypeFilterAction implements Action {
	readonly type = UPDATE_FILE_TYPE_FILTER;

	constructor(public payload: FilterModel[]) {}
}
