import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject} from '@angular/core';
import {FormControl, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';
import {CoverSlideState, DeckBuilderStore} from '../../state';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'deck-builder-cover',
	templateUrl: './deck-builder-cover.component.html',
	styleUrls: ['./deck-builder-cover.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ReactiveFormsModule, SvgIconComponent],
})
export class DeckBuilderCoverSlideComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();

	clientForm!: FormGroup;

	get titleControl(): FormControl {
		return this.clientForm.get('title') as FormControl;
	}

	get subTitleControl(): FormControl {
		return this.clientForm.get('subTitle') as FormControl;
	}

	private readonly deckBuilderStore = inject(DeckBuilderStore);

	ngOnInit(): void {
		this.clientForm = new FormGroup({
			title: new FormControl('', [
				Validators.required.bind(this),
				Validators.minLength(3),
				Validators.maxLength(128),
				Validators.pattern(/^[\w\-. ]+$/), // File Name Pattern
			]),
			subTitle: new FormControl('', [Validators.maxLength(128)]),
		});

		const cover = this.deckBuilderStore.cover();
		this.clientForm.patchValue(cover);

		this.subscriptions.add(
			this.clientForm.valueChanges
				.pipe(
					debounceTime(300),
					tap((value: CoverSlideState) => {
						if (!this.clientForm.controls['title'].valid) {
							this.deckBuilderStore.updateCoverError('Incorrect cover slide "Title"');
						} else if (!this.clientForm.controls['subTitle'].valid) {
							this.deckBuilderStore.updateCoverError(
								'Incorrect cover slide "Subtitle"',
							);
						} else {
							this.deckBuilderStore.updateCover(value);
						}
					}),
				)
				.subscribe(),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
