import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';
import {UserProfileResponse} from './user-profile-response';
import {CONFIG, EnvConfigType} from '@environments/environment';
import {UserSettingsService} from '@shared/services';

import {impersonate} from '@shared/decorators';
import {UserDownloadModel, UserModel, UserProfileModel} from '@shared/models';
import {AnalyticsService} from '@shared/analytics';

@Injectable({providedIn: 'root'})
export class UserProfileService {
	constructor(
		private http: HttpClient,
		private userSettings: UserSettingsService,
		@Inject(CONFIG) private readonly config: EnvConfigType,
		private analytics: AnalyticsService,
	) {}

	@impersonate
	getUserProfile$(email?: string): Observable<UserProfileModel> {
		if (!this.config.serviceless) {
			const fullUrl = `${this.config.serviceUrl}/api/user/get${
				email ? `?email=${encodeURIComponent(email)}` : ''
			}`;

			return this.http
				.get<UserProfileResponse>(fullUrl, {headers: this.prepareHeaders()})
				.pipe(
					map(
						(response) =>
							({
								...response,
								settings: this.userSettings.extract(response.settings),
							}) as UserProfileModel,
					),
					retry(3),
					catchError((error: unknown) => {
						let message = 'Unexpected error';

						if (error instanceof Error) {
							message = error.message;
						} else if (typeof error === 'string') {
							message = error;
						}

						this.analytics.trackException(`cannot get user profile ${message}`);

						return throwError(() => new Error(message));
					}),
				);
		} else {
			return of({
				teams: [
					'#/teams/ui',
					'#/teams/ui/developers',
					'#/teams/ui/api-users',
					'#/teams/trusted-users',
				],
				userDisplayName: 'User Name',
				userEmail: 'userEmail',
				userId: 'userId',
				settings: {
					uiTheme: 'dark',
					disableConfidentialConfirmation: false,
					filters: undefined,
					agreementSigned: true,
				},
				photos: [],
				queryTemplate: '',
			});
		}
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	setUserSetting$(key: string, value: string): Observable<Object> {
		// TODO: will cause error
		const fullUrl = `${this.config.serviceUrl}/api/user/settings/${key}`;
		const body = JSON.stringify(value);

		return this.http.put(fullUrl, body, {headers: this.prepareHeaders()});
	}

	getUserSuggestions$(prefix: string, count?: number): Observable<UserModel[]> {
		const defaultCount = 10;
		const fullUrl = `${this.config.serviceUrl}/api/user/suggest/${
			count || defaultCount
		}?prefix=${encodeURIComponent(prefix)}`;

		return this.http.get<UserModel[]>(fullUrl, {headers: this.prepareHeaders()});
	}

	getUserDownloads$(email: string): Observable<UserDownloadModel[]> {
		const fullUrl = `${
			this.config.serviceUrl
		}/api/user/downloadhistory/100?email=${encodeURIComponent(email)}`;

		return this.http.get<UserDownloadModel[]>(fullUrl, {headers: this.prepareHeaders()});
	}

	getAll$(): Observable<UserModel[]> {
		const fullUrl = `${this.config.serviceUrl}/api/user/get/all`;

		return this.http.get<UserModel[]>(fullUrl, {headers: this.prepareHeaders()});
	}

	private prepareHeaders(): HttpHeaders {
		const headers = new HttpHeaders({
			Accept: 'application/json',
			'Content-Type': 'application/json',
		});

		return headers;
	}
}
