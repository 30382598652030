@if (tree().children.length > 0) {
	<search-filter-header
		[ngClass]="{expanded: isExpanded}"
		[title]="config().name"
		[itemsCount]="itemsCount()"
		[maxCount]="config().maxCount ?? 0"
		[showResetButton]="selectedFilters().length > 0"
		(resetEventEmitter)="reset()"
		(toggleShowEventEmitter)="toggleShowMore()"
		(toggleExpandEventEmitter)="toggleExpand()"
		class="fitler-header"
	></search-filter-header>
	@if (isExpanded) {
		<shared-multi-tree-view
			[tree]="tree()"
			[level]="-1"
			[isShowMore]="isShowMore"
			[hasCheckbox]="isCheckBoxTree()"
			(selectElement)="selectOneNode($event)"
			[selectedIds]="selectedFilters()"
			[maxToShow]="isFiltered() ? tree().children.length : config().showByDefault"
		>
			<div class="tree-filters-item" *treeViewItem="let node">
				@if (isCheckBoxTree()) {
					<pp-checkbox
						[text]="getTextHtml(toNode(node))"
						[title]="toNode(node).text"
						[class.tree-filters-zero-items]="toNode(node).count === 0"
						[checked]="isNodeSelected(toNode(node))"
						(change)="toggleNode(toNode(node))"
						[isPartiallySelected]="isNodePartiallySelected(toNode(node))"
					></pp-checkbox>
				} @else {
					<span
						class="tree-filters-text"
						[class.tree-filters-zero-items]="toNode(node).count === 0"
						[innerHTML]="getTextHtml(toNode(node))"
					></span>
				}
				<span class="tree-filters-count">{{ toNode(node).count | number }}</span>
			</div>
		</shared-multi-tree-view>
	}
}
