import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	OnInit,
	Output,
	computed,
	contentChild,
	input,
	signal,
} from '@angular/core';
import {NgTemplateOutlet} from '@angular/common';
import {PictureFallbackDirective} from './picture-fallback.directive';
import {SvgImageComponent} from '@pp/svg';
import {SizeType} from '@shared/card';

type LoadingStatus = 'init' | 'pending' | 'loaded' | 'error';

@Component({
	selector: 'search-picture',
	templateUrl: './picture.component.html',
	styleUrls: ['./picture.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgTemplateOutlet, SvgImageComponent, PictureFallbackDirective],
})
export class PictureComponent implements OnInit {
	src = input<string>();
	title = input<string>();
	fallbackImage = input.required<string>();
	fallbackSize = input<SizeType>('normal');

	size = computed<number>(() => (this.fallbackSize() === 'normal' ? 60 : 24));
	fallback = contentChild(PictureFallbackDirective);
	@Output() imageLoadingStatus = new EventEmitter<LoadingStatus>();

	status = signal<LoadingStatus>('pending');

	ngOnInit(): void {
		this.imageLoadEvent('init');
	}

	imageLoadEvent(status: LoadingStatus): void {
		this.status.set(status);
	}
}
