import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	computed,
	inject,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {NgStyle, NgTemplateOutlet, AsyncPipe} from '@angular/common';
import {SearchFeatureState} from '../../reducers/search-state';
import {SearchFilterHistogramComponent} from '../search-filter-histogram/search-filter-histogram.component';
import {SearchFiltersSelectedGroupListComponent} from '../search-filters-selected-group-list/search-filters-selected-group-list.component';
import {SearchFiltersListTreeComponent} from '../search-filters-list-tree/search-filters-list-tree.component';
import {SearchFiltersListComponent} from '../search-filters-list/search-filters-list.component';
import {SearchFiltersSelectedListComponent} from '../search-filters-selected-list/search-filters-selected-list.component';
import {SearchFilterSectionComponent} from '../search-filters-widget/search-filters-widget.component';
import {SearchFiltersSearchBarComponent} from '../search-filters-search-bar/search-filters-search-bar.component';
import {SelectionStore} from '@shared/selection';
import {FiltersSettings, FilterState} from '@shared/models';
import {FiltersService, FiltersSettingsService, SearchService} from '@search/services';
import {SearchFilterWidgetTypes} from '@search/models';
import {FilterSectionNamePipe} from '@shared/pipes';
import {UseThemeDirective} from '@shared/directives';

@Component({
	selector: 'search-filters-layout',
	templateUrl: './search-filters-layout.component.html',
	styleUrls: ['./search-filters-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		NgStyle,
		SearchFiltersSearchBarComponent,
		NgTemplateOutlet,
		SearchFilterSectionComponent,
		SearchFiltersSelectedListComponent,
		SearchFiltersListComponent,
		SearchFiltersListTreeComponent,
		SearchFiltersSelectedGroupListComponent,
		SearchFilterHistogramComponent,
		AsyncPipe,
		FilterSectionNamePipe,
		UseThemeDirective,
	],
})
export class SearchFiltersLayoutComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();
	private requestedFilters?: FiltersSettings;

	hiddenSections = new Set<string>();
	filtersSettings$!: Observable<FilterSettingsPair[]>;

	private selectionStore = inject(SelectionStore);
	height = computed(() => {
		if (this.selectionStore.documentIds().length) {
			return 'calc(100vh - 164px)';
		} else {
			return 'calc(100vh - 84px)';
		}
	});

	widgetTypes = SearchFilterWidgetTypes;

	constructor(
		private store$: Store<SearchFeatureState>,
		private searchService: SearchService,
		private filtersService: FiltersService,
		private filtersSettingsService: FiltersSettingsService,
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.store$
				.select((state) => state.search.ui.filterSearchRegex)
				.pipe(
					tap((filterSearch) => {
						if (filterSearch) {
							this.hiddenSections.add('modifiedDate');
							this.hiddenSections.add('size');
						} else {
							this.hiddenSections.delete('modifiedDate');
							this.hiddenSections.delete('size');
						}
					}),
				)
				.subscribe(),
		);

		this.filtersSettings$ = this.filtersSettingsService.filterSettings$.pipe(
			tap((filtersSettings) => this.requestMissingFilters(filtersSettings)),
			map((filtersSettings) => {
				const pairs = Object.entries(filtersSettings).map(
					([key, value]) => ({key, value}) as FilterSettingsPair,
				);
				pairs.sort((a, b) => a.value.order - b.value.order);

				return pairs;
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	toggleSection([source, empty]: [string, boolean]): void {
		if (empty) {
			this.hiddenSections.add(source);
		} else {
			this.hiddenSections.delete(source);
		}
	}

	private requestMissingFilters(filtersSettings: FiltersSettings): void {
		if (this.requestedFilters) {
			const refinements = new Set<string>();

			Object.keys(filtersSettings).forEach((key) => {
				if (
					this.requestedFilters &&
					!this.requestedFilters[key as keyof typeof this.requestedFilters].visible &&
					filtersSettings[key as keyof typeof filtersSettings].visible
				) {
					refinements.add(key);
				}
			});
			this.subscriptions.add(
				this.searchService
					.getRefinements$('any', refinements)
					.pipe(
						tap((data) => {
							const refinementsResults =
								data.d.postquery.PrimaryQueryResult.RefinementResults;
							const actionList = this.filtersService.extractData(refinementsResults);

							actionList.forEach((action) => {
								this.store$.dispatch(action);
							});
						}),
					)
					.subscribe(),
			);
		}

		this.requestedFilters = filtersSettings;
	}
}

interface FilterSettingsPair {
	key: string;
	value: FilterState;
}
