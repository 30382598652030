import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {rangeFiltersService} from '../range-filters/range-filters.service';
import {FiltersSettingsService} from '@search/services';
import {RefinerModel, RefinerOptionsModel} from '@shared/services';

@Injectable({providedIn: 'root'})
export class RefinersBuilderService {
	private rangeDate: string = rangeFiltersService.getDates().join('/');

	private readonly refiners: RefinerModel[] = [
		{
			id: 'languages',
			name: 'languages',
			config: 'filter=5/0/*',
		},
		{
			id: 'fileContentType',
			name: 'FileType',
			config: 'filter=50/0/*',
		},
		{
			id: 'fileType',
			name: 'FileExtension',
			config: 'filter=25/0/*',
		},
		{
			id: 'modifiedDate',
			name: 'Write',
			config: `discretize=manual/${this.rangeDate}`,
		},
		{
			id: 'sourceSystem',
			name: 'usppSourceSystem',
			config: 'filter=20/0/*',
		},
		{
			id: 'size',
			name: 'Size',
			config: `discretize=manual/${rangeFiltersService.getSizes().join('/')}`,
		},
		{
			id: 'documentTags',
			name: 'usppDocumentTags',
			config: 'filter=15/0/*',
		},
		{
			id: 'locationFilterTree',
			name: 'usppLocationFilterTree',
			config: 'filter=25000/1/*',
			lazy: true,
		},
		{
			id: 'contentOwner',
			name: 'usppContentOwner',
			config: 'filter=2000/0/*',
			lazy: true,
		},
		{
			id: 'preparedFor',
			name: 'usppPreparedFor',
			config: 'filter=2000/0/*',
			lazy: true,
		},
		{
			id: 'about',
			name: 'usppAbout',
			config: 'filter=2000/0/*',
			lazy: true,
		},
		{
			id: 'displayAuthor',
			name: 'DisplayAuthor',
			config: 'filter=10000/10/*',
		},
		{
			id: 'tags',
			name: 'Tags',
			config: 'filter=1000/3/*',
		},
		{
			id: 'accessLevel',
			name: 'usppAccessLevel',
			config: 'filter=20/0/*',
		},
		{
			id: 'masterLayout',
			name: 'usppSlideMasterLayout',
			config: 'filter=15000/1/*',
			lazy: true,
		},
	];

	constructor(private filtersSettingsService: FiltersSettingsService) {}

	getRefiners(
		{type = 'any', custom = undefined, exclude = []}: RefinerOptionsModel,
		requestRefinements: Set<string>,
	): RefinerModel[] {
		return (
			custom ??
			this.refiners.filter((refiner) => {
				const requiredType = {
					// eslint-disable-next-line id-blacklist
					any: true,
					lazy: !!refiner.lazy,
					regular: !refiner.lazy,
				}[type];

				return (
					!exclude.includes(refiner.id) &&
					requiredType &&
					requestRefinements.has(refiner.id)
				);
			})
		);
	}

	makeRequest(refiners: RefinerModel[]): string {
		return refiners.map((refiner) => `${refiner.name}(${refiner.config})`).join(',');
	}

	hasRefineLazy(id: string): boolean {
		return this.refiners.some(({id: refinerId, lazy = false}) => refinerId === id && lazy);
	}

	getRefinementsSettings$(): Observable<Set<string>> {
		return this.filtersSettingsService.filterSettings$.pipe(
			map(
				(filtersSettings) =>
					new Set(
						Object.keys(filtersSettings).filter(
							(filter) =>
								filtersSettings[filter as keyof typeof filtersSettings].visible,
						),
					),
			),
			first(),
		);
	}
}
