import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewEncapsulation,
} from '@angular/core';
import {CardBodyComponent} from '../card-body/card-body.component';
import {ResizeObserverWrapper} from '@shared/decorators';
import {DirectionType} from '@shared/card/types/direction.type';
import {SizeType} from '@shared/card/types/size.type';

@Component({
	selector: 'shared-card, a[card]',
	exportAs: 'cardRef',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CardBodyComponent],
})
export class CardComponent implements OnInit, OnDestroy {
	#direction: DirectionType = 'horizontal';
	#fallbackDirection: DirectionType = 'horizontal';
	#size: SizeType = 'normal';
	#fallbackSize: SizeType = 'normal';
	resizeObserver$!: ResizeObserverWrapper;

	@Input()
	set direction(direction: DirectionType) {
		if (this.#direction !== direction) {
			this.switchDirection(direction);
		}
	}
	get direction(): DirectionType {
		return this.#direction;
	}

	@Input()
	set size(size: SizeType) {
		if (this.#size !== size) {
			this.switchSize(size);
		}
	}
	get size(): SizeType {
		return this.#size;
	}

	@HostBinding('class.card-small') isSmall = false;

	@HostBinding('class.card-medium') isMedium = false;

	@HostBinding('class.card-normal') isNormal = true;

	@HostBinding('class.card-horizontal') isHorizontal = true;

	@HostBinding('class.card-vertical')
	@HostBinding('class.divider')
	isVertical = false;

	@HostBinding('class.card')
	readonly isCard = true;

	constructor(
		private eRef: ElementRef,
		private renderer: Renderer2,
	) {}

	ngOnInit(): void {
		this.#fallbackDirection = this.#direction;
		this.#fallbackSize = this.#size;
		if (this.isVertical) {
			this.setSizeVerticalCard();
		}
	}

	ngOnDestroy(): void {
		if (this.resizeObserver$) {
			this.resizeObserver$.disconnect();
		}
	}

	switchDirection(direction: DirectionType): void {
		this.#direction = direction;
		this.isHorizontal = direction === 'horizontal';
		this.isVertical = !this.isHorizontal;

		this.recheckView();
	}

	switchSize(size: SizeType): void {
		this.#size = size;

		this.isNormal = size === 'normal';
		this.isMedium = size === 'medium';
		this.isSmall = size === 'small';

		this.recheckView();
	}

	restoreFallbackDirection(): void {
		this.direction = this.#fallbackDirection;
	}

	restoreFallbackSize(): void {
		this.size = this.#fallbackSize;
	}

	recheckView(): void {
		if (this.isVertical && !this.isNormal) {
			this.switchSize('normal');
		}
	}

	private setSizeVerticalCard(): void {
		this.resizeObserver$ = new ResizeObserverWrapper(() => {
			const verticalMaxHeight = (this.eRef.nativeElement.offsetWidth / 16) * 9;
			this.renderer.setStyle(
				this.eRef.nativeElement,
				'--picture-max-height',
				`${verticalMaxHeight}px`,
			);
		});

		this.resizeObserver$.observe(this.eRef.nativeElement);
	}
}
