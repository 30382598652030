import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {formatRange} from '@shared/utils';

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'filterName',
	standalone: true,
})
export class FilterValuePipe implements PipeTransform {
	transform(filterValue: string): string {
		return this.formatFilterValue(filterValue, 'unknown');
	}

	formatFilterValue(filterValue: string, filterName: string): string {
		const parts = filterValue.split('|');
		const lastPart = parts[parts.length - 1];

		if (this.isGuid(lastPart) && parts.length > 1) {
			return parts[parts.length - 2];
		} else if (filterValue.startsWith('[') && filterValue.endsWith(']')) {
			const rangeValues = filterValue.replace(/^\[|\]$/g, '').split(' TO ');

			return formatRange(rangeValues[0], rangeValues[1], filterName);
		} else {
			return lastPart;
		}
	}

	private guidPattern = /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/i;

	isGuid(name: string): boolean {
		return this.guidPattern.test(name);
	}
}
