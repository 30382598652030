import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {UseThemeDirective} from '@shared/directives';

@Component({
	selector: 'home-footer',
	standalone: true,
	imports: [UseThemeDirective],
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
	currentYear = new Date().getFullYear();
}
