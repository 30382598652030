import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CardActionsService} from '../services/card-actions.service';
import {SearchItemActionType} from '../types/search-item-action-type.model';
import {SearchItemActionInfo} from '../types/search-item-action-info.model';
import {DocumentItemActionService} from '../services/document-action.service';
import {SvgIconComponent} from '@pp/svg';
import {DocumentIndexItem} from '@shared/api';

@Component({
	selector: 'shared-card-menu-actions',
	templateUrl: './card-menu-actions.component.html',
	styleUrls: ['./card-menu-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CardActionsService],
	standalone: true,
	imports: [SvgIconComponent],
})
export class CardMenuActionsComponent implements OnInit {
	@Input({required: true}) item!: DocumentIndexItem;
	@Input({required: true}) actionsToExclude!: SearchItemActionType[];

	menuActionsInfo!: SearchItemActionInfo[];

	constructor(
		private readonly resultParser: DocumentItemActionService,
		private cardActionsService: CardActionsService,
	) {}

	ngOnInit() {
		this.menuActionsInfo = this.resultParser.getMenuActionsInfo(
			this.item,
			this.actionsToExclude,
		);
	}

	onAction(actionType: SearchItemActionType) {
		this.cardActionsService.onAction(actionType, this.item);
	}
}
