import {Directive, HostBinding} from '@angular/core';

@Directive({
	selector: 'shared-chain[dot], dot',
	standalone: true,
})
export class DotDirective {
	@HostBinding('class.with-dot')
	readonly isWithDot = true;
}
