export const selectProperties = [
	// Basic Properties
	'Path',
	'FileName',
	'Url',
	'Size',
	'Write',
	'FileType',
	'Title',

	// Highlighting
	'HitHighlightedProperties',
	'HitHighlightedSummary',
	'FileExtension',

	// Duplicates
	'DocId',
	'fcocount',
	'DocumentSignature',

	// Custom properties
	'usppAutoTitle',
	'usppDocumentTags',
	'usppContentOwner',
	'usppSourceSystem',
	'usppLocationFilterTree',
	'usppImages',
	'usppOgImage',
	'usppSlidesCount',
	'usppImportSourceUrl',
	'usppAccessLevel',
	'usppSourceDocument',

	// Action
	'CopySourceOWSTEXT',
	'ServerRedirectedURL',

	// Can be useful
	'usppThumbnailUrl',
	'contentclass',
	'SPSiteURL',
];
