<div class="header-theme-toggle" (click)="toggleTheme()">
	<pp-icon
		class="header-theme-toggle-icon"
		[size]="20"
		[hint]="hintThemeToggle"
		[hintPosition]="'BOTTOM'"
		name="{{ theme() === 'light' ? 'theme-dark-mode-24' : 'theme-light-mode-24' }}"
	/>

	<ng-template #hintThemeToggle>
		<span>Switch To {{ theme() === 'light' ? 'Dark' : 'Light' }} Theme</span>
	</ng-template>
</div>
