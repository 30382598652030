import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'shared-navigation-group',
	templateUrl: './navigation-group.component.html',
	styleUrls: ['./navigation-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SvgIconComponent],
})
export class NavigationGroupComponent {
	@Input({required: true}) groupName!: string;

	isSelectedLinkExist = false;

	@Input({required: true}) set hasSelectedLink(value: boolean) {
		this.isSelectedLinkExist = value;
		this.isExpanded = value || (this.isExpanded && !value);
	}

	isExpanded = false;

	toggleGroup(): void {
		this.isExpanded = !this.isExpanded;
	}
}
