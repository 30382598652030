import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';

import * as actions from '../actions';

@Injectable({providedIn: 'root'})
export class SelectedFiltersEffects {
	private readonly timeout = 500;

	submitQuery$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(
				actions.ADD_EXCLUDE_FILTER,
				actions.ADD_INCLUDE_FILTER,
				actions.REPLACE_INCLUDE_FILTER,
				actions.REMOVE_FILTER,
			),
			debounceTime(this.timeout),
			switchMap(() => of(new actions.SubmitQueryAction())),
		),
	);

	resetFilters$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(actions.RESET_SELECTED_FILTER, actions.RESET_SELECTED_FILTERS),
			switchMap(() => of(new actions.SubmitQueryAction())),
		),
	);

	constructor(private actions$: Actions) {}
}
