<div class="owners-section dp-card">
	<header>
		{{ title() }}
		@if (canManage()) {
			<button
				btn
				btnType="link"
				withIconBefore="action-settings-18"
				title="Manage Сontacts"
				(click)="showEditDialog()"
			>
				Manage
			</button>
		}
	</header>
	@if (itemsCount() === 0) {
		<span class="no-results">Nothing here yet</span>
	} @else {
		<ul class="owners-list">
			@for (user of items() | moreLess: !isShowMore() : 3; track user.aadId) {
				<li>
					<search-preview-user-info [userInfo]="user"></search-preview-user-info>
				</li>
			}
		</ul>

		@if (itemsCount() > 3) {
			<button (click)="toggleShowMore()" class="dp-card-header-more-link show-more-button">
				Show {{ isShowMore() ? 'all' : 'less' }}
				{{ isShowMore() ? '(' + itemsCount() + ')' : '' }}

				<pp-icon
					[name]="
						isShowMore() ? 'navigation-chevron-down-18' : 'navigation-chevron-up-18'
					"
					[size]="18"
				></pp-icon>
			</button>
		}
	}
</div>
