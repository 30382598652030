<div class="search-context-widget" #contextWidget>
	<div class="results-count-container">
		<pp-checkbox-selection
			class="builder-checkbox"
			description="all visible items"
			[items]="documentIndexItems()"
		></pp-checkbox-selection>
		<span class="results-count">{{ searchResultTotalCount() | number }} results</span>
	</div>

	<ul class="filters-list">
		@for (filter of selectedFilters(); track filter.fieldName) {
			<li class="filter">
				@if (filter.selectedFilters.length > 1) {
					<span [class]="['filter-count', 'filter-operator', filter.filterOperator]">
						{{ filter.selectedFilters.length }}
						<div class="selected-collapsed-list">
							<div class="selected-collapsed-list-content">
								<div class="selected-collapsed-list-content-header">
									<h4>
										Filter Type
										<div
											[class]="[
												'filter-operator-type',
												'filter-operator',
												filter.filterOperator
											]"
										>
											{{ filter.filterOperator | uppercase }}
										</div>
									</h4>
									<span class="selected-collapsed-list-content-header-caption">{{
										filter.filterCaption
									}}</span>
								</div>
								<ul>
									@for (
										selectedFilter of filter.selectedFilters;
										track selectedFilter.displayName
									) {
										<li
											class="filter"
											[ngClass]="{
												except: selectedFilter.isExcluded
											}"
										>
											<span class="filter-label">{{
												selectedFilter.displayName
											}}</span>
											<pp-icon
												(click)="
													removeFilter(filter.fieldName, selectedFilter)
												"
												name="navigation-close-18"
											></pp-icon>
										</li>
									}
								</ul>
							</div>
						</div>
					</span>
					<span class="filter-label">{{ filter.facetName }}</span>
				} @else {
					<span class="filter-label">{{ filter.selectedFilters[0].displayName }}</span>
				}

				<pp-icon
					name="navigation-close-18"
					class="filters-list-clear-icon"
					(click)="resetFilterGroup(filter.fieldName)"
				></pp-icon>
			</li>
		}
		@if (selectedFilters().length) {
			<button
				(click)="resetAll()"
				btn
				btnType="link"
				withIconBefore="navigation-refresh-18"
				class="reset-filters-button"
				ppCdkUseTheme="dark"
			>
				Reset filters
			</button>
		}
	</ul>

	@if (currentPreset()) {
		<search-settings-buttons
			class="settings-buttons"
			[currentPreset]="currentPreset()!"
		></search-settings-buttons>
	}
</div>
