import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	ViewEncapsulation,
	EventEmitter,
	HostListener,
	Input,
	Output,
	inject,
} from '@angular/core';
import {Observable} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {UiStore} from '@shared/state';
import {SvgIconComponent} from '@pp/svg';
import {DropdownComponent} from '@shared/components/dropdown/dropdown.component';

@Component({
	selector: 'search-sort-by',
	templateUrl: './sort-by.component.html',
	styleUrls: ['./sort-by.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [DropdownComponent, SvgIconComponent, AsyncPipe],
})
export class SortByComponent {
	@Input({required: true}) list!: {name: string}[];
	@Input({required: true}) value$!: Observable<string>;
	@Output() changeOrder = new EventEmitter<{name: string}>();

	expanded = false;
	isMobile = inject(UiStore).window.isMobileMode;

	constructor(private element: ElementRef) {}

	open(): void {
		this.expanded = !this.expanded;
	}

	@HostListener('window:click', ['$event'])
	close(event: Event): void {
		if (!this.element.nativeElement.contains(event.target)) {
			this.expanded = false;
		}
	}

	select(item: {name: string}): void {
		this.expanded = false;
		this.changeOrder.emit(item);
	}
}
