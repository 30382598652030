<header class="range-header">
	<ng-template [ngTemplateOutlet]="range"></ng-template>

	<div class="label-count">{{ count | number }}</div>
</header>

<ul #histogram class="bars-container">
	@for (bar of bars; track identityBar(i, bar); let i = $index) {
		<li
			class="bar-wrap"
			[attr.style]="barStyle(bar)"
			[attr.data-bar-index]="i"
			[class.bar-selected]="isBarSelected(i)"
			(click)="onChange($event)"
		>
			<div class="bar" [hint]="range" hintPosition="TOP"></div>
		</li>
	}
</ul>

<div class="slider">
	<input
		class="range"
		id="left"
		type="range"
		[hint]="range"
		hintPosition="BOTTOM"
		[min]="min"
		[value]="left"
		[max]="max"
	/>
	<input
		class="range"
		id="right"
		type="range"
		[hint]="range"
		hintPosition="BOTTOM"
		[min]="min"
		[value]="right"
		[max]="max"
	/>
</div>

<div class="labels">
	<div class="label-left">{{ minLabel }}</div>
	<div class="label-right">{{ maxLabel }}</div>
</div>

<ng-template #range>{{ formattedRangeValue }}</ng-template>
