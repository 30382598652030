import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
	OnDestroy,
	inject,
} from '@angular/core';

import {Observable, Subscription} from 'rxjs';
import {first, map, shareReplay, tap} from 'rxjs/operators';
import {ConfidentialType} from '../confidential/confidential.enum';
import {ConfidentialComponent} from '../confidential/confidential.component';
import {CrmAccountModel, SearchResultModel} from '@shared/models';

import {CrmAccountsService, DownloadService} from '@shared/services';
import {UserStore} from '@shared/state';
import {ModalService} from '@shared/modals';

@Component({
	selector: 'shared-download',
	templateUrl: './download.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
})
export class DownloadComponent implements OnDestroy {
	private readonly subscriptions = new Subscription();
	private userStore = inject(UserStore);
	private downloadService = inject(DownloadService);

	document?: SearchResultModel;
	confidentialType?: ConfidentialType;
	logos$?: Observable<CrmAccountModel[]>;

	@Input({required: true}) set data(value: SearchResultModel) {
		this.document = undefined;
		this.confidentialType = undefined;

		if (value) {
			if (value.confidentialDocument) {
				this.document = value;
				this.confidentialType = ConfidentialType.DOCUMENT;

				this.openConfidentialModal();
			} else {
				const disableWarning = this.userStore.settings.agreementSigned();
				if (!disableWarning) {
					this.document = value;
					this.confidentialType = ConfidentialType.CONTENT;

					this.logos$ = this.accounts
						.getAccountsByImage$(this.document.images ?? [])
						.pipe(
							map((accounts) =>
								accounts.sort((a, b) => a.name.localeCompare(b.name)),
							),
							shareReplay({bufferSize: 1, refCount: true}),
						);

					this.subscriptions.add(
						this.logos$
							.pipe(
								first(),
								tap((logos) => {
									if (!logos.length) {
										this.download(this.document);
									} else {
										this.openConfidentialModal(logos);
									}
								}),
							)
							.subscribe(),
					);
				} else {
					this.downloadService.downloadFile(value.downloadUri);
					this.done.emit(value);
				}
			}
		}
	}

	@Output() cancel = new EventEmitter<SearchResultModel>();
	@Output() done = new EventEmitter<SearchResultModel>();

	constructor(
		private accounts: CrmAccountsService,
		private readonly modalService: ModalService,
	) {}

	ngOnDestroy() {
		this.subscriptions?.unsubscribe();
	}

	private openConfidentialModal(logos?: CrmAccountModel[]): void {
		const confidentialInstance = this.modalService.openModal(ConfidentialComponent, {
			type: 'PREVIEW-MENU',
			showCloseButton: false,
			data: {
				document: this.document,
				confidentialType: this.confidentialType,
				accountLogos: logos ?? [],
			},
		});

		this.subscriptions.add(
			confidentialInstance.confirm
				.pipe(tap((document) => this.download(document)))
				.subscribe(),
		);
	}

	private download(document?: SearchResultModel): void {
		if (document) {
			this.downloadService.downloadFile(document.downloadUri);
			this.done.emit(document);
		} else {
			this.cancel.emit(document);
		}
	}
}
