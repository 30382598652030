<p class="search-subheader">
	@if (usagePermission) {
		<shared-usage-permissions
			[value]="usagePermission"
			[size]="12"
			class="dp-dot-chain-after-link"
		></shared-usage-permissions>
	}
	@if (result.accessLevel) {
		<shared-with class="dp-dot-chain-after" [result]="result"></shared-with>
	}
	@if (result.isAutoGenerated) {
		<span
			*ppAnchor="'search-subheader-type'; with: typeForAnchor"
			class="dp-dot-chain-link dp-dot-chain-link-light"
		>
			<span class="search-subheader-type">{{ documentType }} from&nbsp;</span>
			<span
				class="search-subheader-link"
				title="Download source: {{ fileName }}"
				(click)="triggerQueueDownloadSource(result)"
				>{{ fileName }}</span
			>
		</span>
	}
</p>
