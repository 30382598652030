@if (selectedFilters$ | async; as selectedFilters) {
	@if (selectedFilters.length > 0) {
		<ul class="search-filters-selected-list">
			@for (filter of selectedFilters; track filter.token) {
				<search-applied-filter
					[appliedFilterModel]="filter"
					(removeEmitter)="remove(filter)"
				></search-applied-filter>
			}
		</ul>
	}
}
