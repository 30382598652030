<div class="panel-container admin-panel">
	<header class="panel-header">Admin panel</header>
	@if (!loading) {
		<button (click)="downloadAll()">Download All</button>
		<button (click)="getResultsFile()">Export to Excel</button>
		<button (click)="getStoreJSON()">Export Store</button>
		<button (click)="getTimeRangeFile()">Export Modified Dates</button>
		@if (gridViewLink$ | async; as gridViewLink) {
			<button
				[cdkCopyToClipboard]="gridViewLink"
				(cdkCopyToClipboardCopied)="handleCopiedLink($event)"
			>
				Copy grid view link
			</button>
		}
		<button type="button" (click)="getDownloadsHistory()">Export Downloads History</button>
		@if ((queryModificationVisible$ | async) === false) {
			<button type="button" (click)="showQueryModification()">Show Query Modification</button>
		}
	}
	@if (loading) {
		<pp-spinner [style]="'black'"></pp-spinner>
	}
</div>
