import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

import * as actions from '../actions';
import {AnalyticsService} from '@shared/analytics';

@Injectable({providedIn: 'root'})
export class AnalyticsEffects {
	trackIncludeFilter$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(actions.ADD_INCLUDE_FILTER, actions.REPLACE_INCLUDE_FILTER),
			tap((action: actions.AddIncludeFilterAction | actions.ReplaceIncludeFilterAction) => {
				const {filter} = action.payload;

				if (filter) {
					this.analytics.trackFilterAction(
						'filter',
						action.payload.type,
						filter.value || filter.name,
					);
				}
			}),
			switchMap(() => of(new actions.EmptyAction())),
		),
	);

	trackExcludeFilter$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(actions.ADD_EXCLUDE_FILTER),
			tap((action: actions.AddExcludeFilterAction) => {
				const {filter} = action.payload;

				this.analytics.trackFilterAction(
					'exclude',
					action.payload.type,
					filter?.value || filter?.name,
				);
			}),
			switchMap(() => of(new actions.EmptyAction())),
		),
	);

	trackResetSelectedFilters$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(actions.RESET_SELECTED_FILTERS),
			tap(() => {
				this.analytics.trackFilterAction('reset', 'All');
			}),
			switchMap(() => of(new actions.EmptyAction())),
		),
	);

	trackResetSelectedFilter$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(actions.RESET_SELECTED_FILTER),
			tap((action: actions.ResetSelectedFilterAction) => {
				if (action.initiator === 'search-context') {
					this.analytics.trackSearchResultsAction('reset', action.payload);
				} else {
					this.analytics.trackFilterAction('reset', action.payload);
				}
			}),
			switchMap(() => of(new actions.EmptyAction())),
		),
	);

	trackChangeView$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(actions.CHANGE_VIEW),
			tap((action: actions.ChangeViewAction) => {
				this.analytics.trackSearchResultsAction(
					'changeViewMode',
					action.payload === 'grid' ? 'table' : 'default',
				);
			}),
			switchMap(() => of(new actions.EmptyAction())),
		),
	);

	trackDuplicates$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(actions.CHANGE_GROUP_ID),
			tap((action: actions.ChangeGroupIdAction) => {
				if (action.payload) {
					this.analytics.trackSearchResultsAction('duplicates', action.payload);
				} else {
					this.analytics.trackSearchResultsAction('duplicatesExit');
				}
			}),
			switchMap(() => of(new actions.EmptyAction())),
		),
	);

	constructor(
		private actions$: Actions,
		private analytics: AnalyticsService,
	) {}
}
