import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {ModalWindowHeaderComponent} from './modal-window-header/modal-window-header.component';

@Component({
	selector: 'shared-modal-window',
	templateUrl: './modal-window.component.html',
	styleUrls: ['./modal-window.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [ModalWindowHeaderComponent],
})
export class ModalWindowComponent {
	@Input() windowTitle!: string;

	@ViewChild('positionBlock', {static: true}) positionBlock!: ElementRef;

	@Input()
	set onCenter(value: boolean) {
		if (value) {
			this.positionBlock.nativeElement.classList.add('center');
		}
	}
}
