import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	OnDestroy,
	ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, first, map, tap} from 'rxjs/operators';

import {AsyncPipe} from '@angular/common';
import {StateSerializerService} from '../../services';
import {
	QueryState,
	SearchFeatureState,
	SearchState,
	SelectedFilters,
	SelectedFiltersState,
} from '../../reducers/search-state';
import {
	InitQueryAction,
	InitSelectedFiltersAction,
	ResetUIAction,
	SubmitQueryAction,
} from '../../actions';
import {QueryEffectsComponent} from '../query-effects/query-effects.component';
import {SearchResultsComponent} from '../search-results/search-results.component';
import {SearchFiltersLayoutComponent} from '../search-filters-layout/search-filters-layout.component';
import {ActionPanelComponent} from '../action-panel/action-panel.component';
import {TitleService} from '@shared/services';
import {getFirst, htmlDecode, parseJson} from '@shared/utils';
import {AnalyticsService} from '@shared/analytics';
import {PageProgressBarComponent} from '@shared/components/page-progress-bar/page-progress-bar.component';
import {UseThemeDirective} from '@shared/directives';
import {SharedSkeletonComponent} from '@pp/skeleton';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		PageProgressBarComponent,
		ActionPanelComponent,
		SearchFiltersLayoutComponent,
		SearchResultsComponent,
		QueryEffectsComponent,
		AsyncPipe,
		UseThemeDirective,
		SharedSkeletonComponent,
	],
})
export class SearchComponent implements AfterViewInit, OnDestroy {
	private subscriptions: Subscription[] = [];

	firstLoad$ = new BehaviorSubject(true);
	resultsLoading$: Observable<boolean> = this.store$.select(
		(state) => !!state.search.query.isLoading,
	);

	@Input() page = 'Search';

	constructor(
		private route: ActivatedRoute,
		private store$: Store<SearchFeatureState>,
		private stateSerializer: StateSerializerService,
		private analytics: AnalyticsService,
		private titleService: TitleService,
	) {}

	ngAfterViewInit(): void {
		const subscription = this.route.queryParamMap
			.pipe(
				map((params) => params.get('o') ?? ''),
				distinctUntilChanged(),
				tap((query) => {
					const parsedQuery = parseJson(htmlDecode(query), null);
					this.titleService.setSearchTitle(parsedQuery?.query?.submittedQuery);

					this.analytics.trackSiteSearch(parsedQuery?.query?.submittedQuery);
					this.analytics.trackPageNavigation('/search');
				}),
				map(this.convertLegacyQuery.bind(this)),
				map(this.deserializeState.bind(this)),
				tap(this.restoreState.bind(this)),
			)
			.subscribe();

		this.addSubscription(subscription);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
	}

	private convertLegacyQuery(query: string): string {
		let result = htmlDecode(query);
		const parsedQuery = parseJson(result, null);

		if (parsedQuery?.selectedFilters) {
			const convert = (filters: SelectedFilters, key: string): void => {
				if (Array.isArray(filters[key as keyof typeof filters][0])) {
					filters[key as keyof typeof filters] = filters[key as keyof typeof filters].map(
						(item) => item[0],
					);
				}
			};

			if (parsedQuery.selectedFilters.include) {
				Object.keys(parsedQuery.selectedFilters.include).forEach(
					convert.bind(null, parsedQuery.selectedFilters.include),
				);
			}

			if (parsedQuery.selectedFilters.exclude) {
				Object.keys(parsedQuery.selectedFilters.exclude).forEach(
					convert.bind(null, parsedQuery.selectedFilters.exclude),
				);
			}

			result = JSON.stringify(parsedQuery);
		}

		return result;
	}

	private extractDataFromStore(): IExtractDataFromStore {
		const query: QueryState = getFirst(this.store$.select((store) => store.search.query));
		const selectedFilters: SelectedFiltersState = getFirst(
			this.store$.select((store) => store.search.selectedFilters),
		);

		return {
			query,
			selectedFilters,
		};
	}

	private deserializeState(currentQuery: string): SearchState {
		// TODO: Basically it's not a good idea to serialize state and compare it with query... But I've no better solution for now.
		const currentState = this.stateSerializer.serialize(this.extractDataFromStore());
		let result;

		if (currentQuery !== currentState) {
			result = parseJson(htmlDecode(currentQuery), null);
		}

		return result;
	}

	private restoreState(state: SearchState): void {
		if (state) {
			this.store$.dispatch(new InitQueryAction(state.query));
			this.store$.dispatch(new InitSelectedFiltersAction(state.selectedFilters));
		}

		// eslint-disable-next-line rxjs/no-subject-value
		if (this.firstLoad$.getValue() || state) {
			this.store$.dispatch(new ResetUIAction());
			this.store$.dispatch(new SubmitQueryAction());
			const subscription = this.store$
				.select((store) => store.search.query.isLoading)
				.pipe(
					first((value) => !value),
					tap(() => {
						this.firstLoad$.next(false);
					}),
				)
				.subscribe();

			this.addSubscription(subscription);
		}
	}

	private addSubscription(subscription: Subscription): void {
		this.subscriptions.push(subscription);
	}
}

interface IExtractDataFromStore {
	query: QueryState;
	selectedFilters: SelectedFiltersState;
}
