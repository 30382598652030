import {
	ChangeDetectionStrategy,
	Component,
	Renderer2,
	OnInit,
	ChangeDetectorRef,
	inject,
	computed,
	Signal,
} from '@angular/core';
import {RouterLink} from '@angular/router';
import {AsyncPipe} from '@angular/common';
import {NavigationService} from '@shared/services';
import {NavigationTreeNode} from '@shared/models';
import {SvgIconComponent} from '@pp/svg';
import {UiStore} from '@shared/state';
import {AnalyticsService} from '@shared/analytics';
import {PopoverService} from '@shared/popover';
import {AccessInfoPopoverComponent} from '@shared/access';

@Component({
	selector: 'dashboard-sitemap-widget',
	standalone: true,
	imports: [SvgIconComponent, RouterLink, AsyncPipe],
	templateUrl: './sitemap-widget.component.html',
	styleUrls: ['./sitemap-widget.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [PopoverService],
})
export class SitemapWidgetComponent implements OnInit {
	scopes: Signal<Scope[]> = computed(
		() =>
			[
				{
					title: 'Pitch',
					icon: 'megaphone-icon',
					items: this.navigationService.getSalesPitchesLinks(),
					isOpened: false,
				},
				{
					title: 'Propose',
					icon: 'suitcase-icon',
					items: this.navigationService.getProposalLinks(),
					isOpened: false,
				},
				{
					title: 'Learn',
					icon: 'bulb-icon',
					items: this.navigationService.getInsightsLinks(),
					isOpened: false,
				},
				{
					title: 'Contribute',
					icon: 'contribute-icon',
					items: this.navigationService.getContributeLinks,
					isOpened: false,
				},
			] as Scope[],
	);

	isMobile = inject(UiStore).window.isMobileMode;

	constructor(
		private readonly navigationService: NavigationService,
		private renderer: Renderer2,
		private detector: ChangeDetectorRef,
		private readonly popOverService: PopoverService,
		private readonly analytics: AnalyticsService,
	) {}

	ngOnInit(): void {
		this.renderer.listen('document', 'click', (event) => {
			if (
				!event.target.closest('.info-widget-section-links-list') &&
				!event.target.closest('.info-widget-section')
			) {
				this.scopes().forEach((scope) => (scope.isOpened = false));
				this.detector.detectChanges();
			}
		});
	}

	toggleListLinks(event: Event, scopeName: string) {
		event.stopPropagation();
		if (!(event.target as HTMLElement).closest('.info-widget-section-links-list')) {
			this.scopes().forEach(
				(scope) => (scope.isOpened = scope.title === scopeName ? !scope.isOpened : false),
			);
		}
	}

	openAccessInfoPopover(event: Event): void {
		this.popOverService.openPopOver(
			AccessInfoPopoverComponent,
			{},
			event.target as HTMLElement,
			'bottom',
			true,
		);
	}

	trackAnalytics(linkTitle: string): void {
		this.analytics.trackDashboardAction('sitemapNavigation', linkTitle);
	}
}

interface Scope {
	title: string;
	icon: string;
	items: ScopeLink[];
	isOpened: boolean;
}

interface ScopeLink extends NavigationTreeNode {
	externalLink?: string;
}
