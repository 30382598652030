import {ChangeDetectionStrategy, Component, computed, inject, signal} from '@angular/core';
import {EditLinksModalComponent} from './edit-links-modal/edit-links-modal.component';
import {UnsafeLinkConfirmationComponent} from './unsafe-link-confirmation/unsafe-link-confirmation.component';
import {PreviewStore} from '@preview/document/preview.store';
import {ButtonComponent} from '@pp/button';
import {SvgIconComponent} from '@pp/svg';
import {MoreLessPipe} from '@shared/pipes';
import {DocLink} from '@shared/api';
import {ModalService} from '@shared/modals';
import {AnalyticsService} from '@shared/analytics';
import {PopoverService} from '@shared/popover';

@Component({
	selector: 'search-preview-links',
	templateUrl: './preview-links.component.html',
	styleUrls: ['./preview-links.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [PopoverService],
	standalone: true,
	imports: [ButtonComponent, SvgIconComponent, MoreLessPipe],
})
export class PreviewLinksWidgetComponent {
	private previewStore = inject(PreviewStore);
	isShowMore = signal(true);
	linksCount = computed(() => this.previewStore.links().length);
	links = computed(() => {
		const links = this.previewStore.links();

		return links.map((link) => ({
			...link,
			hostname: this.getHostname(link.url),
		}));
	});

	private icons: {[key: string]: string} = {
		default: 'action_language_18',
		'kb.epam.com': 'content_confluence_18',
		'videoportal.epam.com': 'content_video_18',
		'epam.com': 'content_epam.com_18',
		'showcase.epam.com': 'content_showcase_18',
		'info.epam.com': 'content_infoportal_18',
		'web.microsoftstream.com': 'content_video_18',
		'epam.sharepoint.com': 'content_sharepoint_18',
		'sharepoint.epam.com': 'content_sharepoint_18',
		'epam-my.sharepoint.com': 'content_sharepoint_18',
		'yammer.com': 'content_yammer_18',
	};

	constructor(
		private readonly analyticsService: AnalyticsService,
		private readonly popOverService: PopoverService,
		private readonly modalService: ModalService,
	) {}

	getHostIcon(link: DocLinkVm): string {
		return this.icons[link.hostname] || this.icons['default'];
	}

	trackClick(url: string): void {
		const hostname = this.getHostname(url);
		this.analyticsService.trackDocumentPreviewAction('open:link', hostname);
	}

	getHostname(url: string): string {
		try {
			const x = new URL(url);

			return x.hostname.replace('www.', '');
		} catch {
			return 'no URL';
		}
	}

	toggleShowMore(): void {
		this.isShowMore.update((x) => !x);
	}

	openUnsafeLinkPopOver(event: Event, link: DocLink): void {
		this.popOverService.openPopOver(
			UnsafeLinkConfirmationComponent,
			{
				url: link.url,
			},
			event.target as HTMLElement,
			'left',
			true,
		);
	}

	showEditDialog(): void {
		this.modalService.openModal(EditLinksModalComponent, {
			type: 'EDIT-LINKS-MODAL',
			showCloseButton: false,
		});
	}
}

interface DocLinkVm extends DocLink {
	hostname: string;
}
