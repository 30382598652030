import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	Input,
	OnInit,
	ViewEncapsulation,
	signal,
} from '@angular/core';
import {NgStyle, NgClass} from '@angular/common';
import {PreviewComponent} from '../preview/preview.component';
import {SearchResultModel} from '@shared/models';
import {UtilsService} from '@shared/services';

@Component({
	selector: 'shared-modal-video',
	templateUrl: './modal-video.component.html',
	styleUrls: ['./modal-video.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, NgClass, PreviewComponent],
})
export class ModalVideoComponent implements OnInit {
	private resizeHandle?: number;
	private readonly aspectRation = 16 / 9;
	private readonly contentProportion = 0.8; // 80% of widow size

	class = signal<string | null>(null);
	styles = signal<{width: string} | null>(null);

	fakeDoc: Partial<SearchResultModel> = {};

	@Input() set url(url: string) {
		this.fakeDoc = {
			videoUrl: this.utilsService.getEmbeddedVideoUrl(url) ?? undefined,
		};
	}

	constructor(private utilsService: UtilsService) {}

	@HostListener('window:resize')
	resize(): void {
		window.clearTimeout(this.resizeHandle);

		this.resizeHandle = window.setTimeout(() => this.resizeCallback(), 100);
	}

	ngOnInit(): void {
		this.resize();
	}

	resizeCallback(): void {
		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;

		if (windowHeight * this.aspectRation > windowWidth) {
			this.class.set('');
			this.styles.set(null);
		} else {
			const width = Math.ceil(windowHeight * this.contentProportion * this.aspectRation);

			this.styles.set({
				width: `${width}px`,
			});
		}
	}
}
