@if (!supportedBrowser()) {
	<div class="outdated-browser-container">
		<div class="outdated-browser-message">
			It looks like you are using an outdated browser. Please, update your browser (for
			Chrome/Firefox/Edge) or OS (for Safari) to latest version. We appreciate your
			understanding!
			@if (teams() | hasTeam: 'admin') {
				<p>{{ userAgent }}</p>
			}
		</div>

		<div class="outdated-browser-links">
			<a
				class="chrome"
				title="Get Chrome"
				href="https://www.google.com/chrome/browser/desktop/index.html"
			></a>
			<a
				class="firefox"
				title="Get Firefox"
				href="https://www.mozilla.org/en-US/firefox/new/"
			></a>
			<a class="edge" title="Get Edge" href="https://www.microsoft.com/en-us/edge"></a>
		</div>
	</div>
}
