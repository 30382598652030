import {Injectable} from '@angular/core';

import {FilterService} from '../filter-factory/filter-service';
import {StockTreeFilterService} from '../stock-tree-filter/stock-tree-filter.service';
import {TreeFilterModel} from '@search/models';
import {Refinement} from '@shared/services';

@Injectable({providedIn: 'root'})
export class LocationTreeFilterService implements FilterService {
	private confluence = /^Confluence/i;
	private offeringWrongCase = 'Offerings and expertise';
	private knowledgeBase = '';
	private offeringRightCase = 'Offerings and Expertise';

	constructor(private stockTreeFilter: StockTreeFilterService) {}

	convert(data: Refinement[]): TreeFilterModel {
		data.forEach((refinement) => {
			refinement.RefinementName = refinement.RefinementName.replace(
				this.confluence,
				this.knowledgeBase,
			).replace(this.offeringWrongCase, this.offeringRightCase);

			refinement.RefinementValue = refinement.RefinementValue.replace(
				this.confluence,
				this.knowledgeBase,
			).replace(this.offeringWrongCase, this.offeringRightCase);
		});

		return this.stockTreeFilter.convert(data);
	}

	prepareName(token: string): string {
		return this.stockTreeFilter.prepareName(token);
	}
}
