<div class="user-settings-container">
	<header class="user-settings-header" (click)="toggle()">
		Settings
		<pp-icon
			[name]="expanded ? 'navigation-chevron-down-18' : 'navigation-chevron-right-18'"
			[size]="18"
		></pp-icon>
	</header>
	@if (expanded) {
		<ul class="user-settings-list">
			<li>
				<label>
					<pp-checkbox
						[checked]="disabled()"
						(change)="toggleWarningsDisplay($event.target)"
						text="Disable download confirmation dialog"
					></pp-checkbox>
				</label>
			</li>
			<li>
				<ng-content></ng-content>
			</li>
		</ul>
	}
</div>
