<!-- if you change the template you need update the tree list template -->
@if (items$ | async; as items) {
	@if (items.length) {
		<ul class="search-filters-list dp-list">
			@for (item of items | moreLess: showMore : 5; track item.name) {
				<li class="dp-list-item dot">
					<div
						class="search-filters-list-item-content dp-list-item-content"
						*ppAnchor="anchorName; with: item.name; when: source === 'documentTags'"
					>
						<div class="search-filters-list-item-text dp-list-item-text">
							<a
								class="search-filters-list-item-include"
								title="{{ item.name }} ({{ item.count }})"
								[class.selected]="item.selected"
								(click)="includeFilter(item)"
							>
								<span
									class="search-filters-list-item-label"
									[innerHTML]="item.richName || item.name"
								></span>
								<span class="search-filters-list-item-count dp-list-item-count"
									>({{ item.count | number }})</span
								>
							</a>
						</div>

						<button
							class="search-filters-list-item-exclude dp-button-null"
							title="Exclude {{ item.name }}"
							[class.selected]="item.selected"
							(click)="excludeFilter(item)"
						>
							<pp-icon
								name="navigation_deselect_outline_18"
								aria-hidden="true"
							></pp-icon>
						</button>
					</div>
				</li>
			}
			@if (items.length > 5) {
				<li>
					<button
						class="search-filters-list-toggle dp-button-null"
						(click)="toggleShowMore()"
					>
						{{ showMore ? 'Show less' : 'Show more' }}
					</button>
				</li>
			}
		</ul>
	}
}
