import {Action} from '@ngrx/store';

import {SelectedFiltersPayload} from './selected-filters-payload';

export const REMOVE_FILTER = '[Search] Remove Filter';

export class RemoveFilterAction implements Action {
	readonly type = REMOVE_FILTER;

	constructor(public payload: SelectedFiltersPayload) {}
}
