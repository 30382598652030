import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	Renderer2,
	OnDestroy,
	inject,
} from '@angular/core';
import {Observable, combineLatest, tap, map, filter, OperatorFunction} from 'rxjs';
import {Store} from '@ngrx/store';
import {toObservable} from '@angular/core/rxjs-interop';
import {AsyncPipe} from '@angular/common';
import {SortByComponent} from '../../sort-by/sort-by.component';
import {SearchContextComponent} from '../../search-context/search-context.component';
import {ResultsCountComponent} from '../../results-count/results-count.component';
import {
	SearchFeatureState,
	SelectedFilters,
	SelectedFiltersType,
} from '@search/reducers/search-state';
import {ChangeSortByAction, ChangeViewAction} from '@search/actions';
import {AppliedFilterModel, SortByModel} from '@search/models';
import {FilterFactoryService, SearchService, SortByService} from '@search/services';
import {ModalService} from '@shared/modals';
import {FiltersSettings} from '@shared/models';
import {ResizeObserverWrapper} from '@shared/decorators';
import {UserStore} from '@shared/state';
import {ButtonComponent} from '@pp/button';
import {SearchFiltersLayoutComponent, AppliedFilterGroupViewModel} from '@search/components';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'search-filter-info',
	templateUrl: './filter-info.component.html',
	styleUrls: ['./filter-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ButtonComponent,
		ResultsCountComponent,
		SearchContextComponent,
		SortByComponent,
		AsyncPipe,
	],
})
export class FilterInfoComponent implements OnInit, OnDestroy {
	sortByValue$!: Observable<string>;
	resizeObserver$!: ResizeObserverWrapper;

	filterInfoVm$!: Observable<FilterInfoViewModel>;

	sortByList!: SortByModel[];

	private filterInfoElement?: ElementRef;

	constructor(
		private store$: Store<SearchFeatureState>,
		private sortBy: SortByService,
		private analytics: AnalyticsService,
		private readonly element: ElementRef,
		private renderer: Renderer2,
		private searchService: SearchService,
		private filterFactory: FilterFactoryService,
		private readonly modalService: ModalService,
	) {}

	@ViewChild('filterInfoContainer')
	set filterInfoContainer(filterInfoElement: ElementRef) {
		this.filterInfoElement = filterInfoElement;
		this.observeFilterInfo();
	}

	private userStore = inject(UserStore);
	private filters$ = toObservable(this.userStore.settings.filters);
	ngOnInit() {
		const appliedFilters$ = combineLatest([
			this.getFilters$('include'),
			this.getFilters$('exclude'),
			this.filters$.pipe(
				filter((filterSettings) => !!filterSettings) as OperatorFunction<
					FiltersSettings | undefined,
					FiltersSettings
				>,
			),
		]).pipe(
			map(([includes, excludes, filterSettings]) => {
				const filterGroups = this.mergeFilters([...includes, ...excludes]);

				return filterGroups.sort(
					(a, b) =>
						filterSettings[a.filterName as keyof typeof filterSettings].order -
						filterSettings[b.filterName as keyof typeof filterSettings].order,
				);
			}),
		);

		this.filterInfoVm$ = combineLatest([
			this.store$.select((state) => state.search.ui.view),
			appliedFilters$,
		]).pipe(
			map(([view, filters]) => ({
				view,
				appliedFiltersGroups: filters,
			})),
			tap(() => this.observeFilterInfo()),
		);

		this.sortByList = this.sortBy.getSortByList();

		this.sortByValue$ = this.store$
			.select((state) => state.search.query.sortBy)
			.pipe(
				map((value) => {
					const matchedItem = this.sortByList.find(
						(order) =>
							order.value === value.value && order.direction === value.direction,
					);

					return matchedItem ? matchedItem.name : '';
				}),
			);

		this.resizeObserver$ = new ResizeObserverWrapper(([entry]) => {
			const countContainerWidth =
				this.element.nativeElement.querySelector('.results-count').clientWidth;
			const settingsButtonsWidth =
				this.element.nativeElement.querySelector('.settings-buttons').clientWidth;
			const filtersLisContainer = this.element.nativeElement.querySelector('.filters-list');

			const availableWidth =
				entry.target.clientWidth - countContainerWidth - settingsButtonsWidth - 60;

			if (!!filtersLisContainer && availableWidth < filtersLisContainer.clientWidth) {
				this.renderer.setStyle(
					entry.target,
					'grid-template-areas',
					"'results-count settings-buttons' 'filters-list filters-list'",
				);
			} else {
				this.renderer.setStyle(
					entry.target,
					'grid-template-areas',
					"'results-count filters-list settings-buttons'",
				);
			}
		});
	}

	ngOnDestroy() {
		this.resizeObserver$.disconnect();
	}

	private mergeFilters(
		appliedFilterGroups: AppliedFilterGroupViewModel[],
	): AppliedFilterGroupViewModel[] {
		const filterNames = new Set(appliedFilterGroups.map((item) => item.filterName));

		return Array.from(filterNames).map((filterName) => {
			const filters = appliedFilterGroups
				.filter((filterGroup) => filterGroup.filterName === filterName)
				.flatMap((filterGroup) => filterGroup.filters);

			const groupedFilters = this.groupFiltersByName(filters);
			groupedFilters.sort((a, b) => a.name.localeCompare(b.name));

			return {
				filterName,
				filters: groupedFilters,
			};
		});
	}

	/*
	Collapses filters with the same name into one.

	Example:
	[
		{name: 'Word', token: '1234', include: false},
		{name: 'Word', token: '4321', include: false},
		{name: 'Word', token: '2342', include: false}
	]
	=>
	[{name: 'Word', token: null, include: false}]
*/
	private groupFiltersByName(appliedFilters: AppliedFilterModel[]): AppliedFilterModel[] {
		const uniqueNames = new Set(appliedFilters.map((item) => item.name));

		return Array.from(uniqueNames).map((name) => {
			const items = appliedFilters.filter((item) => item.name === name);

			if (items.length === 1) {
				return items[0];
			}

			return {
				name,
				include: items[0].include, // we assume that all filters with the same name has identical inclusion
				token: '',
			};
		});
	}

	private getFilters$(type: SelectedFiltersType): Observable<AppliedFilterGroupViewModel[]> {
		return this.store$
			.select((state) => state.search.selectedFilters[type])
			.pipe(
				map((selectedFilters: SelectedFilters) =>
					Object.entries(selectedFilters)
						.filter(([, tokens]) => tokens.length)
						.map(([filterName, tokens]) =>
							this.createFiltersGroup(filterName, type, tokens),
						),
				),
			);
	}

	private createFiltersGroup(
		filterName: string,
		type: SelectedFiltersType,
		tokens: string[],
	): AppliedFilterGroupViewModel {
		const include = type === 'include';
		const nameFromLabelMap = this.searchService.histogramLabels.get(filterName);
		const filters = tokens.map((token) => {
			const name = nameFromLabelMap
				? nameFromLabelMap
				: this.filterFactory.get(filterName).service.prepareName(token);

			return {include, token: nameFromLabelMap ? nameFromLabelMap : token, name};
		});

		return {filterName, filters};
	}

	changeView(view: 'grid' | 'list'): void {
		this.store$.dispatch(new ChangeViewAction(view === 'grid' ? 'list' : 'grid'));
	}

	private observeFilterInfo(): void {
		if (this.filterInfoElement) {
			this.resizeObserver$.disconnect();
			this.resizeObserver$.observe(this.filterInfoElement.nativeElement);
		}
	}

	changeSortBy(model: SortByModel): void {
		this.analytics.trackSearchResultsAction('sortBy', model.name.replace(' (', '('));
		this.store$.dispatch(
			new ChangeSortByAction({
				value: model.value,
				direction: model.direction,
			}),
		);
	}

	toggleFiltersPanel(): void {
		this.modalService.openModal(SearchFiltersLayoutComponent, {
			showCloseButton: true,
			type: 'FILTERS_SEARCH_MODAL',
			theme: 'light',
		});
	}
}

interface FilterInfoViewModel {
	appliedFiltersGroups: AppliedFilterGroupViewModel[];
	view: 'grid' | 'list';
}
