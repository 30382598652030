import {Inject, Pipe, PipeTransform} from '@angular/core';

import {CONFIG, EnvConfigType} from '@environments/environment';

@Pipe({
	name: 'image',
	standalone: true,
})
export class ImagePipe implements PipeTransform {
	constructor(@Inject(CONFIG) private readonly config: EnvConfigType) {}
	transform(hash: string): string {
		return `${this.config.serviceUrl}/api/images/${hash}`;
	}
}
