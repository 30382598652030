import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	Self,
	OnDestroy,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {tap} from 'rxjs/operators';
import {BreakpointsType, ContainerStrategy, LayoutService} from '@shared/layout';
import {AnalyticsService} from '@shared/analytics';
import {DocDetailsItem, DocumentItemParserService} from '@shared/api';

const BreakpointsDefault: BreakpointsType = ['(min-width: 635px)'];

@Component({
	selector: 'search-preview-content',
	templateUrl: './preview-content.component.html',
	styleUrls: ['./preview-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LayoutService, ContainerStrategy],
	standalone: true,
})
export class PreviewContentComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();

	private intervalHandle!: number;
	private resizeHandle!: number;
	private strategy!: ContainerStrategy;
	private previewUrl = '';

	previewSource!: SafeUrl | null;
	// TODO: `isPortraitMode` and `isBig` probably does not make sense when `preview-document` control the behavior
	isPortraitMode = false;
	isBig = false;
	isExtraHeaderExist = false;

	isHighlightsExist = false;

	@Input({required: true}) set item(value: DocDetailsItem) {
		this.isHighlightsExist = !!value.document.description?.highlightedValue;
		this.isPortraitMode = !this.parserService.isPowerPoint(value.document);
		this.isExtraHeaderExist = value.isPersonalizedPreviewUrl;

		if (!!value.documentPreviewUrl) {
			this.previewUrl = value.documentPreviewUrl;
			this.previewSource = this.sanitizer.bypassSecurityTrustResourceUrl(
				value.documentPreviewUrl,
			);
		}

		setTimeout(() => {
			this.resize();
		});
	}

	@Input() query = '';
	@Input() inline = false;
	@Input() calculateOnResize = true;
	@Output() closePreview = new EventEmitter();

	constructor(
		@Self() private readonly layout: LayoutService,
		private sanitizer: DomSanitizer,
		private analytics: AnalyticsService,
		private element: ElementRef,
		private detector: ChangeDetectorRef,
		private readonly parserService: DocumentItemParserService,
	) {}

	ngOnInit(): void {
		this.strategy = this.layout.useStrategy('container');
		this.strategy.element = this.element.nativeElement;
		this.strategy.breakpoints = BreakpointsDefault;

		this.subscriptions.add(
			this.strategy.observer$
				.pipe(
					tap(({matches}) => {
						this.isBig = matches && !!this.previewUrl.match('embed.aspx');
						this.detector.detectChanges();
					}),
				)
				.subscribe(),
		);

		this.analytics.trackPreviewAction('open');
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.strategy?.destroy();

		window.clearInterval(this.intervalHandle);
		this.analytics.trackPreviewAction('close');
	}

	@HostListener('window:resize')
	resize(): void {
		if (this.calculateOnResize) {
			window.clearTimeout(this.resizeHandle);

			this.resizeHandle = window.setTimeout(this.resizeCallback.bind(this), 100);
		}
	}

	private resizeCallback(): void {
		const sideMargin = this.inline ? 0 : 30;
		const container = this.element.nativeElement.querySelector(
			'.preview-container',
		) as HTMLElement;
		const frame = this.element.nativeElement.querySelector('.preview-wrapper') as HTMLElement;
		const {offsetHeight, offsetWidth} = container;

		if (frame && !this.isPortraitMode) {
			const ratio = 16 / 9;
			const bottomMenu = 23;
			const margin = this.inline ? 0 : 100;

			this.resizePreview(
				offsetWidth - sideMargin,
				offsetHeight - margin,
				ratio,
				bottomMenu,
				frame,
			);
		}
	}

	private resizePreview(
		width: number,
		height: number,
		ratio: number,
		offset: number,
		frame: HTMLElement,
	): void {
		if (width / (height - offset) > ratio) {
			frame.style.height = `${height}px`;
			frame.style.width = `${(height - offset) * ratio}px`;
		} else {
			frame.style.width = `${width}px`;
			frame.style.height = `${width / ratio + offset}px`;
		}
	}
}
