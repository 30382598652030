import {Injectable} from '@angular/core';
import {NewsCardModel} from '@dashboard/models';
import {DeckBuilderModalComponent} from '@deck-builder/components';
import {ModalService} from '@shared/modals';
import {ResponseFragment} from '@search2/models';

@Injectable({
	providedIn: 'root',
})
export class NewsCardsService {
	private productNewsCards: NewsCardModel[] = [
		{
			title: 'PowerPoint Add-In',
			description:
				'Grab Presales assets in a few clicks, straight from your PowerPoint presentation.',
			actions: [{label: 'Read more', type: 'primary', link: ''}],
		},
		{
			title: 'Presales Offerings',
			description:
				'Escape the repetition cycle and power up your proposal preparation with proven, templated solutions',
			actions: [
				{
					label: 'Read more',
					type: 'secondary',
					link: '',
				},
				{label: 'Explore', type: 'primary', routerLink: '/p/offerings'},
			],
		},
		{
			title: 'How to Write Case Studies',
			description:
				'Want to create a Case Study for the Presales portal but don’t know where to start?',
			actions: [
				{
					label: 'Read more',
					type: 'secondary',
					link: '',
				},
				{
					label: 'Learn how',
					type: 'primary',
					link: '',
				},
			],
		},
		{
			title: 'Deck Builder',
			description: 'Merge Presales-verified materials into one deck without copypasting.',
			actions: [{label: 'Open', emitEvent: 'openDeckBuilder', type: 'primary'}],
		},
		{
			title: 'Presales Toolbox',
			description: 'Get the job done!',
			actions: [
				{
					label: 'Read more',
					type: 'secondary',
					link: '',
				},
				{label: 'Explore', type: 'primary', routerLink: '/p/toolbox'},
			],
		},
		{
			title: 'Case Studies',
			description: 'Find the right Case Study and people who can talk about it.',
			actions: [
				{
					label: 'Read more',
					type: 'secondary',
					link: '',
				},
				{label: 'Explore', type: 'primary', routerLink: '/p/cases'},
			],
		},
		{
			title: 'Presales Stories',
			description: 'Discover capabilities.',
			actions: [
				{
					label: 'Read more',
					type: 'secondary',
					link: '',
				},
				{label: 'Explore', type: 'primary', routerLink: '/p/stories'},
			],
		},
		{
			title: 'Presales Community',
			description: "Subscribe to the latest Presales' news and insights.",
			actions: [
				{
					label: 'Join',
					type: 'primary',
					link: '',
				},
			],
		},
		{
			title: 'Presales Portal',
			description: 'Where am I and who is this resource for?',
			actions: [
				{
					label: 'Read more',
					type: 'primary',
					link: '',
				},
			],
		},
	];

	productNews: ResponseFragment<NewsCardModel> = {
		items: this.productNewsCards,
		totalCount: this.productNewsCards.length,
	};

	constructor(private modalService: ModalService) {}

	setCardEventListener(eventName: string) {
		if (eventName === 'openDeckBuilder') {
			this.modalService.openModal(DeckBuilderModalComponent, {
				type: 'DECK_BUILDER',
				showCloseButton: false,
			});
		}
	}
}
