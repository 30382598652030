import {Pipe, PipeTransform, inject} from '@angular/core';
import {HostnamePipe} from './hostname.pipe';
import {DocumentIndexItem} from '@shared/api';

@Pipe({
	name: 'fileExtension',
	standalone: true,
})
export class FileExtensionPipe implements PipeTransform {
	private readonly classNames: Dictionary<string> = {
		pptx: 'ppt',
		ppt: 'ppt',
		pot: 'ppt',
		potx: 'ppt',
		ppsx: 'ppt',

		docx: 'word',
		doc: 'word',
		docm: 'word',
		rtf: 'word',
		dotx: 'word',
		wordml: 'word',
		dot: 'word',

		xltx: 'excel',
		xlt: 'excel',
		xlsx: 'excel',
		xls: 'excel',
		xlsb: 'excel',
		xlsm: 'excel',
		excelml: 'excel',

		pdf: 'pdf',

		vsd: 'visio',
		vdx: 'visio',
		vsdx: 'visio',

		html: 'web',
		htm: 'web',
		mhtml: 'web',
		mhtm: 'web',
		aspx: 'web',
		www: 'web',
		page: 'confluence',

		mpp: 'msp',

		eap: 'eap',

		msg: 'mail',
		eml: 'mail',
	};

	private readonly hostnamePipe = inject(HostnamePipe);

	transform(item: DocumentIndexItem): string {
		const fileExtension = item.file ? item.file.extension : 'url';

		if (fileExtension === 'url') {
			const hostname = this.hostnamePipe.transform(item.previewPageUrl);

			if (hostname === 'kb.epam.com') {
				return 'confluence';
			}
			if (hostname === 'epam.sharepoint.com') {
				return 'sharepoint';
			}
		}

		return this.classNames[fileExtension] || 'other';
	}
}
