import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Renderer2,
	Output,
	EventEmitter,
	inject,
	effect,
	computed,
	untracked,
} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {RouterLinkActive, RouterLink} from '@angular/router';
import {NavigationService} from '../../services';
import {UiStore} from '../../state';
import {NavigationGroupsContainerComponent} from './navigation-groups-container/navigation-groups-container.component';
import {SvgIconComponent} from '@pp/svg';
import {UseThemeDirective} from '@shared/directives';
import {AnalyticsService} from '@shared/analytics';
import {SelectionStore} from '@shared/selection';

@Component({
	selector: 'shared-navigation-panel',
	templateUrl: './navigation-panel.component.html',
	styleUrls: ['./navigation-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		RouterLinkActive,
		RouterLink,
		SvgIconComponent,
		NavigationGroupsContainerComponent,
		AsyncPipe,
		UseThemeDirective,
	],
})
export class NavigationPanelComponent {
	@Input({required: true}) containerSelector!: string;
	@Output() navPanelButtonStateAction = new EventEmitter<boolean>();

	navigationTree = this.navigationService.navigationTree;

	shouldListenHover = false;
	isPanelOpened = this.navigationService.getNavPanelState();

	private selectionStore = inject(SelectionStore);
	height = computed(() => {
		if (this.selectionStore.documentIds().length) {
			return 'calc(100vh - 144px)';
		} else {
			return 'calc(100vh - 60px)';
		}
	});

	constructor(
		private readonly navigationService: NavigationService,
		private readonly renderer: Renderer2,
		private readonly analytics: AnalyticsService,
	) {
		effect(() => {
			const windowState = this.uiStore.window();
			const isPanelOpened = windowState.width > 1540 || windowState.isMobileMode;
			untracked(() => this.navigationService.setNavPanelState(isPanelOpened));

			const isBurgerVisible = windowState.width > 1540;
			this.shouldListenHover = !windowState.isMobileMode && !isBurgerVisible;

			this.navPanelButtonStateAction.emit(isBurgerVisible);
			this.updateContainerGrid(isPanelOpened);
		});

		effect(() => {
			const currentState = this.isPanelOpened();
			this.updateContainerGrid(currentState);
		});
	}

	private readonly uiStore = inject(UiStore);

	togglePanel(newState: boolean): void {
		this.navigationService.setNavPanelState(newState);

		this.updateContainerGrid(newState);
	}

	openPanel(): void {
		this.navigationService.setNavPanelState(true);
	}

	closePanel(): void {
		this.navigationService.setNavPanelState(false);
	}

	trackAnalytics(name: string): void {
		this.analytics.trackNavigationAction('navigate:scope', name);
	}

	private updateContainerGrid(isPanelOpened: boolean): void {
		const containerElement = document.querySelector(this.containerSelector);

		if (!containerElement) {
			return;
		}

		if (isPanelOpened) {
			this.renderer.setStyle(
				containerElement,
				'grid-template-columns',
				'238px calc(100% - 238px)',
			);
		} else {
			this.renderer.setStyle(
				containerElement,
				'grid-template-columns',
				'60px calc(100% - 60px)',
			);
		}
	}
}
