@if (showCloseContainer) {
	<pp-icon
		[class.right-margin]="isInsidePowerPoint()"
		name="navigation-close-36"
		(click)="close()"
	></pp-icon>
}
<article [class.close-container]="showCloseContainer" [ppCdkUseTheme]="theme()">
	<ng-template cdkPortalOutlet></ng-template>
</article>

@if (isScrollTopVisible) {
	<div (click)="scrollToTop()" class="scroll-to-top">
		<pp-icon [size]="24" name="navigation-chevron-double-left-18"></pp-icon>
	</div>
}

<search-selection-panel-gap></search-selection-panel-gap>
<div class="bottom-margin">
	<div></div>
</div>
