import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

import {UseThemeDirective} from '@shared/directives';
import {AnalyticsService} from '@shared/analytics';
import {focusOnInput} from '@shared/utils';
import {SvgIconComponent} from '@pp/svg';
import {QueryStringPipe} from '@shared/pipes';
import {AutosuggestComponent} from '@search/components/autosuggest/autosuggest.component';

@Component({
	selector: 'dashboard-search-navigation',
	standalone: true,
	imports: [SvgIconComponent, UseThemeDirective, AutosuggestComponent],
	templateUrl: './search-navigation.component.html',
	styleUrls: ['./search-navigation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [QueryStringPipe],
})
export class SearchNavigationComponent implements OnInit {
	readonly query$ = new BehaviorSubject<string>('');
	autosuggestVisible = false;
	query = '';

	constructor(
		private router: Router,
		private element: ElementRef,
		private analytics: AnalyticsService,
		private queryString: QueryStringPipe,
	) {}

	ngOnInit(): void {
		focusOnInput(this.element.nativeElement.querySelector('input'));
		this.autosuggestVisible = true;
	}

	submit(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			// eslint-disable-next-line rxjs/no-subject-value
			this.search(this.query$.getValue());
		}
	}

	search(query: string): void {
		this.analytics.trackDashboardAction('search');
		this.router.navigateByUrl(
			`/search?o=${encodeURIComponent(this.queryString.transform(query))}`,
		);
		// this.router.navigate(['/p/default']);
		// this.router.navigateByUrl(`/p/search?o=${query}}`);
		// this.searchSuggestionComponent.searchQueryValueChanged(query);
	}

	changeQuery(target: EventTarget | null): void {
		const query = (target as HTMLInputElement).value;
		this.autosuggestVisible = true;
		this.query$.next(query);
	}

	updateCurrentQuery(query: string): void {
		this.query = query;
		this.element.nativeElement.querySelector('input').focus();
	}

	appendQuery(query: string): void {
		this.query = this.query.trim() + query;
		this.element.nativeElement.querySelector('input').focus();
	}

	focus(): void {
		this.autosuggestVisible = true;
	}

	blur(target: EventTarget | null): void {
		const query = (target as HTMLInputElement).value;
		this.query$.next(query);
	}

	@HostListener('window:click', ['$event'])
	hideDialogs(event: Event): void {
		if (!this.element.nativeElement.contains(event.target)) {
			this.autosuggestVisible = false;
		}
	}
}
