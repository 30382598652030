@if (type() === 'switcher') {
	<label class="switcher">
		<input
			class="checkbox-input"
			type="checkbox"
			[checked]="checked()"
			(change)="updateValue($event)"
			[attr.disabled]="isDisabled()"
		/>
		<div class="checkbox-container"></div>
	</label>
} @else {
	<label class="dp-checkbox-container">
		<input
			class="checkbox-input"
			type="checkbox"
			[checked]="isChecked()"
			[class.checked]="isChecked()"
			(change)="updateValue($event)"
			[attr.disabled]="isDisabled()"
		/>
		<div class="dp-checkbox-background">
			<pp-icon [name]="iconName()" [size]="12" />
		</div>

		@if (text()) {
			<span class="checkbox-text" [title]="title() || text()" [innerHTML]="text()"></span>
		}
		@if (labelTemplate()) {
			<ng-template [ngTemplateOutlet]="labelTemplate() ?? null"></ng-template>
		}
	</label>
}
