<figure>
	@if (status() !== 'error' && src()) {
		<img
			class="image"
			loading="lazy"
			[src]="src()"
			[attr.imageLoaded]="status() === 'loaded'"
			[alt]="title()"
			(load)="imageLoadEvent('loaded')"
			(error)="imageLoadEvent('error')"
		/>
	}
	@if (status() !== 'loaded') {
		<div class="fallback">
			<ng-template
				[ngTemplateOutlet]="fallback()?.templateRef || view_fallback"
			></ng-template>
		</div>
	}
	@if (status() === 'pending') {
		<div class="skeleton-loader"></div>
	}
	<div class="container">
		<ng-content></ng-content>
	</div>
</figure>

<ng-template #view_fallback>
	<pp-image [name]="fallbackImage()" [width]="size()" [height]="size()"></pp-image>
</ng-template>
