<div class="featured-container">
	@if (items().length) {
		<search-cards-swiper [items]="items()">
			<ng-container header>
				<header class="featured-title">
					<h3>{{ title() }}</h3>
				</header>
			</ng-container>

			<ng-template #itemTemplate let-item>
				<search-item-card
					[item]="item.document"
					size="normal"
					direction="vertical"
					[lightMode]="true"
					[context]="items()"
				></search-item-card>
			</ng-template>
		</search-cards-swiper>
	}
</div>
