@if (value$ | async; as value) {
	<shared-dropdown
		type="secondary"
		openStrategy="click"
		[iconOnly]="isMobile()"
		[icon]="isMobile() ? 'sorting-icon' : ''"
	>
		<div dropdownHeader>Sort by {{ value }}</div>

		<ng-template #dropdownContent>
			@for (item of list; track item.name) {
				<a (click)="select(item)" class="sort-by-list-item">
					{{ item.name }}
					@if (item.name === value) {
						<pp-icon name="notification-done-18" />
					}
				</a>
			}
		</ng-template>
	</shared-dropdown>
}
