@if (documentIds().length > 0) {
	<div class="selection-panel" ppCdkUseTheme="dark">
		<div class="selection-panel-content">
			<div class="selection-panel-summary">
				<span class="selection-panel-summary-count">
					{{ documentIds().length }}
				</span>
				<span>Selected</span>
			</div>
			<div class="selection-panel-actions">
				<button
					class="dp-button dp-big selection-panel-actions-build"
					type="button"
					hint="Merge presentations into one deck"
					hintPosition="TOP"
					(click)="openDeckBuilder()"
					[disabled]="isLocked()"
				>
					@if (isLocked()) {
						<pp-spinner type="circle" [context]="'bookmark'"></pp-spinner>
					}
					Build Deck
				</button>
				<button
					class="dp-button secondary dp-big selection-panel-actions-unselect"
					type="button"
					hint="Unselect all presentations"
					hintPosition="TOP"
					(click)="unselectAll()"
					[disabled]="isLocked()"
				>
					Unselect All
				</button>
			</div>
		</div>
	</div>
}
