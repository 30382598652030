import {ActionReducerMap} from '@ngrx/store';

import * as filters from './filters/filters.reducer';
import * as query from './query/query.reducer';
import * as results from './results/results.reducer';
import {SearchState} from './search-state';
import * as selectedFilters from './selected-filters/selected-filters.reducer';
import * as ui from './ui/ui.reducer';
import {loadingFiltersReducer} from '@search/reducers/loading-filters/loading-filters.reducer';

export const reducers: ActionReducerMap<SearchState, never> = {
	filters: filters.reducer,
	query: query.reducer,
	selectedFilters: selectedFilters.reducer,
	ui: ui.reducer,
	results: results.reducer,
	loadingFilters: loadingFiltersReducer,
};
