import {
	ChangeDetectionStrategy,
	Component,
	TemplateRef,
	ViewEncapsulation,
	computed,
	inject,
	input,
} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {CheckboxComponent} from '@pp/checkbox';
import {DocumentIndexItem} from '@shared/api';
import {SelectionStore} from '@shared/selection';

@Component({
	selector: 'pp-checkbox-selection',
	standalone: true,
	imports: [CheckboxComponent, AsyncPipe],
	templateUrl: './checkbox-selection.component.html',
	styleUrls: ['./checkbox-selection.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class CheckboxSelectionComponent {
	items = input.required<DocumentIndexItem[]>();
	description = input.required<string>();
	alwaysVisible = input<boolean>(false);
	labelTemplate = input<TemplateRef<unknown>>();

	private selectionStore = inject(SelectionStore);

	isCheckboxVisible = computed(
		() => this.alwaysVisible() || this.selectionStore.documentIds().length > 0,
	);
	isCheckboxDisabled = computed(() => this.selectionStore.isLocked());

	private documentIds = computed(() => this.items().map((item) => item.id));
	private selectedDocumentIds = computed(() => {
		const documentIds = this.documentIds();

		return this.selectionStore.documentIds().filter((docId) => documentIds.includes(docId));
	});
	isCheckboxSelected = computed(() => this.selectedDocumentIds().length === this.items().length);
	isCheckboxPartiallySelected = computed(
		() => this.selectedDocumentIds().length > 0 && !this.isCheckboxSelected(),
	);

	onCheck(event: Event): void {
		event.preventDefault();
		event.stopPropagation();

		if (this.isCheckboxSelected()) {
			this.selectionStore.unselectItems(this.items(), this.description());
		} else {
			this.selectionStore.selectItems(this.items(), this.description());
		}
	}
}
