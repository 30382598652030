import {BreakpointState} from '@angular/cdk/layout';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ElementRef,
	Input,
	OnDestroy,
	AfterViewInit,
	Self,
	TemplateRef,
	TrackByFunction,
	input,
	effect,
	signal,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {NgTemplateOutlet} from '@angular/common';
import {DirectionType, isDirectionType} from '../../card/types/direction.type';
import {isSizeType, SizeType} from '../../card/types/size.type';
import {CardContextInterface} from '../types/card-context.interface';
import {ViewBehavioursType, ViewBehaviourType} from '../types/view-behaviours.type';
import {LayoutStrategyInterface} from '@shared/layout/interfaces/layout-strategy.interface';
import {LayoutStrategyType} from '@shared/layout/types/layout-strategy.type';
import {BreakpointsType} from '@shared/layout/types/breakpoints.type';
import {LayoutService} from '@shared/layout/layout.service';
import {ViewportStrategy} from '@shared/layout/strategies/viewport.strategy';
import {ContainerStrategy} from '@shared/layout/strategies/container.strategy';

const BreakpointsDefault: BreakpointsType = [
	'(max-width: 335px)',
	'(min-width: 336px) and (max-width: 519px)',
	'(min-width: 519px) and (max-width: 749px)',
	'(min-width: 750px)',
];

const ViewBehaviourDefault: ViewBehavioursType = ['vertical', 'medium', 'normal'];

@Component({
	selector: 'shared-card-list',
	exportAs: 'cardListRef',
	templateUrl: './card-list.component.html',
	styleUrls: ['./card-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LayoutService, ViewportStrategy, ContainerStrategy],
	standalone: true,
	imports: [NgTemplateOutlet],
})
export class CardListComponent<T = unknown> implements AfterViewInit, OnDestroy {
	private readonly subscriptions = new Subscription();

	@Input()
	set layoutStrategy(layoutStrategy: LayoutStrategyType) {
		if (layoutStrategy !== this.layoutStrategyLocal) {
			this.switchLayout(layoutStrategy);
		}
	}

	private layoutStrategyLocal!: LayoutStrategyType;
	private strategy!: LayoutStrategyInterface;
	#fallbackViewBehaviour: ViewBehaviourType = ['horizontal', 'normal'];

	breakpoints = input<BreakpointsType>();
	viewBehaviour = input<ViewBehavioursType>(ViewBehaviourDefault);

	@Input({required: true}) containerRef!: Element;

	@Input() list: T[] = [];

	@Input() hasSeparator = false;

	@ContentChild('cardTemplate', {static: false}) cardTemplateRef!: TemplateRef<
		CardContextInterface<T>
	>;

	viewBehaviourIsHorizontal = true;
	viewBehaviourIsVertical = false;
	viewBehaviourIsSmall = false;
	viewBehaviourIsMedium = false;
	viewBehaviourIsNormal = true;

	direction = signal<DirectionType>('horizontal');
	size = signal<SizeType>('normal');

	constructor(
		private readonly element: ElementRef,
		@Self() private readonly layoutService: LayoutService,
		private readonly changeDetector: ChangeDetectorRef,
	) {
		effect(
			() => {
				const viewBehaviour = this.viewBehaviour();
				this.setFallbackViewBehaviour(viewBehaviour);

				this.strategy.breakpoints = this.breakpoints() ?? BreakpointsDefault;
			},
			{allowSignalWrites: true},
		);
	}

	@Input()
	trackBy: TrackByFunction<T> = (index) => index;

	ngAfterViewInit(): void {
		this.setFallbackViewBehaviour(this.viewBehaviour());

		if (!this.layoutStrategyLocal) {
			this.switchLayout('container');
		}

		this.subscriptions.add(
			this.strategy.observer$
				.pipe(tap((state) => this.handlerViewBehaviour(state)))
				.subscribe(),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.strategy?.destroy();
	}

	context(item: T, index: number): CardContextInterface<T> {
		return {$implicit: item, index};
	}

	setFallbackViewBehaviour(viewBehaviours: ViewBehavioursType): void {
		const lastViewBehaviour = viewBehaviours[viewBehaviours.length - 1];

		if (Array.isArray(lastViewBehaviour)) {
			this.#fallbackViewBehaviour = lastViewBehaviour;

			return;
		}

		this.#fallbackViewBehaviour = [...this.normalizeViewBehaviour(lastViewBehaviour)];
	}

	private handlerViewBehaviour({matches, breakpoints}: BreakpointState): void {
		let viewBehaviour = this.#fallbackViewBehaviour;

		if (matches) {
			const matchedBreakpointIndex = Object.values(breakpoints).findIndex(Boolean);
			const matchedViewBehaviour =
				this.viewBehaviour()[
					Math.max(0, Math.min(matchedBreakpointIndex, this.viewBehaviour().length - 1))
				];

			viewBehaviour = Array.isArray(matchedViewBehaviour)
				? matchedViewBehaviour
				: this.normalizeViewBehaviour(matchedViewBehaviour);
		}

		const [direction, size] = viewBehaviour;

		this.switchDirection(direction);
		this.switchSize(size);

		this.changeDetector.detectChanges();
	}

	private switchLayout(layoutStrategy: LayoutStrategyType): void {
		this.layoutStrategyLocal = layoutStrategy;

		if (this.strategy) {
			this.strategy.destroy();
		}

		this.strategy = this.createStrategy();
		this.strategy.breakpoints = this.breakpoints() ?? BreakpointsDefault;
	}

	private createStrategy(): LayoutStrategyInterface {
		let strategy: LayoutStrategyInterface;

		switch (this.layoutStrategyLocal) {
			case 'container':
				strategy = this.layoutService.useStrategy<ContainerStrategy>('container');
				strategy.element = this.containerRef
					? this.containerRef
					: this.element.nativeElement;
				break;
			case 'viewport':
			default:
				strategy = this.layoutService.useStrategy<ViewportStrategy>('viewport');
				break;
		}

		return strategy;
	}

	private normalizeViewBehaviour(viewBehaviour: DirectionType | SizeType): ViewBehaviourType {
		const direction = (
			isDirectionType(viewBehaviour) ? viewBehaviour : 'horizontal'
		) as DirectionType;
		const size = (
			isSizeType(viewBehaviour) && direction === 'horizontal' ? viewBehaviour : 'normal'
		) as SizeType;

		return [direction, size];
	}

	private switchDirection(direction: DirectionType): void {
		this.viewBehaviourIsHorizontal = direction === 'horizontal';
		this.viewBehaviourIsVertical = !this.viewBehaviourIsHorizontal;

		this.direction.set(direction);
	}

	private switchSize(size: SizeType): void {
		this.viewBehaviourIsSmall = size === 'small';
		this.viewBehaviourIsMedium = size === 'medium';
		this.viewBehaviourIsNormal = size === 'normal';

		this.size.set(size);
	}
}
