<div
	[ppCdkUseTheme]="strictLightMode() ? 'light' : theme()"
	[class.under-navigation]="isNavPanelOpened()"
	class="mobile-filters"
>
	@if (filtersOpened()) {
		<div class="filters-content" [@expandedFilters]="filtersOpened()">
			<ng-content></ng-content>
		</div>

		<div (click)="closeFilters()" class="mobile-filters-backdrop"></div>
	}

	<div (click)="toggleFilters()" class="filters-toggle-container">
		@if (filtersOpened()) {
			<div class="filters-toggle opened">
				<pp-icon name="navigation-chevron-left-18"></pp-icon>
			</div>
		} @else {
			<div class="filters-toggle" [ppCdkUseTheme]="'dark'">
				<pp-icon name="navigation-chevron-right-18"></pp-icon>
			</div>
		}

		<div class="hover-overlay"></div>
	</div>
</div>
