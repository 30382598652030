<div class="search-navigation-container dp-search-bar" ppCdkUseTheme="dark">
	<pp-icon class="search-navigation-icon dp-search-bar-search-icon" name="action-search-24" />
	<input
		type="text"
		class="input-search"
		placeholder="Search in Presales Portal"
		autocomplete="off"
		autocorrect="off"
		[value]="query"
		(input)="changeQuery($event.target)"
		(focus)="focus()"
		(blur)="blur($event.target)"
		(keyup)="submit($event)"
	/>
</div>
