/* eslint-disable max-len */
import {ChangeDetectionStrategy, Component, OnDestroy, inject} from '@angular/core';

import {Router, RouterLink} from '@angular/router';
import {Subject, Subscription, tap} from 'rxjs';
import {AsyncPipe, NgTemplateOutlet} from '@angular/common';
import {CloudThumbnailComponent} from '../cloud-thumbnail/cloud-thumbnail.component';
import {NewsSectionComponent} from '../news-section/news-section.component';
import {PresetSectionComponent} from '../preset-section/preset-section.component';
import {FormatPipe} from '../../pipes';
import {WhatsNewViewmodel} from './whats-new.viewmodel';
import {HintDirective} from '@pp/hint';
import {CopyLinkService} from '@shared/services';
import {SearchResultModel, ThumbnailAction} from '@shared/models';
import {OpeningMethods} from '@shared/enums/opening-methods.enum';
import {WhatsNewSpService} from '@dashboard/services';
import {SearchQueryService} from '@search2/services';
import {NewPipe} from '@shared/pipes';
import {SvgIconComponent} from '@pp/svg';
import {SearchModule} from '@search/search.module';
import {SearchResultThumbnailComponent} from '@search/components';
import {AnalyticsService} from '@shared/analytics';
import {ModalService} from '@shared/modals';
import {CardsSwiperComponent} from '@shared/card-containers';
import {ResultOverlayComponent} from '@shared/components/result-overlay/result-overlay.component';
import {DownloadComponent} from '@shared/components/download/download.component';
import {PreviewMenuComponent} from '@shared/components/preview-menu/preview-menu.component';
import {CONFIG} from '@environments/environment';

@Component({
	selector: 'home-whats-new',
	standalone: true,
	imports: [
		RouterLink,
		AsyncPipe,
		NgTemplateOutlet,
		SearchModule,
		NewsSectionComponent,
		PresetSectionComponent,
		CloudThumbnailComponent,
		SvgIconComponent,
		HintDirective,
		FormatPipe,
		CardsSwiperComponent,
		ResultOverlayComponent,
		DownloadComponent,
		NewPipe,
		SearchResultThumbnailComponent,
	],
	templateUrl: './whats-new.component.html',
	styleUrls: ['./whats-new.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsNewComponent implements OnDestroy {
	private readonly count = 10;
	private readonly subscriptions = new Subscription();
	config = inject(CONFIG);

	archivesQuery = this.searchQueryService.createQuery({
		presetId: 'archive',
		orderBy: '-created_date',
		size: this.count,
	});

	marketingQuery = this.searchQueryService.createQuery({
		presetId: 'marketing',
		orderBy: '-created_date',
		size: this.count,
	});

	salesQuery = this.searchQueryService.createQuery({
		presetId: 'sales',
		orderBy: '-created_date',
		size: this.count,
	});

	recommendedDeck$ = this.whatsNewSp.getRecommendedDecks$();
	recommendedDecksSearchQuery =
		'{"query":{"sortBy":{"value":"Write","direction":1}},"selectedFilters":{"include":{"locationFilterTree":["\\"ǂǂ4f66666572696e677320616e64206578706572746973653a50726573616c6573205265636f6d6d656e6473\\""],"modifiedDate":["4,9"]},"exclude":{"documentTags":["\\"ǂǂ4175746f2d67656e657261746564\\""]}}}';

	showCases$ = this.whatsNewSp.getShowcase$();
	showCasesSearchQuery =
		'{"query":{"sortBy":{"value":"Write","direction":1}},"selectedFilters":{"include":{"documentTags":["\\"ǂǂ43617365207374756479\\""],"sourceSystem":["\\"ǂǂ73686f77636173652e6570616d2e636f6d\\""],"modifiedDate":["4,9"]}}}';

	infoNews$ = this.whatsNewSp.getInfoNews$();
	infoSearchQuery =
		'{"query":{"sortBy":{"value":"Write","direction":1}},"selectedFilters":{"include":{"documentTags":["\\"ǂǂ43617365207374756479\\""],"sourceSystem":["\\"ǂǂ696e666f2e6570616d2e636f6d\\""],"modifiedDate":["4,9"]}}}';

	separator: WhatsNewViewmodel[] = [
		{
			header: 'com',
			data$: this.whatsNewSp.getEpamComPages$(),
			link: '/search',
			params: {
				o: '{"query":{"sortBy":{"value":"Write","direction":1}},"selectedFilters":{"include":{"sourceSystem":["\\"ǂǂ7777772e6570616d2e636f6d\\""],"modifiedDate":["4,9"]}}}',
			},
			verified: false,
		},
		{
			header: 'Customer Stories',
			description: 'from elements.com',
			data$: this.whatsNewSp.getElementsCases$(),
			link: '/search',
			params: {
				o: '{"query":{"sortBy":{"value":"Write","direction":1}},"selectedFilters":{"include":{"documentTags":["\\"ǂǂ43617365207374756479\\""],"sourceSystem":["\\"ǂǂ656c656d656e74732e6570616d2e636f6d\\""],"modifiedDate":["4,9"]}}}',
			},
			verified: false,
		},
	];

	hoveredDocumentId?: string;
	documentToConfirm$ = new Subject<SearchResultModel | undefined>();

	constructor(
		private readonly router: Router,
		private readonly analytics: AnalyticsService,
		private readonly searchQueryService: SearchQueryService,
		private readonly whatsNewSp: WhatsNewSpService,
		private readonly copyService: CopyLinkService,
		private readonly modalService: ModalService,
	) {}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	getResultIcon(result: SearchResultModel): string {
		switch (result.openingMethod) {
			case OpeningMethods.EXTERNAL:
				return 'link-horizontal-18';
			case OpeningMethods.DOWNLOAD:
			default:
				return 'file-attachment-18';
		}
	}

	trackShowMore(header: string): void {
		this.analytics.trackDashboardAction('showMore', header);
	}

	trackDownload(header: string): void {
		this.analytics.trackDashboardAction('download', header);
	}

	showOverlay(result: SearchResultModel): void {
		this.hoveredDocumentId = result.documentId;
	}

	hideOverlay(): void {
		this.hoveredDocumentId = undefined;
	}

	triggerAction(action: ThumbnailAction, result: SearchResultModel): void {
		switch (action) {
			case 'DOWNLOAD':
				this.download(result);
				break;
			case 'COPY':
				this.copyService.handleCopied(true);
				break;
			case 'PREVIEW':
				this.showPreview(result);
				break;
			default:
				this.analytics.trackException(
					`Unknown action triggered on Home page: ${action as string}`,
				);
				break;
		}
	}

	private showPreview(result: SearchResultModel): void {
		if (!result.previewAvailable) {
			return;
		}

		if (result.fileType === 'Web page') {
			if (result.fileExtension === 'cloudPage') {
				this.router.navigate([result.importSource]);
			} else {
				window.open(
					result.fileExtension === 'page' ? result.importSource : result.filePath,
				);
			}
		} else {
			const previewMenuInstance = this.modalService.openModal(PreviewMenuComponent, {
				type: 'PREVIEW-MENU',
				showCloseButton: false,
				data: {
					model: result,
					context: [],
				},
			});

			this.subscriptions.add(
				previewMenuInstance.queueDownload
					.pipe(
						tap((document: SearchResultModel) => {
							this.download(document);
						}),
					)
					.subscribe(),
			);
		}
	}

	private download(document: SearchResultModel): void {
		this.documentToConfirm$.next(document);
	}

	handleConfirmation(confirmedDocument?: SearchResultModel): void {
		if (confirmedDocument) {
			this.analytics.trackSearchResultsAction('download', confirmedDocument.filePath);
			this.analytics.trackDashboardAction('download');
		}

		this.documentToConfirm$.next(undefined);
	}
}
