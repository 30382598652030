import {inject} from '@angular/core';
import {patchState, signalStore, withMethods, withState} from '@ngrx/signals';
import {UserProfile} from '@shared/api';
import {UserProfileModel, UserSettingsModel} from '@shared/models';
import {ThemeModeType} from '@shared/theme';
import {AnalyticsService} from '@shared/analytics';

interface UserState {
	profile: UserProfileState;
	settings: UserSettingsModel;
}

export interface UserProfileState {
	teams: string[] | undefined; // undefined when state is uninitialized
	userDisplayName: string;
	userEmail: string;
	queryTemplate: string;
	userId?: string;
	employeeId?: string;
	avatarUrl?: string;
}

const userInitialState: UserState = {
	profile: {
		teams: undefined,
		userDisplayName: '',
		userEmail: '',
		queryTemplate: '{searchTerms}',
		userId: undefined,
		employeeId: undefined,
		avatarUrl: undefined,
	},
	settings: {
		uiTheme: 'dark',
		disableConfidentialConfirmation: false,
		filters: undefined,
		agreementSigned: false,
	},
};

export const UserStore = signalStore(
	{providedIn: 'root'},
	withState(userInitialState),
	withMethods((store) => {
		const analytics = inject(AnalyticsService);

		return {
			updateUserProfile(profile: UserProfileModel): void {
				patchState(store, (state) => ({
					profile: {
						...state.profile,
						teams: profile.teams,
						userDisplayName: profile.userDisplayName,
						userEmail: profile.userEmail.toLowerCase(),
						queryTemplate: profile.queryTemplate,
					},
				}));
			},
			updateNewUserProfile(profile: UserProfile): void {
				patchState(store, (state) => ({
					profile: {
						...state.profile,
						userId: profile.userId,
						employeeId: profile.employeeId,
						avatarUrl: profile.avatarUrl,
					},
				}));
			},
			updateUserSettings(settings: UserSettingsModel): void {
				patchState(store, () => ({settings}));
			},
			async toggleUiTheme(): Promise<void> {
				const uiTheme = store.settings.uiTheme() === 'dark' ? 'light' : 'dark';
				analytics.trackHeaderActions('theme-toggle', uiTheme);

				patchState(store, (state) => {
					return {settings: {...state.settings, uiTheme: uiTheme as ThemeModeType}};
				});

				// TODO: Uncomment when the new endpoint is ready
				// await lastValueFrom(userProfileService.setUserSetting$('theme', uiTheme));
			},
		};
	}),
);
