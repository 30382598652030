import {
	CdkDragDrop,
	moveItemInArray,
	CdkDropList,
	CdkDrag,
	CdkDragHandle,
} from '@angular/cdk/drag-drop';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import {map, switchMap, tap} from 'rxjs/operators';

import {Subscription} from 'rxjs';
import {ReorderListModel} from '@shared/models';
import {FilterSectionNamePipe} from '@shared/pipes';

import {ToastService, UserProfileService} from '@shared/services';
import {FiltersSettingsService} from '@search/services';
import {SearchFilterWidgetTypes} from '@search/models';
import {UserStore} from '@shared/state';
import {AnalyticsService} from '@shared/analytics';
import {CheckboxComponent} from '@pp/checkbox';
import {SvgIconComponent} from '@pp/svg';

@Component({
	selector: 'panels-filters-settings',
	templateUrl: './filters-settings.component.html',
	styleUrls: ['./filters-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SvgIconComponent, CdkDropList, CdkDrag, CheckboxComponent, CdkDragHandle],
})
export class FiltersSettingsComponent implements OnInit, OnDestroy {
	private readonly subscriptions = new Subscription();

	expanded = true;
	list: ReorderListModel[] = [];

	constructor(
		private userProfile: UserProfileService,
		private filterSectionName: FilterSectionNamePipe,
		private detector: ChangeDetectorRef,
		private analytics: AnalyticsService,
		private readonly toastService: ToastService,
		private readonly filterService: FiltersSettingsService,
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.filterService.filterSettings$
				.pipe(
					map((filters) =>
						// ? We use here reduce and we can't change filters object because on localhost Chrome throw error
						// ? ERROR TypeError: Cannot assign to read only property 'documentTags' of object '[object Object]'
						Object.keys(filters).reduce<{[key: string]: UpdatedFiltersState}>(
							(output, key) => {
								output[key as keyof typeof output] = {
									...filters[key as keyof typeof filters],
									name: this.filterSectionName.transform(key),
								};

								return output;
							},
							{},
						),
					),
					map((filters) => {
						const list = Object.keys(filters).map((key) => ({
							id: key,
							...filters[key],
						}));
						list.sort((left, right) => left.order - right.order);

						return [...list];
					}),
					tap((list) => {
						this.list = list;
						this.detector.markForCheck();
					}),
				)
				.subscribe(),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	drop(event: CdkDragDrop<string[]>): void {
		moveItemInArray(this.list, event.previousIndex, event.currentIndex);
	}

	toggleVisibility(target: EventTarget | null, item: ReorderListModel): void {
		item.visible = (target as HTMLInputElement).checked;
	}

	save(): void {
		const result: {[key: string]: {order: number; visible: boolean}} = {};

		this.list.forEach((item, index) => {
			result[item.id as keyof typeof result] = {
				order: index,
				visible: item.visible,
			};
		});

		this.analytics.trackUserPanelActions('saveFiltersSettings');
		this.updateFiltersSettings(JSON.stringify(result));
	}

	reset(): void {
		this.analytics.trackUserPanelActions('resetFiltersSettings');
		this.updateFiltersSettings('');
	}

	toggle(): void {
		this.expanded = !this.expanded;
	}
	private userStore = inject(UserStore);
	private updateFiltersSettings(settings: string): void {
		this.subscriptions.add(
			this.userProfile
				.setUserSetting$('filtersSettings', settings)
				.pipe(
					switchMap(() => this.userProfile.getUserProfile$()),
					tap((profile) => {
						this.userStore.updateUserProfile(profile);
						this.userStore.updateUserSettings(profile.settings);
						this.toastService.show({
							type: 'success',
							message: 'Filter settings updated',
						});
					}),
				)
				.subscribe(),
		);
	}
}

interface UpdatedFiltersState {
	order: number;
	visible: boolean;
	type: SearchFilterWidgetTypes;
	name: string;
}
