<search-picture
	class="item-thumbnail"
	[src]="item().thumbnailUrl"
	[fallbackImage]="'logo_' + (item() | fileExtension) + '_64'"
	[fallbackSize]="size()"
>
	@if (specialCaseFallbackIcon()) {
		<ng-template searchPictureFallback>
			<div class="special-fallback">
				<pp-icon [name]="specialCaseFallbackIcon()!" [size]="36"></pp-icon>
			</div>
		</ng-template>
	}

	@if (showAsHiddenSlide()) {
		<div class="hidden-overlay">
			<pp-icon class="deactivate-icon" name="action-deactivate" [size]="18" />
		</div>
	}

	@if (showCheckbox()) {
		<div class="selection-checkbox">
			<pp-checkbox
				class="filter-item-checkbox"
				[checked]="checkboxState().isCheckboxSelected"
				[disabled]="checkboxState().isCheckboxDisabled"
				(clickCheckbox)="toggleSelectionState(checkboxState().isCheckboxSelected, $event)"
			></pp-checkbox>
		</div>
	}

	@if (showActions() && accessType() !== 'notFound' && isHovered()) {
		<shared-card-actions [size]="size()">
			<shared-card-action
				*ppAsPrimary
				[size]="size() === 'small' ? 36 : 60"
				[actionInfo]="primaryAction()"
				(click)="onAction($event, primaryAction().actionType)"
				[hint]="primaryAction().name"
				hintPosition="BOTTOM"
			></shared-card-action>

			@if (size() !== 'small') {
				@for (actionInfo of secondaryActions(); track actionInfo.actionType) {
					<shared-card-action
						*ppAsSecondary
						[size]="size() === 'normal' ? 30 : 24"
						[class.copied]="isCopied && actionInfo.actionType === 'copy-link'"
						[actionInfo]="actionInfo"
						(click)="onAction($event, actionInfo.actionType)"
						[hint]="actionInfo.name"
						hintPosition="BOTTOM"
					></shared-card-action>
				}
			}
		</shared-card-actions>
	}

	<div *ngIf="!!item().extraTags" class="item-thumbnail-flag-list">
		<search-flag-list [item]="item()" [flagSize]="flagSize()"></search-flag-list>
	</div>

	@if (showSlideCount()) {
		<div class="item-thumbnail-slides-count" [class.small]="size() === 'small'">
			{{ slidesCount() }}
			{{ slidesCount() === 1 ? 'Slide' : 'Slides' }}
		</div>
	}
</search-picture>
