import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class XHRHeaderInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let headers = request.headers.set('X-Requested-With', 'XMLHttpRequest');

		if (request.url.includes('sharepoint')) {
			headers = headers.append('Accept-Language', 'en-US,en;q=0.9');
		}

		return next.handle(
			request.clone({
				headers,
			}),
		);
	}
}
