import {ChangeDetectionStrategy, Component, Input, OnInit, inject} from '@angular/core';
import {ModalService} from '@shared/modals';
import {AnalyticsService, InformativeBannerAction} from '@shared/analytics';
import {ButtonComponent} from '@pp/button';
import {SvgImageComponent} from '@pp/svg';
import {ModalVideoComponent} from '@shared/components/modal-video/modal-video.component';

@Component({
	selector: 'search-informative-banner',
	templateUrl: './informative-banner.component.html',
	styleUrls: ['./informative-banner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonComponent, SvgImageComponent],
})
export class InformativeBannerComponent implements OnInit {
	@Input() presetId!: string;

	private readonly modalService = inject(ModalService);
	imageGroupNumber = Math.floor(Math.random() * 4) + 1;
	videoUrl: string | null = null;

	constructor(private readonly analytics: AnalyticsService) {}

	ngOnInit() {
		this.videoUrl = this.getVideoUrl(this.presetId);
	}

	openVideoModal(isFirstVisitModal = false): void {
		this.modalService.openModal(ModalVideoComponent, {
			showCloseButton: true,
			type: 'OPEN_VIDEO_MODAL',
			data: {
				url: this.videoUrl!,
			},
			theme: 'dark',
		});

		this.trackAnalytics(
			isFirstVisitModal ? 'openVideo:first-visit' : 'openVideo',
			this.presetId,
		);
	}

	private getVideoUrl(presetId: string): string | null {
		switch (presetId) {
			case 'stories':
				return 'https://videoportal.epam.com/video/V7gV2BJ0';
			default:
				return null;
		}
	}

	trackAnalytics(eventName: InformativeBannerAction, presetId: string): void {
		this.analytics.trackInformativeBannerAction(eventName, presetId);
	}
}
