import {ChangeDetectionStrategy, Component, Input, OnInit, inject} from '@angular/core';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {SvgIconComponent} from '@pp/svg';
import {UsppUserInfo} from '@shared/api';
import {MailToPipe, TeamsPipe} from '@shared/pipes';
import {AnalyticsService} from '@shared/analytics';

@Component({
	selector: 'search-preview-user-info',
	templateUrl: './preview-user-info.component.html',
	styleUrls: ['./preview-user-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, NgTemplateOutlet, SvgIconComponent],
})
export class PreviewUserInfoComponent implements OnInit {
	@Input({required: true}) userInfo!: UsppUserInfo;

	telescopeLink?: string;
	msTeamsLink?: string;
	mailToLink?: string;

	loadingErrorExist?: boolean;

	constructor(private readonly analytics: AnalyticsService) {}
	private readonly teamsPipe = inject(TeamsPipe);
	private readonly mailToPipe = inject(MailToPipe);

	ngOnInit() {
		// `externalId` is only set for users, not groups (and equal to EPAM UUID)
		if (this.userInfo.externalId) {
			this.msTeamsLink = this.teamsPipe.transform(this.userInfo.email);
		} else {
			if (this.userInfo.email) {
				this.mailToLink = this.mailToPipe.transform({to: this.userInfo.email});
			}
		}

		if (!this.userInfo.avatarUrl) {
			this.loadingErrorExist = true;
		}
	}

	trackEvent(type: string) {
		this.analytics.trackDocumentPreviewAction('open:link', `user:${type}`);
	}

	handleImgError(status: 'loaded' | 'error'): void {
		this.loadingErrorExist = status === 'error';
	}
}
