@if (document) {
	<div class="confidential-container" [ngClass]="confidentialType">
		<main class="confidential-popup">
			<header class="confidential-header">
				{{ setting.title }}
				<pp-icon
					name="navigation-close-36"
					title="Close (Esc)"
					aria-hidden="true"
					(click)="cancel()"
				></pp-icon>
			</header>

			<article class="confidential-content">
				@switch (confidentialType) {
					@case (confidentialTypes.CONTENT) {
						This is a warning that you are downloading confidential information which is
						not allowed to disclose
						<shared-confidential-logos
							[accountLogos]="accountLogos"
							[images]="document.images"
							[showAll]="true"
						></shared-confidential-logos>
					}

					@case (confidentialTypes.DOCUMENT) {
						You are downloading <b [innerHTML]="document.title"></b> that is a
						confidential document or actual client deliverable that can't be reused
						as-is or disclosed
					}
				}
			</article>

			<footer class="confidential-footer">
				@if (setting.checkbox) {
					<div class="confidential-footer-remember">
						<label>
							<pp-checkbox
								(change)="rememberChoice($event.target)"
								text="Don't show this again"
							></pp-checkbox>
						</label>
					</div>
				}
				<div class="confidential-footer-options">
					<button class="confidential-cancel" (click)="cancel()">Cancel</button>
					<button class="confidential-confirm" (click)="confirmRisk()">
						{{ setting.downloadButton }}
					</button>
				</div>
			</footer>
		</main>
	</div>
}
