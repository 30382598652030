import {Pipe, PipeTransform} from '@angular/core';
import {SearchResultModel} from '@shared/models';

@Pipe({
	name: 'fileType',
	standalone: true,
})
export class FileTypePipe implements PipeTransform {
	private readonly classNames: Dictionary<string> = {
		pptx: 'ppt',
		ppt: 'ppt',
		pot: 'ppt',
		potx: 'ppt',
		ppsx: 'ppt',

		docx: 'word',
		doc: 'word',
		docm: 'word',
		rtf: 'word',
		dotx: 'word',
		wordml: 'word',
		dot: 'word',

		xltx: 'excel',
		xlt: 'excel',
		xlsx: 'excel',
		xls: 'excel',
		xlsb: 'excel',
		xlsm: 'excel',
		excelml: 'excel',

		pdf: 'pdf',

		vsd: 'visio',
		vdx: 'visio',
		vsdx: 'visio',

		html: 'web',
		htm: 'web',
		mhtml: 'web',
		mhtm: 'web',
		aspx: 'web',
		www: 'web',
		page: 'confluence',

		mpp: 'msp',

		eap: 'eap',

		msg: 'mail',
		eml: 'mail',
	};

	constructor() {}

	transform(item: SearchResultModel): string {
		const fileExtension = item.fileExtension
			? item.fileExtension.split('.').reverse()[0]
			: 'url';

		return this.classNames[fileExtension] || 'other';
	}
}
